import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function TimeSeriesShortTable(props) {

	const classes = useStyles();

	const { rows, detail, setDetail } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');


	const head_cells = [
		{ id: 'id', alignTo: 'left', disablePadding: false, label: 'Назва' },
		{ id: 'status', alignTo: 'left', disablePadding: false, label: 'Статус' },
		{ id: 'seller', alignTo: 'left', disablePadding: false, label: 'Продавець' },
		{ id: 'consumer', alignTo: 'left', disablePadding: false, label: 'Покупець' },
		{ id: 'amount', alignTo: 'right', disablePadding: false, label: 'Обсяг, МВтг' },
		{ id: 'action', alignTo: 'right', disablePadding: false, label: '==' },
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleView = (event, id) => {
		if (detail === id) {
			setDetail('');
		} else {
			setDetail(id);
		}
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onRequestSort={handleRequestSort}
						disable_checkbox={true}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy)).map(row => {

									return (
										<TableRow
											hover
											key={row.key}
										>

											<TableCell align='left'>{row.id}</TableCell>
											<TableCell align='left'>{row.status}</TableCell>
											<TableCell align='left'>{row.seller}</TableCell>
											<TableCell align='left'>{row.consumer}</TableCell>
											<TableCell align='right'>{row.amount.toFixed(1)}</TableCell>

											<TableCell align='right' padding='checkbox'>
												<Checkbox
													checked={detail === row.id}
													onClick={event => handleView(event, row.id)}
												/>
											</TableCell>

										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


TimeSeriesShortTable.propTypes = {
	rows: PropTypes.array.isRequired,
	detail: PropTypes.string.isRequired,
	setDetail: PropTypes.func.isRequired,
};