import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUploadSharp';
import DownloadIcon from '@material-ui/icons/CloudDownloadSharp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ScheduleTable from './subcomponents/SchedulesTable';

import WaitDialog from '../subcomponents/WaitDialog';
import ScheduleUploadDialog from './subcomponents/ScheduleUploadDialog';
import MmsResultsDialog from './subcomponents/MmsResultDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { bcContext } from './bcContext';
import { is_valid_date } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	grid: {
		marginTop: theme.spacing(1),
		flexGrow: 1,
	},

	position_parent: {
		position: 'relative',
		textAlign: 'center',
	},

	header: {
		margin: theme.spacing(1, 0, 1, 0),
		display: 'inline-block',
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

}));

var online_schedules = [];

export default function ScheduleDownloadForm(props) {
	const classes = useStyles();

	const { offline_schedules_selected, setOfflineSchedulesSelected } = props;

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);


	const initial_upload_dialog = {
		show: false,
		schedule: undefined,
		results: undefined
	}
	const [upload_dialog, setUploadDialog] = useState(initial_upload_dialog);

	const initial_mms_dialog = {
		show: false,
		reasons: [],
		schedules: [],
		anomaly_reports: []
	}
	const [mms_dialog, setMmsDialog] = useState(initial_mms_dialog);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	const [online_schedules_selected, setOnlineSchedulesSelected] = React.useState([]);

	// import context
	const { global_state, setGlobalState, list_schedule } = useContext(bcContext);


	function createInitialState() {

		const complex_state = {
			offline_schedules: [],
			upload_disabled: false,
			download_disabled: false,
			report_date: props.date,
		}

		return complex_state;
	}


	async function follow_link(link, schedule) {

		if (!schedule) {
			return;
		}

		try {

			setLocalState(prev_state => ({ ...prev_state,
				upload_disabled: true,
			}));

			// upload the schedule
			const get_request_id = await axios.post(link, schedule);

			// get results in 40 seconds
			setTimeout(async() => {

				const response = await axios.get('/api/bc/schedules/mms/' + get_request_id.data + '/results');
				if (response.status === 200) {

					setLocalState(prev_state => ({...prev_state,
						upload_disabled: false,
					}));
	
					setMmsDialog(prev_state => ({ ...prev_state,
						show: true,
						reasons: response.data.reasons,
					}));
				}

			}, 40000)

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function UploadSchedule() {

		const local_id = parseInt(offline_schedules_selected[0]);
		if (local_id) {

			const upload_results = await axios.get('/api/bc/schedules/' + local_id + '/upload');
			const schedule = upload_results.data;

			const domain = global_state.domains.find(elem => elem.domain_id === schedule.domain_id);
			const status = global_state.statuses.find(elem => elem.status_id === schedule.status_id);
			const sender = global_state.companies.find(elem => elem.company_id === schedule.sender_company_id);
			const receiver = global_state.companies.find(elem => elem.company_id === schedule.receiver_company_id);

			schedule.domain = (domain !== undefined) ? domain.domain_display_name : schedule.domain_id;
			schedule.status = (status !== undefined) ? status.status_name : '';
			schedule.start = getDateFormat(schedule.trade_date);
			schedule.sender = (sender !== undefined) ? sender.name : '';
			schedule.receiver = (receiver !== undefined) ? receiver.name : '';

			schedule.time_series.forEach(ts => {

				const seller = global_state.companies.find(elem => elem.company_id === ts.seller_company_id);
				const consumer = global_state.companies.find(elem => elem.company_id === ts.consumer_company_id);
				const domain = global_state.domains.find(elem => elem.domain_id === ts.domain_id);
	
				ts.seller = (seller !== undefined) ? seller.name : '';
				ts.consumer = (consumer !== undefined) ? consumer.name : '';
				ts.domain = (domain !== undefined) ? domain.domain_display_name : ts.domain_id;
			});

			setUploadDialog({
				show: true,
				schedule: schedule,
				results: undefined
			});

		} else {
			alert('Не вибраний графік для завантаження.');
		}
	}


	async function DownloadMmsSchedules() {

		try {

			setLocalState(prev_state => ({ ...prev_state,
				download_disabled: true,
			}));

			const date_2 = new Date(local_state.report_date);
			const date_1 = new Date(local_state.report_date);
			date_1.setHours(0, 0, 0, 0);
			date_2.setHours(24, 0, 0, 0);

			const payload = {
				period_start: getDateFormat(date_1),
				period_end: getDateFormat(date_2)
			}

			const get_request_id = await axios.post('/api/bc/schedules/mms/own_schedules', payload);

			setTimeout(async() => {

				const response = await axios.get('/api/bc/schedules/mms/' + get_request_id.data + '/results');
				if (response.status === 200) {

					online_schedules = response.data.schedules;

					setMmsDialog(prev_state => ({ ...prev_state,
						show: true,
						schedules: response.data.schedules,
						anomaly_reports: response.data.anomaly_reports
					}));
	
					setLocalState(prev_state => ({ ...prev_state,
						download_disabled: false,
					}));
				}

			}, 30000)

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function ShowOnlineSchedule(schedule_id) {

		const schedule = online_schedules.filter(item => item.schedule_id === schedule_id);

		setMmsDialog(prev_state => ({ ...prev_state,
			show: true,
			schedules: schedule,
		}));
	}


	async function DeleteMmsSchedule(remote_id) {

		if (!window.confirm('Видалення графіку з сервера неможливо. Якщо необхідно, графік можна переписати новим із нульовими значеннями. Переписати?')) {
			return;
		}

		try {

			const response = await axios.post('/api/bc/schedules/mms/' + remote_id + '/delete');

			if (response.status === 200) {

				online_schedules = online_schedules.filter(item => item.order_id !== remote_id);

				setMmsDialog(prev_state => ({ ...prev_state,
					show: true,
				}));
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function handleScheduleAction(action_id, schedule_id) {

		switch (action_id) {

			case 0:
				ShowOnlineSchedule(schedule_id);
				break;
			case 1:
				DeleteMmsSchedule(schedule_id);
				break;
			default:
				break;
		}
	}


	function onUploadDialogClose(result) {

		setUploadDialog({
			show: false,
			schedule: undefined,
		});

		// after closing of upload dialog execute upload and getting results
		if (result !== undefined) {

			if (!window.confirm('Ви впевнені, що бажаєте завтантажити графік?')) {
				return;
			}

			const time_series_ids = [];

			result.time_series.forEach(item => {
				time_series_ids.push({
					time_series_id: item.time_series_id,
					revision: item.revision,
					revision_id: item.revision_id
				})
			});

			const payload = {
				schedule_id: result.schedule_id,
				revision: result.revision,
				revision_id: result.revision_id,
				time_series: time_series_ids
			};

			follow_link('/api/bc/schedules/mms/' + result.schedule_id + '/upload', payload);
		}
	}


	function onMmsDialogClose() {

		setOfflineSchedulesSelected([]);

		setGlobalState(state => ({ ...state,
			update_flag: !state.update_flag,
		}));

		setMmsDialog(prev_state => ({...prev_state,
			show: false,
		}));
	}


	function onErrorMessageClose() {

		setMessage(message => ({...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		let is_subscribed = true;

		if (is_valid_date(local_state.report_date)) {

			axios.get('/api/bc/schedules', { params: {
					period_start:	getDateFormat(local_state.report_date),
					period_end:		getDateFormat(local_state.report_date)
			}})
				.then(response => {

					if(is_subscribed) {

						let drafts = response.data.schedules_list.filter(item => item.status_id <= 2);
						setLocalState(prev_state => ({ ...prev_state,
							offline_schedules: drafts,
						}));
					}

				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});
		}

		return () => (is_subscribed = false);

	}, [local_state.report_date])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({ ...message,
							show: true,
							info: `${first_name} ${last_name} \n ${email} \n ${phone}`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	var offline_schedule_rows = [];
	var online_schedule_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		if (local_state.offline_schedules.length > 0) {
			offline_schedule_rows = local_state.offline_schedules.map(item => list_schedule(item));
		}

		if (online_schedules.length > 0) {
			online_schedule_rows = online_schedules.map(item => list_schedule(item));
		}
	}


	return (
		<div className={classes.grid}>

			<Grid container spacing={2}>

				<Grid item xs={6}>

					<div className={ classes.position_parent }>

						<Typography className={classes.header}>
							Часові графіки РДД
						</Typography>

						<Tooltip title='Передати графік в MMS'>
							<IconButton
								className={classes.right_button}
								onClick={() => UploadSchedule()}
								disabled={local_state.upload_disabled}
								color='inherit'
							>
								<UploadIcon />
							</IconButton>
						</Tooltip>

					</div>

				</Grid>

				<Grid item xs={6}>

					<div className={ classes.position_parent }>

						<Typography className={classes.header}>
							Часові графіки РДД в MMS
						</Typography>

						<Tooltip title='Завантажити графік з MMS'>
							<IconButton
								className={classes.right_button}
								onClick={() => DownloadMmsSchedules()}
								disabled={local_state.download_disabled}
								color='inherit'
							>
								<DownloadIcon />
							</IconButton>
						</Tooltip>

					</div>

				</Grid>

				<Grid item xs={6}>

					<ScheduleTable
						rows={offline_schedule_rows}
						selected={offline_schedules_selected}
						setSelected={setOfflineSchedulesSelected}
					/>

				</Grid>

				<Grid item xs={6}>

					<ScheduleTable
						rows={online_schedule_rows}
						selected={online_schedules_selected}
						setSelected={setOnlineSchedulesSelected}
						action_list={[
							{ id: 0, name: 'Відкрити' },
							{ id: 1, name: 'Видалити' }
						]}
						onActionSelect={handleScheduleAction}
					/>

				</Grid>

			</Grid>

			<ScheduleUploadDialog
				open={upload_dialog.show}
				onClose={onUploadDialogClose}
				schedule={upload_dialog.schedule}
			/>

			<MmsResultsDialog
				open={mms_dialog.show}
				onClose={onMmsDialogClose}
				reasons={mms_dialog.reasons}
				schedules={mms_dialog.schedules}
				anomaly_reports={mms_dialog.anomaly_reports}
			/>

			<WaitDialog
				open={local_state.upload_disabled || local_state.download_disabled}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={message.info}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}