import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Avatar from '@material-ui/core/Avatar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import PriceTable from './subcomponents/PriceTable';

import { damPricesContext } from './damPricesContext';
import { getDateFormat } from '../utils/date_functions';
import { ua_prices_from_json, non_ua_prices_from_json, eur_uah_erates } from '../utils/dam_price_functions';
import { excel_date_to_date } from '../utils/excel_functions';
import { getDateFromLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	control_margin: {
		marginTop: theme.spacing(2),
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		textAlign: 'center',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function DamPricesImportForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);
	const [wait, setWait] = useState(false);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// import context
	const { global_state, setGlobalState } = useContext(damPricesContext);


	function createInitialState() {

		const complex_state = {
			prices: [],
			exchange_rates: [],
		}

		return complex_state;
	}


	function handleImportUaFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });

					const all_prices = [];

					for (let i = 0; i < wb.SheetNames.length; i++) {

						const name = wb.SheetNames[i];

						const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[name], { header: 1 });
						const prices = ua_prices_from_json(json_doc);

						prices.forEach(price => {

							const domain = global_state.domains.find(item => price.domain.toLowerCase().includes(item.domain_display_name.toLowerCase()));
							if (domain !== undefined) {
								price.domain_id = domain.domain_id;
							}
						});

						all_prices.push(...prices);
					}

					setLocalState(prev_state => ({ ...prev_state,
						prices: all_prices,
					}));

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleImportNonUaFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });

					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
					const prices = non_ua_prices_from_json(json_doc);

					prices.forEach(price => {

						const domain = global_state.domains.find(item => price.domain.toLowerCase() === item.domain_name.toLowerCase());
						if (domain !== undefined) {
							price.domain_id = domain.domain_id;
						}

						let potential_date;
						if (typeof price.trade_date === 'string') {
							potential_date = getDateFromLocalDateString(price.trade_date);
						} else {
							potential_date = excel_date_to_date(price.trade_date);
						}

						price.trade_date = potential_date;
					});

					setLocalState(prev_state => ({ ...prev_state,
						prices: prices,
					}));

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleImportExchangeRates(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });

					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
					const exchange_rates = eur_uah_erates(json_doc);

					exchange_rates.forEach(item => {

						let potential_date;
						if (typeof item.trade_date === 'string') {
							potential_date = getDateFromLocalDateString(item.trade_date);
						} else {
							potential_date = excel_date_to_date(item.trade_date);
						}

						item.trade_date = potential_date;
					});

					setLocalState(prev_state => ({ ...prev_state,
						exchange_rates: exchange_rates,
					}));

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	async function save_prices(prices) {

		try {

			const new_data = {
				...prices,
				domain_id: prices.domain_id,
				trade_date: getDateFormat(prices.trade_date),
			};

			if (new_data['p01'] !== undefined) {
				await axios.post('/api/dam_prices/update', new_data);
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	async function save_exchange_rates(exchange_rates) {

		try {

			await axios.post('/api/ers/', { rows: exchange_rates });

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	async function onPriceImportHandler() {

		setWait(true);

		let missing_domain = 0;

		for (let i = 0; i < local_state.prices.length; i++) {

			const item = local_state.prices[i];

			if (item.domain_id !== undefined) {
				await save_prices(item);
			} else {
				missing_domain += 1;
			}
		}

		setGlobalState(prev_state => ({ ...prev_state,
			update_flag: !prev_state.update_flag,
		}));

		let message = 'Імпорт завершено.';

		if (missing_domain > 0) {
			message += `/n Імпорт ${missing_domain} цін не відбувся через невизначену торгівельну зону.`;
		}

		setWait(false);

		window.alert(message);
	}


	async function onExchangeRatesImportHandler() {

		setWait(true);

		const rows = [];

		for (let i = 0; i < local_state.exchange_rates.length; i++) {

			const item = local_state.exchange_rates[i];

			rows.push({
				trade_date: getDateFormat(item.trade_date),
				code: item.code,
				er: item.exchange_rate
			});
		}

		save_exchange_rates(rows);

		setGlobalState(prev_state => ({ ...prev_state,
			update_flag: !prev_state.update_flag,
		}));

		setWait(false);

		window.alert('Імпорт завершено.');
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				<ImportExportIcon />
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				Імпорт цін РДН
			</Typography>

			<Grid container spacing={2} className={ classes.control_margin }>

				<Grid item xs={4}>

					<Typography component='h2' variant='h5' align='center'>
						Імпорт цін України
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						fullWidth
						type='file'
						label='Файл цін РДН'
						inputProps= {{ accept:'.xls, .xlsx' }}
						onChange= { handleImportUaFileChange }
					/>

				</Grid>

				<Grid item xs={4}>

					<Typography component='h2' variant='h5' align='center'>
						Іпорт цін закордону
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						fullWidth
						type='file'
						label='Файл цін'
						inputProps= {{ accept:'.xls, .xlsx' }}
						onChange= { handleImportNonUaFileChange }
					/>

				</Grid>

				<Grid item xs={4}>

					<Typography component='h2' variant='h5' align='center'>
						Іпорт обмінних курсів
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						fullWidth
						type='file'
						label='Файл обмінних курсів'
						inputProps= {{ accept:'.xls, .xlsx' }}
						onChange= { handleImportExchangeRates }
					/>

				</Grid>

			</Grid>

			<PriceTable
				rows={ local_state.prices }
			/>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					onClick={ onPriceImportHandler }
					disabled={ local_state.prices.length === 0 }
				>
					Імпорт цін
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					onClick={ onExchangeRatesImportHandler }
					disabled={ local_state.exchange_rates.length === 0 }
				>
					Імпорт курсів
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className = { classes.button_control }
					onClick= { onCancelHandler }
				>
					Скасувати
				</Button>

			</div>

			<WaitDialog
				open={wait}
			/>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}