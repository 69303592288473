import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function EventsView(props) {

	const classes = useStyles();

	const { rows } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');


	const head_cells = [
		{ id: 'timestamp', alignTo: 'left', disablePadding: false, label: 'Час поідомлення' },
		{ id: 'message', alignTo: 'left', disablePadding: false, label: 'Повідомлення' },
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onRequestSort={handleRequestSort}
						disable_checkbox={true}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy)).map((row, index) => {

									return (
										<TableRow
											hover
											key={index}
										>
											<TableCell scope='row' align='left'>{row.timestamp}</TableCell>
											<TableCell align='left'>{row.message}</TableCell>
										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


EventsView.propTypes = {
	rows: PropTypes.array.isRequired,
};