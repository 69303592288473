
export function getTotalAmount(time_series) {
	var result = 0;

	if (time_series !== undefined) {

		for (let i = 1; i <= 25; i++) {

			let index = (i < 10) ? 'p0' : 'p';
			index = index + i.toString();

			if (time_series[index] !== null) {
				result += parseFloat(time_series[index]);
			}
		}
	}

	return result;
}


export function ScheduleFromXml(schedule_market_document) {

	// check document type
	const doc = schedule_market_document.getElementsByTagName('Schedule_MarketDocument');
	if (doc.length !== 1) {
		console.log(doc);
		return undefined;
	}

	// array of time series
	let time_series = [];
	const xml_series = schedule_market_document.getElementsByTagName('TimeSeries');
	for (let i = 0; i < xml_series.length; i++) {

		// read points from the time series
		let points = [];
		const xml_points = xml_series[i].getElementsByTagName('Point');
		for (let j = 0; j < xml_points.length; j++) {
			points.push({
				id: xml_points[j].getElementsByTagName('position')[0].innerHTML,
				value: parseFloat(xml_points[j].getElementsByTagName('quantity')[0].innerHTML)
			});
		}

		// read the header of time series
		time_series.push({
			rid: xml_series[i].getElementsByTagName('mRID')[0].innerHTML,
			revision: xml_series[i].getElementsByTagName('version')[0].innerHTML,
			in_domain: xml_series[i].getElementsByTagName('in_Domain.mRID')[0].innerHTML,
			out_domain: xml_series[i].getElementsByTagName('out_Domain.mRID')[0].innerHTML,
			consumer: xml_series[i].getElementsByTagName('in_MarketParticipant.mRID')[0].innerHTML,
			seller: xml_series[i].getElementsByTagName('out_MarketParticipant.mRID')[0].innerHTML,
			trade_date: xml_series[i].getElementsByTagName('timeInterval')[0].getElementsByTagName('start')[0].innerHTML,
			points: points,
		});
	}

	// read the header of the schedule
	const schedule = {
		rid: schedule_market_document.getElementsByTagName('mRID')[0].innerHTML,
		revision: schedule_market_document.getElementsByTagName('revisionNumber')[0].innerHTML,

		sender: schedule_market_document.getElementsByTagName('sender_MarketParticipant.mRID')[0].innerHTML,
		sender_role: schedule_market_document.getElementsByTagName('sender_MarketParticipant.marketRole.type')[0].innerHTML,
		receiver: schedule_market_document.getElementsByTagName('receiver_MarketParticipant.mRID')[0].innerHTML,
		receiver_role: schedule_market_document.getElementsByTagName('receiver_MarketParticipant.marketRole.type')[0].innerHTML,

		created_date: schedule_market_document.getElementsByTagName('createdDateTime')[0].innerHTML,
		trade_date: schedule_market_document.getElementsByTagName('schedule_Time_Period.timeInterval')[0].getElementsByTagName('start')[0].innerHTML,
		domain: schedule_market_document.getElementsByTagName('domain.mRID')[0].innerHTML,

		time_series: time_series,
	}

	return schedule;
}


export function ScheduleToXml(schedule_market_document, schedule) {

	// check document type
	let root_elements = schedule_market_document.getElementsByTagName('Schedule_MarketDocument');
	if (root_elements.length !== 1) {
		console.log(root_elements);
		return undefined;
	}

	schedule_market_document.getElementsByTagName('mRID')[0].innerHTML = schedule.rid;
	schedule_market_document.getElementsByTagName('revisionNumber')[0].innerHTML = schedule.revision;

	schedule_market_document.getElementsByTagName('sender_MarketParticipant.mRID')[0].innerHTML = schedule.sender_company;
	schedule_market_document.getElementsByTagName('sender_MarketParticipant.marketRole.type')[0].innerHTML = schedule.sender_role;
	schedule_market_document.getElementsByTagName('receiver_MarketParticipant.mRID')[0].innerHTML = schedule.receiver_company;
	schedule_market_document.getElementsByTagName('receiver_MarketParticipant.marketRole.type')[0].innerHTML = schedule.receiver_role;

	schedule_market_document.getElementsByTagName('createdDateTime')[0].innerHTML = schedule.created_date;
	schedule_market_document.getElementsByTagName('schedule_Time_Period.timeInterval')[0].getElementsByTagName('start')[0].innerHTML = schedule.period_start;
	schedule_market_document.getElementsByTagName('schedule_Time_Period.timeInterval')[0].getElementsByTagName('end')[0].innerHTML = schedule.period_end;

	schedule_market_document.getElementsByTagName('domain.mRID')[0].innerHTML = schedule.domain;

	// read time series xml structure
	let time_series_node = schedule_market_document.getElementsByTagName('TimeSeries')[0];
	// remove time series node from document
	root_elements[0].removeChild(time_series_node);
	// read point xml structure
	let point_node = time_series_node.getElementsByTagName('Period')[0].getElementsByTagName('Point')[0];
	// remove point node from document
	time_series_node.getElementsByTagName('Period')[0].removeChild(point_node);

	for (let i = 0; i < schedule.time_series.length; i++) {

		// create a copy of time series xml structure
		let new_time_series = time_series_node.cloneNode(true);

		new_time_series.getElementsByTagName('mRID')[0].innerHTML = schedule.time_series[i].rid;
		new_time_series.getElementsByTagName('version')[0].innerHTML = schedule.time_series[i].revision;

		new_time_series.getElementsByTagName('in_Domain.mRID')[0].innerHTML = schedule.time_series[i].domain;
		new_time_series.getElementsByTagName('out_Domain.mRID')[0].innerHTML = schedule.time_series[i].domain;

		new_time_series.getElementsByTagName('in_MarketParticipant.mRID')[0].innerHTML = schedule.time_series[i].consumer_company;
		new_time_series.getElementsByTagName('out_MarketParticipant.mRID')[0].innerHTML = schedule.time_series[i].seller_company;

		new_time_series.getElementsByTagName('timeInterval')[0].getElementsByTagName('start')[0].innerHTML = schedule.time_series[i].period_start;
		new_time_series.getElementsByTagName('timeInterval')[0].getElementsByTagName('end')[0].innerHTML = schedule.time_series[i].period_end;

		// insert points into xml structure of time series
		for(let j = 0; j < schedule.time_series[i].points.length; j++) {

			let new_point = point_node.cloneNode(true);

			new_point.getElementsByTagName('position')[0].innerHTML = schedule.time_series[i].points[j].id;
			new_point.getElementsByTagName('quantity')[0].innerHTML = schedule.time_series[i].points[j].value;

			new_time_series.getElementsByTagName('Period')[0].appendChild(new_point);
		}

		// add time series to xml
		root_elements[0].appendChild(new_time_series);
	}

	return schedule_market_document;
}


export function ScheduleFromJson(schedule_json) {

	// check array
	if (schedule_json.length === 0) {
		return undefined;
	}

	// array of rows
	let time_series = [];

	let schedule_id = schedule_json[2][2];
	let revision_id = schedule_json[4][2];
	let sender = schedule_json[12][2];
	let sender_role = schedule_json[14][2];
	let receiver = schedule_json[16][2];
	let receiver_role = schedule_json[18][2];

	let schedule_create_date = new Date();
	schedule_create_date.setFullYear(schedule_json[21][2]);
	schedule_create_date.setMonth(schedule_json[21][3] - 1);
	schedule_create_date.setDate(schedule_json[21][4]);
	schedule_create_date.setHours(schedule_json[21][5], schedule_json[21][6], schedule_json[21][7]);

	let schedule_period_start = new Date();
	schedule_period_start.setUTCFullYear(schedule_json[24][2]);
	schedule_period_start.setUTCMonth(schedule_json[24][3] - 1);
	schedule_period_start.setUTCDate(schedule_json[24][4]);
	schedule_period_start.setUTCHours(schedule_json[24][5], schedule_json[24][6], 0);

	let schedule_period_end = new Date();
	schedule_period_end.setUTCFullYear(schedule_json[24][7]);
	schedule_period_end.setUTCMonth(schedule_json[24][8] - 1);
	schedule_period_end.setUTCDate(schedule_json[24][9]);
	schedule_period_end.setUTCHours(schedule_json[24][10], schedule_json[24][11], 0);

	let domain = schedule_json[26][2];

	let time_Series_end = false;
	let row_index = 28;

	while (!time_Series_end) {

		let next_rid_row = schedule_json[row_index + 1];
		let m_rid = (next_rid_row !== undefined && next_rid_row.length > 2) ? next_rid_row[2] : undefined;

		if (m_rid === undefined) {
			time_Series_end = true;
		}

		if (m_rid !== undefined) {

			let version = schedule_json[row_index + 3][2];
			let business_type = schedule_json[row_index + 5][2];
	
			let in_domain = schedule_json[row_index + 11][2];
			let out_domain = schedule_json[row_index + 13][2];
	
			let consumer = schedule_json[row_index + 15][2];
			let seller = schedule_json[row_index + 17][2];
	
			let period_start = new Date();
			period_start.setUTCFullYear(schedule_json[row_index + 22][2]);
			period_start.setUTCMonth(schedule_json[row_index + 22][3] - 1);
			period_start.setUTCDate(schedule_json[row_index + 22][4]);
			period_start.setUTCHours(schedule_json[row_index + 22][5], schedule_json[row_index + 22][6], 0);
	
			let period_end = new Date();
			period_end.setUTCFullYear(schedule_json[row_index + 22][7]);
			period_end.setUTCMonth(schedule_json[row_index + 22][8] - 1);
			period_end.setUTCDate(schedule_json[row_index + 22][9]);
			period_end.setUTCHours(schedule_json[row_index + 22][10], schedule_json[row_index + 22][11], 0);

			let hour_row = schedule_json[row_index + 24];
			let points = [];
			for (let i = 2; i < 26; i++) {
				if (hour_row[i]) {
					points[i - 2] = {
						id: i - 1,
						value: parseFloat(hour_row[i]),
					}
				}
			}
	
			time_series.push({
	
				rid: m_rid,
				version: version,
				business_type: business_type,

				in_domain: in_domain,
				out_domain: out_domain,

				consumer: consumer,
				seller: seller,

				trade_date: period_start,

				points: points,
			});
		}

		row_index += 26;
	}

	const schedule = {
		schedule_id: schedule_id,
		revision_id: revision_id,
		sender: sender,
		sender_role: sender_role,
		receiver: receiver,
		receiver_role: receiver_role,

		create_date: schedule_create_date,
		trade_date: schedule_period_start,

		domain: domain,

		time_series: time_series,
	}

	return schedule;
}


export function ScheduleToCells(schedule) {

	const result = [];

	result.push({ c: 2, r: 2, t: 's', v: schedule.rid });
	result.push({ c: 2, r: 4, t: 's', v: schedule.revision });
	result.push({ c: 2, r: 12, t: 's', v: schedule.sender_company });

	const created_date = new Date(schedule.created_date);
	result.push({ c: 2, r: 21, t: 's', v: created_date.getUTCFullYear().toString() });
	result.push({ c: 3, r: 21, t: 's', v: (created_date.getUTCMonth() + 1).toString() });
	result.push({ c: 4, r: 21, t: 's', v: created_date.getUTCDate().toString() });
	result.push({ c: 5, r: 21, t: 's', v: created_date.getUTCHours().toString() });
	result.push({ c: 6, r: 21, t: 's', v: created_date.getUTCMinutes().toString() });
	result.push({ c: 7, r: 21, t: 's', v: created_date.getUTCSeconds().toString() });

	const period_start = new Date(schedule.period_start);
	result.push({ c: 2, r: 24, t: 's', v: period_start.getUTCFullYear().toString() });
	result.push({ c: 3, r: 24, t: 's', v: (period_start.getUTCMonth() + 1).toString() });
	result.push({ c: 4, r: 24, t: 's', v: period_start.getUTCDate().toString() });
	result.push({ c: 5, r: 24, t: 's', v: period_start.getUTCHours().toString() });

	const period_end = new Date(schedule.period_end);
	result.push({ c: 7, r: 24, t: 's', v: period_end.getUTCFullYear().toString() });
	result.push({ c: 8, r: 24, t: 's', v: (period_end.getUTCMonth() + 1).toString() });
	result.push({ c: 9, r: 24, t: 's', v: period_end.getUTCDate().toString() });
	result.push({ c: 10, r: 24, t: 's', v: period_end.getUTCHours().toString() });

	result.push({ c: 2, r: 26, t: 's', v: schedule.domain });

	for (let i = 0; i < schedule.time_series.length; i++) {

		result.push({ c: 2, r: 28 + i * 26 + 1, t: 's', v: `TS${i + 1}` });
		result.push({ c: 2, r: 28 + i * 26 + 3, t: 's', v: schedule.time_series[i].revision.toString() });

		result.push({ c: 2, r: 28 + i * 26 + 11, t: 's', v: schedule.time_series[i].domain });
		result.push({ c: 2, r: 28 + i * 26 + 13, t: 's', v: schedule.time_series[i].domain });

		result.push({ c: 2, r: 28 + i * 26 + 15, t: 's', v: schedule.time_series[i].consumer_company });
		result.push({ c: 2, r: 28 + i * 26 + 17, t: 's', v: schedule.time_series[i].seller_company });

		const period_start = new Date(schedule.time_series[i].period_start);
		result.push({ c: 2, r: 28 + i * 26 + 22, t: 's', v: period_start.getUTCFullYear().toString() });
		result.push({ c: 3, r: 28 + i * 26 + 22, t: 's', v: (period_start.getUTCMonth() + 1).toString() });
		result.push({ c: 4, r: 28 + i * 26 + 22, t: 's', v: period_start.getUTCDate().toString() });
		result.push({ c: 5, r: 28 + i * 26 + 22, t: 's', v: period_start.getUTCHours().toString() });
	
		const period_end = new Date(schedule.time_series[i].period_end);
		result.push({ c: 7, r: 28 + i * 26 + 22, t: 's', v: period_end.getUTCFullYear().toString() });
		result.push({ c: 8, r: 28 + i * 26 + 22, t: 's', v: (period_end.getUTCMonth() + 1).toString() });
		result.push({ c: 9, r: 28 + i * 26 + 22, t: 's', v: period_end.getUTCDate().toString() });
		result.push({ c: 10, r: 28 + i * 26 + 22, t: 's', v: period_end.getUTCHours().toString() });

		schedule.time_series[i].points.forEach(item => {
			result.push({ c: 2 + parseInt(item.id) - 1, r: 28 + i * 26 + 24, t: 's', v: item.value });
		});
	}

	return result;
}
