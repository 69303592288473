import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { planningContext } from './planningContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	hour_point: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		width: '85px'
	},

}));


export default function PlanRowEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label
	const domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);

	// import context
	const { global_state, setGlobalState } = useContext(planningContext);

	// get forecast id from url
	const plan_row_id = parseInt(props.match.params.plan_row_id);
	const update_url = isNaN(plan_row_id) ? '/api/planning/plan/add' : '/api/planning/plan/' + plan_row_id;


	function createInitialState () {

		var day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);

		const hours_count = getHoursInDate(day_1);

		// initialize hour input elements with 24 element for each hour
		var result = [];
		for (let i = 1; i <= hours_count; i++) {

			result.push({
				key: i,
				id: i.toString(),
				label: i.toString(),
				valid: true,
				value: 0
			});
		}

		const complex_state = {
			group_id: 0,
			consumer_company_id: 0,
			seller_company_id: 0,
			domain_id: 0,
			row_name: '',
			trade_date: getDateFormat(day_1),
			hours_count: hours_count,
			hours: result,
			changed: false,
		}

		return complex_state;
	}


	function handleDomainChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeDateChange (event) {

		const event_value = event.target.value;
		const new_hours_count = getHoursInDate(event_value);

		if (new_hours_count !== local_state.hours_count) {

			// create a copy of hour point
			let result = [ ...local_state.hours ];

			// if hours is greater then array length, add elements
			if (new_hours_count > local_state.hours.length) {

				for (let i = local_state.hours.length + 1; i <= new_hours_count; i++) {

					result.push({
						key: i,
						id: i.toString(),
						label: i.toString(),
						valid: true,
						value: 0
					});
				}
			}

			// if hours is lower then array length, remove elements
			if (new_hours_count < local_state.hours.length) {
				result = result.slice(0, new_hours_count)
			}

			setLocalState(prev_state => ({ ...prev_state,
				trade_date: event_value,
				hours_count: new_hours_count,
				hours: result,
				changed: true,
			}));

		} else {

			setLocalState(prev_state => ({ ...prev_state,
				trade_date: event_value,
				changed: true,
			}));
		}
	}


	function handleRowNameChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			row_name: event_value,
			changed: true,
		}));
	}


	function handleConsumerChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			consumer_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleSellerChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			seller_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function onTextChangeHandler (event) {

		// cache event value
		const event_value = event.target.value;
		// get element id
		const element_id = parseInt(event.target.id);

		// find item
		const new_points = [ ...local_state.hours ];
		const item_at_index = new_points.find(item => item.key === element_id);

		item_at_index.valid = is_valid_float_input(event_value);
		item_at_index.value = event_value;

		setLocalState(prev_state => ({ ...prev_state,
			hours: new_points,
			changed: true,
		}));
	}


	function onValuePaste (event) {

		const event_target = event.target;
		// for elements with identified id
		if (event_target.id) {

			// e.clipboardData contains the data that is about to be pasted.
			if (event.clipboardData.types.indexOf('text/plain') > -1) {

				const string_to_paste = event.clipboardData.getData('text/plain');
				const array_to_paste = string_to_paste.replace(/\t+/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';');

				// Since the paste operation get canceled, we need to manually
				// paste the data into the document.
				const new_points = [ ...local_state.hours ];

				// iterate through buffer array
				for (let i = 0; i < array_to_paste.length; i++) {

					if (array_to_paste[i]) {

						let element_id = parseInt(event_target.id) + i - 1;
						if (new_points[element_id.toString()]) {
							new_points[element_id.toString()].valid = is_valid_float_input(array_to_paste[i]);
							new_points[element_id.toString()].value = array_to_paste[i];
						}
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hours: new_points,
					changed: true,
				}));

				// This is necessary to prevent the default paste action.
				event.preventDefault();
			}
		}
	}


	function onKeyPress(event) {

		const event_target = event.target;

		if (event.charCode === 13) {

			// prevent defualt action
			event.preventDefault();

			// for elements with identified id
			if (event_target.id) {

				const pressed_id = parseInt(event_target.id);
				const pressed_value = event_target.value;
				const new_points = [ ...local_state.hours ];

				for (let i = 0; i < new_points.length; i++) {

					if (i > pressed_id - 1) {
						new_points[i].value = pressed_value;
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hours: new_points,
					changed: true,
				}));
			}
		}
	}


	async function saveHandler () {

		try {

			const new_data = {
				group_id: local_state.group_id,
				consumer_company_id: local_state.consumer_company_id,
				seller_company_id: local_state.seller_company_id,
				domain_id: local_state.domain_id,
				row_name: local_state.row_name,
				trade_date: getDateFormat(local_state.trade_date),
				p01: (local_state.hours.length > 0) ? local_state.hours[0].value : null,
				p02: (local_state.hours.length > 1) ? local_state.hours[1].value : null,
				p03: (local_state.hours.length > 2) ? local_state.hours[2].value : null,
				p04: (local_state.hours.length > 3) ? local_state.hours[3].value : null,
				p05: (local_state.hours.length > 4) ? local_state.hours[4].value : null,
				p06: (local_state.hours.length > 5) ? local_state.hours[5].value : null,
				p07: (local_state.hours.length > 6) ? local_state.hours[6].value : null,
				p08: (local_state.hours.length > 7) ? local_state.hours[7].value : null,
				p09: (local_state.hours.length > 8) ? local_state.hours[8].value : null,
				p10: (local_state.hours.length > 9) ? local_state.hours[9].value : null,
				p11: (local_state.hours.length > 10) ? local_state.hours[10].value : null,
				p12: (local_state.hours.length > 11) ? local_state.hours[11].value : null,
				p13: (local_state.hours.length > 12) ? local_state.hours[12].value : null,
				p14: (local_state.hours.length > 13) ? local_state.hours[13].value : null,
				p15: (local_state.hours.length > 14) ? local_state.hours[14].value : null,
				p16: (local_state.hours.length > 15) ? local_state.hours[15].value : null,
				p17: (local_state.hours.length > 16) ? local_state.hours[16].value : null,
				p18: (local_state.hours.length > 17) ? local_state.hours[17].value : null,
				p19: (local_state.hours.length > 18) ? local_state.hours[18].value : null,
				p20: (local_state.hours.length > 19) ? local_state.hours[19].value : null,
				p21: (local_state.hours.length > 20) ? local_state.hours[20].value : null,
				p22: (local_state.hours.length > 21) ? local_state.hours[21].value : null,
				p23: (local_state.hours.length > 22) ? local_state.hours[22].value : null,
				p24: (local_state.hours.length > 23) ? local_state.hours[23].value : null,
				p25: (local_state.hours.length > 24) ? local_state.hours[24].value : null
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		var data_valid = true;
		local_state.hours.forEach(item => {
			if (!item.valid) {
				document.getElementById(item.id).focus();
				data_valid = false;
			}
		});

		if (data_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.go(-1);
			}
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setConsumerLabelWidth(consumer_label.current.offsetWidth);
		setSellerLabelWidth(seller_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		if (plan_row_id) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { group_id, consumer_company_id, seller_company_id, domain_id, row_name, trade_date } = response.data;

						var result = [];
						for (let i = 1; i <= 25; i++) {

							const index = (i < 10) ? `p0${i}` : `p${i}`;

							if (response.data[index] !== null) {

								result.push({
									key: i,
									id: i.toString(),
									label: i.toString(),
									valid: true,
									value: parseFloat(response.data[index]),
								});
							}
						}

						setLocalState(prev_state => ({ ...prev_state,
							group_id: parseInt(group_id),
							consumer_company_id: parseInt(consumer_company_id),
							seller_company_id: parseInt(seller_company_id), 
							domain_id: parseInt(domain_id),
							row_name: row_name,
							trade_date: getDateFormat(trade_date),
							hours_count: result.length,
							hours: result,
							changed: false,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [plan_row_id, update_url])



	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}


	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={ item.company_id } value={ item.company_id } >{ item.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<Container className={ classes.root_form } component='main'>

			<Avatar className={ classes.avatar }>
				{ isNaN(plan_row_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(plan_row_id) ? 'Додати план' : 'Редагувати план' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Grid container spacing={2}>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={ domain_label_width }
								onChange={ handleDomainChange }
								value={ local_state.domain_id }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={ consumer_label } id='consumer-label'>Покупець</InputLabel>
							<Select
								native
								labelId='consumer-label'
								labelWidth={ consumer_label_width }
								onChange={ handleConsumerChange }
								value={ local_state.consumer_company_id }
								disabled={ local_state.group_id !== 0 }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={ seller_label } id='seller-label'>Продавець</InputLabel>
							<Select
								native
								labelId='seller-label'
								labelWidth={ seller_label_width }
								onChange={ handleSellerChange }
								value={ local_state.seller_company_id }
								disabled={ local_state.group_id !== 0 }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value={ local_state.trade_date }
							onChange={ handleTradeDateChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							fullWidth
							label='Назва'
							value={ local_state.row_name }
							onChange={ handleRowNameChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4} />

				</Grid>

				<div className={classes.input_form_control}>

					{ local_state.hours.map(item => {
						return (
							<TextField
								variant='outlined'
								margin='normal'
								size='small'
								className={ classes.hour_point }
								key={ item.key }
								id={ item.id }
								error={ !item.valid }
								label={ item.label }
								onChange={ onTextChangeHandler }
								onPaste={ onValuePaste }
								onKeyPress={ onKeyPress }
								value={ item.value }
							/>
						)
					}) }

				</div>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button_control }
						disabled={ !local_state.changed }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button_control }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}