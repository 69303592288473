
import { getDateFromLocalDateString } from './date_functions';


function get_doamins_by_direction(direction) {

	let out_domain, in_domain;

	switch (direction) {
		case 'UR':
		case 'Укр >> Ру':
			out_domain = 'UA_IPS';
			in_domain = 'RO';
			break;
		case 'RU':
			out_domain = 'RO';
			in_domain = 'UA_IPS';
			break;
		case 'US':
		case 'Укр >> Сл':
			out_domain = 'UA_IPS';
			in_domain = 'SK';
			break;
		case 'SU':
			out_domain = 'SK';
			in_domain = 'UA_IPS';
			break;
		case 'UH':
		case 'Укр >> Уг':
			out_domain = 'UA_IPS';
			in_domain = 'HU';
			break;
		case 'HU':
			out_domain = 'HU';
			in_domain = 'UA_IPS';
			break;
		case 'UB':
			out_domain = 'UA_IPS';
			in_domain = 'BY';
			break;
		case 'BU':
			out_domain = 'BY';
			in_domain = 'UA_IPS';
			break;
		case 'UF':
			out_domain = 'UA_IPS';
			in_domain = 'RU';
			break;
		case 'FU':
			out_domain = 'RU';
			in_domain = 'UA_IPS';
			break;
		// case '':
		// 	out_domain = '';
		// 	in_domain = '';
		// 	break;
	}

	return {
		out_domain: out_domain,
		in_domain: in_domain
	}
}


export function get_doamins_by_tab_name(direction) {

	let out_domain, in_domain;

	if (direction.indexOf('Україна → Румунія') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'RO';
	} else if (direction.indexOf('Румунія → Україна') === 0) {
		out_domain = 'RO';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Словаччина') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'SK';
	} else if (direction.indexOf('Словаччина → Україна') === 0) {
		out_domain = 'SK';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Угорщина') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'HU';
	} else if (direction.indexOf('Угорщина → Україна') === 0) {
		out_domain = 'HU';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Білорусь') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'BY';
	} else if (direction.indexOf('Білорусь → Україна') === 0) {
		out_domain = 'BY';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Росія') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'RU';
	} else if (direction.indexOf('Росія → Україна') === 0) {
		out_domain = 'RU';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Польща') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'PL';
	} else if (direction.indexOf('Польща → Україна') === 0) {
		out_domain = 'PL';
		in_domain = 'UA_IPS';
	} else if (direction.indexOf('Україна → Молдова') === 0) {
		out_domain = 'UA_IPS';
		in_domain = 'MO';
	} else if (direction.indexOf('Молдова → Україна') === 0) {
		out_domain = 'MO';
		in_domain = 'UA_IPS';
	} else {
		out_domain = '';
		in_domain = '';
	}

	return {
		out_domain: out_domain,
		in_domain: in_domain
	}
}


export function capacity_v1_from_json(json_doc) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	let auction_type;
	switch (json_doc[0][0].toLowerCase()) {
		case 'місяць':
			auction_type = 1;
			break;
		case 'рік':
			auction_type = 2;
			break;
		default:
			auction_type = 0;
			break;
	}

	if (auction_type === 0) {

		const companies_count = json_doc[1].length - 5;
		const date_str = json_doc[2][0];
		const period_start = getDateFromLocalDateString(date_str);
		const period_end = new Date(period_start);
		period_end.setHours(24, 0, 0, 0);

		for (let j = 0; j < companies_count; j++) {

			const points = [];
			for (let i = 2; i < json_doc.length; i++) {

				points.push({
					id: parseInt(json_doc[i][1]),
					os: json_doc[i][3],
					rs: json_doc[i][5 + j],
					price: json_doc[i][2]
				})
			}

			result.push({
				type_id: auction_type,
				number: undefined,
				out_domain: undefined,
				in_domain: undefined,
				period_start: period_start,
				period_end: period_end,
				company_name: json_doc[1][5 + j],
				points: points
			})
		}

	} else {

		const auction_count = json_doc[1].length - 2;
		const companies_count = json_doc.length - 5;

		// for each auction
		for (let i = 0; i < auction_count; i++) {

			const auction_str = json_doc[1][2 + i];
			const price = json_doc[2][2 + i];
			const os = json_doc[3][2 + i];

			const period_str = auction_str.substring(
				auction_str.lastIndexOf("(") + 1,
				auction_str.lastIndexOf(")")
			);

			const data_str = auction_str.slice(0, auction_str.lastIndexOf("("));
			const data_parts = data_str.split(/[\s+]/);
			const direction = data_parts[1];

			const period_parts = period_str.split(/[\s+][\-][\s+]/);
			const { out_domain, in_domain } = get_doamins_by_direction(direction);

			for (let j = 0; j < companies_count; j++) {

				result.push({
					type_id: auction_type,
					number: parseInt(data_parts[0]),
					out_domain: out_domain,
					in_domain: in_domain,
					period_start: getDateFromLocalDateString(period_parts[0]),
					period_end: getDateFromLocalDateString(period_parts[1]),
					company_name: json_doc[5 + j][1],
					points: [{
						id: 1,
						os: os,
						rs: json_doc[5 + j][2 + i],
						price: price
					}]
				})
			}
		}
	}

	return result;
}


export function capacity_v2_from_json(json_doc, auction_type, trade_date) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	if (auction_type === 0) {

		const companies_count = json_doc[0].length - 6;
		const period_start = new Date(trade_date);
		const period_end = new Date(period_start);
		period_end.setHours(24, 0, 0, 0);

		for (let j = 0; j < companies_count; j++) {

			const points = [];
			for (let i = 1; i < json_doc.length; i++) {

				points.push({
					id: parseInt(json_doc[i][0]),
					os: json_doc[i][1],
					rs: json_doc[i][6 + j],
					price: parseFloat(json_doc[i][4])
				})
			}

			result.push({
				type_id: auction_type,
				number: undefined,
				out_domain: undefined,
				in_domain: undefined,
				period_start: period_start,
				period_end: period_end,
				company_name: json_doc[0][6 + j],
				points: points
			});
		}

	} else {

		// define companies count
		let companies_count = 0;
		if (json_doc.length > 4) {
			for (let j = 5; j < json_doc.length; j++) {
				if (json_doc[j][0] !== 'Період скорочення продукту') {
					companies_count++;
				} else {
					break;
				}
			}
		}

		const period_start = new Date(trade_date);
		const period_end = new Date(period_start);
		period_end.setMonth(period_start.getMonth() + 1, -1);

		for (let j = 0; j < companies_count; j++) {

			result.push({
				type_id: auction_type,
				number: undefined,
				out_domain: undefined,
				in_domain: undefined,
				period_start: period_start,
				period_end: period_end,
				company_name: json_doc[5 + j][0],
				points: [{
					id: 1,
					os: json_doc[3][1],
					rs: json_doc[5 + j][1],
					price: parseFloat(json_doc[2][1]),
				}]
			});

		}
	}

	return result;
}


export function limits_from_json(json_doc) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	const date_info = json_doc[0][1];
	const date_parts = date_info.split(/[\s+]/);
	const date_str = date_parts[date_parts.length - 1];

	let summ_row = json_doc.length;
	for (let i = 0; i < json_doc.length; i++) {

		if (json_doc[i][0] !== undefined && json_doc[i][0].toLowerCase && json_doc[i][0].toLowerCase() === 'сумма') {
			summ_row = i;
			break;
		}
	}

	const column_count = json_doc[3].length;
	for (let c = 1; c < column_count;) {

		if (json_doc[2][c] !== undefined && typeof(json_doc[2][c]) === 'string' && json_doc[2][c].toLowerCase() === 'всього') {
			c += 3;
			continue;
		}

		if (c === 1 || c % 3 === 1) {
			c++;
			continue;
		}

		const a_data = json_doc[2][c - 1];
		const a_data_parts = a_data ? a_data.split(/[\s+]/) : undefined;

		const column = 12 * Math.floor(c / 12) + 1;
		const alternative_str = json_doc[1][column];

		let type, direction, number;
		if (a_data_parts) {

			if (a_data_parts.length > 0) {
				switch (a_data_parts[0].toLowerCase()) {
					case 'добова':
						type = 0;
						break;
					case 'місячна':
						type = 1;
						break;
					case 'річна':
						type = 2;
						break;
				}
			}

			if (type > 0) {
				if (a_data_parts.length > 2) {
					number = parseInt(a_data_parts[1]);
					direction = a_data_parts[2].replace(/[^a-zA-Z]/g, '');
				} else {
					direction = alternative_str;
				}
			} else {
				if (a_data_parts.length > 1) {
					direction = a_data_parts[1].replace(/[^a-zA-Z]/g, '');
				} else {
					direction = alternative_str;
				}
			}
		}

		const { out_domain, in_domain } = get_doamins_by_direction(direction);

		const points = [];

		for (let r = 1; r + 3 < summ_row; r++) {

			// const index = (r < 10) ? `p0${r}` : `p${r}`;
			// potential_limit[index] = json_doc[3 + r][c];

			points.push({
				id: r,
				value: Math.abs(parseFloat(json_doc[3 + r][c]))
			})
		}

		const potential_limit = {
			type_id: type,
			number: number,
			direction: direction,
			out_domain: out_domain,
			in_domain: in_domain,
			row_name: json_doc[3][c],
			limit_date: getDateFromLocalDateString(date_str),
			points: points
		}

		const total_auction_amount = parseInt(json_doc[summ_row][c - 1]);
		if (c % 3 !== 0 && direction && total_auction_amount !== 0) {
			result.push(potential_limit);
		}

		c++;
	}

	return result;
}
