import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function SelectTimeSeriesShortTable(props) {

	const classes = useStyles();

	const { rows, selected, setSelected, detail, setDetail } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');


	const head_cells = [
		{ id: 'id', alignTo: 'left', disablePadding: true, label: 'Назва' },
		{ id: 'status', alignTo: 'left', disablePadding: false, label: 'Статус' },
		{ id: 'seller', alignTo: 'left', disablePadding: false, label: 'Продавець' },
		{ id: 'consumer', alignTo: 'left', disablePadding: false, label: 'Покупець' },
		{ id: 'amount', alignTo: 'right', disablePadding: false, label: 'Обсяг, МВтг' },
		{ id: 'action', alignTo: 'right', disablePadding: false, label: '==' },
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};


	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};


	const handleView = (event, id) => {
		if (detail === id) {
			setDetail(0);
		} else {
			setDetail(id);
		}
	}


	const isSelected = (id) => selected.indexOf(id) !== -1;

	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={selected.length}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy)).map((row, index) => {

									const item_is_selected = isSelected(row.id);
									const label_id = `row-checkbox-${index}`;

									return (
										<TableRow
											hover
											key={row.key}
											role='checkbox'
											aria-checked={item_is_selected}
											selected={item_is_selected}
										>

											<TableCell padding='checkbox'>
												<Checkbox
													checked={item_is_selected}
													onClick={event => handleClick(event, row.id)}
													inputProps={{ 'aria-labelledby': label_id }}
												/>
											</TableCell>

											<TableCell component='th' id={label_id} scope='row' padding='none'>
												{row.id}
											</TableCell>

											<TableCell align='left'>{row.status}</TableCell>
											<TableCell align='left'>{row.seller}</TableCell>
											<TableCell align='left'>{row.consumer}</TableCell>
											<TableCell align='right'>{row.amount.toFixed(1)}</TableCell>

											<TableCell align='right' padding='checkbox'>
												<Checkbox
													checked={detail === row.id}
													onClick={event => handleView(event, row.id)}
												/>
											</TableCell>

										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


SelectTimeSeriesShortTable.propTypes = {
	rows: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
	detail: PropTypes.number.isRequired,
	setDetail: PropTypes.func.isRequired,
};