import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Avatar from '@material-ui/core/Avatar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import IdmOrdersTable from './subcomponents/IdmOrdersTable';
import OptionConfirmationDialog from '../subcomponents/OptionConfirmationDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { idmContext } from './idmContext';
import { is_valid_date } from '../utils/validation';
import { IdmOrderFromJson } from '../utils/idm_functions';
import { getISOStringFromDateString, getDateFormat, getLocalDateString, getLocalDateTimeString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	grid_1: {
		marginTop: theme.spacing(4),
		flexGrow: 1,
	},

	control_margin_top_1: {
		marginTop: theme.spacing(2),
	},

	filter_block: {
		padding: theme.spacing(2),
		border: '1px solid gray',
		borderRadius: '10px',
		alignItems: 'center',
	},

	control_margin_top_2: {
		marginTop: theme.spacing(1),
	},

	grid_2: {
		marginTop: theme.spacing(2),
		flexGrow: 1,
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function IdmImportExportForm(props) {
	const classes = useStyles();

	const trade_date = props.match.params.trade_date;

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// state for the import order
	const [imported_xlsx, setImportedXlsx] = useState(undefined);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	const [file_orders_selected, setFileOrdersSelected] = React.useState([]);
	const [db_orders_selected, setDbOrdersSelected] = React.useState([]);

	const [open_option_dialog, setOpenOptionDialog] = useState(false);

	// variables for the label control
	const domain_label = React.useRef(null);
	const trade_type_label = React.useRef(null);

	// state for label widths
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [trade_type_label_width, setTradeTypeLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, list_order } = useContext(idmContext);


	function createInitialState () {

		const complex_state = {

			filter_domain_id: 0,
			filter_trade_type_id: 0,

			file_trade_date: '',
			file_domain_id: 0,
			file_orders: [],

			db_trade_date: trade_date !== undefined ? trade_date : '',
			db_orders: [],

			update_flag: true,
		}

		return complex_state;
	}


	function handleImportFileChange (event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, range: 2 });
	
					// get order from xml document and store into state
					const import_xlsx_order = IdmOrderFromJson(json_doc);
					setImportedXlsx(import_xlsx_order);

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));
					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleFilterDomainChange (event) {

		const event_value = parseInt(event.target.value);

		setLocalState(prev_state => ({ ...prev_state,
			filter_domain_id: isNaN(event_value) ? 0 : event_value,
		}));
	}


	function handleFilterTradeTypeChange (event) {

		const event_value = parseInt(event.target.value);

		setLocalState(prev_state => ({ ...prev_state,
			filter_trade_type_id: isNaN(event_value) ? 0 : event_value,
		}));
	}


	function handleFileDomainChange (event) {

		const event_value = parseInt(event.target.value);

		setLocalState(prev_state => ({ ...prev_state,
			file_domain_id: isNaN(event_value) ? 0 : event_value,
		}));
	}


	function handleDbTradeDateChange (event) {

		const event_value = event.target.value;

		if (local_state.file_trade_date === '') {
			setLocalState(prev_state => ({ ...prev_state,
				db_trade_date: event_value
			}));
		}
	}


	async function save_order (order, link) {

		try {

			const new_data = {
				revision: 1,
				revision_id: 0,
				status_id: 1,

				sender_company_id: user.company_id,
				trade_date: getDateFormat(order.trade_date),
				domain_id: local_state.file_domain_id,
				trade_type_id: order.trade_type_id,

				period_from: (order.period_from !== '') ? parseInt(order.period_from) : null,
				period_to: (order.period_to !== '') ? parseInt(order.period_to) : null,
				validity_date: (order.validity_date !== '') ? getISOStringFromDateString(order.validity_date) : null,

				block_order_id: order.block_order_id,
				block_type_id: order.block_type_id,
				indication_id: order.indication_id,

				price: order.price,
				amount: order.amount,
				comment: order.comment,
			}

			if (new_data.period_from === null) {
				delete new_data.period_from;
			}
			if (new_data.period_to === null) {
				delete new_data.period_to;
			}
			if (new_data.validity_date === null) {
				delete new_data.validity_date;
			}

			const response = await axios.post(link, new_data);
			if (response.status === 200) {

				setLocalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	function handleImportOrdersSelected () {

		if (file_orders_selected.length > 0) {

			if (local_state.file_domain_id === 0) {

				window.alert('Для імпорту необхідно вибрати торгову зону.');
				return;
			}

			const import_orders = local_state.file_orders.filter(item => file_orders_selected.includes(item.order_id));
			import_orders.forEach(async (order) => {
				await save_order(order, '/api/idm/add');
			});

		} else {
			window.alert('Для імпорту необхідно вибрати заявки.');
		}
	}


	async function handleRewriteOrderSelected () {

		if (file_orders_selected.length === 1 && db_orders_selected.length === 1) {

			if (local_state.file_domain_id === 0) {

				window.alert('Для імпорту необхідно вибрати торгову зону.');
				return;
			}

			const update_link = '/api/idm/' + db_orders_selected[0];

			const order = local_state.file_orders.find(item => item.order_id === file_orders_selected[0]);
			if (order !== undefined) {
				await save_order(order, update_link);
			}

		} else {
			window.alert('Для перезапісу необхідно вибрати заявку із файлу імпорту та з існуючих заявок.');
		}
	}


	async function exportOrderToXlsx () {

		const json_order = [];

		const export_orders = local_state.db_orders.filter(item => db_orders_selected.includes(item.order_id));

		export_orders.forEach((order, index) => {

			let block_order;
			switch (order.block_order_id) {
				case 1:
					block_order = 'B';
					break;
				case 2:
					block_order = 'J';
					break;
				default:
					break;
			}

			const block_type = global_state.block_types.find(elem => elem.type_id === order.block_type_id);
			let block_type_code = (block_type !== undefined) ? block_type.type_code : '';
	
			const trade_type = global_state.trade_types.find(elem => elem.type_id === order.trade_type_id);
			let trade_type_code = (trade_type !== undefined) ? trade_type.type_code : '';

			const indication_type = global_state.indication_types.find(elem => elem.type_id === order.indication_id);
			let indication_type_code = (indication_type !== undefined) ? indication_type.type_code : '';

			json_order[index] = [];
			json_order[index][0] = getLocalDateString(order.trade_date);
			json_order[index][1] = order.period_from;
			json_order[index][2] = order.period_to;
			json_order[index][3] = block_order;
			json_order[index][4] = block_type_code;
			json_order[index][5] = trade_type_code;
			json_order[index][6] = indication_type_code;
			json_order[index][7] = order.price;
			json_order[index][8] = order.amount;
			json_order[index][9] = 'A';
			json_order[index][10] = (order.validity_date) ? getLocalDateTimeString(order.validity_date).replace(',', '') : '';
			json_order[index][11] = order.comment;
		})

		try {

			// download template file
			const temp_xlsx_doc = await axios.get('/docs/templates/IdmOrder.xlsx', { responseType: 'arraybuffer' });
			const wb = XLSX.read(temp_xlsx_doc.data, { type: 'array', cellStyles: true });
			const sheet = wb.Sheets[wb.SheetNames[0]];

			// add json to sheet
			XLSX.utils.sheet_add_json(sheet, json_order, { skipHeader: true, origin: 2 });

			// save workbook to file
			XLSX.writeFile(wb, `Export_IDM_${local_state.db_trade_date}.xlsx`);

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	async function handleOptionDialogClose (option_value) {
		setOpenOptionDialog(false);

		// continue export
		if (option_value === 'xml') {
			// await exportOrderToXml();
		}
		if (option_value === 'xlsx') {
			await exportOrderToXlsx();
		}
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setTradeTypeLabelWidth(trade_type_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		if (is_valid_date(local_state.db_trade_date)) {

			const doamin = (local_state.filter_domain_id !== 0) ? local_state.filter_domain_id : null

			axios.get('/api/idm', {
				params: {
					domain: doamin,
					period_start: getDateFormat(local_state.db_trade_date),
					period_end: getDateFormat(local_state.db_trade_date)
				}
			})
				.then(response => {
					setLocalState(prev_state => ({ ...prev_state,
						db_orders: response.data.orders_list,
					}));
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});
		}

	}, [local_state.filter_domain_id, local_state.db_trade_date, local_state.update_flag])


	useEffect(() => {

		if (imported_xlsx !== undefined) {

			let imported_orders = [];
			imported_xlsx.forEach((item, index) => {

				let block_order_id;
				switch (item.block_order) {
					case 'B':
						block_order_id = 1;
						break;
					case 'J':
						block_order_id = 2;
						break;
					default:
						block_order_id = 2;
						break;
				}

				let block_type_id;
				switch (item.block_type) {
					case '':
						block_type_id = 0;
						break;
					case 'BL':
						block_type_id = 1;
						break;
					case 'PL':
						block_type_id = 2;
						break;
					case 'OP':
						block_type_id = 3;
						break;
					case 'V':
						block_type_id = 4;
						break;
					default:
						block_type_id = 0;
						break;
				}

				let trade_type_id;
				switch (item.trade_type) {
					case 'N':
						trade_type_id = 1;
						break;
					case 'P':
						trade_type_id = 2;
						break;
					default:
						trade_type_id = 2;
						break;
				}

				let indication_id;
				switch (item.indication) {
					case 'N':
						indication_id = 1;
						break;
					case 'FOK':
						indication_id = 2;
						break;
					case 'IOC':
						indication_id = 3;
						break;
					case 'AON':
						indication_id = 4;
						break;
					default:
						indication_id = 1;
						break;
				}

				let trade_stage_id;
				switch (item.trade_stage) {
					case 'N':
						trade_stage_id = 1;
						break;
					case 'A':
						trade_stage_id = 2;
						break;
					default:
						trade_stage_id = 2;
						break;
				}

				imported_orders.push({
					order_id: index,
					trade_date: getDateFormat(item.trade_date),
					period_from: item.period_from,
					period_to: item.period_to,
					block_order_id: block_order_id,
					block_type_id: block_type_id,
					trade_type_id: trade_type_id,
					trade_stage_id: trade_stage_id,
					indication_id: indication_id,
					price: item.price,
					amount: item.amount,
					validity_date: item.validity_date,
					comment: item.comment,
				});
			})

			setLocalState(prev_state => ({ ...prev_state,
				file_trade_date: imported_orders[0].trade_date,
				file_orders: imported_orders,
				db_trade_date: imported_orders[0].trade_date,
			}));
		}

	}, [imported_xlsx, global_state])


	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id}>{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''}></option>)
	}

	var display_trade_types = [];
	if (global_state.trade_types.length > 0) {
		display_trade_types = global_state.trade_types.map(item => {
			return (
				<option key={item.type_id} value={item.type_id}>{item.type_comment}</option>
			)
		})
		display_trade_types.unshift(<option key={-1} value={''} ></option>)
	}

	var file_orders_rows = [];
	var db_orders_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) &&
		(global_state.companies.length > 0) && (global_state.block_orders.length > 0) &&
		(global_state.block_types.length > 0) && (global_state.indication_types.length > 0) &&
		(global_state.trade_types.length > 0) && (global_state.trade_stages.length > 0)) {

		if (local_state.file_orders.length > 0) {
			file_orders_rows = local_state.file_orders.map(item => list_order(item));
		}

		if (local_state.db_orders.length > 0) {
			db_orders_rows = local_state.db_orders.map(item => list_order(item));
		}
	}


	return (
		<div className={classes.root_form}>

			<Avatar className={classes.avatar}>
				<ImportExportIcon />
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				Імпорт та експорт заявок ВДР
			</Typography>

			<Grid container spacing={2} className={classes.grid_1}>

				<Grid item xs={6}>

					<Typography component='h2' variant='h5' align='center'>
						Файл імпорту
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						className={classes.control_margin_top_1}
						required
						variant='outlined'
						fullWidth
						type='file'
						label='Файл заявки ВДР'
						inputProps={{ accept: '.xlsx' }}
						onChange={ handleImportFileChange }
					/>

				</Grid>

				<Grid item xs={6}>

					<div className={classes.filter_block}>

						<Typography variant='body1' align='center'>
							Фільтр
						</Typography>

						<Grid container spacing={2} className={classes.control_margin_top_2}>

							<Grid item xs={6}>

								<FormControl
									variant='outlined'
									required
									fullWidth
								>
									<InputLabel ref={domain_label} id='domain-label'>Зона балансування</InputLabel>
									<Select
										native
										labelId='domain-label'
										labelWidth={domain_label_width}
										value={local_state.filter_domain_id}
										onChange={ handleFilterDomainChange }
									>
										{display_domains}
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={6}>

								<FormControl
									variant='outlined'
									required
									fullWidth
								>
									<InputLabel ref={trade_type_label} id='trade-type-label'>Тип торгівлі</InputLabel>
									<Select
										native
										labelId='trade-type-label'
										labelWidth={trade_type_label_width}
										value={local_state.filter_trade_type_id}
										onChange={ handleFilterTradeTypeChange }
									>
										{display_trade_types}
									</Select>
								</FormControl>

							</Grid>

						</Grid>

					</div>

				</Grid>

			</Grid>

			<Grid container spacing={2} className={classes.grid_2}>

				<Grid item xs={6}>

					<Typography component='h2' variant='h5' align='center'>
						Заявки ВДР з файлу імпорта
					</Typography>

				</Grid>

				<Grid item xs={6}>

					<Typography component='h2' variant='h5' align='center'>
						Існуючі заявки ВДР
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						required
						fullWidth
						label='Доба торгівлі'
						type='date'
						value={local_state.file_trade_date}
						InputLabelProps={{ shrink: true }}
						disabled={local_state.file_trade_date !== ''}
					/>

				</Grid>

				<Grid item xs={3}>

					<FormControl
						variant='outlined'
						required
						fullWidth
					>
						<InputLabel ref={domain_label} id='domain-label'>Зона балансування</InputLabel>
						<Select
							native
							labelId='domain-label'
							labelWidth={domain_label_width}
							value={local_state.file_domain_id}
							onChange={ handleFileDomainChange }
						>
							{display_domains}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						required
						fullWidth
						label='Доба торгівлі'
						type='date'
						value={local_state.db_trade_date}
						InputLabelProps={{ shrink: true }}
						disabled={local_state.file_trade_date !== ''}
						onChange={handleDbTradeDateChange}
					/>

				</Grid>

				<Grid item xs={3} />

				<Grid item xs={6} >

					<IdmOrdersTable
						rows={file_orders_rows}
						selected={file_orders_selected}
						setSelected={setFileOrdersSelected}
					/>

				</Grid>

				<Grid item xs={6} >

					<IdmOrdersTable
						rows={db_orders_rows}
						selected={db_orders_selected}
						setSelected={setDbOrdersSelected}
					/>

				</Grid>

			</Grid>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					disabled={ file_orders_selected.length === 0 }
					onClick={ handleImportOrdersSelected }
				>
					Імпортувати відмічені заявки
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					disabled={ file_orders_selected.length !== 1 || db_orders_selected.length !== 1 }
					onClick={handleRewriteOrderSelected}
				>
					Переписати існуючу заявку
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					disabled={ db_orders_selected.length === 0 }
					onClick={() => { setOpenOptionDialog(true) }}
				>
					Експорт
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className={classes.button_control}
					onClick={() => props.history.go(-1)}
				>
					Скасувати
				</Button>

			</div>

			<OptionConfirmationDialog
				keepMounted
				open={ open_option_dialog }
				onClose={ handleOptionDialogClose }
				title= { 'Експорт заявки. Вибіріть формат експорту' }
				options= { [ 'xlsx' ] }
				init_value={ 'xlsx' }
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</div>
	);
}