import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import ContractEditForm from './ContractEditForm';
import GroupEditForm from './EsGroupEditForm';

import ContractTable from './subcomponents/ContractTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { contractsContext } from './contractsContext';
import { getLocalDateString } from '../utils/date_functions';

import NotAuthorised from '../auth/NotAuthorised';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function ContractsForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = {

		update_flag: false,

		companies: [],
		contracts: [],
	};
	const [global_state, setGlobalState] = useState(initial_state);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function addItemHandler() {
		props.history.push('/contracts/add');
	} 


	function onErrorMessageClose () {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	useEffect(() => {

		axios.get('/api/admin')
			.then(response => {

				setGlobalState(prev_state => ({ ...prev_state,
					companies: response.data.company_list,
					contracts: response.data.contract_list,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [global_state.update_flag]);


	// define variables for items
	var contracts_rows = [];
	if ((global_state.contracts.length > 0) && (global_state.companies.length > 0)) {
		contracts_rows = global_state.contracts.map(item => {
				return ({
					key: item.contract_id,
					id: item.contract_id,
					number: item.contract_number,
					date: getLocalDateString(item.contract_date),
					valid_date: getLocalDateString(item.valid_date),
					consumer: global_state.companies.find(company => company.company_id === item.consumer_id).name,
					seller: global_state.companies.find(company => company.company_id === item.seller_id).name,
				})
			})
	};


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 6)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<contractsContext.Provider value={{ global_state, setGlobalState }}>
				<Switch>
					<Route
						path='/contracts/:contract_id/group/:group_id'
						component= { GroupEditForm }
						exact
					/>
					<Route
						path='/contracts/add'
						component= { ContractEditForm }
					/>
					<Route
						path='/contracts/:contract_id'
						component= { ContractEditForm }
					/>
					<Route path='/contracts'>

						<div className = { classes.root }>

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Двосторонні договори
									</Typography>
									
									<Tooltip title='Додати'>
										<IconButton
											onClick={ addItemHandler }
											color='inherit'
										>
											<AddIcon />
										</IconButton>
									</Tooltip>

									<IconButton
										className={classes.right_button}
										onClick={ () => props.history.push('/') }
										color='inherit'
									>
										<BackIcon />
									</IconButton>

								</Toolbar>
							</AppBar>

							<ContractTable
								rows= { contracts_rows }
							/>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ '' }
							onClose={ onErrorMessageClose }
						/>

					</Route>
				</Switch>
			</contractsContext.Provider>
		)
	}
}

export default withRouter(ContractsForm)