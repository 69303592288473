import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { planningContext } from './planningContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat, getDateTimeFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_top_margin: {
		marginTop: theme.spacing(2),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	container: {
		marginTop: theme.spacing(2),
		minWidth: '1000px',
		display: 'flex',
		flexDirection: 'column',
	},

}));


export default function PlanRowEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label
	const domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(planningContext);

	// get forecast id from url
	const group_id = parseInt(props.match.params.group_id);
	const update_url = isNaN(group_id) ? '/api/planning/group/add' : '/api/planning/group/' + group_id;


	function createInitialState () {

		const complex_state = {
			create_date: '', 
			period_start: '',
			period_end: '',
			domain_id: 0,
			consumer_company_id: 0,
			seller_company_id: 0,

			group_payment_days: 0,
			payment_days_valid: true,
			group_price: 0,
			price_valid: true,
			group_discount_percent: 0,
			discount_percent_valid: true,
			group_discount: 0,
			discount_valid: true,
			group_comment: '',

			changed: false,
		}

		return complex_state;
	}


	function handlePaymentDaysChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			group_payment_days: event_value,
			payment_days_valid: !isNaN(parseInt(event_value)) || event_value === '',
			changed: true,
		}));
	}


	function handlePriceChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			group_price: event_value,
			price_valid: is_valid_float_input(event_value) || event_value === '',
			changed: true,
		}));
	}


	function handleDiscountPercentChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			group_discount_percent: event_value,
			discount_percent_valid: is_valid_float_input(event_value) || event_value === '',
			changed: true,
		}));
	}


	function handleDiscountChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			group_discount: event_value,
			discount_valid: is_valid_float_input(event_value) || event_value === '',
			changed: true,
		}));
	}


	function handleCommentChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			group_comment: event_value,
			changed: true,
		}));
	}


	async function saveHandler () {

		try {

			const new_data = {
				payment_days: (local_state.group_payment_days === '') ? undefined : local_state.group_payment_days,
				price: (local_state.group_price === '') ? undefined : local_state.group_price,
				discount_percent: (local_state.group_discount_percent === '') ? undefined : local_state.group_discount_percent,
				discount: (local_state.group_discount === '') ? undefined : local_state.group_discount,
				comment: local_state.group_comment
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		var data_valid = local_state.group_discount !== '' ||
						local_state.group_price !== '' ||
						local_state.group_discount_percent !== '';

		if (data_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.go(-1);
			}
		} else {

			window.alert('Перевірте правільність даних.');
		}
	}

	
	function onCancelHandler () {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setConsumerLabelWidth(consumer_label.current.offsetWidth);
		setSellerLabelWidth(seller_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		async function fetchData(update_url) {

			try {

				const response = await axios.get(update_url);

				const { create_date, period_start, period_end, domain_id, consumer_company_id, seller_company_id,
					payment_days, price, discount_percent, discount, comment } = response.data;

				setLocalState(prev_state => ({ ...prev_state,
					create_date: getDateTimeFormat(create_date),
					period_start: getDateFormat(period_start),
					period_end: getDateFormat(period_end),
					domain_id: parseInt(domain_id),
					consumer_company_id: parseInt(consumer_company_id),
					seller_company_id: parseInt(seller_company_id), 

					group_payment_days: (payment_days === null) ? '' : parseInt(payment_days),
					group_price: (price === null) ? '' : price,
					group_discount_percent: (discount_percent === null) ? '' : discount_percent,
					group_discount: (discount === null) ? '' : discount,
					group_comment: (comment === null) ? '' : comment,

					changed: false,
				}));

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (!isNaN(group_id)) {
			fetchData(update_url);
		}

	}, [group_id, update_url])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={ item.company_id } value={ item.company_id } >{ item.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<Container className={ classes.root_form } component='main' maxWidth='sm'>

			<Avatar className={ classes.avatar }>
				{ isNaN(group_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>

			<Typography className={classes.control_bottom_margin} component='h3' variant='h6' align='center'>
				{ isNaN(group_id) ? 'Додати групу' : 'Редагувати групу' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Grid container spacing={2}>

					<Grid item xs={6}>

						<TextField
							variant='outlined'
							fullWidth
							label='Дата створення'
							type='datetime-local'
							disabled={ true }
							value={ local_state.create_date }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={6}>

						<FormControl
							variant='outlined'
							fullWidth
						>
							<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={ domain_label_width }
								value={ local_state.domain_id }
								disabled={ true }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6}>

						<FormControl
							variant='outlined'
							fullWidth
						>
							<InputLabel ref={ consumer_label } id='consumer-label'>Покупець</InputLabel>
							<Select
								native
								labelId='consumer-label'
								labelWidth={ consumer_label_width }
								value={ local_state.consumer_company_id }
								disabled={ true }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6}>

						<FormControl
							variant='outlined'
							fullWidth
						>
							<InputLabel ref={ seller_label } id='seller-label'>Продавець</InputLabel>
							<Select
								native
								labelId='seller-label'
								labelWidth={ seller_label_width }
								value={ local_state.seller_company_id }
								disabled={ true }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6}>

						<TextField
							variant='outlined'
							fullWidth
							label='Початок періоду'
							type='date'
							value={ local_state.period_start }
							InputLabelProps={{ shrink: true }}
							disabled={ true }
						/>

					</Grid>

					<Grid item xs={6}>

						<TextField
							variant='outlined'
							fullWidth
							label='Кінець періоду'
							type='date'
							value={ local_state.period_end }
							InputLabelProps={{ shrink: true }}
							disabled={ true }
						/>

					</Grid>

				</Grid>

				<TextField
					className={classes.control_top_margin}
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Період сплати'
					onChange={ handlePaymentDaysChange }
					error={ !local_state.payment_days_valid }
					value={ local_state.group_payment_days }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Фіксована ціна'
					onChange={ handlePriceChange }
					error={ !local_state.price_valid }
					value={ local_state.group_price }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Дісконт, %'
					onChange={ handleDiscountPercentChange }
					error={ !local_state.discount_percent_valid }
					value={ local_state.group_discount_percent }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Дісконт, грн'
					onChange={ handleDiscountChange }
					error={ !local_state.discount_valid }
					value={ local_state.group_discount }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Коментар'
					onChange={ handleCommentChange }
					value={ local_state.group_comment }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button_control }
						disabled={ !local_state.changed }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button_control }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>


		</Container >
	)
}