import React from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import FilterIcon from '@material-ui/icons/FilterList';


const useStyles = makeStyles(theme => ({

	filter: {
		position: 'relative',
		marginLeft: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
	},

	filter_label: {
		padding: theme.spacing(1),
	},

	filter_back: {
		backgroundColor: fade(theme.palette.common.white, 0.5),
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},

	filter_input: {
		padding: theme.spacing(1),
		width: '100%',
	},

}));


export default function DateDomainFilter(props) {
	const classes = useStyles();

	return (
		<div className={classes.filter}>

			<label
				className={ classes.filter_label }
				htmlFor='date-domain-filter-date'
			>
				Дата
			</label>

			<InputBase
				id='date-domain-filter-date'
				type='date'
				classes={{
					root: classes.filter_back,
					input: classes.filter_input
				}}
				value={props.date}
				onChange={(e) => { props.onFilterChange(e.target.value, props.domain) }}
			/>

			<label
				className={classes.filter_label}
				htmlFor='date-domain-filter-domain'
			>
				Торгова зона
			</label>

			<Select
				id='date-domain-filter-domain'
				native
				classes={{
					root: classes.filter_back,
					select: classes.filter_input,
				}}
				value={ props.domain }
				onChange={(e) => { props.onFilterChange(props.date, e.target.value) } }
			>
				{ props.domain_list }
			</Select>

			<IconButton
				onClick={ props.onFilterClick }
				color='inherit'
			>
				<FilterIcon />
			</IconButton>

		</div>
	)
};


DateDomainFilter.propTypes = {
	date: PropTypes.string.isRequired,
	domain: PropTypes.number.isRequired,
	domain_list: PropTypes.array.isRequired,
	onFilterChange: PropTypes.func.isRequired,
	onFilterClick: PropTypes.func.isRequired,
};