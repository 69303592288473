import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({

	container: {
		textAlign: 'center'
	},

	root: {
		display: 'inline-block',
		margin: theme.spacing(2),
	},
}));


export default function WaitDialog(props) {

	const classes = useStyles();
	const { open } = props;

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth='xs'
			aria-labelledby='confirmation-dialog-title'
			open={open}
		>
			<DialogTitle id='confirmation-dialog-title'>Будь ласка, зачекайте</DialogTitle>

			<DialogContent className={classes.container}>

				<div className={classes.root}>
					<CircularProgress />
				</div>

			</DialogContent>
		</Dialog>
	);
}

WaitDialog.propTypes = {
	open: PropTypes.bool.isRequired,
};