import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import AuthIcon from '@material-ui/icons/VpnKeyTwoTone';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { adminContext } from './adminContext';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	margin_above: {
		marginTop: theme.spacing(4),
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(2, 0.5),
		display: 'inline-block',
	},

}));


const UserDefinedSwitch = withStyles({

		switchBase: {
			color: '#f1f8e9',
			'&$checked': {
				color: '#76ff03',
		},

		'&$checked + $track': {
			backgroundColor: '#ccff90',
		},
	},

	checked: {},
	track: {},

}) (Switch);


export default function UserAuthForm(props) {

	const classes = useStyles();

	const { setGlobalState } = useContext(adminContext);

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	// states for input elements
	const initial_state = {
		// internal states
		user_last_name: '',
		user_first_name: '',
		user_email: '',
		user_auth: 0,
		auth_list: [],
	};
	const [local_state, setLocalState] = useState(initial_state);


	// get user id from url
	const user_id = parseInt(props.match.params.user_id);

	// check user id
	if (!user_id) {
		props.history.go(-1);
	}

	const read_url = '/api/admin/user/' + user_id;
	const update_url = '/api/admin/user/' + user_id + '/auth';


	const reverse_authorisation = async (id) => {

		var authorisation = local_state.user_auth;

		var update_value = 1 << (id - 1);
		const current_state = (authorisation & update_value) !== 0;

		if (current_state) {
			update_value = ~update_value;
			authorisation &= update_value;
		} else {
			authorisation |= update_value;
		}

		setLocalState(prev_state => ({ ...prev_state,
			user_auth: authorisation,
		}))
	}


	const is_authorised = (id) => {
		return (local_state.user_auth & (1 << (id - 1))) !== 0;
	}


	const onCancelHandler = () => {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	async function SaveUserHandler () {

		const user_data = {
			status: local_state.user_auth,
		};

		try {

			const response = await axios.post(update_url, user_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({
					...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		if (await SaveUserHandler()) {
			// return to parent page
			props.history.push('/admin');
		}
	}


	useEffect(() => {

		async function fetchData (read_url, update_url) {

			try {

				const user_result = await axios.get(read_url);
				const auth_result = await axios.get(update_url);
				const auth_list_result = await axios.get('/api/admin/authorisations');

				setLocalState(prev_state => ({ ...prev_state,
					user_last_name: user_result.data.user.last_name,
					user_first_name: user_result.data.user.first_name,
					user_email: user_result.data.user.email,
					user_auth: auth_result.data.authorisation,
					auth_list: auth_list_result.data.authorisation_list,
				}))

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData(read_url, update_url);

	}, [read_url, update_url])


	return (

		<Container className={ classes.paper } component='main' maxWidth='xs'>

			<Avatar className={ classes.avatar }>
				<AuthIcon />
			</Avatar>
			<Typography component='h1' variant='h5'>
				Права доступу користувача
			</Typography>

			<form onSubmit={ onSubmitHandler } className={ classes.margin_above }>

				<Typography component='h1' variant='h5'>
					{ local_state.user_first_name } { local_state.user_last_name }
				</Typography>

				<Typography component='h1' variant='h5'>
					{ local_state.user_email }
				</Typography>

				<FormControl component='fieldset' className={ classes.margin_above }>

					<FormLabel component='legend'>Права доступу користувача</FormLabel>
					<FormGroup>

						{
							local_state.auth_list.map(item => {

								return (
									<FormControlLabel
										control={
											<UserDefinedSwitch
												checked={is_authorised(item.section_id)}
												onChange={ () => reverse_authorisation(item.section_id)}
												name={ item.section_name }
											/>
										}
										label={ item.section_name }
										key={ item.section_id }
									/>
								)
							})
						}

					</FormGroup>
				</FormControl>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>
					
				</div>
			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>

	);
}