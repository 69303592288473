import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/EditSharp';
import AuthIcon from '@material-ui/icons/VpnKeySharp';
import BlockIcon from '@material-ui/icons/LockOpenSharp';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import FilterSearch from '../../subcomponents/FilterSearch';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

	selected: {
		background: '#76ff03',
	},

	non_selected: {
		background: 'white',
	},

}));


export default function UserTable(props) {

	const classes = useStyles();

	const { rows, logged_in, show_edit_action, show_admin_actions } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');

	const init_filter = {
		last_name: '',
		first_name: '',
		company: '',
		email: '',
		phone: '',
		role: '',
	};
	const [filter, setFilter] = React.useState(init_filter);

	const head_cells = [
		{ id: 'last_name', alignTo: 'left', disablePadding: false, label: 'Прізвище', filter_by: filter.last_name },
		{ id: 'first_name', alignTo: 'left', disablePadding: false, label: 'Ім\'я', filter_by: filter.first_name },
		{ id: 'company', alignTo: 'left', disablePadding: false, label: 'Компанія', filter_by: filter.company },
		{ id: 'email', alignTo: 'left', disablePadding: false, label: 'Email', filter_by: filter.email },
		{ id: 'phone', alignTo: 'left', disablePadding: false, label: 'Телефон', filter_by: filter.phone },
		{ id: 'actions', alignTo: 'right', disablePadding: false, label: 'Дії' }
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleFilterChange = (id, event) => {

		const new_filter = { ...filter };
		new_filter[id] = event.target.value;

		setFilter(new_filter);
	}


	const handleFilterDelete = () => {
		const new_filter = { ...filter };
		Object.keys(new_filter).forEach(key => {
			new_filter[key] = '';
		});
		setFilter(new_filter);
	}


	const is_logged_in = (id) => logged_in.indexOf(id) !== -1;


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onRequestSort={handleRequestSort}
						disable_checkbox={true}
					/>

					<TableBody>
						<TableRow>
							{
								head_cells.map(item => (
									(item.id !== 'actions') ?
									<TableCell
										key={ item.id }
									>
										<FilterSearch
											value={ item.filter_by }
											onSearchChange={ (event) => handleFilterChange(item.id, event) }
										/>
									</TableCell>
									:
									<TableCell
										key={ item.id }
									>
										<IconButton
											onClick={ handleFilterDelete }
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								))
							}
						</TableRow>
					</TableBody>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy))
								.filter(row => row.last_name.toLowerCase().indexOf(filter.last_name) !== -1)
								.filter(row => row.first_name.toLowerCase().indexOf(filter.first_name) !== -1)
								.filter(row => row.company.toLowerCase().indexOf(filter.company) !== -1)
								.filter(row => row.email.toLowerCase().indexOf(filter.email) !== -1)
								.filter(row => row.phone.indexOf(filter.phone) !== -1)
								.map(row => (
										<TableRow
											hover
											key={row.key}
											className={ is_logged_in(row.id) ? classes.selected : classes.non_selected }
										>
											<TableCell scope='row'>{row.last_name}</TableCell>
											<TableCell align='left'>{row.first_name}</TableCell>
											<TableCell align='left'>{row.company}</TableCell>
											<TableCell align='left'>{row.email}</TableCell>
											<TableCell align='left'>{row.phone}</TableCell>
											<TableCell align='right'>
												{
													((show_edit_action === undefined) || ((show_edit_action !== undefined) && show_edit_action)) &&
													<NavLink to={ 'admin/user/' + row.id }>
														<EditIcon />
													</NavLink>
												}
												{
													show_admin_actions &&
													<>
														<NavLink to={ 'admin/user/' + row.id + '/auth' }>
															<AuthIcon />
														</NavLink>
														<NavLink to={ 'admin/user/' + row.id + '/block' }>
															<BlockIcon />
														</NavLink>
													</>
												}
											</TableCell>
										</TableRow>
									)
								)
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


UserTable.propTypes = {
	rows: PropTypes.array.isRequired,
	logged_in: PropTypes.array,
	show_edit_action: PropTypes.bool,
	show_admin_actions: PropTypes.bool,
};