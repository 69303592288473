import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		marginTop: theme.spacing(2),
	},

	table: {
		minWidth: 750,
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 1),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

}));

var row_id;

export default function EsGroupRowsTable(props) {

	const classes = useStyles();

	const { rows, action_list, onActionSelect } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);


	const head_cells = [
		{ id: 'period_start', alignTo: 'left', disablePadding: true, label: 'Період з' },
		{ id: 'period_end', alignTo: 'left', disablePadding: true, label: 'Період до' },
		{ id: 'capacity_price', alignTo: 'left', disablePadding: true, label: 'Ціна перетину' },
		{ id: 'external_tax', alignTo: 'left', disablePadding: true, label: 'Зовнішній налог' },
		{ id: 'income_ratio', alignTo: 'left', disablePadding: true, label: 'Коефіціент доходу' },
		{ id: 'additional_expenses', alignTo: 'left', disablePadding: true, label: 'Додаткові розходи' },
	];


	if (onActionSelect !== undefined) {
		head_cells.push(
			{ id: 'action', alignTo: 'right', disablePadding: false, label: 'Дія' }
		)
	}


	const handleOpenMenu = (event, id) => {
		// store order id
		row_id = id
		setAnchorEl(event.currentTarget);
	};


	const handleCloseMenu = (id) => {

		if (onActionSelect !== undefined) {
			onActionSelect(id, row_id)
		}
		setAnchorEl(null);
	};


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<TableHead>
						<TableRow>
							{
								head_cells.map(item => (
									<TableCell
										key={item.id}
										className={classes.head}
										align={item.alignTo}
										padding={item.disablePadding ? 'none' : 'default'}
									>
										{item.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>

					<TableBody>
						{
							rows.map((row, row_index) => {

									return (
										<TableRow
											hover
											size='small'
											role='checkbox'
											key={row.group_id}
										>

											<TableCell
												key={ `row-${row_index}-period_start` }
											>
												{ getLocalDateString(row.period_start) }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-period_end` }
											>
												{ getLocalDateString(row.period_end) }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-cap_price` }
											>
												{ row.capacity_price }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-external_tax` }
											>
												{ row.external_tax }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-income_ratio` }
											>
												{ row.income_ratio }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-additional_expenses` }
											>
												{ row.additional_expenses }
											</TableCell>

											{ (onActionSelect !== undefined) &&
												<TableCell align='right'>
													<IconButton
														size='small'
														onClick={(event) => handleOpenMenu(event, row.group_id)}
														color='inherit'
													>
														<ActionIcon />
													</IconButton>
												</TableCell>
											}
										</TableRow>
									)
							})
						}
					</TableBody>

				</Table>
			</TableContainer>

			{
				(action_list !== undefined) &&
				<Menu
					id='group-rows-simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					{
						action_list.map(item => 
							<MenuItem key={item.id} onClick={() => handleCloseMenu(item.id)}>{ item.name }</MenuItem>
						)
					}
				</Menu>
			}

		</Paper>
	);
}


EsGroupRowsTable.propTypes = {
	rows: PropTypes.array.isRequired,
	action_list: PropTypes.array,
	onActionSelect: PropTypes.func
};