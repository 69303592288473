import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import OpenIcon from '@material-ui/icons/EditSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


export default function CapacityLimitTable(props) {

	const classes = useStyles();

	const { rows, onLimitAction } = props;

	const head_cells = [
		{ id: 'limit_date', alignTo: 'left', disablePadding: false, label: 'Дата обмеження' },
		{ id: 'open', alignTo: 'right', disablePadding: false, label: 'Відкрити' },
		{ id: 'delete', alignTo: 'right', disablePadding: false, label: 'Видалити' }
	];


	return (
		<Paper className={classes.paper}>

			<TableContainer>
				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={'asc'}
						order_by={''}
						onSelectAllClick={() => {}}
						onRequestSort={() => {}}
					/>

					<TableBody>
						{
							rows.map((row, row_index) => (
								<TableRow
									hover
									key={row.capacity_limit_id}
								>

									<TableCell
										key={ `row-${row_index}-empty` }
									/>

									<TableCell
										key={ `row-${row_index}-date` }
										scope='row'
									>
										{ getLocalDateString(row.limit_date) }
									</TableCell>

									<TableCell
										key={ `row-${row_index}-open` }
										align='right'
									>
										<IconButton
											onClick={ () => onLimitAction(0, row.capacity_limit_id) }
										>
											<OpenIcon />
										</IconButton>
									</TableCell>

									<TableCell
										key={ `row-${row_index}-delete` }
										align='right'
									>
										<IconButton
											onClick={ () => onLimitAction(1, row.capacity_limit_id) }
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>

								</TableRow>
							))
						}
					</TableBody>

				</Table>
			</TableContainer>

		</Paper>
	);
}


CapacityLimitTable.propTypes = {
	rows: PropTypes.array.isRequired,
	onLimitAction: PropTypes.func.isRequired
};