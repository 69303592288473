import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
		fontWeight: 'bold',
	},

	block: {
		border: '1px solid gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	row_block: {
		border: '1px dotted green',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	target_row: {
		background: 'lightgreen'
	},

	source_row: {
		background: 'yellow',
		fontWeight: 'bold'
	},

	not_selected: {
		background: 'none'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function GroupSelectionDialog(props) {

	const classes = useStyles();
	const { open, email_content, onClose, onRowUpdate } = props;


	const handleCheckBoxClick = (email_index, target_row_id, source_row_id) => {

		const target_row = email_content[email_index].db_rows.find(item => item.plan_row_id === target_row_id);
		if (target_row !== undefined) {
			target_row.source_row_id = source_row_id;

			onRowUpdate();
		}
	}


	const onYesHandler = () => {

		const result = [];

		for (let i = 0; i < email_content.length; i++) {
			for (let j = 0; j < email_content[i].db_rows.length; j++) {

				const result_row = email_content[i].db_rows[j];

				if (result_row.source_row_id > 0) {

					const source_row = email_content[i].email_rows.find(item => item.row_id === result_row.source_row_id);
					if (source_row !== undefined) {

						result_row.trade_date = source_row.trade_date;

						result_row.points.splice(0, result_row.points.length)
						result_row.points.push(...source_row.points);

						result.push(result_row);
					}
				}
			}
		}

		onClose(result);
	};


	const onCancelHandler = () => {
		onClose(undefined);
	};


	let rows = 0;
	let selected = 0;
	for (let i = 0; i < email_content.length; i++) {
		for (let j = 0; j < email_content[i].db_rows.length; j++) {
			rows += 1;
			selected += email_content[i].db_rows[j].source_row_id > 0 ? 1 : 0;
		}
	}

	var all_selected = (rows > 0 && selected > 0);


	return (
		<Dialog
			open={open}
			maxWidth='xl'
			aria-labelledby='group-dialog-title'
		>
			<DialogTitle id='group-dialog-title' className={classes.header} >
				Запис рядів планування з email
			</DialogTitle>

			<DialogContent>

				{
					email_content.map((pair, pair_index) => (
						<div
							key={ pair_index }
							className={ classes.block }
						>

							<table>
								<tbody>
									<tr>
										<td>
											<Typography>
												<b>Продавець</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{ pair.seller_name }
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Покупець</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{ pair.consumer_name }
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Зона торгівлі</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{ pair.domain_name }
											</Typography>
										</td>
									</tr>
								</tbody>
							</table>

							<br/>

							{
								(pair.db_rows.length > 0) &&
								<Typography>
									Існуючі планові ряди в базі даних PowerTrade:
								</Typography>
							}

							<br/>

							{
								pair.db_rows.map((row, row_index) => (
									<div
										key={ row_index }
										className={ classes.row_block }
									>

										<Typography>
											<u>Збережений ряд</u>:
										</Typography>

										<br/>

										<Typography>
											<b>Дата</b>: { getLocalDateString(row.trade_date) }
										</Typography>

										<table
											key={`header-table-${row_index}`}
											className={ classes.target_row }
										>
											<thead>
												<tr>
													<th key='head'>Година</th>
													{ row.points.map(item => <th key={`head-${item.index}`}>{ item.index }</th>) }
													<th key='sum'>Всього</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<th key='value'>Обсяг, МВтг</th>
													{ row.points.map(item => <td key={`row-${item.index}`}>{ item.value.toFixed(3) }</td>) }
													<td><b>{ row.sum.toFixed(3) }</b></td>
												</tr>
											</tbody>
										</table>

										<br/>

										<Typography>
											<u>Переписати цей плановий ряд наступним рядом з email</u>:
										</Typography>

										<br/>

										{
											(pair.email_rows.length > 0) &&
											<table
												key={`email-rows-table-${row_index}`}
											>
												<thead>
													<tr>
														<th></th>
														<th key='head'>Година</th>
														{ pair.email_rows[0].points.map(item => <th key={`head-${item.index}`}>{ item.index }</th>) }
														<th key='sum'>Всього</th>
													</tr>
												</thead>
												<tbody>
													{
														pair.email_rows.map((erow, erow_index) => (
														<tr
															key={erow_index}
															className={ erow.row_id === row.source_row_id ? classes.source_row : classes.not_selected }
														>
															<th>
																<Checkbox
																	key={`check-${erow_index}`}
																	checked={ erow.row_id === row.source_row_id }
																	onClick={() => handleCheckBoxClick(pair_index, row.plan_row_id, erow.row_id)}
																/>
															</th>
															<th key='value'>Обсяг, МВтг</th>
															{ erow.points.map(item => <td key={`row-${item.index}`}>{ item.value.toFixed(3) }</td>) }
															<td><b>{ erow.sum.toFixed(3) }</b></td>
														</tr>
														))
													}
												</tbody>
											</table>
										}
									</div>
								))
							}

							{
								(pair.db_rows.length === 0) &&
								<Typography>
									Існуючих планових рядів в базі даних PowerTrade не знайдено.
								</Typography>
							}

						</div>
					))
				}

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { onYesHandler }
						disabled = { !all_selected }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick = { onCancelHandler }
					>
						Відміна
					</Button>

				</div>

			</DialogContent>
		</Dialog>
	);
}

GroupSelectionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	email_content: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onRowUpdate: PropTypes.func.isRequired
};