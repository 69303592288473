import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EventsView from './subcomponents/EventsView';
import MarketInfoFrom from './MarketInfoForm';
import HelpRequestForm from './HelpRequestForm';
import { getLocalDateTimeString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	place_holder: {
		margin: theme.spacing(3),
	},

}));


function HelpForm(props) {

	const classes = useStyles();

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = {
		events: []
	};
	const [global_state, setGlobalState] = useState(initial_state);


	function getTabsProps(index) {
		return {
			id: `help-tab-${index}`,
			'aria-controls': `help-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`help-tabpanel-${index}`}
				aria-labelledby={`help-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_event_list = await axios.get('/api/info/events/list');

				const events = [];
				res_event_list.data.forEach(element => {
					events.push({
						timestamp: getLocalDateTimeString(element.timestamp),
						message: element.message
					})
				});

				setGlobalState(prev_state => ({ ...prev_state,
					events: events,
				}));

			} catch (error) {
				console.log(error);
			}
		}

		if (tab_no === 1) {
			fetchData();
		}

	}, [tab_no])


	return (

		<div className={classes.root} >

			<AppBar position='static'>
				<Toolbar>

					<Typography className={classes.title} variant='h6' noWrap>
						Підтримка
					</Typography>

					<IconButton
						className={classes.right_button}
						onClick={() => props.history.push('/')}
						color='inherit'
					>
						<BackIcon />
					</IconButton>

				</Toolbar>
			</AppBar>

			<Tabs
				value={tab_no}
				onChange={OnTabChage}
				className={classes.tab}
				indicatorColor='primary'
				textColor='primary'
			>
				<Tab label='Статус ринків' {...getTabsProps(0)} />
				<Tab label='Архів повідомлень' {...getTabsProps(1)} />
				<Tab label='Інструкції' {...getTabsProps(2)} />
				<Tab label='Підтримка' {...getTabsProps(3)} />
			</Tabs>

			<TabPanel value={tab_no} index={0}>

				<MarketInfoFrom/>

			</TabPanel>

			<TabPanel value={tab_no} index={1}>

				<EventsView
					rows={global_state.events}
				/>

			</TabPanel>

			<TabPanel value={tab_no} index={2}>

				<div className={classes.place_holder}>
					<a href='/docs/help/Инструкция по разделу планирования.pdf'>Інструкція з роботи з розділом планування.</a>
				</div>

				<div className={classes.place_holder}>
					<a href='/docs/help/Инструкция по составлению часовых графиков.pdf'>Інструкція з роботи з часовими рядами.</a>
				</div>

				<div className={classes.place_holder}>
					<a href='/docs/help/Инструкция по работе с разделами РДН и ВДР.pdf'>Інструкція з роботи із розділами РДН і ВДР.</a>
				</div>

				<div className={classes.place_holder}>
					<a href='/docs/help/Инструкция по загрузке данных блоков.pdf'>Інструкція з завантаження даних блоків.</a>
				</div>

			</TabPanel>

			<TabPanel value={tab_no} index={3}>

				<HelpRequestForm />

			</TabPanel>

		</div>
	)
}

export default withRouter(HelpForm);