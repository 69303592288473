import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import XLSX from 'xlsx';
// import { saveAs } from 'file-saver';

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { DamOrderFromJson } from '../utils/dam_functions';
import { excel_date_to_date } from '../utils/excel_functions';
import { DamResultsFromJson } from '../utils/results_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	paper_top: {
		width: '600px',
	},

	paper_bottom: {
		marginTop: theme.spacing(2),
		width: '1000px',
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		border: '1px solid gray',
		borderRadius: '10px',
		alignItems: 'center',
		width: '100%',
	},

	control_margin: {
		marginBottom: theme.spacing(2),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

}));


export default function DamPriceCalculationForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	const inital_sate = {
		import_file: undefined,
		import_order_file_1: undefined,
		import_order_file_2: undefined,

		bids_to_remove: undefined,
		bids_to_add: undefined,

		loaded_data: undefined,
		domains: [],
		dates: [],
		hours: [],
		trade_types: [],

		domain: '',
		date: 0,
	}
	const [local_state, setLocalState] = useState(inital_sate);

	// states for the label width
	const domain_label = React.useRef(null);
	const date_label = React.useRef(null);

	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [date_label_width, setDateLabelWidth] = useState(0);


	// ascending sort by 2 item of row
	function sort_rows_ascending_by_price (a, b) {
		if (a[2] > b[2]) return 1;
		if (b[2] > a[2]) return -1;
		return 0;
	}

	// descending sort by 2 item of row
	function sort_rows_descending_by_price (a, b) {
		if (a[2] > b[2]) return -1;
		if (b[2] > a[2]) return 1;
		return 0;
	}


	function dam_price_calculation (buy_bids, sell_bids) {

		let buy_index = 0;
		let sell_index = 0;
		var dam_price = sell_bids[sell_index][2];

		// const buy_export = [];
		// const sell_export = [];

		// buy_export.push(`before calculation;`);
		// buy_export.push(buy_bids.join(';'));
		// buy_export.push('bids_end;');

		// sell_export.push(`before calculation;`);
		// sell_export.push(sell_bids.join(';'));
		// sell_export.push('bids_end;');

		// while buying price is bigger than selling price
		while (buy_index < buy_bids.length && sell_index < sell_bids.length && buy_bids[buy_index][2] >= sell_bids[sell_index][2]) {

			// if (buy_bids[0][1] === 24) {

			// 	buy_export.push(`index: ${buy_index};`);
			// 	buy_export.push(buy_bids.join(';'));
			// 	buy_export.push('bids_end;');

			// 	sell_export.push(`index: ${sell_index}; dam price: ${dam_price}`);
			// 	sell_export.push(sell_bids.join(';'));
			// 	sell_export.push('bids_end;');
			// }

			// if sell volume == buy volume
			if (sell_bids[sell_index][3] === buy_bids[buy_index][3]) {

				// sell bid accepted, so store new price
				dam_price = sell_bids[sell_index][2];

				// advance both indexes
				sell_index++;
				buy_index++;

				continue;
			}

			// if sell volume > buy volume
			if (sell_bids[sell_index][3] > buy_bids[buy_index][3]) {

				// make sell volume smaller by buy volume
				let volume = sell_bids[sell_index][3];
				volume -= buy_bids[buy_index][3];
				sell_bids[sell_index][3] = parseFloat(volume.toFixed(1));

				// sell bid accepted, so store new price
				dam_price = sell_bids[sell_index][2];

				// advance bid index
				buy_index++;

				continue;
			}

			// if sell volume < buy volume
			if (sell_bids[sell_index][3] < buy_bids[buy_index][3]) {

				// make buy volume smaller by sell volume
				let volume = buy_bids[buy_index][3];
				volume -= sell_bids[sell_index][3];
				buy_bids[buy_index][3] = parseFloat(volume.toFixed(1));

				// sell bid accepted, so store new price
				dam_price = sell_bids[sell_index][2];

				// advance bid index
				sell_index++;

				continue;
			}
		}

		// if (buy_bids[0][1] === 24) {

		// 	buy_export.push(`index: ${buy_index};`);
		// 	buy_export.push(buy_bids.join(';'));
		// 	buy_export.push('bids_end;');

		// 	sell_export.push(`index: ${sell_index}; dam price: ${dam_price}`);
		// 	sell_export.push(sell_bids.join(';'));
		// 	sell_export.push('bids_end;');

		// 	const save_file_content = [ ...buy_export ];
		// 	const blob = new Blob([ save_file_content ], { type: 'text/plain' });
		// 	saveAs(blob, `export_buy_rows.csv`);

		// 	const save_file_content_2 = [ ...sell_export ];
		// 	const blob_2 = new Blob([ save_file_content_2 ], { type: 'text/plain' });
		// 	saveAs(blob_2, `export_sell_rows.csv`);
		// }

		return dam_price;
	}


	function handleImportFileChange (event) {

		try {

			const event_value = event.target.files[0];

			// import from xlsx file
			if (event_value.name.indexOf('.xlsx') !== -1) {

				setLocalState(prev_state => ({ ...prev_state,
					import_file: event_value,
				}));

			} else {
				window.alert('Обрано формат, що не підтримується.')
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function handleImportOrderFile1Change (event) {

		try {

			const event_value = event.target.files[0];

			// import from xlsx file
			if (event_value.name.indexOf('.xlsx') !== -1) {

				setLocalState(prev_state => ({ ...prev_state,
					import_order_file_1: event_value,
				}));

			} else {
				window.alert('Обрано формат, що не підтримується.')
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function handleImportOrderFile2Change (event) {

		try {

			const event_value = event.target.files[0];

			// import from xlsx file
			if (event_value.name.indexOf('.xlsx') !== -1) {

				setLocalState(prev_state => ({ ...prev_state,
					import_order_file_2: event_value,
				}));

			} else {
				window.alert('Обрано формат, що не підтримується.')
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function onUploadHandler(e) {

		e.preventDefault();

		try {

			// import
			if (local_state.import_file !== undefined && local_state.import_order_file_1 !== undefined && local_state.import_order_file_2 !== undefined) {

				const reader = new FileReader();

				// declare onload function
				reader.onload = function () {

					const file_content = reader.result;

					const wb = XLSX.read(file_content, { type: 'binary' });
					const second_sheet_name = wb.SheetNames.find(elem => elem.indexOf('Точки кривої') !== -1);
				
					if (second_sheet_name !== undefined) {
				
						const json_dam_results = XLSX.utils.sheet_to_json(wb.Sheets[second_sheet_name], { header: 1 });
						const result = DamResultsFromJson(json_dam_results);

						setLocalState(prev_state => ({ ...prev_state,
							loaded_data: result.data,
							domains: result.domains,
							dates: result.dates,
							hours: result.hours,
							trade_types: result.trade_types,
						}))
					}
				}

				reader.readAsBinaryString(local_state.import_file);

				const order_1_reader = new FileReader();

				// declare onload function
				order_1_reader.onload = function () {

					const file_content = order_1_reader.result;

					const wb = XLSX.read(file_content, { type: 'binary' });
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });

					// get order from xml document and store into state
					const import_xlsx_order = DamOrderFromJson(json_doc);
					setLocalState(prev_state => ({ ...prev_state,
						bids_to_remove: import_xlsx_order,
					}))
				}

				order_1_reader.readAsBinaryString(local_state.import_order_file_1);

				const order_2_reader = new FileReader();

				// declare onload function
				order_2_reader.onload = function () {

					const file_content = order_2_reader.result;

					const wb = XLSX.read(file_content, { type: 'binary' });
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });

					// get order from xml document and store into state
					const import_xlsx_order = DamOrderFromJson(json_doc);
					setLocalState(prev_state => ({ ...prev_state,
						bids_to_add: import_xlsx_order,
					}))
				}

				order_2_reader.readAsBinaryString(local_state.import_order_file_2);
			}

		} catch(error) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function handleDomainChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain: event_value,
		}));
	}


	function handleDateChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			date: event_value,
		}));
	}


	async function onDownloadHandler (e) {

		e.preventDefault();

		try {

			const is_bids_to_remove_sell = local_state.bids_to_remove.trade_type.toLowerCase() === 'sell' || local_state.bids_to_remove.trade_type.toLowerCase() === 'продаж';
			const value_matches_bids_to_remove = (value) => {
				if (is_bids_to_remove_sell) {
					return value === 'sell' || value === 'продаж';
				} else {
					return value === 'buy' || value === 'купівля';
				}
			}

			if (local_state.loaded_data !== undefined) {

				const filtered_by_domain = local_state.loaded_data.filter(row => row[6] === local_state.domain);
				const filtered_by_date = filtered_by_domain.filter(row => row[0] === parseInt(local_state.date));

				const clone_source = filtered_by_date.map(row => ([ ...row ]));

				// dam price calculation
				const dam_price = [];
				local_state.hours.forEach(hour => {

					const bids_buying = clone_source.filter(row => row[1] === hour && (row[5].toLowerCase() === 'купівля' || row[5].toLowerCase() === 'buy'));
					const bids_selling = clone_source.filter(row => row[1] === hour && (row[5].toLowerCase() === 'продаж' || row[5].toLowerCase() === 'sell'));

					bids_buying.sort(sort_rows_descending_by_price);
					bids_selling.sort(sort_rows_ascending_by_price);

					dam_price[hour - 1] = dam_price_calculation(bids_buying, bids_selling);
				})

				var bids_found = true;

				// remove orders
				local_state.bids_to_remove.rows.forEach(item => {

					const bids = filtered_by_date.filter(row => row[1] === item.period && row[2] === item.price && row[3] >= item.amount && value_matches_bids_to_remove(row[5]));
					if (bids.length > 0) {
						bids[0][3] -= item.amount;
					} else {
						bids_found = false;
					}
				})

				const clone2 = filtered_by_date.map(row => ([ ...row ]));

				if (!bids_found) {

					window.alert('Заявки, які потрібно видалити, не знайдені.');
					return;
				}

				// add orders
				local_state.bids_to_add.rows.forEach(item => {

					var new_row = [];
					new_row[0] = parseInt(local_state.date);
					new_row[1] = item.period;
					new_row[2] = item.price;
					new_row[3] = item.amount;
					new_row[4] = item.amount;
					new_row[5] = local_state.bids_to_add.trade_type.toLowerCase();
					new_row[6] = local_state.domain;

					filtered_by_date.push(new_row);
				})

				// dam price calculation
				const dam_price_theory = [];
				local_state.hours.forEach(hour => {

					const bids_buying = filtered_by_date.filter(row => row[1] === hour && (row[5].toLowerCase() === 'купівля' || row[5].toLowerCase() === 'buy'));
					const bids_selling = filtered_by_date.filter(row => row[1] === hour && (row[5].toLowerCase() === 'продаж' || row[5].toLowerCase() === 'sell'));

					bids_buying.sort(sort_rows_descending_by_price);
					bids_selling.sort(sort_rows_ascending_by_price);

					dam_price_theory[hour - 1] = dam_price_calculation(bids_buying, bids_selling);
				})

				// create output data
				var output = [];
				// create two cells of header
				output[0] = [];
				output[0][0] = 'Дата';
				output[0][1] = excel_date_to_date(local_state.date).toLocaleDateString();

				output[1] = [];
				output[1][0] = 'Година';
				output[1].push( ...local_state.hours );

				output[2] = [];
				output[2][0] = 'Ціна РДН';
				output[2].push( ...dam_price );

				output[3] = [];
				output[3][0] = 'Ціна РДН теоритична';
				output[3].push( ...dam_price_theory );

				const file_name = `Output_${local_state.domain}_${local_state.date}.xlsx`

				// create sheet from json
				const output_sheet = XLSX.utils.json_to_sheet(output, { skipHeader: true });
				const after_remove = XLSX.utils.json_to_sheet(clone2, { skipHeader: true });
				const after_add = XLSX.utils.json_to_sheet(filtered_by_date, { skipHeader: true });

				// create new workbook
				var output_wb = XLSX.utils.book_new();

				// insert sheet into workbook
				XLSX.utils.book_append_sheet(output_wb, output_sheet, file_name);
				XLSX.utils.book_append_sheet(output_wb, after_remove, 'after_remove');
				XLSX.utils.book_append_sheet(output_wb, after_add, 'after_add');

				// save workbook to file
				XLSX.writeFile(output_wb, file_name);
			}

		} catch(error) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: error.message,
			}));

			console.log(error);
		}
	}


	function onErrorMessageClose () {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (local_state.loaded_data !== undefined) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
			setDateLabelWidth(date_label.current.offsetWidth);
		}

	},[local_state.loaded_data])


	useEffect(() => {

		if (local_state.bids_to_remove !== undefined && local_state.bids_to_add !== undefined && local_state.dates.length > 0) {

			const date_remove = new Date(local_state.bids_to_remove.trade_date);
			const date_add = new Date(local_state.bids_to_add.trade_date);

			if (date_remove.getUTCFullYear() !== date_add.getUTCFullYear() ||
				date_remove.getUTCMonth() !== date_add.getUTCMonth() ||
				date_remove.getUTCDate() !== date_add.getUTCDate()) {

				setMessage(prev_state => ({...prev_state,
					show: true,
					message: 'Дати заявки 1 та заявки 2 не співпадають.',
				}));
			}

			var contains = false;
			local_state.dates.forEach(item => {

				if (item.display.getUTCFullYear() === date_add.getUTCFullYear() &&
					item.display.getUTCMonth() === date_add.getUTCMonth() &&
					item.display.getUTCDate() === date_add.getUTCDate()) {

					contains = true;
				}
			});

			if (!contains) {

				setMessage(prev_state => ({...prev_state,
					show: true,
					message: 'Дата заявки не міститься в файлі результатів РДН.',
				}));
			}
		}

	},[local_state.bids_to_remove, local_state.bids_to_add, local_state.dates])


	var display_domains = [ ];
	if (local_state.domains.length > 0) {
		display_domains = local_state.domains.map(item => {
			return (
				<option key={ item } value={ item }>{ item }</option>
			)
		});
		display_domains.unshift(<option key={ -1 } value=''></option>)
	}

	var display_dates = [];
	if (local_state.dates.length > 0 && local_state.bids_to_remove !== undefined && local_state.bids_to_add !== undefined) {

		const date_remove = new Date(local_state.bids_to_remove.trade_date);

		local_state.dates.forEach(item => {

			if (item.display.getUTCFullYear() === date_remove.getUTCFullYear() &&
				item.display.getUTCMonth() === date_remove.getUTCMonth() &&
				item.display.getUTCDate() === date_remove.getUTCDate()) {

				display_dates.push(
					<option key={ item.value } value={ item.value }>{ item.display.toLocaleDateString() }</option>
				)
			}
		});
		display_dates.unshift(<option key={ -1 } value=''></option>)
	}


	return (

		<Container className={ classes.paper } component='main'>

			<div className={ classes.paper_top }>

				<form onSubmit={ onUploadHandler } className={ classes.form_control } >

					<Typography component='h3' variant='h6' align='center'>
						Зчитання результатів РДН
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						className={classes.control_margin}
						fullWidth
						type='file'
						label='Файл результатів РДН'
						inputProps= {{ accept:'.xlsx' }}
						onChange= { handleImportFileChange }
					/>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						className={classes.control_margin}
						fullWidth
						type='file'
						label='Існуючі заявки РДН'
						inputProps= {{ accept:'.xlsx' }}
						onChange= { handleImportOrderFile1Change }
					/>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						className={classes.control_margin}
						fullWidth
						type='file'
						label='Заявки РДН, що додаються'
						inputProps= {{ accept:'.xlsx' }}
						onChange= { handleImportOrderFile2Change }
					/>

					<div className={classes.button_form_control}>

						<Button
							type='submit'
							variant='contained'
							color='primary'
							className = { classes.button_control }
						>
							Зчитати файли
						</Button>

					</div>

				</form>

				{ local_state.loaded_data !== undefined &&
				<form onSubmit={ onDownloadHandler } className={ classes.form_control } >

					<Typography component='h3' variant='h6' align='center' className={classes.control_margin}>
						Розрахунки результатів РДН
					</Typography>

					<FormControl
						required
						variant='outlined'
						className={classes.control_margin}
						fullWidth
					>
						<InputLabel ref={ domain_label } id='domain-label'>Торгова зона</InputLabel>
						<Select
							native
							labelId='domain-label'
							labelWidth={ domain_label_width }
							onChange={ handleDomainChange }
							value={ local_state.domain }
						>
							{ display_domains }
						</Select>
					</FormControl>

					<FormControl
						required
						variant='outlined'
						className={classes.control_margin}
						fullWidth
					>
						<InputLabel ref={ date_label } id='date-start-label'>На дату</InputLabel>
						<Select
							native
							labelId='date-start-label'
							labelWidth={ date_label_width }
							onChange={ handleDateChange }
							value={ local_state.date }
						>
							{ display_dates }
						</Select>
					</FormControl>

					<div className={classes.button_form_control}>

						<Button
							type='submit'
							variant='contained'
							color='primary'
							className = { classes.button_control }
						>
							Завантажити
						</Button>

					</div>

				</form> }

			</div>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>

	);
}