import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2, 2, 1, 2),
	},

	table: {
		width: '100%',
		padding: theme.spacing(1),
		border: '1px solid gray',
		marginBottom: theme.spacing(2),
	},

	header_cell: {
		fontWeight: 'bold',
		fontSize: '11px',
		textAlign: 'center',
		width: '80px',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		textAlign: 'center',
		borderRight: '1px solid gray',
	},

	amount_row: {
		background: '#9BC2E6',
	},

	price_row: {
		background: '#FFC000',
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		// width: '75px',
		borderRight: '1px solid gray',
	}

}));


function IdmReport(props) {
	const classes = useStyles();

	const { report_date, columns_count, sell_orders, buy_orders, idm_sell_orders, idm_buy_orders, dam_prices } = props;

	const headers_blocks = [
		{ id: 0, label: `Заявка на Закупівлю ${report_date}` },
		{ id: 1, label: `Факт Закупівля ${report_date}` },
		{ id: 2, label: `Заявка на Продаж ${report_date}` },
		{ id: 3, label: `Факт Продаж ${report_date}` },
	];

	const headers_rows = [
		{ id: 'header', label: '' },
		{ id: 'amount', label: 'Обсяг торгів, МВтг' },
		{ id: 'price', label: 'Ціна, грн/МВтг' },
		{ id: 'price_dam', label: 'Ціна РДН, грн/МВтг' },
		{ id: 'value', label: 'Вартість, грн' },
	];

	const unb_headers_rows = [
		{ id: 'header', label: '' },
		{ id: 'prof_min_unb', label: 'Профіцит мін. небаланс, грн/МВтг' },
		{ id: 'prof_max_unb', label: 'Профіцит макс. небаланс, грн/МВтг' },
		{ id: 'def_min_unb', label: 'Дефіцит мін. небаланс, грн/МВтг' },
		{ id: 'def_max_unb', label: 'Дефіцит макс. небаланс, грн/МВтг' },
	];

	const headers_columns = [];
	for (let h = 0; h <= columns_count + 1; h++) {
		headers_columns[h] = {
			id: h,
			label: (h > columns_count) ? 'Всього' : h,
		};
	};

	const blocks_array = [];
	for (let b = 0; b < 4; b++) {

		// initialise rows
		const rows_array = [];
		for (let r = 0; r < 5; r++) {

			let total_amount = 0;

			if (b % 2 === 0 && r === 2) {
				continue;
			}
			if (b % 2 === 1 && r === 3) {
				continue;
			}

			// initialise cells
			const cells_array = [];
			for (let c = 0; c < headers_columns.length - 1; c++) {

				let label_text, cell_value, order;
				switch (b) {
					case 0:
						order = buy_orders.find(item => item.period === c);
						break;

					case 1:
						order = idm_buy_orders.find(item => item.period_to === c);
						break;

					case 2:
						order = sell_orders.find(item => item.period === c);
						break;

					case 3:
						order = idm_sell_orders.find(item => item.period_to === c);
						break;

					default:
				}

				const dam_price = dam_prices.find(item => item.period === c);

				if (c === 0) {

					label_text = headers_rows[r].label;

				} else {

					switch (r) {
						case 0:
							label_text = headers_columns[c].label;
							break;

						case 1:
							cell_value = (order !== undefined) ? order.amount.toFixed(1) : '';
							break;

						case 2:
							cell_value = (order !== undefined) ? order.price.toFixed(2) : '';
							break;

						case 3:
							cell_value = (dam_price !== undefined) ? dam_price.price.toFixed(2) : '';
							break;

						case 4:
							cell_value = (dam_price !== undefined && order !== undefined) ? (dam_price.price * order.amount).toFixed(2) : '0.00';
							break;
						
						default:
					}

				}

				if (c > 0 && cell_value !== '') {
					total_amount += parseFloat(cell_value);
				}

				cells_array[c] = {
					id: c,
					label: (c === 0) || (r === 0) ? label_text : undefined,
					value: cell_value,
				}
			}

			cells_array[headers_columns.length - 1] = {
				id: headers_columns.length - 1,
				label: (r === 0) ? headers_columns[headers_columns.length - 1].label : undefined,
				value: (r === 1 || r === 4) ? total_amount.toFixed(2) : '',
			}

			rows_array[r] = {
				id: r,
				cells: cells_array,
			}
		}

		// initialise block
		blocks_array[b] = {
			id: `${b + 1}`,
			label: headers_blocks[b].label,
			rows: rows_array,
		};
	};

	const unballance_rows = [];
	for (let r = 0; r < 5; r++) {

		// initialise cells
		const cells_array = [];
		for (let c = 0; c < headers_columns.length - 1; c++) {

			let label_text, cell_value;
			const dam_price = dam_prices.find(item => item.period === c);

			if (c === 0) {

				label_text = unb_headers_rows[r].label;

			} else {

				switch (r) {
					case 0:
						label_text = headers_columns[c].label;
						break;

					case 1:
						cell_value = (dam_price !== undefined) ? (dam_price.price * 0.95).toFixed(2) : '';
						break;

					case 2:
						cell_value = (dam_price !== undefined) ? (dam_price.price * 0.55 * 0.95).toFixed(2) : '';
						break;

					case 3:
						cell_value = (dam_price !== undefined) ? (dam_price.price * 1.05).toFixed(2) : '';
						break;

					case 4:
						cell_value = (dam_price !== undefined) ? (dam_price.price * 1.15 * 1.05).toFixed(2) : '';
						break;
					
					default:
				}

			}

			cells_array[c] = {
				id: c,
				label: (c === 0) || (r === 0) ? label_text : undefined,
				value: cell_value,
			}
		}

		cells_array[headers_columns.length - 1] = {
			id: headers_columns.length - 1,
			label: (r === 0) ? headers_columns[headers_columns.length - 1].label : undefined,
			value: '',
		}

		unballance_rows[r] = {
			id: r,
			cells: cells_array,
		}
	}


	return (
		<Paper className={classes.paper}>
			{
				blocks_array.map((block, block_index) => (
					<Table
						key={block.id}
						size={'small'}
						className={classes.table}
					>
						<TableBody>
							{
								block.rows.map(row => (
									<TableRow
										key={row.id}
										className={ (row.id === 1 || row.id === 2) ? classes.amount_row : (row.id === 3) ? classes.price_row : undefined }
									>
										{
											(row.id === 0) &&
											<TableCell
												key={`header_${row.id}`}
												className={ classes.header_cell }
												rowSpan={block.rows.length}
											>
												{ headers_blocks[block_index].label }
											</TableCell>
										}
										{
											row.cells.map((cell, cell_index) => (
												<TableCell
													key={cell.id}
													className={ (row.id === 0) || (cell_index === 0) ? classes.head : classes.cells }
												>
													{ (row.id === 0) || (cell_index === 0) ? cell.label : cell.value}
												</TableCell>
											))
										}
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				))
			}
			<Table
				size={'small'}
				className={classes.table}
			>
				<TableBody>
					{
						unballance_rows.map(row => (
							<TableRow
								key={row.id}
							>
								{
									row.cells.map((cell, cell_index) => (
										<TableCell
											key={cell.id}
											className={ (row.id === 0) || (cell_index === 0) ? classes.head : classes.cells }
										>
											{ (row.id === 0) || (cell_index === 0) ? cell.label : cell.value}
										</TableCell>
									))
								}
							</TableRow>
						))
					}
				</TableBody>
			</Table>
		</Paper>
	)
}

export default IdmReport;