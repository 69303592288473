import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import AlertIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { red, green } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getLocalDateString, getLocalDateTimeString } from '../../utils/date_functions';
import { Button } from '@material-ui/core';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
	},

	block: {
		textAlign: 'center',
		border: '1px solid gray',
		borderRadius: '5px',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},

	schedule_block: {
		textAlign: 'center',
		border: '2px solid green',
		borderRadius: '5px',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},

	anomaly_report_block: {
		textAlign: 'center',
		border: '2px solid red',
		borderRadius: '5px',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},

	table_header: {
		textAlign: 'left',
		padding: theme.spacing(1),
	},

	table_block: {
		textAlign: 'center',
		border: '1px solid gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	table_style: {
		width: '100%',
		tableLayout: 'fixed',
		padding: theme.spacing(2, 0, 0, 0),

		'& td:nth-child(even)': {
			backgroundColor: '#E8E8E8',
		},

		'& td:nth-child(odd)': {
			backgroundColor: '#F8F8F8',
		},

		'& th': {
			fontWeight: 'bold',
			backgroundColor: 'white',
		},
	},

	message: {
		padding: theme.spacing(2, 0, 0, 0),
		textAlign: 'left',
	},

	red: {
		display: 'inline-block',
		width: '100%',
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
	},

	green: {
		display: 'inline-block',
		width: '100%',
		color: theme.palette.getContrastText(green[500]),
		backgroundColor: green[500],
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function MmsResultsDialog(props) {
	const classes = useStyles();
	const { open, onClose, selectedValue, reasons, schedules, anomaly_reports } = props;


	const handleClose = () => {
		onClose(selectedValue);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth='xl'
			aria-labelledby='mms-dialog-title'
		>
			<DialogTitle id='mms-dialog-title' className={classes.header} >
				Результати завантаження графіку РДД
			</DialogTitle>

			<DialogContent>
				{
					reasons.map((item, index) => (
						<Grid container spasing={2} key={`reason_${index}`} className={classes.block}>

							<Grid item xs={1}>

								{ (item.code === 'A01') &&
								<div className={ classes.green }>
									<InfoIcon />
								</div> }

								{ (item.code !== 'A01') &&
								<div className={ classes.red }>
									<AlertIcon />
								</div> }

							</Grid>

							<Grid item xs={11}>

								<Typography>
									<b>Підтвердження №</b> {item.rid} від {getLocalDateTimeString(item.created_date)}
								</Typography>

							</Grid>

							<table className={classes.table_header}>
								<tbody>
									<tr>
										<td>
											<Typography>
												<b>ID MMS документу</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.document_id}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Одержаний документ</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.received_document}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Версія документу</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.received_document_revision}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Відправник</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.sender}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Отримувач</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.receiver}
											</Typography>
										</td>
									</tr>
								</tbody>
							</table>

							<Grid item xs={12} className={classes.message}>

								{
									(item.faults.length > 0) &&
									<Typography>
										<b>Помилки у вкладених часових рядах</b>:
									</Typography>
								}

								{
									item.faults.map(fault => (
										<p>
											<Typography>
												<b>ID часового ряду</b>: {fault.rid}
											</Typography>

											<Typography>
												<b>Код помилки</b>: {fault.code}, <b>Повідомлення</b>: {fault.message}
											</Typography>
										</p>
									))
								}

								<Typography>
									<b>Код помилки</b>: {item.code}
								</Typography>

								{ item.message &&
								<Typography>
									<b>Повідомлення</b>: {item.message}
								</Typography> }

							</Grid>

						</Grid>
					))
				}
				{
					schedules.map((item, index) => (
						<Grid container spacing={2} key={`schedule_${index}`} className={classes.schedule_block}>

							<Grid item xs={12}>
								<Typography>
									<b>№ графіку</b>: {item.rid} від {getLocalDateTimeString(item.created_date)}
								</Typography>
							</Grid>

							<table className={classes.table_header}>
								<tbody>
									<tr>
										<td>
											<Typography>
												<b>ID MMS документу</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.document_id}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>ID графіку</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.schedule_id}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Версія графіку</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.revision}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Відправник</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.sender}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Отримувач</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.receiver}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Доба торгівлі</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{getLocalDateString(item.period_start)}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Зона торгівлі</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.domain}
											</Typography>
										</td>
									</tr>
								</tbody>
							</table>

							<Grid item xs={12}>

								{ item.time_series.map((ts, ts_index) => (

									<div key={ ts_index } className={classes.table_block}>

										<table className={classes.table_header}>
											<tbody>
												<tr>
													<td>
														<b>ID</b>:
													</td>
													<td>
														{ts.rid}
													</td>
												</tr>
												<tr>
													<td>
														<b>Версія</b>:
													</td>
													<td>
														{ts.version}
													</td>
												</tr>
												<tr>
													<td>
														<b>Покупець</b>:
													</td>
													<td>
														{ ts.consumer_eic_x_code }
													</td>
												</tr>
												<tr>
													<td>
														<b>Продавець</b>:
													</td>
													<td>
														{ ts.seller_eic_x_code }
													</td>
												</tr>
												<tr>
													<td>
														<b>Зона торгівлі</b>:
													</td>
													<td>
														{ts.domain}
													</td>
												</tr>
											</tbody>
										</table>

										<table className={classes.table_style}>

											<thead>
												<tr>
													{ ts.points.map((point, point_index) => (
														<th key={point_index}> {point.index} </th>
													)) }
												</tr>
											</thead>

											<tbody>
												<tr>
													{ ts.points.map((point, point_index) => (
														<td key={point_index}> {point.value} </td>
													)) }
												</tr>
											</tbody>

										</table>

									</div>

								)) }

							</Grid>

						</Grid>
					))
				}
				{
					anomaly_reports.map((item, index) => (
						<Grid container spacing={2} key={`anomaly_report_${index}`} className={classes.anomaly_report_block}>

							<Grid item xs={12}>
								<Typography>
									<b>№ документу</b>: {item.rid} від {getLocalDateTimeString(item.created_date)}
								</Typography>
							</Grid>

							<table className={classes.table_header}>
								<tbody>
									<tr>
										<td>
											<Typography>
												<b>ID MMS документу</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.document_id}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Відправник</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.sender}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Отримувач</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.receiver}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Доба торгівлі</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{getLocalDateString(item.period_start)}
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<Typography>
												<b>Зона торгівлі</b>:
											</Typography>
										</td>
										<td>
											<Typography>
												{item.domain}
											</Typography>
										</td>
									</tr>
								</tbody>
							</table>

							<Grid item xs={12}>

								{ item.anomalies.map((anomaly, anomaly_index) => (

									<div key={ `anomaly_${anomaly_index}` } className={classes.table_block}>

										<Grid container spacing={1}>

											<Grid item xs={6}>
												<b>Помилковий документ</b>: { anomaly.document }
											</Grid>

											<Grid item xs>
												<b>Версія</b>: {anomaly.document_revision}
											</Grid>

											<Grid item xs={4}>
												<b>Учасник</b>: {anomaly.participant}
											</Grid>

										</Grid>

										{ anomaly.time_series.map((ts, ts_index) => (
											<div key={`ts_${ts_index}`}>

												<table className={classes.table_header}>
													<tbody>
														<tr>
															<td>
																<b>ID</b>:
															</td>
															<td>
																{ts.rid}
															</td>
														</tr>
														<tr>
															<td>
																<b>Версія</b>:
															</td>
															<td>
																{ts.version}
															</td>
														</tr>
														<tr>
															<td>
																<b>Покупець</b>:
															</td>
															<td>
																{ ts.consumer_eic_x_code }
															</td>
														</tr>
														<tr>
															<td>
																<b>Продавець</b>:
															</td>
															<td>
																{ ts.seller_eic_x_code }
															</td>
														</tr>
														<tr>
															<td>
																<b>Зона торгівлі</b>:
															</td>
															<td>
																{ts.domain}
															</td>
														</tr>
													</tbody>
												</table>

												<table  className={classes.table_style}>

													<thead>
														<tr>
															{ ts.points.map((point, point_index) => (
																<th key={point_index}> {point.index} </th>
															)) }
														</tr>
													</thead>

													<tbody>
														<tr>
															{ ts.points.map((point, point_index) => (
																<td key={point_index}> {point.value} </td>
															)) }
														</tr>
													</tbody>

												</table>

												<Grid container spacing={1} className={classes.message}>

													<Grid item xs={3}>

														<Typography>
															<b>Код помилки</b>: {ts.reason_code}
														</Typography>

													</Grid>

													<Grid item xs={9}>

														<Typography>
															<b>Текст помилки</b>: {ts.reason_text}
														</Typography>

													</Grid>

												</Grid>

											</div>
										)) }

									</div>

								)) }

							</Grid>

						</Grid>
					))
				}

				<div className={classes.button_form_control}>
					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { handleClose }
					>
						Закрити
					</Button>
				</div>

			</DialogContent>
		</Dialog>
	);
}

MmsResultsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	reasons: PropTypes.array,
	schedules: PropTypes.array,
	anomaly_reports: PropTypes.array
};