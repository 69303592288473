import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import GroupPricesTable from './subcomponents/GroupPricesTable';
import WaitDialog from '../subcomponents/WaitDialog';

import { getDateFormat, getLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_align_home: {
		position: 'relative',

	},

	control_align: {
		position: 'absolute',
		bottom: theme.spacing(2),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	container: {
		marginTop: theme.spacing(2),
		minWidth: '1000px',
		display: 'flex',
		flexDirection: 'column',
	},

}));


function ComponentPaymentsEdit(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);
	const [wait, setWait] = useState(false);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// get props
	const { contract_id, companies, period_start, period_end } = props;


	function createInitialState() {

		const day_1 = new Date();
		day_1.setDate(1);

		const complex_state = {
			contract_no: '',
			contract_date: getDateFormat(day_1),
			consumer_company_id: 0,
			seller_company_id: 0,
			consumer_company_name: '',
			seller_company_name: '',
			payments: [],
			ballance_date: getDateFormat(day_1),
			ballance_payment_id: 0,
			ballance_payment: 0,
			display_payments: [],
			updated: false,
		}

		return complex_state;
	}


	function handleMonthBallanceChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			ballance_payment: parseFloat(event_value),
		}));
	}


	function onPaymentUpdate(updated_row) {

		if (updated_row) {

			const new_rows = [...local_state.display_payments];
			const the_row = new_rows.find(item => item.id === updated_row.id);

			if (the_row) {
				the_row.payment = updated_row.payment;
			}

			setLocalState(prev_state => ({ ...prev_state,
				display_payments: new_rows,
			}));
		}
	}


	function onRowDelete(row_id) {

		const new_rows = [...local_state.display_payments];
		const the_row = new_rows.find(item => item.id === row_id);

		if (the_row) {
			the_row.payment = '';
		}

		setLocalState(prev_state => ({ ...prev_state,
			display_payments: new_rows,
		}));
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	function getDaysCount(period_start, period_end) {
		return Math.round((new Date(period_end) - new Date(period_start)) / 3.6e6 / 24) + 1;
	}


	async function SavePayment(payment) {

		try {

			if (payment.payment !== '') {

				// if payment is entered
				let link;
				if (payment.payment_id) {
					link = '/api/admin/contracts/payments/' + payment.payment_id;
				} else {
					link = '/api/admin/contracts/payments/add';
				}

				await axios.post(link, payment);

			} else {

				// if payment is not entered
				// check if payment_id exists,
				// in this case - delete
				if (payment.payment_id) {
					await axios.post('/api/admin/contracts/payments/' + payment.payment_id + '/delete');
				}
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	async function onSavePayments() {

		setWait(true);

		for (let i = 0; i < local_state.display_payments.length; i++) {

			const the_payment = {
				payment_id: local_state.display_payments[i].payment_id,
				contract_id: contract_id,
				payment_date: getDateFormat(local_state.display_payments[i].date),
				payment: local_state.display_payments[i].payment
			};

			SavePayment(the_payment);
		}

		// save ballance payment
		const the_payment = {
			payment_id: local_state.ballance_payment_id,
			contract_id: contract_id,
			payment_date: getDateFormat(local_state.ballance_date),
			payment: local_state.ballance_payment,
			month_ballance: true
		};

		SavePayment(the_payment);

		setWait(false);

		setLocalState(prev_state => ({ ...prev_state,
			updated: !prev_state.updated
		}));

		alert('Платежі збережені.');
	}


	useEffect(() => {

		async function fetchData(contract_id) {

			try {

				const resp_contract = await axios.get('/api/admin/contract/' + contract_id);
				const resp_payments = await axios.get('/api/admin/contracts/payments', { params: {
					contract_id: contract_id,
				}});

				const contract = resp_contract.data.contract;
				const payments = resp_payments.data.payments;

				const display_payments = [];

				const stored_payment = payments.find(item => item.month_ballance === true);
				const ballance_payment_id = (stored_payment !== undefined) ? stored_payment.payment_id : 0;
				const ballance_payment = (stored_payment !== undefined) ? stored_payment.payment : 0;

				let days = getDaysCount(period_start, period_end);
				for (let d = 0; d < days; d++) {

					const row_date = new Date(period_start);
					row_date.setHours(24 * d, 0, 0, 0);

					const the_row = payments.find(item => {
						const item_date = new Date(item.payment_date);
						return (item_date.getFullYear() === row_date.getFullYear() &&
							item_date.getMonth() === row_date.getMonth() &&
							item_date.getDate() === row_date.getDate() &&
							item.month_ballance !== true)
					});

					display_payments[d] = {
						id: d,
						payment_id: (the_row !== undefined) ? the_row.payment_id : undefined,
						date: getDateFormat(row_date),
						payment: (the_row !== undefined) ? the_row.payment : ''
					}
				}

				const consumer_id = parseInt(contract.consumer_id);
				const seller_id = parseInt(contract.seller_id);

				const consumer = companies.find(item => item.company_id === consumer_id);
				const seller = companies.find(item => item.company_id === seller_id);

				setLocalState(prev_state => ({ ...prev_state,
					contract_no: contract.contract_number,
					contract_date: contract.contract_date,
					consumer_company_id: consumer_id,
					seller_company_id: seller_id,
					consumer_company_name: (consumer !== undefined) ? consumer.name : '',
					seller_company_name: (seller !== undefined) ? seller.name : '',
					payments: payments,
					ballance_payment_id: ballance_payment_id,
					ballance_payment: ballance_payment,
					display_payments: display_payments,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (contract_id && companies.length > 0) {
			fetchData(contract_id);
		}

	}, [contract_id, companies, local_state.updated])


	return (
		<Container component='main'>

			<div className={classes.container}>

				<Grid container spacing={2} >

					<Grid item xs={6}>

						<Typography component='h3' variant='h6' align='center'>
							Договір { local_state.contract_no } від { getLocalDateString(local_state.contract_date) }
						</Typography>

						<Typography component='h3' variant='body1' noWrap>
							<b>Покупець</b>: { local_state.consumer_company_name }
						</Typography>

						<Typography component='h3' variant='body1' noWrap>
							<b>Продавець</b>: { local_state.seller_company_name }
						</Typography>

					</Grid>

					<Grid item xs={6}>

						<Typography className={classes.control_bottom_margin} component='h3' variant='h6' align='center'>
							Оплати
						</Typography>

						<Grid container spacing={2}>

							<Grid item xs={6} className={classes.control_align_home}>

								<Typography
									className={classes.control_align}
									component='h3'
									variant='body1'
								>
									Баланс на { getLocalDateString(local_state.ballance_date) }:
								</Typography>

							</Grid>

							<Grid item xs={6}>

								<TextField
									className={classes.control_bottom_margin}
									type='number'
									size='small'
									fullWidth
									label='Переплата на начало місяця'
									value={ local_state.ballance_payment }
									onChange={ handleMonthBallanceChange }
								/>

							</Grid>

						</Grid>

						<GroupPricesTable
							rows={local_state.display_payments}
							onValueUpdate={onPaymentUpdate}
							onActionSelect={onRowDelete}
						/>

					</Grid>

				</Grid>

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={classes.button_control}
						disabled={local_state.group_contract_id === 0}
						onClick={onSavePayments}
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={classes.button_control}
						onClick={onCancelHandler}
					>
						Скасувати
					</Button>

				</div>

			</div>

			<WaitDialog
				open={wait}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</Container >
	)
}

export default withRouter(ComponentPaymentsEdit);