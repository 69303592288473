import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import InfoIcon from '@material-ui/icons/InfoSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import CompanyEditForm from './CompanyEditForm';
import UserAuthForm from './UserAuthForm';
import UserEditForm from './UserEditForm';
import UserBlockForm from './UserBlockForm';
import SettingsConfigForm from './SettingsConfigForm';
import SettingsTableForm from './SettingsTableForm';

import ImportForm from './ImportForm';
import CreateLicForm from './CreateLicForm';

import CompanyTable from './subcomponents/CompanyTable';
import UserTable from './subcomponents/UserTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import LicenseNotificationDialog from '../auth/LicenseNotificationDialog';

import { globalContext } from '../layout/Context';
import { adminContext } from './adminContext';

import NotAuthorised from '../auth/NotAuthorised';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function AdminForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = {

		update_flag: false,

		companies: [],
		contracts: [],
		users: [],
		logged_in_users: [],
		logs: {
			pt_logs: [],
			mms_logs: [],
			xmtrade_logs: []
		}
	};
	const [global_state, setGlobalState] = useState(initial_state);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	const init_lic_info = {
		show: false,
		license: {}
	}
	const [lic_info_dialog, setLicInfoDialog] = useState(init_lic_info);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id:					`admin-tab-${index}`,
			'aria-controls':	`admin-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
			<div
				hidden={value !== index}
				id={`admin-tabpanel-${index}`}
				aria-labelledby={`admin-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	function addItemHandler() {

		switch (tab_no) {
			case 0:
				props.history.push('/admin/company/add');
				break;

			case 1:
				props.history.push('/admin/user/add');
				break;

			default:
				break;
		
		}
	} 


	function onErrorMessageClose () {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	async function onLicenseButtonClick() {

		if (user.is_logged_in) {

			const res_license = await axios.get('/api/settings/license');
			const license = res_license.data;
	
			setLicInfoDialog(prev => ({ ...prev,
				show: true,
				license: license
			}));
		}
	}


	function onLicDialogClose() {

		setLicInfoDialog(prev => ({ ...prev,
			show: false
		}));
	}


	useEffect(() => {

		axios.get('/api/admin')
			.then(response => {

				setGlobalState(prev_state => ({ ...prev_state,
					companies: response.data.company_list,
					contracts: response.data.contract_list,
					users: response.data.user_list,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [global_state.update_flag]);


	useEffect(() => {

		if(tab_no === 1) {

			axios.get('/api/admin/logged_in_users')
				.then(response => {

					const user_ids = [];
					response.data.forEach(item => user_ids.push(item.user_id));

					setGlobalState(prev_state => ({ ...prev_state,
						logged_in_users: user_ids,
					}));
				})
				.catch(error => console.log(error));
		}

		if(tab_no === 3) {

			axios.get('/api/admin/logs')
				.then(response => {

					setGlobalState(prev_state => ({ ...prev_state,
						logs: response.data,
					}));
				})
				.catch(error => console.log(error));
		}

	}, [tab_no]);


	// define variables for items
	var companies_rows = [];
	var users_rows = [];

	if (global_state.companies.length > 0) {
		companies_rows = global_state.companies.map(item => {
				return({
					key: item.company_id,
					id: item.company_id,
					name: item.name,
					address: item.address,
					registration_code: item.registration_code,
					eic_x_code: item.eic_x_code,
				})
			})
	};

	if ((global_state.users.length > 0) && (global_state.companies.length > 0)) {
		users_rows = global_state.users.map(item => {
			return ({
				key: item.user_id,
				id: item.user_id,
				company: global_state.companies.find(company => company.company_id === item.company_id).name,
				last_name: item.last_name,
				first_name: item.first_name,
				email: item.email,
				phone: item.phone,
			})
		})
	};


	if (!user.is_logged_in || !(is_user_authorised(user.authorisation, 1) || is_user_authorised(user.authorisation, 4)) ) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<adminContext.Provider value={{ global_state, setGlobalState }}>
				<Switch>
					<Route
						path='/admin/company/add'
						component= { CompanyEditForm }
					/>
					<Route
						path='/admin/company/:company_id'
						component= { CompanyEditForm }
					/>
					<Route
						path='/admin/user/add'
						component = { UserEditForm }
					/>
					<Route
						exact
						path='/admin/user/:user_id'
						component = { UserEditForm }
					/>
					<Route
						exact
						path='/admin/user/:user_id/block'
						component = { UserBlockForm }
					/>
					<Route
						exact
						path='/admin/user/:user_id/auth'
						component = { UserAuthForm }
					/>
					<Route
						exact
						path='/admin/import'
						component = { ImportForm }
					/>
					<Route
						exact
						path='/admin/create_lic'
						component = { CreateLicForm }
					/>
					<Route path='/admin'>

						<div className={ classes.root }>

							<AppBar position='static' >
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Адміністрування
									</Typography>
									
									<Tooltip title='Додати'>
										<IconButton
											onClick={ addItemHandler }
											color='inherit'
										>
											<AddIcon />
										</IconButton>
									</Tooltip>

									<div className={ classes.right_button }>

										<Tooltip title='Ліцензійна інформайція'>
											<IconButton
												onClick={ onLicenseButtonClick }
												color='inherit'
											>
												<InfoIcon />
											</IconButton>
										</Tooltip>

										<IconButton
											onClick={ () => props.history.push('/') }
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<Tabs
								key={'tabs-admin'}
								value={tab_no}
								onChange={OnTabChage }
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab key={'tab-admin-0'} label='Компанії' {...getTabsProps(0)} />
								<Tab key={'tab-admin-1'} label='Користувачі' {...getTabsProps(1)} />
								{
									(is_user_authorised(user.authorisation, 30) || is_user_authorised(user.authorisation, 31)) &&
									<Tab key={'tab-admin-2'} label='Налаштування' {...getTabsProps(2)} />
								}
								{
									is_user_authorised(user.authorisation, 30) &&
									<Tab key={'tab-admin-3'} label='Лог файли' {...getTabsProps(3)} />
								}
							</Tabs>

							<TabPanel key={'tab-panel-admin-0'} value={tab_no} index={0}>

								<CompanyTable
									rows={ companies_rows }
								/>

							</TabPanel>

							<TabPanel key={'tab-panel-admin-1'} value={tab_no} index={1}>

								<UserTable
									rows={ users_rows }
									logged_in={ global_state.logged_in_users }
									show_edit_action={ is_user_authorised(user.authorisation, 2) }
									show_admin_actions={ is_user_authorised(user.authorisation, 3) }
								/>

							</TabPanel>

							<TabPanel key={'tab-panel-admin-2'} value={tab_no} index={2}>

								{
									is_user_authorised(user.authorisation, 30) &&
									<SettingsConfigForm />
								}
								{
									is_user_authorised(user.authorisation, 31) &&
									<SettingsTableForm />
								}

							</TabPanel>

							<TabPanel key={'tab-panel-admin-3'} value={tab_no} index={3}>

								{
									(global_state.logs.pt_logs.length > 0) &&
									<p>
										Загальні логи PowerTrade
									</p>
								}
								{
									global_state.logs.pt_logs.map((item, index) => {
										return (
											<div
												key={index}
											>
												<a href={item.link}>{item.file_name}</a>
											</div>
										)
									})
								}

								{
									(global_state.logs.mms_logs.length > 0) &&
									<p>
										Останні файли коммунікації з MMS
									</p>
								}
								{
									global_state.logs.mms_logs.map((item, index) => {
										return (
											<div
												key={index}
											>
												<a href={item.link}>{item.file_name}</a>
											</div>
										)
									})
								}

								{
									(global_state.logs.xmtrade_logs.length > 0) &&
									<p>
										Останні файли коммунікації з XMtrade
									</p>
								}
								{
									global_state.logs.xmtrade_logs.map((item, index) => {
										return (
											<div
												key={index}
											>
												<a href={item.link}>{item.file_name}</a>
											</div>
										)
									})
								}

							</TabPanel>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ '' }
							onClose={ onErrorMessageClose }
						/>

						<LicenseNotificationDialog
							open={ lic_info_dialog.show }
							onClose={ onLicDialogClose }
							license={ lic_info_dialog.license }
						/>

					</Route>
				</Switch>
			</adminContext.Provider>
		)
	}
}

export default withRouter(AdminForm)