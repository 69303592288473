import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));

var row_id;

export default function SchedulesTable(props) {

	const classes = useStyles();

	const { rows, selected, setSelected, onOpenRequest, onActionSelect, action_list } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');
	const [page, setPage] = React.useState(0);
	const [rows_per_page, setRowsPerPage] = React.useState(3);


	const head_cells = [
		{ id: 'start', alignTo: 'left', disablePadding: false, label: 'Доба торгівлі' },
		{ id: 'domain', alignTo: 'left', disablePadding: false, label: 'Зона балансування' },
		{ id: 'status', alignTo: 'left', disablePadding: false, label: 'Статус' },
		{ id: 'revision', alignTo: 'right', disablePadding: false, label: 'Ревізія' },
		{ id: 'sender', alignTo: 'left', disablePadding: false, label: 'Відправник' },
		{ id: 'amount', alignTo: 'right', disablePadding: false, label: 'Обсяг' },
	];


	if (onActionSelect !== undefined) {
		head_cells.push(
			{ id: 'action', alignTo: 'right', disablePadding: false, label: 'Дія' }
		)
	}


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};


	const handleClick = (id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};


	const handleDoubleClick = (id) => {
		if(onOpenRequest !== undefined) {
			onOpenRequest(id);
		}
	};


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};


	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};


	const handleOpenMenu = (event, id) => {
		// store order id
		row_id = id
		setAnchorEl(event.currentTarget);
	};


	const handleCloseMenu = (id) => {

		if (onActionSelect !== undefined) {
			onActionSelect(id, row_id)
		}
		setAnchorEl(null);
	};


	const isSelected = (id) => selected.indexOf(id) !== -1;
	const emptyRows = rows_per_page - Math.min(rows_per_page, rows.length - page * rows_per_page);

	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={selected.length}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy))
								.slice(page * rows_per_page, page * rows_per_page + rows_per_page)
								.map((row, index) => {

									const item_is_selected = isSelected(row.id);
									const label_id = `row-checkbox-${index}`;

									return (
										<TableRow
											hover
											role='checkbox'
											aria-checked={item_is_selected}
											key={row.key}
											selected={item_is_selected}
											onDoubleClick={() => handleDoubleClick(row.id)}
										>

											<TableCell padding='checkbox'>
												<Checkbox
													checked={item_is_selected}
													onClick={() => handleClick(row.id)}
													inputProps={{ 'aria-labelledby': label_id }}
												/>
											</TableCell>
											<TableCell component='th' id={label_id} scope='row' padding='none'>
												{row.start}
											</TableCell>
											<TableCell align='left'>{row.domain}</TableCell>
											<TableCell align='left'>{row.status}</TableCell>
											<TableCell align='right'>{row.revision}</TableCell>
											<TableCell align='left'>{row.sender}</TableCell>
											<TableCell align='right'>{row.amount}</TableCell>

											{
												(onActionSelect !== undefined) &&
												<TableCell align='right'>
													<IconButton
														size='small'
														onClick={(event) => handleOpenMenu(event, row.id)}
														color='inherit'
													>
														<ActionIcon />
													</IconButton>
												</TableCell>
											}
										</TableRow>
									);
								})
						}
						{
							emptyRows > 0 && (
								<TableRow style={{ height: 33 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)
						}
					</TableBody>
				</Table>

			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[3, 6, 9]}
				component='div'
				count={rows.length}
				rowsPerPage={rows_per_page}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>

			{
				(action_list !== undefined) &&
				<Menu
					id='schedules-simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					{
						action_list.map(item => 
							<MenuItem key={item.id} onClick={() => handleCloseMenu(item.id)}>{ item.name }</MenuItem>
						)
					}
				</Menu>
			}

		</Paper>
	);
}

SchedulesTable.propTypes = {
	rows: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
	onOpenRequest: PropTypes.func,
	action_list: PropTypes.array,
	onActionSelect: PropTypes.func
};