import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
	},

	direct_row: {
		background: '#FFFAF0',
	},

	indirect_row: {
		background: '#F8F8FF',
	},

	result_cell: {
		fontWeight: 'bold',
		fontSize: '11px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '75px',
	}

}));


export default function MonthResultsView(props) {
	const classes = useStyles();

	const { hours_count, days_count, period, rows } = props;

	const display_rows = [];
	const block_total = [];

	for (let r = 0; r <= days_count + 1; r++) {

		let row_date = new Date(period);
		row_date.setDate(r);

		let filtered_rows = rows.filter(item => {
			const item_date = new Date(item.trade_date);
			return (item_date.getFullYear() === row_date.getFullYear() && 
					item_date.getMonth() === row_date.getMonth() &&
					item_date.getDate() === row_date.getDate())
		});

		// initialise cells
		const cells_array = [];
		let sum = 0;
		for (let c = 0; c <= hours_count + 1; c++) {

			// initialize
			if (block_total[c] === undefined) block_total[c] = 0;

			let value = 0;
			if (c > 0 && c <= hours_count && filtered_rows.length === 1) {

				const index = (c < 10) ? `p0${c}` : `p${c}`;
				value = parseFloat(filtered_rows[0][index]);

				block_total[c] += value;
			}

			if (r === days_count + 1) {
				value = block_total[c];
			}

			sum += value;

			cells_array[c] = {
				id: `${r}.${c}`,
				label: (c <= hours_count) ? c : 'Всього',
				value: (c <= hours_count) ? value : sum,
			}
		}

		display_rows[r] = {
			id: r,
			label: (r === 0) ? 'День' : (r <= days_count) ? getLocalDateString(row_date) : 'Всього',
			cells: cells_array,
		}
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer>
				<Table size= {'small'} className={classes.table} >
					<TableBody>

						{
							display_rows.map((row, row_index) => (
								<TableRow
									hover
									key={ row.id }
									className={ (row_index % 2 !== 0) ? classes.direct_row : classes.indirect_row }
								>
									{
										row.cells.map((cell, cell_index) => (
											<TableCell
												key={ cell.id }
												className={ ((row_index === 0) || (cell_index === 0)) ? classes.head : ((row_index === days_count + 1) || (cell_index === hours_count + 1)) ? classes.result_cell : classes.cells }
											>
												{
													(cell_index === 0) ?
														row.label
													:
														(row_index === 0) ? cell.label : cell.value.toFixed(1)
												}
											</TableCell>
										))
									}
								</TableRow>
							))
						}

					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
};