import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddBox';
import HelpIcon from '@material-ui/icons/HelpSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';

import NumericTextField from '../../subcomponents/NumericTextField';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	container: {
		maxHeight: 600,
	},

	table_toolbar: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
	},

	row_data: {
		background: '#D3D3D3',
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '75px',
	}

}));


function DamBlocksEdit(props) {
	const classes = useStyles();

	const { columns_count, blocks_count, max_blocks, rows, onTableValueUpdate, onBlockAdd, onBlockDelete, onHelpClicked, onPaste } = props;

	const initial_value = {
		block_edit: -1,
		period_edit: -1,
		cell_edit: -1,
		value_edit: 0,
	}
	const [state, setState] = useState(initial_value);


	function onAddBlock() {
		if (blocks_count < max_blocks) {
			onBlockAdd();
		}
	}


	function onDeleteBlock(block_index) {
		if (block_index === blocks_count - 1) {
			onBlockDelete(block_index + 1);
		} 
	}


	function onMouseClickEvent (block, period, cell) {

		const external_row = rows.find(item => item.block_number === block + 1 && item.period === period);
		
		let value = '';
		if (external_row !== undefined) {
			switch (cell) {
				case 1:
					value = external_row.amount;
					break;
				case 2:
					value = external_row.price;
					break;
				case 3:
					value = external_row.splitting_type_id;
					break;
				default:
					break;
			}
		}

		setState(prev_state => ({ ...prev_state,
			block_edit: block,
			period_edit: period,
			cell_edit: cell,
			value_edit: value,
		}));
	}


	function onValueChange(block, period, cell, event) {

		const event_value = event.target.value;

		// update rows
		onTableValueUpdate(block + 1, period, cell, event_value);

		// update internal state
		setState(prev_state => ({ ...prev_state,
			value_edit: event_value,
		}));
	}


	function onValuePaste(block, period, cell, event) {
		onPaste(block + 1, period, cell, event);
	}


	function onKeyDown(period, event) {

		if (event.key === 'Tab') {

			event.preventDefault();

			setState(prev_state => ({ ...prev_state,
				period_edit: period + 1,
			}));
		}
	}


	const head_cells = [];
	for (let h = 0; h <= columns_count; h++) {
		head_cells[h] = {
			id: h,
			label: (h !== 0) ? h : 'Період',
		};
	}

	const blocks_array = []
	for (let b = 0; b < blocks_count; b++) {

		// initialise rows
		const rows_array = [];
		for (let r = 0; r < 4; r++) {

			// only first block has 3 rows
			if (b > 0 && r === 3) {
				continue;
			}

			// initialise cells
			const cells_array = [];
			for (let c = 0; c <= columns_count; c++) {

				let label_text, cell_value;
				const row_item = rows.find(item => item.block_number === b + 1 && item.period === c);

				if (c === 0) {
					switch (r) {
						case 0:
							label_text = 'Період';
							break;

						case 1:
							label_text = "Обсяг (МВтг)";
							break;
	
						case 2:
							label_text = 'Ціна (грн/МВтг)';
							break;
	
						case 3:
							label_text = 'Виробнича подільність';
							break;
	
						default:
							break;
					}
				} else {

					label_text = head_cells[c].label;

					switch (r) {
						case 1:
							cell_value = (row_item !== undefined) ? row_item.amount : '';
							break;
	
						case 2:
							cell_value = (row_item !== undefined) ? row_item.price : '';
							break;
	
						case 3:
							cell_value = (row_item !== undefined) ? row_item.splitting_type_id : 1;
							break;
	
						default:
							break;
					}
				}

				cells_array[c] = {
					id: `${b+1}.${r}.${c}`,
					label: (c === 0) || (r === 0) ? label_text : undefined,
					value: cell_value,
				}
			}

			rows_array[r] = {
				id: `${b+1}.${r}`,
				cells: cells_array,
			}
		}

		// initialise block
		blocks_array[b] = {
			id: `${b+1}`,
			label: `Блок ${b+1}`,
			rows: rows_array,
		};
	}


	return (
		<Paper className={classes.paper}>
			<Toolbar>
				<Typography variant='h6'>
					Блоки заявки
				</Typography>
				<div key={ 'header'} className={classes.table_toolbar}>
					<IconButton
						key={ 'help_action' }
						onClick={ () => onHelpClicked() }
					>
						<HelpIcon />
					</IconButton>
					<IconButton
						key={ 'add_block_action' }
						disabled={ blocks_count === max_blocks }
						onClick={ () => onAddBlock() }
					>
						<AddIcon />
					</IconButton>
				</div>
			</Toolbar>

			<TableContainer className={classes.container}>
				<Table size= {'small'} className={classes.table} >
					{
						blocks_array.map((block, block_index) => (
							<TableBody
								key={ block.id }
							>
								<TableRow
									key={ `${block.id}:header_row` }
								>
									<TableCell
										key={ `${block.id}:delete_cell` }
										className={ classes.head }
									>
										{
											(block_index > 0) &&
											<IconButton
												disabled={ block_index !== blocks_count - 1 }
												onClick={ () => onDeleteBlock(block_index) }
											>
												<DeleteIcon />
											</IconButton>
										}
									</TableCell>

									<TableCell
										key={ `${block.id}:header_cell` }
										className={ classes.head }
										align='center'
										colSpan={ columns_count }
									>
										{ block.label }
									</TableCell>
								</TableRow>

								{
									block.rows.map((row, row_index) => (

										<TableRow
											hover
											key={ row.id }
											className={ classes.row_data }
										>
											{
												row.cells.map((cell, cell_index) => (
													<TableCell
														key={ cell.id }
														className={ (row_index === 0) || (cell_index === 0) ? classes.head : classes.cells }
														onClick={ (row_index !== 0) || (cell_index !== 0) ? () => onMouseClickEvent(block_index, cell_index, row_index) : undefined }
													>
														{
															(row_index === 0) || (cell_index === 0)
																?
																	cell.label
																:
																	(block_index === state.block_edit) && (cell_index === state.period_edit) && (row_index === state.cell_edit)
																	? 
																		(row_index === 3)
																			?
																				<Select
																					native
																					value={ state.value_edit }
																					onChange={ (event) => onValueChange(block_index, cell_index, row_index, event) }
																				>
																					<option key={1} value={1}>Так</option>
																					<option key={2} value={2}>Ні</option>
																				</Select>
																			:
																				<NumericTextField
																					value={ state.value_edit }
																					onChange={ (event) => onValueChange(block_index, cell_index, row_index, event) }
																					onPaste={ (event) => onValuePaste(block_index, cell_index, row_index, event) }
																					onKeyDown={ (event) => onKeyDown(cell_index, event) }
																				/>
																	: (row_index === 3) ?
																			(cell.value === 1) ? 'Так' : 'Ні'
																			: cell.value
														}
													</TableCell>
												))
											}
										</TableRow>
									))
								}
							</TableBody>
						))
					}
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default DamBlocksEdit;