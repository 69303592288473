import React from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/SearchSharp';


const useStyles = makeStyles(theme => ({

	search: {
		// position: 'relative',
		border: '1px solid',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
	},

	search_icon: {
		verticalAlign: 'middle',
		padding: theme.spacing(1),
	},

	search_input: {
		verticalAlign: 'middle',
		color: 'inherit',
		width: '80%'
	},

}));


function FilterSearch(props) {
	const classes = useStyles();

	return (
		<div className={classes.search}>

			<SearchIcon className={classes.search_icon}/>

			<InputBase
				placeholder='Пошук…'
				className={classes.search_input}
				onChange={props.onSearchChange}
				value={props.value}
			/>

		</div>
	)
}

export default FilterSearch;


FilterSearch.propTypes = {
	value: PropTypes.string.isRequired,
	onSearchChange: PropTypes.func.isRequired,
};