import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import PriceTable from './subcomponents/PriceTable';

import FilterPeriodDomain from '../subcomponents/FilterPeriodDomain';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import DamPricesImportForm from './DamPricesImportForm';

import { globalContext } from '../layout/Context';
import { damPricesContext } from './damPricesContext';
import NotAuthorised from '../auth/NotAuthorised';

import { is_valid_date } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function DamPrices(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function createInitialFilter () {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(24, 0, 0, 0);

		const complex_state = {
			enable: false,
			domain: 1,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function createInitialState () {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			prices: [],
		}

		return complex_state;
	}


	function onFilterChange (start, end, domain) {

		setFilter(filter => ({ ...filter,
			domain: parseInt(domain),
			period_start: start,
			period_end: end,
		}));
	}


	function onFilterClick () {

		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({ ...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose () {
	
		setMessage(message => ({...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/capacities');

				setGlobalState(prev_state => ({ ...prev_state,
					domains: res_market_defs.data.domain_list,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		async function fetchData(filter) {

			if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

				try {

					const resp_prices = await axios.get('/api/dam_prices', {
						params: {
							domain:			filter.domain,
							period_start:	getDateFormat(filter.period_start),
							period_end:		getDateFormat(filter.period_end)
						}
					});

					setGlobalState(prev_state => ({ ...prev_state,
						prices: resp_prices.data.dam_prices,
					}));

				} catch(error) {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				}

				setFilter(filter => ({ ...filter, enable: false }));
			}
		}

		fetchData(filter);

	}, [filter])


	const list_prices = (item) => {

		const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);

		return ({
			key: item.price_id,
			id: item.price_id,
			domain: (domain !== undefined) ? domain.domain_display_name : '',
			...item
		})
	}

	const domain_list = global_state.domains.map(item => <option key={item.domain_id} value={item.domain_id}>{ item.domain_display_name }</option>);
	const display_prices = global_state.prices.map(item => list_prices(item));


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 24)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<damPricesContext.Provider value={{ global_state, setGlobalState }}>
				<Switch>
					<Route
						path='/dam_prices/import'
						component={ DamPricesImportForm }
					/>
					<Route
						path='/dam_prices'
					>

						<div className = { classes.root } >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Архів цін РДН
									</Typography>

									<Tooltip title='Імпорт'>
										<span>
											<IconButton
												onClick={ () => { props.history.push('/dam_prices/import') } }
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									<FilterPeriodDomain
										period_start={filter.period_start}
										period_end={filter.period_end}
										domain={filter.domain}
										doamin_list={ domain_list }
										onFilterChange={onFilterChange}
										onFilterClick={onFilterClick}
									/>

									<div className={classes.right_button}>

										<IconButton
											onClick={ () => props.history.push('/') }
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<PriceTable
								rows={display_prices}
							/>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ '' }
							onClose={ onErrorMessageClose }
						/>

					</Route>
				</Switch>
			</damPricesContext.Provider>
		)
	}
}

export default withRouter(DamPrices);