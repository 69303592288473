import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { contractsContext } from './contractsContext';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_top_margin: {
		marginTop: theme.spacing(2),
	},

}));


export default function PlanRowEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label
	const contract_type_label = React.useRef(null);
	const [contract_label_width, setContractLabelWidth] = useState(0);

	// import context
	const { global_state, setGlobalState } = useContext(contractsContext);

	// get forecast id from url
	const contract_id = parseInt(props.match.params.contract_id);
	const group_id = parseInt(props.match.params.group_id);
	const update_url = isNaN(group_id) ? '/api/es/group/add' : '/api/es/group/' + group_id;


	function createInitialState () {

		const complex_state = {

			contract_type_id: 0,

			period_start: '',
			period_end: '',

			capacity_price: 0,
			capacity_price_valid: true,
			external_tax: 0,
			external_tax_valid: true,
			internal_tax: 0,
			internal_tax_valid: true,
			transition_tariff: 0,
			transition_tariff_valid: true,
			income_ratio: 0,
			income_ratio_valid: true,
			additional_expenses: 0,
			additional_expenses_valid: true,

			changed: true,
		}

		return complex_state;
	}


	function handleContractTypeChange (event) {

		const event_value = event.target.value;
		setLocalState(prev_state => ({ ...prev_state,
			contract_type_id: parseInt(event_value),
		}));
	}


	function handlePeriodStartChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			period_start: event_value,
			changed: true,
		}));
	}


	function handlePeriodEndChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			period_end: event_value,
			changed: true,
		}));
	}


	function handleCapacityPriceChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			capacity_price: event_value,
			capacity_price_valid: !isNaN(parseFloat(event_value)) && parseFloat(event_value) > 0,
			changed: true,
		}));
	}


	function handleExternalTaxChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			external_tax: event_value,
			external_tax_valid: !isNaN(parseFloat(event_value)) && parseFloat(event_value) >= 0,
			changed: true,
		}));
	}


	function handleInternalTaxChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			internal_tax: event_value,
			internal_tax_valid: !isNaN(parseFloat(event_value)) && parseFloat(event_value) >= 0 && parseFloat(event_value) <= 1,
			changed: true,
		}));
	}


	function handleTransitionTariffChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			transition_tariff: event_value,
			transition_tariff_valid: !isNaN(parseFloat(event_value)) && parseFloat(event_value) > 0,
			changed: true,
		}));
	}


	function handleIncomeRatioChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			income_ratio: event_value,
			income_ratio_valid: !isNaN(parseFloat(event_value)) && parseFloat(event_value) >= 0 && parseFloat(event_value) <= 1,
			changed: true,
		}));
	}


	function handleAdditionalExpensesChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			additional_expenses: event_value,
			additional_expenses_valid: !isNaN(parseFloat(event_value)),
			changed: true,
		}));
	}


	async function saveHandler () {

		try {

			const new_data = {
				contract_id: contract_id,
				period_start: getDateFormat(local_state.period_start),
				period_end: getDateFormat(local_state.period_end),
				capacity_price: local_state.capacity_price,
				external_tax: local_state.external_tax,
				internal_tax: local_state.contract_type_id === 0 ? local_state.internal_tax : undefined,
				transition_tariff: local_state.contract_type_id === 1 ? local_state.transition_tariff : undefined,
				income_ratio: local_state.income_ratio,
				additional_expenses: local_state.additional_expenses
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		var data_valid = local_state.capacity_price_valid &&
						local_state.external_tax_valid &&
						local_state.internal_tax_valid &&
						local_state.transition_tariff_valid &&
						local_state.income_ratio_valid &&
						local_state.additional_expenses_valid;

		if (data_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.go(-1);
			}
		} else {

			window.alert('Перевірте правільність даних.');
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setContractLabelWidth(contract_type_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		if (group_id) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { period_start, period_end, capacity_price, external_tax, internal_tax,
								transition_tariff, income_ratio, additional_expenses } = response.data;

						setLocalState(prev_state => ({ ...prev_state,

							contract_type_id: (internal_tax) ? 0 : 1,
							period_start: getDateFormat(period_start),
							period_end: getDateFormat(period_end),

							capacity_price: capacity_price,
							external_tax: external_tax,
							internal_tax: internal_tax,
							transition_tariff: transition_tariff,
							income_ratio: income_ratio,
							additional_expenses: additional_expenses,

							changed: false,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [group_id, update_url])


	useEffect(() => {

		if (contract_id) {

			axios.get('/api/admin/contract/' + contract_id)
				.then(response => {

					const { contract } = response.data;

					setLocalState(prev_state => ({ ...prev_state,
						period_start: getDateFormat(contract.contract_date),
						period_end: getDateFormat(contract.valid_date),
					}));
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [contract_id])


	// filling select control for domains
	var display_contract_types = [
		<option key={ 0 } value={ 0 } >Імпорт</option>,
		<option key={ 1 } value={ 1 } >Експорт</option>
	];


	return (

		<Container className={ classes.root_form } component='main' maxWidth='sm'>

			<Avatar className={ classes.avatar }>
				{ isNaN(group_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(group_id) ? 'Додати фінансові показники' : 'Редагувати фінансові показнки' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Grid container spacing={2}>

					<Grid item xs={6}>

						<FormControl variant='outlined' required className={classes.select_form_control} fullWidth>
							<InputLabel ref={ contract_type_label } id='contract-type'>Тип договору</InputLabel>
							<Select
								native
								labelId='contract-type'
								labelWidth={ contract_label_width }
								className={classes.select_control}
								value={ local_state.contract_type_id }
								onChange={ handleContractTypeChange }
							>
								{ display_contract_types }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							variant='outlined'
							fullWidth
							label='Початок періоду'
							type='date'
							value={ local_state.period_start }
							onChange={ handlePeriodStartChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={6}>

						<TextField
							variant='outlined'
							fullWidth
							label='Кінець періоду'
							type='date'
							value={ local_state.period_end }
							onChange={ handlePeriodEndChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

				</Grid>

				<TextField
					className={classes.control_top_margin}
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Ціна перетину, EUR'
					onChange={ handleCapacityPriceChange }
					error={ !local_state.capacity_price_valid }
					value={ local_state.capacity_price }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Зовнішній налог, EUR'
					onChange={ handleExternalTaxChange }
					error={ !local_state.external_tax_valid }
					value={ local_state.external_tax }
				/>

				{ (local_state.contract_type_id === 0) &&
				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Внутрішній налог, %'
					onChange={ handleInternalTaxChange }
					error={ !local_state.internal_tax_valid }
					value={ local_state.internal_tax }
				/> }

				{ (local_state.contract_type_id === 1) &&
				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Тариф на передачу, грн'
					onChange={ handleTransitionTariffChange }
					error={ !local_state.transition_tariff_valid }
					value={ local_state.transition_tariff }
				/> }

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Коефіціент доходу, %'
					onChange={ handleIncomeRatioChange }
					error={ !local_state.income_ratio_valid }
					value={ local_state.income_ratio }
				/>

				<TextField
					margin='normal'
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					fullWidth
					label='Додаткові розходи, грн'
					onChange={ handleAdditionalExpensesChange }
					error={ !local_state.additional_expenses_valid }
					value={ local_state.additional_expenses }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button_control }
						disabled={ !local_state.changed }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button_control }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}