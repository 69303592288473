
export function excel_date_to_iso_date_string(excel_number) {
	return new Date(Math.round((excel_number - 25569) * 86400 * 1000)).toISOString().substring(0, 10);
}


export function iso_date_to_excel_date(iso_date) {
	return new Date(iso_date) / 86400 / 1000 + 25569;
}


export function excel_date_to_iso_date_time(excel_number) {
	return new Date(Math.round((excel_number - 25569) * 86400 * 1000)).toISOString().slice(0, -1);
}


export function excel_date_to_date(excel_number) {
	return new Date(Math.round((excel_number - 25569) * 86400 * 1000));
}


export function excel_date_hour_to_iso_date_time(excel_number, hour) {
	let result = new Date(Math.round((excel_number - 25569) * 86400 * 1000));
	result.setHours(hour);
	return result.toISOString();
}