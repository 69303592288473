import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
	},

	block: {
		width: '400px',
		padding: theme.spacing(2),
	}

}));


export default function IdmHelpDialog(props) {
	const classes = useStyles();
	const { open, onClose, selectedValue } = props;


	const handleClose = () => {
		onClose(selectedValue);
	};


	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby='help-dialog-title' >
			<DialogTitle id='help-dialog-title' className={classes.header} >Заповнення заявки ВДР</DialogTitle>

			<details className={classes.block}>
				<summary><b>Період з</b></summary>
				<p align='justify'>
					Початковий період простого замовлення або визначеного користувачем блочного замовлення (для заздалегідь визначених замовлень BL, PL, OP залишити незаповненим).
				</p>
				<p><i>Формат: ГГ</i></p>
			</details>

			<details className={classes.block}>
				<summary><b>Період до</b></summary>
				<p align='justify'>
					Кінцевий період простого замовлення або визначеного користувачем блочного замовлення (для заздалегідь визначених замовлень BL, PL, OP залишити незаповненим).
				</p>
				<p><i>Формат: ГГ</i></p>
			</details>

			<details className={classes.block}>
				<summary><b>Тип замовлення</b></summary>
				<p align='justify'>
					<li>N - Просте;</li>
					<li>A - Блочне.</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Тип блоку</b></summary>
				<p align='justify'>
					У випадку простих замовлень залишити незаповненим. Виберіть одне значення для блочних замовлень:
					<li>BL - Base load - базове навантаження;</li>
					<li>PL - Peak load - пікове навантаження;</li>
					<li>OP - Off peak - непікове навантаження;</li>
					<li>V - User-defined block - визначені користувачем блоки.</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Напрямок</b></summary>
				<p align='justify'>
					<li>N - Купівля;</li>
					<li>P - Продаж.</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Індикація</b></summary>
				<p align='justify'>
					<li>N - без обмеження;</li>
					<li>FOK - Fill or Kill;</li>
					<li>IOC - Immediate or cancel;</li>
					<li>AON - All or None - лише для визначених користувачем блочних замовлень.</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Ціна</b></summary>
				<p align='justify'>
					Ціна в грн/МВтг.
				</p>
				<p><i>Формат: число (2 десяткові знаки)</i></p>
			</details>

			<details className={classes.block}>
				<summary><b>Кількість</b></summary>
				<p align='justify'>
					Кількість в МВтг.
				</p>
				<p><i>Формат: число (1 десятковий знак)</i></p>
			</details>

			<details className={classes.block}>
				<summary><b>Активний</b></summary>
				<p align='justify'>
					<li>A - Так</li>
					<li>N - Ні</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Дійсний до</b></summary>
				<p align='justify'>
					Дата і час дії замовлення. Якщо незаповнено, то замовлення буде дійсне до часу закриття воріт протягом певного періоду.
				</p>
				<p><i>Формат: ДД.ММ.РРРР ГГ:ХХ:СС</i></p>
			</details>

			<details className={classes.block}>
				<summary><b>Коментар</b></summary>
				<p align='justify'>
					Довільний текст.
				</p>
			</details>

		</Dialog>
	);
}


IdmHelpDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};