import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditPlanRowsTable from './EditPlanRowsTable';


const useStyles = makeStyles(theme => ({

	sizer: {
		width: '950px'
	},

	header: {
		textAlign: 'center',
		fontWeight: 'bold',
	},

	schedule_block: {
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},

	table_header: {
		textAlign: 'left',
		padding: theme.spacing(1),
	},

	table_block: {
		textAlign: 'center',
		border: '1px solid gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	table_style: {
		width: '100%',
		tableLayout: 'fixed',
		padding: theme.spacing(2, 0, 0, 0),

		'& td:nth-child(even)': {
			backgroundColor: '#E8E8E8',
		},

		'& td:nth-child(odd)': {
			backgroundColor: '#F8F8F8',
		},

		'& th': {
			fontWeight: 'bold',
			backgroundColor: 'white',
		},
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


var edit_rows = [];


export default function PlanRowsDetailsDialog(props) {

	const classes = useStyles();
	const { consumer, seller, rows, row_to_add, add_direction, open, onClose, onSend, onAdd } = props;


	const onAddHandler = () => {

		if (rows.length > 0) {

			const consumer = rows[0].consumer_company_id;
			const seller = rows[0].seller_company_id;
			const trade_date = rows[0].trade_date;
			const domain = rows[0].domain_id;

			onAdd(domain, consumer, seller, trade_date);
		}
	}


	const onSaveHandler = () => {
		onClose(edit_rows);
	};


	const onSendHandler = () => {
		onSend(edit_rows);
	}


	const onCancelHandler = () => {
		onClose(undefined);
	};


	if (rows.length > 0) {
		edit_rows = rows;
	}


	return (
		<Dialog
			open={open}
			maxWidth={false}
			aria-labelledby='upload-dialog-title'
		>
			<DialogTitle id='upload-dialog-title' className={classes.header} >
				Планування обсягів електричної енергії
			</DialogTitle>

			<DialogContent>

				<div className={classes.sizer} />
				
				<Typography>
					<b>Продавець</b>: { seller }, <b>Покупець</b>: { consumer }
				</Typography>

				<EditPlanRowsTable
					rows={ edit_rows }
					row_to_add={ row_to_add }
					add_direction={ add_direction }
					selected={[]}
					setSelected={ () => {} }
					onChangeRow={ () => {} }
				/>

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { onAddHandler }
					>
						+
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { onSaveHandler }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { onSendHandler }
					>
						Відправити на погодження
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</DialogContent>
		</Dialog>
	);
}

PlanRowsDetailsDialog.propTypes = {
	consumer: PropTypes.string.isRequired,
	seller: PropTypes.string.isRequired,
	rows: PropTypes.array.isRequired,
	row_to_add: PropTypes.object,
	add_direction: PropTypes.bool,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSend: PropTypes.func.isRequired
};