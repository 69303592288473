import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import AddIcon from '@material-ui/icons/AddSharp';
import SendIcon from '@material-ui/icons/SendSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';
import ImportExportIcon from '@material-ui/icons/ImportExportSharp';

import ExternalSeriesTable from './subcomponents/ExternalSeriesTable';
import MonthResultsView from './subcomponents/MonthResultsView';

import FilterPeriodDomain from '../subcomponents/FilterPeriodDomain';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { esContext } from './esContext';
import NotAuthorised from '../auth/NotAuthorised';

import ExternalSeriesEditForm from './ExternalSeriesEditForm';
import ExternalSeriesSendForm from './ExternalSeriesSendForm';
import ExternalSeriesImportForm from './ExternalSeriesImportForm';

import { is_valid_date } from '../utils/validation';
import { getDateFormat, getDaysInMonth, getMonthFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function ExternalSeries(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// variable for the label control
	const consumer_seller_label = React.useRef(null);
	const [consumer_seller_label_width, setConsumerSellerLabelWidth] = useState(0);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id: `es-tab-${index}`,
			'aria-controls': `es-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`es-tabpanel-${index}`}
				aria-labelledby={`es-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {

		setTabNo(tab);
	}


	function createInitialFilter() {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(24, 0, 0, 0);

		const complex_state = {
			enable: false,
			domain: 1,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function createInitialState() {

		const day = new Date();

		const complex_state = {

			update_flag: false,

			month: getMonthFormat(day),

			pair_id: -1,
			filtered_data: [],

			// internal states
			domains: [],
			companies: [],
			margin_rows: [],

			// component states
			external_series: [],
			capacities: [],
		}

		return complex_state;
	}


	function handleAddAction() {

		props.history.push('/external_series/add');
	}


	function handleSendAction() {

		props.history.push('/external_series/send');
	}


	function handleOpenAction(item) {

		if (item?.id) {
			props.history.push('/external_series/' + item.id);
		}
	}


	async function handleDeleteAction(item) {

		if (item.id) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити вибрану інформацію?')) {
				return;
			}

			try {

				// send request to back end
				const response = await axios.post(`/api/es/${item.id}/delete`);

				if (response.status === 200) {

					setGlobalState(prev_state => ({
						...prev_state,
						update_flag: !global_state.update_flag,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({
						...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	function handleConsumerSellerChange(event) {

		const event_value = event.target.value;

		setGlobalState(prev_state => ({
			...prev_state,
			pair_id: parseInt(event_value),
		}));
	}


	function handleMonthChange(event) {
		const event_value = event.target.value;

		setGlobalState(prev_state => ({
			...prev_state,
			month: event_value,
		}));
	}


	function onFilterChange(start, end, domain) {

		setFilter(filter => ({
			...filter,
			domain: parseInt(domain),
			period_start: start,
			period_end: end,
		}));
	}


	function onFilterClick() {

		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({
				...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const res_market_defs = await axios.get('/api/defs/capacities');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({
					...prev_state,
					domains: res_market_defs.data.domain_list,
					companies: res_company_data.data.company_list,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		if (consumer_seller_label.current) {
			setConsumerSellerLabelWidth(consumer_seller_label.current.offsetWidth);
		}

	}, [tab_no])


	useEffect(() => {

		async function fetchData() {

			const day_1 = new Date(global_state.month);
			const day_2 = new Date(global_state.month);
			day_2.setMonth(day_2.getMonth() + 1);
			day_2.setDate(day_2.getDate() - 1);

			try {

				// get external series
				const resp_ess = await axios.get('/api/es/', {
					params: {
						period_start: getDateFormat(day_1),
						period_end: getDateFormat(day_2),
					}
				});

				// get companies with full financial contract details
				const res_company_data = await axios.get('/api/admin/companies', {
					params: {
						full_contract: true
					}
				});

				const external_series = resp_ess.data.external_series_list;
				const companies = res_company_data.data.company_list;

				// get known ids
				const known_ids = [];
				companies.map(item => known_ids.push(item.company_id));

				const filtered = external_series.filter(item => known_ids.includes(item.consumer_company_id) && known_ids.includes(item.seller_company_id));

				const filtered_companies = [];
				for (let i = 0; i < filtered.length; i++) {

					const comp = filtered[i];
					const find_cons = companies.find(item => item.company_id === comp.consumer_company_id);
					const find_sell = companies.find(item => item.company_id === comp.seller_company_id);

					const new_company = {
						id: i,
						out_domain_id: comp.out_domain_id,
						in_domain_id: comp.in_domain_id,
						consumer_id: comp.consumer_company_id,
						seller_id: comp.seller_company_id,
						name: `${find_sell.name} -> ${find_cons.name}`
					}

					const new_company_exists = filtered_companies.find(item => item.consumer_id === find_cons.company_id && item.seller_id === find_sell.company_id);

					if (find_cons !== undefined && find_sell !== undefined && new_company_exists === undefined) {
						filtered_companies.push(new_company);
					}
				}

				setGlobalState(prev_state => ({
					...prev_state,
					filtered_data: filtered_companies,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (tab_no === 3) {
			fetchData();
		}

	}, [tab_no, global_state.month])


	useEffect(() => {

		async function fetchData() {

			// get dates first and last days of the month
			const day_1 = new Date(global_state.month);
			const day_2 = new Date(global_state.month);
			day_2.setMonth(day_2.getMonth() + 1);
			day_2.setDate(day_2.getDate() - 1);

			try {

				// calculate for paier of consumer / seller
				const pair = global_state.filtered_data.find(item => item.id === global_state.pair_id)

				if (pair) {

					// download contracts
					const resp_contracts = await axios.get('/api/admin/contracts', {
						params: {
							consumer_id: pair.consumer_id,
							seller_id: pair.seller_id,
						}
					});

					const selected_contracts = resp_contracts.data.contract_list;

					if (selected_contracts.length > 0) {

						// get groups (finance results) of selected contract
						const resp_group = await axios.get('/api/es/groups/' + selected_contracts[0].contract_id);

						// get exchange rates for the same period EUR/UAH
						const resp_ers = await axios.get('/api/ers/', {
							params: {
								period_start: getDateFormat(day_1),
								period_end: getDateFormat(day_2),
								code: 978
							}
						});

						// get external prices (for export domain) for the period
						const resp_out_prices = await axios.get('/api/dam_prices', {
							params: {
								domain: pair.out_domain_id,
								period_start: getDateFormat(day_1),
								period_end: getDateFormat(day_2),
							}
						});

						// get internal prices (for import domain) for the period
						const resp_in_prices = await axios.get('/api/dam_prices', {
							params: {
								domain: pair.in_domain_id,
								period_start: getDateFormat(day_1),
								period_end: getDateFormat(day_2),
							}
						});

						// get auction results for the period
						const resp_capacities = await axios.get('/api/capacity', {
							params: {
								out_domain: pair.out_domain_id,
								in_domain: pair.in_domain_id,
								period_start: getDateFormat(day_1),
								period_end: getDateFormat(day_2),
							}
						});

						const finance_data = resp_group.data.group_list[0];
						const exchange_rates = resp_ers.data.exchange_rates;
						const dam_out_prices = resp_out_prices.data.dam_prices;
						const dam_in_prices = resp_in_prices.data.dam_prices;
						const capacities = resp_capacities.data.capacities_list;

						const rows = [];

						// for each day of the time period
						let i_date = new Date(day_1);
						while (i_date.getTime() <= day_2.getTime()) {

							// create new row of the table
							const new_row = {
								trade_date: getDateFormat(i_date),
							};

							// select the price row of out domain
							const out_prise_row = dam_out_prices.find(item => {

								const item_date = new Date(item.trade_date);
								return (item_date.getFullYear() === i_date.getFullYear() &&
									item_date.getMonth() === i_date.getMonth() &&
									item_date.getDate() === i_date.getDate())
							});

							// select the price row of in domain
							const in_prise_row = dam_in_prices.find(item => {

								const item_date = new Date(item.trade_date);
								return (item_date.getFullYear() === i_date.getFullYear() &&
									item_date.getMonth() === i_date.getMonth() &&
									item_date.getDate() === i_date.getDate())
							});

							// select exchange rate
							const ex_rate_row = exchange_rates.find(item => {

								const item_date = new Date(item.trade_date);
								return (item_date.getFullYear() === i_date.getFullYear() &&
									item_date.getMonth() === i_date.getMonth() &&
									item_date.getDate() === i_date.getDate())
							});

							// get all capacities for the day
							const capacity_rows = capacities.filter(item => {

								const item_period_start = new Date(item.period_start);
								const item_period_end = new Date(item.period_end);

								return (
									(
										item.type === 0 &&
										item_period_start.getFullYear() === i_date.getFullYear() &&
										item_period_start.getMonth() === i_date.getMonth() &&
										item_period_start.getDate() === i_date.getDate()
									) ||
									(
										item.type > 0 &&
										item_period_start.getTime() <= i_date.getTime() &&
										item_period_end.getTime() >= i_date.getTime()
									)
								)
							});

							// construct single capacity row - combine all capacities for day - month
							// result = sum(price[i] * volume[i]) / sum(volume[i])
							const capacity_row = {
								trade_date: getDateFormat(i_date)
							};

							// for each index
							for (let h = 1; h <= 25; h++) {

								const target_index = (h < 10) ? `p0${h}` : `p${h}`;

								// for each row in capacity rows
								let volume = 0;
								for (let r = 0; r < capacity_rows.length; r++) {

									const the_row = capacity_rows[r];

									// calculate index
									const source_index = (the_row.type === 0) ? (h < 10) ? `p0${h}` : `p${h}` : 'p01';

									if (the_row[`${source_index}_pr`] !== undefined && the_row[`${source_index}_pr`] !== null) {

										if (capacity_row[target_index] === undefined) {
											capacity_row[target_index] = 0;
										}

										const price = the_row[`${source_index}_pr`];
										const cap = the_row[`${source_index}_rs`];

										capacity_row[target_index] += price * cap;
										volume += cap;
									}
								}

								capacity_row[target_index] /= volume;
							}

							// calculate margin row
							if (out_prise_row !== undefined && in_prise_row !== undefined) {

								for (let h = 1; h <= 25; h++) {

									const index = (h < 10) ? `p0${h}` : `p${h}`;

									if (out_prise_row[index] !== undefined && out_prise_row[index] !== null &&
										in_prise_row[index] !== undefined && in_prise_row[index] !== null) {

										// calculate sell price
										const sell_price = in_prise_row[index] - capacity_row[index];

										// calculate buy price
										const buy_price = (out_prise_row[index] + finance_data.capacity_price + finance_data.external_tax) *
											(1 + finance_data.internal_tax) * ex_rate_row.er;

										// margin
										new_row[index] = (sell_price - buy_price) * finance_data.income_ratio + finance_data.additional_expenses;
									}
								}
							}

							i_date.setHours(24, 0, 0, 0);

							rows.push(new_row);
						}

						console.log(rows);

						setGlobalState(prev_state => ({
							...prev_state,
							margin_rows: rows,
						}));
					}
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (tab_no === 3 && global_state.pair_id > -1) {
			fetchData();
		}

	}, [tab_no, global_state.pair_id])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		async function fetchData(filter) {

			if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

				try {

					const response_es = await axios.get('/api/es', {
						params: {
							domain: filter.domain,
							period_start: getDateFormat(filter.period_start),
							period_end: getDateFormat(filter.period_end)
						}
					});

					const response_ca = await axios.get('/api/capacity', {
						params: {
							domain: filter.domain,
							period_start: getDateFormat(filter.period_start),
							period_end: getDateFormat(filter.period_end)
						}
					});

					setGlobalState(prev_state => ({
						...prev_state,
						external_series: response_es.data.external_series_list,
						capacities: response_ca.data.capacities_list
					}));

				} catch (error) {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({
							...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				}

				setFilter(filter => ({ ...filter, enable: false }));
			}
		}

		fetchData(filter);

	}, [filter])


	const list_external_series = (item) => {

		const cells = [];
		for (let i = 1; i <= 25; i++) {

			let index = (i < 10) ? `p0${i}` : `p${i}`;
			if (item[index] !== undefined && item[index] !== null) {
				cells.push({
					id: i,
					value: item[index]
				});
			}
		}

		const out_domain = global_state.domains.find(elem => elem.domain_id === item.out_domain_id);
		const in_domain = global_state.domains.find(elem => elem.domain_id === item.in_domain_id);
		const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
		const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

		return ({
			key: item.external_series_id,
			id: item.external_series_id,
			trade_date: item.trade_date,
			out_domain_id: item.out_domain_id,
			out_domain: (out_domain !== undefined) ? out_domain.domain_display_name : '',
			in_domain_id: item.in_domain_id,
			in_domain: (in_domain !== undefined) ? in_domain.domain_display_name : '',
			consumer_id: item.consumer_company_id,
			consumer: (consumer !== undefined) ? consumer.name : '',
			seller_id: item.seller_company_id,
			seller: (seller !== undefined) ? seller.name : '',
			points: cells,
		})
	}


	var filter_domain_list = [];
	var display_domains = [];
	var display_companies = [];
	var filtered_companies = [];
	var display_es_import = [];

	if ((global_state.domains.length > 0) && (global_state.companies.length > 0)) {

		filter_domain_list = [
			<option key={1} value={1}>ОЕС</option>,
			<option key={2} value={2}>БУОС</option>
		];

		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id}>{item.domain_display_name}</option>
			)
		});
		display_domains.unshift(<option key={-1} value={''} ></option>);

		display_companies = global_state.companies.map(company => {
			return (
				<option key={company.company_id} value={company.company_id} >{company.name}</option>
			)
		});
		display_companies.unshift(<option key={-1} value={''} ></option>);

		filtered_companies = global_state.filtered_data.map(company => {
			return (
				<option key={company.id} value={company.id} >{company.name}</option>
			)
		});
		filtered_companies.unshift(<option key={-1} value={''} ></option>);

		if (global_state.external_series.length > 0) {
			global_state.external_series.forEach(item => {
				display_es_import.push(list_external_series(item));
			})
		}
	}


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 27)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<esContext.Provider value={{ global_state, setGlobalState, list_external_series }}>
				<Switch>
					<Route
						path='/external_series/add'
						component={ExternalSeriesEditForm}
					/>
					<Route
						path='/external_series/send'
						component={ExternalSeriesSendForm}
					/>
					<Route
						path='/external_series/import'
						component={ExternalSeriesImportForm}
					/>
					<Route
						path='/external_series/:external_series_id'
						component={ExternalSeriesEditForm}
					/>
					<Route
						path='/external_series'
					>

						<div className={classes.root} >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Графіки імпорту / експорту
									</Typography>

									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={handleAddAction}
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Відправити'>
										<span>
											<IconButton
												onClick={handleSendAction}
												color='inherit'
											>
												<SendIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Імпорт'>
										<span>
											<IconButton
												onClick={() => { props.history.push('/external_series/import') }}
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									{
										(tab_no < 3) &&
										<FilterPeriodDomain
											period_start={filter.period_start}
											period_end={filter.period_end}
											domain={filter.domain}
											doamin_list={filter_domain_list}
											onFilterChange={onFilterChange}
											onFilterClick={onFilterClick}
										/>
									}

									<div className={classes.right_button}>

										<IconButton
											onClick={() => props.history.push('/')}
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label='Графіки' {...getTabsProps(0)} />
								<Tab label='Звіт' {...getTabsProps(1)} />
								<Tab label='Розрахунок маржі' {...getTabsProps(2)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<ExternalSeriesTable
									period_start={filter.period_start}
									period_end={filter.period_end}
									rows={display_es_import}
									onOpenRequest={handleOpenAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={1}>


							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<Grid container spacing={2} className={classes.root}>

									<Grid item xs={2}>

										<TextField
											variant='outlined'
											fullWidth
											label='Місяць'
											type='month'
											value={global_state.month}
											onChange={handleMonthChange}
											InputLabelProps={{ shrink: true }}
										/>

									</Grid>

									<Grid item xs={4}>

										<FormControl variant='outlined' required className={classes.select_form_control} fullWidth>
											<InputLabel ref={consumer_seller_label} id='consumer-seller-label'>Продавець - Покупець</InputLabel>
											<Select
												native
												labelId='consumer-seller-label'
												labelWidth={consumer_seller_label_width}
												value={global_state.pair_id}
												onChange={handleConsumerSellerChange}
											>
												{filtered_companies}
											</Select>
										</FormControl>

									</Grid>

									<Grid item xs={6}>

									</Grid>

								</Grid>

								<MonthResultsView
									hours_count={24}
									days_count={getDaysInMonth(global_state.month)}
									period={global_state.month}
									rows={global_state.margin_rows}
								/>

							</TabPanel>

						</div>

						<MessageErrorSnackbar
							open={message.show}
							message={message.message}
							info={''}
							onClose={onErrorMessageClose}
						/>

					</Route>
				</Switch>
			</esContext.Provider>
		)
	}
}

export default withRouter(ExternalSeries);