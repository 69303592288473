import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/SendOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import ExternalSeriesTable from './subcomponents/ExternalSeriesTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { esContext } from './esContext';
import { is_valid_date } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	hour_point: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		width: '85px'
	},

}));


export default function ExternalSeriesSendForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// import context
	const { global_state, setGlobalState, list_external_series } = useContext(esContext);


	function createInitialState () {

		var day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);

		const complex_state = {

			trade_date: getDateFormat(day_1),
			export_version: 1,
			external_series: []
		}

		return complex_state;
	}


	function handleTradeDateChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			trade_date: event_value,
		}));
	}


	function handleVersionChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			export_version: parseInt(event_value),
		}));
	}


	async function saveHandler () {

		try {

			const import_rows = [];
			const export_rows = [];

			local_state.external_series.forEach(item => {

				if (item.in_domain_id <= 2) {
					import_rows.push(item);
				} else {
					export_rows.push(item);
				}
			})

			const payload = {
				trade_date: getDateFormat(local_state.trade_date),
				version: local_state.export_version,
				import_rows: import_rows,
				export_rows: export_rows,
			}

			const response = await axios.post('/api/communication/import_export', payload);
			if (response.status === 200) {

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		if (await saveHandler()) {
			alert('Дані відправлені.')
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData(trade_date) {

			if (is_valid_date(trade_date)) {

				const next_day = new Date(trade_date);
				next_day.setHours(24, 0, 0, 0);

				try {

					// read external series for 2 days
					const resp_es_today = await axios.get('/api/es', {
						params: {
							period_start:	getDateFormat(trade_date),
							period_end:		getDateFormat(trade_date)
						}
					});

					const resp_es_next = await axios.get('/api/es', {
						params: {
							period_start:	getDateFormat(next_day),
							period_end:		getDateFormat(next_day)
						}
					});

					const external_series = [];
					const es_1 = resp_es_today.data.external_series_list;
					const es_2 = resp_es_next.data.external_series_list;

					for (let i = 0; i < es_1.length; i++) {

						const series = {
							external_series_id: es_1[i].external_series_id,
							trade_date: trade_date,
							consumer_company_id: es_1[i].consumer_company_id,
							seller_company_id: es_1[i].seller_company_id,
							out_domain_id: es_1[i].out_domain_id,
							in_domain_id: es_1[i].in_domain_id,
						}

						for (let j = 1; j <= 25; j++) {

							const i_source = (j < 9) ? `p0${j + 1}` : `p${j + 1}`;
							const i_target = (j < 10) ? `p0${j}` : `p${j}`;

							if (es_1[i][i_source] !== undefined && es_1[i][i_source] !== null) {
								series[i_target] = es_1[i][i_source];
							}
						}

						const next_es = es_2.find(item => item.consumer_company_id === series.consumer_company_id &&
														  item.seller_company_id === series.seller_company_id &&
														  item.out_domain_id === series.out_domain_id &&
														  item.in_domain_id === series.in_domain_id);

						const next_index = (series['p24'] !== undefined) ? 'p25' : 'p24';

						if (next_es !== undefined) {
							series[next_index] = next_es['p01'];
						} else {
							series[next_index] = 0;
						}

						external_series.push(series);
					}

					setLocalState(prev_state => ({ ...prev_state,
						external_series: external_series,
					}));

				} catch(error) {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				}
			}
		}

		fetchData(local_state.trade_date);

	}, [local_state.trade_date])


	var display_es_import = [];
	var display_es_export = [];

	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={ item.company_id } value={ item.company_id } >{ item.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	if (local_state.external_series.length > 0) {
		local_state.external_series.forEach(item => {

			const row = list_external_series(item);
			if (item.in_domain_id <= 2) {
				display_es_import.push(row);
			} else {
				display_es_export.push(row);
			};

		})
	}


	return (
		<Container className={ classes.root_form } component='main'>

			<Avatar className={ classes.avatar }>
				<SendIcon />
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				Відправити графіки імпорту / експорту
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Grid container spacing={2}>

					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value={ local_state.trade_date }
							onChange={ handleTradeDateChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Версія'
							type='number'
							value={ local_state.export_version }
							onChange={ handleVersionChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={6}/>

				</Grid>

				<div className={classes.input_form_control}>

					<Typography component='h3' align='center'>
						Графіки імпорту
					</Typography>

					<ExternalSeriesTable
						rows={ display_es_import }
						selected={[]}
						setSelected={() => {}}
					/>

				</div>

				<div className={classes.input_form_control}>

					<Typography component='h3' align='center'>
						Графіки експорту
					</Typography>

					<ExternalSeriesTable
						rows={ display_es_export }
						selected={[]}
						setSelected={() => {}}
					/>

				</div>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button_control }
						disabled={ local_state.external_series.length === 0 }
					>
						Відправити
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button_control }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}