import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/RemoveSharp';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import UncheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
		fontWeight: 'bold',
	},

	schedule_block: {
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},

	table_header: {
		textAlign: 'left',
		padding: theme.spacing(1),
	},

	table_block: {
		textAlign: 'center',
		border: '1px solid gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	table_style: {
		width: '100%',
		tableLayout: 'fixed',
		padding: theme.spacing(2, 0, 0, 0),

		'& td:nth-child(even)': {
			backgroundColor: '#E8E8E8',
		},

		'& td:nth-child(odd)': {
			backgroundColor: '#F8F8F8',
		},

		'& th': {
			fontWeight: 'bold',
			backgroundColor: 'white',
		},
	},

	comment: {
		textAlign: 'left',
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function ScheduleUploadDialog(props) {

	const classes = useStyles();
	const { open, onClose } = props;

	const init_state = {
		revision: 0,
		time_series_revisions: [],
		schedule: undefined
	}
	const [state, setState] = useState(init_state);


	useEffect(() => {

		if (props.schedule) {

			const ts_revisions = [];
			props.schedule.time_series.forEach(elem => {
				
				ts_revisions.push({
					time_series_id: elem.time_series_id,
					revision: elem.revision,
				})
			});

			setState(prev_state => ({ ...prev_state,
				revision: props.schedule.revision,
				time_series_revisions: ts_revisions,
				schedule: props.schedule,
			}));
		}

	}, [props.schedule])


	const onScheduleRevisionChange = (value) => {

		if (value > 0) {

			const new_schedule = state.schedule;
			new_schedule.revision = new_schedule.revision + 1;

			setState(prev_state => ({ ...prev_state,
				schedule: new_schedule,
			}));
		}

		if (value < 0) {

			const new_schedule = state.schedule;

			if (new_schedule.revision > 1) {

				new_schedule.revision = new_schedule.revision - 1;

				setState(prev_state => ({ ...prev_state,
					schedule: new_schedule,
				}));
			}
		}
	}


	const onAllTimeSeriesChecked = () => {

		const new_schedule = state.schedule;
		new_schedule.time_series.forEach((ts) => {
			ts.is_checked = true;
		})

		setState(prev_state => ({ ...prev_state,
			schedule: new_schedule,
		}));
	}


	const onAllTimeSeriesUnchecked = () => {

		const new_schedule = state.schedule;
		new_schedule.time_series.forEach((ts) => {
			ts.is_checked = false;
		})

		setState(prev_state => ({ ...prev_state,
			schedule: new_schedule,
		}));
	}


	const onTimeSeriesCheckedChange = (value) => {

		const new_schedule = state.schedule;
		const ts_init = state.schedule.time_series.find(item => item.time_series_id === value);

		if (ts_init !== undefined) {

			const state = ts_init.is_checked;
			ts_init.is_checked = (state === undefined) ? true : !state;

			setState(prev_state => ({ ...prev_state,
				schedule: new_schedule,
			}));
		}
	}


	const onAllTimeSeriesRevisionChange = (value) => {

		if (value > 0) {

			const new_schedule = state.schedule;
			new_schedule.time_series.forEach((ts) => {

				const ts_state = state.schedule.time_series.find(item => item.time_series_id === ts.time_series_id);

				if (ts_state.is_checked !== undefined && ts_state.is_checked) {
					ts.revision = ts.revision + 1;
				}
			})

			setState(prev_state => ({ ...prev_state,
				schedule: new_schedule,
			}));
		}

		if (value < 0) {

			const new_schedule = state.schedule;
			new_schedule.time_series.forEach((ts) => {

				const ts_state = state.schedule.time_series.find(item => item.time_series_id === ts.time_series_id);

				if (ts_state.is_checked !== undefined && ts_state.is_checked && ts.revision > 1) {
					ts.revision = ts.revision - 1;
				}
			})

			setState(prev_state => ({ ...prev_state,
				schedule: new_schedule,
			}));
		}
	}


	const onTimeSeriesRevisionChange = (ts_id, value) => {

		if (value > 0) {

			const new_schedule = state.schedule;
			const ts_result = new_schedule.time_series.find(item => item.time_series_id === ts_id);

			ts_result.revision = ts_result.revision + 1;

			setState(prev_state => ({ ...prev_state,
				schedule: new_schedule,
			}));
		}

		if (value < 0) {

			const new_schedule = state.schedule;
			const ts_result = new_schedule.time_series.find(item => item.time_series_id === ts_id);

			if (ts_result.revision > 1) {

				ts_result.revision = ts_result.revision - 1;

				setState(prev_state => ({ ...prev_state,
					schedule: new_schedule,
				}));
			}
		}
	}


	const onYesHandler = () => {
		onClose(state.schedule);
	};


	const onCancelHandler = () => {
		onClose(undefined);
	};


	return (
		<Dialog
			open={open}
			maxWidth='xl'
			aria-labelledby='upload-dialog-title'
		>
			<DialogTitle id='upload-dialog-title' className={classes.header} >
				Завантаження графіку РДД
			</DialogTitle>
			<DialogContent>

				{ (state.schedule !== undefined) &&
				<Grid container spacing={2} className={classes.schedule_block}>

					<Grid item xs={12}>
						<Typography>
							<b>№ графіку</b>: {state.schedule.schedule_id}
						</Typography>
					</Grid>

					<table className={classes.table_header}>
						<tbody>
							<tr>
								<td>
									<Typography>
										<b>Версія графіку</b>:
									</Typography>
								</td>
								<td>
									<Typography>
										{state.schedule.revision}
									</Typography>
								</td>
								<td>
									<IconButton
										size='small'
										onClick={() => onScheduleRevisionChange(1)}
									>
										<AddIcon/>
									</IconButton>
								</td>
								<td>
									<IconButton
										size='small'
										onClick={() => onScheduleRevisionChange(-1)}
									>
										<RemoveIcon/>
									</IconButton>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Відправник</b>:
									</Typography>
								</td>
								<td>
									<Typography>
										{state.schedule.sender}
									</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Отримувач</b>:
									</Typography>
								</td>
								<td>
									<Typography>
										{state.schedule.receiver}
									</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Доба торгівлі</b>:
									</Typography>
								</td>
								<td>
									<Typography>
										{getLocalDateString(state.schedule.start)}
									</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Зона торгівлі</b>:
									</Typography>
								</td>
								<td>
									<Typography>
										{state.schedule.domain}
									</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Виділити/скинути всі часові ряди</b>:
									</Typography>
								</td>
								<td/>
								<td>
									<IconButton
										size='small'
										onClick={() => onAllTimeSeriesChecked()}
									>
										<CheckIcon/>
									</IconButton>
								</td>
								<td>
									<IconButton
										size='small'
										onClick={() => onAllTimeSeriesUnchecked()}
									>
										<UncheckIcon/>
									</IconButton>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>
										<b>Версія часових рядів</b>:
									</Typography>
								</td>
								<td/>
								<td>
									<IconButton
										size='small'
										onClick={() => onAllTimeSeriesRevisionChange(1)}
									>
										<AddIcon/>
									</IconButton>
								</td>
								<td>
									<IconButton
										size='small'
										onClick={() => onAllTimeSeriesRevisionChange(-1)}
									>
										<RemoveIcon/>
									</IconButton>
								</td>
							</tr>
						</tbody>
					</table>

					<Grid item xs={12}>

						{ state.schedule.time_series.map((ts, ts_index) => (

							<div key={ ts_index } className={classes.table_block}>

								<table className={classes.table_header}>
									<tbody>
										<tr>
											<td>
												<IconButton
													size='small'
													onClick={() => onTimeSeriesCheckedChange(ts.time_series_id)}
												>
													{ (ts.is_checked !== undefined && ts.is_checked) &&
														<CheckIcon/>
													}
													{ (ts.is_checked === undefined || !ts.is_checked) &&
														<UncheckIcon/>
													}
												</IconButton>
											</td>
											<td>
												<b>№ часового ряду</b>: {ts_index + 1}
											</td>
										</tr>
										<tr>
											<td>
												<b>Версія</b>:
											</td>
											<td>
												{ts.revision}
											</td>
											<td>
												<IconButton
													size='small'
													onClick={() => onTimeSeriesRevisionChange(ts.time_series_id, 1)}
												>
													<AddIcon/>
												</IconButton>
											</td>
											<td>
												<IconButton
													size='small'
													onClick={() => onTimeSeriesRevisionChange(ts.time_series_id, -1)}
												>
													<RemoveIcon/>
												</IconButton>
											</td>
										</tr>
										<tr>
											<td>
												<b>Продавець</b>:
											</td>
											<td>
												{ ts.seller }
											</td>
										</tr>
										<tr>
											<td>
												<b>Покупець</b>:
											</td>
											<td>
												{ ts.consumer }
											</td>
										</tr>
										<tr>
											<td>
												<b>Зона торгівлі</b>:
											</td>
											<td>
												{ts.domain}
											</td>
										</tr>
									</tbody>
								</table>

								<table className={classes.table_style}>

									<thead>
										<tr>
											{ ts.points.map((point, point_index) => (
												<th key={point_index}> {point.index} </th>
											)) }
										</tr>
									</thead>

									<tbody>
										<tr>
											{ ts.points.map((point, point_index) => (
												<td key={point_index}> {point.value} </td>
											)) }
										</tr>
									</tbody>

								</table>

							</div>

						)) }

					</Grid>

				</Grid> }

				<div className={classes.comment}>
					<b>Коментар</b>
					<br />
					<i>
						Версії графіків та часових рядів попередньо встановлюються за наступними правилами:
						<li>версія часового графіку встановлюється на 1 більше ніж остання версія графіку, що був завантажений на цю торгову добу;</li>
						<li>версія часового ряду встановлюється на 1 більше ніж кількість завантажений часових графіків з тими самими контрагентами на цю торгову добу.</li>
					</i>
				</div>

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { onYesHandler }
					>
						Так
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</DialogContent>
		</Dialog>
	);
}

ScheduleUploadDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	schedule: PropTypes.object,
};