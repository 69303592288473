import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import AlertIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { red, yellow, green } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DamBlocksView from './DamBlocksView';

import { getLocalDateString, getHoursInDate } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
	},

	block: {
		textAlign: 'center',
		border: '1px solid gray',
		borderRadius: '5px',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},

	message: {
		textAlign: 'left',
	},

	red: {
		display: 'inline-block',
		width: '100%',
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
	},

	yellow: {
		display: 'inline-block',
		width: '100%',
		color: theme.palette.getContrastText(yellow[500]),
		backgroundColor: yellow[500],
	},

	green: {
		display: 'inline-block',
		width: '100%',
		color: theme.palette.getContrastText(green[500]),
		backgroundColor: green[500],
	},

}));


export default function DamXmtradeResultsDialog(props) {
	const classes = useStyles();
	const { open, onClose, selectedValue, reasons, results } = props;


	const handleClose = () => {
		onClose(selectedValue);
	};


	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth='xl'
			aria-labelledby='xmtrade-dialog-title'
		>
			<DialogTitle id='xmtrade-dialog-title' className={classes.header} >
				Результати завантаження заявки РДН
			</DialogTitle>

			<DialogContent>

				{
					reasons.map((item, index) => (
						<div key={ index } className={classes.block}>

							{ (item.severity === 1) &&
							<div className={ classes.red }>
								<AlertIcon />
							</div> }

							{ (item.severity === 2) &&
							<div className={ classes.red }>
								<AlertIcon />
							</div> }

							{ (item.severity === 3) &&
							<div className={ classes.green }>
								<InfoIcon />
							</div> }

							{ (item.severity === 4) &&
							<div className={ classes.yellow }>
								<WarningIcon />
							</div> }

							<header className={ classes.message }>
								<h3> Тип відповіді: { item.type } </h3>
								<h4> Причина: { item.code } </h4>
								<p><i> { item.message } </i></p>
							</header>

						</div>
					))
				}
				{
					results.map((item, index) => (
						<div key={ index } className={classes.block}>

							<Grid container spacing={2}>

								<Grid item xs={12}>

									<Typography>
										№ заявки: {item.order_id}
									</Typography>

								</Grid>

								<Grid item xs>

									<Typography>
										Дата торгівлі: {getLocalDateString(item.trade_date)}
									</Typography>

								</Grid>

								<Grid item xs>

									<Typography>
										Зона торгівлі: {item.domain}
									</Typography>

								</Grid>

								<Grid item xs>

									<Typography>
										Тип торгівлі: {item.trade_type}
									</Typography>

								</Grid>

								<Grid item xs>

									<Typography>
										Блочна заявка: {item.acceptance_type}
									</Typography>

								</Grid>

								{ (item.block_type !== undefined) &&
								<Grid item xs>

									<Typography>
										Тип блоку: {item.block_type}
									</Typography>

								</Grid> }

							</Grid>

							<DamBlocksView
								columns_count={getHoursInDate(item.trade_date)}
								rows={item.rows}
							/>

						</div>
					))
				}

			</DialogContent>
		</Dialog>
	);
}

DamXmtradeResultsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	reasons: PropTypes.array.isRequired,
	results: PropTypes.array
};