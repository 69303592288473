import React, { useContext } from 'react';
import { globalContext } from '../layout/Context';

import LoginForm from './LoginForm';
import UserForm from './UserForm';


export default function AuthForm() {

	const { user } = useContext(globalContext);

	if (!user.is_logged_in) {
		return (
			<LoginForm />
		)
	}
	else {
		return (
			<UserForm />
		)
	}
};