import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
	},

	block: {
		width: '400px',
		padding: theme.spacing(2),
	}

}));


export default function DamHelpDialog(props) {
	const classes = useStyles();
	const { open, onClose, selectedValue } = props;


	const handleClose = () => {
		onClose(selectedValue);
	};


	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby='help-dialog-title' >
			<DialogTitle id='help-dialog-title' className={classes.header} >Заповнення заявки РДН</DialogTitle>

			<details className={classes.block}>
				<summary><b>Блочна заявка</b></summary>
				<p align='justify'>
					Загальний акцепт першого блоку є показником Заявки, який визначає чи повинен весь обсяг електроенергії в усі години, які визначені для першого блоку заявки, сторгуватися як одне ціле.
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Тип блочної заявки</b></summary>
				<p align='justify'>
					Вибір типу блочної заявки. 
					Стандартний профіль = блочна заявка з ціною та обсягом для кожного періоду торгів.
					Профіль з середньозваженою ціною = блочна заявка з обсягом для кожного торгового періоду, тоді як однакова ціна повинна бути задана для кожного періоду торгів. Система прийме ціну як одну середньозважену ціну за весь блок.
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Торгова година</b></summary>
				<p align='justify'>
					Торгова година - це окрема година в добі постачання.
					<li>День переходу з Центральноєвропейського часу (CET) на Центральноєвропейський літній час (CEST) має 23 Торгові години;</li>
					<li>День переходу з CEST на CET має 25 Торгових годин;</li>
					<li>Всі інші дні року мають по 24 Торгові години.</li>
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Блок</b></summary>
				<p align='justify'>
					Блоки в межах одної Заявки дозволяють пропонувати у погодинній заявці певний обсяг електроенергії за певну ціну і в той же час
					іншу кількість електроенергії за іншу ціну. В одній заявці може бути до 25 блоків. При зазначенні цін в блоки Заявок на купівлю,
					необхідно вводити ціни в порядку спадання, від найвищої до найнижчої. Ціни, зазначені в блоках Заявки на продаж, мають бути розміщені
					у порядку зростання, від найнижчої до найвищої.
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Обсяг</b></summary>
				<p align='justify'>
					Обсяг замовленої електроенергії визначається МВт*год.
					Дозволений діапазон обсягу електроенергії становить від 1 МВт*год до 99 999 МВт*год з точністю до одного десяткового знаку в МВт*год.
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Ціна</b></summary>
				<p align='justify'>
					Найвища (купівельна) або найнижча (продажна) прийнята ціна за одиницю МВт*год.
					Дозволений діапазон ціни становить від 10 грн/МВт*год до 50 000 грн/МВт*год з точністю до 2 десяткових знаків в грн.
					Ціни, зазначені в заявках, не включають ПДВ та податок на споживання електроенергії.
				</p>
			</details>

			<details className={classes.block}>
				<summary><b>Виробнича подільність</b></summary>
				<p align='justify'>
					Виробнича подільність визначається лише для заявок на продаж та зазначається окремо для кожної години.
					Цей показник визначає чи можливо поділити обсяг електроенргії при узгодженні заявки (чи можливо зменшити обсяг). Для Заявки на купівлю значення цього показника встановлюється на "Так" для всіх годин першого блоку.
				</p>
			</details>

		</Dialog>
	);
}


DamHelpDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};