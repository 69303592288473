import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Avatar from '@material-ui/core/Avatar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import TimeSeriesShortTable from './subcomponents/TimeSeriesShortTable';
import SelectTimeSeriesShortTable from './subcomponents/SelectTimeSeriesShortTable';
import TimeSeriesCompare from './subcomponents/TimeSeriesCompare';
import OptionConfirmationDialog from '../subcomponents/OptionConfirmationDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { bcContext } from './bcContext';
import { is_valid_date } from '../utils/validation';
import { getLocalDateString, getDateFormat, utc_time_to_ukraine_time, getISOString_HHMMSS_FromDateString } from '../utils/date_functions';
import { ScheduleToXml, ScheduleToCells, ScheduleFromXml, ScheduleFromJson } from '../utils/schedule_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: '100%',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	grid_1: {
		marginTop: theme.spacing(4),
		flexGrow: 1,
	},

	grid_2: {
		marginTop: theme.spacing(1),
		flexGrow: 1,
	},

	form_control: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(1),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

}));


export default function ScheduleImportExportForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	// state for the import schedule
	const [imported_file, setImportedFile] = useState(undefined);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	const [open_option_dialog, setOpenOptionDialog] = useState(false);

	// variables for the label control
	const domain_label = React.useRef(null);
	const schedule_label = React.useRef(null);
	const sender_company_label = React.useRef(null);
	const sender_role_label = React.useRef(null);

	// state for label widths
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [schedule_label_width, setScheduleLabelWidth] = useState(0);
	const [sender_company_label_width, setSenderCompanyLabelWidth] = useState(0);
	const [sender_role_label_width, setSenderRoleLabelWidth] = useState(0);

	const [file_ts_detail, setFileTsDetail] = useState('');
	const [selected_db_ts, setSelectedDbTs] = useState([]);
	const [db_ts_detail, setDbTsDetail] = useState(0);
	const [db_ts_detail_full, setDbTsDetailFull] = useState(undefined);

	// import context
	const { user } = useContext(globalContext);
	const { global_state } = useContext(bcContext);

	// time series to compare
	var ts_compare_1 = undefined; var ts_compare_2 = undefined;

	const schedule_id = parseInt(props.match.params.schedule_id);
	const time_series_id = parseInt(props.match.params.time_series_id);


	function createInitialState() {

		const complex_state = {

			file_created_date: '',
			file_revision: 0,
			file_revision_id: 0,
			file_domain_id: 0,
			file_trade_date: '',

			file_sender_company_id: 0,
			file_sender_role_id: 0,

			file_receiver_company_name: '',
			file_receiver_company_role: '',

			file_time_series: [],

			db_created_date: '',
			db_revision: 0,
			db_revision_id: 0,
			db_status_id: 0,
			db_domain_id: 0,
			db_trade_date: '',

			db_sender_company_id: 0,
			db_sender_role_id: 0,

			db_receiver_company_name: '',
			db_receiver_company_role: '',

			db_time_series: [],

			db_schedules: [],
			db_schedule_id: 0,
		}

		return complex_state;
	}


	function handleImportFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xml file
		if (event_value.name.indexOf('.xml') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content_string = reader.result;

					const parser = new DOMParser();
					const xml_doc = parser.parseFromString(file_content_string, 'text/xml');
	
					// get order from xml document and store into variable
					const imported_file = ScheduleFromXml(xml_doc);
					setImportedFile(imported_file);

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));
					console.log(e);
				}
			}

			reader.readAsText(event_value);
			import_executed = true;
		}

		// import from xlsx file
		if (event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });
	
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
	
					// get order from xlsx document and store into variable
					const imported_file = ScheduleFromJson(json_doc);
					setImportedFile(imported_file);

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));
					console.log(e);
				}

			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function onDbScheduleChange(event) {

		const event_value = event.target.value;

		if (event_value !== '') {

			const selected_schedule = local_state.db_schedules.find(item => item.schedule_id === parseInt(event_value));

			setLocalState(prev_value => ({ ...prev_value,
				db_domain_id: (selected_schedule !== undefined) ? selected_schedule.domain_id : 0,
				db_status_id: (selected_schedule !== undefined) ? selected_schedule.status_id : 0,
				db_trade_date: (selected_schedule !== undefined) ? getDateFormat(selected_schedule.trade_date) : '',
				db_sender_company_id: (selected_schedule !== undefined) ? selected_schedule.sender_company_id : 0,
				db_sender_role_id: (selected_schedule !== undefined) ? selected_schedule.sender_market_role_id : 0,
				db_receiver_company_name: local_state.file_receiver_company_name,
				db_receiver_company_role: local_state.file_receiver_company_role,
				db_schedule_id: parseInt(event_value),
			}))

			const time_series = [];
			selected_schedule.time_series.forEach(item => {
				time_series.push(item.time_series_id);
			})

			setSelectedDbTs(time_series);

		} else {

			setLocalState(prev_value => ({ ...prev_value,
				db_domain_id: 0,
				db_status_id: 0,
				db_trade_date: '',
				db_sender_company_id: 0,
				db_sender_role_id: 0,
				db_receiver_company_name: '',
				db_receiver_company_role: '',
				db_schedule_id: 0,
			}))

			setSelectedDbTs([]);
		}
	}


	function handleRoleChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			db_sender_role_id: parseInt(event_value),
		}));
	}


	async function saveTimeSeries(source, target) {

		const import_url = (target !== undefined) ? '/api/bc/ts/' + target.name : '/api/bc/ts/add';

		try {

			const new_data = {
				domain_id: local_state.file_domain_id,
				trade_date: getDateFormat(source.trade_date),
				consumer_company_id: source.consumer_company_id,
				seller_company_id: source.seller_company_id,
				p01: source.points[0].value,
				p02: source.points[1].value,
				p03: source.points[2].value,
				p04: source.points[3].value,
				p05: source.points[4].value,
				p06: source.points[5].value,
				p07: source.points[6].value,
				p08: source.points[7].value,
				p09: source.points[8].value,
				p10: source.points[9].value,
				p11: source.points[10].value,
				p12: source.points[11].value,
				p13: source.points[12].value,
				p14: source.points[13].value,
				p15: source.points[14].value,
				p16: source.points[15].value,
				p17: source.points[16].value,
				p18: source.points[17].value,
				p19: source.points[18].value,
				p20: source.points[19].value,
				p21: source.points[20].value,
				p22: source.points[21].value,
				p23: source.points[22].value,
				p24: (source.points.length > 23) ? source.points[23].value : null,
				p25: (source.points.length > 24) ? source.points[24].value : null
			}

			const response = await axios.post(import_url, new_data);
			if (response.status === 200) {

				const {schedules, time_series} = await download_schedule_time_series_data(local_state.file_domain_id, source.trade_date);
				setLocalState(prev_state => ({ ...prev_state,
					db_schedules: schedules,
					db_time_series: time_series,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function onTsDelete(row_id) {

		if (!window.confirm('Ви впевнені, що бажаєте видалити часовий ряд?')) {
			return;
		}

		const file_ts = local_state.file_time_series.find(item => item.rid === row_id);
		if (file_ts !== undefined) {

			setLocalState(prev_value => ({ ...prev_value,
				file_time_series: prev_value.file_time_series.filter(item => item.rid !== row_id),
			}))
		}

		const db_ts = local_state.db_time_series.find(item => item.time_series_id === row_id);
		if (db_ts !== undefined) {

			try {

				const id = parseInt(row_id);
				const response = await axios.post('/api/bc/ts/' + id + '/delete');
	
				if (response.status === 200) {

					setLocalState(prev_state => ({ ...prev_state,
						db_time_series: prev_state.db_time_series.filter(item => item.time_series_id !== row_id),
					}));

					setDbTsDetail(0);
				}

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}
	
				console.log(error);
			}
		}
	}


	function OnImportTsHandler () {

		if (ts_compare_1 !== undefined) {

			if (ts_compare_2 === undefined) {

				if (!window.confirm('Ви впевнені, що бажаєте імпортувати часовий ряд?')) {
					return;
				}
			}

			if (ts_compare_2 !== undefined) {

				if (ts_compare_2.status_id > 1) {
					window.alert('Неможливо перезаписати часовий ряд, який вже оброблявся.');
					return;
				}

				if (!window.confirm('При імпорті дані вибраного ряду переписуються даними, що імпортуютсья. Ви впевнені?')) {
					return;
				}
			}

			const consumer_valid = Number.isInteger(ts_compare_1.consumer_company_id);
			const seller_valid = Number.isInteger(ts_compare_1.seller_company_id);

			if (!consumer_valid || !seller_valid) {

				const unknown_company = !consumer_valid ? ts_compare_1.consumer_company_id : ts_compare_1.seller_company_id;
				window.alert(`Компанія ${unknown_company} не внесена в PowerTrade. Імпорт не можливий.`);
				return;
			}

			saveTimeSeries(ts_compare_1, ts_compare_2);
		}
	}


	async function OnImportAllTsHandler () {

		local_state.file_time_series.forEach(item => {

			const consumer_valid = Number.isInteger(item.consumer_company_id);
			const seller_valid = Number.isInteger(item.seller_company_id);

			if (!consumer_valid || !seller_valid) {

				const unknown_company = !consumer_valid ? item.consumer_company_id : item.seller_company_id;
				window.alert(`Компанія ${unknown_company} не внесена в PowerTrade. Імпорт не можливий.`);
				return;
			}

			saveTimeSeries(item, undefined);
		})
	}


	async function OnImportHeadHandler () {

		// import schedule
		if (local_state.db_schedule_id === 0) {

			if (!window.confirm('Ви впевнені, що бажаєте імпортувати графік?')) {
				return;
			}
		}

		// replace schedule
		if (local_state.db_schedule_id !== 0) {

			if (local_state.db_status_id > 1) {
				window.alert('Неможливо перезаписати графік, який вже оброблявся.');
				return;
			}

			if (!window.confirm('При імпорті дані вибраного графіку переписуються даними, що імпортуютсья. Ви впевнені?')) {
				return;
			}
		}

		const import_url = (local_state.db_schedule_id !== 0) ? '/api/bc/schedules/' + local_state.db_schedule_id : '/api/bc/schedules/add';

		try {

			const new_data = {
				domain_id: local_state.file_domain_id,
				trade_date: getDateFormat(local_state.file_trade_date),
				sender_company_id: local_state.file_sender_company_id,
				sender_role_id: local_state.file_sender_role_id,
				time_series: selected_db_ts,
			}

			const response = await axios.post(import_url, new_data);
			if (response.status === 200) {

				const {schedules, time_series} = await download_schedule_time_series_data(local_state.file_domain_id, local_state.file_trade_date);
				setLocalState(prev_state => ({ ...prev_state,
					db_schedules: schedules,
					db_time_series: time_series,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(prev_state => ({ ...prev_state,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	async function get_export_schedule() {

		let schedule_time_series = [];

		const request = [];
		for (let i = 0; i < selected_db_ts.length; i++) {
			request[i] = parseInt(selected_db_ts[i])
		}

		try {

			const response = await axios.get('/api/bc/ts/full_by_id', { params: { ids: request }});
			if (response.data !== undefined) {

				response.data.forEach((item, item_index) => {

					if (item !== null) {
	
						const { revision, domain_id, trade_date, consumer_company_id, seller_company_id } = item;
		
						// get points
						let points = [];
						for (let j = 1; j <= 25; j++) {
		
							let index = (j < 10) ? 'p0' : 'p';
							index = index + j.toString();

							if (item[index] !== null) {
								points.push({
									id: j.toString(),
									value: parseFloat(parseFloat(item[index]).toFixed(3)),
								});
							}
						}

						const consumer_company = global_state.companies.find(elem => elem.company_id === parseInt(consumer_company_id));
						const sender_company = global_state.companies.find(elem => elem.company_id === parseInt(seller_company_id));
						const domain = global_state.domains.find(elem => elem.domain_id === parseInt(domain_id));

						let period_start = new Date(trade_date);
						period_start.setHours(0, 0, 0, 0);

						let period_end = new Date(period_start);
						period_end.setMinutes(period_end.getMinutes() + 24 * 60);

						const current_time_series = {
							rid: 'TS' + (item_index + 1).toString(),
							revision: revision,
							domain: (domain !== undefined) ? domain.domain_code_eic : '',
							consumer_company: (consumer_company !== undefined) ? consumer_company.eic_x_code : '',
							seller_company: (sender_company !== undefined) ? sender_company.eic_x_code : '',
							period_start: utc_time_to_ukraine_time(period_start),
							period_end: utc_time_to_ukraine_time(period_end),
							points: points
						}

						schedule_time_series.push(current_time_series);
					}
				})
			}

		} catch(error) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: (error.response) ? error.response.data : error.message,
			}));
		};


		var sender_company, sender_role, domain;
		if (schedule_id > 0 || time_series_id > 0) {
			sender_company = global_state.companies.find(elem => elem.company_id === local_state.db_sender_company_id);
			sender_role = global_state.market_roles.find(elem => elem.role_id === local_state.db_sender_role_id);
			domain = global_state.domains.find(elem => elem.domain_id === local_state.db_domain_id);
		} else {
			sender_company = global_state.companies.find(elem => elem.company_id === local_state.file_sender_company_id);
			sender_role = global_state.market_roles.find(elem => elem.role_id === local_state.file_sender_role_id);
			domain = global_state.domains.find(elem => elem.domain_id === local_state.file_domain_id);
		}

		const created_date = new Date();

		let period_start = (schedule_id > 0 || time_series_id > 0) ? local_state.db_trade_date : local_state.file_trade_date;
		// ensure begin of the day
		period_start = new Date(period_start);
		period_start.setHours(0, 0, 0, 0);

		var period_end = new Date(period_start);
		period_end.setMinutes(period_end.getMinutes() + 24 * 60);

		const result = {
			rid: ((sender_company !== undefined) ? sender_company.eic_x_code : '') + '-' + getDateFormat(period_start),
			created_date: getISOString_HHMMSS_FromDateString(created_date),
			revision: (schedule_id > 0 || time_series_id > 0) ? local_state.db_revision : local_state.file_revision,
			domain: (domain !== undefined) ? domain.domain_code_eic : '',

			period_start: utc_time_to_ukraine_time(period_start),
			period_end: utc_time_to_ukraine_time(period_end),

			sender_company: (sender_company !== undefined) ? sender_company.eic_x_code : '',
			sender_role: (sender_role !== undefined) ? sender_role.role_code : '',

			receiver_company: (schedule_id > 0 || time_series_id > 0) ? local_state.db_receiver_company_name : local_state.file_receiver_company_name,
			receiver_role: (schedule_id > 0 || time_series_id > 0) ? local_state.db_receiver_company_role : local_state.file_receiver_company_role,

			time_series: schedule_time_series,
		}

		return result;
	}


	async function exportScheduleToXlsx() {

		const export_schedule = await get_export_schedule();

		try {

			// download template file
			const temp_xlsx_doc = await axios.get('/docs/templates/Schedule_MarketDocument.xlsx', { responseType: 'arraybuffer' });
			const wb = XLSX.read(temp_xlsx_doc.data, { type: 'array', cellStyles: true });
			const sheet = wb.Sheets[wb.SheetNames[0]];

			// make changes to a template sheet
			const update_commands = ScheduleToCells(export_schedule);
			update_commands.forEach(item => {

				const cell_ref = XLSX.utils.encode_cell({ c: item.c, r: item.r });

				if (sheet[cell_ref] === undefined) {
					sheet[cell_ref] = sheet['A1'];
				}

				sheet[cell_ref].t = item.t;
				sheet[cell_ref].v = item.v;
				delete(sheet[cell_ref].w);
			});

			// save workbook to file
			XLSX.writeFile(wb, `Export_FC_Schedule_${export_schedule.sender_company}_${export_schedule.domain}_${getDateFormat(export_schedule.period_start)}.xlsx`);

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	async function exportScheduleToXml() {

		const export_schedule = await get_export_schedule();

		try {

			const temp_xml_document = await axios.get('/docs/templates/Schedule_MarketDocument.xml');

			const parser = new DOMParser();
			// parse document to xml to export
			const xml_schedule = parser.parseFromString(temp_xml_document.data, 'text/xml');

			// store schedule to xml document
			ScheduleToXml(xml_schedule, export_schedule);

			// convert xml back to string
			const save_file_content = (new XMLSerializer()).serializeToString(xml_schedule);
			// get string to blob and save blob to a file
			const blob = new Blob([ save_file_content ], { type: 'text/xml' });
			saveAs(blob, `Export_FC_Schedule_${export_schedule.sender_company}_${export_schedule.domain}_${getDateFormat(export_schedule.period_start)}.xml`);

		} catch(error) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	async function onExportTsHandler() {

		if (selected_db_ts.length === 0) {
			return;
		}

		if (!window.confirm('Ви впевнені, що бажаєте експортувати виділені часові ряди?')) {
			return;
		}

		setOpenOptionDialog(true);
	}


	async function download_schedule_time_series_data(domain, trade_date) {

		const result = {
			schedules: [],
			time_series: [],
		}

		if (domain !== 0 && is_valid_date(trade_date)) {

			const result_download = await axios.get('/api/bc/schedules', {
				params: {
					domain:			domain,
					period_start:	getDateFormat(trade_date),
					period_end:		getDateFormat(trade_date)
				}
			});

			result.schedules = result_download.data.schedules_list;
			result.time_series = result_download.data.time_series_list
		}

		return result;
	}


	async function handleOptionDialogClose (option_value) {
		setOpenOptionDialog(false);

		// continue export
		if (option_value === 'xml') {
			await exportScheduleToXml();
		}
		if (option_value === 'xlsx') {
			await exportScheduleToXlsx();
		}
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setScheduleLabelWidth(schedule_label.current.offsetWidth);
		setSenderCompanyLabelWidth(sender_company_label.current.offsetWidth);
		setSenderRoleLabelWidth(sender_role_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		async function fetch_data() {

			try {

				const receiver_result = await axios.get('/api/bc/schedules/receiver');
				const sender_company = global_state.companies.find(company => company.company_id === user.company_id);

				setLocalState(prev_state => ({ ...prev_state,
					file_sender_company_id: (sender_company !== undefined) ? sender_company.company_id : 0,
					file_receiver_company_name: receiver_result.data.eic_x_code,
					file_receiver_company_role: receiver_result.data.receiver_role_code,
					db_sender_company_id: (sender_company !== undefined) ? sender_company.company_id : 0,
					db_receiver_company_name: receiver_result.data.eic_x_code,
					db_receiver_company_role: receiver_result.data.receiver_role_code,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetch_data();

	}, [user, global_state])


	useEffect(() => {

		async function fetch_data(schedule_id) {

			try {

				// download schedule
				const result_schedule = await axios.get('/api/bc/schedules/' + schedule_id);

				const { revision, revision_id, status_id, domain_id, trade_date, sender_company_id, sender_market_role_id } = result_schedule.data;

				let time_series = [];
				for (let i = 0; i < result_schedule.data.time_series.length; i++) {
					time_series.push(parseInt(result_schedule.data.time_series[i].time_series_id));
				}

				// download relevant data
				const result_data = await download_schedule_time_series_data(domain_id, trade_date);

				setLocalState(prev_state => ({ ...prev_state,
					db_revision: parseInt(revision),
					db_revision_id: parseInt(revision_id),
					db_status_id: parseInt(status_id),
					db_domain_id: parseInt(domain_id),
					db_trade_date: getDateFormat(trade_date),
					db_sender_company_id: parseInt(sender_company_id),
					db_sender_role_id: parseInt(sender_market_role_id),
					db_schedules: result_data.schedules,
					db_schedule_id: parseInt(schedule_id),
					db_time_series: result_data.time_series,
				}));

				setSelectedDbTs(time_series)

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}

				console.log(error);
			}
		}

		if (schedule_id > 0) {
			fetch_data(schedule_id);
		}

	}, [schedule_id])


	useEffect(() => {

		async function fetch_data(time_series_id) {

			try {

				// download schedule
				const result_ts = await axios.get('/api/bc/ts/' + time_series_id);

				const { revision, revision_id, status_id, domain_id, trade_date } = result_ts.data;

				// download relevant data
				const result_data = await download_schedule_time_series_data(domain_id, trade_date);

				setLocalState(prev_state => ({ ...prev_state,
					db_revision: parseInt(revision),
					db_revision_id: parseInt(revision_id),
					db_status_id: parseInt(status_id),
					db_domain_id: parseInt(domain_id),
					db_trade_date: getDateFormat(trade_date),
					db_schedules: result_data.schedules,
					db_time_series: result_data.time_series,
				}));

				setSelectedDbTs([ time_series_id ])

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}

				console.log(error);
			}
		}

		if (time_series_id > 0) {
			fetch_data(time_series_id);
		}

	}, [time_series_id])


	useEffect(() => {

		async function fetch_data(imported_file) {

			let domain = global_state.domains.find(item => item.domain_code_eic.localeCompare(imported_file.domain) === 0);
			let domain_id = (domain !== undefined) ? domain.domain_id : 0;
			let sender_company = global_state.companies.find(item => item.eic_x_code.localeCompare(imported_file.sender) === 0);
			let sender_id = (sender_company !== undefined) ? sender_company.company_id : imported_file.sender;
			let sender_role = global_state.market_roles.find(item => item.role_code.localeCompare(imported_file.sender_role) === 0);
			let sender_role_id = (sender_role !== undefined) ? sender_role.role_id : 0;

			let imported_ts = [];
			for (let i = 0; i < imported_file.time_series.length; i++) {

				let ts_domain = global_state.domains.find(item => item.domain_code_eic.localeCompare(imported_file.time_series[i].in_domain) === 0);
				let current_domain_id = (ts_domain !== undefined) ? ts_domain.domain_id : 0;
				let current_consumer = global_state.companies.find(item => item.eic_x_code.localeCompare(imported_file.time_series[i].consumer) === 0);
				let current_consumer_id = (current_consumer !== undefined) ? current_consumer.company_id : imported_file.time_series[i].consumer;
				let current_seller = global_state.companies.find(item => item.eic_x_code.localeCompare(imported_file.time_series[i].seller) === 0);
				let current_seller_id = (current_seller !== undefined) ? current_seller.company_id : imported_file.time_series[i].seller;

				imported_ts.push({
					rid: imported_file.time_series[i].rid,
					domain_id: current_domain_id,
					trade_date: imported_file.time_series[i].trade_date,
					consumer_company_id: current_consumer_id,
					seller_company_id: current_seller_id,
					points: imported_file.time_series[i].points,
				});
			}

			const { schedules, time_series } = await download_schedule_time_series_data(domain_id, imported_file.trade_date);

			setLocalState(prev_state => ({ ...prev_state,
				file_domain_id: domain_id,
				file_trade_date: getDateFormat(imported_file.trade_date),
				file_sender_company_id: sender_id,
				file_sender_role_id: sender_role_id,

				file_receiver_company_name: imported_file.receiver,
				file_receiver_company_role: imported_file.receiver_role,
				file_time_series: imported_ts,

				db_schedules: schedules,
				db_time_series: time_series,
			}));
		}

		if (imported_file !== undefined) {
			fetch_data(imported_file);
		}

	}, [imported_file, global_state])


	useEffect(() => {

		if (db_ts_detail !== 0) {

			axios.get('/api/bc/ts/full_by_id', { params: { ids: [ db_ts_detail ] }})
				.then(response => {

					const time_series = response.data;
					if(Array.isArray(time_series)) {
						setDbTsDetailFull(time_series[0]);
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

		} else {
			setDbTsDetailFull(undefined);
		}

	}, [db_ts_detail])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (schedule_id > 0) {
				axios.post('/api/bc/schedules/' + schedule_id + '/close');
			}
			if (time_series_id > 0) {
				axios.post('/api/bc/ts/' + time_series_id + '/close');
			}
		}

	}, [schedule_id, time_series_id])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id }>{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies
			.filter(company => company.company_id === user.company_id)
			.map(item => {
				return (
					<option key={ item.company_id } value={ item.company_id }>{ item.name }</option>
				)
			})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for market roles
	var display_roles = [];
	if (global_state.market_roles.length > 0) {
		display_roles = global_state.market_roles.map(item => {
			return (
				<option key={ item.role_id } value={ item.role_id }>{ item.role_code + ': ' + item.role_comment }</option>
			)
		})
		display_roles.unshift(<option key={-1} value={''} ></option>)
	}

	var file_ts_display = [];
	var db_ts_display = [];
	var schedules_display = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		if (local_state.file_time_series.length > 0) {

			file_ts_display = local_state.file_time_series.map(item => {

				const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
				const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
				const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);
				var amount = 0;

				item.points.forEach(point => {
					amount += parseFloat(point.value);
				})

				return ({
					key: item.rid,
					id: item.rid,
					domain: (domain !== undefined) ? domain.domain_display_name : item.domain_id,
					revision: item.revision,
					consumer: (consumer !== undefined) ? consumer.name : item.consumer_company_id,
					seller: (seller !== undefined) ? seller.name : item.seller_company_id,
					amount: amount,
				})
			});

			if (file_ts_detail !== '') {

				const ts = local_state.file_time_series.find(item => item.rid === file_ts_detail);
				const consumer = global_state.companies.find(item => item.company_id === ts.consumer_company_id);
				const seller = global_state.companies.find(item => item.company_id === ts.seller_company_id);

				ts_compare_1 = {
					name: ts.rid,
					status: '-',
					consumer_company_id: ts.consumer_company_id,
					consumer: (consumer !== undefined) ? consumer.name : ts.consumer_company_id,
					seller_company_id: ts.seller_company_id,
					seller: (seller !== undefined) ? seller.name : ts.seller_company_id,
					trade_date: ts.trade_date,
					points: ts.points,
				}
			}
		}

		if (local_state.db_time_series.length > 0) {
			db_ts_display = local_state.db_time_series.map(item => {

				const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
				const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
				const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);
				const status = global_state.statuses.find(elem => elem.status_id === item.status_id);
	
				return ({
					key: item.time_series_id,
					id: item.time_series_id,
					domain: (domain !== undefined) ? domain.domain_display_name : item.domain_id,
					revision: item.revision,
					consumer: (consumer !== undefined) ? consumer.name : item.consumer_company_id,
					seller: (seller !== undefined) ? seller.name : item.seller_company_id,
					status: (status !== undefined) ? status.status_name : undefined,
					amount: item.amount,
				})
			});
		}

		if (local_state.db_schedules.length > 0) {
			schedules_display = local_state.db_schedules.map(item => {

				const sender = global_state.companies.find(elem => elem.company_id === item.sender_company_id);
				const sender_name = (sender !== undefined) ? sender.name : '';
				const status = global_state.statuses.find(elem => elem.status_id === item.status_id);

				return (
					<option
						key={ item.schedule_id }
						value={ item.schedule_id }
					> {item.schedule_id}. {sender_name}: {getLocalDateString(item.trade_date)}. {status.status_name} </option>
				)
			})
			schedules_display.unshift(<option key={-1} value={''} ></option>)
		}

		if (db_ts_detail_full !== undefined) {

			const consumer = global_state.companies.find(item => item.company_id === db_ts_detail_full.consumer_company_id);
			const seller = global_state.companies.find(item => item.company_id === db_ts_detail_full.seller_company_id);

			const points = [];
			for(let i = 1; i <= 25; i++) {

				const index = (i < 10) ? `p0${i}` : `p${i}`;
				if (db_ts_detail_full[index] !== null) {
					points.push({
						id: i,
						value: db_ts_detail_full[index],
					})
				}
			}

			ts_compare_2 = {
				name: db_ts_detail_full.time_series_id,
				status_id: db_ts_detail_full.status_id,
				consumer: (consumer !== undefined) ? consumer.name : '',
				seller: (seller !== undefined) ? seller.name : '',
				trade_date: db_ts_detail_full.trade_date,
				points: points,
			}
		}
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				<ImportExportIcon />
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				Імпорт / експорт графіків та часових рядів
			</Typography>

			<Grid container spacing={1} className={classes.grid_1}>

				<Grid item xs>

					<Grid container spacing={2} className={ classes.form_control }>

						<Grid item xs={12}>

							<Typography component='h2' variant='h5' align='center'>
								Файл імпорту
							</Typography>

						</Grid>

						<Grid item xs={12}>

							<TextField
								InputLabelProps={{ shrink: true }}
								required
								variant='outlined'
								fullWidth
								type='file'
								label='Файл графіку MMS'
								inputProps= {{ accept:'.xml, .xlsx' }}
								onChange= { handleImportFileChange }
							/>

						</Grid>

					</Grid>

				</Grid>

				<Grid item xs>

					<Grid container spacing={2} className={ classes.form_control }>

						<Grid item xs={12}>

							<Typography component='h2' variant='h5' align='center'>
								Існуючі графіки
							</Typography>

						</Grid>

						<Grid item xs={12}>

							<FormControl
								variant='outlined'
								required
								fullWidth
							>
								<InputLabel ref={ schedule_label } id='schedule-label'>Графік</InputLabel>
								<Select
									native
									labelId='schedule-label'
									labelWidth={ schedule_label_width }
									value={ local_state.db_schedule_id }
									disabled={ imported_file === undefined }
									onChange={ onDbScheduleChange }
								>
									{ schedules_display }
								</Select>
							</FormControl>

						</Grid>

					</Grid>

				</Grid>

			</Grid>

			<Grid container spacing={1} className={classes.grid_2}>

				<Grid item xs>

					<Grid container spacing={2} className={ classes.form_control }>

						<Grid item xs={12}>

							<Typography component='h2' variant='h5' align='center'>
								Графік з файлу імпорта
							</Typography>

						</Grid>

						<Grid item xs={6}>

							<FormControl
								variant='outlined'
								required
								fullWidth
							>
								<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
								<Select
									native
									labelId='domain-label'
									labelWidth={ domain_label_width }
									value={ local_state.file_domain_id }
									disabled={ local_state.file_domain_id !== 0 }
								>
									{ display_domains }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={6}>

							<TextField
								variant='outlined'
								required
								fullWidth
								label='Доба торгівлі'
								type='date'
								value = { local_state.file_trade_date }
								InputLabelProps={{ shrink: true }}
								disabled={ local_state.file_trade_date !== '' }
							/>

						</Grid>

						<Grid item xs={12}>

							<Typography component='h3' variant='body1' align='center'>
								Компанії
							</Typography>

						</Grid>

						<Grid item xs={6}>

							<FormControl
								variant='outlined'
								required
								fullWidth
								className={classes.control_bottom_margin}
							>
								<InputLabel ref={ sender_company_label } id='company-label'>Відправник</InputLabel>
								<Select
									native
									labelId='company-label'
									labelWidth={ sender_company_label_width }
									value={ local_state.file_sender_company_id }
									disabled={ local_state.file_sender_company_id !== 0 }
								>
									{ display_companies }
								</Select>
							</FormControl>

							<FormControl
								variant='outlined'
								required
								fullWidth
								className={classes.control_bottom_margin}
							>
								<InputLabel ref={ sender_role_label } id='role-label'>Роль відправника</InputLabel>
								<Select
									native
									labelId='role-label'
									labelWidth={ sender_role_label_width }
									value={ local_state.file_sender_role_id }
									disabled={ local_state.file_sender_role_id !== 0 }
								>
									{ display_roles }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={6}>

							<TextField
								label='Отримувач'
								variant='outlined'
								className={classes.control_bottom_margin}
								fullWidth
								value = { local_state.file_receiver_company_name }
								disabled
							/>

							<TextField
								label='Роль отримувача'
								variant='outlined'
								className={classes.control_bottom_margin}
								fullWidth
								value = { local_state.file_receiver_company_role }
								disabled
							/>

						</Grid> 

						<Grid item xs={12}>

							<Typography component='h3' variant='body1' align='center'>
								Часові ряди
							</Typography>

						</Grid>

						<Grid item xs={12}>

							<TimeSeriesShortTable
								rows={file_ts_display}
								detail={file_ts_detail}
								setDetail={setFileTsDetail}
							/>

						</Grid>

					</Grid>

				</Grid>

				<Grid item xs>

					<Grid container spacing={2} className={ classes.form_control }>

						<Grid item xs={12}>

							<Typography component='h2' variant='h5' align='center'>
								Існуючий графік
							</Typography>

						</Grid>

						<Grid item xs={6}>

							<FormControl
								variant='outlined'
								required
								fullWidth
							>
								<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
								<Select
									native
									labelId='domain-label'
									labelWidth={ domain_label_width }
									value={ local_state.db_domain_id }
									disabled={ local_state.db_schedule_id > 0 }
								>
									{ display_domains }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={6}>

							<TextField
								variant='outlined'
								required
								fullWidth
								label='Доба торгівлі'
								type='date'
								value = { local_state.db_trade_date }
								InputLabelProps={{ shrink: true }}
								disabled={ local_state.db_schedule_id > 0 }
							/>

						</Grid>

						<Grid item xs={12}>

							<Typography component='h3' variant='body1' align='center'>
								Компанії
							</Typography>

						</Grid>

						<Grid item xs={6}>

							<FormControl
								variant='outlined'
								required
								fullWidth
								className={classes.control_bottom_margin}
							>
								<InputLabel ref={ sender_company_label } id='company-label'>Відправник</InputLabel>
								<Select
									native
									labelId='company-label'
									labelWidth={ sender_company_label_width }
									value={ local_state.db_sender_company_id }
									disabled={ local_state.db_schedule_id > 0 }
								>
									{ display_companies }
								</Select>
							</FormControl>

							<FormControl
								variant='outlined'
								required
								fullWidth
								className={classes.control_bottom_margin}
							>
								<InputLabel ref={ sender_role_label } id='role-label'>Роль відправника</InputLabel>
								<Select
									native
									labelId='role-label'
									labelWidth={ sender_role_label_width }
									value={ local_state.db_sender_role_id }
									onChange={ handleRoleChange }
									disabled={ local_state.db_schedule_id > 0 }
								>
									{ display_roles }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={6}>

							<TextField
								label='Отримувач'
								variant='outlined'
								className={classes.control_bottom_margin}
								fullWidth
								value = { local_state.db_receiver_company_name }
								disabled
							/>

							<TextField
								label='Роль отримувача'
								variant='outlined'
								className={classes.control_bottom_margin}
								fullWidth
								value = { local_state.db_receiver_company_role }
								disabled
							/>

						</Grid>

						<Grid item xs={12}>

							<Typography component='h3' variant='body1' align='center'>
								Існуючі часові ряди <i>(Часові ряди, що входять до графіку, відмічені)</i>
							</Typography>

						</Grid>

						<Grid item xs={12}>

							<SelectTimeSeriesShortTable
								rows={db_ts_display}
								selected={selected_db_ts}
								setSelected={setSelectedDbTs}
								detail={db_ts_detail}
								setDetail={setDbTsDetail}
							/>

						</Grid>

					</Grid>

				</Grid>

			</Grid>

			{ (ts_compare_1 !== undefined || ts_compare_2 !== undefined) &&
			<Grid container spacing={1} className={ classes.form_control }>

				<Grid item xs={12}>

					<Typography component='h2' variant='h5' align='center'>
						Порівняння часових рядів
					</Typography>

				</Grid>

				<Grid item xs={12}>

					<TimeSeriesCompare
						rows={ [ ts_compare_1, ts_compare_2 ] }
						onDelete={ onTsDelete }
					/>

					{ (ts_compare_1 !== undefined) &&
						<div className={classes.button_form_control}>
							<Button
								variant='contained'
								color='primary'
								className={ classes.button_control }
								disabled={ ts_compare_1 === undefined }
								onClick={ OnImportTsHandler }
							>
								{ (ts_compare_2 === undefined) ? 'Імпорт ряду' : 'Перезаписати ряд' }
							</Button>
						</div>
					}

				</Grid>

			</Grid> }

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					disabled={ imported_file === undefined }
					onClick={ OnImportAllTsHandler }
				>
					Імпортувати всі часові ряди
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					disabled={ imported_file === undefined }
					onClick={ OnImportHeadHandler }
				>
					{ (local_state.db_schedule_id === 0) ? 'Імпорт графіку' : 'Перезаписати графік' }
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					onClick={ onExportTsHandler }
				>
					Експорт
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className = { classes.button_control }
					onClick= { () => props.history.go(-1) }
				>
					Скасувати
				</Button>

			</div>

			<OptionConfirmationDialog
				keepMounted
				open={ open_option_dialog }
				onClose={ handleOptionDialogClose }
				title= { 'Експорт часових графіків / часових рядів. Вибіріть формат експорту' }
				options= { [ 'xml', 'xlsx' ] }
				init_value={ 'xlsx' }
			/>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ message.info }
				onClose={ onErrorMessageClose }
			/>

		</div>
	);
}