import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Avatar from '@material-ui/core/Avatar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import ComponentCapacityExportForm from './ComponentCapacityExportForm';
import { capacityContext } from './capacityContext';

import { getDateFormat, getLocalDateString, parse_month } from '../utils/date_functions';
import { get_doamins_by_tab_name, capacity_v2_from_json, limits_from_json } from '../utils/capacity_functions';
import { is_company_name_equal } from '../utils/validation';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	main_form: {
		marginTop: theme.spacing(2)
	},

	grid: {
		marginTop: theme.spacing(4),
		flexGrow: 1,
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		textAlign: 'center',
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	box: {
		maxWidth: '30%',
		border: '1px solid gray',
		borderRadius: '5px',
		padding: theme.spacing(1),
		margin: theme.spacing(1, 1, 1, 0),
		display: 'inline-block',
		alignItems: 'center'
	},

	texts: {
		textAlign: 'left',
		margin: theme.spacing(1, 0, 2, 0),
	},

	company_unknown: {
		color: 'red'
	},

	company_known: {
		color: 'green'
	},

	table_control: {
		border: '1px solid gray',
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},

	table_head: {
		fontWeight: 'bold',
	},

	table_row_1: {
		background: 'white'
	},

	table_row_2: {
		background: '#F0FFFF'
	},

}));


export default function CapacityImportExportForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// variables for the label control
	const type_label = React.useRef(null);
	const out_domain_label = React.useRef(null);
	const in_domain_label = React.useRef(null);
	// state for label widths
	const [type_label_width, setTypeLabelWidth] = useState(0);
	const [out_domain_label_width, setOutDomainLabelWidth] = useState(0);
	const [in_domain_label_width, setInDomainLabelWidth] = useState(0);

	// import context
	const { global_state, setGlobalState } = useContext(capacityContext);


	function getTabsProps(index) {

		return {
			id:					`capacities-tab-${index}`,
			'aria-controls':	`capacities-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
			<div
				hidden={value !== index}
				id={`capacities-tabpanel-${index}`}
				aria-labelledby={`capacities-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	function createInitialState() {

		const complex_state = {
			type_id: 0,
			saved_auctions: [],
			auctions: [],
			limits: [],
		}

		return complex_state;
	}


	function handleImportCapacityFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const file_name = event_value.name;
			const file_name_parts = file_name.split(' ');

			const date_str_reverse = file_name.replace(/[^_0-9-]/g, '');
			const temp_parts = date_str_reverse.split(/[-_]+/).filter(a => a).reverse();
			let date_str = temp_parts.join('-');
			let month_str = '';
			let month = 0;

			// auction type is day
			let auction_type = 0;

			if (temp_parts.length === 1) {

				month_str = file_name_parts[file_name_parts.length - 2];
				month = parse_month(month_str);
				date_str = `${temp_parts[0]}-${month}`;

				auction_type = 1;
			}

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });

					const capacities_list = [];

					for (let i = 0; i < wb.SheetNames.length; i++) {

						const { in_domain, out_domain } = get_doamins_by_tab_name(wb.SheetNames[i]);

						const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], { header: 1 });
						const capacities = capacity_v2_from_json(json_doc, auction_type, date_str);

						capacities.forEach(item => {

							const out_domain_obj = global_state.domains.find(d => d.domain_name === out_domain);
							const in_domain_obj = global_state.domains.find(d => d.domain_name === in_domain);
							const company = global_state.companies.find(c => is_company_name_equal(c.name, item.company_name));

							item.out_domain = out_domain;
							item.out_domain_id = out_domain_obj.domain_id;
							item.in_domain = in_domain;
							item.in_domain_id = in_domain_obj.domain_id;
							item.company_id = (company !== undefined) ? company.company_id : undefined;
						});

						if (capacities.length > 0) {
							capacities_list.push(...capacities);
						}
					}

					setLocalState(prev_state => ({ ...prev_state,
						type_id: auction_type,
						auctions: capacities_list,
					}));

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleImportLimitFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });
	
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
					const limits = limits_from_json(json_doc);

					limits.forEach(item => {

						if (item.out_domain !== undefined && item.in_domain !== undefined) {

							const out_domain = global_state.domains.find(d => d.domain_name === item.out_domain);
							const in_domain = global_state.domains.find(d => d.domain_name === item.in_domain);

							item.out_domain_id = out_domain.domain_id;
							item.in_domain_id = in_domain.domain_id;
						}
					});
					
					setLocalState(prev_state => ({ ...prev_state,
						limits: limits,
					}));

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleOutDomainChange(item, event) {

		if (item.type_id === 0) {

			const event_value = event.target.value;
			const new_capacities = [ ...local_state.auctions ];

			new_capacities.forEach(item => item.out_domain_id = parseInt(event_value));

			setLocalState(prev_state => ({ ...prev_state,
				auctions: new_capacities,
			}));
		}
	}


	function handleInDomainChange(item, event) {

		if (item.type_id === 0) {

			const event_value = event.target.value;
			const new_capacities = [ ...local_state.auctions ];

			new_capacities.forEach(item => item.in_domain_id = parseInt(event_value));

			setLocalState(prev_state => ({ ...prev_state,
				auctions: new_capacities,
			}));
		}
	}


	async function save_capacity(capacity) {

		if (capacity.company_id === undefined) {
			return;
		}

		try {

			const new_data = {
				type_id: capacity.type_id,
				number: capacity.number,
				out_domain_id: capacity.out_domain_id,
				in_domain_id: capacity.in_domain_id,
				period_start: getDateFormat(capacity.period_start),
				period_end: getDateFormat(capacity.period_end),
				company_id: capacity.company_id,
				p01_os: (capacity.points.length > 0) ? capacity.points[0].os : null,
				p01_rs: (capacity.points.length > 0) ? capacity.points[0].rs : null,
				p01_pr: (capacity.points.length > 0) ? capacity.points[0].price : null,
				p02_os: (capacity.points.length > 1) ? capacity.points[1].os : null,
				p02_rs: (capacity.points.length > 1) ? capacity.points[1].rs : null,
				p02_pr: (capacity.points.length > 1) ? capacity.points[1].price : null,
				p03_os: (capacity.points.length > 2) ? capacity.points[2].os : null,
				p03_rs: (capacity.points.length > 2) ? capacity.points[2].rs : null,
				p03_pr: (capacity.points.length > 2) ? capacity.points[2].price : null,
				p04_os: (capacity.points.length > 3) ? capacity.points[3].os : null,
				p04_rs: (capacity.points.length > 3) ? capacity.points[3].rs : null,
				p04_pr: (capacity.points.length > 3) ? capacity.points[3].price : null,
				p05_os: (capacity.points.length > 4) ? capacity.points[4].os : null,
				p05_rs: (capacity.points.length > 4) ? capacity.points[4].rs : null,
				p05_pr: (capacity.points.length > 4) ? capacity.points[4].price : null,
				p06_os: (capacity.points.length > 5) ? capacity.points[5].os : null,
				p06_rs: (capacity.points.length > 5) ? capacity.points[5].rs : null,
				p06_pr: (capacity.points.length > 5) ? capacity.points[5].price : null,
				p07_os: (capacity.points.length > 6) ? capacity.points[6].os : null,
				p07_rs: (capacity.points.length > 6) ? capacity.points[6].rs : null,
				p07_pr: (capacity.points.length > 6) ? capacity.points[6].price : null,
				p08_os: (capacity.points.length > 7) ? capacity.points[7].os : null,
				p08_rs: (capacity.points.length > 7) ? capacity.points[7].rs : null,
				p08_pr: (capacity.points.length > 7) ? capacity.points[7].price : null,
				p09_os: (capacity.points.length > 8) ? capacity.points[8].os : null,
				p09_rs: (capacity.points.length > 8) ? capacity.points[8].rs : null,
				p09_pr: (capacity.points.length > 8) ? capacity.points[8].price : null,
				p10_os: (capacity.points.length > 9) ? capacity.points[9].os : null,
				p10_rs: (capacity.points.length > 9) ? capacity.points[9].rs : null,
				p10_pr: (capacity.points.length > 9) ? capacity.points[9].price : null,
				p11_os: (capacity.points.length > 10) ? capacity.points[10].os : null,
				p11_rs: (capacity.points.length > 10) ? capacity.points[10].rs : null,
				p11_pr: (capacity.points.length > 10) ? capacity.points[10].price : null,
				p12_os: (capacity.points.length > 11) ? capacity.points[11].os : null,
				p12_rs: (capacity.points.length > 11) ? capacity.points[11].rs : null,
				p12_pr: (capacity.points.length > 11) ? capacity.points[11].price : null,
				p13_os: (capacity.points.length > 12) ? capacity.points[12].os : null,
				p13_rs: (capacity.points.length > 12) ? capacity.points[12].rs : null,
				p13_pr: (capacity.points.length > 12) ? capacity.points[12].price : null,
				p14_os: (capacity.points.length > 13) ? capacity.points[13].os : null,
				p14_rs: (capacity.points.length > 13) ? capacity.points[13].rs : null,
				p14_pr: (capacity.points.length > 13) ? capacity.points[13].price : null,
				p15_os: (capacity.points.length > 14) ? capacity.points[14].os : null,
				p15_rs: (capacity.points.length > 14) ? capacity.points[14].rs : null,
				p15_pr: (capacity.points.length > 14) ? capacity.points[14].price : null,
				p16_os: (capacity.points.length > 15) ? capacity.points[15].os : null,
				p16_rs: (capacity.points.length > 15) ? capacity.points[15].rs : null,
				p16_pr: (capacity.points.length > 15) ? capacity.points[15].price : null,
				p17_os: (capacity.points.length > 16) ? capacity.points[16].os : null,
				p17_rs: (capacity.points.length > 16) ? capacity.points[16].rs : null,
				p17_pr: (capacity.points.length > 16) ? capacity.points[16].price : null,
				p18_os: (capacity.points.length > 17) ? capacity.points[17].os : null,
				p18_rs: (capacity.points.length > 17) ? capacity.points[17].rs : null,
				p18_pr: (capacity.points.length > 17) ? capacity.points[17].price : null,
				p19_os: (capacity.points.length > 18) ? capacity.points[18].os : null,
				p19_rs: (capacity.points.length > 18) ? capacity.points[18].rs : null,
				p19_pr: (capacity.points.length > 18) ? capacity.points[18].price : null,
				p20_os: (capacity.points.length > 19) ? capacity.points[19].os : null,
				p20_rs: (capacity.points.length > 19) ? capacity.points[19].rs : null,
				p20_pr: (capacity.points.length > 19) ? capacity.points[19].price : null,
				p21_os: (capacity.points.length > 20) ? capacity.points[20].os : null,
				p21_rs: (capacity.points.length > 20) ? capacity.points[20].rs : null,
				p21_pr: (capacity.points.length > 20) ? capacity.points[20].price : null,
				p22_os: (capacity.points.length > 21) ? capacity.points[21].os : null,
				p22_rs: (capacity.points.length > 21) ? capacity.points[21].rs : null,
				p22_pr: (capacity.points.length > 21) ? capacity.points[21].price : null,
				p23_os: (capacity.points.length > 22) ? capacity.points[22].os : null,
				p23_rs: (capacity.points.length > 22) ? capacity.points[22].rs : null,
				p23_pr: (capacity.points.length > 22) ? capacity.points[22].price : null,
				p24_os: (capacity.points.length > 23) ? capacity.points[23].os : null,
				p24_rs: (capacity.points.length > 23) ? capacity.points[23].rs : null,
				p24_pr: (capacity.points.length > 23) ? capacity.points[23].price : null,
				p25_os: (capacity.points.length > 24) ? capacity.points[24].os : null,
				p25_rs: (capacity.points.length > 24) ? capacity.points[24].rs : null,
				p25_pr: (capacity.points.length > 24) ? capacity.points[24].price : null
			};

			// add or update capacity
			await axios.post('/api/capacity/add', new_data);

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	async function save_limit(limit) {

		try {

			const new_data = {
				type_id: limit.type_id,
				capacity_id: limit.capacity_id,
				limit_date: getDateFormat(limit.limit_date),
				p01: (limit.points.length > 0) ? limit.points[0].value : null,
				p02: (limit.points.length > 1) ? limit.points[1].value : null,
				p03: (limit.points.length > 2) ? limit.points[2].value : null,
				p04: (limit.points.length > 3) ? limit.points[3].value : null,
				p05: (limit.points.length > 4) ? limit.points[4].value : null,
				p06: (limit.points.length > 5) ? limit.points[5].value : null,
				p07: (limit.points.length > 6) ? limit.points[6].value : null,
				p08: (limit.points.length > 7) ? limit.points[7].value : null,
				p09: (limit.points.length > 8) ? limit.points[8].value : null,
				p10: (limit.points.length > 9) ? limit.points[9].value : null,
				p11: (limit.points.length > 10) ? limit.points[10].value : null,
				p12: (limit.points.length > 11) ? limit.points[11].value : null,
				p13: (limit.points.length > 12) ? limit.points[12].value : null,
				p14: (limit.points.length > 13) ? limit.points[13].value : null,
				p15: (limit.points.length > 14) ? limit.points[14].value : null,
				p16: (limit.points.length > 15) ? limit.points[15].value : null,
				p17: (limit.points.length > 16) ? limit.points[16].value : null,
				p18: (limit.points.length > 17) ? limit.points[17].value : null,
				p19: (limit.points.length > 18) ? limit.points[18].value : null,
				p20: (limit.points.length > 19) ? limit.points[19].value : null,
				p21: (limit.points.length > 20) ? limit.points[20].value : null,
				p22: (limit.points.length > 21) ? limit.points[21].value : null,
				p23: (limit.points.length > 22) ? limit.points[22].value : null,
				p24: (limit.points.length > 23) ? limit.points[23].value : null,
				p25: (limit.points.length > 24) ? limit.points[24].value : null,
			};

			await axios.post('/api/capacity/limit/add', new_data);

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	async function onAllImportHandler() {

		for (let i = 0; i < local_state.auctions.length; i++) {

			const item = local_state.auctions[i];

			if (item.company_id !== undefined) {
				await save_capacity(item);
			}
		}

		let error_message = '';

		for (let i = 0; i < local_state.limits.length; i++) {

			const limit = local_state.limits[i];

			const cap_response = await axios.get('/api/capacity', {
				params: {
					out_domain: limit.out_domain_id,
					in_domain: limit.in_domain_id,
					period_start: getDateFormat(limit.limit_date),
					period_end: getDateFormat(limit.limit_date),
				}
			});

			const saved_auctions = cap_response.data.capacities_list;

			if (saved_auctions.length === 0) {
				error_message += `Не можливо імпортувати обмеження ${i + 1}. Не знайдено відповідного аукціону. `; 
			} else {

				const item_capacity = saved_auctions.filter(item => 
					(((limit.number !== undefined) && 
					(item.auction_number === limit.number)) || (limit.number === undefined)) &&
					(item.type === limit.type_id)
				);

				if (item_capacity.length !== 1) {
					error_message += `Не можливо імпортувати обмеження ${i + 1}. Не знайдено відповідного аукціону або знайдено декілька аукціонів на відповідний період. `; 
				} else {

					limit.capacity_id = item_capacity[0].capacity_id;
					save_limit(limit);
				}
			}
		}

		if (error_message.length > 0) {

			setMessage(message => ({ ...message,
				show: true,
				message: error_message,
			}));
		}

		setGlobalState(prev_state => ({ ...prev_state,
			update_flag: !prev_state.update_flag,
		}));

		window.alert('Імпорт завершено.')
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (type_label.current) {
			setTypeLabelWidth(type_label.current.offsetWidth);
		}
		if (out_domain_label.current) {
			setOutDomainLabelWidth(out_domain_label.current.offsetWidth);
		}
		if (in_domain_label.current) {
			setInDomainLabelWidth(in_domain_label.current.offsetWidth);
		}

	}, [tab_no, local_state.auctions, local_state.limits])


	var display_types = [];
	display_types.push(<option key={-1} value={''}></option>);
	display_types.push(<option key={0} value={0}>Добовий</option>);
	display_types.push(<option key={1} value={1}>Місячний</option>);
	display_types.push(<option key={2} value={2}>Річний</option>);


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => <option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>);
		display_domains.unshift(<option key={-1} value={''} ></option>);
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => (
			<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
		));
		display_companies.unshift(<option key={-1} value={''} ></option>);
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				<ImportExportIcon />
			</Avatar>

			<Tabs
				value={tab_no}
				onChange={OnTabChage}
				className={classes.tab}
				indicatorColor='primary'
				textColor='primary'
			>
				<Tab label={ 'Імпорт' } {...getTabsProps(0)} />
				<Tab label={ 'Експорт' } {...getTabsProps(1)} />
			</Tabs>

			<TabPanel value={tab_no} index={0}>

				<Grid container spacing={2} className={ classes.main_form }>

					<Grid item xs={6}>

						<Typography component='h2' variant='h4' align='center'>
							Імпорт результатів аукціону з розподілу пропускної спороможності
						</Typography>

					</Grid>

					<Grid item xs={6}>

						<Typography component='h2' variant='h4' align='center'>
							Імпорт результатів обмеження аукціону з розподілу пропускної спороможності
						</Typography>

					</Grid>

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							required
							variant='outlined'
							fullWidth
							type='file'
							label='Файл аукціонів'
							inputProps= {{ accept:'.xls, .xlsx' }}
							onChange= { handleImportCapacityFileChange }
						/>

					</Grid>

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							required
							variant='outlined'
							fullWidth
							type='file'
							label='Файл обмеження'
							inputProps= {{ accept:'.xls, .xlsx' }}
							onChange= { handleImportLimitFileChange }
						/>

					</Grid>

				</Grid>

				{ (local_state.auctions.length > 0) &&
				<div className={ classes.form_control } >

					<Grid container spacing={2} className={ classes.grid }>

						<Grid item xs={3}>

							<FormControl variant='outlined' className={classes.control_bottom_margin} fullWidth>
								<InputLabel ref={ type_label } id='type-label'>Тип аукціону</InputLabel>
								<Select
									native
									labelId='type-label'
									labelWidth={ type_label_width }
									value={ local_state.type_id }
								>
									{ display_types }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={9} />

					</Grid>

					{
						local_state.auctions.map((item, a_index) => (
							<div
								key={ a_index }
								className={ classes.box }
							>

								<div className={ classes.texts }>

									{ (item.type_id > 0) &&
									<Typography>
										<b>Номер аукціону</b>: { item.number }
									</Typography> }

									<Typography
										className={ (item.company_id === undefined) ? classes.company_unknown : classes.company_known }
									>
										<b>Компанія</b>: { item.company_name }
									</Typography>

								</div>

								<Grid container spacing={2}>

									<Grid item xs={6}>

										<FormControl
											variant='outlined'
											required
											className={classes.control_bottom_margin}
											fullWidth
										>
											<InputLabel ref={ out_domain_label } id='out-domain-label'>Зона балансування експорту</InputLabel>
											<Select
												native
												labelId='out-domain-label'
												labelWidth={ out_domain_label_width }
												onChange={ (event) => handleOutDomainChange(item, event) }
												value={ item.out_domain_id }
											>
												{ display_domains }
											</Select>
										</FormControl>

									</Grid>

									<Grid item xs={6}>

										<FormControl
											variant='outlined'
											required
											className={classes.control_bottom_margin}
											fullWidth
										>
											<InputLabel ref={ in_domain_label } id='in-domain-label'>Зона балансування імпорту</InputLabel>
											<Select
												native
												labelId='in-domain-label'
												labelWidth={ in_domain_label_width }
												onChange={ (event) => handleInDomainChange(item, event) }
												value={ item.in_domain_id }
											>
												{ display_domains }
											</Select>
										</FormControl>

									</Grid>

								</Grid>

								<div className={ classes.texts }>

									<Typography>
										<b>{ (item.type_id === 0) ? 'Доба торгівлі' : 'Начало періоду'}</b>: { getLocalDateString(item.period_start) }
									</Typography>

									{ (item.type_id > 0) &&
									<Typography>
										<b>Кінець періоду</b>: { getLocalDateString(item.period_end) }
									</Typography> }

								</div>

								{
									(item.points.length) > 0 &&
									<table className={classes.table_control}>
										<thead>
											<tr className={classes.table_head}>
												{ (item.type_id === 0) &&
													<td>
														Година
													</td>
												}
												<td>
													Гранична ціна
												</td>
												<td>
													ОС (МВт)
												</td>
												<td>
													РПС (МВт)
												</td>
											</tr>
										</thead>
										<tbody>
											{
												item.points.map((point, index) => (
													<tr key={index} className={ (index % 2 === 0) ? classes.table_row_1 : classes.table_row_2 }>
														{ (item.type_id === 0) &&
															<td className={classes.table_head}>
																{ point.label }
															</td>
														}
														<td>
															{ (item.type_id === 0 || (item.type_id > 0 && index === 0)) &&
																point.price
															}
														</td>
														<td>
															{ (item.type_id === 0 || (item.type_id > 0 && index === 0)) &&
																point.os
															}
														</td>
														<td>
															{ (item.type_id === 0 || (item.type_id > 0 && index === 0)) &&
																point.rs
															}
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								}

							</div>
						))
					}

				</div> }

				{ (local_state.limits.length > 0) &&
				<div className={ classes.form_control } >

					{
						local_state.limits.map((item, l_index) => (
							<div
								key={ l_index }
								className={ classes.box }
							>

								<Grid container spacing={2} className={ classes.grid }>

									<Grid item>

										<FormControl variant='outlined' fullWidth>
											<InputLabel ref={ type_label } id='type-label'>Тип аукціону</InputLabel>
											<Select
												native
												labelId='type-label'
												labelWidth={ type_label_width }
												value={ item.type_id }
											>
												{ display_types }
											</Select>
										</FormControl>

									</Grid>

								</Grid>

								<div className={ classes.texts }>

									{ (item.type_id > 0) &&
									<Typography>
										<b>Номер аукціону</b>: { item.number }
									</Typography> }

								</div>

								<Grid container spacing={2}>

									<Grid item xs={6}>

										<FormControl
											variant='outlined'
											required
											className={classes.control_bottom_margin}
											fullWidth
										>
											<InputLabel ref={ out_domain_label } id='out-domain-label'>Зона балансування експорту</InputLabel>
											<Select
												native
												labelId='out-domain-label'
												labelWidth={ out_domain_label_width }
												onChange={ (event) => handleOutDomainChange(item, event) }
												value={ item.out_domain_id }
											>
												{ display_domains }
											</Select>
										</FormControl>

									</Grid>

									<Grid item xs={6}>

										<FormControl
											variant='outlined'
											required
											className={classes.control_bottom_margin}
											fullWidth
										>
											<InputLabel ref={ in_domain_label } id='in-domain-label'>Зона балансування імпорту</InputLabel>
											<Select
												native
												labelId='in-domain-label'
												labelWidth={ in_domain_label_width }
												onChange={ (event) => handleInDomainChange(item, event) }
												value={ item.in_domain_id }
											>
												{ display_domains }
											</Select>
										</FormControl>

									</Grid>

								</Grid>

								<div className={ classes.texts }>

									<Typography>
										<b>Дата обмеження</b>: { getLocalDateString(item.limit_date) }
									</Typography>

								</div>

								{
									(item.points.length) > 0 &&
									<table className={classes.table_control}>
										<thead>
											<tr className={classes.table_head}>
												<td>
													Година
												</td>
												<td>
													Наявна РПС (МВт)
												</td>
											</tr>
										</thead>
										<tbody>
											{
												item.points.map((point, index) => (
													<tr key={index} className={ (index % 2 === 0) ? classes.table_row_1 : classes.table_row_2 }>
														<td className={classes.table_head}>
															{ point.id }
														</td>
														<td>
															{ point.value }
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								}

							</div>
						))
					}

				</div> }

			</TabPanel>

			<TabPanel value={tab_no} index={1}>

				<ComponentCapacityExportForm />

			</TabPanel>

			{ (tab_no === 0) &&
			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={ classes.button_control }
					onClick={ onAllImportHandler }
					disabled={ local_state.auctions.length === 0 && local_state.limits.length === 0 }
				>
					Імпорт
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className = { classes.button_control }
					onClick= { onCancelHandler }
				>
					Скасувати
				</Button>

			</div> }

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}