
import { excel_date_to_iso_date_string, iso_date_to_excel_date } from './excel_functions';


export function DamOrderFromJson(dam_json) {

	// check array
	if (dam_json.length === 0) {
		return undefined;
	}

	// array of rows
	let order_rows = [];

	const order_id = dam_json[0][8];
	const sender = dam_json[2][5];
	const trade_date = dam_json[0][5];
	const domain = dam_json[3][5];
	const trade_type = dam_json[1][5];
	const acceptance_type = dam_json[4][5];
	const block_type = dam_json[4][11];

	let row_index = 6;
	while (row_index < 82) {

		for (let col_index = 2; col_index < 26; col_index++) {

			const block_number = (row_index === 6) ? 1 : ((row_index - 10) / 3 + 2);
			const period_value = dam_json[row_index][col_index];
			const amount_value = parseFloat(dam_json[row_index + 1][col_index]);
			const price_value = parseFloat(dam_json[row_index + 2][col_index]);
			const splitting_value = (row_index === 6) ? dam_json[row_index + 3][col_index] : undefined;

			if (amount_value !== undefined && !isNaN(amount_value) && price_value !== undefined && !isNaN(price_value)) {
				order_rows.push({
					block_number: block_number,
					period: parseInt(period_value),
					amount: parseFloat(amount_value.toFixed(1)),
					price: parseFloat(price_value.toFixed(2)),
					splitting: splitting_value,
				});
			}
		}

		row_index = (row_index === 6) ? 10 : (row_index + 3);
	}

	var order_date;
	if (typeof trade_date === 'string') {
		const strs = trade_date.split('.');
		order_date= `${strs[2]}-${strs[1]}-${strs[0]}`;
	} else if (typeof trade_date === 'number') {
		order_date = excel_date_to_iso_date_string(trade_date);
	}

	const order = {
		order_id: order_id,
		sender: sender,
		trade_date: order_date,
		domain: domain,
		trade_type: trade_type,
		acceptance_type: acceptance_type,
		block_type: block_type,
		rows: order_rows,
	}

	return order;
}


export function DamOrderToCells(order) {

	const result = [];

	result.push({ c: 5, r: 0, t: 'n', v: iso_date_to_excel_date(order.trade_date) });
	result.push({ c: 5, r: 1, t: 's', v: order.trade_type });
	result.push({ c: 5, r: 2, t: 's', v: order.sender });
	result.push({ c: 5, r: 3, t: 's', v: order.domain });
	result.push({ c: 5, r: 4, t: 's', v: order.acceptance_type });
	result.push({ c: 11, r: 4, t: 's', v: order.block_type });

	order.rows.forEach(item => {

		const row_index = (item.block_number === 1) ? 6 : (item.block_number - 2) * 3 + 10;
		const col_index = item.period + 1;
		
		result.push({ c: col_index, r: row_index + 1, t: 'n', v: item.amount });
		result.push({ c: col_index, r: row_index + 2, t: 'n', v: item.price });

		if (item.block_number === 1) {
			result.push({ c: col_index, r: row_index + 3, t: 's', v: item.splitting_type });
		}
	});

	return result;
}


export function DamOrderFromXml(xml_order) {

	// check document type
	const root_nodes = xml_order.getElementsByTagName('ISOTEDATA');
	if (root_nodes.length !== 1) {
		console.log(root_nodes);
		return undefined;
	}

	const order_rows = [];

	// get Trade nodes
	const trade_nodes = root_nodes[0].getElementsByTagName('Trade');
	for (let i = 0; i < trade_nodes.length; i++) {

		// get profile nodes
		const profile_nodes = trade_nodes[i].getElementsByTagName('ProfileData');
		for (let j = 0; j < profile_nodes.length; j++) {

			// block index
			const index = profile_nodes[j].attributes['profile-role'].value;
			const block_number = parseInt(index.replace( /[^\d.]*/g, ''));
			const is_price_block = index.includes('BP');
			const is_amount_block = index.includes('BC');

			// rows
			const data_nodes = profile_nodes[j].getElementsByTagName('Data');
			for (let k = 0; k < data_nodes.length; k++) {
	
				const period = parseInt(data_nodes[k].attributes['period'].value);
				const value = parseFloat(data_nodes[k].attributes['value'].value);
				const splitting_attribute = data_nodes[k].attributes['splitting'];
				const splitting = (splitting_attribute !== undefined) ? splitting_attribute.value : undefined;

				// find uncomplete existing block
				const existing_row = order_rows.find(item => item.block_number === block_number && item.period === period);
				if (existing_row !== undefined) {
					if (is_price_block) {
						existing_row.price = parseFloat(value.toFixed(2));
					}
					if (is_amount_block) {
						existing_row.amount = parseFloat(value.toFixed(1));
					}
				} else {
					order_rows.push({
						block_number: block_number,
						period: period,
						price: is_price_block ? value : undefined,
						amount: is_amount_block ? value : undefined,
						splitting: (block_number === 1) ? splitting : undefined,
					})
				}
			}
		}
	}
	
	const block_type = trade_nodes[0].attributes['block-type'];
	const block_type_value = (block_type !== undefined) ? block_type.value : '';

	const order = {
		id: root_nodes[0].attributes['id'].value,
		sender: root_nodes[0].getElementsByTagName('SenderIdentification')[0].attributes['id'].value,
		receiver: root_nodes[0].getElementsByTagName('ReceiverIdentification')[0].attributes['id'].value,
		trade_date: trade_nodes[0].attributes['trade-day'].value,
		trade_type: trade_nodes[0].attributes['trade-type'].value,
		acceptance_type: trade_nodes[0].attributes['acceptance'].value,
		block_type: block_type_value,
		domain: trade_nodes[0].attributes['market-area'].value,
		rows: order_rows,
	}

	return order;
}


export function DamOrderToXml(xml_order, export_order) {

	// check document type
	let root_nodes = xml_order.getElementsByTagName('ISOTEDATA');
	if (root_nodes.length !== 1) {
		console.log(root_nodes);
		return undefined;
	}

	root_nodes[0].attributes['id'].value = export_order.order_id;
	root_nodes[0].attributes['date-time'].value = export_order.created_date;

	root_nodes[0].getElementsByTagName('SenderIdentification')[0].attributes['id'].value = export_order.sender;
	root_nodes[0].getElementsByTagName('ReceiverIdentification')[0].attributes['id'].value = export_order.receiver;

	const trade_node = root_nodes[0].getElementsByTagName('Trade')[0];

	trade_node.getElementsByTagName('Party')[0].attributes['id'].value = export_order.sender;
	trade_node.attributes['trade-day'].value = export_order.trade_date;
	trade_node.attributes['trade-type'].value = export_order.trade_type;
	trade_node.attributes['acceptance'].value = export_order.acceptance_type;

	if (export_order.block_type) {
		trade_node.attributes['block-type'].value = export_order.block_type;
	} else {
		trade_node.removeAttribute('block-type');
	}

	trade_node.attributes['market-area'].value = export_order.domain;

	const profile_nodes = trade_node.getElementsByTagName('ProfileData');
	let price_profile_node, amount_profile_node, price_data_node, amount_data_node;

	for (let i = 0; i < profile_nodes.length; i++) {

		if (profile_nodes[i].attributes['profile-role'].value.localeCompare('BC01') === 0) {
			amount_profile_node = profile_nodes[i];

			amount_data_node = amount_profile_node.getElementsByTagName('Data')[0];
			amount_profile_node.removeChild(amount_data_node);
		}
		if (profile_nodes[i].attributes['profile-role'].value.localeCompare('BP01') === 0) {
			price_profile_node = profile_nodes[i];

			price_data_node = price_profile_node.getElementsByTagName('Data')[0];
			price_profile_node.removeChild(price_data_node);
		}
	}

	trade_node.removeChild(amount_profile_node);
	trade_node.removeChild(price_profile_node);

	const price_nodes = [];
	const amount_nodes = [];

	// adding rows
	export_order.rows.forEach(row => {

		const index = row.block_number;

		if (price_nodes[index] === undefined) {

			amount_nodes[index] = amount_profile_node.cloneNode(true);
			price_nodes[index] = price_profile_node.cloneNode(true);

			const profile_number = (index < 10) ? `0${index}` : `${index}`;
			amount_nodes[index].attributes['profile-role'].value = `BC${profile_number}`;
			price_nodes[index].attributes['profile-role'].value = `BP${profile_number}`;

			const party_node = trade_node.getElementsByTagName('Party')[0];

			trade_node.insertBefore(amount_nodes[index], party_node);
			trade_node.insertBefore(price_nodes[index], party_node);
		}

		const new_amount_node = amount_data_node.cloneNode(true);
		const new_price_node = price_data_node.cloneNode(true);

		new_amount_node.attributes['period'].value = row.period;
		new_amount_node.attributes['value'].value = row.amount.toFixed(1);
		new_price_node.attributes['period'].value = row.period;
		new_price_node.attributes['value'].value = row.price.toFixed(2);

		if (row.splitting_type) {
			new_amount_node.attributes['splitting'].value = row.splitting_type;
			new_price_node.attributes['splitting'].value = row.splitting_type;
		} else {
			new_amount_node.removeAttribute('splitting');
			new_price_node.removeAttribute('splitting');
		}

		amount_nodes[index].appendChild(new_amount_node);
		price_nodes[index].appendChild(new_price_node);
	})

	root_nodes[0].normalize();
	return xml_order;
}
