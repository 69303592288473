import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import LockOpenIcon from '@material-ui/icons/LockOpenSharp';
import LockIcon from '@material-ui/icons/LockSharp'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { adminContext } from './adminContext';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	select_form_control: {
		marginTop: theme.spacing(2),
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	grid: {
		marginTop: theme.spacing(2),
	},

	box_red: {
		padding: theme.spacing(2),
		textAlign: 'center',
		background: 'red'
	},

	box_green: {
		padding: theme.spacing(2),
		textAlign: 'center',
		background: 'lightGreen'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(2, 0.5),
		display: 'inline-block',
	},

}));


export default function UserBlockForm(props) {

	const classes = useStyles();

	const { global_state, setGlobalState } = useContext(adminContext);

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	// states for input elements
	const initial_state = {
		// internal states
		user_last_name: '',
		user_first_name: '',
		user_email: '',
		user_blocked: false,
		user_logged_in: false,
	};
	const [local_state, setLocalState] = useState(initial_state);

	// get user id from url
	const user_id = parseInt(props.match.params.user_id);

	// check user id
	if (!user_id) {
		props.history.go(-1);
	}

	const block_url = '/api/admin/user/' + user_id + '/block';
	const kick_url = '/api/admin/user/' + user_id + '/kick';


	const block_unblock_user = async () => {

		const user_data = {
			status: !local_state.user_blocked
		};

		try {

			const response = await axios.post(block_url, user_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	};


	const kick_user_out = async () => {

		try {

			const response = await axios.post(kick_url);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	const onCancelHandler = () => {
		props.history.go(-1);
	};


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	useEffect(() => {

		async function fetch_data(user_id) {

			try {

				const status_is_blocked = await axios.get('/api/admin/user/' + user_id + '/block');
				const status_is_logged_in = await axios.get('/api/admin/user/' + user_id + '/logged_in');

				if (global_state.users.length > 0) {

					const current_user = global_state.users.find(item => item.user_id === user_id);
					if (current_user !== undefined) {
						setLocalState(state => ({...state,
							user_last_name: current_user.last_name,
							user_first_name: current_user.first_name,
							user_email: current_user.email,
							user_blocked: status_is_blocked.data.user_status,
							user_logged_in: status_is_logged_in.data.user_status
						}))
					}
				}

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}
	
				console.log(error);
			}

		}

		fetch_data(user_id);

	},[user_id, global_state]);


	return (

		<Container className={ classes.paper } component='main' maxWidth='md'>

			<Avatar className={ classes.avatar }>
				{ local_state.user_blocked ? <LockIcon /> : <LockOpenIcon /> }
			</Avatar>

			<Typography component='h1' variant='h4'>
				Блокування користувача
			</Typography>

			<TextField
				variant='outlined'
				margin='normal'
				type='text'
				disabled={ true }
				fullWidth
				label='Прізвище'
				value={ local_state.user_last_name }
			/>

			<TextField
				variant='outlined'
				margin='normal'
				type='text'
				disabled={ true }
				fullWidth
				label="Ім'я"
				value={ local_state.user_first_name }
			/>

			<TextField
				variant='outlined'
				margin='normal'
				type='email'
				disabled={ true }
				fullWidth
				label='Email'
				value={ local_state.user_email }
			/>

			<Grid container spacing={2} className={classes.grid}>

				<Grid item xs={8}>
					<div className={ local_state.user_blocked ? classes.box_red : classes.box_green }>
						{ local_state.user_blocked ? 'Користувач заблокований' : 'Користувач разблокований' }
					</div>
				</Grid>

				<Grid item xs={4}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={block_unblock_user}
					>
						{ local_state.user_blocked ? 'Розблокувати користувача' : 'Заблокувати користувача' }
					</Button>

				</Grid>

				<Grid item xs={8}>
					<div className={ local_state.user_logged_in ? classes.box_green : classes.box_red }>
						{ local_state.user_logged_in ? 'Користувач увійшов в систему' : 'Користувач не в системі' }
					</div>
				</Grid>

				<Grid item xs={4}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={kick_user_out}
						disabled={ !local_state.user_logged_in }
					>
						Вибросити користувача з системи
					</Button>

				</Grid>

			</Grid>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='secondary'
					className={ classes.button }
					onClick={ onCancelHandler }
				>
					Скасувати
				</Button>
				
			</div>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>

	);
}