
import { excel_date_to_iso_date_string, iso_date_to_excel_date } from './excel_functions';
import { getHoursInDate, getDateFormat } from './date_functions';


export function PlanRowsFromJson(plan_rows_json) {

	// check array
	if (plan_rows_json.length === 0) {
		return undefined;
	}

	// array of rows
	let plan_rows = [];

	let domain = plan_rows_json[0][1];
	let seller = plan_rows_json[1][1];
	let consumer = plan_rows_json[2][1];

	const start_row_index = 12;

	for (let j = start_row_index; j < plan_rows_json.length; j++) {

		let date;
		let current_row = plan_rows_json[j];

		date = current_row[0];
		if (date !== undefined) {

			let trade_date_string = excel_date_to_iso_date_string(date);

			let trade_date = new Date(trade_date_string);
			trade_date.setHours(0, 0, 0, 0);

			const max_hours = getHoursInDate(trade_date);

			let hour_row = plan_rows_json[j];
			let points = [];
			for (let i = 1; i <= max_hours; i++) {
				if (hour_row[i] !== undefined) {
					points[i - 1] = {
						id: i,
						value: parseFloat(hour_row[i]),
					}
				}
			}

			plan_rows.push({
				domain: domain,
				consumer: consumer,
				seller: seller,
				trade_date: trade_date,
				points: points,
			});

		} else {
			break;
		}
	}

	return plan_rows;
}


export function PaymentDetailsFromJson(plan_rows_json) {

	// check array
	if (plan_rows_json.length === 0) {
		return undefined;
	}
	if (plan_rows_json.length < 9) {
		return undefined;
	}

	const result = {
		payment_day: parseInt(plan_rows_json[3][1]),
		fixed_price: parseFloat(plan_rows_json[5][1]),
		discount_percent: parseFloat(plan_rows_json[6][1]),
		discount: parseFloat(plan_rows_json[7][1]),
		comment: plan_rows_json[8][1]
	}

	return result;
}


export function PlanRowsToCells(plan_rows_json) {

	const result = [];

	result.push({ c: 1, r: 1, t: 's', v: plan_rows_json.domain });
	result.push({ c: 1, r: 2, t: 's', v: plan_rows_json.seller });
	result.push({ c: 1, r: 3, t: 's', v: plan_rows_json.consumer });
	result.push({ c: 1, r: 4, t: 's', v: plan_rows_json.payment_days });
	result.push({ c: 1, r: 6, t: 's', v: plan_rows_json.price });
	result.push({ c: 1, r: 7, t: 's', v: plan_rows_json.discount_percent });
	result.push({ c: 1, r: 8, t: 's', v: plan_rows_json.discount });
	result.push({ c: 1, r: 9, t: 's', v: plan_rows_json.comment });

	for (let i = 0; i < plan_rows_json.rows.length; i++) {

		const current_row = plan_rows_json.rows[i];
		const row_index = 13 + i;

		result.push({ c: 0, r: row_index, t: 'n', v: iso_date_to_excel_date(getDateFormat(current_row.trade_date)) });

		for (let h = 1; h <= 25; h++) {

			const index = (h < 10) ? `p0${h}` : `p${h}`;
			const value = (current_row[index] !== null && current_row[index] !== undefined) ? current_row[index] : '';

			result.push({ c: h, r: row_index, t: 'n', v: value });
		}
	}

	return result;
}