import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import MonthlyPlanIcon from '@material-ui/icons/DateRangeSharp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import MonthPlanningTable from './subcomponents/MonthPlanningTable';
import TimeSeriesCompare from '../bc/subcomponents/TimeSeriesCompare';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import ComponentEditMonthlyPlanning from './ComponentEditMonthlyPlanning';

import { planningContext } from './planningContext';
import { getDateFormat, getMonthFormat } from '../utils/date_functions';
import { PaymentDetailsFromJson, PlanRowsFromJson } from '../utils/plan_row_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: '100%',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	form_control: {
		marginTop: theme.spacing(8),
		alignItems: 'center'
	},

	container: {
		position: 'relative',
		minHeight: '60px',
		textAlign: 'center',
		verticalAlign: 'middle',
	},

	template_button: {
		position: 'absolute',
		bottom: '0px',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(2),
		display: 'inline-block'
	},

}));


var selected_tab = '';


function MonthlyPlanning(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// import state
	const [imported_wb, setImportedWb] = useState(undefined);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const import_tab_label = React.useRef(null);
	const domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);

	// state for label widths
	const [import_tab_label_width, setImportTabLabelWidth] = useState(0);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);

	// table variables
	const [selected_file_rows, setSelectedFileRows] = useState([]);
	const [import_rows_selected, setImportRowsSelected] = useState([]);
	const [rows_details, setRowsDetails] = useState([]);

	// import context
	const { global_state } = useContext(planningContext);


	function getTabsProps(index) {
		return {
			id: `month-planning-tab-${index}`,
			'aria-controls': `month-planning-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`month-planning-tabpanel-${index}`}
				aria-labelledby={`month-planning-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	function createInitialState() {

		var today = new Date();
		today.setHours(0, 0, 0, 0);

		const complex_state = {

			period: getMonthFormat(today),
			domain_id: 0,
			consumer_company_id: 0,
			consumer_name: '',
			seller_company_id: 0,
			seller_name: '',

			group_payment_days: 0,
			group_price: 0,
			group_discount_percent: 0,
			group_discount: 0,
			group_comment: '',

			file_plan_rows: [],
			db_plan_rows: [],

			update_flag: false,
			in_progres: false
		}

		return complex_state;
	}


	function handleImportFileChange(event) {

		const event_value = event.target.files[0];

		// declare reader
		const reader = new FileReader();

		// declare onload function
		reader.onload = function () {

			try {

				const file_content = reader.result;
				const wb = XLSX.read(file_content, { type: 'binary' });
				console.log(wb);
				setImportedWb(wb);

			} catch (e) {

				window.alert('Виникла помилка при імпорті з данного файлу.');
				console.log(e);
			}
		}

		// read
		reader.readAsBinaryString(event_value);
	}


	function handleImportTabChange(event) {
		selected_tab = event.target.value;

		if (selected_tab !== '') {

			try {

				const json_doc = XLSX.utils.sheet_to_json(imported_wb.Sheets[selected_tab], { header: 1 });

				const file_plan_rows = PlanRowsFromJson(json_doc);
				const payment_details = PaymentDetailsFromJson(json_doc);

				let domain_id;
				switch (file_plan_rows[0].domain) {
					case 'ОЕС':
						domain_id = 1;
						break;
					case 'БуОс':
						domain_id = 2;
						break;
					default:
						domain_id = 0;
						break;
				}

				let consumer_company = global_state.companies.find(company => company.eic_x_code.localeCompare(file_plan_rows[0].consumer) === 0);
				let consumer_company_id = (consumer_company !== undefined) ? consumer_company.company_id : 0;
				let consumer_name = (consumer_company !== undefined) ? consumer_company.name : 'не знайено';

				let seller_company = global_state.companies.find(company => company.eic_x_code.localeCompare(file_plan_rows[0].seller) === 0);
				let seller_company_id = (seller_company !== undefined) ? seller_company.company_id : 0;
				let seller_name = (seller_company !== undefined) ? seller_company.name : 'не знайдено';

				var imported_plan_rows = [];
				file_plan_rows.forEach((item, index) => {

					imported_plan_rows.push({
						id: index + 1,
						domain_id: domain_id,
						trade_date: item.trade_date,
						consumer_company_id: consumer_company_id,
						seller_company_id: seller_company_id,
						points: item.points,
					});
				})

				setLocalState(prev_state => ({ ...prev_state,
					period: getMonthFormat(file_plan_rows[0].trade_date),
					domain_id: domain_id,
					consumer_company_id: consumer_company_id,
					consumer_name: consumer_name,
					seller_company_id: seller_company_id,
					seller_name: seller_name,

					group_payment_days: isNaN(payment_details.payment_day) ? '' : payment_details.payment_day,
					group_price: isNaN(payment_details.fixed_price) ? '' : payment_details.fixed_price,
					group_discount_percent: isNaN(payment_details.discount_percent) ? '' : payment_details.discount_percent,
					group_discount: isNaN(payment_details.discount) ? '' : payment_details.discount,
					group_comment: payment_details.comment,

					file_plan_rows: imported_plan_rows,
				}));

				if (consumer_company_id === 0 || seller_company_id === 0 || domain_id === 0) {
					window.alert('Виникла помилка при імпорті з данного файлу.');
				}

			} catch (e) {

				window.alert('Виникла помилка при імпорті з данного файлу.');
				console.log(e);
			}
		}
	}


	async function savePlanRow(group_id, id_source, id_target) {

		const source = local_state.file_plan_rows.find(row => row.id === id_source);
		if (source !== undefined) {

			try {

				const new_data = {
					group_id: group_id,
					row_name: '',
					consumer_company_id: source.consumer_company_id,
					seller_company_id: source.seller_company_id,
					domain_id: source.domain_id,
					trade_date: getDateFormat(source.trade_date),
					p01: (source.points.length > 0 && source.points[0]) ? source.points[0].value : 0,
					p02: (source.points.length > 1 && source.points[1]) ? source.points[1].value : 0,
					p03: (source.points.length > 2 && source.points[2]) ? source.points[2].value : 0,
					p04: (source.points.length > 3 && source.points[3]) ? source.points[3].value : 0,
					p05: (source.points.length > 4 && source.points[4]) ? source.points[4].value : 0,
					p06: (source.points.length > 5 && source.points[5]) ? source.points[5].value : 0,
					p07: (source.points.length > 6 && source.points[6]) ? source.points[6].value : 0,
					p08: (source.points.length > 7 && source.points[7]) ? source.points[7].value : 0,
					p09: (source.points.length > 8 && source.points[8]) ? source.points[8].value : 0,
					p10: (source.points.length > 9 && source.points[9]) ? source.points[9].value : 0,
					p11: (source.points.length > 10 && source.points[10]) ? source.points[10].value : 0,
					p12: (source.points.length > 11 && source.points[11]) ? source.points[11].value : 0,
					p13: (source.points.length > 12 && source.points[12]) ? source.points[12].value : 0,
					p14: (source.points.length > 13 && source.points[13]) ? source.points[13].value : 0,
					p15: (source.points.length > 14 && source.points[14]) ? source.points[14].value : 0,
					p16: (source.points.length > 15 && source.points[15]) ? source.points[15].value : 0,
					p17: (source.points.length > 16 && source.points[16]) ? source.points[16].value : 0,
					p18: (source.points.length > 17 && source.points[17]) ? source.points[17].value : 0,
					p19: (source.points.length > 18 && source.points[18]) ? source.points[18].value : 0,
					p20: (source.points.length > 19 && source.points[19]) ? source.points[19].value : 0,
					p21: (source.points.length > 20 && source.points[20]) ? source.points[20].value : 0,
					p22: (source.points.length > 21 && source.points[21]) ? source.points[21].value : 0,
					p23: (source.points.length > 22 && source.points[22]) ? source.points[22].value : 0,
					p24: (source.points.length > 23 && source.points[23]) ? source.points[23].value : null,
					p25: (source.points.length > 24 && source.points[24]) ? source.points[24].value : null
				}

				const replace_url = (id_target > 0) ? '/api/planning/plan/' + id_target : '/api/planning/plan/add';
				await axios.post(replace_url, new_data);

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({
						...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	async function handleDeleteRowFromCompare(event) {

		if (rows_details.includes(event)) {

			try {

				const response = await axios.post('/api/planning/plan/' + event + '/delete');
				if (response.status === 200) {

					setLocalState(prev_state => ({
						...prev_state,
						update_flag: !prev_state.update_flag,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({
						...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	async function handleImportPlanRows(event) {

		if (local_state.consumer_company_id === 0 || local_state.seller_company_id === 0 || local_state.domain_id === 0) {
			window.alert('При імпорті з файлу виникла помилка. Необхідно перевірити правільність даних та провести імпорт даних ще раз.');
			return;
		}

		if (import_rows_selected.length > 0) {

			// create plan group and get group id
			if (local_state.group_price !== 0 || local_state.group_discount !== 0 || local_state.group_discount_percent !== 0) {

				setLocalState(prev_state => ({ ...prev_state,
					in_progres: true,
				}));

				try {

					const new_data = {
						payment_days: (local_state.group_payment_days === '') ? undefined : local_state.group_payment_days,
						price: (local_state.group_price === '') ? undefined : local_state.group_price,
						discount_percent: (local_state.group_discount_percent === '') ? undefined : local_state.group_discount_percent,
						discount: (local_state.group_discount === '') ? undefined : local_state.group_discount,
						comment: local_state.group_comment
					}

					const result_add_group = await axios.post('/api/planning/group/add', new_data);
					const group_id = result_add_group.data.id;

					for (let i = 0; i < import_rows_selected.length; i++) {
						await savePlanRow(group_id, import_rows_selected[i][0], 0);
					}

					alert('Імпорт рядів завершено.');

				} catch (error) {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				}

			} else {

				alert('Перевірте правільність імпорту фінансових даних.');
				return 0;
			}

			setLocalState(prev_state => ({ ...prev_state,
				in_progres: false,
				update_flag: !prev_state.update_flag,
			}));
		}
	}


	async function handleReplacePlanRows(event) {

		if (local_state.consumer_company_id === 0 || local_state.seller_company_id === 0 || local_state.domain_id === 0) {
			window.alert('При імпорті з файлу виникла помилка. Необхідно перевірити правільність даних та провести імпорт даних ще раз.');
			return;
		}

		// update plan group
		if (local_state.group_payment_days !== 0 &&
			(local_state.group_price !== 0 || local_state.group_discount !== 0 || local_state.group_discount_percent !== 0)) {

			try {

				const group_ids = [];

				local_state.db_plan_rows.forEach(row => {

					if (!group_ids.includes(row.group_id)) {
						group_ids.push(row.group_id);
					}
				})

				if (group_ids.length !== 1) {
					window.alert('Не можливо знайти групу для оновлення. Переписати ряди не можливо.');
					return 0;
				}

				setLocalState(prev_state => ({ ...prev_state,
					in_progres: true,
				}));

				const new_data = {
					payment_days: (local_state.group_payment_days === '') ? undefined : local_state.group_payment_days,
					price: (local_state.group_price === '') ? undefined : local_state.group_price,
					discount_percent: (local_state.group_discount_percent === '') ? undefined : local_state.group_discount_percent,
					discount: (local_state.group_discount === '') ? undefined : local_state.group_discount,
					comment: local_state.group_comment
				}

				await axios.post('/api/planning/group/' + group_ids[0], new_data);

				for (let i = 0; i < import_rows_selected.length; i++) {
					await savePlanRow(group_ids[0], import_rows_selected[i][0], import_rows_selected[i][1]);
				}

				alert('Ряди було перезаписано.');

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}

		} else {

			alert('Перевірте правільність імпорту фінансових даних.');
			return 0;
		}

		setLocalState(prev_state => ({ ...prev_state,
			update_flag: !prev_state.update_flag,
			in_progres: false,
		}));
	}


	async function handleTemplateDownload() {

		try {

			const temp_xlsx_doc = await axios.get('/docs/templates/Month_Planning.xlsx', { responseType: 'arraybuffer' });
			const blob = new Blob([temp_xlsx_doc.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, 'Month_Planning_Import_Template.xlsx');

		} catch (error) {

			console.log(error);
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (import_tab_label.current) {
			setImportTabLabelWidth(import_tab_label.current.offsetWidth);
		}
		if (domain_label.current) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
		}
		if (consumer_label.current) {
			setConsumerLabelWidth(consumer_label.current.offsetWidth);
		}
		if (seller_label.current) {
			setSellerLabelWidth(seller_label.current.offsetWidth);
		}

	}, [tab_no])


	useEffect(() => {

		async function fetchData(domain, consumer, seller, period_start, period_end) {

			try {

				const response_plan_rows = await axios.get('/api/planning/plans', { params: {
					domain: domain,
					consumer_company_id: consumer,
					seller_company_id: seller,
					period_start: getDateFormat(period_start),
					period_end: getDateFormat(period_end)
				}});

				const read_rows = response_plan_rows.data.plan_row_list;

				setLocalState(prev_state => ({
					...prev_state,
					db_plan_rows: read_rows,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		const period_start = new Date(local_state.period);
		period_start.setDate(1);
		const period_end = new Date(period_start.getFullYear(), period_start.getMonth() + 1, 1);

		if (local_state.domain_id > 0) {
			fetchData(local_state.domain_id, local_state.consumer_company_id, local_state.seller_company_id, period_start, period_end);
		}

	}, [local_state.period, local_state.domain_id, local_state.consumer_company_id, local_state.seller_company_id, local_state.update_flag])


	var display_domains = [];
	var display_companies = [];
	var display_file_rows = [];
	var display_db_rows = [];
	var ts_compare = [];

	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => <option key={ company.company_id } value= { company.company_id } >{ company.name }</option>);
		display_companies.unshift(<option key={-1} value={''} ></option>);
	}

	if (local_state.file_plan_rows.length > 0) {

		local_state.file_plan_rows.forEach(item => {
			display_file_rows.push({
				name: item.id,
				date: new Date(item.trade_date),
				...item
			})
		})

		if (rows_details.length > 0 && rows_details[0] > 0) {

			const found_ts = display_file_rows.find(item => item.id === rows_details[0]);
			ts_compare.push({
				consumer: local_state.consumer_name,
				seller: local_state.seller_name,
				...found_ts,
			})
		}
	}

	if (local_state.db_plan_rows.length > 0) {

		local_state.db_plan_rows.forEach(item => {

			const points = [];
			for (let i = 1; i <= 25; i++) {
				const index = (i < 10) ? `p0${i}` : `p${i}`;
				if (item[index] !== null) {
					points.push({
						id: i,
						value: parseFloat(item[index])
					})
				}
			}

			display_db_rows.push({
				id: item.plan_row_id,
				name: item.plan_row_id,
				date: new Date(item.trade_date),
				points: points,
				...item
			})
		})

		if (rows_details.length > 1) {

			for (let i = 1; i < rows_details.length; i++) {

				if (rows_details[i] > 0) {

					const found_ts = display_db_rows.find(item => item.id === rows_details[i]);
					if (found_ts !== undefined) {
						ts_compare.push({
							consumer: local_state.consumer_name,
							seller: local_state.seller_name,
							...found_ts,
						});
					}
				}
			}
		}
	}

	var display_import_tabs = [];
	if (imported_wb !== undefined) {

		display_import_tabs = imported_wb.SheetNames.map(name => {
			return (
				<option key={name}>{name}</option>
			)
		})

		display_import_tabs.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<Container className={classes.root_form} component='main'>

			<Avatar className={classes.avatar}>
				<MonthlyPlanIcon />
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				Місячне планування
			</Typography>

			<Tabs
				value={tab_no}
				onChange={OnTabChage}
				className={classes.tab}
				indicatorColor='primary'
				textColor='primary'
			>
				<Tab label='Редагування' {...getTabsProps(0)} />
				<Tab label='Імпорт' {...getTabsProps(1)} />
			</Tabs>

			<TabPanel value={tab_no} index={0}>

				<ComponentEditMonthlyPlanning />

			</TabPanel>

			<TabPanel value={tab_no} index={1}>

				<Grid container spacing={2}>

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							required
							variant='outlined'
							margin='normal'
							fullWidth
							type='file'
							label='Файл імпорту місячного плану'
							inputProps={{ accept: '.xlsx' }}
							onChange={handleImportFileChange}
						/>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={import_tab_label} id='import-tab-label'>Вкладка файлу імпорту</InputLabel>
							<Select
								native
								labelId='import-tab-label'
								labelWidth={import_tab_label_width}
								onChange={handleImportTabChange}
							>
								{display_import_tabs}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6}>

						<div className={classes.container}>

							<Button
								className={classes.template_button}
								variant='contained'
								color='primary'
								onClick={handleTemplateDownload}
							>
								Зразок файлу імпорту
							</Button>

						</div>

					</Grid>

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Місяць планування'
							type='month'
							value={local_state.period}
							disabled={local_state.period !== ''}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={domain_label} id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={domain_label_width}
								value={local_state.domain_id}
							>
								{display_domains}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={3}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={consumer_label} id='consumer-label'>Покупець</InputLabel>
							<Select
								native
								labelId='consumer-label'
								labelWidth={consumer_label_width}
								value={local_state.consumer_company_id}
							>
								{display_companies}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={3}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={seller_label} id='seller-label'>Продавець</InputLabel>
							<Select
								native
								labelId='seller-label'
								labelWidth={seller_label_width}
								value={local_state.seller_company_id}
							>
								{display_companies}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Період сплати'
							value={local_state.group_payment_days}
							disabled={local_state.group_payment_days !== 0}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Фіксована ціна'
							value={local_state.group_price}
							disabled={local_state.group_price !== 0}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Дісконт, %'
							value={local_state.group_discount_percent}
							disabled={local_state.group_discount_percent !== 0}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Дісконт, грн'
							value={local_state.group_discount}
							disabled={local_state.group_discount !== 0}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={6}>

						<TextField
							InputLabelProps={{ shrink: true }}
							variant='outlined'
							required
							fullWidth
							label='Коментар'
							value={local_state.group_comment}
							disabled={local_state.group_comment !== ''}
						/>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={12}>
						<Typography component='h3' variant='body1' align='center'>
							Планові ряди
						</Typography>
					</Grid>

					<Grid item xs={12}>

						<MonthPlanningTable
							date={local_state.period}
							rows_source={display_file_rows}
							rows_target={display_db_rows}
							selected={selected_file_rows}
							setSelected={setSelectedFileRows}
							setImportIds={setImportRowsSelected}
							setDetails={setRowsDetails}
						/>

					</Grid>

					<Grid item xs={12}>

						{(ts_compare.length > 0) &&
							<TimeSeriesCompare
								rows={ts_compare}
								onDelete={handleDeleteRowFromCompare}
							/>}

					</Grid>

				</Grid>

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={classes.button_control}
						onClick={handleImportPlanRows}
						disabled={import_rows_selected.length === 0}
					>
						Імпорт виділених рядів
					</Button>

					<Button
						variant='contained'
						color='primary'
						className={classes.button_control}
						onClick={handleReplacePlanRows}
						disabled={import_rows_selected.length === 0}
					>
						Переписати виділені ряди
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={classes.button_control}
						onClick={() => props.history.go(-1)}
					>
						Скасувати
					</Button>

				</div>

			</TabPanel>

			<WaitDialog
				open={local_state.in_progres}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</Container>
	)
}


export default withRouter(MonthlyPlanning);