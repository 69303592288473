import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { is_valid_float_input } from '../../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	container: {
		
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
	},

	cells: {
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '65px',
	}

}));


function ProductionPlanEdit(props) {
	const classes = useStyles();

	const { columns_count, hours, setHours } = props;


	const initial_state = {
		index: -1,
		row: '',
	};
	const [local_state, setLocalState] = useState(initial_state);


	function onMouseClickEvent (event) {
		const event_target = event.target;

		if (event_target.id) {
			const ids = event_target.id.split('_');
			const index = parseInt(ids[0]);

			setLocalState(prev_state => ({...prev_state,
				index: index,
				row: ids[1],
			}));
		}
	}


	function onValueChange (event) {
		const event_value = event.target.value;

		const new_hours = [ ...hours ];
		const edited_item = new_hours.find(item => item.key === local_state.index);

		if (edited_item) {

			switch (local_state.row) {
				case 'min':
					edited_item.min = event_value;
					edited_item.min_valid = is_valid_float_input(event_value);
					break;
				case 'value':
					edited_item.value = event_value;
					edited_item.value_valid = is_valid_float_input(event_value);
					break;
				case 'max':
					edited_item.max = event_value;
					edited_item.max_valid = is_valid_float_input(event_value);
					break;
				default: 
					break;
			}

			setHours(new_hours);
		}
	}


	function onValuePaste (event) {

		const event_target = event.target;

		const string_to_paste = event.clipboardData.getData('text/plain');
		const array_to_paste = string_to_paste.replace(/,/g, '.').replace(/\s+/g, ';').split(';');

		// Since the paste operation get canceled, we need to manually
		// paste the data into the document.
		const new_hours = [ ...hours ];

		// iterate through buffer array
		for (let i = 0; i < array_to_paste.length; i++) {

			if (array_to_paste[i]) {

				let ids = event_target.id.split('_');
				let hour_id = parseInt(ids[0]) + i - 1;

				let new_value = parseFloat(array_to_paste[i]);
				if (new_value) {
					if (new_hours[hour_id.toString()]) {
						switch (ids[1]) {
							case 'min':
								new_hours[hour_id.toString()].min = new_value;
								break;
							case 'value':
								new_hours[hour_id.toString()].value = new_value;
								break;
							case 'max':
								new_hours[hour_id.toString()].max = new_value;
								break;
							default:
								break;
						}
					}
				}
			}
		}

		setHours(new_hours);

		event.preventDefault();
	}


	function onKeyPress(event) {

		const event_target = event.target;

		if (event.charCode === 13) {

			// prevent defualt action
			event.preventDefault();

			// for elements with identified id
			if (event_target.id) {

				const ids = event_target.id.split('_');

				const pressed_id = parseInt(ids[0]);
				const pressed_value = event_target.value;
				const new_hours = [ ...hours ];

				for (let i = 0; i < new_points.length; i++) {

					if (i > pressed_id - 1) {

						switch (ids[1]) {
							case 'min':
								new_hours[i].min = pressed_value;
								break;
							case 'value':
								new_hours[i].value = pressed_value;
								break;
							case 'max':
								new_hours[i].max = pressed_value;
								break;
							default:
								break;
						}
						
					}
				}

				setHours(new_hours);
			}
		}
	}


	const head_cells = [];
	for (let h = 1; h <= columns_count; h++) {
		head_cells[h] = {
			id: h,
			label: h.toString(),
		}
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer className={classes.container}>
				<Table size= {'small'} className={classes.table} >
					<TableHead>
						<TableRow>
							<TableCell
								key={ `head_cell_name` }
							/>
							{
								head_cells.map(cell => (
									<TableCell
										key={ `${cell.id}_head` }
										className={ classes.head }
									>
										{ cell.label }
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>

						<TableRow
							key={ `min_row` }
						>
							<TableCell
								key={ `min_row_name` }
								className={ classes.head }
							>
								Мінімум
							</TableCell>
							{
								hours.map((item, cell_index) => (
									<TableCell
										key={ `${item.id}_min` }
										id={ `${item.id}_min` }
										className={ classes.cells }
										onClick={ onMouseClickEvent }
									>
										{
											(cell_index === local_state.index - 1) && (local_state.row === 'min')
												? 
													<TextField
														size='small'
														id={ `${item.id}_min_tf` }
														error={ !item.min_valid }
														onChange={ onValueChange }
														onPaste={ onValuePaste }
														onKeyPress={ onKeyPress }
														value={ item.min }
													/>
												: item.min
										}
									</TableCell>
								))
							}
						</TableRow>

						<TableRow
							key={ `value_row` }
						>
							<TableCell
								key={ `value_row_name` }
								className={ classes.head }
							>
								Значення
							</TableCell>
							{
								hours.map((item, cell_index) => (
									<TableCell
										key={ `${item.id}_value` }
										id={ `${item.id}_value` }
										className={ classes.cells }
										onClick={ onMouseClickEvent }
									>
										{
											(cell_index === local_state.index - 1) && (local_state.row === 'value')
												? 
													<TextField
														size='small'
														id={ `${item.id}_value_tf` }
														error={ !item.value_valid }
														onChange={ onValueChange }
														onPaste={ onValuePaste }
														onKeyPress={ onKeyPress }
														value={ item.value }
													/>
												: item.value
										}
									</TableCell>
								))
							}
						</TableRow>

						<TableRow
							key={ `max_row` }
						>
							<TableCell
								key={ `max_row_name` }
								className={ classes.head }
							>
								Максімум
							</TableCell>
							{
								hours.map((item, cell_index) => (
									<TableCell
										key={ `${item.id}_max` }
										id={ `${item.id}_max` }
										className={ classes.cells }
										onClick={ onMouseClickEvent }
									>
										{
											(cell_index === local_state.index - 1) && (local_state.row === 'max')
												? 
													<TextField
														size='small'
														id={ `${item.id}_max_tf` }
														error={ !item.max_valid }
														onChange={ onValueChange }
														onPaste={ onValuePaste }
														onKeyPress={ onKeyPress }
														value={ item.max }
													/>
												: item.max
										}
									</TableCell>
								))
							}
						</TableRow>

					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default ProductionPlanEdit;