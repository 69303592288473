
import { excel_date_to_date } from './excel_functions';
import { getDateFromLocalDateString, getDateTimeFromLocalDateTimeString, getDateTimeFormat, getDateFormat } from './date_functions';


export function IdmOrderFromJson(idm_json) {

	// check array
	if (idm_json.length === 0) {
		return undefined;
	}

	// array of rows
	let result_orders = [];
	let order_trade_date = 0;
	let potential_order_trade_date = undefined;

	for (let i = 0; i < idm_json.length; i++) {

		if (order_trade_date === 0) {
			order_trade_date = idm_json[i][0];
		} else {
			if (order_trade_date !== idm_json[i][0]) {
				order_trade_date = undefined;
			}
		}

		if (typeof order_trade_date === 'string') {
			potential_order_trade_date = getDateFromLocalDateString(order_trade_date);
		} else {
			potential_order_trade_date = excel_date_to_date(order_trade_date);
		}

		let potential_validity_date;
		if (typeof idm_json[i][10] === 'string') {
			potential_validity_date = getDateTimeFromLocalDateTimeString(idm_json[i][10]);
		} else {
			potential_validity_date = excel_date_to_date(idm_json[i][10]);
		}

		const try_price = parseFloat(idm_json[i][7]);
		const try_float = parseFloat(idm_json[i][8]);

		if (potential_order_trade_date !== undefined) {

			result_orders.push({
				trade_date: getDateFormat(potential_order_trade_date),
				period_from: idm_json[i][1],
				period_to: idm_json[i][2],
				block_order: idm_json[i][3],
				block_type: idm_json[i][4],
				trade_type: idm_json[i][5],
				indication: idm_json[i][6],
				price: isNaN(try_price) ? 0 : try_price,
				amount: isNaN(try_float) ? 0 : try_float,
				trade_stage: idm_json[i][9],
				validity_date: getDateTimeFormat(potential_validity_date),
				comment: idm_json[i][11]
			});
		}
	}

	return result_orders;
}


export function IdmOrderFromXml(xml_order) {

	// check document type
	const root_nodes = xml_order.getElementsByTagName('ISOTEDATA');
	if (root_nodes.length !== 1) {
		console.log(root_nodes);
		return undefined;
	}

	const order_rows = [];

	// get trade nodes
	const trade_nodes = root_nodes[0].getElementsByTagName('Trade');
	for (let i = 0; i < trade_nodes.length; i++) {

		const party_node = trade_nodes[i].getElementsByTagName('Party')[0];

		// get profile nodes
		const profile_nodes = trade_nodes[i].getElementsByTagName('ProfileData');
		for (let j = 0; j < profile_nodes.length; j++) {

			// block index
			const index = profile_nodes[j].attributes['profile-role'].value;
			const is_price_block = index.includes('BP');
			const is_amount_block = index.includes('BC');

			// rows
			const data_nodes = profile_nodes[j].getElementsByTagName('Data');
			for (let k = 0; k < data_nodes.length; k++) {

				const period_from = parseInt(data_nodes[k].attributes['period-from'].value);
				const period_to = parseInt(data_nodes[k].attributes['period-to'].value);
				const value = parseFloat(data_nodes[k].attributes['value'].value);

				// try to find uncomplete existing row
				const existing_row = order_rows.find(item => item.period_from === period_from && item.period_to === period_to);
				if (existing_row !== undefined) {
					if (is_price_block) {
						existing_row.price = parseFloat(value.toFixed(2));
					}
					if (is_amount_block) {
						existing_row.amount = parseFloat(value.toFixed(1));
					}
				} else {

					const validity_date = trade_nodes[i].attributes['order-expiration'];
					const block_order = trade_nodes[i].attributes['block-order'].value;
					const block_type = trade_nodes[i].attributes['block-type'];
					const indication = trade_nodes[i].attributes['indication'].value;
					const trade_type = trade_nodes[i].attributes['trade-type'].value;
					const trade_stage = trade_nodes[i].attributes['trade-stage'];
					const comment = party_node.attributes['comment'];

					order_rows.push({
						period_from: period_from,
						period_to: period_to,
						validity_date: (validity_date !== undefined) ? validity_date.value : '',
						block_order: block_order,
						block_type: (block_type !== undefined) ? block_type.value : undefined,
						indication: indication,
						trade_type: trade_type,
						trade_stage: (trade_stage !== undefined) ? trade_stage.value : undefined,
						price: is_price_block ? value : undefined,
						amount: is_amount_block ? value : undefined,
						comment: (comment !== undefined) ? comment.value : '',
					})
				}
			}
		}
	}

	const order = {
		id: root_nodes[0].attributes['id'].value,
		sender: root_nodes[0].getElementsByTagName('SenderIdentification')[0].attributes['id'].value,
		receiver: root_nodes[0].getElementsByTagName('ReceiverIdentification')[0].attributes['id'].value,
		trade_date: trade_nodes[0].attributes['trade-day'].value,
		domain: trade_nodes[0].attributes['market-area'].value,
		rows: order_rows,
	}

	return order;
}


export function IdmOrderToXml(xml_order, export_order) {

	// check document type
	let root_nodes = xml_order.getElementsByTagName('ISOTEDATA');
	if (root_nodes.length !== 1) {
		console.log(root_nodes);
		return undefined;
	}

	root_nodes[0].attributes['id'].value = export_order.order_id;
	root_nodes[0].attributes['date-time'].value = export_order.created_date;

	root_nodes[0].getElementsByTagName('SenderIdentification')[0].attributes['id'].value = export_order.sender;
	root_nodes[0].getElementsByTagName('ReceiverIdentification')[0].attributes['id'].value = export_order.receiver;

	const trade_node = root_nodes[0].getElementsByTagName('Trade')[0];

	trade_node.getElementsByTagName('Party')[0].attributes['id'].value = export_order.sender;
	trade_node.attributes['trade-day'].value = export_order.trade_date;
	trade_node.attributes['market-area'].value = export_order.domain;

	if (export_order.validity_date !== '') {
		trade_node.attributes['order-expiration'].value = export_order.validity_date;
	} else {
		trade_node.removeAttribute('order-expiration');
	}
	trade_node.attributes['trade-type'].value = export_order.trade_type;
	trade_node.attributes['block-order'].value = export_order.block_order;
	trade_node.attributes['indication'].value = export_order.indication;
	trade_node.attributes['trade-stage'].value = export_order.trade_stage;

	if (export_order.block_type) {
		trade_node.attributes['block-type'].value = export_order.block_type;
	} else {
		trade_node.removeAttribute('block-type');
	}

	const profile_nodes = trade_node.getElementsByTagName('ProfileData');
	let price_data_node, amount_data_node;

	for (let i = 0; i < profile_nodes.length; i++) {
		if (profile_nodes[i].attributes['profile-role'].value.localeCompare('BC01') === 0) {
			amount_data_node = profile_nodes[i].getElementsByTagName('Data')[0];
		}
		if (profile_nodes[i].attributes['profile-role'].value.localeCompare('BP01') === 0) {
			price_data_node = profile_nodes[i].getElementsByTagName('Data')[0];
		}
	}

	amount_data_node.attributes['period-from'].value = export_order.period_from;
	amount_data_node.attributes['period-to'].value = export_order.period_to;
	amount_data_node.attributes['value'].value = export_order.amount.toFixed(1);

	price_data_node.attributes['period-from'].value = export_order.period_from;
	price_data_node.attributes['period-to'].value = export_order.period_to;
	price_data_node.attributes['value'].value = export_order.price.toFixed(2);

	root_nodes[0].normalize();
	return xml_order;
}
