import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import PeriodCapacityTable from './subcomponents/PeriodCapacityTable';

import { globalContext } from '../layout/Context';
import { capacityContext } from './capacityContext';

import { is_valid_date } from '../utils/validation';
import { getDateFormat, getLocalDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	main_form: {
		marginTop: theme.spacing(2)
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		textAlign: 'center',
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

}));


function ComponentCapacityExportForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const type_label = React.useRef(null);
	const company_label = React.useRef(null);
	const out_domain_label = React.useRef(null);
	const in_domain_label = React.useRef(null);
	// state for label widths
	const [type_label_width, setTypeLabelWidth] = useState(0);
	const [company_label_width, setCompanyLabelWidth] = useState(0);
	const [out_domain_label_width, setOutDomainLabelWidth] = useState(0);
	const [in_domain_label_width, setInDomainLabelWidth] = useState(0);
	// table variables
	const [capacities_selected, setCapacitiesSelected] = React.useState([]);

	// import context
	const { global_state, list_capacities } = useContext(capacityContext);
	const { user } = useContext(globalContext);


	function createInitialState() {

		const day_1 = new Date();
		day_1.setHours(-24 * 7, 0, 0, 0);

		const day_2 = new Date();

		const complex_state = {
			company_id: 0,
			out_domain_id: 0,
			in_domain_id: 0,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),

			capacities: [],
			capacity_limits: []
		}

		return complex_state;
	}


	function handleTypeFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			type_id: parseInt(event_value),
		}));
	}


	function handleOutDomainFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			out_domain_id: parseInt(event_value),
		}));
	}


	function handleInDomainFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			in_domain_id: parseInt(event_value),
		}));
	}


	function handleCompanyFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			company_id: parseInt(event_value),
		}));
	}


	function handlePeriodStartFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			period_start: event_value,
		}));
	}


	function handlePeriodEndFilterChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			period_end: event_value,
		}));
	}


	async function onExportHandler() {

		if (capacities_selected.length === 0) {
			alert('Вибіріть результати аукціону для експорту.');
			return;
		}

		const output = [];
		output[0] = [];
		output[0][0] = 'Компанія';
		output[0][1] = 'Торгова зона експорту';
		output[0][2] = 'Торгова зона імпорту';
		output[0][3] = 'Дата';
		output[0][4] = 'Година';
		output[0][5] = 'Тип аукціону';
		output[0][6] = 'ОС, МВт';
		output[0][7] = 'РПС, МВт';
		output[0][8] = 'Ціна, грн/МВтг';
		output[0][9] = 'Обмеження, МВт';

		let row_nr = 1;

		for (let i = 0; i < local_state.capacities.length; i++) {

			const this_row = local_state.capacities[i];

			if (!capacities_selected.includes(this_row.capacity_id)) {
				continue;
			}

			let auction_type = '';
			switch (this_row.type) {
				case 0:
					auction_type = 'Добовий';
					break;
				case 1:
					auction_type = 'Місячний';
					break;
				case 2:
					auction_type = 'Річний';
					break;
				default:
					break;
			}

			const company = global_state.companies.find(item => item.company_id === this_row.company_id);
			const out_domain = global_state.domains.find(item => item.domain_id === this_row.out_domain_id);
			const in_domain = global_state.domains.find(item => item.domain_id === this_row.in_domain_id);

			const date_1 = new Date(this_row.period_start);
			const date_2 = new Date(local_state.period_start);
			const date_3 = new Date(this_row.period_end);
			const date_4 = new Date(local_state.period_end);

			const current_date = (date_1.getTime() > date_2.getTime()) ? date_1 : date_2;
			const end_date = (date_3.getTime() < date_4.getTime()) ? date_3 : date_4;

			while (current_date.getTime() <= end_date.getTime()) {

				const this_date = new Date(current_date);

				const lims = local_state.capacity_limits.filter(row => {
					const row_date = new Date(row.limit_date);
	
					return (
						this_row.capacity_id === row.capacity_id &&
						row_date.getFullYear() === this_date.getFullYear() &&
						row_date.getMonth() === this_date.getMonth() &&
						row_date.getDate() === this_date.getDate()
					)
				});

				for (let j = 1; j <= getHoursInDate(current_date); j++) {

					const index = (this_row.type > 0) ? 'p01' : (j < 10) ? `p0${j}` : `p${j}`;
					const lim_index = (j < 10) ? `p0${j}` : `p${j}`;

					output[row_nr] = [];
					output[row_nr][0] = (company !== undefined) ? company.name : '';
					output[row_nr][1] = (out_domain !== undefined) ? out_domain.domain_name : '';
					output[row_nr][2] = (in_domain !== undefined) ? in_domain.domain_name : '';

					output[row_nr][3] = getLocalDateString(current_date);
					output[row_nr][4] = j;
					output[row_nr][5] = auction_type;
					output[row_nr][6] = this_row[`${index}_os`];
					output[row_nr][7] = this_row[`${index}_rs`];
					output[row_nr][8] = this_row[`${index}_pr`];

					output[row_nr][9] = (lims.length > 0) ? lims[0][lim_index] : undefined;

					row_nr++;
				}

				current_date.setHours(24, 0, 0, 0);
			}
		}

		const file_name = `Output_${getDateFormat(local_state.period_start)}_${getDateFormat(local_state.period_end)}.xlsx`

		// create sheet from json
		const output_sheet = XLSX.utils.json_to_sheet(output, { skipHeader: true });

		// create new workbook
		var output_wb = XLSX.utils.book_new();

		// insert sheet into workbook
		XLSX.utils.book_append_sheet(output_wb, output_sheet, 'output');

		// save workbook to file
		XLSX.writeFile(output_wb, file_name);
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (type_label.current) {
			setTypeLabelWidth(type_label.current.offsetWidth);
		}
		if (company_label.current) {
			setCompanyLabelWidth(company_label.current.offsetWidth);
		}
		if (out_domain_label.current) {
			setOutDomainLabelWidth(out_domain_label.current.offsetWidth);
		}
		if (in_domain_label.current) {
			setInDomainLabelWidth(in_domain_label.current.offsetWidth);
		}

	}, [])


	useEffect(() => {

		if (user) {
			setLocalState(prev_state => ({
				...prev_state,
				company_id: user.company_id,
			}));
		}

	}, [user])


	useEffect(() => {

		async function fetchData() {

			try {

				const response = await axios.get('/api/capacity', { params: {
					company_id: local_state.company_id,
					out_domain: local_state.out_domain_id,
					in_domain: local_state.in_domain_id,
					period_start: getDateFormat(local_state.period_start),
					period_end: getDateFormat(local_state.period_end)
				}});

				const capacities = response.data.capacities_list;
				var capacity_limits = [];

				for (let i = 0; i < capacities.length; i++) {

					const limit_response = await axios.get('/api/capacity/limits/' + capacities[i].capacity_id);
					capacity_limits.push(...limit_response.data);
				}

				setLocalState(prev_state => ({ ...prev_state,
					capacities: capacities,
					capacity_limits: capacity_limits
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({
						...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (local_state.company_id > 0 && local_state.out_domain_id > 0 && local_state.in_domain_id > 0 &&
			is_valid_date(local_state.period_start) && is_valid_date(local_state.period_end)) {
			fetchData();
		}

	}, [local_state.company_id, local_state.out_domain_id, local_state.in_domain_id, local_state.period_start, local_state.period_end])


	var display_types = [];
	display_types.push(<option key={-1} value={''}></option>);
	display_types.push(<option key={0} value={0}>Добовий</option>);
	display_types.push(<option key={1} value={1}>Місячний</option>);
	display_types.push(<option key={2} value={2}>Річний</option>);


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => <option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>);
		display_domains.unshift(<option key={-1} value={''} ></option>);
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => (
			<option key={company.company_id} value={company.company_id} >{company.name}</option>
		));
		display_companies.unshift(<option key={-1} value={''} ></option>);
	}

	var display_export_rows = [];
	if (local_state.capacities.length > 0) {
		display_export_rows = local_state.capacities.map(item => list_capacities(item));
	}

	return (
		<div>

			<Typography component='h2' variant='h4' align='center' className={classes.main_form}>
				Експорт результатів аукціону з розподілу пропускної спороможності
			</Typography>

			<Grid container spacing={2} className={classes.main_form}>

				<Grid item xs={3}>

					<FormControl variant='outlined' required className={classes.select_form_control} fullWidth>
						<InputLabel ref={company_label} id='company-label'>Компанія</InputLabel>
						<Select
							native
							labelId='company-label'
							labelWidth={company_label_width}
							value={local_state.company_id}
							onChange={handleCompanyFilterChange}
						>
							{display_companies}
						</Select>
					</FormControl>

				</Grid>

				{/* <Grid item xs={3}>

					<FormControl variant='outlined' className={classes.control_bottom_margin} fullWidth>
						<InputLabel ref={type_label} id='type-label'>Тип аукціону</InputLabel>
						<Select
							native
							labelId='type-label'
							labelWidth={type_label_width}
							value={local_state.type_id}
							onChange={handleTypeFilterChange}
						>
							{display_types}
						</Select>
					</FormControl>

				</Grid> */}

				<Grid item xs={9} />

				<Grid item xs={3}>

					<FormControl variant='outlined' className={classes.control_bottom_margin} fullWidth>
						<InputLabel ref={out_domain_label} id='out-domain-label'>Торгова зона експорту</InputLabel>
						<Select
							native
							labelId='out-domain-label'
							labelWidth={out_domain_label_width}
							onChange={handleOutDomainFilterChange}
							value={local_state.out_domain_id}
						>
							{display_domains}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={3}>

					<FormControl variant='outlined' className={classes.control_bottom_margin} fullWidth>
						<InputLabel ref={in_domain_label} id='in-domain-label'>Торгова зона імпорту</InputLabel>
						<Select
							native
							labelId='in-domain-label'
							labelWidth={in_domain_label_width}
							onChange={handleInDomainFilterChange}
							value={local_state.in_domain_id}
						>
							{display_domains}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						required
						fullWidth
						label='Начало періоду'
						type='date'
						value={local_state.period_start}
						onChange={handlePeriodStartFilterChange}
						InputLabelProps={{ shrink: true }}
					/>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						required
						fullWidth
						label='Кінець періоду'
						type='date'
						value={local_state.period_end}
						onChange={handlePeriodEndFilterChange}
						InputLabelProps={{ shrink: true }}
					/>

				</Grid>

			</Grid>

			<PeriodCapacityTable
				rows={display_export_rows}
				selected={capacities_selected}
				setSelected={setCapacitiesSelected}
			/>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={onExportHandler}
				>
					Експорт
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className={classes.button_control}
					onClick={onCancelHandler}
				>
					Скасувати
				</Button>

			</div>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}


export default withRouter(ComponentCapacityExportForm);