import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { is_valid_company_name, is_valid_address, is_valid_registration_code, is_valid_eic_code } from '../utils/validation';
import { is_valid_contract_number, is_valid_date, is_valid_name, is_valid_email, is_valid_phone } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		border: '2px solid red',
		borderRadius: '10px',
		alignItems: 'center',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function ImportForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	// import states
	const [company_items, setCompanyitems] = useState([]);
	const [contract_items, setContractItems] = useState([]);
	const [user_items, setUseritems] = useState([]);


	function handleImportCompaniesFileChange (event) {

		try {

			const event_value = event.target.files[0];

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				const file_content_string = reader.result;

				const lines = file_content_string.split(/[\r\n]+/g);
				setCompanyitems(lines);
			}

			reader.readAsText(event_value);

		} catch (error) {
			console.log (error);
		}
	}


	function handleImportCotractsFileChange (event) {

		try {

			const event_value = event.target.files[0];

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				const file_content_string = reader.result;

				const lines = file_content_string.split(/[\r\n]+/g);
				setContractItems(lines);
			}

			reader.readAsText(event_value);

		} catch (error) {
			console.log (error);
		}
	}


	function handleImportUsersFileChange (event) {

		try {

			const event_value = event.target.files[0];

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				const file_content_string = reader.result;

				const lines = file_content_string.split(/[\r\n]+/g);
				setUseritems(lines);
			}

			reader.readAsText(event_value);

		} catch (error) {
			console.log (error);
		}
	}


	async function handleCompaniesImport (event) {

		event.preventDefault();

		if (!window.confirm('Ви впевнені, що бажаєте імпортувати компанії?')) {
			return;
		}

		let import_count = 0;

		for(let i = 0; i < company_items.length; i++) {
					
			let items = company_items[i].split(';');

			if (items.length === 4) {

				const company_name = is_valid_company_name(items[0]) ? items[0] : null;
				const registration_code = is_valid_registration_code(items[1]) ? items[1] : null;
				const address = is_valid_address(items[2]) ? items[2] : null;
				const eic_x_code = is_valid_eic_code(items[3]) ? items[3] : null;

				if (company_name !== null && registration_code !== null && address !== null && eic_x_code !== null) {

					try {

						const company_data = {
							name: company_name,
							registration_code: registration_code,
							address: address,
							eic_x_code: eic_x_code
						}

						const response = await axios.post('/api/admin/company/add', company_data);
			
						if (response.status === 200) {
			
							import_count++;
						}
			
					} catch (error) {
			
						if (error.response) {
							// The request was made and the server responded with a status code out of the range of 2xx
							setMessage(message => ({ ...message,
								show: true,
								message: error.response.data,
							}));
						}
			
						console.log(error);
					}


				} else {
					console.log('Cannot parse line: %o', items);
				}
			} else {
				console.log('Wrong line: %o', items);
			}
		}

		console.log('Imported %o companies', import_count);
	}


	async function handleContractsImport (event) {

		event.preventDefault();

		if (!window.confirm('Ви впевнені, що бажаєте імпортувати контракти?')) {
			return;
		}

		let import_count = 0;

		for(let i = 0; i < contract_items.length; i++) {
					
			let items = contract_items[i].split(';');

			if (items.length === 5) {

				const contract_number = is_valid_contract_number(items[0]) ? items[0] : null;
				const contract_date = is_valid_date(items[1]) ? items[1] : null;
				const consumer_id = parseInt(items[2]);
				const seller_id = parseInt(items[3]);
				const valid_date = is_valid_date(items[4]) ? items[4] : null;

				if (contract_number !== null && contract_date !== null && valid_date !== null && !isNaN(consumer_id) && !isNaN(seller_id)) {

					try {

						const contract_data = {
							contract_number: contract_number,
							contract_date: getDateFormat(contract_date),
							consumer_id: consumer_id,
							seller_id: seller_id,
							valid_date: valid_date
						};

						const response = await axios.post('/api/admin/contract/add', contract_data);
			
						if (response.status === 200) {
			
							import_count++;
						}
			
					} catch (error) {
			
						if (error.response) {
							// The request was made and the server responded with a status code out of the range of 2xx
							setMessage(message => ({
								...message,
								show: true,
								message: error.response.data,
							}));
						}
			
						console.log(error);
					}


				} else {
					console.log('Cannot parse line: %o', items);
				}
			} else {
				console.log('Wrong line: %o', items);
			}
		}

		console.log('Imported %o contracts', import_count);
	}


	async function handleUsersImport (event) {

		event.preventDefault();

		if (!window.confirm('Ви впевнені, що бажаєте імпортувати користувачів?')) {
			return;
		}

		let import_count = 0;

		for(let i = 0; i < user_items.length; i++) {
					
			let items = user_items[i].split(';');

			if (items.length === 6) {

				const company_id = parseInt(items[0]);
				const last_name = is_valid_name(items[1]) ? items[1] : null;
				const first_name = is_valid_name(items[2]) ? items[2] : null;
				const email = is_valid_email(items[3]) ? items[3] : null;
				const phone = is_valid_phone(items[4]) ? items[4] : null;
				const role = items[5];

				if (last_name !== null && first_name !== null && email !== null && phone !== null && !isNaN(company_id)) {

					try {

						const user_data = {
							company_id: company_id,
							last_name: last_name,
							first_name: first_name,
							email: email,
							phone: phone,
							role: role,
							password: 'p@ssw0rd'
						};

						const response = await axios.post('/api/admin/user/add', user_data);
			
						if (response.status === 200) {
			
							import_count++;
						}
			
					} catch (error) {
			
						if (error.response) {
							// The request was made and the server responded with a status code out of the range of 2xx
							setMessage(message => ({
								...message,
								show: true,
								message: error.response.data,
							}));
						}
			
						console.log(error);
					}


				} else {
					console.log('Cannot parse line: %o', items);
				}
			} else {
				console.log('Wrong line: %o', items);
			}
		}

		console.log('Imported %o users', import_count);
	}


	const onCancelHandler = () => {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	return (

		<Container className={ classes.paper } component='main'>

			<form className={ classes.form_control } onSubmit={ handleCompaniesImport } >

				<Typography component='h2' variant='h5' align='center'>
					Імпорт компаній
				</Typography>

				<Typography component='h2' variant='h6' align='center'>
					<p>Підтримується імпорт з csv файлу без заголовку в наступному форматі:</p>

					<table>
						<tbody>
							<tr>
								<th>name;</th>
								<th>registration_code;</th>
								<th>address;</th>
								<th>eic_x_code</th>
							</tr>
						</tbody>
					</table>

				</Typography>

				<TextField
					required
					variant='outlined'
					margin='normal'
					fullWidth
					type='file'
					label='Файл імпорту'
					inputProps = {{ accept:'.csv' }}
					onChange= { handleImportCompaniesFileChange }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
					>
						Імпорт
					</Button>

				</div>

			</form>

			<form className={ classes.form_control } onSubmit={ handleContractsImport } >

				<Typography component='h2' variant='h5' align='center'>
					Імпорт договорів
				</Typography>

				<Typography component='h2' variant='h6' align='center'>
					<p>Підтримується імпорт з csv файлу без заголовку в наступному форматі:</p>

					<table>
						<tbody>
							<tr>
								<th>contract_number;</th>
								<th>contract_date;</th>
								<th>consumer_id;</th>
								<th>seller_id;</th>
								<th>valid_date</th>
							</tr>
						</tbody>
					</table>

				</Typography>

				<TextField
					required
					variant='outlined'
					margin='normal'
					fullWidth
					type='file'
					label='Файл імпорту'
					inputProps = {{ accept:'.csv' }}
					onChange= { handleImportCotractsFileChange }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
					>
						Імпорт
					</Button>

				</div>

			</form>

			<form className={ classes.form_control } onSubmit={ handleUsersImport } >

				<Typography component='h2' variant='h5' align='center'>
					Імпорт користувачів
				</Typography>

				<Typography component='h2' variant='h6' align='center'>
					<p>Підтримується імпорт з csv файлу без заголовку в наступному форматі:</p>

					<table>
						<tbody>
							<tr>
								<th>company_id;</th>
								<th>last_name;</th>
								<th>first_name;</th>
								<th>email;</th>
								<th>phone;</th>
								<th>role</th>
							</tr>
						</tbody>
					</table>

				</Typography>

				<TextField
					required
					variant='outlined'
					margin='normal'
					fullWidth
					type='file'
					label='Файл імпорту'
					inputProps = {{ accept:'.csv' }}
					onChange= { handleImportUsersFileChange }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
					>
						Імпорт
					</Button>

				</div>

			</form>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='secondary'
					className = { classes.button_control }
					onClick= { onCancelHandler }
				>
					Скасувати
				</Button>

			</div>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>

	);
}