import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { bcContext } from './bcContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	header: {
		marginBottom: theme.spacing(3),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	hour_point: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		width: '85px'
	},

}));


export default function TimeSeriesEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);
	const domain_label = React.useRef(null);
	// state for label widths
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);
	const [domain_label_width, setDomainLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(bcContext);

	// get time series id from url
	const time_series_id = parseInt(props.match.params.time_series_id);
	const update_url = isNaN(time_series_id) ? '/api/bc/ts/add' : '/api/bc/ts/' + time_series_id;
	const close_url = isNaN(time_series_id) ? undefined : '/api/bc/ts/' + time_series_id + '/close';
	const confirm_url = isNaN(time_series_id) ? undefined : '/api/bc/ts/' + time_series_id + '/confirm';


	function createInitialState() {

		var day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		var hour_points_array = [];
		for (let i = 1; i <= 24; i++) {

			hour_points_array.push({
				key: i,
				id: i.toString(),
				label: i.toString(),
				valid: true,
				value: 0
			});
		}

		const complex_state = {

			revision: 0,
			revision_id: 0,
			status_id: 1,
			domain_id: 0,
			trade_date: getDateFormat(day_1),
			date_valid: true,
			consumer_company_id: 0,
			seller_company_id: 0,
			receiver_company_code: '',
			receiver_company_role_code: '',

			hours: hour_points_array,

			plan_row: undefined,
			changed: true,
		}

		return complex_state;
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeDateChange(event) {

		const event_value = event.target.value;
		const compare_date = new Date();
		compare_date.setHours(0, 0, 0, 0);

		setLocalState(prev_state => ({ ...prev_state,
			trade_date: event_value,
			date_valid: new Date(event_value) >= compare_date,
			changed: true,
		}));

		// update page according to the time difference
		updateHours(event_value);
	}


	function handleConsumerChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			consumer_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleSellerChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			seller_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function onTextChangeHandler(event) {

		// cache event value
		const event_value = event.target.value;
		// get element id
		const element_id = parseInt(event.target.id);

		// find item
		const new_points = [ ...local_state.hours ];
		const item_at_index = new_points.find(item => item.key === element_id);

		item_at_index.valid = is_valid_float_input(event_value);
		item_at_index.value = event_value;

		setLocalState(prev_state => ({ ...prev_state,
			hours: new_points,
			changed: true,
		}));
	}


	function onValuePaste(event) {

		const event_target = event.target;

		// for elements with identified id
		if (event_target.id) {

			// e.clipboardData contains the data that is about to be pasted.
			if (event.clipboardData.types.indexOf('text/plain') > -1) {

				const string_to_paste = event.clipboardData.getData('text/plain');
				const array_to_paste = string_to_paste.replace(/\t+/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';');

				// Since the paste operation get canceled, we need to manually
				// paste the data into the document.
				const new_points = [ ...local_state.hours ];

				// iterate through buffer array
				for (let i = 0; i < array_to_paste.length; i++) {

					if (array_to_paste[i]) {

						let element_id = parseInt(event_target.id) + i - 1;
						if (new_points[element_id.toString()]) {
							new_points[element_id.toString()].valid = is_valid_float_input(array_to_paste[i]);
							new_points[element_id.toString()].value = array_to_paste[i];
						}
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hours: new_points,
					changed: true,
				}));

				// This is necessary to prevent the default paste action.
				event.preventDefault();
			}
		}
	}


	function onKeyPress(event) {

		const event_target = event.target;

		if (event.charCode === 13) {

			// prevent defualt action
			event.preventDefault();

			// for elements with identified id
			if (event_target.id) {

				const pressed_id = parseInt(event_target.id);
				const pressed_value = event_target.value;
				const new_points = [ ...local_state.hours ];

				for (let i = 0; i < new_points.length; i++) {

					if (i > pressed_id - 1) {
						new_points[i].value = pressed_value;
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hours: new_points,
					changed: true,
				}));
			}
		}
	}


	async function saveHandler() {

		try {

			const new_data = {
				domain_id: local_state.domain_id,
				trade_date: getDateFormat(local_state.trade_date),
				consumer_company_id: local_state.consumer_company_id,
				seller_company_id: local_state.seller_company_id,
				p01: (local_state.hours.length > 0) ? local_state.hours[0].value : null,
				p02: (local_state.hours.length > 1) ? local_state.hours[1].value : null,
				p03: (local_state.hours.length > 2) ? local_state.hours[2].value : null,
				p04: (local_state.hours.length > 3) ? local_state.hours[3].value : null,
				p05: (local_state.hours.length > 4) ? local_state.hours[4].value : null,
				p06: (local_state.hours.length > 5) ? local_state.hours[5].value : null,
				p07: (local_state.hours.length > 6) ? local_state.hours[6].value : null,
				p08: (local_state.hours.length > 7) ? local_state.hours[7].value : null,
				p09: (local_state.hours.length > 8) ? local_state.hours[8].value : null,
				p10: (local_state.hours.length > 9) ? local_state.hours[9].value : null,
				p11: (local_state.hours.length > 10) ? local_state.hours[10].value : null,
				p12: (local_state.hours.length > 11) ? local_state.hours[11].value : null,
				p13: (local_state.hours.length > 12) ? local_state.hours[12].value : null,
				p14: (local_state.hours.length > 13) ? local_state.hours[13].value : null,
				p15: (local_state.hours.length > 14) ? local_state.hours[14].value : null,
				p16: (local_state.hours.length > 15) ? local_state.hours[15].value : null,
				p17: (local_state.hours.length > 16) ? local_state.hours[16].value : null,
				p18: (local_state.hours.length > 17) ? local_state.hours[17].value : null,
				p19: (local_state.hours.length > 18) ? local_state.hours[18].value : null,
				p20: (local_state.hours.length > 19) ? local_state.hours[19].value : null,
				p21: (local_state.hours.length > 20) ? local_state.hours[20].value : null,
				p22: (local_state.hours.length > 21) ? local_state.hours[21].value : null,
				p23: (local_state.hours.length > 22) ? local_state.hours[22].value : null,
				p24: (local_state.hours.length > 23) ? local_state.hours[23].value : null,
				p25: (local_state.hours.length > 24) ? local_state.hours[24].value : null
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function confirmHandler() {

		if (confirm_url) {

			try {

				await axios.post(close_url);
				const response = await axios.post(confirm_url);
		
				if (response.status === 200) {

					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));

					return true;
				}
	
			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}

				console.log(error);
			}
		}

		return false;
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		var data_valid = local_state.date_valid;
		local_state.hours.forEach(item => {
			if (!item.valid) {
				document.getElementById(item.id).focus();
				data_valid = false;
			}
		});

		if (data_valid) {

			if (!local_state.changed && local_state.status_id === 3) {

				// if state is not changed - confirm
				if (await confirmHandler()) {
					// return to parent page
					props.history.push('/time_series');
				}

			} else {

				// if state has changed - save
				if (await saveHandler()) {
					// return to parent page
					props.history.push('/time_series');
				}
			}

		} else {

			window.alert('Перевірте правильність вводу даних часового ряда.');
		}
	}


	function onCancelHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.go(-1);
	}


	function OnImportExportHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.push('/schedules/import/0/' + time_series_id);
	}


	function OnPlanTakeoverHandler() {

		if (local_state.plan_row !== undefined) {

			var result = [];
			for (let i = 1; i <= 25; i++) {

				const index = (i < 10) ? `p0${i}` : `p${i}`;
				if (local_state.plan_row[index] !== null) {

					result.push({
						key: i,
						id: i.toString(),
						label: i.toString(),
						valid: true,
						value: parseFloat(local_state.plan_row[index]),
					});
				}
			}

			setLocalState(prev_state => ({ ...prev_state,
				hours: result,
				changed: false,
			}));
		}
	}


	function updateHours(start_date) {

		// get start and end dates
		var calculated_start_date = new Date(start_date);
		var calculated_end_date = new Date(start_date);
		calculated_end_date.setMinutes(calculated_end_date.getMinutes() + 24 * 60);

		// get difference in hours between the dates
		var hours = (calculated_end_date - calculated_start_date) / 3.6e6;
		var result = null;

		// if hours is greater then array length, add elements
		if (hours > local_state.hours.length) {

			// create a copy of hour_point and add elements
			result = [ ...local_state.hours ];
			const new_length = (hours > 25) ? 25 : hours
			for (let i = local_state.hours.length + 1; i <= new_length; i++) {

				result.push({
					key: i,
					id: i.toString(),
					label: i.toString(),
					valid: true,
					value: 0
				});
			}

			setLocalState(prev_state => ({ ...prev_state,
				hours: result
			}));
		}

		// if hours is lower then array length, remove elements
		if (hours < local_state.hours.length) {

			// create a copy of hour_point and remove elements
			result = [ ...local_state.hours ];
			setLocalState(prev_state => ({ ...prev_state,
				hours: result.slice(0, (hours < 0) ? 0 : hours)
			}));
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		setConsumerLabelWidth(consumer_label.current.offsetWidth);
		setSellerLabelWidth(seller_label.current.offsetWidth);
		setDomainLabelWidth(domain_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		axios.get('/api/bc/schedules/receiver')
			.then(response => {

				setLocalState(prev_state => ({ ...prev_state,
					receiver_company_code: response.data.eic_x_code,
					receiver_company_role_code: response.data.receiver_role_code,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [])


	useEffect(() => {

		if (!time_series_id) {
			const consumer_company = global_state.companies.find(company => company.company_id === user.company_id);

			if (consumer_company !== undefined) {
				setLocalState(prev_state => ({ ...prev_state,
					consumer_company_id: consumer_company.company_id
				}));
			}
		}

	}, [time_series_id, user, global_state])


	useEffect(() => {

		if (time_series_id) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { revision, revision_id, status_id, domain_id, trade_date, consumer_company_id, seller_company_id } = response.data;

						var result = [];
						for (let i = 1; i <= 25; i++) {

							const index = (i < 10) ? `p0${i}` : `p${i}`;
							if (response.data[index] !== null) {

								result.push({
									key: i,
									id: i.toString(),
									label: i.toString(),
									valid: true,
									value: parseFloat(response.data[index]),
								});
							}
						}

						setLocalState(prev_state => ({ ...prev_state,
							revision: parseInt(revision),
							revision_id: parseInt(revision_id),
							status_id: parseInt(status_id),
							domain_id: parseInt(domain_id),
							trade_date: getDateFormat(trade_date),
							consumer_company_id: parseInt(consumer_company_id),
							seller_company_id: parseInt(seller_company_id),

							hours: result,
							changed: false,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
						let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
							edit: edit,
						}));
					}

					console.log(error);
				});
		}

	}, [time_series_id, update_url])


	useEffect(() => {

		if (local_state.status_id === 1 && local_state.domain_id > 0 && local_state.consumer_company_id > 0 && local_state.seller_company_id > 0) {

			axios.get('/api/planning/plans_total', {
				params: {
					domain: local_state.domain_id,
					consumer_company_id: local_state.consumer_company_id,
					seller_company_id: local_state.seller_company_id,
					period_start: getDateFormat(local_state.trade_date),
					period_end: getDateFormat(local_state.trade_date),
				}
			})
				.then(response => {

					const planning_rows = response.data.plan_row_list;

					setLocalState(prev_state => ({ ...prev_state,
						plan_row: planning_rows[0]
					}));

				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [time_series_id, local_state.domain_id, local_state.consumer_company_id, local_state.seller_company_id])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({ ...message,
							show: true,
							info: `${ first_name } ${ last_name } \n ${ email } \n ${ phone }`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (close_url) {
				axios.post(close_url);
			}
		}

	}, [close_url])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={ item.company_id } value={ item.company_id } >{ item.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				{ isNaN(time_series_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(time_series_id) ? 'Додати часовий ряд' : 'Змінити часовий ряд' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Typography component='h2' variant='h5' align='center' className= { classes.header }>
					Часовий ряд графіку
				</Typography>

				<Grid container spacing={2}>

					<Grid item xs={6}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={ domain_label_width }
								onChange={ handleDomainChange }
								value={ local_state.domain_id }
								disabled={ local_state.status_id !== 1 }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value = { local_state.trade_date }
							error = { !local_state.date_valid }
							onChange = { handleTradeDateChange }
							InputLabelProps={{ shrink: true }}
							disabled={ local_state.status_id !== 1 }
						/>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={ seller_label } id='seller-label'>Продавець</InputLabel>
							<Select
								native
								labelId='seller-label'
								labelWidth={ seller_label_width }
								onChange={ handleSellerChange }
								value={ local_state.seller_company_id }
								disabled={ local_state.status_id !== 1 }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={ consumer_label } id='consumer-label'>Покупець</InputLabel>
							<Select
								native
								labelId='consumer-label'
								labelWidth={ consumer_label_width }
								onChange={ handleConsumerChange }
								value={ local_state.consumer_company_id }
								disabled={ local_state.status_id !== 1 }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

				</Grid>

				<div className={classes.input_form_control}>

					{ local_state.hours.map(item => {
						return (<TextField
							variant='outlined'
							margin='normal'
							size='small'
							className= { classes.hour_point }
							key={ item.key }
							id={ item.id }
							error={ !item.valid }
							label={ item.label }
							onChange={ onTextChangeHandler }
							onPaste={ onValuePaste }
							onKeyPress={ onKeyPress }
							value={ item.value }
							disabled={ local_state.status_id !== 1 && local_state.status_id !== 3 }
						/>)
					}) }

				</div>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
						disabled={ local_state.status_id !== 1 && local_state.status_id !== 3 }
					>
						{ (!local_state.changed && local_state.status_id === 3) ? 'Погодити' : 'Зберегти' }
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnImportExportHandler }
					>
						Імпорт / Експорт
					</Button>

					{
						(local_state.plan_row !== undefined) &&
						<Button
							variant='contained'
							color='primary'
							className = { classes.button_control }
							onClick = { OnPlanTakeoverHandler }
						>
							Взяти обсяги з плану
						</Button>
					}

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ message.info }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}