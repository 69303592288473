import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import AnnouncementIcon from '@material-ui/icons/AnnouncementRounded';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { Grid, Button, Typography, TextField } from '@material-ui/core';
import { getLocalDateString } from '../utils/date_functions';


function LicenseNotificationDialog(props) {

	const { open, onClose, license } = props;

	// define state for details
	const [details, setDetails] = useState({
		show: false,
		info: ''
	});


	async function handleUpdate () {

		if (!details.show) {

			setDetails(prev_state => ({ ...prev_state,
				show: true,
			}));

		} else {

			if (details.info !== '') {

				try {

					const data = {
						license_info: details.info,
					}
		
					const response = await axios.post('/api/settings/license', data);
					if (response.status === 200) {
		
						setDetails({
							show: false,
							info: ''
						});
					}

				} catch(error) {
		
					alert(error.response.data);
					console.log(error);
				}
			}
		}
	}


	function handleClose () {
		onClose();
	}


	function handleDetailsChange (event) {

		const event_value = event.target.value;
		setDetails(event_value);

		setDetails(prev_state => ({ ...prev_state,
			show: true,
			info: event_value,
		}));
	}


	let display_message = '';
	const expiry = new Date(license.validity);

	const today = new Date()
	today.setHours(0, 0, 0, 0);

	const next_month = new Date();
	next_month.setHours(0, 0, 0, 0);
	next_month.setDate(next_month.getDate() + 30);

	if (today >= expiry) {
		display_message = `Дія ліцензії на користування PowerTrade закінчилась ${ getLocalDateString(expiry) }.`
	} else if (next_month > expiry) {
		display_message = `Дія ліцензії на користування PowerTrade закінчиться ${ getLocalDateString(expiry) }.`
	}


	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth='xs'
			aria-labelledby='confirmation-dialog-title'
			open={open}
		>
			<DialogTitle
				id='confirmation-dialog-title'
			>
				Ліцензія
			</DialogTitle>

			<DialogContent>

				{ (display_message !== '') &&
					<Grid container>

						<Grid item xs={2}>

							<AnnouncementIcon
								color='secondary'
							/>

						</Grid>

						<Grid item xs={10}>

							<Typography>
								{ display_message }
							</Typography>

						</Grid>

					</Grid>
				}

				<Grid container>

					<Grid item xs={12}>

						<h3>Ліцензійна інформація</h3>

					</Grid>

					<Grid item xs={8}>

						<Typography>Кількість користувачів</Typography>

					</Grid>

					<Grid item xs={4}>

						{ license.max_users }

					</Grid>

					<Grid item xs={8}>

						<Typography>Кінцева дата</Typography>

					</Grid>

					<Grid item xs={4}>

						{ getLocalDateString(license.validity) }

					</Grid>

				</Grid>


				{
					details.show &&
					<Grid container>

						<Grid item xs={12}>

							<h3>Оновлення діцензії</h3>

						</Grid>

						<Grid item xs={12}>

							<TextField
								multiline
								fullWidth
								value={ details.info }
								onChange={ handleDetailsChange }
							/>

						</Grid>

					</Grid>
				}

			</DialogContent>

			<DialogActions>

				<Button
					onClick={handleUpdate}
					color='primary'
				>
					Оновити ліцензію
				</Button>

				<Button
					autoFocus
					onClick={handleClose}
					color='secondary'
				>
					Закрити
				</Button>

			</DialogActions>
		</Dialog>
	);
}


export default LicenseNotificationDialog;


LicenseNotificationDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	license: PropTypes.object.isRequired
};