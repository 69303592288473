import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import FilterSearch from '../../subcomponents/FilterSearch';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function ContractTable(props) {

	const classes = useStyles();

	const { rows } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');

	const init_filter = {
		number: '',
		date: '',
		valid_date: '',
		consumer: '',
		seller: ''
	};
	const [filter, setFilter] = React.useState(init_filter);

	const head_cells = [
		{ id: 'number', alignTo: 'left', disablePadding: false, label: 'Номер', filter_by: filter.number },
		{ id: 'date', alignTo: 'left', disablePadding: false, label: 'Дата', filter_by: filter.date },
		{ id: 'valid_date', alignTo: 'left', disablePadding: false, label: 'Дійсний до', filter_by: filter.valid_date },
		{ id: 'seller', alignTo: 'left', disablePadding: false, label: 'Продавець', filter_by: filter.seller },
		{ id: 'consumer', alignTo: 'left', disablePadding: false, label: 'Покупець', filter_by: filter.consumer },
		{ id: 'actions', alignTo: 'right', disablePadding: false, label: 'Дії' },
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleFilterChange = (id, event) => {

		const new_filter = { ...filter };
		new_filter[id] = event.target.value;

		setFilter(new_filter);
	}


	const handleFilterDelete = () => {
		const new_filter = { ...filter };
		Object.keys(new_filter).forEach(key => {
			new_filter[key] = '';
		});
		setFilter(new_filter);
	}


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onRequestSort={handleRequestSort}
						disable_checkbox={true}
					/>

					<TableBody>
						<TableRow>
							{
								head_cells.map(item => (

									(item.id !== 'actions') ?
									<TableCell
										key={ item.id }
									>
										<FilterSearch
											value={ item.filter_by }
											onSearchChange={ (event) => handleFilterChange(item.id, event) }
										/>
									</TableCell>
									:
									<TableCell
										key={ item.id }
									>
										<IconButton
											onClick={ handleFilterDelete }
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								))
							}
						</TableRow>
					</TableBody>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy))
								.filter(row => row.number.toLowerCase().indexOf(filter.number) !== -1)
								.filter(row => row.date.indexOf(filter.date) !== -1)
								.filter(row => row.valid_date.indexOf(filter.valid_date) !== -1)
								.filter(row => row.consumer.toLowerCase().indexOf(filter.consumer) !== -1)
								.filter(row => row.seller.toLowerCase().indexOf(filter.seller) !== -1)
								.map((row, index) => {

									const label_id = `row-checkbox-${index}`;

									return (
										<TableRow
											hover
											key={row.key}
										>
											<TableCell component='th' id={label_id} scope='row'>
												{row.number}
											</TableCell>

											<TableCell align='left'>{row.date}</TableCell>
											<TableCell align='left'>{row.valid_date}</TableCell>
											<TableCell align='left'>{row.seller}</TableCell>
											<TableCell align='left'>{row.consumer}</TableCell>
											<TableCell align='right'>
												<NavLink to={ 'contracts/' + row.id }>
													<EditIcon />
												</NavLink>
											</TableCell>
										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


ContractTable.propTypes = {
	rows: PropTypes.array.isRequired,
};