import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getLocalDateTimeString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		textAlign: 'center',
	},

	title: {
		display: 'none',
		margin: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	control_margin: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function HelpRequestForm() {

	const classes = useStyles();

	// states for form controls
	const form_init_state = {
		url: '',
		request: '',
	};
	const [request, setRequest] = useState(form_init_state);


	function handleUrlChange(event) {

		const event_value = event.target.value;

		setRequest(local_state => ({ ...local_state,
			url: event_value
		}));
	}


	function handleRequestChange(event) {

		const event_value = event.target.value;

		setRequest(local_state => ({ ...local_state,
			request: event_value
		}));
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		const new_data = {
			url_reference: request.url,
			request_text: request.request,
		}

		const response = await axios.post('/api/help/request', new_data);
		if (response.status === 200) {

			const request_time = response.data.request_time;

			setRequest(local_state => ({ ...local_state,
				url: '',
				request: '',
			}));

			window.alert('Ваше звернення було зареєстроване ' + getLocalDateTimeString(request_time));
		} else {
			window.alert('Помилка реєстрації звернення.');
		}
	}


	return (

		<form onSubmit={onSubmitHandler} className={classes.root} >

			<Typography variant='h6' noWrap className={classes.title } >
				Звернення до технічної підтримки
			</Typography>

			<Grid container spacing={2}>

				<Grid item xs={4}>

					<TextField
						label='URL роздіу, з приводу якого Ви звертаєтесь'
						variant='outlined'
						className={classes.control_margin}
						fullWidth
						value={request.url}
						onChange={handleUrlChange}
					/>

				</Grid>

				<Grid item xs={8}></Grid>

				<Grid item xs={12}>

					<TextField
						label='Ваше звернення'
						variant='outlined'
						multiline
						className={classes.control_margin}
						fullWidth
						value={request.request}
						onChange={handleRequestChange}
					/>

				</Grid>

			</Grid>

			<div className={classes.button_form_control}>

				<Button
					type='submit'
					variant='contained'
					color='primary'
					className={classes.button_control}
				>
					Подати звернення
				</Button>

			</div>

		</form>
	)
}