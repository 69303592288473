import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},

	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},

	button: {
		marginTop: theme.spacing(2),
	},

}));


function LoginForm() {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	const [user_email, setUserEmail] = useState('');
	const [user_password, setUserPassword] = useState('');

	const { setUser } = useContext(globalContext);


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	function loginHandler(e) {

		e.preventDefault();

		async function fetchData() {

			const auth_data = {
				email: user_email,
				password: user_password
			};

			try {

				const res_login = await axios.post('/api/login', auth_data);
				if (res_login.status === 200) {

					setUser(prev_state => ({ ...prev_state,

						company_id: res_login.data.company_id,
						last_name: res_login.data.last_name,
						first_name: res_login.data.first_name,
						email: res_login.data.email,
						phone: res_login.data.phone,
						role: res_login.data.role,
						authorisation: res_login.data.authorisation,
	
						is_logged_in: true,
						login_error: false,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();
	};


	return (
		<Container className={ classes.paper } component='main' maxWidth='xs'>

			<Avatar className={ classes.avatar }>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h4'>
				Авторизація
			</Typography>

			<form className = { classes.form } onSubmit={ loginHandler } >
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					label='Email користувача'
					autoComplete='email'
					autoFocus
					onChange={(e) => setUserEmail(e.target.value)}
				/>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					label='Пароль'
					autoComplete='current password'
					type='password'
					onChange={(e) => setUserPassword(e.target.value)}
				/>
				<Button
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					className={ classes.button }
				>
					Увійти
				</Button>
			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	);
}


export default LoginForm;