import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		width: '100%',
	},

	table_head: {
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'center',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
		width: '70px'
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '75px',
	},

}));


export default function SummaryReport2(props) {
	const classes = useStyles();

	const { report } = props;

	// Create our number formatter.
	var formatter = new Intl.NumberFormat('ua', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});


	return (
		<Paper className={classes.paper}>
			<TableContainer>
				{	(report && report.volumes) &&
					<Table
						key={'volumes'}
						size= {'small'}
						className={classes.table}
					>
						<TableBody>
							<TableRow key={0}>
								<TableCell
									colSpan={report.column_count + 2}
									className={ classes.table_head }
								>
									Обсяг, МВтг
								</TableCell>
							</TableRow>
							{
								report.volumes.map((row, row_index) => (
									<TableRow key={row_index}>
										{
											row.map((cell, cell_index) => (
												<TableCell
													key={ cell_index }
													className={ (row_index === 0 || cell_index === 0) ? classes.head : classes.cells }
												>
													{
														(row_index === 0 || cell_index === 0) ? cell : formatter.format(cell)
													}
												</TableCell>
											))
										}
									</TableRow>
								))
							}
							<TableRow key={-1}>
								<TableCell colSpan={report.column_count + 2} />
							</TableRow>
						</TableBody>
					</Table>
				}
				{	(report && report.amounts) &&
					<Table
						key={'amounts'}
						size={'small'}
						className={classes.table}
					>
						<TableBody>
							<TableRow key={0}>
								<TableCell
									colSpan={report.column_count + 2}
									className={ classes.table_head }
								>
									Вартість, грн
								</TableCell>
							</TableRow>
							{
								report.amounts.map((row, row_index) => (
									<TableRow key={ row_index }>
										{
											row.map((cell, cell_index) => (
												<TableCell
													key={ cell_index }
													className={ (row_index === 0 || cell_index === 0) ? classes.head : classes.cells }
												>
													{
														(row_index === 0 || cell_index === 0) ? cell : formatter.format(cell)
													}
												</TableCell>
											))
										}
									</TableRow>
								))
							}
							<TableRow key={-1}>
								<TableCell colSpan={report.column_count + 2} />
							</TableRow>
						</TableBody>
					</Table>
				}
				{	(report && report.references) &&
					<Table
						key={'references'}
						size={'small'}
						className={classes.table}
					>
						<TableBody>
							<TableRow key={0}>
								<TableCell
									colSpan={report.column_count + 2}
									className={ classes.table_head }
								>
									Вартість за цінами РДН, грн
								</TableCell>
							</TableRow>
							{
								report.references.map((row, row_index) => (
									<TableRow key={ row_index }>
										{
											row.map((cell, cell_index) => (
												<TableCell
													key={ cell_index }
													className={ (row_index === 0 || cell_index === 0) ? classes.head : classes.cells }
												>
													{
														(row_index === 0 || cell_index === 0) ? cell : formatter.format(cell)
													}
												</TableCell>
											))
										}
									</TableRow>
								))
							}
							<TableRow key={-1}>
								<TableCell colSpan={report.column_count + 2} />
							</TableRow>
						</TableBody>
					</Table>
				}
			</TableContainer>
		</Paper>
	)
};


SummaryReport2.propTypes = {
	report: PropTypes.object.isRequired,
};