import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';
import { Bar } from 'react-chartjs-2';

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { is_valid_float_input } from '../utils/validation';
import { excel_date_to_iso_date_string, excel_date_to_date, excel_date_hour_to_iso_date_time } from '../utils/excel_functions';
import { compare_as_numbers, DamResultsFromJson } from '../utils/results_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	paper_top: {
		width: '600px',
	},

	paper_bottom: {
		marginTop: theme.spacing(2),
		width: '1000px',
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		border: '1px solid gray',
		borderRadius: '10px',
		alignItems: 'center',
		width: '100%',
	},

	control_margin: {
		marginBottom: theme.spacing(2),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

}));


export default function DamResultsAnalysisForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	const inital_sate = {
		import_file: undefined,

		loaded_data: undefined,
		domains: [],
		dates: [],
		hours: [],
		trade_types: [],

		trade_type: '',
		domain: '',
		date_start: 0,
		date_end: 0,
		minimum_amount: 0,
		minimum_amount_valid: true,

		// charts data
		selling_volumes: [],
		buying_volumes: [],
		dam_prices: [],
		deals: [],
	}
	const [local_state, setLocalState] = useState(inital_sate);

	// states for the label width
	const domain_label = React.useRef(null);
	const date_start_label = React.useRef(null);
	const date_end_label = React.useRef(null);
	const trade_type_label = React.useRef(null);

	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [date_start_label_width, seDateStartLabelWidth] = useState(0);
	const [date_end_label_width, seDateEndLabelWidth] = useState(0);
	const [trade_type_label_width, setTradeTypeLabelWidth] = useState(0);


	// ascending sort by 2 item of row
	function compare_rows_as_numbers(a, b) {
		if (a[2] > b[2]) return 1;
		if (b[2] > a[2]) return -1;
		return 0;
	}


	function handleImportFileChange(event) {

		try {

			const event_value = event.target.files[0];

			// import from xlsx file
			if (event_value.name.indexOf('.xlsx') !== -1) {

				setLocalState(prev_state => ({ ...prev_state,
					import_file: event_value,
				}));

			} else {
				window.alert('Обрано формат, що не підтримується.')
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function onUploadHandler(e) {

		e.preventDefault();

		try {

			// import
			if (local_state.import_file !== undefined) {

				const reader = new FileReader();

				// declare onload function
				reader.onload = function () {

					const file_content = reader.result;

					const wb = XLSX.read(file_content, { type: 'binary' });
					const second_sheet_name = wb.SheetNames.find(elem => elem.indexOf('Точки кривої') !== -1);

					if (second_sheet_name !== undefined) {

						const json_dam_results = XLSX.utils.sheet_to_json(wb.Sheets[second_sheet_name], { header: 1 });
						const result = DamResultsFromJson(json_dam_results);

						setLocalState(prev_state => ({ ...prev_state,
							loaded_data: result.data,
							domains: result.domains,
							domain: result.domains[0],
							dates: result.dates,
							date_start: result.dates[0].value.toString(),
							date_end: result.dates[result.dates.length - 1].value.toString(),
							hours: result.hours,
							trade_types: result.trade_types,
							trade_type: result.trade_types[0]
						}))
					}
				}

				reader.readAsBinaryString(local_state.import_file);
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain: event_value,
		}));
	}


	function handleDateStartChange(event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			date_start: event_value,
		}));
	}


	function handleDateEndChange(event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			date_end: event_value,
		}));
	}


	function handleTradeTypeChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			trade_type: event_value,
		}));
	}


	function handleValueChange(event) {

		const event_value = event.target.value.replace(',', '.');

		setLocalState(prev_state => ({ ...prev_state,
			minimum_amount: event_value,
			minimum_amount_valid: is_valid_float_input(event_value),
		}));
	}


	async function onDownloadHandler(e) {

		e.preventDefault();

		if (!local_state.minimum_amount_valid) {
			return;
		}

		try {

			if (local_state.loaded_data !== undefined) {

				const filtered_by_domain = local_state.loaded_data.filter(row => row[6] === local_state.domain);
				const filtered_by_dates = filtered_by_domain.filter(row => row[0] >= local_state.date_start && row[0] <= local_state.date_end);
				const filtered_by_buying = filtered_by_dates.filter(row => row[5].toLowerCase() === 'купівля');
				const filtered_by_selling = filtered_by_dates.filter(row => row[5].toLowerCase() === 'продаж');

				const source_array = (local_state.trade_type.toLowerCase() === 'продаж') ? filtered_by_selling : filtered_by_buying;

				const hour_price_values = {};
				const dates = [];
				source_array.forEach(row => {
					if (!dates.includes(row[0])) {
						dates.push(row[0]);
					}
					if (hour_price_values[row[1]] === undefined) {
						hour_price_values[row[1]] = [];
					}
					if (!hour_price_values[row[1]].includes(row[2])) {
						hour_price_values[row[1]].push(row[2]);
					}
				});

				// Chart data
				var selling_volumes = [];
				var buying_volumes = [];
				var dam_prices = [];
				var deals = [];

				// create output data
				var output = [];
				// create two cells of header
				output[0] = [];
				output[0][0] = 'Година';
				output[0][1] = 'Ціна';

				// sort dates and save them to array and header
				dates.sort(compare_as_numbers);
				var output_dates = [];
				dates.forEach((value, index) => {
					output_dates[index + 2] = value;
					output[0][index + 2] = excel_date_to_date(value).toLocaleDateString();
				});

				// sort prices
				Object.keys(hour_price_values).forEach(key => {
					hour_price_values[key].sort(compare_as_numbers);
				});

				// get block average power
				var blocks_average_power = [];
				var nuclear_blocks_average_power = [];
				try {

					const average_power_result = await axios.get('/api/blocks/average', {
						params: {
							period_start: excel_date_to_iso_date_string(output_dates[2]),
							period_end: excel_date_to_iso_date_string(output_dates[output_dates.length - 1] + 1),
							domain: local_state.domain,
						}
					});

					if (average_power_result.status === 200) {
						blocks_average_power = average_power_result.data;
					}

					const average_nuclear_power_result = await axios.get('/api/blocks/average/nuclear', {
						params: {
							period_start: excel_date_to_iso_date_string(output_dates[2]),
							period_end: excel_date_to_iso_date_string(output_dates[output_dates.length - 1] + 1),
						}
					});

					if (average_nuclear_power_result.status === 200) {
						nuclear_blocks_average_power = average_nuclear_power_result.data;
					}

				} catch (error) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: error.message,
					}));

					console.log(error)
				}

				// fill output
				var output_row_index = 1;
				Object.keys(hour_price_values).forEach(key => {

					// first fill hours, prices and amount
					// key is hour, value is prices array
					hour_price_values[key].forEach(price => {

						output[output_row_index] = [];
						output[output_row_index][0] = key;
						output[output_row_index][1] = price;

						// define amounts
						output_dates.forEach((value, index) => {

							// find amount with date (value), hour (key), price
							let search_row = source_array.find(row => {
								return (row[0] === value) && (row[1] === parseInt(key)) && (row[2] === price);
							});
							let return_value;
							if (search_row !== undefined) {
								return_value = (search_row[3] > local_state.minimum_amount) ? search_row[3] : undefined;
							}

							output[output_row_index][index] = return_value;
						})

						output_row_index += 1;
					});

					// then output summary for each date (value) and hour (key)

					// acceptance
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Акцептовано';
					output_dates.forEach((value, index) => {
						// filter with date and hour
						const filtered_rows = filtered_by_selling.filter(row => row[0] === value && row[1] === parseInt(key));

						// sum of accepted buying values
						let sum = 0;
						filtered_rows.forEach(row => {
							sum += row[4];
						})
						output[output_row_index][index] = sum;
					});
					output_row_index += 1;

					// selling volumes
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Обсяг продажу';
					output_dates.forEach((value, index) => {
						// filter with date and hour
						const filtered_rows = filtered_by_selling.filter(row => row[0] === value && row[1] === parseInt(key));

						// sum of proposed values
						let sum = 0;
						filtered_rows.forEach(row => {
							sum += row[3];
						})
						output[output_row_index][index] = sum;

						if (parseInt(local_state.date_end) === value) {
							selling_volumes.push(sum);

							// filter by volume > 200
							const rows_to_display = filtered_rows.filter(row => row[3] > 200);
							// sort rows by price descending
							rows_to_display.sort(compare_rows_as_numbers);

							// take 3 first rows and above 80% from last
							let last_volume = 0;
							rows_to_display.forEach((row, index) => {

								if ((index < 3) || (row[3] > last_volume * 0.8)) {

									deals.push({
										hour: parseInt(key),
										volume: 100 * row[3] / sum,
										price: row[2],
										text: `${row[3]} МВтг: ${row[2]} грн`
									});

									last_volume = row[3];
								}
							});
						}
					});
					output_row_index += 1;

					// buying volumes
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Обсяг купівлі';
					output_dates.forEach((value, index) => {
						// filter with date and hour
						const filtered_rows = filtered_by_buying.filter(row => row[0] === value && row[1] === parseInt(key));

						// sum of proposed values
						let sum = 0;
						filtered_rows.forEach(row => {
							sum += row[3];
						})
						output[output_row_index][index] = sum;

						if (parseInt(local_state.date_end) === value) {
							buying_volumes.push(sum)
						}
					});
					output_row_index += 1;

					// last acceptance
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Останній акцепт';
					output_dates.forEach((value, index) => {
						// filter with date and hour
						// const filtered_rows = filtered_by_selling.filter(row => row[0] === value && row[1] === parseInt(key) && row[3] !== row[4] && row[4] > 0);
						const filtered_rows = filtered_by_selling.filter(row => row[0] === value && row[1] === parseInt(key) && row[3] >= row[4] && row[4] > 0);
						const last_row = filtered_rows[filtered_rows.length - 1];
						let output_string = `${last_row[2]}: ${last_row[3]} (${last_row[4]})`;

						// let output_string = '';
						// filtered_rows.forEach(row => {
						// 	output_string += `${row[2]}: ${row[3]} (${row[4]});`
						// });
						output[output_row_index][index] = output_string;

						if (parseInt(local_state.date_end) === value) {
							const i = filtered_rows.length - 1;
							dam_prices.push((i >= 0) ? filtered_rows[i][2] : 0);
						}
					});
					output_row_index += 1;

					// block information
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Стан блоків';
					output_dates.forEach((value, index) => {
						// get information of blocks average power during the hour
						const period_start_iso = excel_date_hour_to_iso_date_time(output_dates[2], 0);
						const period_end_iso = excel_date_hour_to_iso_date_time(value, parseInt(key));
						const hour = (new Date(period_end_iso) - new Date(period_start_iso)) / 3.6e6;
						output[output_row_index][index] = blocks_average_power[hour];
					});
					output_row_index += 1;

					// nuclear stations status
					output[output_row_index] = [];
					output[output_row_index][0] = key;
					output[output_row_index][1] = 'Стан атомних блоків';
					output_dates.forEach((value, index) => {
						// get information of nuclear blocks average power during the hour
						const period_start_iso = excel_date_hour_to_iso_date_time(output_dates[2], 0);
						const period_end_iso = excel_date_hour_to_iso_date_time(value, parseInt(key));
						const hour = (new Date(period_end_iso) - new Date(period_start_iso)) / 3.6e6;
						output[output_row_index][index] = nuclear_blocks_average_power[hour];
					});
					output_row_index += 1;

				});

				// filter out rows with undefined values
				const filtered_output = output.filter(row => {
					let result = false;
					output_dates.forEach((value, index) => {
						result = result || (row[index] !== undefined);
					})
					return result;
				});

				const file_name = `Output_${local_state.domain}_${excel_date_to_date(local_state.date_start).toLocaleDateString()}_${excel_date_to_date(local_state.date_end).toLocaleDateString()}.xlsx`

				// create sheet from json
				const output_sheet = XLSX.utils.json_to_sheet(filtered_output, { skipHeader: true });
				const source_sheet = XLSX.utils.json_to_sheet(local_state.loaded_data, { skipHeader: true });

				// create new workbook
				var output_wb = XLSX.utils.book_new();
				// insert sheet into workbook
				XLSX.utils.book_append_sheet(output_wb, output_sheet, 'Результат аналізу');
				XLSX.utils.book_append_sheet(output_wb, source_sheet, 'Точки кривої');

				// save workbook to file
				XLSX.writeFile(output_wb, file_name);

				setLocalState(prev_state => ({ ...prev_state,
					selling_volumes: selling_volumes,
					buying_volumes: buying_volumes,
					dam_prices: dam_prices,
					deals: deals,
				}));
			}

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));

			console.log(error);
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (local_state.loaded_data !== undefined) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
			seDateStartLabelWidth(date_start_label.current.offsetWidth);
			seDateEndLabelWidth(date_end_label.current.offsetWidth);
			setTradeTypeLabelWidth(trade_type_label.current.offsetWidth);
		}

	}, [local_state.loaded_data])


	var display_domains = [];
	if (local_state.domains.length > 0) {
		display_domains = local_state.domains.map(item => {
			return (
				<option key={item} value={item}>{item}</option>
			)
		});
		display_domains.unshift(<option key={-1} value=''></option>)
	}

	var display_dates = [];
	if (local_state.dates.length > 0) {
		display_dates = local_state.dates.map(item => {
			return (
				<option key={item.value} value={item.value}>{item.display.toLocaleDateString()}</option>
			)
		});
		display_dates.unshift(<option key={-1} value=''></option>)
	}

	var display_trade_types = [];
	if (local_state.trade_types.length > 0) {
		display_trade_types = local_state.trade_types.map(item => {
			return (
				<option key={item} value={item}>{item}</option>
			)
		});
		display_trade_types.unshift(<option key={-1} value=''></option>)
	}

	var display_deals = [];
	if (local_state.deals.length > 0) {

		let trace_number = 3;
		let current_hour = 1;
		let number = 0;
		local_state.deals.forEach(item => {

			if (item.hour === current_hour) {
				number += 1;
			} else {

				current_hour = item.hour;
				if (number > trace_number) {
					trace_number = number;
				}
				number = 0;
			}
		});

		for (let i = 0; i < trace_number; i++) {

			const y_array = [];
			const text_array = [];
			for (let j = 1; j <= 25; j++) {

				const hour_deals = local_state.deals.filter(item => item.hour === j);

				y_array.push((i < hour_deals.length - 1) ? hour_deals[i].volume.toFixed(1) : undefined);
				text_array.push((i < hour_deals.length - 1) ? hour_deals[i].text : undefined);
			}

			let random_color = Math.floor(Math.random()*16777215).toString(16);

			display_deals.push({
				label: 'Обсяги продажу',
				type: 'bar',
				data: y_array,
				text: text_array,
				backgroundColor: '#' + random_color,
				borderColor: '#' + random_color,
				hoverBorderColor: '#e51c23'
			})
		}

	}


	const options = {
		responsive: true,
		tooltips: {
			mode: 'label'
		},
		elements: {
			line: {
				fill: false
			}
		},
		scales: {
			xAxes: [
				{
					display: true,
					gridLines: {
						display: true
					},
				}
			],
			yAxes: [
				{
					type: 'linear',
					display: true,
					position: 'left',
					id: 'y-axis-1',
					gridLines: {
						display: false
					},
				},
				{
					type: 'linear',
					display: true,
					position: 'right',
					id: 'y-axis-2',
					gridLines: {
						display: true
					},
				}
			]
		}
	};


	const options2 = {
		responsive: true,
		tooltips: {
			mode: 'label'
		},
		elements: {
			line: {
				fill: false
			}
		},
		scales: {
			xAxes: [{
				stacked: true
			}],
			yAxes: [{
				stacked: true
			}]
		}
	};


	return (

		<Container className={classes.paper} component='main'>

			<div className={classes.paper_top}>

				<form onSubmit={onUploadHandler} className={classes.form_control} >

					<Typography component='h3' variant='h6' align='center'>
						Зчитання результатів РДН
					</Typography>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						className={classes.control_margin}
						fullWidth
						type='file'
						label='Файл результатів РДН'
						inputProps={{ accept: '.xlsx' }}
						onChange={handleImportFileChange}
					/>

					<div className={classes.button_form_control}>

						<Button
							type='submit'
							variant='contained'
							color='primary'
							className={classes.button_control}
						>
							Зчитати файл
						</Button>

					</div>

				</form>

				{ local_state.loaded_data !== undefined &&
					<form onSubmit={onDownloadHandler} className={classes.form_control} >

						<Typography component='h3' variant='h6' align='center' className={classes.control_margin}>
							Розрахунки результатів РДН
						</Typography>

						<Grid container spacing={2}>

							<Grid item xs={12}>

								<FormControl
									variant='outlined'
									required
									fullWidth
								>
									<InputLabel ref={domain_label} id='domain-label'>Торгова зона</InputLabel>
									<Select
										native
										labelId='domain-label'
										labelWidth={domain_label_width}
										onChange={handleDomainChange}
										value={local_state.domain}
									>
										{display_domains}
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={6}>

								<FormControl
									required
									variant='outlined'
									fullWidth
								>
									<InputLabel ref={date_start_label} id='date-start-label'>З дати</InputLabel>
									<Select
										native
										labelId='date-start-label'
										labelWidth={date_start_label_width}
										onChange={handleDateStartChange}
										value={local_state.date_start}
									>
										{display_dates}
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={6}>

								<FormControl
									required
									variant='outlined'
									fullWidth
								>
									<InputLabel ref={date_end_label} id='date-end-label'>До дати</InputLabel>
									<Select
										native
										labelId='date-end-label'
										labelWidth={date_end_label_width}
										onChange={handleDateEndChange}
										value={local_state.date_end}
									>
										{display_dates}
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={12}>

								<FormControl
									variant='outlined'
									required
									fullWidth
								>
									<InputLabel ref={trade_type_label} id='trade-type-label'>Тип торгів</InputLabel>
									<Select
										native
										labelId='trade-type-label'
										labelWidth={trade_type_label_width}
										onChange={handleTradeTypeChange}
										value={local_state.trade_type}
									>
										{display_trade_types}
									</Select>
								</FormControl>

							</Grid>

						</Grid>

						<TextField
							required
							variant='outlined'
							margin='normal'
							error={!local_state.minimum_amount_valid}
							fullWidth
							label='Включати в результат угоди з обсягом більше МВтг'
							onChange={handleValueChange}
							value={local_state.minimum_amount}
						/>

						<div className={classes.button_form_control}>

							<Button
								type='submit'
								variant='contained'
								color='primary'
								className={classes.button_control}
							>
								Завантажити
							</Button>

						</div>

					</form>}

			</div>

			{ local_state.selling_volumes.length > 0 &&
				<div className={classes.paper_bottom}>

				<Bar
					data={{
						labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
						datasets: [{
							label: 'Обсяг продажу',
							type: 'bar',
							data: local_state.selling_volumes,
							yAxisID: 'y-axis-1',
							backgroundColor: '#2E86C1',
							borderColor: '#2E86C1',
							hoverBorderColor: '#2874A6'
						},
						{
							label: 'Обсяг купівлі',
							type: 'bar',
							data: local_state.buying_volumes,
							yAxisID: 'y-axis-1',
							backgroundColor: '#17A589',
							borderColor: '#17A589',
							hoverBorderColor: '#148F77'
						},
						{
							label: 'Ціна РДН',
							type: 'line',
							data: local_state.dam_prices,
							yAxisID: 'y-axis-2',
							backgroundColor: '#D4AC0D',
							borderColor: '#D4AC0D',
							pointBorderColor: '#B7950B',
							pointBackgroundColor: '#B7950B',
							pointHoverBackgroundColor: '#9A7D0A',
							pointHoverBorderColor: '#9A7D0A',
						}]
					}}
					options={options}
				/>

				<Bar
					data={{
						labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
						datasets: display_deals
					}}
					options={options2}
				/>

				</div>}

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</Container>

	);
}