import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import DamBlocksEdit from './subcomponents/DamBlocksEdit';
import DamHelpDialog from './subcomponents/DamHelpDialog';
import OptionConfirmationDialog from '../subcomponents/OptionConfirmationDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { damContext } from './damContext';
import { DamOrderFromJson, DamOrderToCells, DamOrderFromXml, DamOrderToXml } from '../utils/dam_functions';
import { getDateFormat, getISOString_HHMMSS_FromDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	grid: {
		flexGrow: 1,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center',
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

	header: {
		marginBottom: theme.spacing(3),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(1),
	},

}));


export default function DamOrderEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	// state for the import order
	const [imported_xml, setImportedXml] = useState(undefined);
	const [imported_xlsx, setImportedXlsx] = useState(undefined);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	const [open_help_dialog, setOpenHelpDialog] = useState(false);
	const [open_option_dialog, setOpenOptionDialog] = useState(false);

	// variables for the label control
	const domain_label = React.useRef(null);
	const trade_type_label = React.useRef(null);
	const sender_company_label = React.useRef(null);
	const acceptance_type_label = React.useRef(null);
	const block_type_label = React.useRef(null);

	// state for label widths
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [trade_type_label_width, setTradeTypeLabelWidth] = useState(0);
	const [sender_company_label_width, setSenderCompanyLabelWidth] = useState(0);
	const [acceptance_type_label_width, setAcceptanceTypeLabelWidth] = useState(0);
	const [block_type_label_width, setBlockTypeLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(damContext);

	// get order id from url
	const order_id = parseInt(props.match.params.order_id);
	const update_url = isNaN(order_id) ? '/api/dam/add' : '/api/dam/' + order_id;
	const close_url = isNaN(order_id) ? undefined : '/api/dam/' + order_id + '/close';


	function createInitialState () {

		// get tomorrows's midnight
		var day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		const complex_state = {

			status_id: 1,
			sender_company_id: 0,
			trade_date: getDateFormat(day_1),
			trade_date_valid: true,
			domain_id: 0,
			trade_type_id: 0,
			acceptance_type_id: 2,
			block_type_id: 0,
			rows: [],

			columns_count: getHoursInDate(day_1),
			blocks_count: 1,
			max_blocks_count: 25,

			receiver_company_code: '',
			receiver_company_name: '',

			action_in_progress: false,
			changed: true,

			max_day_threshold: 0,
			max_night_threshold: 0
		}

		return complex_state;
	}


	function onUpdateTableValue (block_number, period, cell, value) {

		let copy_rows = [ ...local_state.rows ];
		const row_to_change = copy_rows.find(item => item.block_number === block_number && item.period === period);

		if (row_to_change !== undefined) {

			switch (cell) {
				case 1:
					row_to_change.amount = value;
					break;
				case 2:
					row_to_change.price = value;
					break;
				case 3:
					row_to_change.splitting_type_id = parseInt(value);
					break;
				default:
					break;
			}

		} else {

			const new_row = {
				block_number: block_number,
				period: period,
				amount: 0,
				price: 0,
				splitting_type_id: 1,
			};

			switch (cell) {
				case 1:
					new_row.amount = value;
					break;
				case 2:
					new_row.price = value;
					break;
				case 3:
					new_row.splitting_type_id = parseInt(value);
					break;
				default:
					break;
			}

			copy_rows = copy_rows.concat(new_row);
		}

		setLocalState(prev_state => ({ ...prev_state,
			rows: copy_rows,
		}))
	}


	function onValuePaste(block_number, period, cell, event) {

		// e.clipboardData contains the data that is about to be pasted.
		if (event.clipboardData.types.indexOf('text/plain') > -1) {

			const string_to_paste = event.clipboardData.getData('text/plain');
			// split string if new line character occurs
			const strings_to_paste = string_to_paste.split(/\r\n|\r|\n/g);

			// convert strings to arrays
			const arrays_to_paste = strings_to_paste.map(s => s.replace(/\t/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';'));

			let copy_rows = [ ...local_state.rows ];

			let value_index = 0
			for (let i = period; i < 25; i++) {

				const row_to_change = copy_rows.find(item => item.block_number === block_number && item.period === i);
				if (row_to_change !== undefined) {

					switch (cell) {
						case 1:
							row_to_change.amount = arrays_to_paste[0][value_index];
							if (arrays_to_paste.length > 1) {
								row_to_change.price = arrays_to_paste[1][value_index];
							}
							break;
						case 2:
							row_to_change.price = arrays_to_paste[0][value_index];
							break;
						default:
							break;
					}

				} else {
		
					const new_row = {
						block_number: block_number,
						period: i,
						amount: 0,
						price: 0,
						splitting_type_id: 1,
					};
		
					switch (cell) {
						case 1:
							new_row.amount = arrays_to_paste[0][value_index];
							if (arrays_to_paste.length > 1) {
								new_row.price = arrays_to_paste[1][value_index];
							}
							break;
						case 2:
							new_row.price = arrays_to_paste[0][value_index];
							break;
						default:
							break;
					}

					copy_rows = copy_rows.concat(new_row)
				}

				value_index++;
			}

			setLocalState(prev_state => ({ ...prev_state,
				rows: copy_rows,
			}))

			// This is necessary to prevent the default paste action.
			event.preventDefault();
		}
	}


	function onAddTableBlock () {

		setLocalState(prev_state => ({ ...prev_state,
			blocks_count: prev_state.blocks_count + 1,
			changed: true,
		}));
	}


	function onDeleteTableBlock (block_number) {

		const copy_rows = local_state.rows.filter(item => item.block_number !== block_number);

		setLocalState(prev_state => ({ ...prev_state,
			blocks_count: prev_state.blocks_count - 1,
			rows: copy_rows,
			changed: true,
		}));
	}


	function handleImportFileChange (event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xml file
		if (event_value.name.indexOf('.xml') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {
					const file_content_string = reader.result;

					const parser = new DOMParser();
					const xml_doc = parser.parseFromString(file_content_string, 'text/xml');
	
					// get order from xml document and store into state
					const import_xml_order = DamOrderFromXml(xml_doc);
					setImportedXml(import_xml_order);

				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e)
				}
			}

			reader.readAsText(event_value);
			import_executed = true;
		}

		// import from xlsx file
		if (event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {
					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });
					const json_doc = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
	
					// get order from xml document and store into state
					const import_xlsx_order = DamOrderFromJson(json_doc);
					setImportedXlsx(import_xlsx_order);
				} catch (e) {

					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e)
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.')
		}
	}


	function handleImport (event) {

		event.preventDefault();

		if (!window.confirm('Ви впевнені, що бажаєте імпортувати заявку? При імпорті дані переписуються даними, що імпортуєтсья.')) {
			return;
		}

		if (imported_xml !== undefined) {

			let domain = global_state.domains.find(item => item.domain_name === imported_xml.domain);
			let domain_id = (domain !== undefined) ? domain.domain_id : 0;

			let sender_company = global_state.companies.find(item => item.eic_x_code.localeCompare(imported_xml.sender) === 0);
			let sender_company_id = (sender_company !== undefined) ? sender_company.company_id : 0;

			let trade_type = global_state.trade_types.find(item => item.type_code === imported_xml.trade_type);
			let trade_type_id = (trade_type !== undefined) ? trade_type.type_id : undefined;

			let acceptace = global_state.acceptance_types.find(item => item.type_code === imported_xml.acceptance_type);
			let acceptace_type_id = (acceptace !== undefined) ? acceptace.type_id : undefined;

			let block_type = global_state.block_types.find(item => item.type_code === imported_xml.block_type);
			let block_type_id = (block_type !== undefined) ? block_type.type_id : undefined;

			let blocks_count = 1;
			const order_rows = [];

			imported_xml.rows.forEach(row => {

				const splitting = global_state.splitting_types.find(item => item.type_code === row.splitting);
				const splitting_id = (splitting !== undefined) ? splitting.type_id : undefined;

				if (row.block_number > blocks_count) {
					blocks_count = row.block_number;
				}

				order_rows.push({
					block_number: row.block_number,
					period: row.period,
					price: row.price,
					amount: row.amount,
					splitting_type_id: splitting_id,
				})
			})

			setLocalState(prev_state => ({
				...prev_state,
				sender_company_id: sender_company_id,
				trade_date: getDateFormat(imported_xml.trade_date),
				domain_id: domain_id,
				trade_type_id: trade_type_id,
				acceptance_type_id: acceptace_type_id,
				block_type_id: block_type_id,
				rows: order_rows,

				columns_count: getHoursInDate(imported_xml.trade_date),
				blocks_count: blocks_count,
			}))
		}

		if (imported_xlsx !== undefined) {

			let domain_id;
			switch (imported_xlsx.domain.toLowerCase()) {
				case 'oec':
				case 'оес':
				case 'ua_ips':
					domain_id = 1;
					break;
				case 'буос':
				case 'ua_bei':
					domain_id = 2;
					break;
				default:
					break;
			}

			let trade_type_id;
			switch (imported_xlsx.trade_type.toLowerCase()) {
				case 'buy':
				case 'купівля':
					trade_type_id = 1;
					break;
				case 'sell':
				case 'продаж':
					trade_type_id = 2;
					break;
				default:
					break;
			}

			let acceptace_type_id;
			switch (imported_xlsx.acceptance_type.toLowerCase()) {
				case 'yes':
				case 'так':
					acceptace_type_id = 1;
					break;
				case 'no':
				case 'ні':
					acceptace_type_id = 2;
					break;
				default:
					break;
			}

			let block_type_id;
			switch (imported_xlsx.block_type) {
				case 'Проста блочна заявка':
					block_type_id = 1;
					break;
				case 'Профільована блочна заявка':
					block_type_id = 2;
					break;
				default:
					block_type_id = 0;
					break;
			}

			let sender_company = global_state.companies.find(item => item.eic_x_code.localeCompare(imported_xlsx.sender) === 0);
			let sender_company_id = (sender_company !== undefined) ? sender_company.company_id : 0;

			let blocks_count = 1;

			const order_rows = [];
			imported_xlsx.rows.forEach(item => {

				let splitting_type_id;
				switch (item.splitting) {
					case 'Yes':
					case 'Так':
						splitting_type_id = 1;
						break;
					case 'No':
					case 'Ні':
						splitting_type_id = 2;
						break;
					default:
						splitting_type_id = undefined;
						break;
				}

				if (item.block_number > blocks_count) {
					blocks_count = item.block_number;
				}

				order_rows.push({
					block_number: item.block_number,
					period: item.period,
					amount: item.amount,
					price: item.price,
					splitting_type_id: splitting_type_id,
				});
			});

			setLocalState(prev_state => ({
				...prev_state,
				sender_company_id: sender_company_id,
				trade_date: getDateFormat(imported_xlsx.trade_date),
				domain_id: domain_id,
				trade_type_id: trade_type_id,
				acceptance_type_id: acceptace_type_id,
				block_type_id: block_type_id,
				rows: order_rows,

				columns_count: getHoursInDate(imported_xlsx.trade_date),
				blocks_count: blocks_count,
			}))
		}
	}


	function handleDomainChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeTypeChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			trade_type_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeDateChange (event) {

		const event_value = event.target.value;
		const compare_date = new Date();
		compare_date.setHours(0, 0, 0, 0);

		setLocalState(prev_state => ({ ...prev_state,
			trade_date: event_value,
			trade_date_valid: new Date(event_value) >= compare_date,
			columns_count: getHoursInDate(event_value),
			changed: true,
		}));
	}


	function handleCompanyChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			sender_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleAcceptanceTypeChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			acceptance_type_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleBlockTypeChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			block_type_id: parseInt(event_value),
			changed: true,
		}));
	}


	async function saveHandler (rows) {

		try {

			const new_data = {
				status_id: 1,
				sender_company_id: local_state.sender_company_id,
				trade_date: getDateFormat(local_state.trade_date),
				domain_id: local_state.domain_id,
				trade_type_id: local_state.trade_type_id,
				acceptance_type_id: local_state.acceptance_type_id,
				block_type_id: local_state.block_type_id,
				rows: rows,
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({
					...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		// check if input is valid
		let input_valid = local_state.trade_date_valid;
		let price_threshold = false;

		// get only filled rows
		const effective_rows = local_state.rows.filter(item => item.amount !== '' && item.price !== '');
		if (!local_state.editing && effective_rows.length > 0) {

			// data validation algorithm
			effective_rows.forEach(row => {

				const period = parseInt(row.period);
				const amount = parseFloat(row.amount);
				const price = parseFloat(row.price);

				if (amount !== 0 && amount < 0.1) {
					input_valid = false;
				}

				// validate price
				const threshold = (period >= 7 && period <=23) ? local_state.max_day_threshold : local_state.max_night_threshold;
				if (price !== 0 && (price < 10 || price > threshold)) {
					input_valid = false;
					price_threshold = true;
				}
			})

		} else {
			input_valid = false;
		}

		if (input_valid) {
			if (await saveHandler(effective_rows)) {
				// return to parent page
				props.history.push('/dam');
			}
		} else {

			if (price_threshold) {
				window.alert('Ціни в заявки повинні бути більше 10 та менше встановленого Price cap для РДН.')
			} else {
				window.alert('Перевірте правильність вводу даних заявки.');
			}
		}
	}


	async function getLastPrices () {

		if (local_state.domain_id === 0 || local_state.trade_type_id === 0) {
			return;
		}

		// get yeterday's midnight
		var day_1 = new Date();
		day_1.setHours(-24, 0, 0, 0);

		const payload = {
			request_code: 941,
			trade_date: getDateFormat(day_1),
		}

		setLocalState(prev_state => ({ ...prev_state,
			action_in_progress: true,
		}));

		try {

			const response = await axios.get('/api/dam/xmtrade/results', { params: payload });
			if (response.status === 200) {

				const trade_results = response.data.results;
				const domain = global_state.domains.find(item => item.domain_id === local_state.domain_id);
				const trade_result = trade_results.find(item => item.domain === domain.domain_name);

				const new_rows = [ ...local_state.rows ];
				new_rows.forEach(row => {
					if (row.price === '' || row.price === 0) {
						const replace_row = trade_result.rows.find(item => item.period === row.period);
						if (replace_row !== undefined) {
							row.price = replace_row.price;
						}
					}
				})

				setLocalState(prev_state => ({ ...prev_state,
					rows: new_rows,
					action_in_progress: false,
				}));
			}

		} catch (error) {
			
			setLocalState(prev_state => ({ ...prev_state,
				action_in_progress: true,
			}));

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	function onCancelHandler () {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.go(-1);
	}


	function handleHelpDialogClose () {
		setOpenHelpDialog(false);
	}


	async function exportOrderToXlsx() {

		try {

			let sender_company = global_state.companies.find(item => item.company_id === local_state.sender_company_id);
			let sender_company_code = (sender_company !== undefined) ? sender_company.eic_x_code : '';

			let domain;
			switch (local_state.domain_id) {
				case 1:
					domain = 'OEC';
					break;
				case 2:
					domain = 'БуОс';
					break;
				default:
					break;
			}

			let trade_type;
			switch (local_state.trade_type_id) {
				case 1:
					trade_type = 'Купівля';
					break;
				case 2:
					trade_type = 'Продаж';
					break;
				default:
					break;
			}

			let acceptance_type;
			switch (local_state.acceptance_type_id) {
				case 1:
					acceptance_type = 'Так';
					break;
				case 2:
					acceptance_type = 'Ні';
					break;
				default:
					acceptance_type = '';
					break;
			}

			let block_type;
			switch (local_state.block_type_id) {
				case 0:
					block_type = '';
					break;
				case 1:
					block_type = 'Проста блочна заявка';
					break;
				case 2:
					block_type = 'Профільована блочна заявка';
					break;
				default:
					block_type = '';
					break;
			}

			let order_rows = [];
			local_state.rows.forEach(item => {

				let splitting_type;
				switch (item.splitting_type_id) {
					case 1:
						splitting_type = 'Так';
						break;
					case 2:
						splitting_type = 'Ні';
						break;
					default:
						splitting_type = undefined;
						break;
				}

				order_rows.push({
					block_number: item.block_number,
					period: item.period,
					amount: item.amount,
					price: item.price,
					splitting_type: splitting_type,
				});
			})

			const export_order = {
				sender: sender_company_code,
				trade_date: local_state.trade_date,
				domain: domain,
				trade_type: trade_type,
				acceptance_type: acceptance_type,
				block_type: block_type,
				rows: order_rows,
			}

			// download template file
			const temp_xlsx_doc = await axios.get('/docs/templates/DamOrder.xlsx', { responseType: 'arraybuffer' });
			const wb = XLSX.read(temp_xlsx_doc.data, { type: 'array', cellStyles: true });
			const sheet = wb.Sheets[wb.SheetNames[0]];

			// make changes to a template sheet
			const update_commands = DamOrderToCells(export_order);
			update_commands.forEach(item => {

				const cell_ref = XLSX.utils.encode_cell({ c: item.c, r: item.r });
				sheet[cell_ref].t = item.t;
				sheet[cell_ref].v = item.v;
				delete(sheet[cell_ref.w]);
			});

			// save workbook to file
			XLSX.writeFile(wb, `Export_DAM_${domain}_${local_state.trade_date}.xlsx`);

		} catch (error) {

			setMessage(prev_state => ({ ...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	async function exportOrderToXml() {

		let sender_company = global_state.companies.find(item => item.company_id === local_state.sender_company_id);
		let sender_company_code = (sender_company !== undefined) ? sender_company.eic_x_code : '';

		let domain = global_state.domains.find(item => item.domain_id === local_state.domain_id);
		let domain_name = (domain !== undefined) ? domain.domain_name : '';

		let trade_type = global_state.trade_types.find(item => item.type_id === local_state.trade_type_id);
		let trade_type_code = (trade_type !== undefined) ? trade_type.type_code : undefined;

		let acceptance_type = global_state.acceptance_types.find(item => item.type_id === local_state.acceptance_type_id);
		let acceptance_type_code = (acceptance_type !== undefined) ? acceptance_type.type_code : undefined;

		let block_type = global_state.block_types.find(item => item.type_id === local_state.block_type_id);
		let block_type_code = (block_type !== undefined) ? block_type.type_code : undefined;

		let order_rows = [];
		local_state.rows.forEach(row => {

			let splitting_type = global_state.splitting_types.find(item => item.type_id === row.splitting_type_id);
			let splitting_type_code = (splitting_type !== undefined) ? splitting_type.type_code : undefined;

			order_rows.push({
				block_number: row.block_number,
				period: row.period,
				amount: row.amount,
				price: row.price,
				splitting_type: splitting_type_code,
			});
		})

		const export_order = {
			order_id: order_id,
			created_date: getISOString_HHMMSS_FromDateString(new Date()),
			sender: sender_company_code,
			receiver: local_state.receiver_company_code,
			trade_date: local_state.trade_date,
			domain: domain_name,
			trade_type: trade_type_code,
			acceptance_type: acceptance_type_code,
			block_type: block_type_code,
			rows: order_rows,
		}

		try {

			const xml_document = await axios.get('/docs/templates/DamOrder.xml');

			const parser = new DOMParser();
			// parse document to xml to export
			const xml_order = parser.parseFromString(xml_document.data, 'text/xml');

			// store schedule to xml document
			DamOrderToXml(xml_order, export_order);

			// convert xml back to string
			const save_file_content = (new XMLSerializer()).serializeToString(xml_order);
			// get string to blob and save blob to a file
			const blob = new Blob([ save_file_content ], { type: 'text/xml' });
			saveAs(blob, `Export_DAM_${local_state.trade_date}_${domain_name}.xml`);

		} catch(error) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: error.message,
			}));
		}
	}


	async function handleOptionDialogClose (option_value) {
		setOpenOptionDialog(false);

		// continue export
		if (option_value === 'xml') {
			await exportOrderToXml();
		}
		if (option_value === 'xlsx') {
			await exportOrderToXlsx();
		}
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setTradeTypeLabelWidth(trade_type_label.current.offsetWidth);
		setSenderCompanyLabelWidth(sender_company_label.current.offsetWidth);
		setAcceptanceTypeLabelWidth(acceptance_type_label.current.offsetWidth);
		setBlockTypeLabelWidth(block_type_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		axios.get('/api/idm/receiver')
			.then(response => {

				setLocalState(prev_state => ({ ...prev_state,
					receiver_company_code: response.data.eic_x_code,
					receiver_company_name: response.data.name,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [])


	useEffect(() => {

		const sender_company = global_state.companies.find(company => company.company_id === user.company_id);

		if (sender_company !== undefined) {
			setLocalState(prev_state => ({ ...prev_state,
				sender_company_id: sender_company.company_id,
			}));
		}

	}, [user, global_state])


	useEffect(() => {

		if (order_id) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { status_id, sender_company_id, trade_date, domain_id, trade_type_id, acceptance_type_id, block_type_id } = response.data;
						const order_rows = response.data.rows;

						let max_block_number = 1;
						order_rows.forEach(elem => {
							if (elem.block_number > max_block_number) {
								max_block_number = elem.block_number;
							}

							elem.splitting_type_id = (elem.splitting_type_id !== null) ? elem.splitting_type_id : undefined;
						});

						setLocalState(prev_state => ({ ...prev_state,
							status_id: parseInt(status_id),
							sender_company_id: parseInt(sender_company_id),
							trade_date: getDateFormat(trade_date),
							domain_id: parseInt(domain_id),
							trade_type_id: parseInt(trade_type_id),
							acceptance_type_id: parseInt(acceptance_type_id),
							block_type_id: parseInt(block_type_id),
							rows: order_rows,

							columns_count: getHoursInDate(trade_date),
							blocks_count: max_block_number,
						}));

					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
						let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
							edit: edit,
						}));
					}

					console.log(error);
				});
		}

	}, [order_id, update_url])


	useEffect(() => {

		async function fetchData() {

			try {

				if (local_state.domain_id === 1) {

					const resp_oes_day_th = await axios.post('/api/settings/get', {
						setting_name: 'DAM_OES_DAY_THRESHOLD',
					});
					const oes_day_threshold = resp_oes_day_th.data.setting_value;
	
					const resp_oes_night_th = await axios.post('/api/settings/get', {
						setting_name: 'DAM_OES_NIGHT_THRESHOLD',
					});
					const oes_night_threshold = resp_oes_night_th.data.setting_value;

					setLocalState(prev_state => ({ ...prev_state,
						max_day_threshold: parseFloat(oes_day_threshold),
						max_night_threshold: parseFloat(oes_night_threshold),
					}));
				}

				if (local_state.domain_id === 2) {

					const resp_buos_day_th = await axios.post('/api/settings/get', {
						setting_name: 'DAM_BUOS_DAY_THRESHOLD',
					});
					const buos_day_threshold = resp_buos_day_th.data.setting_value;
	
					const resp_buos_night_th = await axios.post('/api/settings/get', {
						setting_name: 'DAM_BUOS_NIGHT_THRESHOLD',
					});
					const buos_night_threshold = resp_buos_night_th.data.setting_value;

					setLocalState(prev_state => ({ ...prev_state,
						max_day_threshold: buos_day_threshold,
						max_night_threshold: buos_night_threshold,
					}));
				}

			} catch(error) {

				console.log(error);

				setMessage(message => ({ ...message,
					show: true,
					message: error.message,
				}));
			}
		}

		if (local_state.domain_id > 0) {
			fetchData();
		}

	}, [local_state.domain_id])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({
							...message,
							show: true,
							info: `${ first_name } ${ last_name } \n ${ email } \n ${ phone }`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (close_url) {
				axios.post(close_url);
			}
		}
	}, [close_url])


	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value= { item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	var display_trade_types = [];
	if (global_state.trade_types.length > 0) {
		display_trade_types = global_state.trade_types.map(item => {
			return (
				<option key={ item.type_id } value= { item.type_id } >{ item.type_comment }</option>
			)
		})
		display_trade_types.unshift(<option key={-1} value={''} ></option>)
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies
			.filter(company => company.company_id === user.company_id)
			.map(company => {
				return (
					<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
				)
			})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}

	var display_acceptances = [];
	if (global_state.acceptance_types.length > 0) {
		display_acceptances = global_state.acceptance_types.map(item => {
				return (
					<option key={ item.type_id } value= { item.type_id } >{ item.type_comment }</option>
				)
			})
		display_acceptances.unshift(<option key={-1} value={''} ></option>)
	}

	var display_block_types = [];
	if (global_state.block_types.length > 0) {
		display_block_types = global_state.block_types.map(item => {
			return (
				<option key={ item.type_id } value= { item.type_id } >{ item.type_comment }</option>
			)
		})
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				{ isNaN(order_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(order_id) ? 'Додати заявку РДН' : 'Редагувати заявку РДН' }
			</Typography>

			<div className={classes.grid}>

				<Grid container spacing={3}>

					<Grid item xs={6} >

						<form className={ classes.form_control } onSubmit={ handleImport } >

							<Typography component='h2' variant='h5' align='center'>
								Імпорт заявки РДН
							</Typography>

							<TextField
								InputLabelProps={{ shrink: true }}
								required
								variant='outlined'
								margin='normal'
								fullWidth
								type='file'
								label='Файл заявки РДН'
								inputProps= {{ accept:'.xml, .xlsx' }}
								onChange= { handleImportFileChange }
							/>

							<div className={classes.button_form_control}>

								<Button
									type='submit'
									variant='contained'
									color='primary'
									className = { classes.button_control }
									disabled={ local_state.status_id !== 1 }
								>
									Імпорт
								</Button>

							</div>

						</form>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={12}>

						<form onSubmit={ onSubmitHandler } className = { classes.form_control } >

							<Typography component='h2' variant='h5' align='center' className= { classes.header }>
								Заявка РДН
							</Typography>

							<Grid container spacing={3}>

								<Grid item xs={4} >

									<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
										<InputLabel ref={ domain_label } id='domain-label'>Торгова зона</InputLabel>
										<Select
											native
											labelId='domain-label'
											labelWidth={ domain_label_width }
											onChange={ handleDomainChange }
											value={ local_state.domain_id }
											disabled={ local_state.status_id !== 1 }
										>
											{ display_domains }
										</Select>
									</FormControl>

								</Grid>

								<Grid item xs={8} >

									<Typography component='h3' variant='body1' align='center'>
										Компанії
									</Typography>

								</Grid>

								<Grid item xs={4}>

									<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
										<InputLabel ref={ trade_type_label } id='trade-type-label'>Тип замовлення</InputLabel>
										<Select
											native
											labelId='trade-type-label'
											labelWidth={ trade_type_label_width }
											onChange={ handleTradeTypeChange }
											value={ local_state.trade_type_id }
											disabled={ local_state.status_id !== 1 }
										>
											{ display_trade_types }
										</Select>
									</FormControl>

								</Grid>

								<Grid item xs={4}>

									<FormControl
										variant='outlined'
										required
										fullWidth
										className={classes.control_bottom_margin}
									>
										<InputLabel ref={ sender_company_label } id='company-label'>Відправник</InputLabel>
										<Select
											native
											labelId='company-label'
											labelWidth={ sender_company_label_width }
											onChange={ handleCompanyChange }
											value={ local_state.sender_company_id }
											disabled={ local_state.status_id !== 1 }
										>
											{ display_companies }
										</Select>
									</FormControl>

								</Grid>

								<Grid item xs={4}>

									<TextField
										label='Отримувач'
										variant='outlined'
										className={classes.control_bottom_margin}
										fullWidth
										value = { local_state.receiver_company_name }
										disabled
									/>

								</Grid>

								<Grid item xs={4}>

									<TextField
										variant='outlined'
										required
										fullWidth
										label='Доба торгівлі'
										type='date'
										value = { local_state.trade_date }
										error = { !local_state.trade_date_valid }
										onChange = { handleTradeDateChange }
										InputLabelProps={{ shrink: true }}
										disabled={ local_state.status_id !== 1 }
									/>

								</Grid>

								<Grid item xs={4}>

									<FormControl
										variant='outlined'
										required
										fullWidth
										className={classes.control_bottom_margin}
									>
										<InputLabel ref={ acceptance_type_label } id='acceptance-type-label'>Блочна заявка</InputLabel>
										<Select
											native
											labelId='acceptance-type-label'
											labelWidth={ acceptance_type_label_width }
											onChange={ handleAcceptanceTypeChange }
											value={ local_state.acceptance_type_id }
											disabled={ local_state.status_id !== 1 }
										>
											{ display_acceptances }
										</Select>
									</FormControl>

								</Grid>

								<Grid item xs={4}>

									<FormControl
										variant='outlined'
										required
										fullWidth
										className={classes.control_bottom_margin}
									>
										<InputLabel shrink={true} ref={ block_type_label } id='block-type-label'>Тип блочної заявки</InputLabel>
										<Select
											native
											labelId='block-type-label'
											labelWidth={ block_type_label_width }
											onChange={ handleBlockTypeChange }
											value={ local_state.block_type_id }
											disabled={ local_state.status_id !== 1 }
										>
											{ display_block_types }
										</Select>
									</FormControl>

								</Grid>

							</Grid>

							<div className={classes.input_form_control}>

								<DamBlocksEdit
									columns_count={ local_state.columns_count }
									blocks_count={ local_state.blocks_count }
									max_blocks={ local_state.max_blocks_count }
									rows={ local_state.rows }
									onPaste={ onValuePaste }
									onTableValueUpdate={ onUpdateTableValue }
									onBlockAdd={ onAddTableBlock }
									onBlockDelete={ onDeleteTableBlock }
									onHelpClicked={ () => setOpenHelpDialog(true) }
								/>

							</div>

							<div className={classes.button_form_control}>

								<Button
									className = { classes.button_control }
									disabled={ local_state.rows.length === 0 || local_state.trade_type_id === 0 || local_state.domain_id === 0 || local_state.action_in_progress }
									onClick={getLastPrices}
								>
									Обрати ціни
								</Button>

								<Button
									type='submit'
									variant='contained'
									color='primary'
									className = { classes.button_control }
									disabled={ local_state.status_id !== 1 }
								>
									Зберегти
								</Button>

								<Button
									variant='contained'
									color='primary'
									className = { classes.button_control }
									onClick = { () => { setOpenOptionDialog(true) } }
								>
									Експорт
								</Button>

								<Button
									variant='contained'
									color='secondary'
									className = { classes.button_control }
									onClick= { onCancelHandler }
								>
									Скасувати
								</Button>

							</div>

						</form>

					</Grid>

				</Grid>

			</div>

			<DamHelpDialog open={ open_help_dialog } onClose={ handleHelpDialogClose } />

			<OptionConfirmationDialog
				keepMounted
				open={ open_option_dialog }
				onClose={ handleOptionDialogClose }
				title= { 'Експорт заявки. Вибіріть формат експорту' }
				options= { [ 'xml', 'xlsx' ] }
				init_value={ 'xlsx' }
			/>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ message.info }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}