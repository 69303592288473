import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Avatar from '@material-ui/core/Avatar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import ExternalSeriesTable from './subcomponents/ExternalSeriesTable';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import { esContext } from './esContext';
import { getDateFormat, getMonthFormat } from '../utils/date_functions';
import { ExternalSeriesFromJson } from '../utils/external_series_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	control_margin: {
		margin: theme.spacing(2, 0, 2, 0),
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		textAlign: 'center',
	},

	container: {
		position: 'relative',
		minHeight: '60px',
		textAlign: 'center',
		verticalAlign: 'middle',
	},

	template_button: {
		position: 'absolute',
		bottom: '0px',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


var selected_tab = '';


export default function ExternalSeriesImportForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);
	const [wait, setWait] = useState(false);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const import_tab_label = React.useRef(null);
	const capacity_label = React.useRef(null);
	const out_domain_label = React.useRef(null);
	const in_domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);

	// state for label widths
	const [import_tab_label_width, setImportTabLabelWidth] = useState(0);
	const [capacity_label_width, setCapacityLabelWidth] = useState(0);
	const [out_domain_label_width, setOutDomainLabelWidth] = useState(0);
	const [in_domain_label_width, setInDomainLabelWidth] = useState(0);
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);

	// import context
	const { global_state, setGlobalState } = useContext(esContext);


	function createInitialState() {

		var today = new Date();
		today.setHours(0, 0, 0, 0);

		const complex_state = {

			period: getMonthFormat(today),

			capacity_id: 0,
			out_domain_id: 0,
			in_domain_id: 0,

			consumer_company_id: 0,
			consumer_name: '',
			seller_company_id: 0,
			seller_name: '',

			imported_wb: undefined,
			tabs: [],

			imported_rows: [],
		}

		return complex_state;
	}


	function handleImportFileChange(event) {

		const event_value = event.target.files[0];
		let import_executed = false;

		// import from xlsx file
		if (event_value.name.indexOf('.xls') !== -1 || event_value.name.indexOf('.xlsx') !== -1) {

			const reader = new FileReader();

			// declare onload function
			reader.onload = function () {

				try {

					const file_content = reader.result;
					const wb = XLSX.read(file_content, { type: 'binary' });

					setLocalState(prev_state => ({
						...prev_state,
						tabs: wb.SheetNames,
						imported_wb: wb
					}));

				} catch (e) {

					setMessage(prev_state => ({
						...prev_state,
						show: true,
						message: 'Помилка імпорту. Невірний формат файлу?',
					}));

					console.log(e);
				}
			}

			reader.readAsBinaryString(event_value);
			import_executed = true;
		}

		if (!import_executed) {
			window.alert('Обрано формат, що не підтримується, або виникла помилка при імпорті з данного файлу.');
		}
	}


	function handleImportTabChange(event) {

		selected_tab = event.target.value;

		if (selected_tab !== '') {

			try {

				const json_doc = XLSX.utils.sheet_to_json(local_state.imported_wb.Sheets[selected_tab], { header: 1 });
				const file_rows = ExternalSeriesFromJson(json_doc);

				const out_domain = global_state.domains.find(d => d.domain_display_name === file_rows[0].out_domain);
				const in_domain = global_state.domains.find(d => d.domain_display_name === file_rows[0].in_domain);

				const out_domain_id = out_domain !== undefined ? out_domain.domain_id : 0;
				const in_domain_id = in_domain !== undefined ? in_domain.domain_id : 0;

				let consumer_company = global_state.companies.find(company => company.eic_x_code.localeCompare(file_rows[0].consumer) === 0);
				let consumer_company_id = (consumer_company !== undefined) ? consumer_company.company_id : 0;
				let consumer_name = (consumer_company !== undefined) ? consumer_company.name : 'не знайено';

				let seller_company = global_state.companies.find(company => company.eic_x_code.localeCompare(file_rows[0].seller) === 0);
				let seller_company_id = (seller_company !== undefined) ? seller_company.company_id : 0;
				let seller_name = (seller_company !== undefined) ? seller_company.name : 'не знайдено';

				var imported_rows = [];
				file_rows.forEach((item, index) => {

					imported_rows.push({
						id: index + 1,
						key: index + 1,
						type_id: undefined,
						out_domain_id: out_domain_id,
						out_domain: item.out_domain,
						in_domain_id: in_domain_id,
						in_domain: item.in_domain,
						trade_date: getDateFormat(item.trade_date),
						consumer_company_id: consumer_company_id,
						consumer: consumer_name,
						seller_company_id: seller_company_id,
						seller: seller_name,
						points: item.points,
					});
				})

				setLocalState(prev_state => ({
					...prev_state,
					period: getMonthFormat(file_rows[0].trade_date),
					out_domain_id: out_domain_id,
					in_domain_id: in_domain_id,
					consumer_company_id: consumer_company_id,
					seller_company_id: seller_company_id,
					imported_rows: imported_rows,
				}));

				if (consumer_company_id === 0 || seller_company_id === 0 || out_domain_id === 0) {
					window.alert('Виникла помилка при імпорті з данного файлу.');
				}

			} catch (e) {

				window.alert('Виникла помилка при імпорті з данного файлу.');
				console.log(e);
			}
		}
	}


	async function handleTemplateDownload() {

		try {

			const temp_xlsx_doc = await axios.get('/docs/templates/External_Series.xlsx', { responseType: 'arraybuffer' });
			const blob = new Blob([temp_xlsx_doc.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, 'External_Series_Import_Template.xlsx');

		} catch (error) {

			console.log(error);
		}
	}


	function handleCapacityChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			capacity_id: parseInt(event_value),
		}));
	}


	async function save_external_series(item) {

		try {

			const new_data = {
				capacity_id: local_state.capacity_id,
				out_domain_id: item.out_domain_id,
				in_domain_id: item.in_domain_id,
				trade_date: getDateFormat(item.trade_date),
				consumer_company_id: item.consumer_company_id,
				seller_company_id: item.seller_company_id,
				p01: (item.points.length > 0) ? item.points[0].value : null,
				p02: (item.points.length > 1) ? item.points[1].value : null,
				p03: (item.points.length > 2) ? item.points[2].value : null,
				p04: (item.points.length > 3) ? item.points[3].value : null,
				p05: (item.points.length > 4) ? item.points[4].value : null,
				p06: (item.points.length > 5) ? item.points[5].value : null,
				p07: (item.points.length > 6) ? item.points[6].value : null,
				p08: (item.points.length > 7) ? item.points[7].value : null,
				p09: (item.points.length > 8) ? item.points[8].value : null,
				p10: (item.points.length > 9) ? item.points[9].value : null,
				p11: (item.points.length > 10) ? item.points[10].value : null,
				p12: (item.points.length > 11) ? item.points[11].value : null,
				p13: (item.points.length > 12) ? item.points[12].value : null,
				p14: (item.points.length > 13) ? item.points[13].value : null,
				p15: (item.points.length > 14) ? item.points[14].value : null,
				p16: (item.points.length > 15) ? item.points[15].value : null,
				p17: (item.points.length > 16) ? item.points[16].value : null,
				p18: (item.points.length > 17) ? item.points[17].value : null,
				p19: (item.points.length > 18) ? item.points[18].value : null,
				p20: (item.points.length > 19) ? item.points[19].value : null,
				p21: (item.points.length > 20) ? item.points[20].value : null,
				p22: (item.points.length > 21) ? item.points[21].value : null,
				p23: (item.points.length > 22) ? item.points[22].value : null,
				p24: (item.points.length > 23) ? item.points[23].value : null,
				p25: (item.points.length > 24) ? item.points[24].value : null
			};

			await axios.post('/api/es/add', new_data);

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({
					...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	async function onImportHandler() {

		setWait(true);

		for (let i = 0; i < local_state.imported_rows.length; i++) {

			const item = local_state.imported_rows[i];

			if (item.out_domain_id !== 0 && item.in_domain_id !== 0 && item.consumer_company_id !== 0 && item.seller_company_id !== 0) {
				await save_external_series(item);
			} else {
				window.alert('Не визначена інформація щодо торгової зони чи компаній.');
				break;
			}
		}

		setGlobalState(prev_state => ({
			...prev_state,
			update_flag: !prev_state.update_flag,
		}));

		window.alert('Імпорт завершено.');

		setWait(false);
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (capacity_label.current) {
			setCapacityLabelWidth(capacity_label.current.offsetWidth);
		}
		if (import_tab_label.current) {
			setImportTabLabelWidth(import_tab_label.current.offsetWidth);
		}
		if (out_domain_label.current) {
			setOutDomainLabelWidth(out_domain_label.current.offsetWidth);
		}
		if (in_domain_label.current) {
			setInDomainLabelWidth(in_domain_label.current.offsetWidth);
		}
		if (consumer_label.current) {
			setConsumerLabelWidth(consumer_label.current.offsetWidth);
		}
		if (seller_label.current) {
			setSellerLabelWidth(seller_label.current.offsetWidth);
		}

	}, [])


	useEffect(() => {

		if (import_tab_label.current) {
			setImportTabLabelWidth(import_tab_label.current.offsetWidth);
		}

	}, [local_state.tabs])


	var display_capacities = [];


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		});
		display_domains.unshift(<option key={-1} value={''} ></option>);
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => {
			return (
				<option key={company.company_id} value={company.company_id} >{company.name}</option>
			)
		});
		display_companies.unshift(<option key={-1} value={''} ></option>);
	}

	// filling selected control with tab names
	var display_tabs = [];
	if (local_state.tabs.length > 0) {
		display_tabs = local_state.tabs.map(item => {
			return (
				<option key={item} value={item} >{item}</option>
			)
		});
		display_tabs.unshift(<option key={-1} value={''} ></option>);
	}

	return (
		<div className={classes.root_form}>

			<Avatar className={classes.avatar}>
				<ImportExportIcon />
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				Імпорт графіків імпорту / експорту
			</Typography>

			<Grid container spacing={2} className={classes.control_margin}>

				<Grid item xs={6}>

					<TextField
						InputLabelProps={{ shrink: true }}
						required
						variant='outlined'
						margin='normal'
						fullWidth
						type='file'
						label='Файл імпорту'
						inputProps={{ accept: '.xls, .xlsx' }}
						onChange={handleImportFileChange}
					/>

				</Grid>

				<Grid item xs={6}>

					<div className={classes.container}>

						<Button
							className={classes.template_button}
							variant='contained'
							color='primary'
							onClick={handleTemplateDownload}
						>
							Зразок файлу імпорту
						</Button>

					</div>

				</Grid>

				<Grid item xs={6}>

					<FormControl variant='outlined' required fullWidth>
						<InputLabel ref={import_tab_label} id='import-tab-label'>Вкладка файлу імпорту</InputLabel>
						<Select
							native
							labelId='import-tab-label'
							labelWidth={import_tab_label_width}
							onChange={handleImportTabChange}
						>
							{display_tabs}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={6} />

				<Grid item xs={3}>

					<TextField
						InputLabelProps={{ shrink: true }}
						variant='outlined'
						required
						fullWidth
						label='Місяць планування'
						type='month'
						value={local_state.period}
						disabled={local_state.period !== ''}
					/>

				</Grid>

				<Grid item xs={3}>

					<FormControl
						variant='outlined'
						required
						fullWidth
					>
						<InputLabel ref={capacity_label} id='capacity-label'>РПС</InputLabel>
						<Select
							native
							labelId='capacity-label'
							labelWidth={capacity_label_width}
							onChange={handleCapacityChange}
							value={local_state.capacity_id}
						>
							{display_capacities}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={6} />

				<Grid item xs={3}>

					<FormControl
						variant='outlined'
						required
						fullWidth
					>
						<InputLabel ref={out_domain_label} id='out-domain-label'>Зона балансування експорту</InputLabel>
						<Select
							native
							labelId='out-domain-label'
							labelWidth={out_domain_label_width}
							value={local_state.out_domain_id}
						>
							{display_domains}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={3}>

					<FormControl
						variant='outlined'
						required
						fullWidth
					>
						<InputLabel ref={in_domain_label} id='in-domain-label'>Зона балансування імпорту</InputLabel>
						<Select
							native
							labelId='in-domain-label'
							labelWidth={in_domain_label_width}
							value={local_state.in_domain_id}
						>
							{display_domains}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={6} />

				<Grid item xs={3}>

					<FormControl variant='outlined' required fullWidth>
						<InputLabel ref={consumer_label} id='consumer-label'>Покупець</InputLabel>
						<Select
							native
							labelId='consumer-label'
							labelWidth={consumer_label_width}
							value={local_state.consumer_company_id}
						>
							{display_companies}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={3}>

					<FormControl variant='outlined' required fullWidth>
						<InputLabel ref={seller_label} id='seller-label'>Продавець</InputLabel>
						<Select
							native
							labelId='seller-label'
							labelWidth={seller_label_width}
							value={local_state.seller_company_id}
						>
							{display_companies}
						</Select>
					</FormControl>

				</Grid>

				<Grid item xs={6} />

			</Grid>

			<ExternalSeriesTable
				rows={local_state.imported_rows}
				selected={[]}
				setSelected={() => { }}
			/>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={onImportHandler}

				>
					Імпорт
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={() => { props.history.push('/dam_prices/import') }}
				>
					Імпорт курсів
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className={classes.button_control}
					onClick={onCancelHandler}
				>
					Скасувати
				</Button>

			</div>

			<WaitDialog
				open={wait}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}