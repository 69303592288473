import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		width: '100%',
	},

	table_head: {
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'center',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'left',
		width: '70px'
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(1),
		width: '75px',
	},

}));


// Create number formatters
const curFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
});
const priFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 6,
	maximumFractionDigits: 6
});
const valFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 1,
	maximumFractionDigits: 1
});


export default function SummaryReport4(props) {
	const classes = useStyles();

	const { report } = props;

	return (
		<Paper className={classes.paper}>
			<TableContainer>
				{	(report.oes_sell && report.oes_sell.length > 0) &&
					<Table
						key={'sell_oes'}
						className={classes.table}
					>
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={7}
									className={classes.table_head}
								>
									Продаж в ОЕС
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									key='head_1'
									className={classes.table_head}
								>
									Продавець
								</TableCell>
								<TableCell
									key='head_2'
									className={classes.table_head}
								>
									Покупець
								</TableCell>
								<TableCell
									key='head_3'
									className={classes.table_head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									key='head_4'
									className={classes.table_head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_5'
									className={classes.table_head}
								>
									Сума без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_6'
									className={classes.table_head}
								>
									ПДВ, грн
								</TableCell>
								<TableCell
									key='head_7'
									className={classes.table_head}
								>
									Сума з ПДВ, грн
								</TableCell>
							</TableRow>
							{
								report.oes_sell.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:1` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.seller }
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:2` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.consumer }
													</TableCell>
												}
												<TableCell
													key={ `${row_index}.${group_index}:4` }
													className={ classes.cells }
												>
													{ valFormat.format(group.volume) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:5` }
													className={ classes.cells }
												>
													{ priFormat.format(group.price) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:6` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount) }
												</TableCell>
												<TableCell
													key={ `${row_index}:${group_index}:7` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount * 0.2) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:8` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount_vat) }
												</TableCell>
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell colSpan={7} />
							</TableRow>
						</TableBody>
					</Table>
				}
				{	(report.buos_sell && report.buos_sell.length > 0) &&
					<Table
						key={'sell_buos'}
						className={classes.table}
					>
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={7}
									className={classes.table_head}
								>
									Продаж в БУОС
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									key='head_1'
									className={classes.table_head}
								>
									Продавець
								</TableCell>
								<TableCell
									key='head_2'
									className={classes.table_head}
								>
									Покупець
								</TableCell>
								<TableCell
									key='head_3'
									className={classes.table_head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									key='head_4'
									className={classes.table_head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_5'
									className={classes.table_head}
								>
									Сума без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_6'
									className={classes.table_head}
								>
									ПДВ, грн
								</TableCell>
								<TableCell
									key='head_7'
									className={classes.table_head}
								>
									Сума з ПДВ, грн
								</TableCell>
							</TableRow>
							{
								report.buos_sell.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:1` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.seller }
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:2` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.consumer }
													</TableCell>
												}
												<TableCell
													key={ `${row_index}.${group_index}:4` }
													className={ classes.cells }
												>
													{ valFormat.format(group.volume) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:5` }
													className={ classes.cells }
												>
													{ priFormat.format(group.price) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:6` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount) }
												</TableCell>
												<TableCell
													key={ `${row_index}:${group_index}:7` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount * 0.2) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:8` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount_vat) }
												</TableCell>
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell colSpan={7} />
							</TableRow>
						</TableBody>
					</Table>
				}
				{	(report.oes_buy && report.oes_buy.length > 0) &&
					<Table
						key={'buy_oes'}
						className={classes.table}
					>
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={7}
									className={classes.table_head}
								>
									Купівля в ОЕС
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									key='head_1'
									className={classes.table_head}
								>
									Продавець
								</TableCell>
								<TableCell
									key='head_2'
									className={classes.table_head}
								>
									Покупець
								</TableCell>
								<TableCell
									key='head_3'
									className={classes.table_head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									key='head_4'
									className={classes.table_head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_5'
									className={classes.table_head}
								>
									Сума без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_6'
									className={classes.table_head}
								>
									ПДВ, грн
								</TableCell>
								<TableCell
									key='head_7'
									className={classes.table_head}
								>
									Сума з ПДВ, грн
								</TableCell>
							</TableRow>
							{
								report.oes_buy.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:1` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.seller }
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:2` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.consumer }
													</TableCell>
												}
												<TableCell
													key={ `${row_index}.${group_index}:4` }
													className={ classes.cells }
												>
													{ valFormat.format(group.volume) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:5` }
													className={ classes.cells }
												>
													{ priFormat.format(group.price) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:6` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount) }
												</TableCell>
												<TableCell
													key={ `${row_index}:${group_index}:7` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount * 0.2) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:8` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount_vat) }
												</TableCell>
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell colSpan={7} />
							</TableRow>
						</TableBody>
					</Table>
				}
				{	(report.buos_buy && report.buos_buy.length > 0) &&
					<Table
						key={'buy_buos'}
						className={classes.table}
					>
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={7}
									className={classes.table_head}
								>
									Купівля в БУОС
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									key='head_1'
									className={classes.table_head}
								>
									Продавець
								</TableCell>
								<TableCell
									key='head_2'
									className={classes.table_head}
								>
									Покупець
								</TableCell>
								<TableCell
									key='head_3'
									className={classes.table_head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									key='head_4'
									className={classes.table_head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_5'
									className={classes.table_head}
								>
									Сума без ПДВ, грн
								</TableCell>
								<TableCell
									key='head_6'
									className={classes.table_head}
								>
									ПДВ, грн
								</TableCell>
								<TableCell
									key='head_7'
									className={classes.table_head}
								>
									Сума з ПДВ, грн
								</TableCell>
							</TableRow>
							{
								report.buos_buy.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:1` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.seller }
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={ `${row_index}:${group_index}:2` }
														className={ classes.head }
														rowSpan={row.groups.length}
													>
														{ row.consumer }
													</TableCell>
												}
												<TableCell
													key={ `${row_index}.${group_index}:4` }
													className={ classes.cells }
												>
													{ valFormat.format(group.volume) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:5` }
													className={ classes.cells }
												>
													{ priFormat.format(group.price) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:6` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount) }
												</TableCell>
												<TableCell
													key={ `${row_index}:${group_index}:7` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount * 0.2) }
												</TableCell>
												<TableCell
													key={ `${row_index}.${group_index}:8` }
													className={ classes.cells }
												>
													{ curFormat.format(group.amount_vat) }
												</TableCell>
											</TableRow>
										))
									)
								})
							}
						</TableBody>
					</Table>
				}
			</TableContainer>
		</Paper>
	)
};


SummaryReport4.propTypes = {
	report: PropTypes.object.isRequired,
};