import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUploadSharp';
import DownloadIcon from '@material-ui/icons/CloudDownloadSharp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import DamOrdersTable from './subcomponents/DamOrdersTable';
import DamBlocksView from './subcomponents/DamBlocksView';
import DamXmtradeResultsDialog from './subcomponents/DamXmtradeResultsDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { damContext } from './damContext';
import { is_valid_date } from '../utils/validation';
import { getDateFormat, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	grid: {
		marginTop: theme.spacing(1),
		flexGrow: 1,
	},

	position_parent: {
		position: 'relative',
		textAlign: 'center',
	},

	header: {
		margin: theme.spacing(1, 0, 1, 0),
		display: 'inline-block',
	},

	right_button: {
		display: 'inline-block',
		position: 'absolute',
		right: theme.spacing(1),
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

}));

var cyclic_update_id;
var online_orders = [];

export default function DamOrderDownloadForm(props) {
	const classes = useStyles();

	const { offline_orders_selected, setOfflineOrdersSelected } = props;

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_dialog = {
		show: false,
		reasons: [],
		results: [],
	}
	const [dialog, setDialog] = useState(initial_dialog);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	const [online_orders_selected, setOnlineOrdersSelected] = React.useState([]);

	// import context
	const { global_state, setGlobalState, list_order } = useContext(damContext);


	function createInitialState() {

		const complex_state = {

			offline_orders: [],
			upload_disabled: false,
			download_disabled: false,
			trade_date: props.date,
			cyclic: false,
		}

		return complex_state;
	}


	function changeCheckboxHandle(event) {

		const event_target = event.target.checked;
		setLocalState(prev_state => ({ ...prev_state,
			cyclic: event_target,
		}));
	}


	async function follow_link(link) {

		try {

			setLocalState(prev_state => ({ ...prev_state,
				upload_disabled: true,
			}));

			const response = await axios.post(link);
			if (response.status === 200) {

				setLocalState(prev_state => ({...prev_state,
					upload_disabled: false,
				}));

				setDialog(prev_state => ({ ...prev_state,
					show: true,
					reasons: response.data.reasons,
					results: response.data.results,
				}));
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function UploadOrder() {

		if (offline_orders_selected.length === 1 && online_orders_selected.length === 0) {

			if (!window.confirm('Ви впевнені, що бажаєте відправити заявку?')) {
				return;
			}

			const local_id = parseInt(offline_orders_selected[0]);
			follow_link('/api/dam/xmtrade/' + local_id + '/upload');
		}

		if (offline_orders_selected.length === 1 && online_orders_selected.length === 1) {

			if (!window.confirm('Ви впевнені, що бажаєте відправити заявку?')) {
				return;
			}

			const local_id = parseInt(offline_orders_selected[0]);
			const remote_id = parseInt(online_orders_selected[0]);
			follow_link('/api/dam/xmtrade/' + local_id + '/modify/' + remote_id);
		}

		if (offline_orders_selected.length === 0) {
			window.alert('не вибрана заявка для відправлення.')
		}
	}


	async function DownloadXmtradeOrders() {

		try {

			setLocalState(prev_state => ({ ...prev_state,
				download_disabled: true,
			}));

			const payload = {
				trade_date: getDateFormat(local_state.trade_date),
				cyclic: false,
			}

			const response = await axios.get('/api/dam/xmtrade/own_orders', { params: payload });
			if (response.status === 200) {

				online_orders = response.data.results;

				setDialog(prev_state => ({ ...prev_state,
					show: true,
					reasons: response.data.reasons,
					results: response.data.results,
				}));

				setLocalState(prev_state => ({ ...prev_state,
					download_disabled: false,
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function ShowOnlineOrder(order_id) {

		const order = online_orders.filter(item => item.order_id === order_id);

		setDialog(prev_state => ({ ...prev_state,
			show: true,
			reasons: [],
			results: order,
		}));
	}


	async function DeleteXmtradeOrder(remote_id) {

		if (!window.confirm('Ви впевнені, що бажаєте видалити заявку з сервера?')) {
			return;
		}

		try {

			const order_to_remove = online_orders.find(item => item.order_id === remote_id);
			const trade_type = global_state.trade_types.find(item => item.type_comment === order_to_remove.trade_type);
			const acceptance = global_state.acceptance_types.find(item => item.type_comment === order_to_remove.acceptance_type);

			const payload = {
				remote_id: remote_id,
				domain: order_to_remove.domain,
				trade_date: order_to_remove.trade_date,
				trade_type: trade_type !== undefined ? trade_type.type_code : '',
				acceptance: acceptance !== undefined ? acceptance.type_code : '',
			}

			const response = await axios.post('/api/dam/xmtrade/0/delete', payload);

			if (response.status === 200) {

				online_orders = online_orders.filter(item => item.order_id !== remote_id);

				setDialog(prev_state => ({ ...prev_state,
					show: true,
					reasons: response.data.reasons,
					results: response.data.results,
				}));
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function handleOrderAction(action_id, order_id) {

		switch (action_id) {

			case 0:
				ShowOnlineOrder(order_id);
				break;
			case 1:
				DeleteXmtradeOrder(order_id);
				break;
			default:
				break;
		}
	}


	function onDialogClose() {

		setOfflineOrdersSelected([]);

		setGlobalState(state => ({ ...state,
			update_flag: !state.update_flag,
		}));

		setDialog(prev_state => ({...prev_state,
			show: false,
			reasons: [],
			results: [],
		}));
	}


	function onErrorMessageClose() {

		setMessage(message => ({...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	function get_selected_order_rows() {

		var result = {
			hours_in_order: 24,
			rows: [],
		}

		var order;

		if (offline_orders_selected.length === 1) {
			order = local_state.offline_orders.find(item => item.order_id === offline_orders_selected[0]);
		}

		if (order !== undefined) {

			result.hours_in_order = getHoursInDate(order.trade_date);
			result.rows = order.rows.map(item => {
				return ({
					key: item.row_id,
					id: item.row_id,
					block_number: item.block_number,
					period: item.period,
					amount: item.amount,
					price: item.price,
					splitting_type_id: (item.splitting_type_id !== null) ? item.splitting_type_id : undefined,
				})
			})
		}

		return result;
	}


	useEffect(() => {

		let is_subscribed = true;

		if (is_valid_date(local_state.trade_date)) {

			axios.get('/api/dam', {
				params: {
					period_start:	getDateFormat(local_state.trade_date),
					period_end:		getDateFormat(local_state.trade_date)
				}
			})
				.then(response => {

					if (is_subscribed) {
						let drafts = response.data.orders_list.filter(item => item.status_id <= 2);
						setLocalState(prev_state => ({ ...prev_state,
							offline_orders: drafts,
						}));
					}

				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});
		}

		return () => is_subscribed = false;

	}, [local_state.trade_date])


	// cyclic update functionality
	useEffect(() => {

		async function update_online_orders() {

			try {
	
				const payload = {
					trade_date: getDateFormat(local_state.trade_date),
					cyclic: true,
				}
	
				const response = await axios.get('/api/dam/xmtrade/own_orders', { params: payload });
				if (response.status === 200) {
	
					online_orders = response.data.results;
	
					setLocalState(prev_state => ({ ...prev_state,
						download_disabled: false,
					}));
				}
	
			} catch (error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;
	
					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}
	
				console.log(error);
			}
		}

		if (local_state.trade_date !== undefined) {

			if (local_state.cyclic && cyclic_update_id === undefined) {

				// start cyclic update
				cyclic_update_id = setInterval(update_online_orders, 5 * 1000);
			}
			
			if (!local_state.cyclic && cyclic_update_id !== undefined) {
	
				// stop cyclic update
				clearInterval(cyclic_update_id);
				cyclic_update_id = undefined;
			}
		}

	}, [local_state.cyclic, local_state.trade_date])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({ ...message,
							show: true,
							info: `${first_name} ${last_name} \n ${email} \n ${phone}`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	var offline_orders_rows = [];
	var online_orders_rows = [];
	if ((global_state.domains.length > 0) && (global_state.trade_types.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		if (local_state.offline_orders.length > 0) {
			offline_orders_rows = local_state.offline_orders.map(item => list_order(item));
		}

		if (online_orders.length > 0) {
			online_orders_rows = online_orders.map(item => list_order(item));
		}
	}


	const { hours_in_order, rows } = get_selected_order_rows();


	return (

		<div className={classes.grid}>

			<Grid container spacing={2}>

				<Grid item xs={6}>

					<div className={ classes.position_parent }>

						<Typography className={classes.header}>
							Заявки РДН
						</Typography>

						<Tooltip title='Передати заявку в XMtrade'>
							<IconButton
								className={classes.right_button}
								onClick={() => UploadOrder()}
								disabled={local_state.upload_disabled}
								color='inherit'
							>
								<UploadIcon />
							</IconButton>
						</Tooltip>

					</div>

				</Grid>

				<Grid item xs={6}>

					<div className={ classes.position_parent }>

						<Typography className={classes.header}>
							Заявки РДН в XMtrade
						</Typography>

						<div className={classes.right_button}>

							<Tooltip title='Завантажувати циклічно'>
								<Checkbox
									checked={local_state.cyclic}
									onChange={changeCheckboxHandle}
								/>
							</Tooltip>

							<Tooltip title='Завантажити заявки з XMtrade'>
								<IconButton
									onClick={() => DownloadXmtradeOrders()}
									disabled={local_state.download_disabled}
									color='inherit'
								>
									<DownloadIcon />
								</IconButton>
							</Tooltip>

						</div>

					</div>

				</Grid>

				<Grid item xs={6}>

					<DamOrdersTable
						rows={offline_orders_rows}
						selected={offline_orders_selected}
						setSelected={setOfflineOrdersSelected}
					/>

				</Grid>

				<Grid item xs={6}>

					<DamOrdersTable
						rows={online_orders_rows}
						selected={online_orders_selected}
						setSelected={setOnlineOrdersSelected}
						action_list={[
							{ id: 0, name: 'Відкрити' },
							{ id: 1, name: 'Видалити' }
						]}
						onActionSelect={handleOrderAction}
					/>

				</Grid>

				<Grid item xs={12}>

					<div className={classes.input_form_control}>

						{
							(rows.length > 0) &&
							<DamBlocksView
								columns_count={hours_in_order}
								rows={rows}
							/>
						}

					</div>

				</Grid>

			</Grid>

			<DamXmtradeResultsDialog
				open={dialog.show}
				onClose={onDialogClose}
				reasons={dialog.reasons}
				results={dialog.results}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={message.info}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}