import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/EditOutlined';

import { getLocalDateString, getDayOfWeek, getHoursInDate } from '../../utils/date_functions';
import { is_valid_float } from '../../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(2),
	},

	table: {
		tableLayout: 'auto',
	},

	row_root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},

	head_cell: {
		background: 'white',
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell_min: {
		minWidth: '30px',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

	result_cell_pos: {
		background: 'lightgray',
		color: 'black',
		fontWeight: '999',
		padding: theme.spacing(1, 0, 1, 0),
	},

	result_cell_neg: {
		background: 'lightgray',
		color: 'red',
		fontWeight: '999',
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


function formatFloat(value) {

	if (is_valid_float(value)) {

		let ret_val = value.toFixed(1);
		if (ret_val === '-0.0') {
			return '0.0';
		}

		return ret_val;

	} else {
		return value;
	}
}


function DetailTable(props) {

	const classes = props.style;
	const { head_cells, rows, onRowClick } = props;

	return (
		<TableContainer>

			{
				(rows.length > 0) &&
				<Table
					size={'small'}
				>

					<TableBody
						key='detail-table'
					>
						<TableRow
							key='detail-header'
						>
							{
								head_cells.map((item, head_index) => (
									<TableCell
										key={item.id}
										align={(head_index === 0) ? 'left' : 'right'}
										className={classes.head_cell}
									>
										{item.name}
									</TableCell>
								))
							}
						</TableRow>

						{
							rows.map((row, row_index) => (
								<TableRow
									key={`row_${row_index}`}
									hover
								>
									{
										row.cells.map((cell, cell_index) => (
											<TableCell
												key={`cell_${cell_index}`}
												align={(cell_index === 0) ? 'left' : 'right'}
												className={classes.slim_cell}
											>
												{cell.value}
											</TableCell>
										))
									}
									<TableCell>
										<IconButton
											size='small'
											onClick={() => onRowClick(row)}
										>
											<EditIcon fontSize='small' />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						}

					</TableBody>

				</Table>
			}
			{
				(rows.length === 0) &&
				'Додаткова інформація відсутня'
			}

		</TableContainer>
	)
}


function ExpandRow(props) {

	const classes = props.style;
	const { row, row_count, index, details_head_cells, details_rows, onRowClick } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow
				hover
				key={row.id}
			>
				{
					(index === 0) &&
					<TableCell
						key={`${row.id}-date`}
						className={classes.head_cell}
						width='100px'
						// span first cell on row count + 2 detail rows for each 3 rows
						rowSpan={Math.max(5, row_count + 2 * row_count / 3)}
					>
						{`${getLocalDateString(row.date)} ${getDayOfWeek(row.date)}`}
					</TableCell>
				}
				{
					(index % 3 === 0) &&
					<TableCell
						key={`${row.id}-info`}
						className={classes.head_cell}
						width='200px'
						align='center'
						rowSpan={5}
					>
						{
							(row_count > 1) &&
							<table>
								<tbody>
									<tr>
										<td rowSpan={4}>
											{row.info?.out_domain}
											<br />
											<small>{row.info?.seller}</small>
										</td>
										<td>
											{row.info?.import}
										</td>
										<td rowSpan={4}>
											{row.info?.in_domain}
											<br />
											<small>{row.info?.consumer}</small>
										</td>
									</tr>
									<tr>
										<td>&#10230;</td>
									</tr>
									<tr>
										<td>&#10229;</td>
									</tr>
									<tr>
										<td>{row.info?.export}</td>
									</tr>
								</tbody>
							</table>
						}
					</TableCell>
				}
				{
					row.cells.map((cell, cell_index) => (
						<TableCell
							key={cell.id}
							align='right'
							className={
								((index - 2) % 3 === 0) ?
									// for index = 2, 5, 8, 11
									(cell.value < 0) ? classes.result_cell_neg : classes.result_cell_pos
									:
									// for index != 2, 5, 8, 11
									(cell_index < 25) ? classes.slim_cell_min : classes.slim_cell
							}
						>
							{cell.value}
							{
								(cell_index === (row.cells.length - 1) && ((index - 2) % 3 !== 0)) &&
								<IconButton
									size='small'
									onClick={() => setOpen(!open)}
								>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						</TableCell>
					))
				}
			</TableRow>
			{
				((index - 2) % 3 !== 0) &&
				<TableRow
					className={(open) ? undefined : classes.row_root}
				>
					<TableCell
						style={{ padding: 0 }}
						colSpan={26}
					>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box>
								<DetailTable
									style={classes}
									head_cells={details_head_cells}
									rows={details_rows}
									onRowClick={onRowClick}
								/>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			}
		</React.Fragment>
	)
}


export default function ExternalSeriesTable(props) {

	const classes = useStyles();
	const { period_start, period_end, rows, onOpenRequest } = props;

	// calculate number of days
	let days = Math.round((new Date(period_end) - new Date(period_start)) / 3.6e6 / 24) + 1;
	if (isNaN(days)) {
		days = 2;
	}

	// calculate max column count
	var max_column_number = 24;
	for (let d = 0; d < days; d++) {

		const row_date = new Date(period_start);
		row_date.setHours(24 * d, 0, 0, 0);
		const current_max_hours = getHoursInDate(row_date);

		if (current_max_hours > max_column_number) {
			max_column_number = current_max_hours;
		}
	}

	// create header arrays
	const main_head_cells = [
		{ id: 'date', alignTo: 'left', disablePadding: true, name: 'Дата' },
		{ id: 'direction', alignTo: 'center', disablePadding: true, name: 'Інформація' },
	];
	const details_head_cells = [
		{ id: 'id', alignTo: 'right', disablePadding: true, name: 'ID' },
	];
	for (let i = 1; i <= max_column_number; i++) {
		main_head_cells.push({ id: i, alignTo: 'right', disablePadding: false, name: i.toString() });
		details_head_cells.push({ id: i, alignTo: 'right', disablePadding: false, name: i.toString() });
	}
	main_head_cells.push({ id: 'sum', alignTo: 'right', disablePadding: false, name: 'Всього' });
	main_head_cells.push({ id: 'action', alignTo: 'right', disablePadding: false, name: '' });
	details_head_cells.push({ id: 'sum', alignTo: 'right', disablePadding: false, name: 'Всього' });

	// search for specific values in rows
	const values = [];
	for (let d = 0; d < days; d++) {

		const this_date = new Date(period_start);
		this_date.setHours(24 * d, 0, 0, 0);

		values[d] = {
			trade_date: this_date,
			data: []
		}

		// filter rows by date
		const these_rows = rows.filter(a => {

			const row_date = new Date(a.trade_date);

			return row_date.getFullYear() === this_date.getFullYear() &&
				row_date.getMonth() === this_date.getMonth() &&
				row_date.getDate() === this_date.getDate()
		});

		// if something found - add specific info, else - placeholder
		if (these_rows.length > 0) {

			for (let i = 0; i < these_rows.length; i++) {

				let is_import = (these_rows[i].in_domain_id < 3);

				if (is_import) {

					const this_value = {
						in_domain_id: these_rows[i].in_domain_id,
						in_domain: these_rows[i].in_domain,
						out_domain_id: these_rows[i].out_domain_id,
						out_domain: these_rows[i].out_domain,
						seller_id: these_rows[i].seller_id,
						seller: these_rows[i].seller,
						consumer_id: these_rows[i].consumer_id,
						consumer: these_rows[i].consumer,
						import: [these_rows[i]],
						export: []
					}

					const index = values[d].data.findIndex(a =>
						a.in_domain_id === this_value.in_domain_id &&
						a.out_domain_id === this_value.out_domain_id &&
						a.seller_id === this_value.seller_id &&
						a.consumer_id === this_value.consumer_id);

					if (index === -1) {
						values[d].data.push(this_value);
					} else {
						values[d].data[index].import.push(these_rows[i]);
					}

				} else {

					const this_value = {
						in_domain_id: these_rows[i].out_domain_id,
						in_domain: these_rows[i].out_domain,
						out_domain_id: these_rows[i].in_domain_id,
						out_domain: these_rows[i].in_domain,
						seller_id: these_rows[i].consumer_id,
						seller: these_rows[i].consumer,
						consumer_id: these_rows[i].seller_id,
						consumer: these_rows[i].seller,
						import: [],
						export: [these_rows[i]]
					}

					const index = values[d].data.findIndex(a =>
						a.in_domain_id === this_value.in_domain_id &&
						a.out_domain_id === this_value.out_domain_id &&
						a.seller_id === this_value.seller_id &&
						a.consumer_id === this_value.consumer_id);

					if (index === -1) {
						values[d].data.push(this_value);
					} else {
						values[d].data[index].export.push(these_rows[i]);
					}
				}
			}
		}
	}

	// create blocks for each day
	const day_blocks = [];
	for (let d = 0; d < values.length; d++) {

		const current_max_hours = getHoursInDate(values[d].trade_date);

		// row index 
		let row_index = 0;
		const day_rows = [];

		// in case the item is placeholder, just display one empty row
		if (values[d].data.length === 0) {

			// cells for the row
			const day_cells = [];
			for (let c = 0; c < max_column_number + 2; c++) {

				day_cells[c] = {
					id: `cell_${c}`,
					value: (c < max_column_number + 1) ? formatFloat(0) : '',
				}
			}

			day_rows[row_index] = {
				id: row_index,
				date: values[d].trade_date,
				info: undefined,
				cells: day_cells,
				details_head_cells: details_head_cells,
				details_rows: [],
			}

			row_index++;
		}

		// in case of normal item there will be 3 rows for each domain - contractors
		if (values[d].data.length > 0) {

			// for each item in specific values - that is pair of contractors shall be 3 rows
			for (let j = 0; j < values[d].data.length; j++) {

				// balance row - 3rd row
				const balance_row = [];
				for (let i = 0; i < max_column_number + 1; i++) {
					balance_row[i] = 0;
				}

				for (let r = 0; r < 3; r++) {

					let row_name, detail_rows;

					// get cells for detail rows and row name
					switch (r) {
						case 0:
							row_name = 'Імпорт';
							detail_rows = [];

							values[d].data[j].import.forEach(item => {

								let sum = 0;
								const row_cells = [];
								row_cells.push({
									id: 'id',
									value: item.id,
								});

								for (let i = 1; i <= max_column_number + 1; i++) {

									if (item.points[i - 1] !== undefined) {
										const value = parseFloat(item.points[i - 1].value);
										sum += value;
										row_cells.push({
											id: (i < 10) ? `p0${i}` : `p${i}`,
											value: value.toFixed(1)
										});
									}
								}

								if (current_max_hours < max_column_number) {
									row_cells.push({
										id: 'decoy',
										value: '',
									});
								}

								row_cells.push({
									id: 'sum',
									value: sum.toFixed(1),
								});

								detail_rows.push({
									id: item.id,
									trade_date: item.trade_date,
									seller_company_id: item.seller_company_id,
									consumer_company_id: item.consumer_company_id,
									cells: row_cells,
								})
							})
							break;
						case 1:
							row_name = 'Експорт';
							detail_rows = [];

							values[d].data[j].export.forEach(item => {

								let sum = 0;
								const row_cells = [];
								row_cells.push({
									id: 'capacity',
									value: item.id,
								});

								for (let i = 1; i <= max_column_number + 1; i++) {

									if (item.points[i - 1] !== undefined) {
										const value = parseFloat(item.points[i - 1].value);
										sum += value;
										row_cells.push({
											id: (i < 10) ? `p0${i}` : `p${i}`,
											value: value.toFixed(1)
										});
									}
								}

								if (current_max_hours < max_column_number) {
									row_cells.push({
										id: 'decoy',
										value: '',
									});
								}

								row_cells.push({
									id: 'sum',
									value: sum.toFixed(1)
								});

								detail_rows.push({
									id: item.id,
									trade_date: item.trade_date,
									seller_company_id: item.seller_company_id,
									consumer_company_id: item.consumer_company_id,
									cells: row_cells,
								})
							});
							break;
						case 2:
							row_name = 'Сальдо';
							detail_rows = [];
							break;
						default:
							break;
					}

					// get cells for each of 3 rows
					const result_row = [];
					for (let c = 0; c <= max_column_number; c++) {
						result_row[c] = 0;
					}

					detail_rows.forEach(item => {

						for (let c = 1; c <= max_column_number + 1; c++) {

							if (item.cells[c]) {

								const value = parseFloat(item.cells[c].value);
								if (!isNaN(value)) {
									result_row[c - 1] += value;

									switch (r) {
										case 0:
											balance_row[c - 1] += value;
											break;
										case 1:
											balance_row[c - 1] -= value;
											break;
										default:
											break;
									}
								}
							}
						}
					});

					const day_cells = [];
					for (let c = 0; c < max_column_number + 2; c++) {
						day_cells[c] = {
							id: `cell_${c}`,
							value: (c === max_column_number + 1) ? row_name : (r < 2) ? formatFloat(result_row[c]) : formatFloat(balance_row[c]),
						}
					}

					// constructing overal info for second cell
					const info = {
						...values[d].data[j],
						import: (r === 0) ? day_cells[24].value : 0,
						export: (r === 1) ? day_cells[24].value : 0
					};

					if (r === 1) {
						day_rows[row_index - 1].info.export = day_cells[24].value;
					}

					day_rows[row_index] = {
						id: row_index,
						date: values[d].trade_date,
						info: info,
						cells: day_cells,
						details_head_cells: details_head_cells,
						details_rows: detail_rows,
					}

					row_index++;
				}
			}
		}

		day_blocks[d] = {
			id: `block-${d}`,
			rows: day_rows,
		}
	}


	console.log(day_blocks);


	return (
		<TableContainer className={classes.paper}>

			<Table size={'small'} className={classes.table} >

				<TableBody
					key='header_body'
				>
					<TableRow
						key='header_row'
					>
						{
							main_head_cells.map(item => (
								<TableCell
									key={item.id}
									align={item.alignTo}
									className={classes.head_cell}
								>
									{item.name}
								</TableCell>
							))
						}
					</TableRow>
				</TableBody>

				{
					day_blocks.map(block => (
						<TableBody
							key={block.id}
						>
							{
								block.rows.map((row, row_index) => (
									<ExpandRow
										key={`row-${row.id}`}
										style={classes}
										row_count={block.rows.length}
										index={row_index}
										row={row}
										details_head_cells={row.details_head_cells}
										details_rows={row.details_rows}
										onRowClick={onOpenRequest}
									/>
								))
							}
						</TableBody>
					))
				}

			</Table>
		</TableContainer>
	);
}


ExternalSeriesTable.propTypes = {
	period_start: PropTypes.string.isRequired,
	period_end: PropTypes.string.isRequired,
	rows: PropTypes.array.isRequired,
	onOpenRequest: PropTypes.func.isRequired,
};