import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import OpenIcon from '@material-ui/icons/FolderOpenSharp';
import SendIcon from '@material-ui/icons/SendSharp';
import RecallIcon from '@material-ui/icons/CancelScheduleSendSharp';
import ConfirmIcon from '@material-ui/icons/DoneSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import HistoryIcon from '@material-ui/icons/HistorySharp';
import MonthlyPlanIcon from '@material-ui/icons/DateRangeSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import TimeSeriesTable from './subcomponents/TimeSeriesTable';
import TimeSeriesEditForm from './TimeSeriesEditForm';
import FilterPeriod from '../subcomponents/FilterPeriod';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { bcContext } from './bcContext';

import HistoryDialog from '../subcomponents/HistoryDialog';
import NotAuthorised from '../auth/NotAuthorised';

import { is_valid_date } from '../utils/validation';
import { getDateFormat, getLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function TimeSeries(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_dialog = {
		show: false,
		rows: []
	}
	const [history_dialog, setHistoryDialog] = useState(initial_dialog);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// table variables
	const [draft_selected, setDraftSelected] = React.useState([]);
	const [send_selected, setSendSelected] = React.useState([]);
	const [active_selected, setActiveSelected] = React.useState([]);
	const [confirmed_selected, setConfirmedSelected] = React.useState([]);
	const [archived_selected, setArchivedSelected] = React.useState([]);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id:					`time-series-tab-${index}`,
			'aria-controls':	`time-series-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
			<div
				hidden={value !== index}
				id={`time-series-tabpanel-${index}`}
				aria-labelledby={`time-series-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {

		setDraftSelected([]);
		setActiveSelected([]);
		setSendSelected([]);
		setConfirmedSelected([]);
		setArchivedSelected([]);

		setTabNo(tab);
	}


	function createInitialState () {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			statuses: [],
			companies: [],
			// component states
			drafts_time_series: [],
			active_time_series: [],
			send_time_series: [],
			confirmed_time_series: [],
			archived_time_series: [],
		}

		return complex_state;
	}


	function createInitialFilter () {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(72, 0, 0, 0);

		const complex_state = {
			enable: false,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function handleAddTimeSeries() {
		props.history.push('/time_series/add');
	}


	function handleOpenTimeSeries(time_series_id) {

		if (time_series_id !== undefined) {
			props.history.push('/time_series/' + time_series_id);
		}

		OnTabChage(undefined, tab_no);
	}


	async function handleSendTimeSeries() {

		if (draft_selected.length > 0) {

			if (!window.confirm('Ви впевнені, що бажаєте відправити часові ряди на погодження?')) {
				return;
			}

			// create copy of selected ids to avoid changing state
			const ts_to_send = draft_selected.slice();
			const ts_not_send = [];
			const messages = [];
			while (ts_to_send.length > 0) {

				const id = parseInt(ts_to_send[0]);

				try {

					await axios.post('/api/bc/ts/' + id + '/send');

				} catch(error) {

					ts_not_send.push(id);

					const err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					messages.push(err_message + ' | ');

					console.log(error);

				} finally {
					ts_to_send.splice(0, 1);
				}
			}

			if (ts_not_send.length > 0) {

				setMessage(message => ({ ...message,
					show: true,
					message: `${ts_not_send.length} часових рядів не відправлено. Виникли помилки: ${messages}`,
				}));
			}

			setGlobalState(prev_state => ({ ...prev_state,
				update_flag: !prev_state.update_flag,
			}));

			setDraftSelected([]);
		}
	}


	async function handleDeleteTimeSeries() {

		if (draft_selected.length > 0) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити часові ряди?')) {
				return;
			}

			// create copy of selected ids to avoid changing state
			const ts_to_delete = draft_selected.slice();
			const ts_not_deleted = [];
			const messages = [];
			while (ts_to_delete.length > 0) {

				const id = parseInt(ts_to_delete[0]);

				try {

					await axios.post('/api/bc/ts/' + id + '/delete');

				} catch(error) {

					ts_not_deleted.push(id);

					const err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					messages.push(err_message + ' | ');

					console.log(error);

				} finally {
					ts_to_delete.splice(0, 1);
				}
			}

			if (ts_not_deleted.length > 0) {

				setMessage(message => ({ ...message,
					show: true,
					message: `${ts_not_deleted.length} часових рядів не відправлено. Виникли помилки: ${messages}`,
				}));
			}

			setGlobalState(prev_state => ({ ...prev_state,
				update_flag: !prev_state.update_flag,
			}));

			setDraftSelected([]);
		}
	}


	async function handleRecallTimeSeries() {

		if (send_selected.length > 0) {

			if (!window.confirm('Ви впевнені, що бажаєте відкликати часові ряди?')) {
				return;
			}

			// create copy of selected ids to avoid changing state
			const ts_to_recall = send_selected.slice();
			const ts_not_recalled = [];
			const messages = [];
			while (ts_to_recall.length > 0) {

				const id = parseInt(ts_to_recall[0]);

				try {

					await axios.post('/api/bc/ts/' + id + '/recall');

				} catch(error) {

					ts_not_recalled.push(id);

					const err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					messages.push(err_message + ' | ');

					console.log(error);

				} finally {
					ts_to_recall.splice(0, 1);
				}
			}

			if (ts_not_recalled.length > 0) {

				setMessage(message => ({ ...message,
					show: true,
					message: `${ts_not_recalled.length} часових рядів не відправлено. Виникли помилки: ${messages}`,
				}));
			}

			setGlobalState(prev_state => ({ ...prev_state,
				update_flag: !prev_state.update_flag,
			}));

			setSendSelected([]);
		}
	}


	async function handleConfirmTimeSeries() {

		if (active_selected.length > 0) {

			if (!window.confirm('Ви впевнені, що бажаєте погодити часові ряди?')) {
				return;
			}

			// create copy of selected ids to avoid changing state
			const ts_to_confirm = active_selected.slice();
			const ts_not_confirmed = [];
			const messages = [];
			while (ts_to_confirm.length > 0) {

				const id = parseInt(ts_to_confirm[0]);

				try {

					await axios.post('/api/bc/ts/' + id + '/confirm');

				} catch(error) {

					ts_not_confirmed.push(id);

					const err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					messages.push(err_message + ' | ');

					console.log(error);

				} finally {
					ts_to_confirm.splice(0, 1);
				}
			}

			if (ts_not_confirmed.length > 0) {

				setMessage(message => ({ ...message,
					show: true,
					message: `${ts_not_confirmed.length} часових рядів не погоджено. Виникли помилки: ${messages}`,
				}));
			}

			setGlobalState(prev_state => ({ ...prev_state,
				update_flag: !prev_state.update_flag,
			}));

			setActiveSelected([]);
		}
	}


	function handleOpenImportExportForm() {

		const selected_time_series_id = get_selected_time_series_id();
		const time_series_id = (selected_time_series_id !== undefined) ? selected_time_series_id : 0;

		props.history.push('/schedules/import/0/' + time_series_id);
	}


	async function handleOpenTimeSeriesHistory(time_series_id) {

		if (time_series_id === undefined) return;

		try {

			const id = parseInt(time_series_id);
			if (!isNaN(id)) {

				const response = await axios.get('/api/bc/ts/' + id + '/history');
				if (response.status === 200) {
					setHistoryDialog(dialog => ({ ...dialog,
						show: true,
						rows: response.data
					}));
				}
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}
	

	async function handleCreateCopyTimeSeries(time_series_id) {

		if (time_series_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте створити копію часового ряду?')) {
			return;
		}

		try {

			const id = parseInt(time_series_id);
			const response = await axios.post('/api/bc/ts/' + id + '/copy');

			if (response.status === 200) {

				setGlobalState(state => ({ ...state,
					update_flag: !state.update_flag,
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({
					...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function handleTimeSeriesAction(action_id, time_series_id) {

		switch (action_id) {

			case 0:
				handleOpenTimeSeries(time_series_id);
				break;
			case 2:
				handleOpenTimeSeriesHistory(time_series_id);
				break;
			case 3:
				handleCreateCopyTimeSeries(time_series_id);
				break;
			default:
				break;
		}
	}


	function onFilterChange(start, end) {
		setFilter(filter => ({ ...filter,
			period_start: start,
			period_end: end,
		}));
	}


	function onFilterClick() {
		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({ ...filter,
				enable: true,
			}));
		}
	}


	function onHistoryDialogClose() {
	
		setHistoryDialog(dialog => ({ ...dialog,
			show: false,
			rows: []
		}));
	}


	function onErrorMessageClose() {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	function get_selected_time_series_id() {

		let selected_id = undefined;

		switch(tab_no) {

			case 0:
				selected_id = draft_selected.length > 0 ? draft_selected[0] : undefined;
				break;

			case 1:
				selected_id = send_selected.length > 0 ? send_selected[0] : undefined;
				break;

			case 2:
				selected_id = active_selected.length > 0 ? active_selected[0] : undefined;
				break;

			case 3:
				selected_id = confirmed_selected.length > 0 ? confirmed_selected[0] : undefined;
				break;

			case 4:
				selected_id = archived_selected.length > 0 ? archived_selected[0] : undefined;
				break;

			default:
				break;
		}

		return selected_id;
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/bc');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({ ...prev_state,
					domains: res_market_defs.data.domain_list,
					statuses: res_market_defs.data.status_list,
					companies: res_company_data.data.company_list
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

			axios.get('/api/bc/ts', {
				params: {
					period_start:	getDateFormat(filter.period_start),
					period_end:		getDateFormat(filter.period_end)
				}
			})
				.then(response => {

					let drafts = response.data.time_series_list.filter(item => item.status_id === 1);
					let sends = response.data.time_series_list.filter(item => item.status_id === 2);
					let active = response.data.time_series_list.filter(item => item.status_id === 3);
					let confirmed = response.data.time_series_list.filter(item => item.status_id === 4);
					let archived = response.data.time_series_list.filter(item => item.status_id === 5);

					setGlobalState(prev_state => ({ ...prev_state,
						drafts_time_series: drafts,
						send_time_series: sends,
						active_time_series: active,
						confirmed_time_series: confirmed,
						archived_time_series: archived,
					}));

				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

				setFilter(filter => ({ ...filter, enable: false }));
		}

	}, [filter])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({ ...message,
							show: true,
							info: `${ first_name } ${ last_name } \n ${ email } \n ${ phone }`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}

	}, [message.edit])


	const list_time_series = (item) => {

		return ({
			key: item.time_series_id,
			id: item.time_series_id,
			domain: global_state.domains.find(elem => elem.domain_id === item.domain_id).domain_display_name,
			status: global_state.statuses.find(elem => elem.status_id === item.status_id).status_name,
			version: item.version,
			revision: item.revision,
			start: getLocalDateString(item.trade_date),
			consumer: global_state.companies.find(elem => elem.company_id === item.consumer_company_id).name,
			seller: global_state.companies.find(elem => elem.company_id === item.seller_company_id).name,
			amount: item.amount.toFixed(1),
		})
	}


	var drafts_time_series_rows = [];
	var send_time_series_rows = [];
	var active_time_series_rows = [];
	var confirmed_time_series_rows = [];
	var archived_time_series_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		if (global_state.drafts_time_series.length > 0) {
			drafts_time_series_rows = global_state.drafts_time_series.map(item => list_time_series(item))
		}

		if (global_state.send_time_series.length > 0) {
			send_time_series_rows = global_state.send_time_series.map(item => list_time_series(item))
		}

		if (global_state.active_time_series.length > 0) {
			active_time_series_rows = global_state.active_time_series.map(item => list_time_series(item))
		}

		if (global_state.confirmed_time_series.length > 0) {
			confirmed_time_series_rows = global_state.confirmed_time_series.map(item => list_time_series(item))
		}

		if (global_state.archived_time_series.length > 0) {
			archived_time_series_rows = global_state.archived_time_series.map(item => list_time_series(item))
		}
	}


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 8)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<bcContext.Provider value={{ global_state, setGlobalState }}>
				<Switch>
					<Route
						path='/time_series/add'
						component={ TimeSeriesEditForm }
					/>
					<Route
						path='/time_series/:time_series_id'
						component={ TimeSeriesEditForm }
					/>
					<Route
						path='/time_series'
					>

						<div className = { classes.root } >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Часові ряди
									</Typography>
									
									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={ handleAddTimeSeries }
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Відкрити'>
										<span>
											<IconButton
												onClick={() => handleOpenTimeSeries(get_selected_time_series_id())}
												disabled = { draft_selected.length +
															active_selected.length +
															send_selected.length +
															confirmed_selected.length +
															archived_selected.length !== 1 }
												color='inherit'
											>
												<OpenIcon />
											</IconButton>
										</span>
									</Tooltip>

									{ (tab_no === 0) && 
									<Tooltip title='Відправити на погодження'>
										<span>
											<IconButton
												onClick={ handleSendTimeSeries }
												disabled={ draft_selected.length === 0 }
												color='inherit'
											>
												<SendIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no === 0) && 
									<Tooltip title='Видалити'>
										<span>
											<IconButton
												onClick={ handleDeleteTimeSeries }
												disabled={ draft_selected.length === 0 }
												color='inherit'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no === 1) && 
									<Tooltip title='Відкликати'>
										<span>
											<IconButton
												onClick={ handleRecallTimeSeries }
												disabled={ send_selected.length === 0 }
												color='inherit'
											>
												<RecallIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no === 2) && 
									<Tooltip title='Погодити'>
										<span>
											<IconButton
												onClick={ handleConfirmTimeSeries }
												disabled={ active_selected.length === 0 }
												color='inherit'
											>
												<ConfirmIcon />
											</IconButton>
										</span>
									</Tooltip> }

									<Tooltip title='Імпорт / експорт'>
										<span>
											<IconButton
												onClick={handleOpenImportExportForm}
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Історія часового ряду'>
										<span>
											<IconButton
												onClick={() => handleOpenTimeSeriesHistory(get_selected_time_series_id())}
												color='inherit'
												disabled = { draft_selected.length +
															active_selected.length +
															send_selected.length +
															confirmed_selected.length +
															archived_selected.length !== 1 }
											>
												<HistoryIcon />
											</IconButton>
										</span>
									</Tooltip>

									<FilterPeriod
										period_start={filter.period_start}
										period_end={filter.period_end}
										onFilterChange={onFilterChange}
										onFilterClick={onFilterClick}
									/>

									<div className={classes.right_button} >

										<Tooltip title='Місячне плануання' >
											<IconButton
												onClick={ () => props.history.push('/planning/monthly_plan') }
												color='inherit'
											>
												<MonthlyPlanIcon />
											</IconButton>
										</Tooltip>

										<IconButton
											onClick={ () => props.history.push('/') }
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label={ <Badge badgeContent={global_state.drafts_time_series.length} color='primary'>Чернетки</Badge>} {...getTabsProps(0)} />
								<Tab label={ <Badge badgeContent={global_state.send_time_series.length} color='primary'>Відправлені</Badge>} {...getTabsProps(1)} />
								<Tab label={ <Badge badgeContent={global_state.active_time_series.length} color='primary'>Активні</Badge>} {...getTabsProps(2)} />
								<Tab label={ <Badge badgeContent={global_state.confirmed_time_series.length} color='primary'>Погоджені</Badge>} {...getTabsProps(3)} />
								<Tab label={ <Badge badgeContent={global_state.archived_time_series.length} color='primary'>Архівні</Badge>} {...getTabsProps(4)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<TimeSeriesTable
									rows={drafts_time_series_rows}
									selected={draft_selected}
									setSelected={ setDraftSelected }
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={1}>

								<TimeSeriesTable
									rows={send_time_series_rows}
									selected={send_selected}
									setSelected={setSendSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<TimeSeriesTable
									rows={active_time_series_rows}
									selected={active_selected}
									setSelected={setActiveSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={3}>

								<TimeSeriesTable
									rows={confirmed_time_series_rows}
									selected={confirmed_selected}
									setSelected={setConfirmedSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={4}>

								<TimeSeriesTable
									rows={archived_time_series_rows}
									selected={archived_selected}
									setSelected={setArchivedSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/>

							</TabPanel>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ message.info }
							onClose={ onErrorMessageClose }
						/>

						<HistoryDialog
							open={history_dialog.show}
							onClose={onHistoryDialogClose}
							rows={history_dialog.rows}
						/>

					</Route>
				</Switch>
			</bcContext.Provider>
		)
	}
}

export default withRouter(TimeSeries)