import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	container: {
		maxHeight: 400,
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
	},

	row_data: {
		background: '#D3D3D3',
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '75px',
	}

}));


export default function DamBlocksView(props) {
	const classes = useStyles();

	const { columns_count, results } = props;

	const head_cells = [];
	for (let h = 0; h <= columns_count; h++) {
		head_cells[h] = {
			id: h,
			label: (h !== 0) ? h : 'Період',
		};
	}

	const blocks_array = [];
	if (results) {

		for (let b = 0; b < results.length; b++) {

			// initialise rows
			const rows_array = [];
			for (let r = 0; r < 3; r++) {
	
				// initialise cells
				const cells_array = []; let row_text;
				for (let c = 0; c <= columns_count; c++) {

					let label_text, cell_value;
					const row_item = results[b].rows.find(row => row.period === c);

					if (c === 0) {
						switch (r) {
							case 0:
								label_text = 'Період';
								break;
							case 1:
								label_text = "Обсяг (МВтг)";
								row_text = (row_item !== undefined) ? row_item.amount : undefined;
								break;
							case 2:
								label_text = 'Ціна (грн/МВтг)';
								row_text = (row_item !== undefined) ? row_item.price : undefined;
								break;
							default:
								break;
						}
					} else {

						label_text = head_cells[c].label;
						switch (r) {
							case 1:
								cell_value = (row_item !== undefined) ? row_item.amount : '';
								break;
							case 2:
								cell_value = (row_item !== undefined) ? row_item.price : '';
								break;
							default:
								break;
						}
					}

					cells_array[c] = {
						id: `${b+1}.${r}.${c}`,
						label: (c === 0) || (r === 0) ? label_text : undefined,
						value: cell_value,
					}
				}

				rows_array[r] = {
					id: `${b+1}.${r}`,
					common_text: row_text,
					cells: cells_array,
				}
			}

			// initialise block
			blocks_array[b] = {
				id: b.toString(),
				trade_date: results[b].trade_date,
				domain: results[b].domain,
				category: results[b].result_type_name,
				rows: rows_array,
			};
		}
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer className={classes.container}>
				<Table size= {'small'} className={classes.table} >
					{
						blocks_array.map(block => (
							<TableBody
								key={ block.id }
							>
								<TableRow
									key={ `${block.id}:header_row` }
								>
									<TableCell
										key={ `${block.id}:day` }
										className={ classes.head }
									>
										Доба торгівлі
									</TableCell>

									<TableCell
										key={ `${block.id}:header_day` }
										className={ classes.head }
										align='center'
										colSpan={ columns_count / 3 - 2 }
									>
										{ block.trade_date }
									</TableCell>

									<TableCell
										key={ `${block.id}:domain` }
										className={ classes.head }
										colSpan={ 3 }
									>
										Зона торгівлі
									</TableCell>

									<TableCell
										key={ `${block.id}:header_domain` }
										className={ classes.head }
										align='center'
										colSpan={ columns_count / 3 - 2 }
									>
										{ block.domain }
									</TableCell>

									<TableCell
										key={ `${block.id}:type` }
										className={ classes.head }
										colSpan={ 3 }
									>
										Тип результатів
									</TableCell>

									<TableCell
										key={ `${block.id}:header_type` }
										className={ classes.head }
										align='center'
										colSpan={ columns_count / 3 - 2 }
									>
										{ block.category }
									</TableCell>
								</TableRow>

								{
									block.rows.map((row, row_index) => (
										<TableRow
											hover
											key={ row.id }
											className={ classes.row_data }
										>
											{
												row.cells.map((cell, cell_index) => (
													((row.common_text !== undefined && cell_index < 2) || (row.common_text === undefined)) &&
													<TableCell
														key={ cell.id }
														className={ (row_index === 0) || (cell_index === 0) ? classes.head : classes.cells }
													>
														{
															(row_index === 0) || (cell_index === 0) ? cell.label : (row.common_text === undefined) ? cell.value : row.common_text
														}
													</TableCell>
												))
											}
										</TableRow>
									))
								}
							</TableBody>
						))
					}
				</Table>
			</TableContainer>
		</Paper>
	)
};