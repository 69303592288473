import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function IdmResultsView(props) {

	const classes = useStyles();

	const { results } = props;

	const head_cells = [
		{ id: 'period_from', alignTo: 'left', label: 'Період з' },
		{ id: 'period_to', alignTo: 'left', label: 'Період до' },
		{ id: 'price', alignTo: 'right', label: 'Ціна' },
		{ id: 'amount', alignTo: 'right', label: 'Кількість' },
	];

	const blocks_array = [];
	if (results) {
		for (let b = 0; b < results.length; b++) {

			blocks_array[b] = {
				id: b,
				trade_date: results[b].trade_date,
				domain: results[b].domain,
				category: results[b].result_type_name,
				rows: results[b].rows,
			}
		}
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer>
				<Table size= {'small'} className={classes.table} >
					{
						blocks_array.map((block, block_index) => (
							<TableBody
								key={ block.id }
							>
								<TableRow
									key={ `${block.id}:info_row` }
								>

									<TableCell
										className={classes.head}
									>
										Доба торгівлі
									</TableCell>

									<TableCell>
										{ block.trade_date }
									</TableCell>

									<TableCell
										className={classes.head}
									>
										Зона торгівлі
									</TableCell>

									<TableCell>
										{ block.domain }
									</TableCell>

								</TableRow>

								<TableRow
									key={ `${block.id}:info_row_2` }
								>

									<TableCell
										className={classes.head}
									>
										Категорія
									</TableCell>

									<TableCell
										colSpan={3}
									>
										{ block.category }
									</TableCell>

								</TableRow>

								<TableRow
									key={ `${block.id}:header_row` }
								>
									{
										head_cells.map(cell => 
											<TableCell
												className={classes.head}
												key={`${block.id}:${cell.id}`}
												align={ cell.alignTo }
											>
												{cell.label}
											</TableCell>
										)
									}
								</TableRow>

								{
									block.rows.map((row, row_index) => (
										<TableRow
											hover
											key={ row_index }
										>
											<TableCell
												key={ `${row_index}:period_from` }
											>
												{row.period_from}
											</TableCell>

											<TableCell 
												key={ `${row_index}:period_to` }
											>
												{row.period_to}
											</TableCell>

											<TableCell
												key={ `${row_index}:price` }
												align='right'
											>
												{row.price}
											</TableCell>

											<TableCell
												key={ `${row_index}:amount` }
												align='right'
											>
												{row.amount}
											</TableCell>

										</TableRow>
									))
								}

								{
									( block_index < blocks_array.length - 1 ) &&
									<TableRow
										key={`${block.id}:footer_row` }
									>
										<TableCell
											colSpan={4}
										>

										</TableCell>
									</TableRow>
								}

							</TableBody>
						))
					}
				</Table>
			</TableContainer>
		</Paper>
	);
}