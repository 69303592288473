import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		textAlign: 'center',
	},

	title: {
		margin: theme.spacing(2),
	},

	table: {
		border: '1px solid red',
		minHeight: '300px',
	},

	control_margin: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function DocumentMmsUploadForm() {

	const classes = useStyles();

	// states for form controls
	const form_init_state = {
		request: '',
		response: ''
	};
	const [request, setRequest] = useState(form_init_state);


	function handleRequestChange(event) {

		const event_value = event.target.value;

		setRequest(local_state => ({ ...local_state,
			request: event_value
		}));
	}


	async function onSubmitHandler(e) {

		const payload = {
			document: request.request
		}

		const response = await axios.post('/api/bc/schedules/mms/request/test', payload);
		if (response.status === 200) {

			setRequest(local_state => ({ ...local_state,
				response: response.data
			}));

		} else {
			window.alert('Помилка.');
		}
	}


	return (

		<div className={classes.root} >

			<Typography variant='h6' noWrap className={classes.title} >
				Завантаження документу користувача до MMS
			</Typography>

			<Grid container spacing={2} className={classes.table}>

				<Grid item xs={6}>

					<TextField
						label='Ваше звернення'
						variant='outlined'
						multiline
						className={classes.control_margin}
						fullWidth
						value={request.request}
						onChange={handleRequestChange}
					/>

				</Grid>

				<Grid item xs={6}>

					<TextField
						label='Відповідь'
						variant='outlined'
						multiline
						className={classes.control_margin}
						fullWidth
						value={request.response}
					/>

				</Grid>

			</Grid>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={onSubmitHandler}
				>
					Завантажити документ
				</Button>

			</div>

		</div>
	)
}