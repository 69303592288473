import React, { useState } from 'react';

import { is_valid_float_input } from '../utils/validation';
import TextField from '@material-ui/core/TextField';


function NumericTextField(props) {

	const { value, onChange, onPaste, onKeyDown } = props;

	const initial_value = {
		valid: true,
	}
	const [state, setState] = useState(initial_value);


	function onTextChangeHandler (event) {

		// cache event value
		const event_value = event.target.value.replace(',', '.');

		setState(state => ({ ...state,
			valid: is_valid_float_input(event_value),
		}));

		let changed_event = Object.assign({}, event);
		changed_event.target.value = event_value;

		onChange(changed_event);
	}


	function onTextPaste (event) {
		onPaste(event);
	}


	return (

		<TextField
			size='small'
			error={ !state.valid }
			onChange={ onTextChangeHandler }
			onPaste={ onTextPaste }
			onKeyDown={ onKeyDown }
			value={ value }
		/>
	)
}

export default NumericTextField;