import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


export default function ProductionPlanTable(props) {

	const classes = useStyles();

	const { rows, selected, setSelected } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');


	const head_cells = [
		{ id: 'date', alignTo: 'left', disablePadding: true, label: 'Дата' },
		{ id: 'production_company', alignTo: 'center', disablePadding: false, label: 'Компанія' }
	];

	let max_hours = 23;
	let sum = [];
	let min = [];
	let max = [];
	rows.forEach(row => {

		// initialize array element
		sum[row.id] = 0;
		min[row.id] = 0;
		max[row.id] = 0;

		// calculate sum
		for (let i = 1; i <= 25; i++) {

			if (row.points[i] !== undefined) {
				if (i > max_hours) {
					max_hours = i;
				}
				sum[row.id] += row.points[i].value;
				min[row.id] += row.points[i].min;
				max[row.id] += row.points[i].max;
			}
		}
	})

	for (let i = 1; i <= max_hours; i++) {
		head_cells.push({ id: i, alignTo: 'right', disablePadding: false, label: i.toString() });
	}
	head_cells.push({ id: 'sum', alignTo: 'right', disablePadding: true, label: 'Всього' });
	head_cells.push({ id: 'min', alignTo: 'right', disablePadding: true, label: 'Мінімум' });
	head_cells.push({ id: 'max', alignTo: 'right', disablePadding: false, label: 'Максімум' });


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	}


	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	}


	const isSelected = (id) => selected.indexOf(id) !== -1;

	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={selected.length}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy)).map((row, row_index) => {

									const item_is_selected = isSelected(row.id);
									const label_id = `row-checkbox-${row_index}`;

									return (
										<TableRow
											hover
											role='checkbox'
											aria-checked={item_is_selected}
											key={row.key}
											selected={item_is_selected}
										>

											<TableCell padding='checkbox'>
												<Checkbox
													checked={item_is_selected}
													onClick={event => handleClick(event, row.id)}
													inputProps={{ 'aria-labelledby': label_id }}
												/>
											</TableCell>

											<TableCell
												id={label_id}
												key={ `row-${row_index}-date` }
												scope='row'
												padding='none'
											>
												{row.plan_date}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-company` }
												align='center'
												className={classes.slim_cell}
											>
												{row.production_company}
											</TableCell>

											{
												row.points.map((point, point_index) => (
													<TableCell
														key={ `row-${row_index}-${point_index}` }
														align='right'
														className={classes.slim_cell}
													>
														{ point.value }
													</TableCell>
												))
											}

											<TableCell
												key={ `row-sum-${row.key}` }
												align='right'
												className={classes.slim_cell}
											>
												{ sum[row.id].toFixed(1) }
											</TableCell>

											<TableCell
												key={ `row-min-${row.key}` }
												align='right'
												className={classes.slim_cell}
											>
												{ (min[row.id] / max_hours).toFixed(1) }
											</TableCell>

											<TableCell
												key={ `row-max-${row.key}` }
												align='right'
												className={classes.slim_cell}
											>
												{ (max[row.id] / max_hours).toFixed(1) }
											</TableCell>

										</TableRow>
									)
								})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}


ProductionPlanTable.propTypes = {
	rows: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
};