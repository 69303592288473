import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';


const useStyles = makeStyles(theme => ({

	frame: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.common.white,
		textAlign: 'center'
	},

	icon: {
		fontSize: 24,
	},

	message: {
		fontSize: 24,
	},

	info: {
		fontSize: 16,
	},

}));


function MessageErrorSnackbar(props) {
	const classes = useStyles();


	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);


	function handleClose (event, reason) {
		if (reason === 'clickaway') {
			return;
		}

		props.onClose();
	};


	return (

		<Snackbar
			open={ props.open }
			autoHideDuration={ 10000 }
			onClose={ handleClose }
		>
			<SnackbarContent
				aria-describedby='client-snackbar'
				className = { classes.frame }
				message={
					<div
						id='client-snackbar'
						className={classes.frame}
					>
						<p>
							<ErrorIcon className={classes.icon} />
						</p>
						<p className={classes.message}>
							{ props.message }
						</p>
						<p className={classes.info}>
							{ props.info }
						</p>

					</div>
				}
				action={action}
			/>
		</Snackbar>
	)
}

export default MessageErrorSnackbar;


MessageErrorSnackbar.propTypes = {
	open: PropTypes.bool.isRequired,
	message: PropTypes.string.isRequired,
	info: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};