import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import GroupRowsTable from './subcomponents/GroupRowsTable';
import EditPlanRowsTable from './subcomponents/EditPlanRowsTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import { planningContext } from './planningContext';
import { is_valid_date } from '../utils/validation';
import { getDateFormat, getDaysInMonth } from '../utils/date_functions';
import { PlanRowsToCells } from '../utils/plan_row_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: '100%',
	},

	form_control: {
		marginTop: theme.spacing(8),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(2),
		display: 'inline-block'
	},

	control_top_margin: {
		marginTop: theme.spacing(2),
	},

	group_table: {
		height: '400px',
		marginLeft: theme.spacing(2),
	},

}));


function ComponentEditMonthlyPlanning(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);
	const [wait, setWait] = useState(false);

	// filer state
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const import_tab_label = React.useRef(null);
	const domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);

	// state for label widths
	const [domain_label_width, setDomainLabelWidth] = useState(0);

	// table variables
	const [selected_groups, setSelectedGroups] = useState([]);
	const [selected_group_rows, setSelectedGroupRows] = useState([]);

	// import context
	const { global_state } = useContext(planningContext);


	function createInitialFilter() {

		var day_1 = new Date();
		day_1.setDate(1);

		var day_2 = new Date();
		day_2.setDate(getDaysInMonth(day_1));

		const complex_state = {
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
			domain_id: 0,
			consumer_company: null,
			seller_company: null
		};

		return complex_state;
	}


	function createInitialState() {

		var today = new Date();
		today.setHours(0, 0, 0, 0);

		const complex_state = {

			groups: [],
			group_rows: [],

			rows_changed: false,
			update_flag: false,
		}

		return complex_state;
	}


	function handlePeriodStartChange(event) {

		const event_value = event.target.value;

		setFilter(prev_state => ({ ...prev_state,
			period_start: event_value,
		}));
	}


	function handlePeriodEndChange(event) {

		const event_value = event.target.value;

		setFilter(prev_state => ({ ...prev_state,
			period_end: event_value,
		}));
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setFilter(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
		}));
	}


	function handleConsumerChange(event, option) {

		setFilter(prev_state => ({ ...prev_state,
			consumer_company: option,
		}));
	}


	function handleSellerChange(event, option) {

		setFilter(prev_state => ({ ...prev_state,
			seller_company: option,
		}));
	}


	function handleOpenGroup(group_id) {

		if (group_id !== undefined) {
			props.history.push('/planning/plan_groups/' + group_id);
		}
	}


	async function handleDeleteGroup(group_id) {

		if (!isNaN(group_id)) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити вибрану групу?')) {
				return;
			}

			try {

				const response = await axios.post('/api/planning/group/' + group_id + '/delete');
				if (response.status === 200) {

					setLocalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));

					window.alert('Група видалена.');
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	async function handleExportGroup(group_id) {

		if (!isNaN(group_id)) {

			// find data to export
			const group_rows = local_state.group_rows.filter(item => item.group_id === group_id);
			const group = local_state.groups.find(item => item.group_id === group_id);

			if (group === undefined || group_rows.length === 0) {
				window.alert('Переконайтесь, що ви вибрали группу, що бажаєте експортувати та бачите ряди групи.');
				return;
			}

			if (!window.confirm('Ви впевнені, що бажаєте експортувати вибрану групу разом із рядами?')) {
				return;
			}

			const consumer = global_state.companies.find(item => item.company_id === group.consumer_company_id);
			const seller = global_state.companies.find(item => item.company_id === group.seller_company_id);
			const domain = global_state.domains.find(item => item.domain_id === group.domain_id);

			// json export data
			const export_group = {
				consumer: (consumer !== undefined) ? consumer.eic_x_code : '',
				seller: (consumer !== undefined) ? seller.eic_x_code : '',
				domain: (domain !== undefined) ? domain.domain_display_name : '',

				payment_days: group.payment_days,
				price: (group.price !== null) ? group.price : '',
				discount: (group.discount !== null) ? group.discount : '',
				discount_percent: (group.discount_percent !== null) ? group.discount_percent : '',
				comment: (group.comment !== null) ? group.comment : '',

				rows: group_rows
			}

			try {

				const temp_xlsx_doc = await axios.get('/docs/templates/Month_Planning.xlsx', { responseType: 'arraybuffer' });
				const wb = XLSX.read(temp_xlsx_doc.data, { type: 'array', cellStyles: true });
				const sheet = wb.Sheets[wb.SheetNames[0]];

				// make changes to a template sheet
				const update_commands = PlanRowsToCells(export_group);
				update_commands.forEach(item => {

					const cell_ref = XLSX.utils.encode_cell({ c: item.c, r: item.r });
					sheet[cell_ref].t = item.t;
					sheet[cell_ref].v = item.v;
					delete(sheet[cell_ref.w]);
				});

				// save workbook to file
				XLSX.writeFile(wb, `Export_Group_${export_group.seller}_${export_group.consumer}.xlsx`);

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	async function handleGroupAction(action_id, group_id) {

		switch (action_id) {
			case 0:
				handleOpenGroup(group_id);
				break;
			case 1:
				handleDeleteGroup(group_id);
				break;
			case 2:
				handleExportGroup(group_id);
			break;
			default:
				break;
		}
	}


	function handleOpenPlanRow(row_id) {

		if (row_id !== undefined) {
			props.history.push('/planning/plan_rows/' + row_id);
		}
	}


	async function handleDeleteRow(row_id) {

		if (!isNaN(row_id)) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити вибраний ряд?')) {
				return;
			}

			try {

				const response = await axios.post('/api/planning/plan/' + row_id + '/delete');
				if (response.status === 200) {

					setLocalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	async function handleRowAction(action_id, row_id) {

		switch (action_id) {
			case 0:
				handleOpenPlanRow(row_id);
				break;
			case 1:
				handleDeleteRow(row_id);
				break;
			default:
				break;
		}
	}


	function handleRowChange(row) {

		setLocalState(prev_state => ({ ...prev_state,
			rows_changed: true,
		}));
	}


	async function handleSavePlanRows() {

		setWait(true);

		try {

			for (let i = 0; i < local_state.group_rows.length; i++) {

				const row = local_state.group_rows[i];
				await axios.post('/api/planning/plan/' + row.plan_row_id, row);
			}

			setLocalState(prev_state => ({ ...prev_state,
				rows_changed: false,
			}));

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}

		setWait(false);
	}


	async function handleDeleteGroupRows() {

		if (selected_group_rows.length > 0) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити вибрані ряди?')) {
				return;
			}

			try {

				for (let i = 0; i < selected_group_rows.length; i++) {
					await axios.post('/api/planning/plan/' + selected_group_rows[i] + '/delete');
				}

				setLocalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (import_tab_label.current) {
			setImportTabLabelWidth(import_tab_label.current.offsetWidth);
		}
		if (domain_label.current) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
		}
		if (consumer_label.current) {
			setConsumerLabelWidth(consumer_label.current.offsetWidth);
		}
		if (seller_label.current) {
			setSellerLabelWidth(seller_label.current.offsetWidth);
		}

	}, [])


	useEffect(() => {

		async function fetchData(domain, period_start, period_end) {

			try {

				const response_groups = await axios.get('/api/planning/groups', { params: {
					domain: domain,
					period_start: getDateFormat(period_start),
					period_end: getDateFormat(period_end)
				}});

				const groups = response_groups.data.group_list;
				groups.forEach(item => {

					const consumer = global_state.companies.find(c => c.company_id === item.consumer_company_id);
					const seller = global_state.companies.find(c => c.company_id === item.seller_company_id);

					item.consumer = (consumer !== undefined) ? consumer.name : '';
					item.seller = (seller !== undefined) ? seller.name : '';
				})

				setLocalState(prev_state => ({ ...prev_state,
					groups: response_groups.data.group_list,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (filter.domain_id > 0 && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			fetchData(filter.domain_id, filter.period_start, filter.period_end);
		}

	}, [filter, local_state.update_flag])


	useEffect(() => {

		async function fetchData(group_id) {

			try {

				const response_plan_rows = await axios.get('/api/planning/plans', { params: {
					group_id: group_id,
				}});

				setLocalState(prev_state => ({ ...prev_state,
					group_rows: response_plan_rows.data.plan_row_list,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (selected_groups.length > 0) {
			fetchData(selected_groups[0]);
		} else {
			setLocalState(prev_state => ({
				...prev_state,
				group_rows: [],
			}));
		}

	}, [selected_groups, local_state.update_flag])


	const list_group = (item) => {

		const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
		const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
		const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

		return ({
			key: item.group_id,
			id: item.group_id,
			...item,
			domain: (domain !== undefined) ? domain.domain_display_name : item.domain,
			consumer: (consumer !== undefined) ? consumer.name : '',
			seller: (seller !== undefined) ? seller.name : '',
		})
	}


	const list_plan_row = (item) => {

		const cells = [];
		for (let i = 1; i <= 25; i++) {

			let index = (i < 10) ? `p0${i}` : `p${i}`;
			if (item[index] !== null) {
				cells.push({
					id: i,
					value: item[index]
				});
			}
		}

		return ({
			key: item.plan_row_id,
			id: item.plan_row_id,
			...item,
			points: cells,
		})
	}


	var display_domains = [];
	var display_companies = [];
	var display_groups = [];

	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => <option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>);
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => company.name);
	}

	if (local_state.groups.length > 0) {
		display_groups = local_state.groups
			.filter(item => ((filter.consumer_company !== null && item.consumer === filter.consumer_company) || (filter.consumer_company === null)) &&
							((filter.seller_company !== null && item.seller === filter.seller_company) || (filter.seller_company === null)))
			.map(item => list_group(item));
	}


	return (
		<Container className={classes.root_form} component='main'>

			<Grid container>

				<Grid item xs={3}>

					<Grid container spacing={2}>

						<Grid item xs={12}>

							<TextField
								InputLabelProps={{ shrink: true }}
								variant='outlined'
								type='date'
								required
								fullWidth
								label='Період з'
								value={filter.period_start}
								onChange={handlePeriodStartChange}
							/>

						</Grid>

						<Grid item xs={12}>

							<TextField
								InputLabelProps={{ shrink: true }}
								variant='outlined'
								type='date'
								required
								fullWidth
								label='Період до'
								value={filter.period_end}
								onChange={handlePeriodEndChange}
							/>

						</Grid>

						<Grid item xs={12}>

							<FormControl variant='outlined' required fullWidth>
								<InputLabel ref={domain_label} id='domain-label'>Зона балансування</InputLabel>
								<Select
									native
									labelId='domain-label'
									labelWidth={domain_label_width}
									value={filter.domain_id}
									onChange={handleDomainChange}
								>
									{display_domains}
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs={12}>

							<Autocomplete
								options={display_companies}
								getOptionLabel={(option) => option}
								renderInput={(params) => <TextField {...params} label='Покупець' variant='outlined' />}
								value={filter.consumer_company}
								onChange={handleConsumerChange}
							/>

						</Grid>

						<Grid item xs={12}>

							<Autocomplete
								options={display_companies}
								getOptionLabel={(option) => option}
								renderInput={(params) => <TextField {...params} label='Продавець' variant='outlined' />}
								value={filter.seller_company}
								onChange={handleSellerChange}
							/>

						</Grid>

					</Grid>

				</Grid>

				<Grid item xs={9}>

					<div className={classes.group_table}>

						<GroupRowsTable
							rows={display_groups}
							selected={selected_groups}
							setSelected={setSelectedGroups}
							action_list={[
								{ id: 0, name: 'Відкрити' },
								{ id: 1, name: 'Видалити' },
								{ id: 2, name: 'Експорт' },
							]}
							onActionSelect={handleGroupAction}
						/>

					</div>

				</Grid>

				<Grid item xs={12} className={classes.control_top_margin}>

					<Typography component='h3' variant='body1' align='center'>
						Планові ряди
					</Typography>

				</Grid>

			</Grid>

			<EditPlanRowsTable
				rows={local_state.group_rows}
				selected={selected_group_rows}
				setSelected={setSelectedGroupRows}
				action_list={[
					{ id: 0, name: 'Відкрити' },
					{ id: 1, name: 'Видалити' },
				]}
				onActionSelect={handleRowAction}
				onChangeRow={handleRowChange}
			/>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={handleSavePlanRows}
					disabled={!local_state.rows_changed}
				>
					Зберегти
				</Button>

				<Button
					variant='contained'
					color='primary'
					className={classes.button_control}
					onClick={handleDeleteGroupRows}
					disabled={selected_group_rows.length === 0}
				>
					Видалити виділені ряди
				</Button>

				<Button
					variant='contained'
					color='secondary'
					className={classes.button_control}
					onClick={() => props.history.go(-1)}
				>
					Скасувати
				</Button>

			</div>

			<WaitDialog
				open={wait}
			/>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</Container>
	)
}


export default withRouter(ComponentEditMonthlyPlanning);