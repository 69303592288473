import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import CapacityLimitTable from './subcomponents/CapacityLimitTable';

import { globalContext } from '../layout/Context';
import { capacityContext } from './capacityContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat, getHoursInDate, getLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	header: {
		marginBottom: theme.spacing(3),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	table_control: {
		border: '1px solid gray',
		padding: theme.spacing(1),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},

	table_head: {
		fontWeight: 'bold',
	},

	table_row_1: {
		background: 'white'
	},

	table_row_2: {
		background: '#F0FFFF'
	},

	hour_point: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '85px'
	},

}));


export default function CapacityEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const type_label = React.useRef(null);
	const out_domain_label = React.useRef(null);
	const in_domain_label = React.useRef(null);
	// state for label widths
	const [type_label_width, setTypeLabelWidth] = useState(0);
	const [out_domain_label_width, setOutDomainLabelWidth] = useState(0);
	const [in_domain_label_width, setInDomainLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(capacityContext);

	// get time series id from url
	const capacity_id = parseInt(props.match.params.capacity_id);
	const update_url = isNaN(capacity_id) ? '/api/capacity/add' : '/api/capacity/' + capacity_id;


	function createInitialState() {

		var day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);

		var day_2 = new Date();
		day_2.setHours(24, 0, 0, 0);

		var hour_points_array = [];
		for (let i = 1; i <= 24; i++) {

			hour_points_array.push({
				key: i,
				id: i.toString(),
				label: i.toString(),
				valid: true,
				price: 0,
				os: 0,
				rs: 0,
			});
		}

		const complex_state = {

			type_id: 0,
			company_id: 0,
			number: 1,
			out_domain_id: 0,
			in_domain_id: 0,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
			period_valid: true,
			capacity_limits: [],

			hour_points: hour_points_array,

			changed: false,
		}

		return complex_state;
	}


	function handleNumberChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			number: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTypeChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			type_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleCompanyChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleOutDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			out_domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleInDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			in_domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handlePeriodStartChange(event) {

		const event_value = event.target.value;

		if (local_state.type_id === 0) {

			var calculated_end_date = new Date(event_value);
			calculated_end_date.setHours(24, 0, 0, 0);

			setLocalState(prev_state => ({ ...prev_state,
				period_start: event_value,
				period_end: getDateFormat(calculated_end_date),
				period_valid: true,
				changed: true,
			}));

		} else {

			setLocalState(prev_state => ({ ...prev_state,
				period_start: event_value,
				period_valid: new Date(event_value) < new Date(local_state.period_end),
				changed: true,
			}));
		}

		// update page according to the time difference
		updateHours(event_value);
	}


	function handlePeriodEndChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			period_end: event_value,
			period_valid: new Date(local_state.period_start) < new Date(event_value),
			changed: true,
		}));

		// update page according to the time difference
		updateHours(event_value);
	}


	function onTextChangeHandler(event) {

		// cache event value
		const event_value = event.target.value;
		// get element id
		const elem_id = event.target.id;
		const elem_int_id = parseInt(elem_id);

		// find item
		const new_points = [ ...local_state.hour_points ];
		const item_at_index = new_points.find(item => item.key === elem_int_id);

		item_at_index.valid = is_valid_float_input(event_value);

		if (elem_id === elem_int_id + '-price') {
			item_at_index.price = event_value;
		}
		if (elem_id === elem_int_id + '-os') {
			item_at_index.os = event_value;
		}
		if (elem_id === elem_int_id + '-rs') {
			item_at_index.rs = event_value;
		}

		setLocalState(prev_state => ({ ...prev_state,
			hour_points: new_points,
			changed: true,
		}));
	}


	function onValuePaste(event) {

		const event_target = event.target;
		// for elements with identified id
		if (event_target.id) {

			// e.clipboardData contains the data that is about to be pasted.
			if (event.clipboardData.types.indexOf('text/plain') > -1) {

				const string_to_paste = event.clipboardData.getData('text/plain');
				const array_to_paste = string_to_paste.replace(/\t+/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';');

				// Since the paste operation get canceled, we need to manually
				// paste the data into the document.
				const new_points = [ ...local_state.hour_points ];

				// iterate through buffer array
				for (let i = 0; i < array_to_paste.length; i++) {

					if (array_to_paste[i]) {

						let element_id = parseInt(event_target.id) + i - 1;
						if (new_points[element_id.toString()]) {
							new_points[element_id.toString()].value = parseFloat(array_to_paste[i]);
						}
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hour_points: new_points,
					changed: true,
				}));

				// This is necessary to prevent the default paste action.
				event.preventDefault();
			}
		}
	}


	async function saveHandler() {

		try {

			const capacity = {
				type_id: local_state.type_id,
				number: local_state.number,
				out_domain_id: local_state.out_domain_id,
				in_domain_id: local_state.in_domain_id,
				period_start: getDateFormat(local_state.period_start),
				period_end: getDateFormat(local_state.period_end),
				company_id: local_state.company_id,
				p01_os: (local_state.hour_points.length > 0) ? local_state.hour_points[0].os : null,
				p01_rs: (local_state.hour_points.length > 0) ? local_state.hour_points[0].rs : null,
				p01_pr: (local_state.hour_points.length > 0) ? local_state.hour_points[0].price : null,
				p02_os: (local_state.hour_points.length > 1) ? local_state.hour_points[1].os : null,
				p02_rs: (local_state.hour_points.length > 1) ? local_state.hour_points[1].rs : null,
				p02_pr: (local_state.hour_points.length > 1) ? local_state.hour_points[1].price : null,
				p03_os: (local_state.hour_points.length > 2) ? local_state.hour_points[2].os : null,
				p03_rs: (local_state.hour_points.length > 2) ? local_state.hour_points[2].rs : null,
				p03_pr: (local_state.hour_points.length > 2) ? local_state.hour_points[2].price : null,
				p04_os: (local_state.hour_points.length > 3) ? local_state.hour_points[3].os : null,
				p04_rs: (local_state.hour_points.length > 3) ? local_state.hour_points[3].rs : null,
				p04_pr: (local_state.hour_points.length > 3) ? local_state.hour_points[3].price : null,
				p05_os: (local_state.hour_points.length > 4) ? local_state.hour_points[4].os : null,
				p05_rs: (local_state.hour_points.length > 4) ? local_state.hour_points[4].rs : null,
				p05_pr: (local_state.hour_points.length > 4) ? local_state.hour_points[4].price : null,
				p06_os: (local_state.hour_points.length > 5) ? local_state.hour_points[5].os : null,
				p06_rs: (local_state.hour_points.length > 5) ? local_state.hour_points[5].rs : null,
				p06_pr: (local_state.hour_points.length > 5) ? local_state.hour_points[5].price : null,
				p07_os: (local_state.hour_points.length > 6) ? local_state.hour_points[6].os : null,
				p07_rs: (local_state.hour_points.length > 6) ? local_state.hour_points[6].rs : null,
				p07_pr: (local_state.hour_points.length > 6) ? local_state.hour_points[6].price : null,
				p08_os: (local_state.hour_points.length > 7) ? local_state.hour_points[7].os : null,
				p08_rs: (local_state.hour_points.length > 7) ? local_state.hour_points[7].rs : null,
				p08_pr: (local_state.hour_points.length > 7) ? local_state.hour_points[7].price : null,
				p09_os: (local_state.hour_points.length > 8) ? local_state.hour_points[8].os : null,
				p09_rs: (local_state.hour_points.length > 8) ? local_state.hour_points[8].rs : null,
				p09_pr: (local_state.hour_points.length > 8) ? local_state.hour_points[8].price : null,
				p10_os: (local_state.hour_points.length > 9) ? local_state.hour_points[9].os : null,
				p10_rs: (local_state.hour_points.length > 9) ? local_state.hour_points[9].rs : null,
				p10_pr: (local_state.hour_points.length > 9) ? local_state.hour_points[9].price : null,
				p11_os: (local_state.hour_points.length > 10) ? local_state.hour_points[10].os : null,
				p11_rs: (local_state.hour_points.length > 10) ? local_state.hour_points[10].rs : null,
				p11_pr: (local_state.hour_points.length > 10) ? local_state.hour_points[10].price : null,
				p12_os: (local_state.hour_points.length > 11) ? local_state.hour_points[11].os : null,
				p12_rs: (local_state.hour_points.length > 11) ? local_state.hour_points[11].rs : null,
				p12_pr: (local_state.hour_points.length > 11) ? local_state.hour_points[11].price : null,
				p13_os: (local_state.hour_points.length > 12) ? local_state.hour_points[12].os : null,
				p13_rs: (local_state.hour_points.length > 12) ? local_state.hour_points[12].rs : null,
				p13_pr: (local_state.hour_points.length > 12) ? local_state.hour_points[12].price : null,
				p14_os: (local_state.hour_points.length > 13) ? local_state.hour_points[13].os : null,
				p14_rs: (local_state.hour_points.length > 13) ? local_state.hour_points[13].rs : null,
				p14_pr: (local_state.hour_points.length > 13) ? local_state.hour_points[13].price : null,
				p15_os: (local_state.hour_points.length > 14) ? local_state.hour_points[14].os : null,
				p15_rs: (local_state.hour_points.length > 14) ? local_state.hour_points[14].rs : null,
				p15_pr: (local_state.hour_points.length > 14) ? local_state.hour_points[14].price : null,
				p16_os: (local_state.hour_points.length > 15) ? local_state.hour_points[15].os : null,
				p16_rs: (local_state.hour_points.length > 15) ? local_state.hour_points[15].rs : null,
				p16_pr: (local_state.hour_points.length > 15) ? local_state.hour_points[15].price : null,
				p17_os: (local_state.hour_points.length > 16) ? local_state.hour_points[16].os : null,
				p17_rs: (local_state.hour_points.length > 16) ? local_state.hour_points[16].rs : null,
				p17_pr: (local_state.hour_points.length > 16) ? local_state.hour_points[16].price : null,
				p18_os: (local_state.hour_points.length > 17) ? local_state.hour_points[17].os : null,
				p18_rs: (local_state.hour_points.length > 17) ? local_state.hour_points[17].rs : null,
				p18_pr: (local_state.hour_points.length > 17) ? local_state.hour_points[17].price : null,
				p19_os: (local_state.hour_points.length > 18) ? local_state.hour_points[18].os : null,
				p19_rs: (local_state.hour_points.length > 18) ? local_state.hour_points[18].rs : null,
				p19_pr: (local_state.hour_points.length > 18) ? local_state.hour_points[18].price : null,
				p20_os: (local_state.hour_points.length > 19) ? local_state.hour_points[19].os : null,
				p20_rs: (local_state.hour_points.length > 19) ? local_state.hour_points[19].rs : null,
				p20_pr: (local_state.hour_points.length > 19) ? local_state.hour_points[19].price : null,
				p21_os: (local_state.hour_points.length > 20) ? local_state.hour_points[20].os : null,
				p21_rs: (local_state.hour_points.length > 20) ? local_state.hour_points[20].rs : null,
				p21_pr: (local_state.hour_points.length > 20) ? local_state.hour_points[20].price : null,
				p22_os: (local_state.hour_points.length > 21) ? local_state.hour_points[21].os : null,
				p22_rs: (local_state.hour_points.length > 21) ? local_state.hour_points[21].rs : null,
				p22_pr: (local_state.hour_points.length > 21) ? local_state.hour_points[21].price : null,
				p23_os: (local_state.hour_points.length > 22) ? local_state.hour_points[22].os : null,
				p23_rs: (local_state.hour_points.length > 22) ? local_state.hour_points[22].rs : null,
				p23_pr: (local_state.hour_points.length > 22) ? local_state.hour_points[22].price : null,
				p24_os: (local_state.hour_points.length > 23) ? local_state.hour_points[23].os : null,
				p24_rs: (local_state.hour_points.length > 23) ? local_state.hour_points[23].rs : null,
				p24_pr: (local_state.hour_points.length > 23) ? local_state.hour_points[23].price : null,
				p25_os: (local_state.hour_points.length > 24) ? local_state.hour_points[24].os : null,
				p25_rs: (local_state.hour_points.length > 24) ? local_state.hour_points[24].rs : null,
				p25_pr: (local_state.hour_points.length > 24) ? local_state.hour_points[24].price : null
			};

			// add or update capacity
			const response = await axios.post(update_url, capacity);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		var data_valid = local_state.period_valid;
		local_state.hour_points.forEach(item => {
			if (!item.valid) {
				data_valid = false;
			}
		});

		if (data_valid) {

			if (local_state.changed) {

				// if state has changed - save
				if (await saveHandler()) {
					// return to parent page
					props.history.push('/capacities');
				}
			}

		} else {
			window.alert('Перевірте правильність вводу даних.');
		}
	}


	function onCancelHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.push('/capacities');
	}


	function OnImportHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.push('/capacities/import');
	}


	function OnExportHandler() {

		const output = [];
		output[0] = [];
		output[0][0] = 'Компанія';
		output[0][1] = 'Торгова зона експорту';
		output[0][2] = 'Торгова зона імпорту';
		output[0][3] = 'Дата';
		output[0][4] = 'Година';
		output[0][5] = 'Тип аукціону';
		output[0][6] = 'ОС, МВт';
		output[0][7] = 'РПС, МВт';
		output[0][8] = 'Ціна, грн/МВтг';
		output[0][9] = 'Обмеження, МВт';

		let row_nr = 1;

		let auction_type = '';
		switch (local_state.type_id) {
			case 0:
				auction_type = 'Добовий';
				break;
			case 1:
				auction_type = 'Місячний';
				break;
			case 2:
				auction_type = 'Річний';
				break;
			default:
				break;
		}

		const company = global_state.companies.find(item => item.company_id === local_state.company_id);
		const out_domain = global_state.domains.find(item => item.domain_id === local_state.out_domain_id);
		const in_domain = global_state.domains.find(item => item.domain_id === local_state.in_domain_id);

		const current_date = new Date(local_state.period_start);
		const end_date = new Date(local_state.period_end);

		while (current_date.getTime() <= end_date.getTime()) {

			const this_date = new Date(current_date);

			const lims = local_state.capacity_limits.filter(row => {
				const row_date = new Date(row.limit_date);

				return (
					row_date.getFullYear() === this_date.getFullYear() &&
					row_date.getMonth() === this_date.getMonth() &&
					row_date.getDate() === this_date.getDate()
				)
			});

			for (let j = 1; j <= getHoursInDate(current_date); j++) {

				const index = (local_state.type_id > 0) ? 0 : j - 1;
				const lim_index = (j < 10) ? `p0${j}` : `p${j}`;

				output[row_nr] = [];
				output[row_nr][0] = (company !== undefined) ? company.name : '';
				output[row_nr][1] = (out_domain !== undefined) ? out_domain.domain_name : '';
				output[row_nr][2] = (in_domain !== undefined) ? in_domain.domain_name : '';

				output[row_nr][3] = getLocalDateString(current_date);
				output[row_nr][4] = j;
				output[row_nr][5] = auction_type;
				output[row_nr][6] = local_state.hour_points[index].os;
				output[row_nr][7] = local_state.hour_points[index].rs;
				output[row_nr][8] = local_state.hour_points[index].price;

				output[row_nr][9] = (lims.length > 0) ? lims[0][lim_index] : undefined;

				row_nr++;
			}

			current_date.setHours(24, 0, 0, 0);
		}

		const file_name = `Output_${getDateFormat(local_state.period_start)}_${getDateFormat(local_state.period_end)}.xlsx`

		// create sheet from json
		const output_sheet = XLSX.utils.json_to_sheet(output, { skipHeader: true });

		// create new workbook
		var output_wb = XLSX.utils.book_new();

		// insert sheet into workbook
		XLSX.utils.book_append_sheet(output_wb, output_sheet, 'output');

		// save workbook to file
		XLSX.writeFile(output_wb, file_name);
	}


	function updateHours(start_date) {

		if (local_state.type_id > 0) {
			return;
		}

		// get start and end dates
		var calculated_start_date = new Date(start_date);
		var calculated_end_date = new Date(start_date);
		calculated_end_date.setMinutes(calculated_end_date.getMinutes() + 24 * 60);

		// get difference in hours between the dates
		var hours = (calculated_end_date - calculated_start_date) / 3.6e6;
		var result = null;

		// if hours is greater then array length, add elements
		if (hours > local_state.hour_points.length) {

			// create a copy of hour_point and add elements
			result = [ ...local_state.hour_points ];
			const new_length = (hours > 25) ? 25 : hours
			for (let i = local_state.hour_points.length + 1; i <= new_length; i++) {

				result.push({
					key: i,
					id: i.toString(),
					label: i.toString(),
					valid: true,
					limit: 0,
					price: 0,
					os: 0,
					rs: 0,
				});
			}

			setLocalState(prev_state => ({ ...prev_state,
				hour_points: result
			}));
		}

		// if hours is lower then array length, remove elements
		if (hours < local_state.hour_points.length) {

			// create a copy of hour_point and remove elements
			result = [ ...local_state.hour_points ];
			setLocalState(prev_state => ({ ...prev_state,
				hour_points: result.slice(0, (hours < 0) ? 0 : hours)
			}));
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	function onAddCapacityLimit() {

		if (capacity_id !== undefined) {

			if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
				return;
			}

			props.history.push('/capacities/limit/' + capacity_id + '/undefined');
		}
	}


	function onOpenCapacityLimit(limit_id) {
		props.history.push('/capacities/limit/' + capacity_id + '/' + limit_id);
	}


	async function onRemoveCapacityLimit(limit_id) {

		if (!window.confirm('Ви впевнені, що бажаєте видалити обмеження до результатів аукціону?')) {
			return;
		}

		const response_delete = await axios.post('/api/capacity/limit/' + limit_id + '/delete');
		if (response_delete.status === 200) {

			const capacity_limits = [ ...local_state.capacity_limits ];
			const new_limits = capacity_limits.filter(item => item.capacity_limit_id !== limit_id);

			setLocalState(prev_state => ({ ...prev_state,
				capacity_limits: new_limits,
			}));
		}
	}


	function onCapacityLimitAction(action_id, limit_id) {

		switch (action_id) {
			case 0:
				onOpenCapacityLimit(limit_id);
				break;
			case 1:
				onRemoveCapacityLimit(limit_id);
				break;
			default:
				break;
		}
	}


	useEffect(() => {

		setTypeLabelWidth(type_label.current.offsetWidth);
		setOutDomainLabelWidth(out_domain_label.current.offsetWidth);
		setInDomainLabelWidth(in_domain_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		async function fetchData() {

			try {

				const response = await axios.get(update_url);
				if (response.data !== null) {

					const { type, auction_number, out_domain_id, in_domain_id, period_start, period_end } = response.data;
					let capacity_limits = [];

					try {
						const limit_response = await axios.get('/api/capacity/limits/' + capacity_id);
						capacity_limits = limit_response.data;
					} catch (error) {
						console.log(error);
					}

					var result = [];
					for (let i = 1; i <= 25; i++) {

						const index = (i < 10) ? `p0${i}` : `p${i}`;
						if (response.data[`${index}_os`] !== null) {

							result.push({
								key: i,
								id: i.toString(),
								label: i.toString(),
								valid: true,
								limit: (capacity_limits !== undefined) ? parseFloat(capacity_limits[index]) : 0,
								price: parseFloat(response.data[`${index}_pr`]),
								os: parseFloat(response.data[`${index}_os`]),
								rs: parseFloat(response.data[`${index}_rs`])
							});
						}
					}

					setLocalState(prev_state => ({ ...prev_state,
						type_id: type,
						number: auction_number,
						out_domain_id: parseInt(out_domain_id),
						in_domain_id: parseInt(in_domain_id),
						period_start: getDateFormat(period_start),
						period_end: getDateFormat(period_end),
						hour_points: result,
						capacity_limits: capacity_limits,
						changed: false,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (!isNaN(capacity_id)) {
			fetchData();
		}

	}, [capacity_id, update_url])


	useEffect(() => {

		if (user) {
			setLocalState(prev_state => ({ ...prev_state,
				company_id: user.company_id,
			}));
		}

	}, [user])


	var display_types = [];
	display_types.push(<option key={-1} value={''}></option>);
	display_types.push(<option key={0} value={0}>Добовий</option>);
	display_types.push(<option key={1} value={1}>Місячний</option>);
	display_types.push(<option key={2} value={2}>Річний</option>);

	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
		));
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => (
			<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
		));
		display_companies.unshift(<option key={-1} value={''} ></option>);
	}


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				{ isNaN(capacity_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(capacity_id) ? 'Додати результати аукціону з розподілу пропускної спороможності' : 'Змінити результати аукціону з розподілу пропускної спроможності' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Typography component='h2' variant='h5' align='center' className= { classes.header }>
					Аукціон з розподілу пропускної спроможності
				</Typography>

				<Grid container spacing={2}>

					<Grid item xs={3}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ type_label } id='company-label'>Компанія</InputLabel>
							<Select
								native
								labelId='company-label'
								labelWidth={ type_label_width }
								onChange={ handleCompanyChange }
								value={ local_state.company_id }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={3}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ type_label } id='domain-label'>Тип аукціону</InputLabel>
							<Select
								native
								labelId='type-label'
								labelWidth={ type_label_width }
								onChange={ handleTypeChange }
								value={ local_state.type_id }
								disabled={ capacity_id ? true : false }
							>
								{ display_types }
							</Select>
						</FormControl>

					</Grid>

					{ (local_state.type_id > 0) &&
					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Номер'
							type='number'
							value = { local_state.number }
							onChange = { handleNumberChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid> }

					<Grid item xs={(local_state.type_id > 0) ? 3 : 6}/>

					<Grid item xs={(local_state.type_id > 0) ? 3 : 4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ out_domain_label } id='out-domain-label'>Зона балансування експорту</InputLabel>
							<Select
								native
								labelId='out-domain-label'
								labelWidth={ out_domain_label_width }
								onChange={ handleOutDomainChange }
								value={ local_state.out_domain_id }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={(local_state.type_id > 0) ? 3 : 4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ in_domain_label } id='in-domain-label'>Зона балансування імпорту</InputLabel>
							<Select
								native
								labelId='in-domain-label'
								labelWidth={ in_domain_label_width }
								onChange={ handleInDomainChange }
								value={ local_state.in_domain_id }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={(local_state.type_id > 0) ? 3 : 4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label= { (local_state.type_id === 0) ? 'Доба торгівлі' : 'Начало періоду'}
							type='date'
							value = { local_state.period_start }
							error = { !local_state.period_valid }
							onChange = { handlePeriodStartChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					{ (local_state.type_id > 0) &&
					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Кінець періоду'
							type='date'
							value = { local_state.period_end }
							error = { !local_state.period_valid }
							onChange = { handlePeriodEndChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid> }

				</Grid>

				<div className={classes.button_form_control}>
					{
						(local_state.hour_points.length) > 0 &&
						<table className={classes.table_control}>
							<thead>
								<tr className={classes.table_head}>
									{ (local_state.type_id === 0) &&
										<td>
											Година
										</td>
									}
									<td>
										Гранична ціна
									</td>
									<td>
										ОС (МВт)
									</td>
									<td>
										РПС (МВт)
									</td>
								</tr>
							</thead>
							<tbody>
								{
									local_state.hour_points.map((item, index) => (
										<tr key={index} className={ (index % 2 === 0) ? classes.table_row_1 : classes.table_row_2 }>
											{ (local_state.type_id === 0) &&
												<td className={classes.table_head}>
													{ item.label }
												</td>
											}
											<td>
												{ (local_state.type_id === 0 || (local_state.type_id > 0 && index === 0)) &&
												<TextField
													size='small'
													className= { classes.hour_point }
													key={ `${item.key}-price` }
													id={ `${item.id}-price` }
													error={ !item.valid }
													onChange={ onTextChangeHandler }
													onPaste={ onValuePaste }
													value={ item.price }
												/> }
											</td>
											<td>
												{ (local_state.type_id === 0 || (local_state.type_id > 0 && index === 0)) &&
												<TextField
													size='small'
													className= { classes.hour_point }
													key={ `${item.key}-os` }
													id={ `${item.id}-os` }
													error={ !item.valid }
													onChange={ onTextChangeHandler }
													onPaste={ onValuePaste }
													value={ item.os }
												/> }
											</td>
											<td>
												{ (local_state.type_id === 0 || (local_state.type_id > 0 && index === 0)) &&
												<TextField
													size='small'
													className= { classes.hour_point }
													key={ `${item.key}-rs` }
													id={ `${item.id}-rs` }
													error={ !item.valid }
													onChange={ onTextChangeHandler }
													onPaste={ onValuePaste }
													value={ item.rs }
												/> }
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					}
				</div>

				{
					(local_state.capacity_limits.length > 0) &&
					<CapacityLimitTable
						rows={ local_state.capacity_limits }
						onLimitAction={ onCapacityLimitAction }
					/>
				}

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick={ onAddCapacityLimit }
						disabled={ isNaN(capacity_id) || (local_state.type_id === 0 && local_state.capacity_limits.length > 0)}
					>
						Додати обмеження
					</Button>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
						disabled={ !local_state.changed }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnImportHandler }
					>
						Імпорт
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnExportHandler }
					>
						Експорт
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}