import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import ProductionPlanEdit from './subcomponents/ProductionPlanEdit';

import { globalContext } from '../layout/Context';
import { planningContext } from './planningContext';
import { getDateFormat, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		width: '1050px',
		display: 'flex',
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	hour_point: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		width: '85px'
	},

}));


export default function ProductionPlanEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label
	const domain_label = React.useRef(null);
	const orderer_label = React.useRef(null);
	const producer_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [orderer_label_width, setOrdererLabelWidth] = useState(0);
	const [producer_label_width, setProducerLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(planningContext);

	// get plan id from url
	const production_plan_id = parseInt(props.match.params.production_plan_id);
	const update_url = isNaN(production_plan_id) ? '/api/planning/production/add' : '/api/planning/production/' + production_plan_id;
	const close_url = isNaN(production_plan_id) ? undefined : '/api/planning/production/' + production_plan_id + '/close';
	const read_only = props.location.pathname.includes('read_only');


	function createInitialState () {

		var day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);

		const hours_count = getHoursInDate(day_1);

		// initialize hour input elements with 24 element for each hour
		var result = [];
		for (let i = 1; i <= hours_count; i++) {

			result.push({
				key: i,
				id: i.toString(),

				value: 0,
				value_valid: true,
				min: 0,
				min_valid: true,
				max: 0,
				max_valid: true,
			});
		}

		const complex_state = {
			order_company_id: 0,
			production_company_id: 0,
			domain_id: 0,
			plan_date: getDateFormat(day_1),
			hours_count: hours_count,
			hours: result,
		}

		return complex_state;
	}


	function handleDomainChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value)
		}));
	}


	function handlePlanDateChange (event) {

		const event_value = event.target.value;
		const new_hours_count = getHoursInDate(event_value);

		if (new_hours_count !== local_state.hours_count) {

			// create a copy of hour point
			let result = [ ...local_state.hours ];

			// if hours is greater then array length, add elements
			if (new_hours_count > local_state.hours.length) {

				for (let i = local_state.hours.length + 1; i <= new_hours_count; i++) {

					result.push({
						key: i,
						id: i.toString(),
						label: i.toString(),
						value_valid: true,
						value: 0
					});
				}
			}

			// if hours is lower then array length, remove elements
			if (new_hours_count < local_state.hours.length) {

				result = result.slice(0, new_hours_count)
			}

			setLocalState(prev_state => ({ ...prev_state,
				plan_date: event_value,
				hours_count: new_hours_count,
				hours: result,
			}));

		} else {

			setLocalState(prev_state => ({ ...prev_state,
				plan_date: event_value,
			}));
		}
	}


	function handleOrderCompanyChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			order_company_id: parseInt(event_value),
		}));
	}


	function handleProductionCompanyChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			production_company_id: parseInt(event_value),
		}));
	}


	function onHoursChange (value) {

		setLocalState(prev_state => ({...prev_state,
			hours: value,
		}));
	}


	async function saveHandler () {

		try {

			const points = [];
			local_state.hours.forEach(item => {
				points[item.key] = {
					id: item.key,
					min: parseFloat(item.min),
					value: parseFloat(item.value),
					max: parseFloat(item.max),
				}
			})

			const new_data = {
				order_company_id: local_state.order_company_id,
				production_company_id: local_state.production_company_id,
				domain_id: local_state.domain_id,
				plan_date: getDateFormat(local_state.plan_date),
				points: points,
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		var data_valid = true;
		local_state.hours.forEach(item => {

			const current_valid = item.min_valid && item.max_valid && item.value_valid && (parseFloat(item.value) >= parseFloat(item.min)) && (parseFloat(item.value) <= parseFloat(item.max));
			if (!current_valid) {
				window.alert(`Перевірте правильність вводу даних плану виробництва: ${ item.id }`);
				data_valid = false;
			}
		})

		if (data_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.push('/planning');
			}
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setOrdererLabelWidth(orderer_label.current.offsetWidth);
		setProducerLabelWidth(producer_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		if (!production_plan_id) {
			const order_company = global_state.companies.find(item => item.company_id === user.company_id);

			if (order_company !== undefined) {
				setLocalState(prev_state => ({ ...prev_state,
					order_company_id: order_company.company_id
				}));
			}
		}

	}, [production_plan_id, user, global_state])


	useEffect(() => {

		if (production_plan_id) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { order_company_id, production_company_id, domain_id, plan_date } = response.data;

						var result = [];
						for (let i = 1; i <= 25; i++) {

							let index = (i < 10) ? `p0${i}` : `p${i}`;
							let index_min = (i < 10) ? `p0${i}_min` : `p${i}_min`;
							let index_max = (i < 10) ? `p0${i}_max` : `p${i}_max`;

							if (response.data[index] !== null) {

								result.push({
									key: i,
									id: i.toString(),

									value: parseFloat(response.data[index]),
									value_valid: true,
									min: parseFloat(response.data[index_min]),
									min_valid: true,
									max: parseFloat(response.data[index_max]),
									max_valid: true,
								});
							}
						}

						setLocalState(prev_state => ({ ...prev_state,
							order_company_id: parseInt(order_company_id),
							production_company_id: parseInt(production_company_id),
							domain_id: parseInt(domain_id),
							plan_date: getDateFormat(plan_date),
							hours_count: result.length,
							hours: result,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
						let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
							edit: edit,
						}));
					}

					console.log(error);
				});
		}

	}, [production_plan_id, update_url])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({
							...message,
							show: true,
							info: `${ first_name } ${ last_name } \n ${ email } \n ${ phone }`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (close_url) {
				axios.post(close_url);
			}
		}
	}, [close_url])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id } >{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>);
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={ item.company_id } value={ item.company_id } >{ item.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<Container className={ classes.root_form } component='main'>

			<Avatar className={ classes.avatar }>
				{ isNaN(production_plan_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(production_plan_id) ? 'Додати план виробництва' : 'Редагувати план виробництва' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Grid container spacing={2}>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={ domain_label_width }
								onChange={ handleDomainChange }
								value={ local_state.domain_id }
								disabled={ read_only }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={ orderer_label } id='orderer-label'>Замовник</InputLabel>
							<Select
								native
								labelId='orderer-label'
								labelWidth={ orderer_label_width }
								onChange={ handleOrderCompanyChange }
								value={ local_state.order_company_id }
								disabled={ read_only }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required fullWidth>
							<InputLabel ref={ producer_label } id='producer-label'>Виробник</InputLabel>
							<Select
								native
								labelId='producer-label'
								labelWidth={ producer_label_width }
								onChange={ handleProductionCompanyChange }
								value={ local_state.production_company_id }
								disabled={ read_only }
							>
								{ display_companies }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба виробництва'
							type='date'
							value = { local_state.plan_date }
							disabled={ read_only }
							onChange = { handlePlanDateChange }
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={8}/>

				</Grid>

				<ProductionPlanEdit
					columns_count={ local_state.hours.length }
					hours={ local_state.hours }
					setHours={ onHoursChange }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						disabled={ read_only }
						className={ classes.button_control }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button_control }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ message.info }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}