import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

// import main layout
import Layout from './components/layout/Layout';

import IndexPage from './components/index/IndexPage';
import AuthForm from './components/auth/AuthForm';
import AdminForm from './components/admin/AdminForm';
import CapacitiesForm from './components/capacity/Capacities';
import ExternalSeriesForm from './components/external_series/ExternalSeries';
import PlanningForm from './components/planning/Planning';
import ContractsForm from './components/contracts/ContractsForm';
import TimeSeriesForm from './components/bc/TimeSeries';
import SchedulesForm from './components/bc/Schedules';
import DamOrdersForm from './components/dam/DamOrders';
import DamPricesForm from './components/dam_prices/DamPrices';
import DamResultsForm from './components/dam_results/DamResultsForm';
import IdmOrdersForm from './components/idm/IdmOrders';
import IdmResultsForm from './components/idm_results/IdmResultsForm';
import OperationalReportForm from './components/operational_report/OperationalReportForm';
import BlocksForm from './components/blocks/BlocksForm';
import HelpForm from './components/help/HelpForm';
import MessageInfoSnackbar from './components/subcomponents/MessageInfoSnackbar';

import { globalContext } from './components/layout/Context';


const useStyles = makeStyles(theme => ({

	message_alert: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},

}));

var starter = true;
var timer_warning_id;
var timer_logout_id;
var interval_tick_id;
var logout_timestamp;

function App() {
	const classes = useStyles();
	const history = useHistory();

	// global state for user
	const initial_user = {
		company_id: 0,
		company_name: '',
		last_name: '',
		first_name: '',
		email: '',
		phone: '',
		authorisation: 0,
		events_count: 0,

		is_logged_in: false,
		login_error: false
	}
	const [user, setUser] = useState(initial_user);

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);


	const reset_events = [
		'load',
		'mousemove',
		'mousedown',
		'click',
		'scroll',
		'keypress'
	];

	const exit_events = [
		'onunload',
	];

	const [listening, setListening] = useState(false);
	const [open_logout_warning, setOpenLogoutWarning] = React.useState(false);
	const [session_livetime, setSessionLivetime] = React.useState(120);


	const is_user_authorised = (authorisation, id) => {
		return (authorisation & (1 << (id - 1))) !== 0;
	}


	function close_backdrop() {
		setOpenLogoutWarning(false);

		if (interval_tick_id) {
			clearInterval(interval_tick_id);
		}
	};


	function onMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (!user.is_logged_in && !user.login_error) {

			axios.get('/api/login')
				.then(response => {

					setUser(prev_state => ({ ...prev_state,
						company_id: response.data.company_id,
						last_name: response.data.last_name,
						first_name: response.data.first_name,
						email: response.data.email,
						phone: response.data.phone,
						authorisation: response.data.authorisation,

						is_logged_in: true,
						login_error: false,
					}));

				})
				.catch(error => {

					if (user.is_logged_in) {

						setUser(prev_state => ({ ...prev_state,
							company_id: 0,
							company_name: '',
							last_name: '',
							first_name: '',
							email: '',
							phone: '',
							authorisation: 0,

							is_logged_in: false,
							login_error: true,
						}));
					}

					// if not logged in redirect to auth page
					history.push('/auth');

					console.log(error);
				});
		}

	}, [user, history])


	useEffect(() => {

		if (user.is_logged_in && user.company_name === '') {

			axios.get('/api/admin/company/' + user.company_id)
				.then(response => {

					setUser(prev_state => ({ ...prev_state,
						company_name: response.data.company.name,
					}));

				})
				.catch(error => {
					console.log(error);
				});
		}

	}, [user])


	// event subscribtion
	useEffect(() => {

		if (user.is_logged_in && !listening) {

			const event_source_instance = new EventSource('/api/info/events');

			event_source_instance.onmessage = (event) => {

				setUser(prev_state => ({ ...prev_state,
					events_count: prev_state.events_count + 1,
				}))

				setMessage(message => ({ ...message,
					show: true,
					message: event.data,
				}));
			};

			event_source_instance.onerror = (error) => {
				// setListening(false);
			};

			setListening(true);
		}

		if (!user.is_logged_in) {
			setListening(false);
		}

	}, [user, listening]);



	function check_warning_interval() {
		setSessionLivetime(Math.round((logout_timestamp - Date.now()) / 1000));
	}


	function show_warning() {
		interval_tick_id = setInterval(check_warning_interval, 10 * 1000);
		setOpenLogoutWarning(true);
	}


	function set_timers() {

		timer_warning_id = setTimeout(show_warning, 3 * 60 * 1000);
		timer_logout_id = setTimeout(onExitBrowser, 5 * 60 * 1000);

		logout_timestamp = Date.now() + 5 * 60 * 1000;
	}


	function clear_timers() {
		if (timer_warning_id) {
			clearTimeout(timer_warning_id);
		}
		if (timer_logout_id) {
			clearTimeout(timer_logout_id);
		}
	}


	function reset_timers() {
		clear_timers();
		set_timers();
	}


	function onExitBrowser() {

		// if browser exits, close all documents
		history.push('/auth');

		// close backdrop
		close_backdrop();

		// cleanup
		clear_timers();

		// usubscribe from events
		for (let i in reset_events) {
			window.removeEventListener(reset_events[i], reset_timers);
		}
		for (let i in exit_events) {
			window.removeEventListener(exit_events[i], onExitBrowser);
		}

		// Send a logout request to the API
		// axios.get('/api/logout');

		// refresh the page
		window.location.reload();
	}


	if (user.is_logged_in && starter) {

		// start monitoring
		for (let i in reset_events) {
			window.addEventListener(reset_events[i], reset_timers);
		}
		for (let i in exit_events) {
			window.addEventListener(exit_events[i], onExitBrowser);
		}

		set_timers();

		starter = false;
	}


	return (
		<globalContext.Provider value={{ initial_user, user, setUser, is_user_authorised }}>
			<Layout>

				<Backdrop
					className={classes.message_alert}
					open={open_logout_warning}
					onClick={close_backdrop}
					onMouseMove={close_backdrop}
					onKeyDown={close_backdrop}
				>
					<h1>Через <span style={{ color: 'red' }}>{session_livetime}</span> секунд ваш сеанс роботи буде завершено.</h1>
				</Backdrop>

				<Switch>
					<Route
						exact path='/'
						component={IndexPage}
					/>
					<Route
						path='/auth'
						component={AuthForm}
					/>
					<Route
						path='/admin'
						component={AdminForm}
					/>
					<Route
						path='/capacities'
						component={CapacitiesForm}
					/>
					<Route
						path='/external_series'
						component={ExternalSeriesForm}
					/>
					<Route
						path='/planning'
						component={PlanningForm}
					/>
					<Route
						path='/contracts'
						component={ContractsForm}
					/>
					<Route
						path='/time_series'
						component={TimeSeriesForm}
					/>
					<Route
						path='/schedules'
						component={SchedulesForm}
					/>
					<Route
						path='/dam'
						component={DamOrdersForm}
					/>
					<Route
						path='/dam_prices'
						component={DamPricesForm}
					/>
					<Route
						path='/dam_results'
						component={DamResultsForm}
					/>
					<Route
						path='/idm'
						component={IdmOrdersForm}
					/>
					<Route
						path='/idm_results'
						component={IdmResultsForm}
					/>
					<Route
						path='/operational_report'
						component={OperationalReportForm}
					/>
					<Route
						path='/blocks'
						component={BlocksForm}
					/>
					<Route
						path='/help'
						component={HelpForm}
					/>
					<Redirect to={'/'} />
				</Switch>

				<MessageInfoSnackbar
					open={message.show}
					message={message.message}
					onClose={onMessageClose}
				/>

			</Layout>
		</globalContext.Provider>
	);
}

export default App;