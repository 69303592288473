import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';
import { getLocalDateString, getDayOfWeek } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 1),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 1),
	},

}));

var detail_index = 0;

export default function MonthPlanningTable(props) {

	const classes = useStyles();
	const { date, rows_source, rows_target, selected, setSelected, setImportIds, setDetails } = props;

	const head_cells = [
		{ id: 'date', alignTo: 'left', disablePadding: true, label: 'Дата' },
		{ id: 'amount1', alignTo: 'right', disablePadding: false, label: 'Обсяг ряду з файла' },
		{ id: 'amount2', alignTo: 'right', disablePadding: false, label: 'Обсяг існуючого ряду' },
		{ id: 'action', alignTo: 'right', disablePadding: false, label: '==' },
	];

	let this_date = new Date(date);
	let this_month = this_date.getMonth();
	let replace_index = 1;
	let display_rows = [];

	while (this_date.getMonth() === this_month) {

		const found_source_row = rows_source.find(row => row.date.getFullYear() === this_date.getFullYear() &&
														row.date.getMonth() === this_date.getMonth() &&
														row.date.getDate() === this_date.getDate());

		const found_target_row = rows_target.filter(row => row.date.getFullYear() === this_date.getFullYear() &&
														row.date.getMonth() === this_date.getMonth() &&
														row.date.getDate() === this_date.getDate());

		let amount1 = 0; let amount2 = [];
		if (found_source_row !== undefined) {
			for (let i = 0; i < found_source_row.points.length; i++) {
				if (found_source_row.points[i] && found_source_row.points[i].value) {
					amount1 += found_source_row.points[i].value;
				}
			}
		}

		if (found_target_row.length > 0) {

			found_target_row.forEach((row, index) => {

				amount2[index] = 0;

				for (let i = 0; i < row.points.length; i++) {
					if (row.points[i].value) {
						amount2[index] += row.points[i].value;
					}
				}
			});
		}

		let ids = [];
		ids.push((found_source_row !== undefined) ? found_source_row.id : 0);
		found_target_row.forEach(item => ids.push(item.id))

		// insert row
		display_rows.push({
			id: replace_index,
			ids: ids,
			date: new Date(this_date),
			amount1: (found_source_row !== undefined) ? amount1 : undefined,
			amount2: amount2,
		});

		// advance date
		this_date.setDate(this_date.getDate() + 1);
		replace_index += 1; 
	}


	const handleSelectAllClick = (event) => {

		if (event.target.checked) {

			const newSelecteds = rows_source.map(row => row.id);
			setSelected(newSelecteds);

			const import_ids = display_rows.map(row => row.ids);
			setImportIds(import_ids);

		} else {

			setSelected([]);
			setImportIds([]);
		}
	}


	const handleClick = (event, id) => {

		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);

		const import_ids = display_rows.filter(row => newSelected.indexOf(row.id) !== -1)
										.map(row => row.ids)
		setImportIds(import_ids);
	}


	const handleView = (event, id, ids) => {

		if (detail_index === id) {
			detail_index = 0;
			setDetails([]);
		} else {
			detail_index = id;
			setDetails(ids);
		}
	}


	const isSelected = (id) => selected.indexOf(id) !== -1;


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<TableHead>
						<TableRow>
							<TableCell padding='checkbox'>
								<Checkbox
									indeterminate={selected.length > 0 && selected.length < display_rows.length}
									checked={display_rows.length > 0 && selected.length === display_rows.length}
									onChange={handleSelectAllClick}
								/>
							</TableCell>
							{
								head_cells.map(item => (
									<TableCell
										key={item.id}
										className={classes.head}
										align={item.alignTo}
										padding={item.disablePadding ? 'none' : 'default'}
									>
										{item.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							display_rows.map((row, row_index) => {

								const item_is_selected = isSelected(row.id);
								const label_id = `row-checkbox-${row_index}`;

								return (
									<TableRow
										hover
										key={row.id}
										role='checkbox'
										aria-checked={item_is_selected}
										selected={item_is_selected}
									>

										<TableCell padding='checkbox'>
											<Checkbox
												checked={item_is_selected}
												onClick={event => handleClick(event, row.id)}
												inputProps={{ 'aria-labelledby': label_id }}
											/>
										</TableCell>

										<TableCell component='th' id={label_id} scope='row' padding='none'>
											{ `${getLocalDateString(row.date)} ${getDayOfWeek(row.date)}` }
										</TableCell>

										<TableCell
											align='right'
											className={classes.slim_cell}
										>
											{ row.amount1 !== undefined ? row.amount1.toFixed(1) : 'ряд не існує' }
										</TableCell>

										<TableCell
											align='right'
											className={classes.slim_cell}
										>
											{
												row.amount2.length === 0
													?
														'ряд не існує'
													:
														row.amount2.map((item, index) => ` ${item.toFixed(1)} ${ (index < row.amount2.length - 1) ? '/' : '' }` )
											}
										</TableCell>

										<TableCell align='right' padding='checkbox'>
											<Checkbox
												checked={detail_index === row.id}
												onClick={event => handleView(event, row.id, row.ids)}
											/>
										</TableCell>

									</TableRow>
								)
							})
						}
					</TableBody>

				</Table>
			</TableContainer>

		</Paper>
	);
}

MonthPlanningTable.propTypes = {
	date: PropTypes.string.isRequired,
	rows_source: PropTypes.array.isRequired,
	rows_target: PropTypes.array.isRequired,
	setImportIds: PropTypes.func.isRequired,
	setDetails: PropTypes.func.isRequired,
};