import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Checkbox from '@material-ui/core/Checkbox';


export default function EnhancedTableHead(props) {

	const { classes, head_cells, order, order_by, selected_count, row_count, onRequestSort, onSelectAllClick, disable_checkbox } = props;
  
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{
					(disable_checkbox === undefined && !disable_checkbox) &&
						<TableCell padding='checkbox'>
							<Checkbox
								indeterminate={selected_count > 0 && selected_count < row_count}
								checked={row_count > 0 && selected_count === row_count}
								onChange={onSelectAllClick}
							/>
						</TableCell>
				}
				{
					head_cells.map(item => (
						<TableCell
							key={item.id}
							className={classes.head}
							align={item.alignTo}
							padding={item.disablePadding ? 'none' : 'default'}
							sortDirection={order_by === item.id ? order : false}
						>
							<TableSortLabel
								active={order_by === item.id}
								direction={order_by === item.id ? order : 'asc'}
								onClick={createSortHandler(item.id)}
							>
								{item.label}
							</TableSortLabel>
						</TableCell>
					))
				}
			</TableRow>
		</TableHead>
	);
}


EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	head_cells: PropTypes.array.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	order_by: PropTypes.string.isRequired,
	selected_count: PropTypes.number.isRequired,
	row_count: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func,
	disable_checkbox: PropTypes.bool
};