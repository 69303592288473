import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		border: '2px solid red',
		borderRadius: '10px',
		alignItems: 'center',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function CreateLicForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	// define state for license

	const lic_validity = new Date();
	const initial_lic = {
		max_users: 0,
		validity: getDateFormat(lic_validity)
	}
	const [license, setLicense] = useState(initial_lic);


	function handleUsersChange (event) {

		const event_value = event.target.value;

		setLicense(prev_state => ({ ...prev_state,
			max_users: event_value,
		}));
	}


	function handleValidityChange (event) {

		const event_value = event.target.value;

		setLicense(prev_state => ({ ...prev_state,
			validity: event_value,
		}));
	}


	async function handleLicCreate (event) {

		console.log()

		event.preventDefault();

		let lic = '';

		try {

			const data = {
				max_users: license.max_users,
				validity: license.validity
			}

			const response = await axios.post('/api/settings/create_license', data);
			if (response.status === 200) {

				lic = response.data;
				alert(response.data);
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		console.log('License information: %o', lic);
	}


	const onCancelHandler = () => {
		props.history.go(-1);
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	return (

		<Container className={ classes.paper } component='main'>

			<form className={ classes.form_control } onSubmit={ handleLicCreate } >

				<Typography component='h2' variant='h5' align='center'>
					Створити ліцензію
				</Typography>

				<Typography component='h3' variant='h6' align='center'>
					<p>Ліцензійна інформація:</p>
				</Typography>

				<TextField
					required
					variant='outlined'
					margin='normal'
					fullWidth
					label='Кількість користувачів'
					value={ license.max_users }
					onChange= { handleUsersChange }
				/>

				<TextField
					required
					variant='outlined'
					margin='normal'
					fullWidth
					type='date'
					label='Кінцева дата користування'
					value={ license.validity }
					onChange= { handleValidityChange }
				/>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
					>
						Створити
					</Button>

				</div>

			</form>

			<div className={classes.button_form_control}>

				<Button
					variant='contained'
					color='secondary'
					className = { classes.button_control }
					onClick= { onCancelHandler }
				>
					Скасувати
				</Button>

			</div>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>

	);
}