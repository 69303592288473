import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/DeleteForeverSharp';

import NumericTextField from '../../subcomponents/NumericTextField';
import { getLocalDateString, getDayOfWeek } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
	},

	table: {
		width: '100%',
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


const curFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
});


export default function GroupPricesTable(props) {

	const classes = useStyles();

	const { rows, onValueUpdate, onActionSelect } = props;

	const initial_value = {
		edit_row_id: -1,
		edit_row: undefined,
	}
	const [state, setState] = React.useState(initial_value);


	const head_cells = [
		{ id: 'date', alignTo: 'left', disablePadding: false, label: 'Дата' },
		{ id: 'day', alignTo: 'left', disablePadding: false, label: 'День' },
		{ id: 'price', alignTo: 'left', disablePadding: false, label: 'Оплата' },
	];


	if (onActionSelect !== undefined) {
		head_cells.push(
			{ id: 'action', alignTo: 'right', disablePadding: false, label: 'Видалити' }
		)
	}

	// calculate sum of payments
	let summ = 0;
	for (let i = 0; i < rows.length; i++) {
		summ += (rows[i].payment !== '') ? rows[i].payment : 0;
	}


	function onMouseClickEvent (row_id) {

		const edit_row = rows.find(item => item.id === row_id);

		// if changing the edited row, fire update event
		if (state.edit_row_id !== -1 && state.edit_row_id !== row_id) {
			onValueUpdate(state.edit_row);
		}

		setState(prev_state => ({ ...prev_state,
			edit_row_id: row_id,
			edit_row: edit_row,
		}));
	}


	function onValueChange(row_id, event) {

		const event_value = event.target.value;
		const new_row = state.edit_row;
		new_row.payment = event_value;

		// update internal state
		setState(prev_state => ({ ...prev_state,
			edit_row: new_row,
		}));
	}


	function onValuePaste(row_id, event) {

		// e.clipboardData contains the data that is about to be pasted.
		if (event.clipboardData.types.indexOf('text/plain') > -1) {

			const string_to_paste = event.clipboardData.getData('text/plain');
			const value_to_paste = string_to_paste.replace(/\s+/g, '').replace(/,/g, '.');

			const new_row = state.edit_row;
			new_row.payment = value_to_paste;
	
			// update internal state
			setState(prev_state => ({ ...prev_state,
				edit_row: new_row,
			}));

			// This is necessary to prevent the default paste action.
			event.preventDefault();
		}
	}


	function onKeyDown(row_id, event) {

		if (event.key === 'Tab') {

			event.preventDefault();

			setState(prev_state => ({ ...prev_state,
				edit_row_id: prev_state.edit_row_id + 1,
			}));
		}
	}


	const onDeleteAction = (event, id) => {

		if (onActionSelect !== undefined) {
			onActionSelect(id);
		}
	};


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<TableHead>
						<TableRow>
							{
								head_cells.map(item => (
									<TableCell
										key={item.id}
										className={classes.head}
										align={item.alignTo}
										padding={'default'}
									>
										{item.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rows.map((row, row_index) => (

								<TableRow
									hover
									size='small'
									key={row.id}
								>

									<TableCell
										key={ `row-${row_index}-date` }
										scope='row'
										padding='default'
									>
										{ getLocalDateString(row.date) }
									</TableCell>

									<TableCell
										key={ `row-${row_index}-day` }
										padding='default'
									>
										{ getDayOfWeek(row.date) }
									</TableCell>

									<TableCell
										key={ `row-${row_index}-payment` }
										onClick={ () => onMouseClickEvent(row.id) }
									>
										{
											(row.id === state.edit_row_id)
											?
												<NumericTextField
													id={ `text_field_${row.id}` }
													value={ state.edit_row.payment }
													onChange={ (event) => onValueChange(row.id, event) }
													onPaste={ (event) => onValuePaste(row.id, event) }
													onKeyDown={ (event) => onKeyDown(row_index, event) }
												/>
											:
												row.payment !== '' ? curFormat.format(row.payment) : ''
										}
									</TableCell>

									{
										(onActionSelect !== undefined && row.payment !== '') &&
										<TableCell align='right'>
											<IconButton
												size='small'
												onClick={(event) => onDeleteAction(event, row.id)}
												color='inherit'
											>
												<ActionIcon />
											</IconButton>
										</TableCell>
									}
								</TableRow>
							))
						}
						<TableRow>
							<TableCell
								colSpan={2}
								className={classes.head}
							>
								Сумарні оплати
							</TableCell>
							<TableCell>
								{ curFormat.format(summ) }
							</TableCell>
							<TableCell>

							</TableCell>
						</TableRow>
					</TableBody>

				</Table>
			</TableContainer>
		</Paper>
	);
}


GroupPricesTable.propTypes = {
	rows: PropTypes.array.isRequired,
};