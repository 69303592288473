import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import NumericTextField from '../../subcomponents/NumericTextField';
import { getLocalDateString } from '../../utils/date_functions';
import { is_valid_float } from '../../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
		// tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		width: '70px',
		padding: theme.spacing(1, 0, 1, 0),
		margin: theme.spacing(1),
	},

	end_cell: {
		width: '80px',
		padding: theme.spacing(1, 0, 1, 0),
	},

	saldo_cell_pos: {
		background: '#FFE4E1',
		color: 'bleck',
		fontWeight: '999',
		fontStyle: 'oblique',
		padding: theme.spacing(1, 0, 1, 0),
	},

	saldo_cell_neg: {
		background: '#FFE4E1',
		color: 'red',
		fontWeight: '999',
		fontStyle: 'oblique',
		padding: theme.spacing(1, 0, 1, 0),
	}

}));

var row_id;

export default function EditPlanRowsTable(props) {

	const classes = useStyles();

	const { rows, row_to_add, add_direction, selected, setSelected, action_list, onActionSelect, onChangeRow } = props;

	const initial_value = {
		edit_row_id: -1,
		edit_cell: -1,
		edit_row: undefined,
	}
	const [state, setState] = React.useState(initial_value);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const head_cells = [
		{ id: 'date', alignTo: 'left', disablePadding: true, label: 'Дата' },
	];

	let max_hours = 23;
	const sum = [];
	const total = []; total[0] = 0;
	const summary = [];
	const indecies = [];
	rows.forEach(row => {

		// initialize array element
		sum[row.plan_row_id] = 0;

		// calculate sum
		for (let i = 1; i <= 25; i++) {

			const index = (i < 10) ? `p0${i}` : `p${i}`;

			// initialise
			if (total[index] === undefined) total[index] = 0;
			if (summary[index] === undefined) summary[index] = (row_to_add !== undefined) ? row_to_add[index] : 0;

			if (row[index] !== null) {

				if (i > max_hours) {
					max_hours = i;
				}

				const value = parseFloat(row[index])

				sum[row.plan_row_id] += value;

				total[index] += value;
				total[0] += value;

				// if rows are buy-amounts, add values to calculate saldo,
				// otherwise - substruct
				if (add_direction) {
					summary[index] += value;
				} else {
					summary[index] -= value;
				}
			}
		}

		summary[0] = 0;
		for (let i = 1; i <= 25; i++) {

			const index = (i < 10) ? `p0${i}` : `p${i}`;

			if(!isNaN(summary[index])) {
				summary[0] += parseFloat(summary[index]);
			}
		}
	});

	for (let i = 1; i <= max_hours; i++) {
		const index = (i < 10) ? `p0${i}` : `p${i}`;
		indecies.push(index);
		head_cells.push({ id: i, alignTo: 'right', disablePadding: false, label: i.toString() });
	}
	head_cells.push({ id: 'sum', alignTo: 'right', disablePadding: false, label: 'Всього' });


	if (onActionSelect !== undefined) {
		head_cells.push(
			{ id: 'action', alignTo: 'right', disablePadding: false, label: 'Дія' }
		)
	}


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.plan_row_id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	}


	const handleCheckBoxClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let new_selected = [];

		if (selectedIndex === -1) {
			new_selected = new_selected.concat(selected, id);
		} else if (selectedIndex === 0) {
			new_selected = new_selected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			new_selected = new_selected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			new_selected = new_selected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(new_selected);
	}


	function onMouseClickEvent (row, cell) {

		const edit_row = rows.find(item => item.plan_row_id === row);

		// if changing the edited row, fire update event
		if (state.edit_row_id !== -1 && state.edit_row_id !== row) {
			onChangeRow(state.edit_row);
		}

		setState(prev_state => ({ ...prev_state,
			edit_row_id: row,
			edit_cell: cell,
			edit_row: edit_row,
		}));
	}


	function onValueChange(row, cell, index, event) {

		const event_value = event.target.value;
		const new_row = state.edit_row;
		new_row[index] = event_value;

		// update internal state
		setState(prev_state => ({ ...prev_state,
			edit_row: new_row,
		}));
	}


	function onValuePaste(row, cell, index, event) {

		if (event.clipboardData.types.indexOf('text/plain') > -1) {

			const string_to_paste = event.clipboardData.getData('text/plain');
			// split string if new line character occurs
			const strings_to_paste = string_to_paste.split(/\r\n|\r|\n/g);

			// convert strings to arrays
			const arrays_to_paste = strings_to_paste.map(s => s.replace(/\t/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';'));

			// update edit row
			const new_row = state.edit_row;

			// storing first cell
			let i_cell = cell;
			for (let i = 0; i < arrays_to_paste[0].length; i++) {

				// get current index
				let ind = indecies[i_cell];

				// update
				if (arrays_to_paste[i] !== '') {
					new_row[ind] = arrays_to_paste[0][i];
				}

				// go to next index
				i_cell++;
			}

			// This is necessary to prevent the default paste action.
			event.preventDefault();

			// update internal state
			setState(prev_state => ({ ...prev_state,
				edit_row: new_row,
			}));
		}
	}


	function onKeyDown(row, cell, event) {

		if (event.key === 'Tab') {

			event.preventDefault();

			setState(prev_state => ({ ...prev_state,
				edit_cell: cell + 1,
			}));
		}
	}


	const handleOpenMenu = (event, id) => {
		// store order id
		row_id = id
		setAnchorEl(event.currentTarget);
	};


	const handleCloseMenu = (id) => {

		if (onActionSelect !== undefined) {
			onActionSelect(id, row_id)
		}
		setAnchorEl(null);
	};


	function formatFloat(value) {
		if (is_valid_float(value)) {

			let ret_val = value.toFixed(1);
			if (ret_val === '-0.0') {
				return '0.0';
			}

			return ret_val;

		} else {
			return value;
		}
	}


	const isSelected = (id) => selected.indexOf(id) !== -1;


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<TableHead>
						<TableRow>
							{
								<TableCell padding='checkbox'>
									<Checkbox
										indeterminate={selected.length > 0 && selected.length < rows.length}
										checked={rows.length > 0 && selected.length === rows.length}
										onChange={handleSelectAllClick}
									/>
								</TableCell>
							}
							{
								head_cells.map(item => (
									<TableCell
										key={item.id}
										className={classes.head}
										align={item.alignTo}
										padding={item.disablePadding ? 'none' : 'default'}
									>
										{item.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>

					<TableBody>
						{
							rows.map((row, row_index) => {

								const item_is_selected = isSelected(row.plan_row_id);
								const label_id = `row-checkbox-${row_index}`;

								return (
									<TableRow
										hover
										role='checkbox'
										aria-checked={item_is_selected}
										key={row.plan_row_id}
										selected={item_is_selected}
									>

										<TableCell padding='checkbox'>
											<Checkbox
												checked={item_is_selected}
												onClick={event => handleCheckBoxClick(event, row.plan_row_id)}
												inputProps={{ 'aria-labelledby': label_id }}
											/>
										</TableCell>

										<TableCell
											key={ `row-${row_index}-date` }
											id={label_id}
											scope='row'
											padding='none'
										>
											{ getLocalDateString(row.trade_date) }
										</TableCell>

										{
											indecies.map((index, cell_index) => (
												<TableCell
													key={ `row-${row_index}-${cell_index}` }
													id={ `row-${row_index}-${cell_index}` }
													align='right'
													className={classes.slim_cell}
													onClick={ () => onMouseClickEvent(row.plan_row_id, cell_index) }
												>
													{
														(row.plan_row_id === state.edit_row_id && cell_index === state.edit_cell)
														?
															<NumericTextField
																id={ 'text_field_' }
																value={ state.edit_row[index] }
																onChange={ (event) => onValueChange(row.plan_row_id, cell_index, index, event) }
																onPaste={ (event) => onValuePaste(row.plan_row_id, cell_index, index, event) }
																onKeyDown={ (event) => onKeyDown(row_index, cell_index, event) }
															/>
														:
															row[index]
													}
												</TableCell>
											))
										}

										<TableCell
											key={ `row-sum-${row.key}` }
											align='right'
											className={classes.end_cell}
										>
											{ sum[row.plan_row_id].toFixed(1) }
										</TableCell>

										{ (onActionSelect !== undefined) &&
											<TableCell align='right'>
												<IconButton
													size='small'
													onClick={(event) => handleOpenMenu(event, row.plan_row_id)}
													color='inherit'
												>
													<ActionIcon />
												</IconButton>
											</TableCell>
										}
									</TableRow>
								)
							})
						}

						<TableRow
							key={ 'total' }
						>

							<TableCell padding='checkbox' />

							<TableCell
								key={ `row-total-date` }
								scope='row'
								padding='none'
								className={classes.head}
							>
								Всього
							</TableCell>

							{
								indecies.map((index, cell_index) => (
									<TableCell
										key={ `row-total-${cell_index}` }
										align='right'
										className={classes.head}
									>
										{ total[index] !== undefined ? total[index].toFixed(1) : '0.0' }
									</TableCell>
								))
							}

							<TableCell
								key={ `row-total-sum` }
								align='right'
								className={classes.head}
							>
								{ total[0].toFixed(1) }
							</TableCell>
						</TableRow>

						{
							(row_to_add !== undefined) &&
							<TableRow
								key={ 'summary' }
							>

								<TableCell padding='checkbox' />

								<TableCell
									key={ `row-summary-date` }
									scope='row'
									padding='none'
									className={ classes.saldo_cell_pos }
								>
									Сальдо
								</TableCell>

								{
									indecies.map((index, cell_index) => (
										<TableCell
											key={ `row-summary-${cell_index}` }
											align='right'
											className={ (summary[index] < -0.01) ? classes.saldo_cell_neg : classes.saldo_cell_pos }
										>
											{ formatFloat(summary[index]) }
										</TableCell>
									))
								}

								<TableCell
									key={ `row-summary-sum` }
									align='right'
									className={ (summary[0] < -0.01) ? classes.saldo_cell_neg : classes.saldo_cell_pos }
								>
									{ formatFloat(summary[0]) }
								</TableCell>
							</TableRow>
						}

					</TableBody>
				</Table>
			</TableContainer>

			{
				(action_list !== undefined) &&
				<Menu
					id='plan-rows-simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					{
						action_list.map(item => 
							<MenuItem key={item.id} onClick={() => handleCloseMenu(item.id)}>{ item.name }</MenuItem>
						)
					}
				</Menu>
			}

		</Paper>
	);
}


EditPlanRowsTable.propTypes = {
	rows: PropTypes.array.isRequired,
	row_to_add: PropTypes.object,
	add_direction: PropTypes.bool,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
	action_list: PropTypes.array,
	onActionSelect: PropTypes.func,
	onChangeRow: PropTypes.func,
};