import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';

import FilterDate from '../subcomponents/FilterDate';
import IdmResultsView from './subcomponents/IdmResultsView';
import IdmOrdersBookView from './subcomponents/IdmOrdersBookView';
import IdmReport from './subcomponents/IdmReport';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import NotAuthorised from '../auth/NotAuthorised';

import { globalContext } from '../layout/Context';
import { idmResultsContext } from './idmResultsContext';

import { getDateFormat, getLocalDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	control_margin: {
		margin: theme.spacing(2, 0, 0, 2),
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		maxWidth: '50%',
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

}));


function IdmResultsForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	const domain_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// import context
	const { user } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id: `idm-results-tab-${index}`,
			'aria-controls': `idm-results-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`idm-results-tabpanel-${index}`}
				aria-labelledby={`idm-results-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	function createInitialState() {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(-24, 0, 0, 0);

		const complex_state = {

			update_flag: false,

			companies: [],
			domains: [],
			trade_types: [],
			block_orders: [],
			result_types: [],

			trade_date: getDateFormat(day_1),
			domain: 1,
			request_code: 951,
			trade_results: [],
			order_book_trade_results: [],

			dam_buy_saldo: [],
			dam_sell_saldo: [],
			online_orders: [],
			dam_prices: [],

			request_disabled: false,
		}

		return complex_state;
	}


	function sort_idm_order_rows(a, b) {

		if (a.period_from > b.period_from) return 1;
		if (a.period_from < b.period_from) return -1;
		if (a.period_to > b.period_to) return 1;
		if (a.period_to < b.period_to) return -1;
		if (a.sequence > b.sequence) return 1;
		if (a.sequence < b.sequence) return -1;
		return 0;
	}


	function handleRequestCodeChange(event) {

		const event_value = event.target.value;

		setGlobalState(prev_state => ({ ...prev_state,
			request_code: parseInt(event_value),
		}));
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setGlobalState(prev_state => ({ ...prev_state,
			domain: parseInt(event_value),
		}));
	}


	async function IdmResultsDownloadHandler(e) {

		try {

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: true,
			}));

			const payload = {
				request_code: global_state.request_code,
				trade_date: getDateFormat(global_state.trade_date),
			}

			const response = await axios.get('/api/idm/xmtrade/results', { params: payload });
			if (response.status === 200) {

				const trade_results = response.data.results;
				trade_results.forEach(elem => {

					elem.trade_date = getLocalDateString(elem.trade_date);

					const result_type = global_state.result_types.find(item => item.role_id === elem.result_type_id);
					elem.result_type_name = (result_type !== undefined) ? result_type.role_comment : undefined;
				})

				setGlobalState(prev_state => ({
					...prev_state,
					request_disabled: false,
					trade_results: trade_results,
				}));

				return true;
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
			}));
		}
	}


	async function IdmOrderBookDownloadHandler(e) {

		try {

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: true,
			}));

			const response = await axios.get('/api/idm/xmtrade/order_book');
			if (response.status === 200) {

				const trade_results = response.data.results;
				trade_results.forEach(elem => {

					elem.trade_date = getLocalDateString(elem.trade_date);

					const result_type = global_state.result_types.find(item => item.role_id === elem.result_type_id);
					const block_order = global_state.block_orders.find(item => item.order_code === elem.block_order);
					const trade_type = global_state.trade_types.find(item => item.type_code === elem.trade_type);

					// get type names
					elem.result_type_name = (result_type !== undefined) ? result_type.role_comment : undefined;
					elem.block_order_name = (block_order !== undefined) ? block_order.order_comment : undefined;
					elem.trade_type_name = (trade_type !== undefined) ? trade_type.type_comment : undefined;

					// sort rows
					elem.rows.sort(sort_idm_order_rows);
				})

				setGlobalState(prev_state => ({ ...prev_state,
					request_disabled: false,
					order_book_trade_results: trade_results,
				}));

				return true;
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
			}));
		}
	}


	async function IdmReportHandle() {

		try {

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: true,
			}));

			const dam_results_get_options = {
				request_code: 941,
				trade_date: getDateFormat(global_state.trade_date),
			};

			const orders_get_options = {
				trade_date: getDateFormat(global_state.trade_date),
			};

			// get applied dam orders
			const dam_orders_result = await axios.get('/api/dam/xmtrade/own_orders', { params: orders_get_options });
			// get dam results
			const dam_results = await axios.get('/api/dam/xmtrade/results', { params: dam_results_get_options });
			// get applied idm orders
			const idm_orders_result = await axios.get('/api/idm/xmtrade/own_orders', { params: orders_get_options });

			// get current domain
			const domain = global_state.domains.find(item => item.domain_id === global_state.domain);

			// sort the orders
			const applied_for_buy = dam_orders_result.data.results.filter(item => item.domain === domain.domain_name && item.trade_type === 'Купівля');
			const results_for_buy = dam_results.data.results.filter(item => item.domain === domain.domain_name && item.result_type_id === 8);
			const applied_for_sell = dam_orders_result.data.results.filter(item => item.domain === domain.domain_name && item.trade_type === 'Продаж');
			const results_for_sell = dam_results.data.results.filter(item => item.domain === domain.domain_name && item.result_type_id === 9);
			// get dam prices
			const dam_prices = (results_for_buy.length > 0) ? [ ...results_for_buy[0].rows ] : [];

			const dam_buy_saldo = []; const dam_sell_saldo = [];

			applied_for_buy.forEach(order => {

				order.rows.forEach(row => {

					const existing_row = dam_buy_saldo.find(item => item.period === row.period);
					if (existing_row !== undefined) {
						const value = parseFloat(row.amount);
						existing_row.amount += isNaN(value) ? 0 : value;
					} else {
						dam_buy_saldo.push({ ...row });
					}
				});
			});

			results_for_buy.forEach(order => {

				order.rows.forEach(row =>{

					const existing_row = dam_buy_saldo.find(item => item.period === row.period);
					if (existing_row !== undefined) {
						const value = parseFloat(row.amount);
						existing_row.amount -= isNaN(value) ? 0 : value;
					}
				});
			});

			applied_for_sell.forEach(order => {

				order.rows.forEach(row => {

					const existing_row = dam_sell_saldo.find(item => item.period === row.period);
					if (existing_row !== undefined) {
						const value = parseFloat(row.amount);
						existing_row.amount += isNaN(value) ? 0 : value;
					} else {
						dam_sell_saldo.push({ ...row });
					}
				});
			});

			results_for_sell.forEach(order => {

				order.rows.forEach(row =>{

					const existing_row = dam_sell_saldo.find(item => item.period === row.period);
					if (existing_row !== undefined) {
						const value = parseFloat(row.amount);
						existing_row.amount -= isNaN(value) ? 0 : value;
					}
				});
			});

			// get online orders
			let online_orders = idm_orders_result.data.results.filter(item => item.domain === domain.domain_name);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
				dam_buy_saldo: dam_buy_saldo,
				dam_sell_saldo: dam_sell_saldo,
				online_orders: online_orders,
				dam_prices: dam_prices,
			}));

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
			}));
		}
	}


	function onFilterChange(date) {

		setGlobalState(prev_state => ({ ...prev_state,
			trade_date: date,
		}));
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/idm_results');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({
					...prev_state,
					companies: res_company_data.data.company_list,
					domains: res_market_defs.data.domain_list,
					trade_types: res_market_defs.data.trade_type_list,
					block_orders: res_market_defs.data.block_order_list,
					result_types: res_market_defs.data.result_types,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		if (tab_no === 2) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
		}

	}, [tab_no])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	const online_sell_orders = global_state.online_orders.filter(item => item.trade_type === 'Продаж');
	const online_buy_orders = global_state.online_orders.filter(item => item.trade_type === 'Купівля');


	if (!user.is_logged_in) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<idmResultsContext.Provider value={{ global_state, setGlobalState }}>

				<div className={classes.root} >

					<AppBar position='static'>
						<Toolbar>

							<Typography className={classes.title} variant='h6' noWrap>
								Результати ВДР
							</Typography>

							{
								(tab_no !== 1) &&
								<FilterDate
									date={global_state.trade_date}
									onFilterChange={onFilterChange}
									onFilterClick={() => {}}
								/>
							}

							<IconButton
								className={classes.right_button}
								onClick={() => props.history.push('/')}
								color='inherit'
							>
								<BackIcon />
							</IconButton>

						</Toolbar>
					</AppBar>

					<Tabs
						value={tab_no}
						onChange={OnTabChage}
						className={classes.tab}
						indicatorColor='primary'
						textColor='primary'
					>
						<Tab label='Завантаження результатів' {...getTabsProps(0)} />
						<Tab label='Книга заявок' {...getTabsProps(1)} />
						<Tab label='Звітність ВДР' {...getTabsProps(2)} />
					</Tabs>

					<TabPanel value={tab_no} index={0}>

						<div className={classes.button_form_control}>

							<FormControl className={classes.button_control} component='fieldset'>

								<FormLabel >Тип результатів</FormLabel>
								<RadioGroup
									value={global_state.request_code}
									onChange={handleRequestCodeChange}>
									<FormControlLabel value={571} control={<Radio />} label='Результати ВДР за місяць' />
									<FormControlLabel value={951} control={<Radio />} label='Результати ВДР за період' />
									<FormControlLabel value={961} control={<Radio />} label='Результати ВДР за добу' />
								</RadioGroup>

							</FormControl>

						</div>

						<div className={classes.button_form_control}>

							<Button
								variant='contained'
								color='primary'
								className={classes.button_control}
								disabled={global_state.request_disabled}
								onClick={IdmResultsDownloadHandler}
							>
								Зчитати результати ВДР
							</Button>

							{
								(global_state.trade_results.length !== 0) &&
								<IdmResultsView
									results={global_state.trade_results}
								/>
							}

						</div>

					</TabPanel>

					<TabPanel value={tab_no} index={1}>

						<div className={classes.button_form_control}>

							<Button
								variant='contained'
								color='primary'
								className={classes.button_control}
								disabled={global_state.request_disabled}
								onClick={IdmOrderBookDownloadHandler}
							>
								Зчитати книгу заявок
							</Button>

							{
								(global_state.order_book_trade_results.length !== 0) &&
								<IdmOrdersBookView
									results={global_state.order_book_trade_results}
								/>
							}

						</div>

					</TabPanel>

					<TabPanel value={tab_no} index={2}>

						<div className={classes.button_form_control}>

							<FormControl variant='outlined' required className={classes.control_margin} fullWidth>
								<InputLabel ref={domain_label} id='domain-label'>Торгова зона</InputLabel>
								<Select
									native
									labelId='domain-label'
									labelWidth={domain_label_width}
									onChange={handleDomainChange}
									value={global_state.domain}
								>
									{display_domains}
								</Select>
							</FormControl>

							<Button
								variant='contained'
								color='primary'
								className={classes.button_control}
								disabled={global_state.request_disabled}
								onClick={IdmReportHandle}
							>
								Підготувати звітність
							</Button>

						</div>

						<IdmReport
							report_date={getLocalDateString(global_state.trade_date)}
							domain={global_state.domain}
							columns_count={getHoursInDate(global_state.trade_date)}
							sell_orders={global_state.dam_sell_saldo}
							buy_orders={global_state.dam_buy_saldo}
							idm_sell_orders={online_sell_orders}
							idm_buy_orders={online_buy_orders}
							dam_prices={global_state.dam_prices}
						/>

					</TabPanel>

					<MessageErrorSnackbar
						open={message.show}
						message={message.message}
						info={''}
						onClose={onErrorMessageClose}
					/>

				</div>

			</idmResultsContext.Provider>
		);
	}
}

export default withRouter(IdmResultsForm);