import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined'; 
import PersonEditIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { adminContext } from './adminContext';
import { is_valid_name, is_valid_email, is_valid_password, is_valid_phone } from '../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	select_form_control: {
		marginTop: theme.spacing(2),
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	select_control: {
		padding: theme.spacing(1),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(2, 0.5),
		display: 'inline-block',
	},

}));


export default function UserEditForm(props) {

	const classes = useStyles();

	const { global_state, setGlobalState } = useContext(adminContext);

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	const initial_state = {

		user_company_id: 0,
		user_last_name: '',
		user_first_name: '',
		user_email: '',
		user_phone: '',
		user_password: '',

		last_name_valid: false,
		first_name_valid: false,
		email_valid: false,
		phone_valid: false,
		password_valid: false,

		enable_password_input: true,
	};
	const [local_state, setLocalState] = useState(initial_state);

	// variable for the label control
	const input_company_label = React.useRef(null);
	const [company_label_width, setCompanyLabelWidth] = useState(0);

	// get user id from url
	const user_id = parseInt(props.match.params.user_id);
	const update_url = (user_id) ? '/api/admin/user/' + user_id : '/api/admin/user/add';
	const delete_url = (user_id) ? '/api/admin/user/' + user_id + '/delete' : undefined;


	function handleCompanyChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_company_id: parseInt(event_value),
		}));
	}


	function handleLastNameChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_last_name: event_value,
			last_name_valid: is_valid_name(event_value),
		}));
	}


	function handleFirstNameChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_first_name: event_value,
			first_name_valid: is_valid_name(event_value),
		}));
	}


	function handleEmailChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_email: event_value,
			email_valid: is_valid_email(event_value),
		}));
	}


	function handlePhoneChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_phone: event_value,
			phone_valid: is_valid_phone(event_value),
		}));
	}


	function onPasswordResetHandler (event) {

		setLocalState(prev_state => ({ ...prev_state,
			password_valid: false,
			enable_password_input: true,
		}));
	}


	function handlePasswordChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			user_password: event_value,
			password_valid: is_valid_password(event_value),
		}));
	}


	async function SaveUserHandler () {

		const user_data = {
			company_id: local_state.user_company_id,
			last_name: local_state.user_last_name,
			first_name: local_state.user_first_name,
			email: local_state.user_email,
			phone: local_state.user_phone,
			password: (local_state.user_password !== '') ? local_state.user_password : null
		};

		try {

			const response = await axios.post(update_url, user_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({
					...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		if (local_state.last_name_valid && local_state.first_name_valid && local_state.email_valid && local_state.phone_valid && local_state.password_valid) {

			let user_response = true;

			if (user_id) {
				user_response = window.confirm('Ви впевнені, що бажаєте змінити користувача?');
			}

			if (user_response) {
				if (await SaveUserHandler()) {
					// return to parent page
					props.history.push('/admin');
				}
			}
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onDeleteHandler () {

		if (!window.confirm('Ви впевнені, що бажаєте видалити користувача?')) {
			return;
		}

		axios.post(delete_url)
			.then(response => {

				if (response.status === 200) {

					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));

					// return to parent screen
					props.history.push('/admin');
				}

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}
	
				console.log(error);
			});
	}


	function onErrorMessageClose () {

		setMessage(message => ({...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setCompanyLabelWidth(input_company_label.current.offsetWidth);

	},[])


	useEffect(() => {

		if (user_id) {
			axios.get(update_url)
				.then(response => {

					if (response.data.user !== null) {

						setLocalState(prev_state => ({ ...prev_state,
							user_company_id: parseInt(response.data.user.company_id),
							user_last_name: response.data.user.last_name,
							user_first_name: response.data.user.first_name,
							user_email: response.data.user.email,
							user_phone: response.data.user.phone,

							last_name_valid: true,
							first_name_valid: true,
							email_valid: true,
							phone_valid: true,
							password_valid: true,

							enable_password_input: false,
						}));
					}

				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({
							...message,
							show: true,
							message: error.response.data,
						}));
					}
		
					console.log(error);
				});
		}

	},[user_id, update_url])


	var display_companies = null;
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => {
			return (
				<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
			)
		})

		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	return (

		<Container className={ classes.paper } component='main' maxWidth='xs'>

			<Avatar className={ classes.avatar }>
				{ (user_id) ? <PersonEditIcon /> : <PersonAddIcon /> }
			</Avatar>
			<Typography component='h1' variant='h4'>
				{ (user_id) ? 'Змінити користувача' : 'Додати користувача' }
			</Typography>

			<form onSubmit={ onSubmitHandler }>

				<TextField
					variant='outlined'
					margin='normal'
					type='text'
					required
					fullWidth
					error={ !local_state.last_name_valid }
					label='Прізвище'
					value={ local_state.user_last_name }
					onChange={ handleLastNameChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					type='text'
					required
					fullWidth
					error={ !local_state.first_name_valid }
					label="Ім'я"
					value={ local_state.user_first_name }
					onChange={ handleFirstNameChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					type='email'
					required
					fullWidth
					error={ !local_state.email_valid }
					label='Email'
					value={ local_state.user_email }
					onChange={ handleEmailChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					type='tel'
					required
					fullWidth
					error={ !local_state.phone_valid }
					label='Телефон'
					value={ local_state.user_phone }
					onChange={ handlePhoneChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					type='password'
					required={ local_state.enable_password_input }
					fullWidth
					error={ !local_state.password_valid }
					disabled={ !local_state.enable_password_input }
					helperText='Пароль містить мін. 8 символів, зокрема цифри, великі і маленькі латинські літери і спец символи.'
					label='Пароль'
					onChange={ handlePasswordChange }
				/>

				{ !isNaN(user_id) && 
				<Button
					variant='contained'
					color='primary'
					onClick={ onPasswordResetHandler }
				>
					Скинути пароль
				</Button> }

				<FormControl required variant='outlined' className={classes.select_form_control} fullWidth>
					<InputLabel ref={ input_company_label } id='company-label'>Компанія</InputLabel>
					<Select
						native
						labelId='company-label'
						value={ local_state.user_company_id }
						labelWidth={ company_label_width }
						className={classes.select_control}
						onChange={ handleCompanyChange }
					>
						{ display_companies }
					</Select>
				</FormControl>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button }
					>
						Зберегти
					</Button>

					{ !isNaN(user_id) &&
					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ onDeleteHandler }
					>
						Видалити
					</Button> }

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>
			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}