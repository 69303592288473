import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import LicenseNotificationDialog from './LicenseNotificationDialog';

import { globalContext } from '../layout/Context';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},

	button: {
		marginTop: theme.spacing(2),
	},

	header: {
		marginTop: theme.spacing(2),
	},

	user_info: {
		textAlign: 'center',
	},

}));


function UserForm() {

	const classes = useStyles();
	const history = useHistory();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	const init_lic_info = {
		show: false,
		license: {}
	}
	const [lic_info_dialog, setLicInfoDialog] = useState(init_lic_info);

	const { initial_user, user, setUser } = useContext(globalContext);


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	};


	function onLicDialogClose() {

		setLicInfoDialog(prev => ({ ...prev,
			show: false
		}));
		// history.push('/');
	}


	function logoutHandler(e) {

		e.preventDefault();

		axios.get('/api/logout')
			.then(response => {

				if (response.status === 200) {
					setUser(initial_user);
				}

			})
			.catch (error => {

				setUser(user => ({ ...user, login_error: true }));

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});
	};


	useEffect(() => {

		async function fetchData() {

			const res_license = await axios.get('/api/settings/license');
			const license = res_license.data;
			const expiry = new Date(license.validity);

			const next_month = new Date();
			next_month.setHours(0, 0, 0, 0);
			next_month.setDate(next_month.getDate() + 30);

			if (next_month > expiry) {

				// Licence will expire next month, display dialog to handle the case
				setLicInfoDialog(prev => ({ ...prev,
					show: true,
					license: license
				}));
			}
		}

		if (user.is_logged_in) {
			fetchData();
		}

	}, [user.is_logged_in])


	return (
		<Container className={ classes.paper } component='main' maxWidth='xs'>

			<Avatar className={ classes.avatar }>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h4'>
				Кабінет користувача
			</Typography>

			<form className = { classes.form } onSubmit={ logoutHandler } >

				<Typography variant='h5' className={ classes.header }>
					Им'я користувача
				</Typography>
				<Typography variant='h6' className={ classes.user_info }>
					{ user.last_name } { user.first_name }
				</Typography>

				<Typography variant='h6' className={ classes.user_info }>
					{ user.company_name }
				</Typography>

				<Typography variant='h5' className={ classes.header }>
					Контактна інформація
				</Typography>
				<Typography variant='h6' className={ classes.user_info }>
					{ user.email }
				</Typography>
				<Typography variant='h6' className={ classes.user_info }>
					{ user.phone }
				</Typography>

				<Button
					type='submit'
					className={ classes.button }
					fullWidth
					variant='contained'
					color='primary'
				>
					Вийти
				</Button>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

			<LicenseNotificationDialog
				open={ lic_info_dialog.show }
				onClose={ onLicDialogClose }
				license={ lic_info_dialog.license }
			/>

		</Container>
	)
}

export default UserForm;