import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { getHoursInDate } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1),
	},

	table: {
		borderLeft: '1px solid',
		borderTop: '1px solid',
		borderBottom: '1px solid',
		borderSpacing: '0px',
		margin: theme.spacing(1),
	},

	head_row: {
		width: '100px',
		fontWeight: 'bold',
		textAlign: 'center',
	},

	row_1: {
		background: '#FFFFE0',
		textAlign: 'center',
		borderRight: '1px solid',
	},

	row_2: {
		textAlign: 'center',
		borderRight: '1px solid',
	},

	col_head: {
		borderRight: '1px solid',
		borderBottom: '1px solid',
		padding: theme.spacing(1),
	},

	col_regular: {
		textAlign: 'right',
		borderRight: '1px solid',
		padding: theme.spacing(0, 1, 0, 0),
	},

}));


function get_row(array_of_rows) {

	if (array_of_rows === undefined || array_of_rows.length === 0) {
		return undefined;
	}

	if (array_of_rows.length === 1) {
		return { ...array_of_rows[0] };
	}

	if (array_of_rows.length > 1) {

		let result = { ...array_of_rows[0] };

		for (let r = 1; r < array_of_rows.length; r++) {

			let max = (array_of_rows[r].auction_number !== undefined) && (array_of_rows[r].type > 0) ? 1 : 25;

			for (let i = 1; i <= max; i++) {

				let index;
				if (array_of_rows[r].auction_number !== undefined) {
					index = (array_of_rows[r].type > 0) ? 'p01_rs' : (i < 10) ? `p0${i}_rs` : `p${i}_rs`;
				} else {
					index = (i < 10) ? `p0${i}` : `p${i}`;
				}

				result[index] += parseFloat(array_of_rows[r][index]);
			}
		}

		return result;
	}
}


function compare_dates(date, period_start, period_end) {

	const c_date = new Date(date);
	const s_date = new Date(period_start);
	const e_date = new Date(period_end);

	return	s_date.getFullYear() <= c_date.getFullYear() &&
			s_date.getMonth() <= c_date.getMonth() &&
			s_date.getDate() <= c_date.getDate() &&
	 		c_date.getFullYear() <= e_date.getFullYear() &&
			c_date.getMonth() <= e_date.getMonth() &&
			c_date.getDate() <= e_date.getDate();
}


function draw_table(trade_date, block) {

	const classes = useStyles();

	const hours = [];
	const hours_max = getHoursInDate(trade_date);
	for (let i = 0; i < hours_max; i++) {
		hours.push({
			id: i + 1
		})
	}

	return (
		<table
			key={block.id}
			className={classes.table}
		>
			<tbody>
				<tr
					key={'header_row_0'}
					className={classes.head_row}
				>
					<td
						key={'header_cell_0_2'}
						className={classes.col_head}
						rowSpan={3}
					>
						СЕТ
					</td>
					<td
						key={`block_header_${block.id}`}
						className={classes.col_head}
						colSpan={12}
					>
						{ `${ block.out_domain } -> ${ block.in_domain }` }
					</td>
				</tr>
				<tr
					key={'header_row_1'}
					className={classes.head_row}
				>
					{
						block.columns.map((c, c_index) => (
							<td
								key={ c_index }
								className={classes.col_head}
								colSpan={3}
							>
								{ c.column_name }
							</td>
						))
					}
				</tr>
				<tr
					key={'header_row_2'}
					className={classes.head_row}
				>
					{
						block.columns.map((c, c_index) => (
							c.rows.map((r, r_index) => (
								<td
									key={ `c${c_index}_r${r_index}` }
									className={classes.col_head}
								>
									{ r.row_name }
								</td>
							))
						))
					}
				</tr>

				{
					hours.map((h, h_index) => (
						<tr
							key={`row_${h_index}`}
							className={ h_index %2 === 0 ? classes.row_1 : classes.row_2 }
						>
							<td
								key={`row_${h_index}_cell`}
								className={classes.col_regular}
							>
								{ h.id }
							</td>

							{
								block.columns.map((c, c_index) => (
									c.rows.map((r, r_index) => (
										<td
											key={ `c${c_index}_r${r_index}` }
											className={classes.col_regular}
										>
											{ r.cells[h.id] }
										</td>
									))
								))
							}
						</tr>
					))
				}
			</tbody>
		</table>
	);
}


export default function OverviewCapacityTable(props) {

	const classes = useStyles();

	const { trade_date, domain, domain_list, year_auctions, year_limits, month_auctions, month_limits, day_auctions, day_limits } = props;

	// define blocks to store to display variables
	const blocks = [];

	// define domains pares
	for (let i = 0; i < domain_list.length; i++) {

		// first direction
		let out_domain_id = domain;
		let out_domain = domain_list.find(item => item.domain_id === out_domain_id);
		let in_domain_id = domain_list[i].domain_id;
		let in_domain = domain_list[i].domain_name

		const f_y_auc = year_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));
		const f_m_auc = month_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));
		const f_d_auc = day_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));

		if (f_y_auc.length > 0 || f_m_auc.length > 0 || f_d_auc.length > 0) {

			const f_y_lim = year_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));
			const f_m_lim = month_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));
			const f_d_lim = day_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));

			blocks.push({
				out_domain_id: out_domain_id,
				in_domain_id: in_domain_id,
				out_domain: (out_domain !== undefined) ? out_domain.domain_name : '',
				in_domain: in_domain,
				y_auc: f_y_auc,
				m_auc: f_m_auc,
				d_auc: f_d_auc,
				y_lim: f_y_lim,
				m_lim: f_m_lim,
				d_lim: f_d_lim
			});
		}

		// second direction
		out_domain_id = domain_list[i].domain_id;
		out_domain = domain_list[i].domain_name;
		in_domain_id = domain;
		in_domain = domain_list.find(item => item.domain_id === in_domain_id);

		const f_y_auc_ = year_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));
		const f_m_auc_ = month_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));
		const f_d_auc_ = day_auctions.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.period_start, item.period_end));

		if (f_y_auc_.length > 0 || f_m_auc_.length > 0 || f_d_auc_.length > 0) {

			const f_y_lim = year_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));
			const f_m_lim = month_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));
			const f_d_lim = day_limits.filter(item => item.out_domain_id === out_domain_id && item.in_domain_id === in_domain_id && compare_dates(trade_date, item.limit_date, item.limit_date));

			blocks.push({
				out_domain_id: out_domain_id,
				in_domain_id: in_domain_id,
				out_domain: out_domain,
				in_domain: (in_domain !== undefined) ? in_domain.domain_name : '',
				y_auc: f_y_auc_,
				m_auc: f_m_auc_,
				d_auc: f_d_auc_,
				y_lim: f_y_lim,
				m_lim: f_m_lim,
				d_lim: f_d_lim
			});
		}
	}

	// calculate max hours
	const hours_max = getHoursInDate(trade_date);

	// for each domain
	for (let d = 0; d < blocks.length; d++) {

		const calculated_rows = {
			year_delta: [],
			month_delta: [],
			day_delta: [],
			total_auction: [],
			total_limit: [],
			total_delta: [],
		};

		const columns = [];

		// there are 4 columns: 3 auction types and total
		for (let c = 0; c < 4; c++) {

			let c_name = '';
			const rows = [];
			let auction_row;
			let limit_row;

			switch (c) {
				case 0:
					c_name = 'річна';
					auction_row = get_row(blocks[d].y_auc);
					limit_row = get_row(blocks[d].y_lim);
					break;
				case 1:
					c_name = 'місячна';
					auction_row = get_row(blocks[d].m_auc);
					limit_row = get_row(blocks[d].m_lim);
					break;
				case 2:
					c_name = 'добова';
					auction_row = get_row(blocks[d].d_auc);
					limit_row = get_row(blocks[d].d_lim);
					break;
				case 3:
					c_name = 'всього';
					break;
				default:
					break;
			}

			for (let r = 0; r < 3; r++) {

				let r_name;
				let row;

				switch (r) {
					case 0:
						r_name = 'номінована РПС';
						row = auction_row;
						break;
					case 1:
						r_name = 'наявна РПС';
						row = limit_row;
						break;
					case 2:
						r_name = 'обмеження';
						break;
					default:
						break;
				}

				const cells = [];
				for (let i = 1; i <= hours_max; i++) {

					let index;
					switch (r) {
						case 0:
							index = (c < 2) ? 'p01_rs' : (i < 10) ? `p0${i}_rs` : `p${i}_rs`;
							break;
						case 1:
							index = (i < 10) ? `p0${i}` : `p${i}`;
							break;
						default:
							break;
					}

					cells[i] = (row !== undefined) ? parseFloat(row[index]) : 0;

					switch (c.toString() + '|' + r.toString()) {

						case '0|0':
							calculated_rows.year_delta[i] = cells[i];
							calculated_rows.total_auction[i] = cells[i];
							break;
						case '0|1':
							calculated_rows.year_delta[i] -= cells[i];
							calculated_rows.total_limit[i] = cells[i];
							break;
						case '0|2':
							calculated_rows.total_delta[i] = calculated_rows.year_delta[i];
							cells[i] = calculated_rows.year_delta[i];
							break;

						case '1|0':
							calculated_rows.month_delta[i] = cells[i];
							calculated_rows.total_auction[i] += cells[i];
							break;
						case '1|1':
							calculated_rows.month_delta[i] -= cells[i];
							calculated_rows.total_limit[i] += cells[i];
							break;
						case '1|2':
							calculated_rows.total_delta[i] += calculated_rows.month_delta[i];
							cells[i] = calculated_rows.month_delta[i];
							break;

						case '2|0':
							calculated_rows.day_delta[i] = cells[i];
							calculated_rows.total_auction[i] += cells[i];
							break;
						case '2|1':
							calculated_rows.day_delta[i] -= cells[i];
							calculated_rows.total_limit[i] += cells[i];
							break;
						case '2|2':
							calculated_rows.total_delta[i] += calculated_rows.day_delta[i];
							cells[i] = calculated_rows.day_delta[i];
							break;

						case '3|0':
							cells[i] = calculated_rows.total_auction[i];
							break;
						case '3|1':
							cells[i] = calculated_rows.total_limit[i];
							break;
						case '3|2':
							cells[i] = calculated_rows.total_delta[i];
							break;

						default:
							break;
					}
				}

				rows.push({
					row_id: r,
					row_name: r_name,
					cells: cells,
				});
			}

			columns.push({
				column_id: c,
				column_name: c_name,
				rows: rows
			});
		}

		blocks[d] = {
			...blocks[d],
			id: d,
			columns: columns
		};
	}


	return (
		<Paper className={classes.paper}>
			{
				blocks.map(b => draw_table(trade_date, b))
			}
		</Paper>
	);
}


OverviewCapacityTable.propTypes = {
	trade_date: PropTypes.string.isRequired,
	domain: PropTypes.number.isRequired,
	domain_list: PropTypes.array.isRequired,
	year_auctions: PropTypes.array.isRequired,
	year_limits: PropTypes.array.isRequired,
	month_auctions: PropTypes.array.isRequired,
	month_limits: PropTypes.array.isRequired,
	day_auctions: PropTypes.array.isRequired,
	day_limits: PropTypes.array.isRequired,
};