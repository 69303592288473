import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';


function OptionConfirmationDialog(props) {

	const { open, onClose, title, options, init_value } = props;

	const [value, setValue] = React.useState(init_value);


	React.useEffect(() => {
		if (!open) {
			setValue(init_value);
		}
	}, [init_value, open]);


	const handleOk = () => {
		onClose(value);
	};


	const handleCancel = () => {
		onClose();
	};


	const handleChange = (event) => {
		const event_value = event.target.value;
		setValue(event_value);
	};


	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth='xs'
			aria-labelledby='confirmation-dialog-title'
			open={open}
		>
			<DialogTitle id='confirmation-dialog-title'> { title } </DialogTitle>

			<DialogContent dividers>
				<Select
					native
					fullWidth
					onChange={ handleChange }
					value={ value }
				>
					{ options.map(option => (
						<option key={ option } value={ option } >{ option }</option>
					))}
				</Select>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleOk} color='primary'>
					Ok
				</Button>
				<Button autoFocus onClick={handleCancel} color='primary'>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}


export default OptionConfirmationDialog;


OptionConfirmationDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	init_value: PropTypes.string.isRequired,
};