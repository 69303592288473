import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';
import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


var row_id;


export default function PeriodCapacityTable(props) {

	const classes = useStyles();

	const { rows, selected, setSelected, onActionSelect, action_list } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');


	const head_cells = [
		{ id: 'number', alignTo: 'left', disablePadding: true, label: 'Номер' },
		{ id: 'period_start', alignTo: 'left', disablePadding: false, label: 'Період з' },
		{ id: 'period_end', alignTo: 'left', disablePadding: false, label: 'Період по' },
		{ id: 'out_domain', alignTo: 'left', disablePadding: false, label: 'Зона експорту' },
		{ id: 'in_domain', alignTo: 'left', disablePadding: false, label: 'Зона імпорту' },
		{ id: 'price', alignTo: 'right', disablePadding: false, label: 'Гранична ціна' },
		{ id: 'os', alignTo: 'right', disablePadding: false, label: 'ОС (МВт)' },
		{ id: 'rs', alignTo: 'right', disablePadding: false, label: 'РПС (МВт)' },
	];

	if (onActionSelect !== undefined) {
		head_cells.push(
			{ id: 'action', alignTo: 'right', disablePadding: false, label: 'Дія' }
		)
	}


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	}


	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	}


	const handleOpenMenu = (event, id) => {
		// store order id
		row_id = id
		setAnchorEl(event.currentTarget);
	};


	const handleCloseMenu = (id) => {

		if (onActionSelect !== undefined) {
			onActionSelect(id, row_id)
		}
		setAnchorEl(null);
	};


	const isSelected = (id) => selected.indexOf(id) !== -1;


	return (
		<Paper className={classes.paper}>

			<TableContainer>
				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={selected.length}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
					/>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy)).map((row, row_index) => {

									const item_is_selected = isSelected(row.id);
									const label_id = `row-checkbox-${row_index}`;

									return (
										<TableRow
											hover
											role='checkbox'
											aria-checked={item_is_selected}
											key={row.key}
											selected={item_is_selected}
										>

											<TableCell padding='checkbox'>
												<Checkbox
													checked={item_is_selected}
													onClick={event => handleClick(event, row.id)}
													inputProps={{ 'aria-labelledby': label_id }}
												/>
											</TableCell>

											<TableCell
												key={ `row-${row_index}-number` }
												id={label_id}
												scope='row'
												padding='none'
											>
												{ row.number }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-period_start` }
											>
												{ getLocalDateString(row.period_start) }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-period_end` }
											>
												{ getLocalDateString(row.period_end) }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-out_domain` }
											>
												{row.out_domain}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-in_domain` }
											>
												{row.in_domain}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-price` }
												align='right'
											>
												{row.pr}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-os` }
												align='right'
											>
												{row.os}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-rs` }
												align='right'
											>
												{row.rs}
											</TableCell>

											{
												(onActionSelect !== undefined) &&
												<TableCell align='right'>
													<IconButton
														size='small'
														onClick={(event) => handleOpenMenu(event, row.id)}
														color='inherit'
													>
														<ActionIcon />
													</IconButton>
												</TableCell>
											}
										</TableRow>
									)
								})
						}
					</TableBody>

				</Table>
			</TableContainer>

			{
				(action_list !== undefined) &&
				<Menu
					id='period-capacity-simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					{
						action_list.map(item => 
							<MenuItem key={item.id} onClick={() => handleCloseMenu(item.id)}>{ item.name }</MenuItem>
						)
					}
				</Menu>
			}
		</Paper>
	);
}


PeriodCapacityTable.propTypes = {
	rows: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
};