import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { esContext } from './esContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat, getLocalDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	input_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	hour_point: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		width: '85px'
	},

}));


export default function ExternalSeriesEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label
	const capacity_label = React.useRef(null);
	const out_domain_label = React.useRef(null);
	const in_domain_label = React.useRef(null);
	const consumer_label = React.useRef(null);
	const seller_label = React.useRef(null);

	const [capacity_label_width, setCapacityLabelWidth] = useState(0);
	const [out_domain_label_width, setOutDomainLabelWidth] = useState(0);
	const [in_domain_label_width, setInDomainLabelWidth] = useState(0);
	const [consumer_label_width, setConsumerLabelWidth] = useState(0);
	const [seller_label_width, setSellerLabelWidth] = useState(0);

	// import context
	const { global_state, setGlobalState } = useContext(esContext);

	// get external series id from url
	const external_series_id = parseInt(props.match.params.external_series_id);
	const update_url = isNaN(external_series_id) ? '/api/es/add' : '/api/es/' + external_series_id;


	function createInitialState() {

		var day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		const hours_count = getHoursInDate(day_1);

		// initialize hour input elements with 24 element for each hour
		var result = [];
		for (let i = 1; i <= hours_count; i++) {

			result.push({
				key: i,
				id: i.toString(),
				label: i.toString(),
				valid: true,
				limit: NaN,
				value: 0
			});
		}

		const complex_state = {

			capacity_id: 0,
			out_domain_id: 0,
			in_domain_id: 0,
			trade_date: getDateFormat(day_1),
			consumer_company_id: 0,
			seller_company_id: 0,
			hours: result,
			limit_set: false,

			changed: false,
		}

		return complex_state;
	}


	function handleCapacityChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			capacity_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleOutDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			out_domain_id: parseInt(event_value),
			limit_set: false,
			changed: true,
		}));
	}


	function handleInDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			in_domain_id: parseInt(event_value),
			limit_set: false,
			changed: true,
		}));
	}


	function handleTradeDateChange(event) {

		const event_value = event.target.value;
		const new_hours_count = getHoursInDate(event_value);

		if (new_hours_count !== local_state.hours_count) {

			// create a copy of hour point
			let result = [...local_state.hours];

			// if hours is greater then array length, add elements
			if (new_hours_count > local_state.hours.length) {

				for (let i = local_state.hours.length + 1; i <= new_hours_count; i++) {

					result.push({
						key: i,
						id: i.toString(),
						label: i.toString(),
						valid: true,
						value: 0
					});
				}
			}

			// if hours is lower then array length, remove elements
			if (new_hours_count < local_state.hours.length) {
				result = result.slice(0, new_hours_count)
			}

			setLocalState(prev_state => ({
				...prev_state,
				trade_date: event_value,
				hours_count: new_hours_count,
				hours: result,
				limit_set: false,
				changed: true,
			}));

		} else {

			setLocalState(prev_state => ({
				...prev_state,
				trade_date: event_value,
				changed: true,
			}));
		}
	}


	function handleConsumerChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			consumer_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleSellerChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({
			...prev_state,
			seller_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function onTextChangeHandler(event) {

		// cache event value
		const event_value = event.target.value;
		// get element id
		const element_id = parseInt(event.target.id);

		// find item
		const new_points = [...local_state.hours];
		const item_at_index = new_points.find(item => item.key === element_id);

		item_at_index.valid = isNaN(item_at_index.limit) ? is_valid_float_input(event_value) : is_valid_float_input(event_value) && parseFloat(event_value) <= item_at_index.limit;
		item_at_index.value = event_value;

		setLocalState(prev_state => ({
			...prev_state,
			hours: new_points,
			changed: true,
		}));
	}


	function onValuePaste(event) {

		const event_target = event.target;
		// for elements with identified id
		if (event_target.id) {

			// e.clipboardData contains the data that is about to be pasted.
			if (event.clipboardData.types.indexOf('text/plain') > -1) {

				const string_to_paste = event.clipboardData.getData('text/plain');
				const array_to_paste = string_to_paste.replace(/\t+/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';');

				// Since the paste operation get canceled, we need to manually
				// paste the data into the document.
				const new_points = [...local_state.hours];

				// iterate through buffer array
				for (let i = 0; i < array_to_paste.length; i++) {

					if (array_to_paste[i]) {

						let element_id = parseInt(event_target.id) + i - 1;
						if (new_points[element_id.toString()]) {
							new_points[element_id.toString()].valid = is_valid_float_input(array_to_paste[i]);
							new_points[element_id.toString()].value = array_to_paste[i];
						}
					}
				}

				setLocalState(prev_state => ({
					...prev_state,
					hours: new_points,
					changed: true,
				}));

				// This is necessary to prevent the default paste action.
				event.preventDefault();
			}
		}
	}


	function onKeyPress(event) {

		const event_target = event.target;

		if (event.charCode === 13) {

			// prevent defualt action
			event.preventDefault();

			// for elements with identified id
			if (event_target.id) {

				const pressed_id = parseInt(event_target.id);
				const pressed_value = event_target.value;
				const new_points = [...local_state.hours];

				for (let i = 0; i < new_points.length; i++) {

					if (i > pressed_id - 1) {
						new_points[i].value = pressed_value;
					}
				}

				setLocalState(prev_state => ({
					...prev_state,
					hours: new_points,
					changed: true,
				}));
			}
		}
	}


	async function saveHandler() {

		try {

			const new_data = {
				capacity_id: local_state.capacity_id,
				out_domain_id: local_state.out_domain_id,
				in_domain_id: local_state.in_domain_id,
				trade_date: getDateFormat(local_state.trade_date),
				consumer_company_id: local_state.consumer_company_id,
				seller_company_id: local_state.seller_company_id,
				p01: (local_state.hours.length > 0) ? local_state.hours[0].value : null,
				p02: (local_state.hours.length > 1) ? local_state.hours[1].value : null,
				p03: (local_state.hours.length > 2) ? local_state.hours[2].value : null,
				p04: (local_state.hours.length > 3) ? local_state.hours[3].value : null,
				p05: (local_state.hours.length > 4) ? local_state.hours[4].value : null,
				p06: (local_state.hours.length > 5) ? local_state.hours[5].value : null,
				p07: (local_state.hours.length > 6) ? local_state.hours[6].value : null,
				p08: (local_state.hours.length > 7) ? local_state.hours[7].value : null,
				p09: (local_state.hours.length > 8) ? local_state.hours[8].value : null,
				p10: (local_state.hours.length > 9) ? local_state.hours[9].value : null,
				p11: (local_state.hours.length > 10) ? local_state.hours[10].value : null,
				p12: (local_state.hours.length > 11) ? local_state.hours[11].value : null,
				p13: (local_state.hours.length > 12) ? local_state.hours[12].value : null,
				p14: (local_state.hours.length > 13) ? local_state.hours[13].value : null,
				p15: (local_state.hours.length > 14) ? local_state.hours[14].value : null,
				p16: (local_state.hours.length > 15) ? local_state.hours[15].value : null,
				p17: (local_state.hours.length > 16) ? local_state.hours[16].value : null,
				p18: (local_state.hours.length > 17) ? local_state.hours[17].value : null,
				p19: (local_state.hours.length > 18) ? local_state.hours[18].value : null,
				p20: (local_state.hours.length > 19) ? local_state.hours[19].value : null,
				p21: (local_state.hours.length > 20) ? local_state.hours[20].value : null,
				p22: (local_state.hours.length > 21) ? local_state.hours[21].value : null,
				p23: (local_state.hours.length > 22) ? local_state.hours[22].value : null,
				p24: (local_state.hours.length > 23) ? local_state.hours[23].value : null,
				p25: (local_state.hours.length > 24) ? local_state.hours[24].value : null
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({
					...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({
					...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		var data_valid = true;
		local_state.hours.forEach(item => {
			if (!item.valid) {
				document.getElementById(item.id).focus();
				data_valid = false;
			}
		});

		if (data_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.push('/external_series');
			}
		}
	}


	function onCancelHandler() {
		props.history.go(-1);
	}


	function onErrorMessageClose() {

		setMessage(message => ({
			...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		if (capacity_label.current) {
			setCapacityLabelWidth(capacity_label.current.offsetWidth);
		}
		if (out_domain_label.current) {
			setOutDomainLabelWidth(out_domain_label.current.offsetWidth);
		}
		if (in_domain_label.current) {
			setInDomainLabelWidth(in_domain_label.current.offsetWidth);
		}
		if (consumer_label.current) {
			setConsumerLabelWidth(consumer_label.current.offsetWidth);
		}
		if (seller_label.current) {
			setSellerLabelWidth(seller_label.current.offsetWidth);
		}

	}, [])


	useEffect(() => {

		if (!isNaN(external_series_id)) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { capacity_id, out_domain_id, in_domain_id, trade_date, consumer_company_id, seller_company_id } = response.data;

						var result = [];
						for (let i = 1; i <= 25; i++) {

							let index = (i < 10) ? 'p0' : 'p';
							index = index + i.toString();

							if (response.data[index] !== null) {

								result.push({
									key: i,
									id: i.toString(),
									label: i.toString(),
									valid: true,
									value: parseFloat(response.data[index]),
								});
							}
						}

						setLocalState(prev_state => ({
							...prev_state,
							capacity_id: capacity_id,
							out_domain_id: parseInt(out_domain_id),
							in_domain_id: parseInt(in_domain_id),
							trade_date: getDateFormat(trade_date),
							consumer_company_id: parseInt(consumer_company_id),
							seller_company_id: parseInt(seller_company_id),
							hours: result,
							changed: false,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({
							...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [external_series_id, update_url])


	useEffect(() => {

		async function fetchData() {

			try {

				// get limit for the current date
				const resp_limits = await axios.get('/api/capacity/limits', {
					params: {
						out_domain: local_state.out_domain_id,
						in_domain: local_state.in_domain_id,
						limit_date: getDateFormat(local_state.trade_date),
					}
				});

				const limits = resp_limits.data.capacities_list;

				// get external series for the current date
				const resp_ess = await axios.get('/api/es/', {
					params: {
						out_domain: local_state.out_domain_id,
						in_domain: local_state.in_domain_id,
						period_start: getDateFormat(local_state.trade_date),
						period_end: getDateFormat(local_state.trade_date),
					}
				});

				const external_series = resp_ess.data.external_series_list;

				// find item
				const new_points = [...local_state.hours];
				for (let i = 1; i <= 25; i++) {

					const index = (i < 10) ? `p0${i}` : `p${i}`;
					let limit = limits.length > 0 ? 0 : NaN;

					for (let j = 0; j < limits.length; j++) {
						if (limits[j][index] !== null && !isNaN(limits[j][index])) {
							limit += limits[j][index];
						}
					}

					for (let j = 0; j < external_series.length; j++) {
						if (external_series[j][index] !== null && !isNaN(external_series[j][index])) {
							limit -= external_series[j][index];
						}
					}

					const item_at_index = new_points.find(item => item.key === i);
					if (item_at_index !== undefined) {
						item_at_index.limit = limit;
						item_at_index.valid = isNaN(limit) ? is_valid_float_input(item_at_index.value) : parseFloat(item_at_index.value) <= limit;
					}
				}

				setLocalState(prev_state => ({
					...prev_state,
					hours: new_points,
					limit_set: true,
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({
						...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}

		}

		if (!local_state.limit_set && local_state.out_domain_id !== 0 && local_state.in_domain_id !== 0) {
			fetchData();
		}

	}, [local_state.limit_set, local_state.out_domain_id, local_state.in_domain_id, local_state.trade_date, local_state.hours])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(item => {
			return (
				<option key={item.company_id} value={item.company_id} >{item.name}</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}


	// filling select control for capacities
	var display_capacities = [];
	if (global_state.capacities.length > 0) {
		display_capacities = global_state.capacities.map(item => {
			return (
				<option key={item.capacity_id} value={item.capacity_id} >{getLocalDateString(item.period_start)} - {getLocalDateString(item.period_end)}: {item.p01_rs}</option>
			)
		})
		display_capacities.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<div className={classes.root_form}>

			<Avatar className={classes.avatar}>
				{isNaN(external_series_id) ? <AddIcon /> : <EditIcon />}
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{isNaN(external_series_id) ? 'Додати графік імпорту / експорту' : 'Редагувати графік імпорту / експорту'}
			</Typography>

			<form className={classes.form_control} onSubmit={onSubmitHandler} >

				<Grid container spacing={2}>

					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value={local_state.trade_date}
							onChange={handleTradeDateChange}
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={3}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={capacity_label} id='capacity-label'>РПС</InputLabel>
							<Select
								native
								labelId='capacity-label'
								labelWidth={capacity_label_width}
								onChange={handleCapacityChange}
								value={local_state.capacity_id}
							>
								{display_capacities}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={3}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={out_domain_label} id='out-domain-label'>Зона балансування експорту</InputLabel>
							<Select
								native
								labelId='out-domain-label'
								labelWidth={out_domain_label_width}
								onChange={handleOutDomainChange}
								value={local_state.out_domain_id}
							>
								{display_domains}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={3}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={in_domain_label} id='in-domain-label'>Зона балансування імпорту</InputLabel>
							<Select
								native
								labelId='in-domain-label'
								labelWidth={in_domain_label_width}
								onChange={handleInDomainChange}
								value={local_state.in_domain_id}
							>
								{display_domains}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={3}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={seller_label} id='seller-label'>Продавець</InputLabel>
							<Select
								native
								labelId='seller-label'
								labelWidth={seller_label_width}
								onChange={handleSellerChange}
								value={local_state.seller_company_id}
							>
								{display_companies}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={3}>

						<FormControl
							variant='outlined'
							required
							fullWidth
						>
							<InputLabel ref={consumer_label} id='consumer-label'>Покупець</InputLabel>
							<Select
								native
								labelId='consumer-label'
								labelWidth={consumer_label_width}
								onChange={handleConsumerChange}
								value={local_state.consumer_company_id}
							>
								{display_companies}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

				</Grid>

				<div className={classes.input_form_control}>

					{
						local_state.hours.map(item => {
							return (
								<TextField
									variant='outlined'
									margin='normal'
									size='small'
									className={classes.hour_point}
									key={item.key}
									id={item.id}
									error={!item.valid}
									label={(item.limit > 0) ? `${item.label}: Max=${item.limit}` : `${item.label}`}
									onChange={onTextChangeHandler}
									onPaste={onValuePaste}
									onKeyPress={onKeyPress}
									value={item.value}
								/>
							)
						})
					}

				</div>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={classes.button_control}
						disabled={!local_state.changed}
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={classes.button_control}
						onClick={onCancelHandler}
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}