import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		textAlign: 'center',
	},

	title: {
		display: 'none',
		margin: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

}));


export default function MarketInfoForm() {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);


	// states for form controls
	const form_init_state = {
		fcm_data: {},
		dam_data: {},
		idm_data: [],
	};
	const [local_state, setLocalState] = useState(form_init_state);


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		let is_subscribed = true;

		axios.get('/api/info/gates')
			.then(response => {

				if (response.data !== null) {

					if (is_subscribed) {

						setLocalState(local_state => ({ ...local_state,
							fcm_data: response.data.fcm,
							dam_data: response.data.dam,
							idm_data: response.data.idm,
						}));
					}
				}
			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

		return () => is_subscribed = false;

	}, [])


	return (

		<div className={classes.root} >

			<Typography variant='h6' noWrap className={classes.title} >
				Інформація щодо закриття воріт
			</Typography>

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								Сегмент ринку
							</TableCell>
							<TableCell>
								Закриття воріт
							</TableCell>
							<TableCell>
								Період ринку електричної енергії
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								РДД
							</TableCell>
							<TableCell>
								{local_state.fcm_data.gate_closure}
							</TableCell>
							<TableCell>
								{local_state.fcm_data.period}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								РДН
							</TableCell>
							<TableCell>
								{local_state.dam_data.gate_closure}
							</TableCell>
							<TableCell>
								{local_state.dam_data.period}
							</TableCell>
						</TableRow>
						{
							local_state.idm_data.map((item, index) =>
								<TableRow
									key={index}
								>
									{
										(index === 0) &&
										<TableCell
											rowSpan={local_state.idm_data.length}
										>
											ВДР
										</TableCell>
									}
									<TableCell>
										{item.gate_closure}
									</TableCell>
									<TableCell>
										{item.period}
									</TableCell>
								</TableRow>
							)
						}

					</TableBody>
				</Table>
			</TableContainer>

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={''}
				onClose={onErrorMessageClose}
			/>

		</div>
	)
}