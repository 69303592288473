import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import OpenIcon from '@material-ui/icons/FolderOpenSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import Select from '@material-ui/core/Select';
import FilterPeriod from '../subcomponents/FilterPeriod';
import FilterDateDomain from '../subcomponents/FilterDateDomain';

import PeriodCapacityTable from './subcomponents/PeriodCapacityTable';
import DayCapacityTable from './subcomponents/DayCapacityTable';
import OverviewCapacityTable from './subcomponents/OverviewCapacityTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import CapacityEditForm from './CapacityEditForm';
import CapacityImportExportForm from './CapacityImportExportForm';
import LimitEditForm from './LimitEditForm';

import { globalContext } from '../layout/Context';
import { capacityContext } from './capacityContext';

import NotAuthorised from '../auth/NotAuthorised';

import { is_valid_date } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	filter_back: {
		backgroundColor: fade(theme.palette.common.white, 0.5),
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},

	filter_input: {
		padding: theme.spacing(1),
		width: '100%',
	},

}));


function Capacities(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// table variables
	const [year_selected, setYearSelected] = React.useState([]);
	const [month_selected, setMonthSelected] = React.useState([]);
	const [day_selected, setDaySelected] = React.useState([]);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {

		return {
			id:					`capacities-tab-${index}`,
			'aria-controls':	`capacities-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
			<div
				hidden={value !== index}
				id={`capacities-tabpanel-${index}`}
				aria-labelledby={`capacities-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {

		setYearSelected([]);
		setDaySelected([]);
		setMonthSelected([]);

		setTabNo(tab);
	}


	function createInitialState () {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			companies: [],
			// component states
			day_auctions: [],
			month_auctions: [],
			year_auctions: [],
			day_limits: [],
			month_limits: [],
			year_limits: []
		}

		return complex_state;
	}


	function createInitialFilter () {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(72, 0, 0, 0);

		const complex_state = {
			enable: false,
			company_id: 0,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
			domain: 1
		}

		return complex_state;
	}


	function handleAddCapacity() {
		props.history.push('/capacities/add');
	}


	function handleOpenCapacity(capacity_id) {

		if (capacity_id !== undefined) {
			props.history.push('/capacities/' + capacity_id);
		}

		OnTabChage(undefined, tab_no);
	}


	async function handleDeleteCapacity(capacity_id) {

		if (capacity_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте видалити результати аукціонів з розподілу потужності?')) {
			return;
		}

		try {

			const id = parseInt(capacity_id);
			if (!isNaN(id)) {

				const response = await axios.post('/api/capacity/' + id + '/delete');
				if (response.status === 200) {

					setGlobalState(state => ({ ...state,
						update_flag: !state.update_flag,
					}));

					setYearSelected([]);
					setMonthSelected([]);
					setDaySelected([]);
				}
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}
	}


	function handleOpenLimit(capacity_id) {

		if (capacity_id !== undefined) {
			props.history.push('/capacities/limit/' + capacity_id + '/undefined');
		}

		OnTabChage(undefined, tab_no);
	}


	function handleCapacityAction(action_id, capacity_id) {

		switch (action_id) {

			case 0:
				handleOpenCapacity(capacity_id);
				break;
			case 1:
				handleDeleteCapacity(capacity_id);
				break;
			case 2:
				handleOpenLimit(capacity_id);
				break;
			default:
				break;
		}
	}


	function onPeriodFilterChange(start, end) {

		setFilter(filter => ({ ...filter,
			period_start: start,
			period_end: end,
		}));
	}


	function onDateDoaminFilterChange(date, domain) {

		setFilter(filter => ({ ...filter,
			period_start: date,
			domain: parseInt(domain),
		}));
	}


	function onFilterCompanyChange(event) {

		const event_value = event.target.value;

		setFilter(filter => ({ ...filter,
			company_id: parseInt(event_value),
		}));
	}


	function onFilterClick() {
		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({ ...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose() {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	function get_selected_capacity_id() {

		let selected_id = undefined;

		switch(tab_no) {

			case 0:
				selected_id = year_selected.length > 0 ? year_selected[0] : undefined;
				break;

			case 1:
				selected_id = month_selected.length > 0 ? month_selected[0] : undefined;
				break;

			case 2:
				selected_id = day_selected.length > 0 ? day_selected[0] : undefined;
				break;

			default:
				break;
		}

		return selected_id;
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const res_market_defs = await axios.get('/api/defs/capacities');
				const res_company_data = await axios.get('/api/admin/companies', { params: { all: true } });

				setGlobalState(prev_state => ({ ...prev_state,
					domains: res_market_defs.data.domain_list,
					companies: res_company_data.data.company_list,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		if (user) {
			setFilter(prev_state => ({ ...prev_state,
				company_id: user.company_id,
			}));
		}

	}, [user])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		if (filter.enable && filter.company_id > 0 && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

			axios.get('/api/capacity', {
				params: {
					company_id:		filter.company_id,
					period_start:	getDateFormat(filter.period_start),
					period_end:		getDateFormat(filter.period_end)
				}
			})
				.then(response => {

					let days = response.data.capacities_list.filter(item => item.type === 0);
					let months = response.data.capacities_list.filter(item => item.type === 1);
					let years = response.data.capacities_list.filter(item => item.type === 2);

					setGlobalState(prev_state => ({ ...prev_state,
						day_auctions: days,
						month_auctions: months,
						year_auctions: years,
					}));
				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

				setFilter(filter => ({ ...filter, enable: false }));
		}

	}, [filter])


	useEffect(() => {

		if (tab_no === 3 && filter.enable && is_valid_date(filter.period_start)) {

			axios.get('/api/capacity/limits', {
				params: {
					limit_date: getDateFormat(filter.period_start)
				}
			})
				.then(response => {

					let days = response.data.capacities_list.filter(item => item.type === 0);
					let months = response.data.capacities_list.filter(item => item.type === 1);
					let years = response.data.capacities_list.filter(item => item.type === 2);

					setGlobalState(prev_state => ({ ...prev_state,
						day_limits: days,
						month_limits: months,
						year_limits: years,
					}));
				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

				setFilter(filter => ({ ...filter, enable: false }));
		}

	}, [tab_no, filter])


	const list_capacities = (item) => {
		return ({
			key: item.capacity_id,
			id: item.capacity_id,
			number: item.auction_number,
			period_start: item.period_start,
			period_end: item.period_end,
			out_domain: global_state.domains.find(elem => elem.domain_id === item.out_domain_id).domain_display_name,
			in_domain: global_state.domains.find(elem => elem.domain_id === item.in_domain_id).domain_display_name,
			pr: item.p01_pr,
			os: item.p01_os,
			rs: item.p01_rs
		})
	}

	var domain_list = [];
	var companies_list = [];
	var auctions_year_rows = [];
	var auctions_month_rows = [];
	var auctions_day_rows = [];
	if (global_state.domains.length > 0 && global_state.companies.length > 0) {

		domain_list = global_state.domains.map(item => (
			<option key={ item.domain_id } value= { item.domain_id } >{ item.domain_display_name }</option>
		));

		companies_list = global_state.companies.map(company => (
			<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
		));

		if (global_state.year_auctions.length > 0) {
			auctions_year_rows = global_state.year_auctions.map(item => list_capacities(item));
		}

		if (global_state.month_auctions.length > 0) {
			auctions_month_rows = global_state.month_auctions.map(item => list_capacities(item));
		}

		if (global_state.day_auctions.length > 0) {
			auctions_day_rows = global_state.day_auctions.map(item => list_capacities(item));
		}
	}


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 25)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<capacityContext.Provider value={{ global_state, setGlobalState, list_capacities }}>
				<Switch>
					<Route
						path='/capacities/limit/:capacity_id/:limit_id'
						component={ LimitEditForm }
					/>
					<Route
						path='/capacities/import'
						component={ CapacityImportExportForm }
						exact
					/>
					<Route
						path='/capacities/add'
						component={ CapacityEditForm }
					/>
					<Route
						path='/capacities/:capacity_id'
						component={ CapacityEditForm }
					/>
					<Route
						path='/capacities'
					>

						<div className = { classes.root } >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Розподілена пропускна спроможність
									</Typography>

									{ (tab_no < 3) &&
									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={ handleAddCapacity }
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no < 3) &&
									<Tooltip title='Відкрити'>
										<span>
											<IconButton
												onClick={() => handleOpenCapacity(get_selected_capacity_id())}
												disabled = { year_selected.length +
															day_selected.length +
															month_selected.length !== 1 }
												color='inherit'
											>
												<OpenIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no < 3) &&
									<Tooltip title='Видалити'>
										<span>
											<IconButton
												onClick={() => handleDeleteCapacity(get_selected_capacity_id())}
												disabled = { year_selected.length +
															day_selected.length +
															month_selected.length !== 1 }
												color='inherit'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip> }

									<Tooltip title='Імпорт'>
										<span>
											<IconButton
												onClick={ () => props.history.push('/capacities/import') }
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Select
										id='filter-company'
										native
										classes={{
											root: classes.filter_back,
											select: classes.filter_input,
										}}
										value={ filter.company_id }
										onChange={onFilterCompanyChange}
									>
										{ companies_list }
									</Select>

									{ (tab_no < 3) &&
									<FilterPeriod
										period_start={filter.period_start}
										period_end={filter.period_end}
										onFilterChange={onPeriodFilterChange}
										onFilterClick={onFilterClick}
									/> }

									{ (tab_no === 3) &&
									<FilterDateDomain
										date={filter.period_start}
										domain={filter.domain}
										domain_list={ domain_list }
										onFilterChange={onDateDoaminFilterChange}
										onFilterClick={onFilterClick}
									/> }

									<div className={classes.right_button} >

										<IconButton
											onClick={ () => props.history.push('/') }
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label={ <Badge badgeContent={global_state.year_auctions.length} color='primary'>Річна</Badge>} {...getTabsProps(0)} />
								<Tab label={ <Badge badgeContent={global_state.month_auctions.length} color='primary'>Місячна</Badge>} {...getTabsProps(1)} />
								<Tab label={ <Badge badgeContent={global_state.day_auctions.length} color='primary'>Добова</Badge>} {...getTabsProps(2)} />
								<Tab label={ 'Огляд' } {...getTabsProps(3)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<PeriodCapacityTable
									rows={auctions_year_rows}
									selected={year_selected}
									setSelected={setYearSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 1, name: 'Видалити' },
										{ id: 2, name: 'Додати обмеження' },
									]}
									onActionSelect={handleCapacityAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={1}>

								<PeriodCapacityTable
									rows={auctions_month_rows}
									selected={month_selected}
									setSelected={setMonthSelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 1, name: 'Видалити' },
										{ id: 2, name: 'Додати обмеження' },
									]}
									onActionSelect={handleCapacityAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<DayCapacityTable
									rows={auctions_day_rows}
									selected={day_selected}
									setSelected={setDaySelected}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 1, name: 'Видалити' },
										{ id: 2, name: 'Додати обмеження' },
									]}
									onActionSelect={handleCapacityAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={3}>

								<OverviewCapacityTable
									trade_date={filter.period_start}
									domain={filter.domain}
									domain_list={global_state.domains}
									year_auctions={global_state.year_auctions}
									year_limits={global_state.year_limits}
									month_auctions={global_state.month_auctions}
									month_limits={global_state.month_limits}
									day_auctions={global_state.day_auctions}
									day_limits={global_state.day_limits}
								/>

							</TabPanel>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ '' }
							onClose={ onErrorMessageClose }
						/>

					</Route>
				</Switch>
			</capacityContext.Provider>
		)
	}
}

export default withRouter(Capacities)