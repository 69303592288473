import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { getLocalDateTimeString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
	},

	table: {
		width: '800px',
	}

}));


export default function HistoryDialog(props) {
	const classes = useStyles();
	const { open, onClose, rows } = props;


	const handleClose = () => {
		onClose();
	};


	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth='xl'
			aria-labelledby='history-dialog-title'
		>
			<DialogTitle id='history-dialog-title' className={classes.header} >
				Історія
			</DialogTitle>

			<DialogContent>
				<TableContainer>
					<Table
						size={'small'}
						className={classes.table}
					>
						<TableHead>
							<TableRow>
								<TableCell align='center'>№</TableCell>
								<TableCell align='center'>Час дії</TableCell>
								<TableCell align='center'>Дія</TableCell>
								<TableCell align='center'>Компанія</TableCell>
								<TableCell align='center' colSpan={2} >Користувач</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								rows.map((item, index) => (
									<TableRow
										key={index}
									>
										<TableCell align='left'>{index + 1}</TableCell>
										<TableCell align='left'>{getLocalDateTimeString(item.action_time)}</TableCell>
										<TableCell align='left'>{item.action_name}</TableCell>
										<TableCell align='left'>{item.name}</TableCell>
										<TableCell align='left'>{item.last_name}</TableCell>
										<TableCell align='left'>{item.first_name}</TableCell>
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
}


HistoryDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	rows: PropTypes.array.isRequired,
};