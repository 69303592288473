import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import OpenIcon from '@material-ui/icons/FolderOpenSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import SendPlanIcon from '@material-ui/icons/SendSharp';
import GetMailsIcon from '@material-ui/icons/MailOutline';
import MonthlyPlanIcon from '@material-ui/icons/DateRangeSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import ProductionPlanTable from './subcomponents/ProductionPlanTable';
import PlanningTable from './subcomponents/PlanningTable';

import FilterPeriodDomain from '../subcomponents/FilterPeriodDomain';
import PlanRowsDetailsDialog from './subcomponents/PlanRowsDetailsDialog';
import PlanRowsConfirmationDialog from './subcomponents/PlanRowsConfirmationDialog';
import GroupSelectionDialog from './subcomponents/GroupSelectionDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import { globalContext } from '../layout/Context';
import { planningContext } from './planningContext';
import NotAuthorised from '../auth/NotAuthorised';

import PlanRowEditForm from './PlanRowEditForm';
import GroupEditForm from './GroupEditForm';
import ProductionPlanEditForm from './ProductionPlanEditForm';
import MonthlyPlanning from './MonthlyPlanning';

import { is_valid_date } from '../utils/validation';
import { excel_date_to_date } from '../utils/excel_functions';
import { getDateFormat, getLocalDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function Planning(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for details dialog
	const initial_details = {
		consumer: '',
		seller: '',
		rows: [],
		saldo_row: undefined,
		buy: true,
		show_dialog: false,
	}
	const [details, setDetails] = useState(initial_details);

	// state for confirmation dialog
	const initial_confirmation = {
		in_progres: false,
		emails: [],
		show_dialog: false,
	}
	const [confirmation, setConfirmation] = useState(initial_confirmation);

	// state for group selection dialog
	const initial_group_select = {
		email_id: undefined,
		email_content: [],
		show_dialog: false
	}
	const [group, setGroup] = useState(initial_group_select);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// table variables
	const [pp_selected, setPpSelected] = React.useState([]);
	const [pp_status_selected, setPpStatusSelected] = React.useState([]);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	// function getTabsProps (index) {
	// 	return {
	// 		id:					`planning-tab-${index}`,
	// 		'aria-controls':	`planning-tabpanel-${index}`,
	// 	};
	// }


	// function TabPanel (props) {
	// 	const { children, value, index, ...other } = props;
	  
	// 	return (
	// 		<div
	// 			hidden={value !== index}
	// 			id={`planning-tabpanel-${index}`}
	// 			aria-labelledby={`planning-tab-${index}`}
	// 			{...other}
	// 		>
	// 			{value === index && <div>{children}</div>}
	// 		</div>
	// 	);
	// }


	// function OnTabChage (e, tab) {

	// 	setPpSelected([]);
	// 	setPpStatusSelected([]);

	// 	setTabNo(tab);
	// }


	function createInitialFilter () {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(24, 0, 0, 0);

		const complex_state = {
			enable: false,
			domain: 1,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function createInitialState () {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			companies: [],

			// component states
			external_series: [],
			plan_rows: [],
			production_plans: [],
			production_status: [],
		}

		return complex_state;
	}


	function handleAddAction () {

		props.history.push('/planning/production/add');
	}


	function handleOpenAction () {

		let selected_id;
		switch (tab_no) {
			case 1:
				if (pp_selected.length === 1) {
					selected_id = pp_selected[0];
					props.history.push('/planning/production/' + selected_id);
				}
				break;
			case 2:
				if (pp_status_selected.length === 1) {
					selected_id = pp_status_selected[0];
					props.history.push('/planning/production/' + selected_id + '/read_only');
				}
				break;
			default:
				break;
		}
	}


	async function handleDeleteAction () {

		if (pp_selected.length === 1) {

			if (!window.confirm('Ви впевнені, що бажаєте видалити вибрану інформацію?')) {
				return;
			}

			try {

				const id = parseInt(pp_selected[0]);
				const delete_path = '/api/planning/production/' + id + '/delete';

				// send request to back end
				const response = await axios.post(delete_path);

				if (response.status === 200) {

					setGlobalState(prev_state => ({...prev_state,
						update_flag: !global_state.update_flag,
					}));

					setPpSelected([]);
				}

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
					let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
						edit: edit,
					}));
				}
	
				console.log(error);
			}
		}
	}


	async function handleSendProductionPlanAction () {

		try {

			if (pp_selected.length === 1) {

				const production_plan_id = parseInt(pp_selected[0]);
				const url = '/api/planning/production/' + production_plan_id + '/send';
	
				const response = await axios.post(url);
				if (response.status === 200) {
					window.alert('План виробництва відправлено.');
				}
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	async function onCreateDamOrderHandle (row) {

		if (row.cells !== undefined && row.cells.length > 24) {

			if (!window.confirm('Ви впевнені, що бажаєте створити заявку РДН?')) {
				return;
			}

			try {

				const rows_cell = [];
				const rows_buy = []

				// get positive and negative amounts separate to from two orders
				for (let i = 0; i < row.cells.length - 2; i++) {
	
					const amount = parseFloat(row.cells[i].name);

					if (amount > 0) {

						rows_cell.push({
							block_number: 1,
							period: i + 1,
							amount: amount,
							price: 10,
							splitting_type_id: 1,
						});

					} else if (amount < 0) {

						rows_buy.push({
							block_number: 1,
							period: i + 1,
							amount: -amount,
							price: 10,
							splitting_type_id: 1,
						});
					}
				}

				let response_sell, response_buy;

				if (rows_cell.length > 0) {

					const new_data = {
						status_id: 1,
						sender_company_id: user.company_id,
						trade_date: getDateFormat(row.date),
						domain_id: filter.domain,
						trade_type_id: 2,
						acceptance_type_id: 2,
						block_type_id: 0,
						rows: rows_cell,
					}

					response_sell = await axios.post('/api/dam/add', new_data);
				}

				if (rows_buy.length > 0) {

					const new_data = {
						status_id: 1,
						sender_company_id: user.company_id,
						trade_date: getDateFormat(row.date),
						domain_id: filter.domain,
						trade_type_id: 1,
						acceptance_type_id: 2,
						block_type_id: 0,
						rows: rows_buy,
					}

					response_buy = await axios.post('/api/dam/add', new_data);
				}

				if ((response_sell !== undefined && response_sell.status === 200) || (response_buy !== undefined && response_buy.status === 200)) {
	
					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}))

					window.alert('Заявки РДН створено.');
				}

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}
	
				console.log(error);
			}
		}
	}


	async function handleGetMails () {

		setConfirmation(prev_state => ({ ...prev_state,
			in_progres: true
		}));

		try {

			const response = await axios.get('/api/communication/get');
			if (response.status === 200) {

				const emails = response.data;

				setConfirmation(prev_state => ({ ...prev_state,
					in_progres: false,
					emails: emails,
					show_dialog: true
				}));
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setConfirmation(prev_state => ({ ...prev_state,
				in_progres: false
			}));
		}
	}


	async function onGetPlanningRowDetails (trade_date, consumer_company_id, seller_company_id) {

		const response = await axios.get('/api/planning/plans', { params: {
				domain: filter.domain,
				consumer_company_id: consumer_company_id,
				seller_company_id: seller_company_id,
				period_start: getDateFormat(trade_date),
				period_end: getDateFormat(trade_date),
		}});

		const consumer = global_state.companies.find(item => item.company_id === consumer_company_id);
		const seller = global_state.companies.find(item => item.company_id === seller_company_id);
		const row_date = new Date(trade_date);

		const plan_rows_income = planning_rows.income.filter(item => {
			const item_date = new Date(item.trade_date);
			return (item_date.getFullYear() === row_date.getFullYear() && 
					item_date.getMonth() === row_date.getMonth() &&
					item_date.getDate() === row_date.getDate())
		});
		const plan_row_outcome = planning_rows.outcome.filter(item => {
			const item_date = new Date(item.trade_date);
			return (item_date.getFullYear() === row_date.getFullYear() && 
					item_date.getMonth() === row_date.getMonth() &&
					item_date.getDate() === row_date.getDate())
		});
		const plan_row_list = response.data.plan_row_list;
		const saldo_row = {};
		const buy_direction = consumer_company_id === user.company_id;

		for (let i = 1; i <= 25; i++) {

			const index = (i < 10) ? `p0${i}` : `p${i}`;
			saldo_row[index] = 0;

			for (let j = 0; j < plan_rows_income.length; j++) {

				if (plan_rows_income[j].points[i - 1] !== undefined) {
					const value = plan_rows_income[j].points[i - 1].value;
					saldo_row[index] += value;
				}
			}

			for (let j = 0; j < plan_row_outcome.length; j++) {

				if (plan_row_outcome[j].points[i - 1] !== undefined) {
					const value = plan_row_outcome[j].points[i - 1].value;
					saldo_row[index] -= value;
				}
			}

			for (let j = 0; j < plan_row_list.length; j++) {

				if (plan_row_list[j][index] !== undefined) {
					const value = parseFloat(plan_row_list[j][index]);
					// in dialog the rows are added or substructed to calculate new saldo
					// so here to get the reference row the rows are substructed or added
					if (buy_direction) {
						saldo_row[index] -= value;
					} else {
						saldo_row[index] += value;
					}
				}
			}
		}

		setDetails(prev_state => ({ ...prev_state,
			consumer: consumer !== undefined ? consumer.name : '',
			seller: seller !== undefined ? seller.name : '',
			rows: plan_row_list,
			saldo_row: saldo_row,
			buy: buy_direction,
			show_dialog: true
		}));
	}


	function onFilterChange (start, end, domain) {

		setFilter(filter => ({ ...filter,
			domain: parseInt(domain),
			period_start: start,
			period_end: end,
		}));
	}


	function onFilterClick () {

		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({ ...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose () {
	
		setMessage(message => ({...message,
			show: false,
			message: '',
		}));
	}


	async function onDetailsDialogAdd (domain, consumer, seller, trade_date) {

		const new_row = {
			plan_row_id: Math.random(),
			group_id: undefined,
			row_name: `Додатковий обсяг на ${trade_date}`,
			consumer_company_id: consumer,
			seller_company_id: seller,
			domain_id: domain,
			trade_date: getDateFormat(trade_date),
			p01: 0,
			p02: 0,
			p03: 0,
			p04: 0,
			p05: 0,
			p06: 0,
			p07: 0,
			p08: 0,
			p09: 0,
			p10: 0,
			p11: 0,
			p12: 0,
			p13: 0,
			p14: 0,
			p15: 0,
			p16: 0,
			p17: 0,
			p18: 0,
			p19: 0,
			p20: 0,
			p21: 0,
			p22: 0,
			p23: 0,
			p24: getHoursInDate(trade_date) > 23 ? 0 : null,
			p25: getHoursInDate(trade_date) > 24 ? 0 : null
		}

		setDetails(prev_state => ({ ...prev_state,
			rows: [ ...details.rows, new_row ],
		}));
	}


	async function onDetailsDialogSend (send_rows) {

		if (send_rows === undefined || send_rows.length === 0) {
			return;
		}

		let contractor_id = 0;
		if (send_rows[0].seller_company_id === user.company_id) {
			contractor_id = send_rows[0].consumer_company_id;
		} else {
			contractor_id = send_rows[0].seller_company_id;
		}

		const hours = [];
		for (let i = 1; i < 25; i++) {
			hours.push(i.toString());
		}

		try {

			const consumer = global_state.companies.find(item => item.company_id === send_rows[0].consumer_company_id);
			const seller = global_state.companies.find(item => item.company_id === send_rows[0].seller_company_id);
			const trade_date = new Date(send_rows[0].trade_date).toLocaleDateString();
			const domain = global_state.domains.find(item => item.domain_id === send_rows[0].domain_id);

			// create output data
			var output = [];
			// create header
			output[0] = [];
			output[0][0] = 'Дата';
			output[0][1] = trade_date;

			output[1] = [];
			output[1][0] = 'Зона торгівлі';
			output[1][1] = domain.domain_display_name;
			output[1][2] = domain.domain_code_eic;

			output[2] = [];
			output[2][0] = 'Покупець';
			output[2][1] = consumer.name;
			output[2][2] = consumer.eic_x_code;

			output[3] = [];
			output[3][0] = 'Продавець';
			output[3][1] = seller.name;
			output[3][2] = seller.eic_x_code;

			output[4] = [];
			output[4][0] = 'Година';
			output[4].push( ...hours );

			for (let i = 0; i < send_rows.length; i++) {

				output[i + 5] = [];
				output[i + 5][0] = 'Обсяг, МВтг';

				for (let j = 1; j < 25; j++) {
					const index = (j < 10) ? `p0${j}` : `p${j}`;
					output[i + 5][j] = send_rows[i][index];
				}
			}

			const file_name = `confirm_${seller.eic_x_code}_${consumer.eic_x_code}_${trade_date}.xlsx`

			const payload = {
				receiver_company_id: contractor_id,
				trade_date: getDateFormat(send_rows[0].trade_date),
				attachment_name: file_name,
				attachment_content: output,
			}

			const response = await axios.post('/api/communication/confirmation', payload);
			if (response.status === 200) {
				window.alert('Email відправлено.')
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	async function onDetailsDialogClose (result) {

		// save rows
		if (result !== undefined) {

			try {

				for (let i = 0; i < result.length; i++) {

					if (result[i].group_id !== undefined) {

						// update row
						await axios.post('/api/planning/plan/' + result[i].plan_row_id, result[i]);

					} else {

						const group_date = new Date(result[i].trade_date);
						group_date.setDate(1);

						// get group id for specific row
						const result_get_group = await axios.get('/api/planning/specific/group', {
							params: {
								consumer:	result[i].consumer_company_id,
								seller:		result[i].seller_company_id,
								domain:		result[i].domain_id,
								period:		getDateFormat(group_date),
							}
						});
						const group_id = result_get_group.data.group_id;

						const new_row = {
							...result[i],
							group_id: group_id
						}

						// add row
						await axios.post('/api/planning/plan/add', new_row);
					}
				}

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag
				}));
	
			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
	
					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}
	
				console.log(error);
			}
		}

		setDetails(prev_state => ({ ...prev_state,
			consumer: '',
			seller: '',
			rows: [],
			show_dialog: false
		}));
	}


	async function onConfirmEmail (item) {

		setConfirmation(prev_state => ({ ...prev_state,
			in_progres: true
		}));

		const email_content = [];

		for (let a = 0; a < item.attachments.length; a++) {

			try {

				// current attachment
				const doc = item.attachments[a];

				const trade_date = doc.content[0][1];
				let trade_date_obj;

				if (typeof trade_date === 'number') {
					trade_date_obj = excel_date_to_date(trade_date);
				}
				if (typeof trade_date === 'string') {
					trade_date_obj = new Date(trade_date.split('.').reverse().join('-'));
				}

				const domain_code = doc.content[1][2];
				const domain = global_state.domains.find(item => item.domain_code_eic === domain_code);

				const consumer_code = doc.content[2][2];
				const consumer = global_state.companies.find(item => item.eic_x_code === consumer_code);

				const seller_code = doc.content[3][2];
				const seller = global_state.companies.find(item => item.eic_x_code === seller_code);

				if (consumer !== undefined && seller !== undefined) {

					const resp_rows = await axios.get('/api/planning/plans', { params: {
						domain: domain.domain_id,
						consumer_company_id: consumer.company_id,
						seller_company_id: seller.company_id,
						period_start: getDateFormat(trade_date_obj),
						period_end: getDateFormat(trade_date_obj),
					}});

					// 
					let the_working_item;
					// find target element with contractor pair and domain
					const target = email_content.find(item => item.consumer_id === consumer.company_id &&
													  item.seller_id === seller.company_id &&
													  item.domain_id === domain.domain_id &&
													  item.trade_date.getTime() === trade_date_obj.getTime());
					if (target === undefined) {
						the_working_item = {
							domain_id: domain.domain_id,
							domain_name: domain.domain_name,
							seller_id: seller.company_id,
							seller_name: seller.name,
							consumer_id: consumer.company_id,
							consumer_name: consumer.name,
							trade_date: trade_date_obj,
							db_rows: [],
							email_rows: []
						}
					} else {
						the_working_item = target;
					}

					// get existing rows into array avoiding doubles
					const read_rows = resp_rows.data.plan_row_list;
					for (let o = 0; o < read_rows.length; o++) {

						const existing = the_working_item.db_rows.find(item => item.plan_row_id === read_rows[o].plan_row_id);
						if (existing === undefined) {

							const points = [];
							let sum = 0;

							for (let h = 1; h <= getHoursInDate(trade_date_obj); h++) {

								const index = h < 10 ? `p0${h}` : `p${h}`;

								if (read_rows[o][index] !== undefined && read_rows[o][index] !== null) {

									sum += read_rows[o][index];

									points.push({
										index: h,
										value: read_rows[o][index]
									})
	
									delete read_rows[o][index];
								}
							}

							the_working_item.db_rows.push({
								...read_rows[o],
								source_row_id: 0,
								points: points,
								sum: sum,
							});
						}
					}

					for (let r = 5; r < doc.content.length; r++) {

						if (doc.content[r].length === 0) {
							break;
						}

						const points = [];
						let sum = 0;

						for (let h = 1; h <= getHoursInDate(trade_date_obj); h++) {

							sum += doc.content[r][h];

							points.push({
								index: h,
								value: doc.content[r][h]
							})
						}

						const new_row = {
							row_id: 1000 * (a + 1) + (doc.content.length - r),
							group_id: undefined,
							row_name: '',
							consumer_name: consumer.name,
							consumer_company_id: consumer.company_id,
							seller_name: seller.name,
							seller_company_id: seller.company_id,
							domain_id: domain.domain_id,
							trade_date: getDateFormat(trade_date_obj),
							points: points,
							sum: sum,
						}

						// update the row in database
						the_working_item.email_rows.push(new_row);
					}

					if (target === undefined) {
						email_content.push(the_working_item);
					}
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		setConfirmation(prev_state => ({ ...prev_state,
			in_progres: false
		}));

		setGroup(prev_state => ({ ...prev_state,
			email_id: item.email_id,
			email_content: email_content,
			show_dialog: true
		}));
	}


	async function onDeleteEmail (item) {

		if (!window.confirm('Ви впевнені, що бажаєте видалити email?')) {
			return;
		}

		if (item === undefined) {
			return;
		}

		try {

			const response = await axios.post('/api/communication/email/' + item.email_id + '/mark');
			if (response.status === 200) {

				const new_emails = confirmation.emails.filter(mail => mail.email_id !== item.email_id);

				setConfirmation(prev_state => ({ ...prev_state,
					emails: new_emails,
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}
	}


	async function onConfirmationDialogClose () {

		setConfirmation(prev_state => ({ ...prev_state,
			emails: [],
			show_dialog: false
		}));
	}


	async function onGroupSelectionDialogClose (result) {

		if (result === undefined) {

			setGroup(prev_state => ({ ...prev_state,
				email_id: undefined,
				email_content: [],
				show_dialog: false
			}));

			return;
		}

		let rows_updated = 0;
		for (let i = 0; i < result.length; i++) {

			try {

				const update_row = {
					plan_row_id: result[i].plan_row_id,
					group_id: result[i].group_id,
					consumer_company_id: result[i].consumer_company_id,
					seller_company_id: result[i].seller_company_id,
					domain_id: result[i].domain_id,
					row_name: result[i].row_name,
					trade_date: getDateFormat(result[i].trade_date),
					p01: (result[i].points.length > 0) ? result[i].points[0].value : null,
					p02: (result[i].points.length > 1) ? result[i].points[1].value : null,
					p03: (result[i].points.length > 2) ? result[i].points[2].value : null,
					p04: (result[i].points.length > 3) ? result[i].points[3].value : null,
					p05: (result[i].points.length > 4) ? result[i].points[4].value : null,
					p06: (result[i].points.length > 5) ? result[i].points[5].value : null,
					p07: (result[i].points.length > 6) ? result[i].points[6].value : null,
					p08: (result[i].points.length > 7) ? result[i].points[7].value : null,
					p09: (result[i].points.length > 8) ? result[i].points[8].value : null,
					p10: (result[i].points.length > 9) ? result[i].points[9].value : null,
					p11: (result[i].points.length > 10) ? result[i].points[10].value : null,
					p12: (result[i].points.length > 11) ? result[i].points[11].value : null,
					p13: (result[i].points.length > 12) ? result[i].points[12].value : null,
					p14: (result[i].points.length > 13) ? result[i].points[13].value : null,
					p15: (result[i].points.length > 14) ? result[i].points[14].value : null,
					p16: (result[i].points.length > 15) ? result[i].points[15].value : null,
					p17: (result[i].points.length > 16) ? result[i].points[16].value : null,
					p18: (result[i].points.length > 17) ? result[i].points[17].value : null,
					p19: (result[i].points.length > 18) ? result[i].points[18].value : null,
					p20: (result[i].points.length > 19) ? result[i].points[19].value : null,
					p21: (result[i].points.length > 20) ? result[i].points[20].value : null,
					p22: (result[i].points.length > 21) ? result[i].points[21].value : null,
					p23: (result[i].points.length > 22) ? result[i].points[22].value : null,
					p24: (result[i].points.length > 23) ? result[i].points[23].value : null,
					p25: (result[i].points.length > 24) ? result[i].points[24].value : null
				}

				const response = await axios.post('/api/planning/plan/' + update_row.plan_row_id, update_row);
				if (response.status === 200) {
					rows_updated++;
				}
	
			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}
	
				console.log(error);
			}
		}

		window.alert(`${rows_updated} рядів збережено.`);

		// after the rows saved in database, delete email
		if (rows_updated === result.length) {

			const response = await axios.post('/api/communication/email/' + group.email_id + '/mark');
			if (response.status === 200) {
	
				const new_emails = confirmation.emails.filter(mail => mail.email_id !== group.email_id);
	
				setConfirmation(prev_state => ({ ...prev_state,
					emails: new_emails,
				}));
			}
		}

		setGroup(prev_state => ({ ...prev_state,
			email_id: undefined,
			email_content: [],
			show_dialog: false
		}));
	}


	async function onGroupSelectionDialogRowUpdate () {

		setGroup(prev_state => ({ ...prev_state,
			show_dialog: true
		}));
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const res_market_defs = await axios.get('/api/defs/capacities');
				const res_company_data = await axios.get('/api/admin/companies', { params: { all: true } });

				setGlobalState(prev_state => ({ ...prev_state,
					domains: res_market_defs.data.domain_list,
					companies: res_company_data.data.company_list,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		async function fetchData(filter) {

			if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

				try {

					const response_es = await axios.get('/api/es', { params: {
						domain:			filter.domain,
						period_start:	getDateFormat(filter.period_start),
						period_end:		getDateFormat(filter.period_end)
					}});

					const response_plan = await axios.get('/api/planning/plans_total', { params: {
						domain:			filter.domain,
						period_start:	getDateFormat(filter.period_start),
						period_end:		getDateFormat(filter.period_end)
					}});

					const response_pp = await axios.get('/api/planning/production', { params: {
						domain:			filter.domain,
						period_start:	getDateFormat(filter.period_start),
						period_end:		getDateFormat(filter.period_end)
					}});

					setGlobalState(prev_state => ({ ...prev_state,
						external_series: response_es.data.external_series_list,
						plan_rows: response_plan.data.plan_row_list,
						production_plans: response_pp.data.plan_list,
						production_status: response_pp.data.plan_status,
					}));

				} catch(error) {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				}

				setFilter(filter => ({ ...filter, enable: false }));
			}
		}

		fetchData(filter);

	}, [filter])


	const list_external_series = (item) => {

		const cells = [];
		for (let i = 1; i <= 25; i++) {

			let index = (i < 10) ? `p0${i}` : `p${i}`;
			if (item[index] !== null) {
				cells.push({
					id: i,
					value: item[index]
				});
			}
		}

		const out_domain = global_state.domains.find(elem => elem.domain_id === item.out_domain_id);
		const in_domain = global_state.domains.find(elem => elem.domain_id === item.in_domain_id);
		const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
		const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

		return ({
			key: item.external_series_id,
			id: item.external_series_id,
			trade_date: item.trade_date,
			out_domain: (out_domain !== undefined) ? out_domain.domain_display_name : '',
			in_domain: (in_domain !== undefined) ? in_domain.domain_display_name : '',
			consumer: (consumer !== undefined) ? consumer.name : '',
			seller: (seller !== undefined) ? seller.name : '',
			points: cells,
		})
	}


	const list_plan_row = (item) => {

		const cells = [];
		for (let i = 1; i <= 25; i++) {

			let index = (i < 10) ? `p0${i}` : `p${i}`;
			if (item[index] !== null) {
				cells.push({
					id: i,
					value: item[index]
				});
			}
		}

		const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
		const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
		const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

		return ({
			edit: true,
			key: item.plan_row_id,
			id: item.plan_row_id,
			trade_date: item.trade_date,
			domain: (domain !== undefined) ? domain.domain_display_name : '',
			consumer: (consumer !== undefined) ? consumer.name : '',
			seller: (seller !== undefined) ? seller.name : '',
			consumer_company_id: item.consumer_company_id,
			seller_company_id: item.seller_company_id,
			points: cells,
		})
	}


	var planning_rows = {
		income: [],
		outcome: [],
	};


	var domain_list = [];
	var display_companies = [];
	var display_production_plans = [];
	var display_production_plans_status = [];

	if ((global_state.domains.length > 0) && (global_state.companies.length > 0)) {

		domain_list = [
			<option key={1} value={1}>ОЕС</option>,
			<option key={2} value={2}>БУОС</option>
		];

		display_companies = global_state.companies.map(company => {
			return (
				<option key={ company.company_id } value= { company.company_id } >{ company.name }</option>
			)
		})
		display_companies.unshift(<option key={-1} value={''} ></option>)

		if (global_state.external_series.length > 0) {
			global_state.external_series.forEach(item => {

				const row = list_external_series(item);

				if (item.seller_company_id === user.company_id) {
					planning_rows.outcome.push(row);
				}
				if (item.consumer_company_id === user.company_id) {
					planning_rows.income.push(row);
				}
			})
		}

		if (global_state.plan_rows.length > 0) {
			global_state.plan_rows.forEach(item => {

				const row = list_plan_row(item);

				if (item.seller_company_id === user.company_id) {
					planning_rows.outcome.push(row);
				}
				if (item.consumer_company_id === user.company_id) {
					planning_rows.income.push(row);
				}
			})
		}

		if (global_state.production_plans.length > 0) {
			display_production_plans = global_state.production_plans.map(item => {

				const cells = [];
				for (let i = 1; i <= 25; i++) {

					let index_value = (i < 10) ? `p0${i}` : `p${i}`;
					let index_min = (i < 10) ? `p0${i}_min` : `p${i}_min`;
					let index_max = (i < 10) ? `p0${i}_max` : `p${i}_max`;

					if (item[index_value] !== null) {
						cells[i] = {
							id: i,
							min: item[index_min],
							value: item[index_value],
							max: item[index_max],
						};
					}
				}

				return ({
					key: item.production_plan_id,
					id: item.production_plan_id,
					plan_date: getLocalDateString(item.plan_date),
					domain: global_state.domains.find(elem => elem.domain_id === item.domain_id).domain_display_name,
					production_company: global_state.companies.find(elem => elem.company_id === item.production_company_id).name,
					points: cells,
				})
			})
		}

		if (global_state.production_status.length > 0) {
			display_production_plans_status = global_state.production_status.map(item => {

				const cells = [];
				for (let i = 1; i <= 25; i++) {

					let index_value = (i < 10) ? `p0${i}` : `p${i}`;
					let index_min = (i < 10) ? `p0${i}_min` : `p${i}_min`;
					let index_max = (i < 10) ? `p0${i}_max` : `p${i}_max`;

					if (item[index_value] !== null) {
						cells[i] = {
							id: i,
							min: item[index_min],
							value: item[index_value],
							max: item[index_max],
						};
					}
				}

				return ({
					key: item.production_plan_id,
					id: item.production_plan_id,
					plan_date: getLocalDateString(item.plan_date),
					domain: global_state.domains.find(elem => elem.domain_id === item.domain_id).domain_display_name,
					production_company: global_state.companies.find(elem => elem.company_id === item.production_company_id).name,
					points: cells,
				})
			})
		}
	}


	if (!user.is_logged_in || !(is_user_authorised(user.authorisation, 20) || is_user_authorised(user.authorisation, 22))) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<planningContext.Provider value={{ global_state, setGlobalState }}>
				<Switch>
					<Route
						path='/planning/plan_groups/:group_id'
						component={ GroupEditForm }
					/>
					<Route
						path='/planning/plan_rows/:plan_row_id'
						component={ PlanRowEditForm }
					/>
					<Route
						path='/planning/production/add'
						component={ ProductionPlanEditForm }
					/>
					<Route
						path='/planning/production/:production_plan_id'
						component={ ProductionPlanEditForm }
					/>
					<Route
						path='/planning/monthly_plan'
						component={ MonthlyPlanning }
					/>
					<Route
						path='/planning'
					>

						<div className = { classes.root } >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Планування
									</Typography>

									{ (tab_no === 1) && 
									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={ handleAddAction }
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no !== 0) && 
									<Tooltip title='Відкрити'>
										<span>
											<IconButton
												onClick={ handleOpenAction }
												disabled={ pp_selected.length + pp_status_selected.length !== 1 }
												color='inherit'
											>
												<OpenIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no === 1) && 
									<Tooltip title='Видалити'>
										<span>
											<IconButton
												onClick={ handleDeleteAction }
												disabled={ pp_selected.length !== 1 }
												color='inherit'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip> }

									{ (tab_no === 1) && 
									<Tooltip title='Відправити план виробництва'>
										<span>
											<IconButton
												onClick={ handleSendProductionPlanAction }
												disabled={ pp_selected.length !== 1 }
												color='inherit'
											>
												<SendPlanIcon />
											</IconButton>
										</span>
									</Tooltip> }

									<FilterPeriodDomain
										period_start={filter.period_start}
										period_end={filter.period_end}
										domain={filter.domain}
										doamin_list={ domain_list }
										onFilterChange={onFilterChange}
										onFilterClick={onFilterClick}
									/>

									<div className={classes.right_button}>

										{ (tab_no === 0) &&
										<Tooltip title='Отримати погодження з EMAIL' >
											<IconButton
												onClick={ handleGetMails }
												color='inherit'
												disabled={ confirmation.in_progres }
											>
												<GetMailsIcon />
											</IconButton>
										</Tooltip> }

										{ (tab_no === 0) && 
										<Tooltip title='Місячне плануання' >
											<IconButton
												onClick={ () => props.history.push('/planning/monthly_plan') }
												color='inherit'
											>
												<MonthlyPlanIcon />
											</IconButton>
										</Tooltip> }

										<IconButton
											onClick={ () => props.history.push('/') }
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<PlanningTable
								period_start={filter.period_start}
								period_end={filter.period_end}
								rows={planning_rows}
								action_function={onCreateDamOrderHandle}
								details_function={onGetPlanningRowDetails}
							/>

							{/* <Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label='Планування обсягів' {...getTabsProps(0)} />
								<Tab label='Планування виробництва' {...getTabsProps(1)} />
								<Tab label='Статус виробництва' {...getTabsProps(2)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<PlanningTable
									period_start={filter.period_start}
									period_end={filter.period_end}
									rows={planning_rows}
									action_function={onCreateDamOrderHandle}
									details_function={onGetPlanningRowDetails}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={1}>

								<ProductionPlanTable
									rows={display_production_plans}
									selected={pp_selected}
									setSelected={setPpSelected}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<ProductionPlanTable
									rows={display_production_plans_status}
									selected={pp_status_selected}
									setSelected={setPpStatusSelected}
								/>

							</TabPanel> */}

						</div>

						<PlanRowsDetailsDialog
							consumer={ details.consumer }
							seller={ details.seller }
							open={ details.show_dialog }
							rows={ details.rows }
							row_to_add={ details.saldo_row }
							add_direction={ details.buy }
							onClose={ onDetailsDialogClose }
							onSend={ onDetailsDialogSend }
							onAdd={ onDetailsDialogAdd }
						/>

						<PlanRowsConfirmationDialog
							confirmations={ confirmation.emails }
							open={ confirmation.show_dialog }
							onClose={ onConfirmationDialogClose }
							onConfirm={ onConfirmEmail }
							onDelete={ onDeleteEmail }
						/>

						<GroupSelectionDialog
							open={ group.show_dialog }
							email_content={ group.email_content }
							onClose={ onGroupSelectionDialogClose }
							onRowUpdate={ onGroupSelectionDialogRowUpdate }
						/>

						<WaitDialog
							open={confirmation.in_progres}
						/>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ '' }
							onClose={ onErrorMessageClose }
						/>

					</Route>
				</Switch>
			</planningContext.Provider>
		)
	}
}

export default withRouter(Planning);