import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import axios from 'axios';
import { saveAs } from 'file-saver';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},


	form_control: {
		display: 'inline-block',
		width: '300px',
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		border: '1px solid gray',
		borderRadius: '10px',
		alignItems: 'center',
	},

	select_control: {
		marginBottom: theme.spacing(2),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


function BlocksForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	}
	const [message, setMessage] = useState(initial_message);

	const initial_state = {
		period_start: '',
		period_end: '',
		station: '',
		station_list: [],
	}
	const [local_state, setLocalState] = useState(initial_state);

	// state for select control
	const station_label = React.useRef(null);
	const [station_label_width, setStationLabelWidth] = useState(0);

	
	function handlePeriodStartChange (event) {

		const event_value = event.target.value;

		setLocalState(local_state => ({ ...local_state,
			period_start: event_value,
		}));
	}


	function handlePeriodEndChange (event) {

		const event_value = event.target.value;

		setLocalState(local_state => ({ ...local_state,
			period_end: event_value,
		}));
	}


	function handleStationChange (event) {

		const event_value = event.target.value;

		setLocalState(local_state => ({ ...local_state,
			station: event_value
		}));
	}


	async function onDownloadHandler(e) {

		e.preventDefault();

		try {

			const resonse = await axios.get('/api/blocks', {
				params: {
					period_start:	getDateFormat(local_state.period_start),
					period_end:		getDateFormat(local_state.period_end),
					station:		(local_state.station === '') ? null : local_state.station
				}
			});

			const file_name = local_state.period_start + '_' + local_state.period_end + '_' + local_state.station + '.csv';
			const result = resonse.data.blocks_list;

			var output_text = '';

			result.forEach((item, i) => {

				// get keys
				const keys = Object.keys(item);

				if (i === 0) {
					// output keys as header of the file
					keys.forEach(entry => {
						output_text += entry + ';';
					})

					// line break
					output_text += '\n';
				}

				keys.forEach(entry => {
					if (item[entry] !== null) {
						output_text += item[entry] + ';';
					} else {
						output_text += ';';
					}
				})

				// line break
				output_text += '\n';
			})

			// get string to blob
			const blob = new Blob([ output_text ], { type: 'text/csv' });
			// save blob to file
			saveAs(blob, file_name);

		} catch(e) {

			setMessage(prev_state => ({...prev_state,
				show: true,
				message: e.message,
			}));
		}
	}


	function onErrorMessageClose () {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {
		setStationLabelWidth(station_label.current.offsetWidth);
	}, [])


	useEffect(() => {

		axios.get('/api/blocks/stations')
			.then(response => {

				if (response.data !== null) {
					setLocalState(local_state => ({ ...local_state,
						station_list: response.data.station_list
					}));
				}
			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});
	}, [])


	var display_stations = [];
	if (local_state.station_list.length > 0) {
		local_state.station_list.forEach((item, i) => {
			display_stations.push(<option key={ i } value= { item.station } >{ item.station }</option>);
		})
		display_stations.unshift(<option key={-1} value={''} ></option>)
	}


	return (

		<div className = { classes.root } >

			<AppBar position='static'>
				<Toolbar>

					<Typography className={classes.title} variant='h6' noWrap>
						Стан блоків
					</Typography>

					<IconButton
						className={classes.right_button}
						onClick={ () => props.history.push('/') }
						color='inherit'
					>
						<BackIcon />
					</IconButton>

				</Toolbar>
			</AppBar>

			<form onSubmit={ onDownloadHandler } className = { classes.form_control } >

				<TextField
					className={ classes.select_control }
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					required
					fullWidth
					label='Період з'
					type='datetime-local'
					value={ local_state.period_start }
					onChange={ handlePeriodStartChange }
				/>

				<TextField
					className={ classes.select_control }
					InputLabelProps={{ shrink: true }}
					variant='outlined'
					required
					fullWidth
					label='Період до'
					type='datetime-local'
					value={ local_state.period_end }
					onChange={ handlePeriodEndChange }
				/>

				<FormControl variant='outlined' fullWidth>
					<InputLabel ref={ station_label } id='station-label'>Станція</InputLabel>
					<Select
						native
						labelId='station-label'
						labelWidth={ station_label_width }
						onChange={ handleStationChange }
						className={ classes.select_control }
						value={ local_state.station }
					>
						{ display_stations }
					</Select>
				</FormControl>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
					>
						Завантажити
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>

	);
}

export default withRouter(BlocksForm);