import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import OpenIcon from '@material-ui/icons/FolderOpenSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import HistoryIcon from '@material-ui/icons/HistorySharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import ScheduleTable from './subcomponents/SchedulesTable';
import TimeSeriesTable from './subcomponents/TimeSeriesTable';
import FilterPeriod from '../subcomponents/FilterPeriod';
import FilterDate from '../subcomponents/FilterDate';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import ScheduleEditForm from './ScheduleEditForm';
import ScheduleImportExportForm from './ScheduleImportExportForm';
import ScheduleDownloadForm from './ScheduleDownloadForm';
import DocumentMmsUploadForm from './DocumentMmsUploadForm';
import HistoryDialog from '../subcomponents/HistoryDialog';
import NotAuthorised from '../auth/NotAuthorised';

import { globalContext } from '../layout/Context';
import { bcContext } from './bcContext';

import { is_valid_date } from '../utils/validation';
import { getDateFormat, getLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function Schedules(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_dialog = {
		show: false,
		rows: []
	}
	const [history_dialog, setHistoryDialog] = useState(initial_dialog);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	// table variables
	const [draft_schedules_selected, setDraftSchedulesSelected] = React.useState([]);
	const [send_schedules_selected, setSendSchedulesSelected] = React.useState([]);
	const [active_schedules_selected, setActiveSchedulesSelected] = React.useState([]);
	const [confirmed_schedules_selected, setConfirmedSchedulesSelected] = React.useState([]);
	const [archived_schedules_selected, setArchivedSchedulesSelected] = React.useState([]);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id:					`schedules-tab-${index}`,
			'aria-controls':	`schedules-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
			<div
				hidden={value !== index}
				id={`schedules-tabpanel-${index}`}
				aria-labelledby={`schedules-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {

		setDraftSchedulesSelected([]);
		setSendSchedulesSelected([]);
		setActiveSchedulesSelected([]);
		setConfirmedSchedulesSelected([]);
		setArchivedSchedulesSelected([]);

		setTabNo(tab);
	}


	function createInitialState () {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			statuses: [],
			market_roles: [],
			companies: [],

			// component states
			draft_schedules: [],
			active_schedules: [],
			confirmed_schedules: [],
			archived_schedules: [],

			time_series: [],
		}

		return complex_state;
	}


	function createInitialFilter () {

		// set start and end dates
		let day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		let day_2 = new Date();
		day_2.setHours(48, 0, 0, 0);

		const complex_state = {
			enable: false,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function handleAddSchedule() {
		props.history.push('/schedules/add');
	}


	function handleOpenSchedule(schedule_id) {

		if (schedule_id !== undefined) {
			props.history.push('/schedules/' + schedule_id);
		}

		OnTabChage(undefined, tab_no);
	}


	async function handleOpenScheduleHistory(schedule_id) {

		if (schedule_id === undefined) return;

		try {

			const id = parseInt(schedule_id);
			const response = await axios.get('/api/bc/schedules/' + id + '/history');

			if (response.status === 200) {

				setHistoryDialog(dialog => ({ ...dialog,
					show: true,
					rows: response.data
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleCreateCopySchedule(schedule_id) {

		if (schedule_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте створити копію часового графіку?')) {
			return;
		}

		try {

			const id = parseInt(schedule_id);
			const response = await axios.post('/api/bc/schedules/' + id + '/copy');

			if (response.status === 200) {

				setGlobalState(state => ({ ...state,
					update_flag: !state.update_flag,
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({
					...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleOpenTimeSeriesHistory(time_series_id) {

		if (time_series_id === undefined) return;

		try {

			const id = parseInt(time_series_id);
			const response = await axios.get('/api/bc/ts/' + id + '/history');

			if (response.status === 200) {

				setHistoryDialog(dialog => ({ ...dialog,
					show: true,
					rows: response.data
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleCreateCopyTimeSeries(time_series_id) {

		if (time_series_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте створити копію часового ряду?')) {
			return;
		}

		try {

			const id = parseInt(time_series_id);
			const response = await axios.post('/api/bc/ts/' + id + '/copy');

			if (response.status === 200) {

				setGlobalState(state => ({ ...state,
					update_flag: !state.update_flag,
				}));
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({
					...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleDeleteSchedule(schedule_id) {

		if (schedule_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте видалити часовий графік?')) {
			return;
		}

		try {

			const id = parseInt(schedule_id);
			const response = await axios.post('/api/bc/schedules/' + id + '/delete');

			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				setDraftSchedulesSelected([]);
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleDeleteMmsSchedule(schedule_id) {

		if (schedule_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте видалити часовий графік з MMS сервера?')) {
			return;
		}

		try {

			const id = parseInt(schedule_id);
			const response = await axios.post('/api/bc/schedules/mms/' + id + '/delete');

			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	function handleOpenImportExportForm() {

		const selected_schedule_id = get_selected_schedule_id();
		const schedule_id = (selected_schedule_id !== undefined) ? selected_schedule_id : 0;

		props.history.push('/schedules/import/' + schedule_id + '/0');
	}


	function handleScheduleAction(action_id, schedule_id) {

		switch (action_id) {

			case 0:
				handleOpenSchedule(schedule_id);
				break;
			case 1:
				handleDeleteSchedule(schedule_id);
				break;
			case 2:
				handleOpenScheduleHistory(schedule_id);
				break;
			case 3:
				handleCreateCopySchedule(schedule_id);
				break;
			case 4:
				handleDeleteMmsSchedule(schedule_id);
				break;
			default:
				break;
		}
	}


	function handleTimeSeriesAction(action_id, time_series_id) {

		switch (action_id) {

			case 2:
				handleOpenTimeSeriesHistory(time_series_id);
				break;
			case 3:
				handleCreateCopyTimeSeries(time_series_id);
				break;
			default:
				break;
		}
	}


	function onPeriodFilterChange(start, end) {

		setFilter(filter => ({ ...filter,
			period_start: start,
			period_end: end,
		}));
	}


	function onDateFilterChange(value) {

		let day_2 = new Date(value);
		day_2.setHours(24, 0, 0, 0);

		setFilter(filter => ({ ...filter,
			period_start: value,
			period_end: getDateFormat(day_2)
		}));
	}


	function onFilterClick() {

		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({ ...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose () {
	
		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	function onHistoryDialogClose() {

		setHistoryDialog(dialog => ({ ...dialog,
			show: false,
			rows: []
		}));
	}


	function get_selected_schedule() {

		if (draft_schedules_selected.length +
			send_schedules_selected.length +
			active_schedules_selected.length +
			confirmed_schedules_selected.length +
			archived_schedules_selected.length === 1) {
			
			let selected_id = null;
			if (draft_schedules_selected.length === 1) {
				selected_id = draft_schedules_selected[0];
				return global_state.draft_schedules.find(item => item.schedule_id === selected_id);
			}
			if (send_schedules_selected.length === 1) {
				selected_id = send_schedules_selected[0];
				return global_state.draft_schedules.find(item => item.schedule_id === selected_id);
			}
			if (active_schedules_selected.length === 1) {
				selected_id = active_schedules_selected[0];
				return global_state.active_schedules.find(item => item.schedule_id === selected_id);
			}
			if (confirmed_schedules_selected.length === 1) {
				selected_id = confirmed_schedules_selected[0];
				return global_state.confirmed_schedules.find(item => item.schedule_id === selected_id);
			}
			if (archived_schedules_selected.length === 1) {
				selected_id = archived_schedules_selected[0];
				return global_state.archived_schedules.find(item => item.schedule_id === selected_id);
			}

		} else {
			return undefined;
		}
	}


	function get_selected_schedule_id() {

		let selected_id = undefined;

		switch(tab_no) {

			case 0:
				selected_id = draft_schedules_selected.length > 0 ? draft_schedules_selected[0] : undefined;
				break;

			case 1:
				selected_id = send_schedules_selected.length > 0 ? send_schedules_selected[0] : undefined;
				break;

			case 2:
				selected_id = active_schedules_selected.length > 0 ? active_schedules_selected[0] : undefined;
				break;

			case 3:
				selected_id = confirmed_schedules_selected.length > 0 ? confirmed_schedules_selected[0] : undefined;
				break;

			case 4:
				selected_id = archived_schedules_selected.length > 0 ? archived_schedules_selected[0] : undefined;
				break;

			default:
				break;
		}

		return selected_id;
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/bc');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({ ...prev_state,
					domains: res_market_defs.data.domain_list,
					statuses: res_market_defs.data.status_list,
					market_roles: res_market_defs.data.roles_list,
					companies: res_company_data.data.company_list
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

			axios.get('/api/bc/schedules', { params: {
				period_start:	getDateFormat(filter.period_start),
				period_end:		getDateFormat(filter.period_end)
			}})
				.then(response => {

					let drafts = response.data.schedules_list.filter(item => item.status_id === 1);
					let active = response.data.schedules_list.filter(item => item.status_id === 2 || item.status_id === 3);
					let confirmed = response.data.schedules_list.filter(item => item.status_id === 4);
					let archived = response.data.schedules_list.filter(item => item.status_id === 5);

					setGlobalState(prev_state => ({ ...prev_state,
						draft_schedules: drafts,
						active_schedules: active,
						confirmed_schedules: confirmed,
						archived_schedules: archived,
						time_series: response.data.time_series_list,
					}));

				})
				.catch(error => {
	
					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

				setFilter(filter => ({ ...filter, enable: false }));
		}

	}, [filter])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({
							...message,
							show: true,
							info: `${ first_name } ${ last_name } \n ${ email } \n ${ phone }`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}

	}, [message.edit])


	const list_schedule = (item) => {

		const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
		const status = global_state.statuses.find(elem => elem.status_id === item.status_id);
		const sender = global_state.companies.find(elem => elem.company_id === item.sender_company_id);

		return ({
			key: item.schedule_id,
			id: item.schedule_id,
			domain: (domain !== undefined) ? domain.domain_display_name : item.domain,
			status: (status !== undefined) ? status.status_name : '',
			revision: item.revision,
			start: getLocalDateString(item.trade_date),
			sender: (sender !== undefined) ? sender.name : '',
			amount: (item.amount) ? item.amount.toFixed(1) : 0,
		})
	}


	var draft_schedules_rows = [];
	var active_schedules_rows = [];
	var confirmed_schedules_rows = [];
	var archived_schedules_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		if (global_state.draft_schedules.length > 0) {
			draft_schedules_rows = global_state.draft_schedules.map(item => list_schedule(item))
		}

		if (global_state.active_schedules.length > 0) {
			active_schedules_rows = global_state.active_schedules.map(item => list_schedule(item))
		}

		if (global_state.confirmed_schedules.length > 0) {
			confirmed_schedules_rows = global_state.confirmed_schedules.map(item => list_schedule(item))
		}

		if (global_state.archived_schedules.length > 0) {
			archived_schedules_rows = global_state.archived_schedules.map(item => list_schedule(item))
		}
	}

	var time_series_rows = [];
	var schedule_time_series_ids = [];

	const schedule_selected = get_selected_schedule();
	if (schedule_selected !== undefined) {

		schedule_selected.time_series.forEach(item => schedule_time_series_ids.push(item.time_series_id));
		if ((global_state.time_series.length > 0) && (global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

			time_series_rows = global_state.time_series
				.filter(item => schedule_time_series_ids.includes(item.time_series_id))
				.map(item => {
					return ({
						key: item.time_series_id,
						id: item.time_series_id,
						domain: global_state.domains.find(elem => elem.domain_id === item.domain_id).domain_display_name,
						status: global_state.statuses.find(elem => elem.status_id === item.status_id).status_name,
						revision: item.revision,
						start: getLocalDateString(item.trade_date),
						consumer: global_state.companies.find(elem => elem.company_id === item.consumer_company_id).name,
						seller: global_state.companies.find(elem => elem.company_id === item.seller_company_id).name,
						amount: item.amount.toFixed(1),
					})
			})
		}
	}


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 11)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<bcContext.Provider value={{ global_state, setGlobalState, list_schedule }}>
				<Switch>
					<Route
						path='/schedules/import/:schedule_id/:time_series_id'
						component={ ScheduleImportExportForm }
					/>
					<Route
						path='/schedules/add'
						component={ ScheduleEditForm }
					/>
					<Route
						exact
						path='/schedules/upload'
						component={ DocumentMmsUploadForm }
					/>
					<Route
						path='/schedules/:schedule_id'
						component={ ScheduleEditForm }
					/>
					<Route
						path='/schedules'
					>

						<div className = { classes.root } >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Графіки
									</Typography>

									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={ handleAddSchedule }
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Відкрити'>
										<span>
											<IconButton
												onClick={() => handleOpenSchedule(get_selected_schedule_id())}
												color='inherit'
												disabled = { draft_schedules_selected.length +
															send_schedules_selected.length +
															active_schedules_selected.length +
															confirmed_schedules_selected.length +
															archived_schedules_selected.length !== 1 }
											>
												<OpenIcon />
											</IconButton>
										</span>
									</Tooltip>

									{ (tab_no === 0) && 
									<Tooltip title='Видалити'>
										<span>
											<IconButton
												onClick={() => handleDeleteSchedule(get_selected_schedule_id())}
												disabled={ draft_schedules_selected.length !== 1 }
												color='inherit'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip> }

									<Tooltip title='Імпорт / експорт'>
										<span>
											<IconButton
												onClick={handleOpenImportExportForm}
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Історія графіку'>
										<span>
											<IconButton
												onClick={() => handleOpenScheduleHistory(get_selected_schedule_id())}
												color='inherit'
												disabled = { draft_schedules_selected.length +
															send_schedules_selected.length +
															active_schedules_selected.length +
															confirmed_schedules_selected.length +
															archived_schedules_selected.length !== 1 }
											>
												<HistoryIcon />
											</IconButton>
										</span>
									</Tooltip>

									{ (tab_no !== 1) &&
									<FilterPeriod
										period_start={filter.period_start}
										period_end={filter.period_end}
										onFilterChange={onPeriodFilterChange}
										onFilterClick={onFilterClick}
									/> }

									{ (tab_no === 1) &&
									<FilterDate
										date={filter.period_start}
										onFilterChange={onDateFilterChange}
										onFilterClick={onFilterClick}
									/> }

									<IconButton
										className={classes.right_button}
										onClick={ () => props.history.push('/') }
										color='inherit'
									>
										<BackIcon />
									</IconButton>

								</Toolbar>
							</AppBar>

							<Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label={ <Badge badgeContent={global_state.draft_schedules.length} color='primary'>Чернетки</Badge>} {...getTabsProps(0)} />
								<Tab label='Відправлені / подані' {...getTabsProps(1)} />
								<Tab label={ <Badge badgeContent={global_state.active_schedules.length} color='primary'>В роботі</Badge>} {...getTabsProps(2)} />
								<Tab label={ <Badge badgeContent={global_state.confirmed_schedules.length} color='primary'>Підтверджені</Badge>} {...getTabsProps(3)} />
								<Tab label={ <Badge badgeContent={global_state.archived_schedules.length} color='primary'>Архівні</Badge>} {...getTabsProps(4)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<ScheduleTable
									rows={draft_schedules_rows}
									selected={draft_schedules_selected}
									setSelected={setDraftSchedulesSelected}
									onOpenRequest={handleOpenSchedule}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 1, name: 'Видалити' },
										{ id: 2, name: 'Історія графіку' }
									]}
									onActionSelect={handleScheduleAction}
								/>

								{ (time_series_rows.length !== 0) ?
								<TimeSeriesTable
									rows={time_series_rows}
									selected={schedule_time_series_ids}
									setSelected={() => {}}
									action_list={[
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/> : <p>Виберіть графік для відображення пов'язаних часових рядів.</p> }

							</TabPanel>

							<TabPanel value={tab_no} index={1}>

								<ScheduleDownloadForm
									date={filter.period_start}
									offline_schedules_selected={send_schedules_selected}
									setOfflineSchedulesSelected={setSendSchedulesSelected}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<ScheduleTable
									rows={active_schedules_rows}
									selected={active_schedules_selected}
									setSelected={setActiveSchedulesSelected}
									onOpenRequest={handleOpenSchedule}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія графіку' },
										{ id: 3, name: 'Створити копію' },
										{ id: 4, name: 'Видалити' },
									]}
									onActionSelect={handleScheduleAction}
								/>

								{ (time_series_rows.length !== 0) ?
								<TimeSeriesTable
									rows={time_series_rows}
									selected={schedule_time_series_ids}
									setSelected={() => {}}
									action_list={[
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/> : <p>Виберіть графік для відображення пов'язаних часових рядів.</p> }

							</TabPanel>

							<TabPanel value={tab_no} index={3}>

								<ScheduleTable
									rows={confirmed_schedules_rows}
									selected={confirmed_schedules_selected}
									setSelected={setConfirmedSchedulesSelected}
									onOpenRequest={handleOpenSchedule}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія графіку' },
										{ id: 3, name: 'Створити копію' },
										{ id: 4, name: 'Видалити' },
									]}
									onActionSelect={handleScheduleAction}
								/>

								{ (time_series_rows.length !== 0) ?
								<TimeSeriesTable
									rows={time_series_rows}
									selected={schedule_time_series_ids}
									setSelected={() => {}}
									action_list={[
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/> : <p>Виберіть графік для відображення пов'язаних часових рядів.</p> }

							</TabPanel>

							<TabPanel value={tab_no} index={4}>

								<ScheduleTable
									rows={archived_schedules_rows}
									selected={archived_schedules_selected}
									setSelected={setArchivedSchedulesSelected}
									onOpenRequest={handleOpenSchedule}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія графіку' },
										{ id: 3, name: 'Створити копію' },
										{ id: 4, name: 'Видалити' },
									]}
									onActionSelect={handleScheduleAction}
								/>

								{ (time_series_rows.length !== 0) ?
								<TimeSeriesTable
									rows={time_series_rows}
									selected={schedule_time_series_ids}
									setSelected={() => {}}
									action_list={[
										{ id: 2, name: 'Історія ряду' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleTimeSeriesAction}
								/> : <p>Виберіть графік для відображення пов'язаних часових рядів.</p> }

							</TabPanel>

						</div>

						<MessageErrorSnackbar
							open={ message.show }
							message={ message.message }
							info={ message.info }
							onClose={ onErrorMessageClose }
						/>

						<HistoryDialog
							open={history_dialog.show}
							onClose={onHistoryDialogClose}
							rows={history_dialog.rows}
						/>

					</Route>
				</Switch>
			</bcContext.Provider>
		)
	}
}

export default withRouter(Schedules)