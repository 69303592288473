import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table_head: {
		padding: theme.spacing(1),
		fontWeight: 'bold',
		fontSize: '12px',
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: 'white'
	},

	table_section: {
		background: 'lightblue',
		fontSize: '12px',
		textAlign: 'center',
		padding: theme.spacing(1),
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		padding: theme.spacing(1),
		textAlign: 'left',
		width: '70px'
	},

	split_row: {
		padding: theme.spacing(1),
	},

	summary: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'lightgray',
		padding: theme.spacing(1),
		textAlign: 'right',
		color: 'darkgreen',
	},

	summary_all: {
		fontWeight: 'bold',
		fontSize: '12px',
		background: 'lightgreen',
		padding: theme.spacing(1.5),
		textAlign: 'right',
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(1),
		width: '75px',
	},

}));


// Create number formatters
const curFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
});
const valFormat = new Intl.NumberFormat('ua', {
	minimumFractionDigits: 1,
	maximumFractionDigits: 1
});


export default function SummaryReport3(props) {
	const classes = useStyles();

	const { report } = props;

	return (
		<Paper className={classes.paper}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								key='head_1'
								className={classes.table_head}
							>
								Продавець
							</TableCell>
							<TableCell
								key='head_2'
								className={classes.table_head}
							>
								Покупець
							</TableCell>
							<TableCell
								key='head_3'
								className={classes.table_head}
							>
								Вхідний залишок, грн
							</TableCell>
							<TableCell
								key='head_4'
								className={classes.table_head}
							>
								Обсяг, МВтг
							</TableCell>
							<TableCell
								key='head_5'
								className={classes.table_head}
							>
								Ціна без ПДВ, грн
							</TableCell>
							<TableCell
								key='head_6'
								className={classes.table_head}
							>
								Вартість з ПДВ, грн
							</TableCell>
							<TableCell
								key='head_7'
								className={classes.table_head}
							>
								Оплата з ПДВ, грн
							</TableCell>
							<TableCell
								key='head_8'
								className={classes.table_head}
							>
								Маржінальність, грн
							</TableCell>
							<TableCell
								key='head_9'
								className={classes.table_head}
							>
								Залишок, грн
							</TableCell>
						</TableRow>
					</TableHead>
					{
						(report.oes_sell && report.oes_sell.length > 0) &&
						<TableBody
							key={'sell_oes'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Продаж на РДД в ОЕС
								</TableCell>
							</TableRow>
							{
								report.oes_sell.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:1`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.seller}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:2`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.consumer}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:3`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.pre_payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:4`}
													className={classes.cells}
												>
													{valFormat.format(group.volume)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:5`}
													className={classes.cells}
												>
													{curFormat.format(group.price)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:6`}
													className={classes.cells}
												>
													{curFormat.format(group.amount_vat)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:7`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:8`}
													className={classes.cells}
												>
													{curFormat.format(group.margin)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:9`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.ballance)}
													</TableCell>
												}
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього продаж в ОЕС
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_sell_summary.pre_payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{valFormat.format(report.oes_sell_summary.volume)}
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_sell_summary.amount * 1.2)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_sell_summary.payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_sell_summary.margin)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_sell_summary.ballance)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						(report.buos_sell && report.buos_sell.length > 0) &&
						<TableBody
							key={'sell_buos'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Продаж на РДД в БУОС
								</TableCell>
							</TableRow>
							{
								report.buos_sell.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:1`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.seller}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:2`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.consumer}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:3`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.pre_payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:4`}
													className={classes.cells}
												>
													{valFormat.format(group.volume)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:5`}
													className={classes.cells}
												>
													{curFormat.format(group.price)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:6`}
													className={classes.cells}
												>
													{curFormat.format(group.amount_vat)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:7`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:8`}
													className={classes.cells}
												>
													{curFormat.format(group.margin)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:9`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.ballance)}
													</TableCell>
												}
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього продаж в БУОС
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_sell_summary.pre_payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{valFormat.format(report.buos_sell_summary.volume)}
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_sell_summary.amount * 1.2)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_sell_summary.payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_sell_summary.margin)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_sell_summary.ballance)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.dam_sell && report.idm_sell && report.market_sell_summary &&
						<TableBody
							key={'sell_dam_idm'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Продаж на РДН та ВДР
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									Зона торгівлі
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Оператор ринку
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Вартість з ПДВ, грн
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									rowSpan={2}
									className={classes.head}
								>
									ОЕС
								</TableCell>
								<TableCell
									className={classes.head}
								>
									РДН
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.oes_amount / report.dam_sell.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.oes_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									ВДР
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.oes_amount / report.idm_sell.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.oes_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									rowSpan={2}
									className={classes.head}
								>
									БУОС
								</TableCell>
								<TableCell
									className={classes.head}
								>
									РДН
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.buos_amount / report.dam_sell.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_sell.buos_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									ВДР
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.buos_amount / report.idm_sell.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_sell.buos_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього продаж на РДН та ВДР
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{ valFormat.format(report.market_sell_summary.volume) }
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{ curFormat.format(report.market_sell_summary.amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
									className={classes.summary}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.export && report.export.length > 0 &&
						<TableBody
							key={'export'}
						>
							<TableRow>
								<TableCell
									rowSpan={report.import.length + 1}
									className={classes.head}
								>
									Експорт
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Країна
								</TableCell>
								<TableCell
									className={classes.head}
								/>
								<TableCell
									className={classes.head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									colSpan={3}
									className={classes.head}
								/>
								<TableCell
									className={classes.head}
								>
									Маржінальність, грн
								</TableCell>
								<TableCell
									className={classes.head}
								/>
							</TableRow>
							{
								report.export.map(row => (
									<TableRow
										key={row.out_domain_id}
									>
										<TableCell
											className={classes.head}
										>
											{ row.out_domain }
										</TableCell>
										<TableCell
											className={classes.head}
										>
											{ row.in_domain }
										</TableCell>
										<TableCell
											className={classes.cells}
										/>
										<TableCell
											className={classes.cells}
										>
											{ valFormat.format(row.volume) }
										</TableCell>
										<TableCell
											colSpan={3}
											className={classes.cells}
										/>
										<TableCell
											className={classes.cells}
										>
											{ curFormat.format(row.margin) }
										</TableCell>
										<TableCell
											className={classes.cells}
										/>
									</TableRow>
								))
							}
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.total_sell &&
						<TableBody
							key='total_sell'
						>
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary_all}
								>
									Всього продаж
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ valFormat.format(report.total_sell.volume) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_sell.amount * 1.2) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_sell.margin) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_sell.ballance) }
								</TableCell>
							</TableRow>
						</TableBody>
					}
					<TableBody
						key='splitter'
					>
						<TableRow>
							<TableCell
								colSpan={9}
							/>
						</TableRow>
					</TableBody>
				</Table>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								key='head_1'
								className={classes.table_head}
							>
								Продавець
							</TableCell>
							<TableCell
								key='head_2'
								className={classes.table_head}
							>
								Покупець
							</TableCell>
							<TableCell
								key='head_3'
								className={classes.table_head}
							>
								Вхідний залишок, грн
							</TableCell>
							<TableCell
								key='head_4'
								className={classes.table_head}
							>
								Обсяг, МВтг
							</TableCell>
							<TableCell
								key='head_5'
								className={classes.table_head}
							>
								Ціна без ПДВ, грн
							</TableCell>
							<TableCell
								key='head_6'
								className={classes.table_head}
							>
								Вартість з ПДВ, грн
							</TableCell>
							<TableCell
								key='head_7'
								className={classes.table_head}
							>
								Оплата з ПДВ, грн
							</TableCell>
							<TableCell
								key='head_8'
								className={classes.table_head}
							>
								Маржінальність, грн
							</TableCell>
							<TableCell
								key='head_9'
								className={classes.table_head}
							>
								Залишок, грн
							</TableCell>
						</TableRow>
					</TableHead>
					{
						(report.oes_buy && report.oes_buy.length > 0) &&
						<TableBody
							key={'buy_oes'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Купівля на РДД в ОЕС
								</TableCell>
							</TableRow>
							{
								report.oes_buy.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:1`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.seller}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:2`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.consumer}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:3`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.pre_payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:4`}
													className={classes.cells}
												>
													{valFormat.format(group.volume)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:5`}
													className={classes.cells}
												>
													{curFormat.format(group.price)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:6`}
													className={classes.cells}
												>
													{curFormat.format(group.amount_vat)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:7`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:8`}
													className={classes.cells}
												>
													{curFormat.format(group.margin)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:9`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.ballance)}
													</TableCell>
												}
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього купівля в ОЕС
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_buy_summary.pre_payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{valFormat.format(report.oes_buy_summary.volume)}
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_buy_summary.amount * 1.2)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_buy_summary.payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_buy_summary.margin)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.oes_buy_summary.ballance)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						(report.buos_buy && report.buos_buy.length > 0) &&
						<TableBody
							key={'buy_buos'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Купівля на РДД в БУОС
								</TableCell>
							</TableRow>
							{
								report.buos_buy.map((row, row_index) => {
									return (
										row.groups.map((group, group_index) => (
											<TableRow
												key={`${row_index}:${group_index}`}
											>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:1`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.seller}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:2`}
														className={classes.head}
														rowSpan={row.groups.length}
													>
														{row.consumer}
													</TableCell>
												}
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:3`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.pre_payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:4`}
													className={classes.cells}
												>
													{valFormat.format(group.volume)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:5`}
													className={classes.cells}
												>
													{curFormat.format(group.price)}
												</TableCell>
												<TableCell
													key={`${row_index}.${group_index}:6`}
													className={classes.cells}
												>
													{curFormat.format(group.amount_vat)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:7`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.payment)}
													</TableCell>
												}
												<TableCell
													key={`${row_index}.${group_index}:8`}
													className={classes.cells}
												>
													{curFormat.format(group.margin)}
												</TableCell>
												{
													(group_index === 0) &&
													<TableCell
														key={`${row_index}:${group_index}:9`}
														className={classes.cells}
														rowSpan={row.groups.length}
													>
														{curFormat.format(row.ballance)}
													</TableCell>
												}
											</TableRow>
										))
									)
								})
							}
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього купівля в БУОС
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_buy_summary.pre_payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{valFormat.format(report.buos_buy_summary.volume)}
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_buy_summary.amount * 1.2)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_buy_summary.payment)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_buy_summary.margin)}
								</TableCell>
								<TableCell
									className={classes.summary}
								>
									{curFormat.format(report.buos_buy_summary.ballance)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.dam_buy && report.idm_buy && report.market_buy_summary &&
						<TableBody
							key={'buy_dam_idm'}
						>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.table_section}
								>
									Купівля на РДН та ВДР
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									Зона торгівлі
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Оператор ринку
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Ціна без ПДВ, грн
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Вартість з ПДВ, грн
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									rowSpan={2}
									className={classes.head}
								>
									ОЕС
								</TableCell>
								<TableCell
									className={classes.head}
								>
									РДН
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.oes_amount / report.dam_buy.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.oes_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									ВДР
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.oes_amount / report.idm_buy.oes_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.oes_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									rowSpan={2}
									className={classes.head}
								>
									БУОС
								</TableCell>
								<TableCell
									className={classes.head}
								>
									РДН
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.buos_amount / report.dam_buy.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.dam_buy.buos_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									className={classes.head}
								>
									ВДР
								</TableCell>
								<TableCell />
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.buos_amount / report.idm_buy.buos_volume) }
								</TableCell>
								<TableCell
									className={classes.cells}
								>
									{ curFormat.format(report.idm_buy.buos_amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary}
								>
									Всього купівля на РДН та ВДР
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{ valFormat.format(report.market_buy_summary.volume) }
								</TableCell>
								<TableCell
									className={classes.summary}
								/>
								<TableCell
									className={classes.summary}
								>
									{ curFormat.format(report.market_buy_summary.amount * 1.2) }
								</TableCell>
								<TableCell
									colSpan={3}
									className={classes.summary}
								/>
							</TableRow>
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.import && report.import.length > 0 &&
						<TableBody
							key={'import'}
						>
							<TableRow>
								<TableCell
									rowSpan={report.export.length + 1}
									className={classes.head}
								>
									Імпорт
								</TableCell>
								<TableCell
									className={classes.head}
								>
									Країна
								</TableCell>
								<TableCell
									className={classes.head}
								/>
								<TableCell
									className={classes.head}
								>
									Обсяг, МВтг
								</TableCell>
								<TableCell
									colSpan={3}
									className={classes.head}
								/>
								<TableCell
									className={classes.head}
								>
									Маржінальність, грн
								</TableCell>
								<TableCell
									className={classes.head}
								/>
							</TableRow>
							{
								report.import.map(row => (
									<TableRow
										key={row.out_domain_id}
									>
										<TableCell
											className={classes.head}
										>
											{ row.out_domain }
										</TableCell>
										<TableCell
											className={classes.head}
										>
											{ row.in_domain }
										</TableCell>
										<TableCell
											className={classes.cells}
										/>
										<TableCell
											className={classes.cells}
										>
											{ valFormat.format(row.volume) }
										</TableCell>
										<TableCell
											colSpan={3}
											className={classes.cells}
										/>
										<TableCell
											className={classes.cells}
										>
											{ curFormat.format(row.margin) }
										</TableCell>
										<TableCell
											className={classes.cells}
										/>
									</TableRow>
								))
							}
							<TableRow>
								<TableCell
									colSpan={9}
									className={classes.split_row}
								/>
							</TableRow>
						</TableBody>
					}
					{
						report.total_buy &&
						<TableBody
							key='total_buy'
						>
							<TableRow>
								<TableCell
									colSpan={2}
									className={classes.summary_all}
								>
									Всього купівля
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ valFormat.format(report.total_buy.volume) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_buy.amount * 1.2) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								/>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_buy.margin) }
								</TableCell>
								<TableCell
									className={classes.summary_all}
								>
									{ curFormat.format(report.total_buy.ballance) }
								</TableCell>
							</TableRow>
						</TableBody>
					}
				</Table>
			</TableContainer>
		</Paper>
	)
};


SummaryReport3.propTypes = {
	report: PropTypes.object.isRequired,
};