import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
		tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 1),
		width: '30px',
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 1),
	},

}));


export default function TimeSeriesCompare(props) {

	const classes = useStyles();
	const { rows, onDelete } = props;

	const head_cells = [
		{ id: 'name', alignTo: 'left', label: 'Назва' },
		{ id: 'seller', alignTo: 'left', label: 'Продавець' },
		{ id: 'consumer', alignTo: 'left', label: 'Покупець' },
	];

	let max_hours = 23;
	let sum = [ ];
	rows.forEach((row, row_index) => {

		if (row) {
			// initialize array element
			sum[row_index] = 0;

			if (row.points.length > max_hours) {
				max_hours = row.points.length;
			}

			// calculate sum
			row.points.forEach(point => {
				sum[row_index] += point.value;
			})
		}
	})

	for (let i = 1; i <= max_hours; i++) {
		head_cells.push({ id: i, alignTo: 'right', label: i.toString() });
	}
	head_cells.push({ id: 'sum', alignTo: 'right', label: 'Всього' });
	head_cells.push({ id: 'action', alignTo: 'right', label: 'Видалити' });


	return (
		<Paper className={classes.paper}>
			<TableContainer>
				<Table size= {'small'} className={classes.table} >

					<TableHead>
						<TableRow
							key={'table_head_row'}
						>
							{
								head_cells.map(cell => {
									return (
										<TableCell
											key={cell.id}
											align={cell.alignTo}
											className={classes.head}
										>
											{cell.label}
										</TableCell>
									)
								})
							}
						</TableRow>
					</TableHead>

					<TableBody>
						{
							rows.map((row, row_index) => {

								if (row) {
									return (
										<TableRow
											hover
											key={`row-${row_index}`}
										>

											<TableCell
												key={ `row-${row_index}-name` }
											>
												{row.name}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-seller` }
											>
												{row.seller}
											</TableCell>

											<TableCell
												key={ `row-${row_index}-consumer` }
											>
												{row.consumer}
											</TableCell>

											{
												row.points.map((cell, cell_index) => (
													<TableCell
														key={ `row-${row_index}-${cell_index}` }
														align='right'
														className={classes.slim_cell}
													>
														{ cell.value.toFixed(1) }
													</TableCell>
												))
											}

											<TableCell
												key={ `row-${row_index}-sum` }
												align='right'
												className={classes.slim_cell}
											>
												{ sum[row_index].toFixed(1) }
											</TableCell>

											<TableCell
												key={ `row-${row_index}-action` }
												align='right'
												className={classes.slim_cell}
											>
												<IconButton
													onClick={ () => onDelete(row.name) }
													color='inherit'
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>

										</TableRow>
									)
								} else {
									return (
										<TableRow
											hover
											key={`row-${row_index}-dummy`}
										/>
									)
								}
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}


TimeSeriesCompare.propTypes = {
	rows: PropTypes.array.isRequired,
	onDelete: PropTypes.func.isRequired,
};