import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	grid: {
		marginTop: theme.spacing(4),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		display: 'inline-block',
	},

}));


export default function SettingsTableForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	const initial_state = {
		dam_oes_day_threshold: 0,
		dam_oes_night_threshold: 0,
		dam_buos_day_threshold: 0,
		dam_buos_night_threshold: 0
	};
	const [local_state, setLocalState] = useState(initial_state);


	function HandleDamOesDayThresholdChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			dam_oes_day_threshold: event_value,
		}));
	}


	function HandleDamOesNightThresholdChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			dam_oes_night_threshold: event_value,
		}));
	}


	function HandleDamBuosDayThresholdChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			dam_buos_day_threshold: event_value,
		}));
	}


	function HandleDamBuosHightThresholdChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			dam_buos_night_threshold: event_value,
		}));
	}


	async function dam_oes_day_threshold_change () {

		try {

			const response = await axios.post('/api/settings/set', {
				setting_name: 'DAM_OES_DAY_THRESHOLD',
				setting_value: local_state.dam_oes_day_threshold,
			});
			if (response.status === 200) {
				window.alert('Налаштування змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function dam_oes_night_threshold_change () {

		try {

			const response = await axios.post('/api/settings/set', {
				setting_name: 'DAM_OES_NIGHT_THRESHOLD',
				setting_value: local_state.dam_oes_night_threshold,
			});
			if (response.status === 200) {
				window.alert('Налаштування змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function dam_buos_day_threshold_change () {

		try {

			const response = await axios.post('/api/settings/set', {
				setting_name: 'DAM_BUOS_DAY_THRESHOLD',
				setting_value: local_state.dam_buos_day_threshold,
			});
			if (response.status === 200) {
				window.alert('Налаштування змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function dam_buos_night_threshold_change () {

		try {

			const response = await axios.post('/api/settings/set', {
				setting_name: 'DAM_BUOS_NIGHT_THRESHOLD',
				setting_value: local_state.dam_buos_night_threshold,
			});
			if (response.status === 200) {
				window.alert('Налаштування змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	function onErrorMessageClose () {

		setMessage(message => ({...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const resp_oes_day_th = await axios.post('/api/settings/get', {
					setting_name: 'DAM_OES_DAY_THRESHOLD',
				});
				const oes_day_threshold = resp_oes_day_th.data.setting_value;

				const resp_oes_night_th = await axios.post('/api/settings/get', {
					setting_name: 'DAM_OES_NIGHT_THRESHOLD',
				});
				const oes_night_threshold = resp_oes_night_th.data.setting_value;

				const resp_buos_day_th = await axios.post('/api/settings/get', {
					setting_name: 'DAM_BUOS_DAY_THRESHOLD',
				});
				const buos_day_threshold = resp_buos_day_th.data.setting_value;

				const resp_buos_night_th = await axios.post('/api/settings/get', {
					setting_name: 'DAM_BUOS_NIGHT_THRESHOLD',
				});
				const buos_night_threshold = resp_buos_night_th.data.setting_value;

				setLocalState(prev_state => ({ ...prev_state,
					dam_oes_day_threshold: oes_day_threshold,
					dam_oes_night_threshold: oes_night_threshold,
					dam_buos_day_threshold: buos_day_threshold,
					dam_buos_night_threshold: buos_night_threshold,
				}));

			} catch(error) {

				console.log(error);

				setMessage(message => ({ ...message,
					show: true,
					message: error.message,
				}));
			}
		}

		fetchData();

	}, [])


	return (
		<Container className={ classes.paper }>

			<Typography component='h1' variant='h4'>
				Налаштування
			</Typography>

			<Grid container spacing={3} className={classes.grid}>

				<Grid item xs={3}>

					<Typography variant='h6'>
						Гранична ціна РДН в години максимального навантаження по ОЕС України
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						label='Price cap peak hours'
						value={ local_state.dam_oes_day_threshold }
						onChange={ HandleDamOesDayThresholdChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ dam_oes_day_threshold_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Гранична ціна РДН в години максимального навантаження по ОЕС України. 
						В період з 07:00 до 23:00 години ціна в заявках РДН не може перевищувати вказане значення.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Гранична ціна РДН в години мінімального навантаження по ОЕС України
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						label='Price cap off-peak hours'
						value={local_state.dam_oes_night_threshold}
						onChange={ HandleDamOesNightThresholdChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ dam_oes_night_threshold_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Гранична ціна РДН в години мінімального навантаження по ОЕС України.
						В період з 00:00 до 07:00 та з 23:00 до 24:00 години ціна в заявках РДН не може перевищувати вказане значення.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Гранична ціна РДН в години максимального навантаження по БУОС
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						label='Price cap peak hours'
						value={ local_state.dam_buos_day_threshold }
						onChange={ HandleDamBuosDayThresholdChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ dam_buos_day_threshold_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Гранична ціна РДН в години максимального навантаження по БУОС. 
						В період з 07:00 до 23:00 години ціна в заявках РДН не може перевищувати вказане значення.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Гранична ціна РДН в години мінімального навантаження по БУОС
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						label='Price cap off-peak hours'
						value={ local_state.dam_buos_night_threshold }
						onChange={ HandleDamBuosHightThresholdChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ dam_buos_night_threshold_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Гранична ціна РДН в години мінімального навантаження по БУОС.
						В період з 00:00 до 07:00 та з 23:00 до 24:00 години ціна в заявках РДН не може перевищувати вказане значення.
					</Typography>

				</Grid>

			</Grid>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}