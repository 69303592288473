import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';

import EnhancedTableHead from '../../subcomponents/EnhancedTableHead';
import FilterSearch from '../../subcomponents/FilterSearch';
import { stableSort, getSorting } from '../../subcomponents/TableSorting';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		minWidth: 750,
	},

	head: {
		fontWeight: 'bold',
	},

}));


export default function CompanyTable(props) {

	const classes = useStyles();

	const { rows } = props;

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('');

	const init_filter = {
		name: '',
		address: '',
		registration_code: '',
		eic_x_code: ''
	};
	const [filter, setFilter] = React.useState(init_filter);

	const head_cells = [
		{ id: 'name', alignTo: 'left', disablePadding: false, label: 'Назва', filter_by: filter.name },
		{ id: 'address', alignTo: 'left', disablePadding: false, label: 'Адреса', filter_by: filter.address },
		{ id: 'registration_code', alignTo: 'right', disablePadding: false, label: 'Код ЄДРПОУ', filter_by: filter.registration_code },
		{ id: 'eic_x_code', alignTo: 'right', disablePadding: false, label: 'EIC код типу X', filter_by: filter.eic_x_code },
		{ id: 'actions', alignTo: 'right', disablePadding: false, label: 'Дії' },
	];


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}


	const handleFilterChange = (id, event) => {

		const new_filter = { ...filter };
		new_filter[id] = event.target.value;

		setFilter(new_filter);
	}


	const handleFilterDelete = () => {
		const new_filter = { ...filter };
		Object.keys(new_filter).forEach(key => {
			new_filter[key] = '';
		});
		setFilter(new_filter);
	}


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >

					<EnhancedTableHead
						classes={classes}
						head_cells={head_cells}
						selected_count={0}
						row_count={rows.length}
						order={order}
						order_by={orderBy}
						onRequestSort={handleRequestSort}
						disable_checkbox={true}
					/>

					<TableBody>
						<TableRow>
							{
								head_cells.map(item => (

									(item.id !== 'actions') ?
									<TableCell
										key={ item.id }
									>
										<FilterSearch
											value={ item.filter_by }
											onSearchChange={ (event) => handleFilterChange(item.id, event) }
										/>
									</TableCell>
									:
									<TableCell
										key={ item.id }
									>
										<IconButton
											onClick={ handleFilterDelete }
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								))
							}
						</TableRow>
					</TableBody>

					<TableBody>
						{
							stableSort(rows, getSorting(order, orderBy))
								.filter(row => row.name.toLowerCase().indexOf(filter.name) !== -1)
								.filter(row => row.address.toLowerCase().indexOf(filter.address) !== -1)
								.filter(row => row.registration_code.indexOf(filter.registration_code) !== -1)
								.filter(row => row.eic_x_code.indexOf(filter.eic_x_code) !== -1)
								.map((row, index) => {

									const label_id = `row-checkbox-${index}`;

									return (
										<TableRow
											hover
											key={row.key}
										>
											<TableCell component='th' id={label_id} scope='row'>
												{row.name}
											</TableCell>

											<TableCell align='left'>{row.address}</TableCell>
											<TableCell align='right'>{row.registration_code}</TableCell>
											<TableCell align='right'>{row.eic_x_code}</TableCell>
											<TableCell align='right'>
												<NavLink to={ 'admin/company/' + row.id }>
													<EditIcon />
												</NavLink>
											</TableCell>
										</TableRow>
									);
								})
						}
					</TableBody>
				</Table>

			</TableContainer>
		</Paper>
	);
}


CompanyTable.propTypes = {
	rows: PropTypes.array.isRequired,
};