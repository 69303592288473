import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddSharp';
import OpenIcon from '@material-ui/icons/FolderOpenSharp';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import HistoryIcon from '@material-ui/icons/HistorySharp';
import HelpIcon from '@material-ui/icons/HelpSharp';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import IdmHelpDialog from './subcomponents/IdmHelpDialog';
import IdmOrdersTable from './subcomponents/IdmOrdersTable';

import FilterPeriod from '../subcomponents/FilterPeriod';
import FilterDate from '../subcomponents/FilterDate';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { idmContext } from './idmContext';

import IdmOrderEditForm from './IdmOrderEditForm';
import IdmOrderDownloadForm from './IdmOrderDownloadForm';
import IdmImportExportForm from './IdmImportExportForm';
import HistoryDialog from '../subcomponents/HistoryDialog';
import NotAuthorised from '../auth/NotAuthorised';

import { is_valid_date } from '../utils/validation';
import { getDateFormat, getLocalDateString, getLocalDateTimeString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

}));


function IdmOrders(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_dialog = {
		show: false,
		rows: []
	}
	const [history_dialog, setHistoryDialog] = useState(initial_dialog);

	// filter states
	const initial_filter = createInitialFilter();
	const [filter, setFilter] = useState(initial_filter);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	const [open_help_dialog, setOpenHelpDialog] = useState(false);

	// table variables
	const [draft_orders_selected, setDraftOrdersSelected] = React.useState([]);
	const [send_orders_selected, setSendOrdersSelected] = React.useState([]);
	const [active_orders_selected, setActiveOrdersSelected] = React.useState([]);
	const [confirmed_orders_selected, setConfirmedOrdersSelected] = React.useState([]);
	const [archived_orders_selected, setArchivedOrdersSelected] = React.useState([]);

	// import context
	const { user, is_user_authorised } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id: `idm-orders-tab-${index}`,
			'aria-controls': `idm-orders-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`idm-orders-tabpanel-${index}`}
				aria-labelledby={`idm-orders-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {

		setDraftOrdersSelected([]);
		setSendOrdersSelected([]);
		setActiveOrdersSelected([]);
		setConfirmedOrdersSelected([]);
		setArchivedOrdersSelected([]);

		setTabNo(tab);
	}


	function createInitialState() {

		const complex_state = {

			update_flag: false,

			// internal states
			domains: [],
			statuses: [],
			companies: [],

			block_orders: [],
			block_types: [],
			indication_types: [],
			trade_types: [],
			trade_stages: [],

			// component states
			draft_orders: [],
			active_orders: [],
			confirmed_orders: [],
			archived_orders: [],
		}

		return complex_state;
	}


	function createInitialFilter() {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(-24, 0, 0, 0);

		// set end date to tommorow
		const day_2 = new Date();
		day_2.setHours(24, 0, 0, 0);

		const complex_state = {
			enable: false,
			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),
		}

		return complex_state;
	}


	function handleAddOrder() {
		props.history.push('/idm/add');
	}


	function handleOpenOrder(order_id) {

		if (order_id !== undefined) {
			props.history.push('/idm/' + order_id);
		}

		OnTabChage(undefined, tab_no);
	}


	async function handleDeleteOrder(order_id) {

		if (order_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте видалити заявку?')) {
			return;
		}

		try {

			const id = parseInt(order_id);
			if (!isNaN(id)) {

				const response = await axios.post('/api/idm/' + id + '/delete');
				if (response.status === 200) {

					setGlobalState(state => ({ ...state,
						update_flag: !state.update_flag,
					}));

					setDraftOrdersSelected([]);
				}
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleOpenOrderHistory(order_id) {

		if (order_id === undefined) return;

		try {

			const id = parseInt(order_id);
			if (!isNaN(id)) {

				const response = await axios.post('/api/idm/' + id + '/history');
				if (response.status === 200) {
					setHistoryDialog(dialog => ({ ...dialog,
						show: true,
						rows: response.data
					}));
				}
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleCreateCopyOrder(order_id) {

		if (order_id === undefined) return;

		if (!window.confirm('Ви впевнені, що бажаєте створити копію заявки?')) {
			return;
		}

		try {

			const id = parseInt(order_id);
			if (!isNaN(id)) {
				const response = await axios.post('/api/idm/' + id + '/copy');
				if (response.status === 200) {
					setGlobalState(state => ({ ...state,
						update_flag: !state.update_flag,
					}));
				}
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
				let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
					edit: edit,
				}));
			}

			console.log(error);
		}
	}


	async function handleOrderAction(action_id, order_id) {

		switch (action_id) {

			case 0:
				handleOpenOrder(order_id);
				break;
			case 1:
				handleDeleteOrder(order_id);
				break;
			case 2:
				handleOpenOrderHistory(order_id);
				break;
			case 3:
				handleCreateCopyOrder(order_id);
				break;
			default:
				break;
		}
	}


	function handleHelpDialog() {
		setOpenHelpDialog(true)
	}


	function handleHelpDialogClose() {
		setOpenHelpDialog(false);
	}


	function onPeriodFilterChange(start, end) {
		setFilter(filter => ({ ...filter,
			period_start: start,
			period_end: end,
		}));
	}


	function onDateFilterChange(end) {

		setFilter(filter => ({ ...filter,
			period_end: end,
		}));
	}


	function onFilterClick() {

		if (is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {
			setFilter(filter => ({
				...filter,
				enable: true,
			}));
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	function onHistoryDialogClose() {

		setHistoryDialog(dialog => ({ ...dialog,
			show: false,
			rows: []
		}));
	}


	function get_selected_order_id() {

		if (draft_orders_selected.length +
			send_orders_selected.length +
			active_orders_selected.length +
			confirmed_orders_selected.length +
			archived_orders_selected.length === 1) {

			if (draft_orders_selected.length === 1) {
				return draft_orders_selected[0];
			}
			if (send_orders_selected.length === 1) {
				return send_orders_selected[0];
			}
			if (active_orders_selected.length === 1) {
				return active_orders_selected[0];
			}
			if (confirmed_orders_selected.length === 1) {
				return confirmed_orders_selected[0];
			}
			if (archived_orders_selected.length === 1) {
				return archived_orders_selected[0];
			}

		} else {
			return undefined;
		}
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/idm');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({
					...prev_state,
					domains: res_market_defs.data.domain_list,
					statuses: res_market_defs.data.status_list,
					companies: res_company_data.data.company_list,
					block_orders: res_market_defs.data.block_order_list,
					block_types: res_market_defs.data.block_type_list,
					indication_types: res_market_defs.data.indication_type_list,
					trade_types: res_market_defs.data.trade_type_list,
					trade_stages: res_market_defs.data.trade_stage_list
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		setFilter(filter => ({ ...filter, enable: true }));

	}, [global_state.update_flag])


	useEffect(() => {

		if (filter.enable && is_valid_date(filter.period_start) && is_valid_date(filter.period_end)) {

			axios.get('/api/idm', {
				params: {
					period_start: getDateFormat(filter.period_start),
					period_end: getDateFormat(filter.period_end)
				}
			})
				.then(response => {

					let drafts = response.data.orders_list.filter(item => item.status_id <= 2);
					let active = response.data.orders_list.filter(item => item.status_id === 3);
					let confirmed = response.data.orders_list.filter(item => item.status_id === 4);
					let archived = response.data.orders_list.filter(item => item.status_id === 5);

					setGlobalState(prev_state => ({ ...prev_state,
						draft_orders: drafts,
						active_orders: active,
						confirmed_orders: confirmed,
						archived_orders: archived,
					}));

				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});

			setFilter(filter => ({ ...filter, enable: false }));
		}

	}, [filter])


	useEffect(() => {

		if (message.edit !== 0) {

			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({
							...message,
							show: true,
							info: `${first_name} ${last_name} \n ${email} \n ${phone}`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}

	}, [message.edit])


	const list_order = (item) => {

		const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
		const status = global_state.statuses.find(elem => elem.status_id === item.status_id);
		const sender = global_state.companies.find(elem => elem.company_id === item.sender_company_id);
		const block_order = global_state.block_orders.find(elem => elem.order_id === item.block_order_id);
		const block_type = global_state.block_types.find(elem => elem.type_id === item.block_type_id);
		const trade_type = global_state.trade_types.find(elem => elem.type_id === item.trade_type_id);
		const indication_type = global_state.indication_types.find(elem => elem.type_id === item.indication_id);

		return {
			key: item.order_id,
			id: item.order_id,
			domain: (domain !== undefined) ? domain.domain_display_name : item.domain,
			trade_date: getLocalDateString(item.trade_date),
			status: (status !== undefined) ? status.status_name : '',
			revision: item.revision,
			sender: (sender !== undefined) ? sender.name : '',
			period_from: item.period_from,
			period_to: item.period_to,
			validity_date: (item.validity_date !== null) ? getLocalDateTimeString(item.validity_date) : '',
			block_order: (block_order !== undefined) ? block_order.order_comment : '',
			block_type: (block_type !== undefined) ? block_type.type_comment : '',
			trade_type: (trade_type !== undefined) ? trade_type.type_comment : item.trade_type,
			indication_type: (indication_type !== undefined) ? indication_type.type_comment : '',
			price: item.price,
			amount: item.amount,
			comment: (item.comment !== null) ? item.comment : ''
		}
	}


	var draft_orders_rows = [];
	var active_orders_rows = [];
	var confirmed_orders_rows = [];
	var archived_orders_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) &&
		(global_state.companies.length > 0) && (global_state.block_orders.length > 0) &&
		(global_state.block_types.length > 0) && (global_state.indication_types.length > 0) &&
		(global_state.trade_types.length > 0) && (global_state.trade_stages.length > 0)) {

		if (global_state.draft_orders.length > 0) {
			draft_orders_rows = global_state.draft_orders.map(item => list_order(item));
		}
		if (global_state.active_orders.length > 0) {
			active_orders_rows = global_state.active_orders.map(item => list_order(item));
		}
		if (global_state.confirmed_orders.length > 0) {
			confirmed_orders_rows = global_state.confirmed_orders.map(item => list_order(item));
		}
		if (global_state.archived_orders.length > 0) {
			archived_orders_rows = global_state.archived_orders.map(item => list_order(item));
		}
	}


	if (!user.is_logged_in || !is_user_authorised(user.authorisation, 17)) {
		return (
			<NotAuthorised />
		)
	} else {
		return (
			<idmContext.Provider value={{ global_state, setGlobalState, list_order }}>
				<Switch>
					<Route
						path='/idm/import/:trade_date'
						component={IdmImportExportForm}
					/>
					<Route
						path='/idm/add'
						component={IdmOrderEditForm}
					/>
					<Route
						path='/idm/:order_id'
						component={IdmOrderEditForm}
					/>
					<Route
						path='/idm'
					>

						<div className={classes.root} >

							<AppBar position='static'>
								<Toolbar>

									<Typography className={classes.title} variant='h6' noWrap>
										Заявки на ВДР
									</Typography>

									<Tooltip title='Додати'>
										<span>
											<IconButton
												onClick={handleAddOrder}
												color='inherit'
											>
												<AddIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Відкрити'>
										<span>
											<IconButton
												onClick={() => handleOpenOrder(get_selected_order_id())}
												color='inherit'
												disabled = { draft_orders_selected.length +
															send_orders_selected.length +
															active_orders_selected.length +
															confirmed_orders_selected.length +
															archived_orders_selected.length !== 1 }
											>
												<OpenIcon />
											</IconButton>
										</span>
									</Tooltip>

									{ (tab_no === 0) &&
									<Tooltip title='Видалити'>
										<span>
											<IconButton
												onClick={() => handleDeleteOrder(get_selected_order_id())}
												disabled={draft_orders_selected.length !== 1}
												color='inherit'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip> }

									<Tooltip title='Імпорт / експорт'>
										<span>
											<IconButton
												onClick={() => props.history.push('/idm/import/' + filter.period_start)}
												color='inherit'
											>
												<ImportExportIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title='Історія заявки'>
										<span>
											<IconButton
												onClick={() => handleOpenOrderHistory(get_selected_order_id())}
												color='inherit'
												disabled = { draft_orders_selected.length +
															send_orders_selected.length +
															active_orders_selected.length +
															confirmed_orders_selected.length +
															archived_orders_selected.length !== 1 }
											>
												<HistoryIcon />
											</IconButton>
										</span>
									</Tooltip>

									{ (tab_no !== 1) &&
									<FilterPeriod
										period_start={filter.period_start}
										period_end={filter.period_end}
										onFilterChange={onPeriodFilterChange}
										onFilterClick={onFilterClick}
									/> }

									{ (tab_no === 1) &&
									<FilterDate
										date={filter.period_end}
										onFilterChange={onDateFilterChange}
										onFilterClick={onFilterClick}
									/> }

									<div className={classes.right_button}>

										<IconButton
											onClick={handleHelpDialog}
											color='inherit'
										>
											<HelpIcon />
										</IconButton>

										<IconButton
											onClick={() => props.history.push('/')}
											color='inherit'
										>
											<BackIcon />
										</IconButton>

									</div>

								</Toolbar>
							</AppBar>

							<Tabs
								value={tab_no}
								onChange={OnTabChage}
								className={classes.tab}
								indicatorColor='primary'
								textColor='primary'
							>
								<Tab label={ <Badge badgeContent={global_state.draft_orders.length} color='primary'>Чернетки</Badge>} {...getTabsProps(0)} />
								<Tab label='Відправлені / Подані' {...getTabsProps(1)} />
								<Tab label={ <Badge badgeContent={global_state.active_orders.length} color='primary'>В роботі</Badge>} {...getTabsProps(2)} />
								<Tab label={ <Badge badgeContent={global_state.confirmed_orders.length} color='primary'>Підтверджені</Badge>} {...getTabsProps(3)} />
								<Tab label={ <Badge badgeContent={global_state.archived_orders.length} color='primary'>Архівні</Badge>} {...getTabsProps(4)} />
							</Tabs>

							<TabPanel value={tab_no} index={0}>

								<IdmOrdersTable
									rows={draft_orders_rows}
									selected={draft_orders_selected}
									setSelected={setDraftOrdersSelected}
									onOpenRequest={handleOpenOrder}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 1, name: 'Видалити' },
										{ id: 2, name: 'Історія заявки' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleOrderAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={1}>

								<IdmOrderDownloadForm
									date={filter.period_end}
									offline_orders_selected={send_orders_selected}
									setOfflineOrdersSelected={setSendOrdersSelected}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={2}>

								<IdmOrdersTable
									rows={active_orders_rows}
									selected={active_orders_selected}
									setSelected={setActiveOrdersSelected}
									onOpenRequest={handleOpenOrder}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія заявки' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleOrderAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={3}>

								<IdmOrdersTable
									rows={confirmed_orders_rows}
									selected={confirmed_orders_selected}
									setSelected={setConfirmedOrdersSelected}
									onOpenRequest={handleOpenOrder}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія заявки' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleOrderAction}
								/>

							</TabPanel>

							<TabPanel value={tab_no} index={4}>

								<IdmOrdersTable
									rows={archived_orders_rows}
									selected={archived_orders_selected}
									setSelected={setArchivedOrdersSelected}
									onOpenRequest={handleOpenOrder}
									action_list={[
										{ id: 0, name: 'Відкрити' },
										{ id: 2, name: 'Історія заявки' },
										{ id: 3, name: 'Створити копію' }
									]}
									onActionSelect={handleOrderAction}
								/>

							</TabPanel>

						</div>

						<IdmHelpDialog open={open_help_dialog} onClose={handleHelpDialogClose} />

						<MessageErrorSnackbar
							open={message.show}
							message={message.message}
							info={message.info}
							onClose={onErrorMessageClose}
						/>

						<HistoryDialog
							open={history_dialog.show}
							onClose={onHistoryDialogClose}
							rows={history_dialog.rows}
						/>

					</Route>
				</Switch>
			</idmContext.Provider>
		)
	}
}

export default withRouter(IdmOrders)