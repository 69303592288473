import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import { getLocalDateString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	table: {
		// tableLayout: 'auto',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		padding: theme.spacing(1, 0, 1, 1),
	},

	slim_cell: {
		fontSize: '10px',
		padding: theme.spacing(1, 0, 1, 0),
		margin: theme.spacing(1),
	},

	end_cell: {
		fontWeight: 'bold',
		fontSize: '11px',
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


export default function PriceTable(props) {

	const classes = useStyles();

	const { rows } = props;

	const head_cells = [
		{ id: 'date', alignTo: 'left', label: 'Дата' },
		{ id: 'domain', alignTo: 'left', label: 'Зона торгівлі' }
	];

	let max_hours = 23;
	const sum = [];
	const total = []; total[0] = 0;
	const indecies = [];

	for (let r = 0; r < rows.length; r++) {

		// initialize array element
		sum[r] = 0;

		// calculate sum
		for (let i = 1; i <= 25; i++) {

			const index = (i < 10) ? `p0${i}` : `p${i}`;

			// initialise
			if (total[index] === undefined) total[index] = 0;

			if (rows[r][index] !== undefined && rows[r][index] !== null) {

				if (i > max_hours) {
					max_hours = i;
				}

				const value = parseFloat(rows[r][index]);

				sum[r] += value;
				total[index] += value;
				total[0] += value;
			}
		}

		sum[r] /= max_hours;
	}

	for (let i = 1; i <= max_hours; i++) {
		const index = (i < 10) ? `p0${i}` : `p${i}`;
		indecies.push(index);
		head_cells.push({ id: i, alignTo: 'right', label: i.toString() });
	}
	head_cells.push({ id: 'sum', alignTo: 'right', label: 'Середня ціна' });


	return (
		<Paper className={classes.paper}>

			<TableContainer>

				<Table size= {'small'} className={classes.table} >
					<TableHead>
						<TableRow>
							{
								head_cells.map(item => (
									<TableCell
										key={item.id}
										className={classes.head}
										align={item.alignTo}
										padding='default'
									>
										{item.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rows.map((row, row_index) => {
								return (
									<TableRow
										hover
										key={row_index}
									>
										<TableCell
											key={ `row-${row_index}-date` }
											id={ `row-${row_index}-date` }
											scope='row'
											padding='default'
										>
											{ getLocalDateString(row.trade_date) }
										</TableCell>
										<TableCell
											key={ `row-${row_index}-domain` }
											id={ `row-${row_index}-domain` }
											padding='default'
										>
											{ row.domain }
										</TableCell>
										{
											indecies.map((index, cell_index) => (
												<TableCell
													key={ `row-${row_index}-${cell_index}` }
													id={ `row-${row_index}-${cell_index}` }
													align='right'
													className={classes.slim_cell}
												>
													{ row[index] ? row[index].toFixed(2) : '' }
												</TableCell>
											))
										}
										<TableCell
											key={ `row-sum-${row.key}` }
											align='right'
											className={classes.end_cell}
										>
											{ sum[row_index].toFixed(2) }
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}


PriceTable.propTypes = {
	rows: PropTypes.array.isRequired,
};