import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import ContractAddIcon from '@material-ui/icons/AddOutlined';
import ContractEditIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import GroupRowsTable from '../planning/subcomponents/GroupRowsTable';
import EsGroupRowsTable from './subcomponents/EsGroupRowsTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { contractsContext } from './contractsContext';
import { is_valid_contract_number } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	select_form_control: {
		marginTop: theme.spacing(2),
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	select_control: {
		padding: theme.spacing(1),
	},

	group_form_control: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(2, 0.5),
		display: 'inline-block',
	},

}));


export default function ContractEditForm(props) {

	const classes = useStyles();

	const { global_state, setGlobalState } = useContext(contractsContext);

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	const initail_state = {
		contract_number: '',
		contract_date: '',
		consumer_id: 0,
		consumer: null,
		seller_id: 0,
		seller: null,
		valid_date: '',
		contract_number_valid: false,
		es_groups: [],
		plan_groups: []
	};
	const [local_state, setLocalState] = useState(initail_state);

	// get contract id from url
	const contract_id = parseInt(props.match.params.contract_id);
	const update_url = (contract_id) ? '/api/admin/contract/' + contract_id : '/api/admin/contract/add';
	const delete_url = (contract_id) ? '/api/admin/contract/' + contract_id + '/delete' : undefined;


	function handleNumberChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			contract_number: event_value,
			contract_number_valid: is_valid_contract_number(event_value),
		}));
	}


	function handleContractDateChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			contract_date: event_value,
		}));
	}


	function onAddFinanceIndicators () {

		if (contract_id !== undefined) {

			if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
				return;
			}

			props.history.push('/contracts/' + contract_id + '/group/undefined');
		}
	}


	function handleConsumerChange (event, option) {

		const comp = global_state.companies.find(item => item.name === option);
		setLocalState(prev_state => ({ ...prev_state,
			consumer_id: comp !== undefined ? comp.company_id : 0,
			consumer: option
		}));
	}


	function handleSellerChange (event, option) {

		const comp = global_state.companies.find(item => item.name === option);
		setLocalState(prev_state => ({ ...prev_state,
			seller_id: comp !== undefined ? comp.company_id : 0,
			seller: option
		}));
	}


	function handleValidDateChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			valid_date: event_value,
		}));
	}


	async function onEsGroupDeleteAction (group_id) {

		if (group_id) {

			try {

				const response = await axios.post('/api/es/group/' + group_id + '/delete');
				if (response.status === 200) {
	
					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));
				}
	
			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}
	
				console.log(error);
			}
		}
	}


	function onPlanGroupAction (action_id, group_id) {

		switch (action_id) {
			case 0:
				props.history.push('/planning/plan_groups/' + group_id);
				break;
			default:
				break;
		}
	}


	function onEsGroupAction (action_id, group_id) {

		switch (action_id) {
			case 0:
				props.history.push('/contracts/' + contract_id + '/group/' + group_id);
				break;
			case 1:
				onEsGroupDeleteAction(group_id);
				break;
			default:
				break;
		}
	}


	async function SaveContractHandler () {

		const contract_data = {
			contract_id: null,
			contract_number: local_state.contract_number,
			contract_date: getDateFormat(local_state.contract_date),
			consumer_id: local_state.consumer_id,
			seller_id: local_state.seller_id,
			valid_date: local_state.valid_date
		};

		try {

			const response = await axios.post(update_url, contract_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		if (local_state.contract_number_valid) {

			let user_confiramtion = true;
			if (contract_id) {
				user_confiramtion = window.confirm('Ви впевнені, що бажаєте змінити договір?');
			}

			if (user_confiramtion) {
				if (await SaveContractHandler()) {
					// return to parent page
					props.history.push('/contracts');
				}
			}
		}
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onDeleteHandler () {

		if (!window.confirm('Ви впевнені, що бажаєте видалити договір?')) {
			return;
		}

		axios.post(delete_url)
			.then(response => {

				if (response.status === 200) {

					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));

					// return to parent screen
					props.history.push('/contracts');
				}

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchDatat() {

			try {

				const resp_contract = await axios.get(update_url);
				const resp_es_groups = await axios.get('/api/es/groups/' + contract_id);

				const contract = resp_contract.data.contract;
				const consumer = global_state.companies.find(item => item.company_id === parseInt(contract.consumer_id));
				const seller = global_state.companies.find(item => item.company_id === parseInt(contract.seller_id));

				const resp_plan_groups = await axios.get('/api/planning/groups', { params: {
					consumer: (consumer !== undefined) ? consumer.company_id : undefined,
					seller: (seller !== undefined) ? seller.company_id : undefined,
					period_start: getDateFormat(contract.contract_date),
					period_end: getDateFormat(contract.valid_date)
				}});

				setLocalState(prev_state => ({ ...prev_state,
					contract_number: contract.contract_number,
					contract_date: getDateFormat(contract.contract_date),
					consumer_id: parseInt(contract.consumer_id),
					consumer: consumer !== undefined ? consumer.name : '',
					seller_id: parseInt(contract.seller_id),
					seller: seller !== undefined ? seller.name : '',
					valid_date: getDateFormat(contract.valid_date),
					contract_number_valid: true,
					es_groups: resp_es_groups.data.group_list,
					plan_groups: resp_plan_groups.data.group_list
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (contract_id) {
			fetchDatat();
		}

	}, [contract_id, update_url, global_state.update_flag])


	const list_group = (item) => {

		const domain = undefined;
		const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
		const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

		return ({
			key: item.group_id,
			id: item.group_id,
			...item,
			domain: (domain !== undefined) ? domain.domain_display_name : item.domain,
			consumer: (consumer !== undefined) ? consumer.name : '',
			seller: (seller !== undefined) ? seller.name : '',
		})
	}

	const display_companies = global_state.companies.map(item => item.name);
	const display_groups = local_state.plan_groups.map(item => list_group(item));


	return (
		<Container className={ classes.paper } component='main' maxWidth='xs'>

			<Avatar className={ classes.avatar }>
				{ (contract_id) ? <ContractAddIcon /> : <ContractEditIcon /> }
			</Avatar>
			<Typography component='h1' variant='h4'>
				{ (contract_id) ? 'Змінити договір' : 'Додати договір' }
			</Typography>

			<form onSubmit={ onSubmitHandler }>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					error={ !local_state.contract_number_valid }
					label='Номер договору'
					value={ local_state.contract_number }
					onChange={ handleNumberChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					label='Дата договору'
					type='date'
					value={ local_state.contract_date }
					onChange={ handleContractDateChange }
					InputLabelProps={{ shrink: true }}
				/>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					label='Договір дійсний до'
					type='date'
					value={ local_state.valid_date }
					onChange={ handleValidDateChange }
					InputLabelProps={{ shrink: true }}
				/>

				<Autocomplete
					className={classes.select_form_control}
					options={ display_companies }
					getOptionLabel={(option) => option}
					renderInput={(params) => <TextField
												{...params}
												required
												label='Продавець'
												variant='outlined'
											/>}
					value={ local_state.seller }
					onChange={ handleSellerChange }
				/>

				<Autocomplete
					className={classes.select_form_control}
					options={ display_companies }
					getOptionLabel={(option) => option}
					renderInput={(params) => <TextField
												{...params}
												required
												label='Покупець'
												variant='outlined'
											/>}
					value={ local_state.consumer }
					onChange={ handleConsumerChange }
				/>

				{
					(local_state.plan_groups.length > 0) &&
					<div className={classes.group_form_control}>

						<Typography component='h4'>
							<b>Фінансові показнки</b> <i>(для внутрішніх договорів)</i>
						</Typography>

						<GroupRowsTable
							rows={display_groups}
							selected={[]}
							setSelected={() => {}}
							action_list={[
								{ id: 0, name: 'Відкрити' },
							]}
							onActionSelect={onPlanGroupAction}
						/>

					</div>
				}

				{
					(local_state.es_groups.length > 0) &&
					<div className={classes.group_form_control}>

						<Typography component='h4'>
							<b>Фінансові показнки</b> <i>(для внешньоекономічних договорів)</i>
						</Typography>

						<EsGroupRowsTable
							rows={ local_state.es_groups }
							action_list={[
								{ id: 0, name: 'Відкрити' },
								{ id: 1, name: 'Відалити' },
							]}
							onActionSelect={ onEsGroupAction }
						/>

					</div>
				}

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick={ onAddFinanceIndicators }
						disabled={ isNaN(contract_id) }
					>
						Додати фінансові показники
					</Button>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

					{ !isNaN(contract_id) &&
					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ onDeleteHandler }
					>
						Видалити
					</Button> }

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}