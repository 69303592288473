import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(1, 0, 1, 0),
	},

	container: {
		maxHeight: 400,
	},

	table: {
		width: '100%',
	},

	head: {
		fontWeight: 'bold',
		fontSize: '11px',
		background: 'white',
		padding: theme.spacing(1),
		textAlign: 'center',
	},

	row_data: {
		background: '#D3D3D3',
	},

	cells: {
		fontSize: '10px',
		textAlign: 'right',
		padding: theme.spacing(0.3),
		width: '75px',
	}

}));


function DamBlocksView(props) {
	const classes = useStyles();

	const { columns_count, rows } = props;


	const head_cells = [];
	for (let h = 0; h <= columns_count; h++) {
		head_cells[h] = {
			id: h,
			label: (h !== 0) ? h : 'Період',
		};
	}

	var blocks_count = 1;
	rows.forEach(item => {
		if (item.block_number > blocks_count) {
			blocks_count = item.block_number;
		}
	});

	const blocks_array = []
	for (let b = 0; b < blocks_count; b++) {

		// initialise rows
		const rows_array = [];
		for (let r = 0; r < 4; r++) {

			// only first block has 4 rows
			if (b > 0 && r === 3) {
				continue;
			}

			// initialise cells
			const cells_array = [];
			for (let c = 0; c <= columns_count; c++) {

				let label_text, cell_value;
				const row_item = rows.find(item => item.block_number === b + 1 && item.period === c);

				if (c === 0) {
					switch (r) {
						case 0:
							label_text = 'Період';
							break;

						case 1:
							label_text = "Обсяг (МВтг)";
							break;
	
						case 2:
							label_text = 'Ціна (грн/МВтг)';
							break;
	
						case 3:
							label_text = 'Виробнича подільність';
							break;
	
						default:
							break;
					}
				} else {

					label_text = head_cells[c].label;

					switch (r) {
						case 1:
							cell_value = (row_item !== undefined) ? row_item.amount : '';
							break;
	
						case 2:
							cell_value = (row_item !== undefined) ? row_item.price : '';
							break;
	
						case 3:
							cell_value = (row_item !== undefined) ? row_item.splitting_type_id : 1;
							break;
	
						default:
							break;
					}
				}

				cells_array[c] = {
					id: `${b+1}.${r}.${c}`,
					label: (c === 0) || (r === 0) ? label_text : undefined,
					value: cell_value,
				}
			}

			rows_array[r] = {
				id: `${b+1}.${r}`,
				cells: cells_array,
			}
		}

		// initialise block
		blocks_array[b] = {
			id: `${b+1}`,
			label: `Блок ${b+1}`,
			rows: rows_array,
		};
	}


	return (
		<Paper className={classes.paper}>
			<TableContainer className={classes.container}>
				<Table size= {'small'} className={classes.table} >
					{
						blocks_array.map(block => (
							<TableBody
								key={ block.id }
							>
								<TableRow
									key={ `${block.id}:header_row` }
								>
									<TableCell
										key={ `${block.id}:empty_cell` }
										className={ classes.head }
									>
									</TableCell>

									<TableCell
										key={ `${block.id}:header_cell` }
										className={ classes.head }
										align='center'
										colSpan={ columns_count }
									>
										{ block.label }
									</TableCell>
								</TableRow>

								{
									block.rows.map((row, row_index) => (

										<TableRow
											hover
											key={ row.id }
											className={ classes.row_data }
										>
											{
												row.cells.map((cell, cell_index) => (
													<TableCell
														key={ cell.id }
														className={ (row_index === 0) || (cell_index === 0) ? classes.head : classes.cells }
													>
														{
															(row_index === 0) || (cell_index === 0)
																?
																	cell.label
																:
																	(row_index === 3)
																		? (cell.value === 1) ? 'Так' : 'Ні' 
																		: cell.value

														}
													</TableCell>
												))
											}
										</TableRow>
									))
								}
							</TableBody>
						))
					}
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default DamBlocksView;