import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import auth_logo from '../../public/img/auth_logo.png';
import admin_logo from '../../public/img/admin_logo.png';
import capacity_logo from '../../public/img/capacity_logo.png';
import planning_logo from '../../public/img/planning_logo.png';
import contract_logo from '../../public/img/contracts_logo.png'
import time_series_logo from '../../public/img/time_series_logo.png';
import schedules_logo from '../../public/img/schedules_logo.png';
import idm_logo from '../../public/img/idm_logo.png';
import dam_logo from '../../public/img/dam_logo.png';
import prices_logo from '../../public/img/prices_logo.png'
import dam_results_logo from '../../public/img/dam_results_logo.png';
import idm_results_logo from '../../public/img/idm_results_logo.png';
import report_logo from '../../public/img/report_logo.png';
import blocks_logo from '../../public/img/blocks_logo.png';
import help_logo from '../../public/img/help_logo.png';

import { globalContext } from '../layout/Context';


const useStyles = makeStyles(theme => ({

	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},

	frame: {
		border: '1px solid',
		color: 'rgb(17, 82, 147)',
		borderRadius: '10px',
		margin: theme.spacing(1),
	},

	button: {
		margin: theme.spacing(2),
	},

	button_align: {
		verticalAlign: 'top',
		display: 'inline-block',
		width: 300,
	},

	button_text: {
		fontSize: '25px',
		display: 'block',
	}

}));


function IndexPage(props) {
	const classes = useStyles();

	// import context
	const { user, is_user_authorised } = useContext(globalContext);

	return (
		<div className={ classes.root }>

			<Button
				className={ classes.button }
				onClick={ () => { props.history.push('/auth') } }
			>
				<div className={ classes.button_align }>
					<img src={ auth_logo } alt='auth_logo'/>
					<span className={ classes.button_text }>Аутентифікація</span>
				</div>
			</Button>

			<Button
				className={ classes.button }
				onClick={ () => { props.history.push('/admin') } }
				disabled={ !user.is_logged_in }
			>
				<div className={ classes.button_align }>
					<img src={ admin_logo } alt='admin_logo'/>
					<span className={ classes.button_text }>Адміністрування</span>
				</div>
			</Button>

			<Button
				className={ classes.button }
				disabled={ !is_user_authorised(user.authorisation, 6) }
				onClick={ () => { props.history.push('/contracts') } }
			>
				<div className={ classes.button_align }>
					<img src={ contract_logo } alt='contract_logo'/>
					<span className={ classes.button_text }>Договори</span>
				</div>
			</Button>

			<div className={ classes.frame }>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 25) }
					onClick={ () => { props.history.push('/capacities') } }
				>
					<div className={ classes.button_align }>
						<img src={ capacity_logo } alt='capacity_logo'/>
						<span className={ classes.button_text }>Пропускна спроможність</span>
					</div>
				</Button>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 27) }
					onClick={ () => { props.history.push('/external_series') } }
				>
					<div className={ classes.button_align }>
						<img src={ capacity_logo } alt='capacity_logo'/>
						<span className={ classes.button_text }>Імпорт / експорт</span>
					</div>
				</Button>

			</div>

			<Button
				className={ classes.button }
				disabled={ !is_user_authorised(user.authorisation, 20) && !is_user_authorised(user.authorisation, 22) }
				onClick={ () => { props.history.push('/planning') } }
			>
				<div className={ classes.button_align }>
					<img src={ planning_logo } alt='planning_logo'/>
					<span className={ classes.button_text }>Планування</span>
				</div>
			</Button>

			<div className={ classes.frame }>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 8) }
					onClick={ () => { props.history.push('/time_series') } }
				>
					<div className={ classes.button_align }>
						<img src={ time_series_logo } alt='time_series_logo'/>
						<span className={ classes.button_text }>Часові ряди</span>
					</div>
				</Button>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 11) }
					onClick={ () => { props.history.push('/schedules') } }
				>
					<div className={ classes.button_align }>
						<img src={ schedules_logo } alt='schedules_logo'/>
						<span className={ classes.button_text }>Часові графіки</span>
					</div>
				</Button>

			</div>

			<Button
				className={ classes.button }
				disabled={ !user.is_logged_in }
				onClick={ () => { props.history.push('/operational_report') } }
			>
				<div className={ classes.button_align }>
					<img src={ report_logo } alt='report_logo'/>
					<span className={ classes.button_text }>Оперативний звіт</span>
				</div>
			</Button>

			<div className={ classes.frame }>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 14) }
					onClick={ () => { props.history.push('/dam') } }
				>
					<div className={ classes.button_align }>
						<img src={ dam_logo } alt='dam_logo'/>
						<span className={ classes.button_text }>Ринок на добу наперед</span>
					</div>
				</Button>

				<Button
					className={ classes.button }
					onClick={ () => { props.history.push('/dam_results') } }
				>
					<div className={ classes.button_align }>
						<img src={ dam_results_logo } alt='dam_results_logo'/>
						<span className={ classes.button_text }>Результати РДН</span>
					</div>
				</Button>

				<Button
					className={ classes.button }
					onClick={ () => { props.history.push('/dam_prices') } }
				>
					<div className={ classes.button_align }>
						<img src={ prices_logo } alt='prices_logo'/>
						<span className={ classes.button_text }>Архів цін РДН</span>
					</div>
				</Button>

			</div>

			<div className={ classes.frame }>

				<Button
					className={ classes.button }
					disabled={ !is_user_authorised(user.authorisation, 17) }
					onClick={ () => { props.history.push('/idm') } }
				>
					<div className={ classes.button_align }>
						<img src={ idm_logo } alt='idm_logo'/>
						<span className={ classes.button_text }>Внутрішньодобовий ринок</span>
					</div>
				</Button>

				<Button
					className={ classes.button }
					disabled={ !user.is_logged_in }
					onClick={ () => { props.history.push('/idm_results') } }
				>
					<div className={ classes.button_align }>
						<img src={ idm_results_logo } alt='idm_results_logo'/>
						<span className={ classes.button_text }>Результати ВДР</span>
					</div>
				</Button>

			</div>

			<Button
				className={ classes.button }
				onClick={ () => { props.history.push('/blocks') } }
			>
				<div className={ classes.button_align }>
					<img src={ blocks_logo } alt='blocks_logo'/>
					<span className={ classes.button_text }>Стан блоків</span>
				</div>
			</Button>

			<Button
				className={ classes.button }
				disabled={ !user.is_logged_in }
				onClick={ () => { props.history.push('/help') } }
			>
				<div className={ classes.button_align }>
					<img src={ help_logo } alt='help_logo'/>
					<span className={ classes.button_text }>Підтримка</span>
				</div>
			</Button>

		</div>
	)
}

export default withRouter(IndexPage);