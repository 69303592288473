import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';

import FilterDate from '../subcomponents/FilterDate';
import DamResultsView from './subcomponents/DamResultsView';
import DamReport from './subcomponents/DamReport';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import DamResultsAnalysisForm from './DamResultsAnalysisForm';
import DamPriceCalculationForm from './DamPriceCalculationForm';

import { damResultsContext } from './damResultsContext';
import { getDateFormat, getLocalDateString, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	control_margin: {
		margin: theme.spacing(2, 0, 0, 2),
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		maxWidth: '50%',
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

}));


function DamResultsForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	const domain_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);


	function getTabsProps(index) {
		return {
			id: `dam-results-tab-${index}`,
			'aria-controls': `dam-results-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`dam-results-tabpanel-${index}`}
				aria-labelledby={`dam-results-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}


	function createInitialState() {

		// set start date to yesterday
		const day_1 = new Date();
		day_1.setHours(-24, 0, 0, 0);

		const complex_state = {

			update_flag: false,

			companies: [],
			domains: [],
			trade_types: [],
			result_types: [],

			trade_date: getDateFormat(day_1),
			domain: 1,
			request_code: 941,
			trade_results: [],

			sell_orders: [],
			sell_results: [],
			buy_orders: [],
			buy_results: [],
			sell_saldo: [],
			buy_saldo: [],
			dam_prices: [],

			request_disabled: false,
		}

		return complex_state;
	}


	function handleRequestCodeChange (event) {

		const event_value = event.target.value;

		setGlobalState(prev_state => ({ ...prev_state,
			request_code: parseInt(event_value),
		}));
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setGlobalState(prev_state => ({ ...prev_state,
			domain: parseInt(event_value),
		}));
	}


	async function onDamResultsDownloadHandler(e) {

		try {

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: true,
			}));

			const payload = {
				request_code: global_state.request_code,
				trade_date: getDateFormat(global_state.trade_date),
			}

			const response = await axios.get('/api/dam/xmtrade/results', { params: payload });
			if (response.status === 200) {

				const trade_results = response.data.results;

				trade_results.forEach(elem => {

					elem.trade_date = getLocalDateString(elem.trade_date);

					const result_type = global_state.result_types.find(item => item.role_id === elem.result_type_id);
					elem.result_type_name = (result_type !== undefined) ? result_type.role_comment : undefined;
				})

				setGlobalState(prev_state => ({ ...prev_state,
					trade_results: trade_results,
					request_disabled: false,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
			}));
		}
	}


	async function DamReportHandle() {

		try {

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: true,
			}));

			const dam_results_get_options = {
				request_code: 941,
				trade_date: getDateFormat(global_state.trade_date),
			};

			const orders_get_options = {
				trade_date: getDateFormat(global_state.trade_date),
			};

			// get applied dam orders
			const dam_orders_result = await axios.get('/api/dam/xmtrade/own_orders', { params: orders_get_options });
			// get dam results
			const dam_results = await axios.get('/api/dam/xmtrade/results', { params: dam_results_get_options });

			// get current domain
			const domain = global_state.domains.find(item => item.domain_id === global_state.domain);

			// sort the orders
			const applied_for_buy = dam_orders_result.data.results.filter(item => item.domain === domain.domain_name && item.trade_type === 'Купівля');
			const results_for_buy = dam_results.data.results.filter(item => item.domain === domain.domain_name && item.result_type_id === 8);
			const applied_for_sell = dam_orders_result.data.results.filter(item => item.domain === domain.domain_name && item.trade_type === 'Продаж');
			const results_for_sell = dam_results.data.results.filter(item => item.domain === domain.domain_name && item.result_type_id === 9);
			// get dam prices
			const dam_prices = (results_for_buy.length > 0) ? [ ...results_for_buy[0].rows ] : [];

			const sell_orders = [];
			const sell_results = [];
			const buy_orders = [];
			const buy_results = [];
			const buy_saldo = [];
			const sell_saldo = [];

			applied_for_buy.forEach(order => {

				order.rows.forEach(row => {

					const buy_row = buy_orders.find(item => item.period === row.period);
					if (buy_row !== undefined) {
						const value = parseFloat(row.amount);
						buy_row.amount += isNaN(value) ? 0 : value;
					} else {
						buy_orders.push({ ...row });
					}

					const saldo_row = buy_saldo.find(item => item.period === row.period);
					if (saldo_row !== undefined) {
						const value = parseFloat(row.amount);
						saldo_row.amount += isNaN(value) ? 0 : value;
					} else {
						buy_saldo.push({ ...row });
					}
				});
			});

			results_for_buy.forEach(order => {

				order.rows.forEach(row => {

					const result_row = buy_results.find(item => item.period === row.period);
					if (result_row !== undefined) {
						const value = parseFloat(row.amount);
						result_row.amount += isNaN(value) ? 0 : value;
					} else {
						buy_results.push({ ...row, price: (row.amount === undefined) ? undefined : row.price });
					}

					const saldo_row = buy_saldo.find(item => item.period === row.period);
					const value = parseFloat(row.amount);
					if (saldo_row !== undefined) {
						saldo_row.amount -= isNaN(value) ? 0 : value;
					} else {
						buy_saldo.push({ ...row, amount: isNaN(value) ? 0 : -value })
					}
				});
			});

			applied_for_sell.forEach(order => {

				order.rows.forEach(row => {

					const sell_row = sell_orders.find(item => item.period === row.period);
					if (sell_row !== undefined) {
						const value = parseFloat(row.amount);
						sell_row.amount += isNaN(value) ? 0 : value;
					} else {
						sell_orders.push({ ...row });
					}

					const existing_row = sell_saldo.find(item => item.period === row.period);
					if (existing_row !== undefined) {
						const value = parseFloat(row.amount);
						existing_row.amount += isNaN(value) ? 0 : value;
					} else {
						sell_saldo.push({ ...row });
					}
				});
			});

			results_for_sell.forEach(order => {

				order.rows.forEach(row =>{

					const result_row = sell_results.find(item => item.period === row.period);
					if (result_row !== undefined) {
						const value = parseFloat(row.amount);
						result_row.amount += isNaN(value) ? 0 : value;
					} else {
						sell_results.push({ ...row, price: (row.amount === undefined) ? undefined : row.price });
					}

					const saldo_row = sell_saldo.find(item => item.period === row.period);
					const value = parseFloat(row.amount);
					if (saldo_row !== undefined) {
						saldo_row.amount -= isNaN(value) ? 0 : value;
					} else {
						sell_saldo.push({ ...row, amount: isNaN(value) ? 0 : -value })
					}
				});
			});

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
				sell_orders: sell_orders,
				sell_results: sell_results,
				buy_orders: buy_orders,
				buy_results: buy_results,
				sell_saldo: sell_saldo,
				buy_saldo: buy_saldo,
				dam_prices: dam_prices,
			}));

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);

			setGlobalState(prev_state => ({ ...prev_state,
				request_disabled: false,
			}));
		}
	}


	function onFilterChange(date) {

		setGlobalState(prev_state => ({ ...prev_state,
			trade_date: date,
		}));
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {
			try {

				const res_market_defs = await axios.get('/api/defs/dam_results');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({ ...prev_state,
					companies: res_company_data.data.company_list,
					domains: res_market_defs.data.domain_list,
					trade_types: res_market_defs.data.trade_type_list,
					result_types: res_market_defs.data.result_types,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		if (tab_no === 1) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
		}

	}, [tab_no])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}


	return (
		<damResultsContext.Provider value={{ global_state, setGlobalState }}>

			<div className={classes.root} >

				<AppBar position='static'>
					<Toolbar>

						<Typography className={classes.title} variant='h6' noWrap>
							Результати РДН
						</Typography>

						{
							(tab_no < 2) &&
							<FilterDate
								date={global_state.trade_date}
								onFilterChange={onFilterChange}
								onFilterClick={() => {}}
							/>
						}

						<IconButton
							className={classes.right_button}
							onClick={() => props.history.push('/')}
							color='inherit'
						>
							<BackIcon />
						</IconButton>

					</Toolbar>
				</AppBar>

				<Tabs
					value={tab_no}
					onChange={OnTabChage}
					className={classes.tab}
					indicatorColor='primary'
					textColor='primary'
				>
					<Tab label='Завантаження результатів' {...getTabsProps(0)} />
					<Tab label='Звітність РДН' {...getTabsProps(1)} />
					<Tab label='Аналіз результатів' {...getTabsProps(2)} />
					<Tab label='Моделювання ціни' {...getTabsProps(3)} />
				</Tabs>

				<TabPanel value={tab_no} index={0}>

					<div className={classes.button_form_control}>

						<FormControl className={classes.button_control} component='fieldset'>

							<FormLabel>Тип результатів</FormLabel>
							<RadioGroup
								value={global_state.request_code}
								onChange={handleRequestCodeChange}>
								<FormControlLabel value={941} control={<Radio />} label='Почасові результати учасника' />
								<FormControlLabel value={951} control={<Radio />} label='Почасові результати РДН' />
								<FormControlLabel value={961} control={<Radio />} label='Добові результати РДН' />
							</RadioGroup>

						</FormControl>

					</div>

					<div className={classes.button_form_control}>

						<Button
							variant='contained'
							color='primary'
							className = { classes.button_control }
							disabled = { global_state.request_disabled }
							onClick = { onDamResultsDownloadHandler }
						>
							Зчитати результати РДН
						</Button>

					</div>

					{
						(global_state.trade_results.length !== 0) &&
						<DamResultsView
							columns_count={ getHoursInDate(global_state.trade_date) }
							results={global_state.trade_results}
						/>
					}

				</TabPanel>

				<TabPanel value={tab_no} index={1}>

					<div className={classes.button_form_control}>

						<FormControl variant='outlined' required className={classes.control_margin} fullWidth>
							<InputLabel ref={domain_label} id='domain-label'>Торгова зона</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={domain_label_width}
								onChange={handleDomainChange}
								value={global_state.domain}
							>
								{display_domains}
							</Select>
						</FormControl>

						<Button
							variant='contained'
							color='primary'
							className={classes.button_control}
							disabled={global_state.request_disabled}
							onClick={DamReportHandle}
						>
							Підготувати звітність
						</Button>

					</div>

					<DamReport
						report_date={getLocalDateString(global_state.trade_date)}
						domain={global_state.domain}
						columns_count={getHoursInDate(global_state.trade_date)}
						sell_orders={global_state.sell_orders}
						buy_orders={global_state.buy_orders}
						sell_results={global_state.sell_results}
						buy_results={global_state.buy_results}
						sell_saldo={global_state.sell_saldo}
						buy_saldo={global_state.buy_saldo}
						dam_prices={global_state.dam_prices}
					/>


				</TabPanel>

				<TabPanel value={tab_no} index={2}>
					<DamResultsAnalysisForm />
				</TabPanel>

				<TabPanel value={tab_no} index={3}>
					<DamPriceCalculationForm />
				</TabPanel>

				<MessageErrorSnackbar
					open={message.show}
					message={message.message}
					info={''}
					onClose={onErrorMessageClose}
				/>

			</div>

		</damResultsContext.Provider>
	);
}

export default withRouter(DamResultsForm);