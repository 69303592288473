
import { excel_date_to_iso_date_string } from './excel_functions';
import { getHoursInDate } from './date_functions';


export function ExternalSeriesFromJson(json_doc) {

	// check array
	if (json_doc.length === 0) {
		return undefined;
	}

	// array of rows
	let result = [];

	let out_domain = json_doc[0][1];
	let seller = json_doc[1][1];
	let in_domain = json_doc[2][1];
	let consumer = json_doc[3][1];

	const start_row_index = 12;

	for (let j = start_row_index; j < json_doc.length; j++) {

		let date;
		let current_row = json_doc[j];

		date = current_row[0];
		if (date !== undefined) {

			let trade_date_string = excel_date_to_iso_date_string(date);

			let trade_date = new Date(trade_date_string);
			trade_date.setHours(0, 0, 0, 0);

			const max_hours = getHoursInDate(trade_date);

			let hour_row = json_doc[j];
			let points = [];
			for (let i = 1; i <= max_hours; i++) {
				if (hour_row[i] !== undefined) {
					points[i - 1] = {
						id: i,
						value: parseFloat(hour_row[i]),
					}
				}
			}

			result.push({
				out_domain: out_domain,
				consumer: consumer,
				in_domain: in_domain,
				seller: seller,
				trade_date: trade_date,
				points: points,
			});

		} else {
			break;
		}
	}

	return result;
}
