
// email validation expression
export function is_valid_email (value) {
	const pattern = /^[a-zA-Z0-9._+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
	return (value !== '') && pattern.test(String(value));
}


// password validation expression:
// at least 1 lowercase alphabetical character
// at least 1 uppercase alphabetical character
// at least 1 numeric character
// at least 1 special character
// at least 8 characters long
export function is_valid_password (value) {
	const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&*-])(.{8,})$/;
	return (value !== '') && pattern.test(String(value));
}


export function is_valid_date (s) {
	const possible_date = new Date(s);
	return possible_date instanceof Date && !isNaN(possible_date);
}


// registration code validation expression:
// 8 digits
export function is_valid_registration_code (value) {
	const pattern = /^[0-9]{8}$/;
	return (value !== '') && pattern.test(String(value));
}


export function is_valid_float_input (value) {
	const pattern = /^[-0-9]*\.?[0-9]{1,2}$/;
	return (value !== '') && pattern.test(String(value));
}


export function is_valid_float (value) {

	if (typeof(value) === 'string') {
		const result = parseFloat(value);
		if (!isNaN(result)) {
			return result.toString() === value;
		}
	} else {
		return parseFloat(value) === value;
	}
	
	return false;
}


// match for any character not in the list
export function is_valid_address (value) {
	const pattern = /^[^`~!@#$%^&*()_|+=?;:<>{}[\]\\]*$/;
	return (value !== '') && pattern.test(String(value));
}


// match for any character not in the list
export function is_valid_name (value) {
	const pattern = /^[^0-9`~!@#$%^&*()"_|+=?;:,.<>{}[\]\\/]*$/;
	return (value !== '') && pattern.test(String(value));
}


// match for any character not in the list
export function is_valid_company_name (value) {
	const pattern = /^[^`~!@#$%^&*()|=?;:,<>{}[\]\\/]*$/;
	return (value !== '') && pattern.test(String(value));
}


// EIC code validation expression:
// uppercase alphabetical character
// numeric character
// several special characters
export function is_valid_eic_code (value) {
	const pattern = /^[A-Z0-9+\-_]*$/;
	return (value !== '') && pattern.test(String(value));
}


export function is_valid_phone (value) {
	const pattern = /^[+]{0,1}[0-9]*[\s]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/;
	return (value !== '') && pattern.test(String(value));
}


// match for any character not in the list
export function is_valid_contract_number (value) {
	const pattern = /^[^`~!@#$%^&*()'"|+=?;:,<>{}[\]\\]*$/;
	return (value !== '') && pattern.test(String(value));
}


export function is_company_name_equal (name1, name2) {

	const comp1 = name1.replace(/[^a-zA-Zа-яґєіїА-ЯҐЄІЇ0-9'\-. ]/g, '').toLowerCase();
	const comp2 = name2.toLowerCase();

	return (comp1 === comp2);
}