
// const DayOfWeekEnu = {
// 	0: 'Sunday',
// 	1: 'Monday',
// 	2: 'Tuesday',
// 	3: 'Wednesday',
// 	4: 'Thursday',
// 	5: 'Friday',
// 	6: 'Saturday'
// }


const DayOfWeekUkr = {
	0: 'Неділя',
	1: 'Понеділок',
	2: 'Вівторок',
	3: 'Середа',
	4: 'Четвер',
	5: 'Пятниця',
	6: 'Субота',
}


export function getDayOfWeek(d) {
	return DayOfWeekUkr[new Date(d).getDay()];
}


// formats number with 2 digits
function pad(number) {
	if (number < 10) {
		return '0' + number;
	}
	return number;
}


function getTimeZoneOffset(date, timeZone) {

	// Abuse the Intl API to get a local ISO 8601 string for a given time zone.
	const options = {timeZone, calendar: 'iso8601', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
	const dateTimeFormat = new Intl.DateTimeFormat(undefined, options);
	const parts = dateTimeFormat.formatToParts(date);
	const map = new Map(parts.map(x => [x.type, x.value]));
	const year = map.get('year');
	const month = map.get('month');
	const day = map.get('day');
	const hour = map.get('hour');
	const minute = map.get('minute');
	const second = map.get('second');
	const ms = date.getMilliseconds().toString().padStart(3, '0');
	const iso = `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;

	// Lie to the Date object constructor that it's a UTC time.
	const lie = new Date(iso + 'Z');

	// Return the difference in timestamps, as minutes
	// Positive values are West of GMT, opposite of ISO 8601
	// this matches the output of `Date.getTimeZoneOffset`
	return -(lie - date) / 60 / 1000;
}


// converts date to string, required by text input
export function getDateTimeFormat(d) {

	if (!d) return '';

	let result = new Date(d);
	// add time zone offset to convert to ISO format
	result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

	if (!isNaN(result)) {
		return result.toISOString().slice(0, -8);
	} else {
		return '';
	}
}


export function getDateFormat(d) {

	if (!d) return '';

	let result = new Date(d);

	if (!isNaN(result)) {
		return result.getFullYear() + 
		'-' + pad(result.getMonth() + 1) + 
		'-' + pad(result.getDate());
	} else {
		return '';
	}
}


export function getMonthFormat(d) {

	if (!d) return '';

	let result = new Date(d);

	if (!isNaN(result)) {
		return result.getFullYear() + 
		'-' + pad(result.getMonth() + 1);
	} else {
		return '';
	}
}


// converts string in text input format to ISO string
export function getISOStringFromDateString(s) {

	let result = new Date(s);
	return result.toISOString();
}


export function getUTCISOStringFromDateString(s) {

	let result = new Date(s);

	// add time zone offset to convert to ISO format
	result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
	return result.toISOString();
}


export function getUTCISOString_HHMM_FromDateString(s) {

	if (!s) return '';

	let result = new Date(s);
	result.setMinutes(result.getMinutes() + result.getTimezoneOffset());

	if (!isNaN(result)) {
		return result.getUTCFullYear() +
		'-' + pad(result.getUTCMonth() + 1) +
		'-' + pad(result.getUTCDate()) +
		'T' + pad(result.getUTCHours()) +
		':' + pad(result.getUTCMinutes()) +
		'Z';
	} else {
		return '';
	}
}


export function getISOString_HHMM_FromDateString(s) {

	if (!s) return '';

	let result = new Date(s);

	if (!isNaN(result)) {
		return result.getUTCFullYear() +
		'-' + pad(result.getUTCMonth() + 1) +
		'-' + pad(result.getUTCDate()) +
		'T' + pad(result.getUTCHours()) +
		':' + pad(result.getUTCMinutes()) +
		'Z';
	} else {
		return '';
	}
}


export function getISOString_HHMMSS_FromDateString(s) {

	if (!s) return '';

	let result = new Date(s);

	if (!isNaN(result)) {
		return result.getUTCFullYear() +
		'-' + pad(result.getUTCMonth() + 1) +
		'-' + pad(result.getUTCDate()) +
		'T' + pad(result.getUTCHours()) +
		':' + pad(result.getUTCMinutes()) +
		':' + pad(result.getUTCSeconds()) +
		'Z';
	} else {
		return '';
	}
}


// converts date string from server (which is UTC) to locale string
export function getLocalDateTimeString(s) {

	var result = new Date(s);

	// // add time zone offset to convert to ISO format
	// result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

	return result.toLocaleString();
}


// converts date string from server (which is UTC) to locale date string
export function getLocalDateString(s) {

	var result = new Date(s);

	// // add time zone offset to convert to ISO format
	// result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

	return result.toLocaleDateString();
}


export function getHoursInDate(d) {

	const day_1 = new Date(d);
	day_1.setHours(0, 0, 0, 0);

	const day_2 = new Date(d);
	day_2.setHours(24, 0, 0, 0);

	return (day_2 - day_1) / 3.6e6;
}


export function getDaysInMonth(d) {

	const date = new Date(d);
	return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}


export function getDateFromLocalDateString(s) {

	if (typeof s === 'string') {
		// split by . and reverse order
		const temp = s.split('.').reverse();
		// add 20 if year is 2-digits
		if (temp[0].length === 2) {
			temp[0] = '20' + temp[0];
		}
		// join and convert to date
		return new Date(temp.join('-'));
	} else {
		return undefined;
	}
}


export function getDateTimeFromLocalDateTimeString(s) {

	if (typeof s === 'string') {
		// split by empty space
		const date_parts = s.split(' ');

		// split by . and reverse order
		const temp = date_parts[0].split('.').reverse();
		// add 20 if year is 2-digits
		if (temp[0].length === 2) {
			temp[0] = '20' + temp[0];
		}
		// join and convert to date
		return new Date(temp.join('-') + ' ' + date_parts[1]);
	} else {
		return undefined;
	}
}


export function utc_time_to_ukraine_time(t) {

	const d = new Date(t);
	const current_tz = d.getTimezoneOffset();
	const target_tz = getTimeZoneOffset(d, 'Europe/Kiev');
	const add_tz = (target_tz - current_tz) * 60 * 1000;

	const d2 = new Date(d.getTime() + add_tz);
	const result = d2.toISOString().slice(0, -8) + 'Z';

	return result;
}


export function parse_month(month_str) {

	switch(month_str.toLowerCase()) {

		case 'січень':
			return 1;
		case 'лютий':
			return 2;
		case 'березень':
			return 3;
		case 'квітень':
			return 4;
		case 'травень':
			return 5;
		case 'червень':
			return 6;
		case 'липень':
			return 7;
		case 'серпень':
			return 8;
		case 'вересень':
			return 9;
		case 'жовтень':
			return 10;
		case 'листопад':
			return 11;
		case 'грудень':
			return 12;
		default:
			return undefined;
	}
}