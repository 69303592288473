import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ConfirmIcon from '@material-ui/icons/CheckSharp';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { getLocalDateTimeString } from '../../utils/date_functions';


const useStyles = makeStyles(theme => ({

	header: {
		textAlign: 'center',
		fontWeight: 'bold',
	},

	block: {
		border: '1px solid gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	attachment: {
		border: '1px dotted gray',
		borderRadius: '3px',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},

	action_button: {
		margin: theme.spacing(0, 1, 0, 1),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

}));


export default function PlanRowsConfirmationDialog(props) {

	const classes = useStyles();
	const { confirmations, open, onClose, onConfirm, onDelete } = props;


	const onCancelHandler = () => {
		onClose(undefined);
	};


	return (
		<Dialog
			open={open}
			maxWidth='xl'
			aria-labelledby='upload-dialog-title'
		>
			<DialogTitle id='upload-dialog-title' className={classes.header} >
				Підтвердження обсягів електричної енергії
			</DialogTitle>

			<DialogContent>

				{
					confirmations.map((mail, m_index) => (

						<div
							key={m_index}
							className={ classes.block }
						>

							<Typography>
								<b>Відправник</b>: { mail.sender }, <b>Дата</b>: { getLocalDateTimeString(mail.entry_date) }
							</Typography>

							<br />

							<Typography>
								<b>Текст повідомлення</b>:
							</Typography>

							<i>
								{
									mail.body.split('\n').map((i, key) => (
										<div
											key={`p_${m_index}_${key}`}
										>
											{i}
										</div>
									))
								}
							</i>

							<br />

							{
								mail.attachments.map((att, a_index) => (
									<div
										key={`div_${a_index}`}
										className={ classes.attachment }
									>
										<Typography>
											<b>Вкладення</b>: { att.name }
										</Typography>
										<table key={`ta_${a_index}`}>
											<tbody key={`tb_${a_index}`}>
												{
													att.content.map((row, row_index) => (
														<tr key={row_index}>
															{
																row.map((cell, cell_index) => (
																	<td key={cell_index}>
																		{ (Number(cell) === cell && cell % 1 !== 0) ? parseFloat(cell).toFixed(1) : cell }
																	</td>
																))
															}
														</tr>
													))
												}
											</tbody>
										</table>
									</div>
								))
							}

							<div className={classes.button_form_control}>

								<Fab
									className={ classes.action_button }
									onClick={ () => onConfirm(mail) }
									color='primary'
									size='small'
								>
									<ConfirmIcon />
								</Fab>

								<Fab
									className={ classes.action_button }
									onClick={ () => onDelete(mail) }
									color='primary'
									size='small'
								>
									<DeleteIcon />
								</Fab>

							</div>

						</div>
					))
				}

				<div className={classes.button_form_control}>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Закрити
					</Button>

				</div>

			</DialogContent>
		</Dialog>
	);
}

PlanRowsConfirmationDialog.propTypes = {
	confirmations: PropTypes.array.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};