
import { parse_month } from './date_functions';


export function ua_prices_from_json(json_doc) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	// get header
	const header = json_doc[0][0];
	const is_dam = header.includes('РДН');

	if (!is_dam) {
		return [];
	}

	const month_pos = header.indexOf(' за ');
	const after_month_pos = header.indexOf(' ', month_pos + 4);

	let domain_str = header.substring(header.indexOf('по ') + 3, month_pos);
	const month_str = header.substring(month_pos + 4, after_month_pos);
	const year_str = header.substring(after_month_pos);

	// fix for new format file for one domain
	if (header.indexOf('по ') === -1) {
		domain_str = 'ОЕС';
	}

	const trade_month = new Date();
	trade_month.setFullYear(parseInt(year_str));
	trade_month.setMonth(parse_month(month_str) - 1, 1);
	trade_month.setHours(0, 0, 0, 0);

	for (let i = 3; i < json_doc.length; i++) {

		if (json_doc[i].length > 0) {

			const trade_date = new Date(trade_month);
			trade_date.setDate(json_doc[i][0])

			const prices = {
				domain: domain_str, 
				trade_date: trade_date,
			}

			for (let j = 1; j < json_doc[i].length; j++) {

				const index = (j < 10) ? `p0${j}` : `p${j}`;
				prices[index] = json_doc[i][j];
			}

			result.push(prices);

		} else {
			break;
		}
	}

	return result;
}


export function non_ua_prices_from_json(json_doc) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	for (let c = 2; c < json_doc[1].length; c++) {

		for (let i = 2; i < json_doc.length; ) {

			if (json_doc[i].length > 0) {
	
				const prices = {
					domain: json_doc[1][c], 
					trade_date: json_doc[i][0],
				}

				let j = 1;
				for (; j <= 25; j++) {
	
					if (json_doc[i + j - 1] !== undefined) {

						const index = (j < 10) ? `p0${j}` : `p${j}`;
						prices[index] = parseFloat(json_doc[i + j - 1][c]);
	
						if (j === 23 && json_doc[i + 23][1] !== 'H24') {
							break;
						}
						if (j === 24 && json_doc[i + 24] !== undefined && json_doc[i + 24][1] !== 'H25') {
							break;
						}
					}
				}

				i += j;
				result.push(prices);
	
			} else {
				break;
			}
		}
	}

	return result;
}


export function eur_uah_erates(json_doc) {

	if (json_doc === undefined) {
		return;
	}

	const result = [];

	for (let i = 1; i < json_doc.length; i++) {

		if (json_doc[i].length === 7) {

			const item = {
				trade_date: json_doc[i][0],
				code: parseInt(json_doc[i][2]),
				exchange_rate: parseFloat(json_doc[i][6])
			}

			result.push(item);

		} else {
			break;
		}
	}

	return result;
}