import React from 'react'

function NotAuthorised () {
    return (
        <div>
            <h3>Користувач не авторизований.</h3>
        </div>
    )
}

export default NotAuthorised;
