import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ActionIcon from '@material-ui/icons/PostAdd';
import DetailsIcon from '@material-ui/icons/DetailsSharp';

import { getLocalDateString, getDayOfWeek, getHoursInDate } from '../../utils/date_functions';
import { is_valid_float } from '../../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		width: '100%',
		margin: theme.spacing(2),
	},

	table: {
		tableLayout: 'auto',
	},

	row_root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},

	head_cell: {
		background: 'white',
		fontWeight: 'bold',
		padding: theme.spacing(1, 0, 1, 0),
	},

	slim_cell: {
		padding: theme.spacing(1, 0, 1, 0),
	},

	result_cell_pos: {
		background: 'lightgray',
		color: 'black',
		fontWeight: '999',
		padding: theme.spacing(1, 0, 1, 0),
	},

	result_cell_neg: {
		background: 'lightgray',
		color: 'red',
		fontWeight: '999',
		padding: theme.spacing(1, 0, 1, 0),
	},

}));


function formatFloat(value) {

	if (is_valid_float(value)) {

		let ret_val = value.toFixed(1);
		if (ret_val === '-0.0') {
			return '0.0';
		}

		return ret_val;

	} else {
		return value;
	}
}


function DetailTable(props) {

	const classes = props.style;
	const { head_cells, rows, details_function } = props;

	return (
		<TableContainer>
			<Table
				size={'small'}
			>
				<TableBody
					key={'detail-table'}
				>

					<TableRow
						key={`detail-header`}
					>
						{
							head_cells.map((item, head_index) => (
								<TableCell
									key={item.id}
									align={(head_index === 0) ? 'left' : 'right'}
									className={classes.head_cell}
								>
									{item.name}
								</TableCell>
							))
						}
					</TableRow>

					{
						rows.map((row, row_index) => (
							<TableRow
								key={`row_${row_index}`}
							>
								{
									row.cells.map((cell, cell_index) => (
										<TableCell
											key={`cell_${cell_index}`}
											align={(cell_index === 0) ? 'left' : 'right'}
											className={classes.slim_cell}
										>
											{cell.value}
										</TableCell>
									))
								}
								{
									row.edit &&
									<TableCell
										key={`cell_edit`}
									>
										<IconButton
											onClick={() => details_function(row.trade_date, row.consumer_company_id, row.seller_company_id)}
										>
											<DetailsIcon />
										</IconButton>
									</TableCell>
								}
							</TableRow>
						))
					}

				</TableBody>
			</Table>
		</TableContainer>
	)
}


function ExpandRow(props) {

	const classes = props.style;
	const { row, index, details_head_cells, details_rows, action_function, details_function } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow
				hover
				key={row.id}
			>
				{
					(index === 0) &&
					<TableCell
						key={row.id}
						className={classes.head_cell}
						width='100px'
						rowSpan={5}
					>
						{`${getLocalDateString(row.date)} ${getDayOfWeek(row.date)}`}
					</TableCell>
				}
				{
					row.cells.map((cell, cell_index) => (
						<TableCell
							key={cell.id}
							align={'right'}
							className={
								(index === 2)
									?
									(cell.value < 0) ? classes.result_cell_neg : classes.result_cell_pos
									: classes.slim_cell
							}
						>
							{
								(cell_index === (row.cells.length - 1) && index !== 2 && details_rows.length > 0) &&
								<IconButton
									size='small'
									onClick={() => setOpen(!open)}
								>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
							{
								(cell_index === (row.cells.length - 1) && index === 2) &&
								<Tooltip title='Створити заявку РДН'>
									<IconButton
										size='small'
										onClick={() => action_function(row)}
									>
										<ActionIcon />
									</IconButton>
								</Tooltip>
							}
							{cell.value}
						</TableCell>
					))
				}
			</TableRow>
			{
				(index !== 2) &&
				<TableRow
					className={(open) ? undefined : classes.row_root}
				>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0 }}
						colSpan={26}
					>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box>
								<DetailTable
									style={classes}
									head_cells={details_head_cells}
									rows={details_rows}
									details_function={details_function}
								/>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			}
		</React.Fragment>
	)
}


export default function PlanningTable(props) {

	const classes = useStyles();
	const { period_start, period_end, rows, action_function, details_function } = props;

	// calculate number of days
	let days = Math.round((new Date(period_end) - new Date(period_start)) / 3.6e6 / 24) + 1;
	if (isNaN(days)) {
		days = 2;
	}

	// calculate max column count
	var max_column_number = 24;
	for (let d = 0; d < days; d++) {

		const row_date = new Date(period_start);
		row_date.setHours(24 * d, 0, 0, 0);
		const current_max_hours = getHoursInDate(row_date);

		if (current_max_hours > max_column_number) {
			max_column_number = current_max_hours;
		}
	}

	// create header arrays
	const main_head_cells = [];
	const details_head_cells = [];
	for (let i = 0; i <= max_column_number + 2; i++) {

		let name;
		switch (i) {
			case 0:
				name = 'Доба';
				break;
			case max_column_number + 1:
				name = 'Всього';
				break;
			case max_column_number + 2:
				name = '';
				break;
			default:
				name = i.toString();
				break;
		}

		main_head_cells[i] = {
			id: `head-${i}-name`,
			name: name,
		}

		if (i <= max_column_number + 1) {
			details_head_cells[i] = {
				id: `head-${i}-company`,
				name: (i === 0) ? 'Контрагент' : name,
			}
		}
	}

	// create blocks for each day
	const day_blocks = [];
	for (let d = 0; d < days; d++) {

		const row_date = new Date(period_start);
		row_date.setHours(24 * d, 0, 0, 0);

		const current_max_hours = getHoursInDate(row_date);

		// get cells for each of 3 rows
		const balance_row = [];
		for (let i = 0; i < max_column_number + 1; i++) {
			balance_row[i] = 0;
		}

		// each day consist of 3 rows
		const day_rows = [];
		for (let r = 0; r < 3; r++) {

			let row_name, detail_rows, filtered_rows;

			// get cells for detail rows and row name
			switch (r) {
				case 0:
					row_name = 'Купівля';

					const income_rows = [];
					filtered_rows = rows.income.filter(item => {
						const item_date = new Date(item.trade_date);
						return (item_date.getFullYear() === row_date.getFullYear() &&
							item_date.getMonth() === row_date.getMonth() &&
							item_date.getDate() === row_date.getDate())
					});
					if (filtered_rows !== undefined) {
						filtered_rows.forEach(item => {

							let sum = 0;
							const row_cells = [];
							row_cells.push({
								id: 'contractor',
								value: item.seller,
							});

							for (let i = 1; i <= max_column_number + 1; i++) {

								if (item.points[i - 1] !== undefined) {
									const value = parseFloat(item.points[i - 1].value);
									sum += value;
									row_cells.push({
										id: (i < 10) ? `p0${i}` : `p${i}`,
										value: value.toFixed(1)
									});
								}
							}

							if (current_max_hours < max_column_number) {
								row_cells.push({
									id: 'decoy',
									value: '',
								});
							}

							row_cells.push({
								id: 'sum',
								value: sum.toFixed(1),
							});

							income_rows.push({
								id: item.id,
								edit: item.edit !== undefined && item.edit,
								trade_date: item.trade_date,
								seller_company_id: item.seller_company_id,
								consumer_company_id: item.consumer_company_id,
								cells: row_cells,
							})
						})
					}

					detail_rows = income_rows;
					break;
				case 1:
					row_name = 'Продаж';

					const outcome_rows = [];
					filtered_rows = rows.outcome.filter(item => {
						const item_date = new Date(item.trade_date);
						return (item_date.getFullYear() === row_date.getFullYear() &&
							item_date.getMonth() === row_date.getMonth() &&
							item_date.getDate() === row_date.getDate())
					});
					if (filtered_rows !== undefined) {
						filtered_rows.forEach(item => {

							let sum = 0;
							const row_cells = [];
							row_cells.push({
								id: 'contractor',
								value: item.consumer,
							});

							for (let i = 1; i <= max_column_number + 1; i++) {

								if (item.points[i - 1] !== undefined) {
									const value = parseFloat(item.points[i - 1].value);
									sum += value;
									row_cells.push({
										id: (i < 10) ? `p0${i}` : `p${i}`,
										value: value.toFixed(1)
									});
								}
							}

							if (current_max_hours < max_column_number) {
								row_cells.push({
									id: 'decoy',
									value: '',
								});
							}

							row_cells.push({
								id: 'sum',
								value: sum.toFixed(1)
							});

							outcome_rows.push({
								id: item.id,
								edit: item.edit !== undefined && item.edit,
								trade_date: item.trade_date,
								seller_company_id: item.seller_company_id,
								consumer_company_id: item.consumer_company_id,
								cells: row_cells,
							})
						})
					}

					detail_rows = outcome_rows;
					break;
				case 2:
					row_name = 'Сальдо';
					detail_rows = [];
					break;
				default:
					break;
			}

			// get cells for each of 3 rows
			const result_row = [];
			for (let i = 0; i <= max_column_number; i++) {
				result_row[i] = 0;
			}

			detail_rows.forEach(item => {

				for (let i = 1; i <= max_column_number + 1; i++) {

					if (item.cells[i]) {

						const value = parseFloat(item.cells[i].value);
						if (!isNaN(value)) {
							result_row[i - 1] += value;

							switch (r) {
								case 0:
									balance_row[i - 1] += value;
									break;
								case 1:
									balance_row[i - 1] -= value;
									break;
								default:
									break;
							}
						}
					}
				}
			});

			const day_cells = [];
			for (let c = 0; c < max_column_number + 2; c++) {
				day_cells[c] = {
					id: `cell_${c}`,
					value: (c === max_column_number + 1) ? row_name : (r < 2) ? formatFloat(result_row[c]) : formatFloat(balance_row[c]),
				}
			}

			day_rows[r] = {
				id: `row_${r}`,
				date: row_date,
				cells: day_cells,
				details_head_cells: details_head_cells,
				details_rows: detail_rows,
			}
		}

		day_blocks[d] = {
			id: `block_${d}`,
			rows: day_rows,
		}
	}


	return (
		<TableContainer className={classes.paper}>
			<Table size={'small'} className={classes.table}>

				<TableBody
					key='header_body'
				>
					<TableRow
						key='header_row'
					>
						{
							main_head_cells.map((item, head_index) => (
								<TableCell
									key={item.id}
									align={(head_index === 0) ? 'left' : 'right'}
									className={classes.head_cell}
								>
									{item.name}
								</TableCell>
							))
						}
					</TableRow>
				</TableBody>

				{
					day_blocks.map(block => (
						<TableBody
							key={block.id}
						>
							{
								block.rows.map((row, row_index) => (
									<ExpandRow
										key={`row_${row.id}`}
										style={classes}
										index={row_index}
										row={row}
										details_head_cells={row.details_head_cells}
										details_rows={row.details_rows}
										action_function={action_function}
										details_function={details_function}
									/>
								))
							}
						</TableBody>
					))
				}

			</Table>
		</TableContainer>
	)
}


PlanningTable.propTypes = {
	period_start: PropTypes.string.isRequired,
	period_end: PropTypes.string.isRequired,
	rows: PropTypes.object.isRequired,
	action_function: PropTypes.func.isRequired,
	details_function: PropTypes.func.isRequired,
};