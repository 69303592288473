import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import TimeSeriesTable from './subcomponents/TimeSeriesTable';
import PlanRowsTable from './subcomponents/PlanRowsTable';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { bcContext } from './bcContext';
import { is_valid_date } from '../utils/validation';
import { getDateFormat, getLocalDateString } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		alignItems: 'center'
	},

	input_form_control: {
		margin: theme.spacing(0, 2, 0, 2),
		textAlign: 'center',
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	header: {
		marginBottom: theme.spacing(3),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	control_top_margin: {
		marginTop: theme.spacing(2)
	}

}));


export default function ScheduleEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	const initial_filter = {
		status_id: 0,
	}
	const [filter, setFilter] = useState(initial_filter);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// variables for the label control
	const domain_label = React.useRef(null);
	const sender_company_label = React.useRef(null);
	const sender_role_label = React.useRef(null);

	// state for label widths
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [sender_company_label_width, setSenderCompanyLabelWidth] = useState(0);
	const [sender_role_label_width, setSenderRoleLabelWidth] = useState(0);

	const [selected_time_series, setSelectedTimeSeries] = useState([]);
	const [selected_plan_rows, setSelectedPlanRows] = useState([]);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(bcContext);

	// get schedule id from url
	const schedule_id = parseInt(props.match.params.schedule_id);
	const update_url = isNaN(schedule_id) ? '/api/bc/schedules/add' : '/api/bc/schedules/' + schedule_id;
	const close_url = isNaN(schedule_id) ? undefined : '/api/bc/schedules/' + schedule_id + '/close';


	function createInitialState () {

		var day_1 = new Date();
		day_1.setHours(24, 0, 0, 0);

		const complex_state = {
			created_date: '',
			revision: 0,
			revision_id: 0,
			domain_id: 0,
			status_id: 1,
			trade_date: getDateFormat(day_1),
			date_valid: true,
		
			sender_company_id: 0,
			sender_role_id: 1,
			
			receiver_company_name: '',
			receiver_company_code: '',
			receiver_company_role_code: '',
			receiver_company_role_comment: '',

			time_series_list: [],
			plan_rows_list: [],

			changed: true,
			update_flag: true
		}

		return complex_state;
	}


	function handleDomainChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeDateChange (event) {

		const event_value = event.target.value;
		const compare_date = new Date();
		compare_date.setHours(0, 0, 0, 0);

		setLocalState(prev_state => ({ ...prev_state,
			trade_date: event_value,
			date_valid: new Date(event_value) >= compare_date,
			changed: true,
		}));
	}


	function handleCompanyChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			sender_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleRoleChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			sender_role_id: parseInt(event_value),
			changed: true,
		}));
	}


	function onFilterStatusChange (event) {

		const event_value = event.target.value;

		setFilter(prev_state => ({ ...prev_state,
			status_id: parseInt(event_value),
		}));
	}


	async function handleCreateTimeSeries (row_id) {

		const source_row = local_state.plan_rows_list.find(item => item.plan_row_id === row_id);
		if (source_row !== undefined) {

			try {

				await axios.post('/api/bc/ts/add', source_row);
				setLocalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(prev_state => ({ ...prev_state,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}

		}
	}


	async function handleRowAction (action_id, row_id) {

		switch (action_id) {
			case 2:
				handleCreateTimeSeries(row_id);
				break;
			default:
				break;
		}
	}


	async function OnCreateTimeSeriesHandler () {

		for (let i = 0; i < selected_plan_rows.length; i++) {
			await handleCreateTimeSeries(selected_plan_rows[i]);
		}
	}


	async function saveHandler () {

		try {

			const new_data = {
				domain_id: local_state.domain_id,
				trade_date: getDateFormat(local_state.trade_date),
				sender_company_id: local_state.sender_company_id,
				sender_role_id: local_state.sender_role_id,
				time_series: selected_time_series,
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(prev_state => ({ ...prev_state,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		// variables for the message
		let double = false;
		let consumer_id = 0;
		let seller_id = 0;

		const selected_ts_full = local_state.time_series_list.filter(item => selected_time_series.includes(item.time_series_id));

		// check that unique time series selected
		selected_ts_full.forEach(item => {

			const similar_ts = selected_ts_full.filter(ts => ts.consumer_company_id === item.consumer_company_id && ts.seller_company_id === item.seller_company_id);
			if (similar_ts.length > 1) {
				double = true;
				consumer_id = item.consumer_company_id;
				seller_id = item.seller_company_id;
			}
		})

		if (double) {

			const consumer = global_state.companies.find(company => company.company_id === consumer_id);
			const seller = global_state.companies.find(company => company.company_id === seller_id);

			window.alert(`Для графіка обрано два часових ряди із однаковими компаніями. Продавець ${ seller !== undefined ? seller.name : '?' }; покупець: ${ consumer !== undefined ? consumer.name : '?' }.`);
			return;
		}

		if (await saveHandler()) {
			// return to parent page
			props.history.push('/schedules');
		}
	}


	function onCancelHandler () {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.go(-1);
	}


	function OnImportExportHandler () {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.push('/schedules/import/' + schedule_id + '/0');
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		setDomainLabelWidth(domain_label.current.offsetWidth);
		setSenderCompanyLabelWidth(sender_company_label.current.offsetWidth);
		setSenderRoleLabelWidth(sender_role_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		axios.get('/api/bc/schedules/receiver')
			.then(response => {

				setLocalState(prev_state => ({ ...prev_state,
					receiver_company_name: response.data.name,
					receiver_company_code: response.data.eic_x_code,
					receiver_company_role_code: response.data.receiver_role_code,
					receiver_company_role_comment: response.data.receiver_role_comment,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [])


	useEffect(() => {

		const sender_company = global_state.companies.find(company => company.company_id === user.company_id);

		if (sender_company !== undefined) {
			setLocalState(prev_state => ({ ...prev_state,
				sender_company_id: sender_company.company_id,
			}));
		}

	}, [user, global_state])


	useEffect(() => {

		if (schedule_id > 0) {

			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { revision, revision_id, status_id, domain_id, trade_date, sender_company_id, sender_market_role_id } = response.data;

						let time_series = [];
						for (let i = 0; i < response.data.time_series.length; i++) {
							time_series.push(parseInt(response.data.time_series[i].time_series_id));
						}

						setLocalState(prev_state => ({ ...prev_state,
							revision: parseInt(revision),
							revision_id: parseInt(revision_id),
							status_id: parseInt(status_id),
							domain_id: parseInt(domain_id),
							trade_date: getDateFormat(trade_date),
							sender_company_id: parseInt(sender_company_id),
							sender_role_id: parseInt(sender_market_role_id),
							changed: false,
						}));

						setSelectedTimeSeries(time_series);
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
						let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
							edit: edit,
						}));
					}

					console.log(error);
				});
		}

	}, [schedule_id, update_url])


	useEffect(() => {

		if (local_state.domain_id !== 0 && is_valid_date(local_state.trade_date)) {

			axios.get('/api/bc/ts', {
				params: {
					domain:			local_state.domain_id,
					period_start:	getDateFormat(local_state.trade_date),
					period_end:		getDateFormat(local_state.trade_date)
				}
			})
				.then(response => {

					const ts = response.data.time_series_list;

					const this_date = new Date(local_state.trade_date);
					const ts_period = ts.filter(row => { const row_date = new Date(row.trade_date);
										return (row_date.getFullYear() === this_date.getFullYear() &&
												row_date.getMonth() === this_date.getMonth() &&
												row_date.getDate() === this_date.getDate() &&
												row.status_id < 5)});

					setLocalState(prev_state => ({ ...prev_state,
						time_series_list: ts_period,
					}));

				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						setMessage(message => ({ ...message,
							show: true,
							message: error.response.data,
						}));
					}

					console.log(error);
				});
		}

	}, [local_state.domain_id, local_state.trade_date, local_state.update_flag])


	useEffect(() => {

		if (local_state.status_id === 1 && local_state.domain_id > 0) {

			axios.get('/api/planning/plans_total', {
				params: {
					domain: local_state.domain_id,
					period_start: getDateFormat(local_state.trade_date),
					period_end: getDateFormat(local_state.trade_date),
				}
			})
				.then(response => {

					const planning_rows = response.data.plan_row_list;
					setLocalState(prev_state => ({ ...prev_state,
						plan_rows_list: planning_rows
					}));

				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
						}));
					}

					console.log(error);
				});
		}

	}, [local_state.domain_id, local_state.trade_date])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (close_url) {
				axios.post(close_url);
			}
		}

	}, [close_url])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={ item.domain_id } value={ item.domain_id }>{ item.domain_display_name }</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies
			.filter(company => company.company_id === user.company_id)
			.map(item => {
				return (
					<option key={ item.company_id } value={ item.company_id }>{ item.name }</option>
				)
			})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for market roles
	var display_roles = [];
	if (global_state.market_roles.length > 0) {
		display_roles = global_state.market_roles.map(item => {
			return (
				<option key={ item.role_id } value={ item.role_id }>{ item.role_code + ': ' + item.role_comment }</option>
			)
		})
		display_roles.unshift(<option key={-1} value={''} ></option>)
	}

	// filling filter combobox for statuses
	var display_statuses = [];
	if (global_state.statuses.length > 0) {

		display_statuses = global_state.statuses.map(item => {
			return (
				<option key={ item.status_id } value={ item.status_id }>{ item.status_name }</option>
			)
		})
		display_statuses.unshift(<option key={0} value={0}> Всі </option>)
	}


	var time_series_rows = [];
	var plan_rows = [];
	if ((global_state.domains.length > 0) && (global_state.statuses.length > 0) && (global_state.companies.length > 0)) {

		time_series_rows = local_state.time_series_list
			.filter(item => ((filter.status_id > 0) && (item.status_id === filter.status_id)) || ((filter.status_id === 0) && (item.status_id > 0)))
			.map(item => {

			const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
			const status = global_state.statuses.find(elem => elem.status_id === item.status_id);
			const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
			const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

			if (domain === undefined || consumer === undefined || seller === undefined) {
				window.alert('Дані не знайдено. Перевірте правільність внесення контрагентів, договорів та торгівельних зон.');
			}

			return ({
				key: item.time_series_id,
				id: item.time_series_id,
				domain: (domain !== undefined) ? domain.domain_display_name : '',
				status: (status !== undefined) ? status.status_name : '',
				revision: item.revision,
				start: getLocalDateString(item.trade_date),
				consumer: (consumer !== undefined) ? consumer.name : '',
				seller: (seller !== undefined) ? seller.name : '',
				amount: item.amount.toFixed(1),
			})
		});

		plan_rows = local_state.plan_rows_list.map(item => {

			let amount = 0;
			for (let i = 1; i <=25; i++) {

				const index = (i < 10) ? `p0${i}` : `p${i}`;
				if (item[index] !== null) {
					amount += parseFloat(item[index]);
				}
			}

			const domain = global_state.domains.find(elem => elem.domain_id === item.domain_id);
			const consumer = global_state.companies.find(elem => elem.company_id === item.consumer_company_id);
			const seller = global_state.companies.find(elem => elem.company_id === item.seller_company_id);

			if (domain === undefined || consumer === undefined || seller === undefined) {
				window.alert('Дані не знайдено. Перевірте правільність внесення контрагентів, договорів та торгівельних зон.');
			}

			return ({
				key: item.plan_row_id,
				id: item.plan_row_id,
				domain: (domain !== undefined) ? domain.domain_display_name : '',
				start: getLocalDateString(item.trade_date),
				consumer: (consumer !== undefined) ? consumer.name : '',
				seller: (seller !== undefined) ? seller.name : '',
				amount: amount.toFixed(1),
			})
		});
	}

	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				{ isNaN(schedule_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(schedule_id) ? 'Додати графік' : 'Змінити графік' }
			</Typography>

			<form onSubmit={ onSubmitHandler } className = { classes.form_control } >

				<Typography component='h2' variant='h5' align='center' className= { classes.header }>
					Графік
				</Typography>

				<Grid container spacing={2}>

					<Grid item xs={6}>

						<FormControl
							variant='outlined'
							required
							className={classes.control_bottom_margin}
							fullWidth
						>
							<InputLabel ref={ domain_label } id='domain-label'>Зона балансування</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={ domain_label_width }
								onChange={ handleDomainChange }
								value={ local_state.domain_id }
								disabled={ local_state.status_id !== 1 }
							>
								{ display_domains }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={6} />

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value = { local_state.trade_date }
							error = { !local_state.date_valid }
							onChange = { handleTradeDateChange }
							InputLabelProps={{ shrink: true }}
							disabled={ local_state.status_id !== 1 }
						/>

					</Grid>

					<Grid item xs={4}>

						<FormControl
							variant='outlined'
							required
							fullWidth
							className={classes.control_bottom_margin}
						>
							<InputLabel ref={ sender_company_label } id='company-label'>Відправник</InputLabel>
							<Select
								native
								labelId='company-label'
								labelWidth={ sender_company_label_width }
								onChange={ handleCompanyChange }
								value={ local_state.sender_company_id }
								disabled={ local_state.status_id !== 1 }
							>
								{ display_companies }
							</Select>
						</FormControl>

						<FormControl
							variant='outlined'
							required
							fullWidth
							className={classes.control_bottom_margin}
						>
							<InputLabel ref={ sender_role_label } id='role-label'>Роль відправника</InputLabel>
							<Select
								native
								labelId='role-label'
								labelWidth={ sender_role_label_width }
								onChange={ handleRoleChange }
								value={ local_state.sender_role_id }
								disabled={ local_state.status_id !== 1 && local_state.status_id !== 3 }
							>
								{ display_roles }
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							label='Отримувач'
							variant='outlined'
							className={classes.control_bottom_margin}
							fullWidth
							value = { local_state.receiver_company_name }
							disabled
						/>

						<TextField
							label='Роль отримувача'
							variant='outlined'
							className={classes.control_bottom_margin}
							fullWidth
							value = { local_state.receiver_company_role_code + ': ' + local_state.receiver_company_role_comment }
							disabled
						/>

					</Grid> 

				</Grid>

				<div className = {classes.input_form_control}>

					<Typography component='h3' variant='h6' align='center'>
						Наявні часові ряди в графіку
					</Typography>

					<Grid container>

						<Grid item xs />

						<Grid item xs />

						<Grid item xs>

							<FormControl
								variant='outlined'
								required
								className={classes.control_top_margin}
								fullWidth
							>
								<InputLabel ref={ domain_label } id='status-label'>Фільтр статусу</InputLabel>
								<Select
									native
									labelId='status-label'
									labelWidth={ domain_label_width }
									onChange={ onFilterStatusChange }
									value={ filter.status_id }
								>
									{ display_statuses }
								</Select>
							</FormControl>

						</Grid>

						<Grid item xs />

						<Grid item xs />

						<Grid item xs />

					</Grid>

					{
						(time_series_rows.length !== 0) ?
						<TimeSeriesTable
							rows={time_series_rows}
							selected={selected_time_series}
							setSelected={setSelectedTimeSeries}
						/> : <p>Немає часових рядів з обраною зоною балансування та періодом.</p> 
					}

				</div>

				<div className = {classes.input_form_control}>

					<Typography component='h3' variant='h6' align='center'>
						Наявні ряди планування
					</Typography>

					{
						(plan_rows.length !== 0) ?
						<PlanRowsTable
							rows={plan_rows}
							selected={selected_plan_rows}
							setSelected={setSelectedPlanRows}
							action_list={[
								{ id: 2, name: 'Створити часовий ряд' }
							]}
							onActionSelect={handleRowAction}
						/> : <p>Немає рядів планування з обраною зоною балансування та періодом.</p>
					}

				</div>

				<div className={classes.button_form_control}>

					{ (selected_time_series.length > 0) &&
					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
						disabled={ local_state.status_id !== 1 && local_state.status_id !== 3 }
					>
						Зберегти
					</Button> }

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnCreateTimeSeriesHandler }
						disabled = { selected_plan_rows.length === 0 }
					>
						Створити часові ряди
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnImportExportHandler }
					>
						Імпорт / експорт
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>
	);
}