import { excel_date_to_date } from '../utils/excel_functions';


// ascending
export function compare_as_numbers (a, b) {
	if (a > b) return 1;
	if (b > a) return -1;
	return 0;
}


export function DamResultsFromJson (data) {

	if (data !== undefined) {

		let domains = [];
		let days = [];
		let hours = [];
		let trade_types = [];
	
		data.forEach((row, index) => {
	
			// skip header
			if (index > 0) {
				if (!domains.includes(row[6])) {
					domains.push(row[6]);
				}
				if (!days.includes(row[0])) {
					days.push(row[0]);
				}
				if (!hours.includes(row[1])) {
					hours.push(row[1]);
				}
				if (!trade_types.includes(row[5])) {
					trade_types.push(row[5]);
				}
			}
		})
	
		days.sort(compare_as_numbers);
		hours.sort(compare_as_numbers);
	
		const dates = [];
		days.forEach((value, index) => {
			dates[index] = {
				value: value,
				display: excel_date_to_date(value),
			}
		})
	
		return ({
			data: data,
			domains: domains,
			dates: dates,
			hours: hours,
			trade_types: trade_types,
		})
	}
}