import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import IdmHelpDialog from './subcomponents/IdmHelpDialog';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { globalContext } from '../layout/Context';
import { idmContext } from './idmContext';
import { is_valid_float_input } from '../utils/validation';
import { getISOStringFromDateString, getDateFormat, getDateTimeFormat, getHoursInDate } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		maxWidth: '100%',
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		alignItems: 'center',
		flexGrow: 1,
	},

	button_form_control: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(1),
	},

}));


export default function IdmOrderEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
		edit: 0,
		info: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);
	const [open_help_dialog, setOpenHelpDialog] = useState(false);

	// variables for the label control
	const sender_company_label = React.useRef(null);
	const domain_label = React.useRef(null);
	const trade_type_label = React.useRef(null);
	const block_order_label = React.useRef(null);
	const block_type_label = React.useRef(null);
	const indication_type_label = React.useRef(null);
	const period_label = React.useRef(null);

	const [sender_company_label_width, setSenderCompanyLabelWidth] = useState(0);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [trade_type_label_width, setTradeTypeLabelWidth] = useState(0);
	const [block_order_label_width, setBlockOrderLabelWidth] = useState(0);
	const [block_type_label_width, setBlockTypeLabelWidth] = useState(0);
	const [indication_type_label_width, setIndicationTypeLabelWidth] = useState(0);
	const [period_label_width, setPeriodLabelWidth] = useState(0);

	// import context
	const { user } = useContext(globalContext);
	const { global_state, setGlobalState } = useContext(idmContext);

	// get order id from url
	const order_id = parseInt(props.match.params.order_id);
	const update_url = (order_id) ? '/api/idm/' + order_id : '/api/idm/add';
	const close_url = (order_id) ? '/api/idm/' + order_id + '/close' : undefined;


	function createInitialState() {

		var today = new Date();
		today.setHours(0, 0, 0, 0);

		const complex_state = {
			revision: 1,
			revision_id: 0,
			status_id: 1,
			sender_company_id: 0,

			trade_date: getDateFormat(today),
			trade_date_valid: true,
			domain_id: 0,
			trade_type_id: 0,

			period_from: '',
			period_to: '',
			validity_date: '',
			validity_date_valid: true,

			block_order_id: 2,
			block_type_id: 0,
			indication_id: 1,

			price: 0,
			amount: 0,
			comment: '',

			receiver_company_code: '',
			receiver_company_name: '',
			period_from_valid: true,
			price_valid: true,
			amount_valid: true,

			changed: true,
		}

		return complex_state;
	}


	function handleCompanyChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			sender_company_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeDateChange(event) {

		const event_value = event.target.value;
		const compare_date = new Date();
		compare_date.setHours(0, 0, 0, 0);

		setLocalState(prev_state => ({ ...prev_state,
			trade_date: event_value,
			trade_date_valid: new Date(event_value) >= compare_date,
			validity_date_valid: get_validity_date_status(local_state.validity_date, event_value, local_state.period_from),
			changed: true,
		}));
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			domain_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleTradeTypeChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			trade_type_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handlePeriodFromChange(event) {

		const event_value = event.target.value;
		const value_int = parseInt(event_value);
		const value_valid = !isNaN(value_int) && value_int <= 24;

		setLocalState(prev_state => ({ ...prev_state,
			validity_date_valid: get_validity_date_status(local_state.validity_date, local_state.trade_date, event_value),
			period_from: event_value,
			period_to: value_valid ? value_int + 1 : '',
			period_from_valid: event_value === '' || value_valid,
			changed: true,
		}));
	}


	function handleValidityDateChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			validity_date: event_value,
			validity_date_valid: get_validity_date_status(event_value, local_state.trade_date, local_state.period_from),
			changed: true,
		}));
	}


	function handleBlockOrderChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			block_order_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleBlockTypeChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			block_type_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handleIndicationTypeChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			indication_id: parseInt(event_value),
			changed: true,
		}));
	}


	function handlePriceChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			price: event_value,
			price_valid: is_valid_float_input(event_value),
			changed: true,
		}));
	}


	function handleAmountChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			amount: event_value,
			amount_valid: is_valid_float_input(event_value),
			changed: true,
		}));
	}


	function handleCommentChange(event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			comment: event_value,
			changed: true,
		}));
	}


	async function saveHandler() {

		try {

			const new_data = {
				revision: local_state.revision,
				revision_id: local_state.revision_id,
				status_id: local_state.status_id,

				sender_company_id: local_state.sender_company_id,
				trade_date: getDateFormat(local_state.trade_date),
				domain_id: local_state.domain_id,
				trade_type_id: local_state.trade_type_id,

				period_from: (local_state.period_from !== '') ? parseInt(local_state.period_from) : null,
				period_to: (local_state.period_to !== '') ? parseInt(local_state.period_to) : null,
				validity_date: (local_state.validity_date !== '') ? getISOStringFromDateString(local_state.validity_date) : null,

				block_order_id: local_state.block_order_id,
				block_type_id: local_state.block_type_id,
				indication_id: local_state.indication_id,

				price: parseFloat(local_state.price),
				amount: parseFloat(local_state.amount),
				comment: local_state.comment,
			}

			if (new_data.period_from === null) {
				delete new_data.period_from;
			}
			if (new_data.period_to === null) {
				delete new_data.period_to;
			}
			if (new_data.validity_date === null) {
				delete new_data.validity_date;
			}

			const response = await axios.post(update_url, new_data);
			if (response.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch (error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		// check if input is valid
		let input_valid = true;

		if (!local_state.validity_date_valid || !local_state.trade_date_valid) {
			window.alert('Перевірте правильність вводу періодів.');
			input_valid = false;
		}

		const period_from = (local_state.period_from !== '') ? parseInt(local_state.period_from) : '';
		const period_to = (local_state.period_to !== '') ? parseInt(local_state.period_to) : '';

		if (!local_state.price_valid) {
			window.alert('Перевірте правильність вводу ціни.');
			input_valid = false;
		}

		if (!local_state.amount_valid) {
			window.alert('Перевірте правильність вводу кількості.');
			input_valid = false;
		}

		// for block order
		if (local_state.block_order_id === 1) {

			// if no type specified
			if (local_state.block_type_id === 0) {
				window.alert('Не вказаний тип блочної заявки.');
				input_valid = false;
			}

			// if order type is specified period shall be empty
			if (local_state.block_type_id !== 0) {

				if (period_from !== '' || period_to !== '') {
					window.alert('Періоди для блочної заявки не вказуються.');
					input_valid = false;
				}
			}
		}

		// for simple order
		if (local_state.block_order_id === 2) {

			// if type specified
			if (local_state.block_type_id !== 0) {
				window.alert('Вказаний тип простої заявки.');
				input_valid = false;
			}

			if (local_state.block_type_id === 0) {

				if (period_from === '' || period_to === '') {
					window.alert('Не вказані періоди.');
					input_valid = false;
				} else {

					if (period_from < 0 || period_from > 24) {
						window.alert('Не вірний період з.');
						input_valid = false;
					}
					if (period_to < 1 || period_to > 25) {
						window.alert('Не вірний період до.');
						input_valid = false;
					}

					if (input_valid) {
						if (period_to - period_from !== 1) {
							window.alert('Не вірні періоди.');
							input_valid = false;
						}
					}
				}
			}
		}

		if (parseFloat(local_state.amount) < 0.1) {
			window.alert('Перевірте правильність ціни.');
			input_valid = false;
		}

		if (parseFloat(local_state.price) < 10) {
			window.alert('Перевірте правильність кількості.');
			input_valid = false;
		}

		if (input_valid) {

			if (await saveHandler()) {
				// return to parent page
				props.history.push('/idm');
			}
		}
	}


	function handleCancel() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.go(-1);
	}


	function handleImportExport() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.push('/idm/import/' + local_state.trade_date);
	}


	function handleHelpDialogClose() {
		setOpenHelpDialog(false);
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	function get_validity_date_status(date_value, trade_date, period_from) {

		const compare_date = new Date(trade_date);
		compare_date.setHours(period_from - 1, 0, 0, 0);
		const this_date = new Date(date_value);

		return (date_value === '') ? true : ((new Date() < this_date) && (this_date <= compare_date));
	}


	useEffect(() => {

		setSenderCompanyLabelWidth(sender_company_label.current.offsetWidth);
		setDomainLabelWidth(domain_label.current.offsetWidth);
		setTradeTypeLabelWidth(trade_type_label.current.offsetWidth);
		setBlockOrderLabelWidth(block_order_label.current.offsetWidth);
		setBlockTypeLabelWidth(block_type_label.current.offsetWidth);
		setIndicationTypeLabelWidth(indication_type_label.current.offsetWidth);
		setPeriodLabelWidth(period_label.current.offsetWidth);

	}, [])


	useEffect(() => {

		axios.get('/api/idm/receiver')
			.then(response => {

				setLocalState(prev_state => ({
					...prev_state,
					receiver_company_code: response.data.eic_x_code,
					receiver_company_name: response.data.name,
				}));

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});

	}, [])


	useEffect(() => {

		const sender_company = global_state.companies.find(company => company.company_id === user.company_id);

		if (sender_company !== undefined) {
			setLocalState(prev_state => ({ ...prev_state,
				sender_company_id: sender_company.company_id,
			}));
		}

	}, [user, global_state])


	useEffect(() => {

		if (order_id) {
			axios.get(update_url)
				.then(response => {

					if (response.data !== null) {

						const { revision, revision_id, status_id, sender_company_id, trade_date, domain_id,
							trade_type_id, period_from, period_to, validity_date, block_order_id, block_type_id,
							indication_id, price, amount, comment } = response.data;

						setLocalState(prev_state => ({ ...prev_state,
							revision: parseInt(revision),
							revision_id: parseInt(revision_id),
							status_id: parseInt(status_id),
							sender_company_id: parseInt(sender_company_id),
							trade_date: getDateFormat(trade_date),
							domain_id: parseInt(domain_id),
							trade_type_id: trade_type_id,
							period_from: period_from,
							period_to: period_to,
							validity_date: getDateTimeFormat(validity_date),
							block_order_id: block_order_id,
							block_type_id: block_type_id,
							indication_id: indication_id,
							price: price,
							amount: amount,
							comment: (comment !== null) ? comment : '',
							changed: false,
						}));
					}
				})
				.catch(error => {

					if (error.response) {
						// The request was made and the server responded with a status code out of the range of 2xx
						let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;
						let edit = (error.response.data.user_busy !== undefined) ? error.response.data.user_busy : 0;

						setMessage(message => ({ ...message,
							show: true,
							message: err_message,
							edit: edit,
						}));
					}

					console.log(error);
				});
		}
	}, [order_id, update_url])


	useEffect(() => {

		if (message.edit !== 0) {
			axios.get('/api/admin/user/' + message.edit + '/find')
				.then(response => {

					if (response.data !== null) {

						const { last_name, first_name, email, phone } = response.data;
						setMessage(message => ({ ...message,
							show: true,
							info: `${first_name} ${last_name} \n ${email} \n ${phone}`,
						}));
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [message.edit])


	// component will unmount hook
	useEffect(() => {

		return () => {
			if (close_url) {
				axios.post(close_url);
			}
		}
	}, [close_url])


	// filling select control for domains
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		})
		display_domains.unshift(<option key={-1} value={''} ></option>)
	}

	// filling select control for companies
	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies
			.filter(company => company.company_id === user.company_id)
			.map(company => {
				return (
					<option key={company.company_id} value={company.company_id} >{company.name}</option>
				)
			})
		display_companies.unshift(<option key={-1} value={''} ></option>)
	}

	// getting lists for row variables
	var display_block_orders = [];
	if (global_state.block_orders.length > 0) {
		display_block_orders = global_state.block_orders.map(item => {
			return (
				<option key={item.order_id} value={item.order_id} >{item.order_comment}</option>
			)
		})
		display_block_orders.unshift(<option key={-1} value={''} ></option>)
	}

	var display_block_types = [];
	if (global_state.block_types.length > 0) {
		display_block_types = global_state.block_types.map(item => {
			return (
				<option key={item.type_id} value={item.type_id} >{item.type_comment}</option>
			)
		})
	}

	var display_trade_types = [];
	if (global_state.trade_types.length > 0) {
		display_trade_types = global_state.trade_types.map(item => {
			return (
				<option key={item.type_id} value={item.type_id} >{item.type_comment}</option>
			)
		})
		display_trade_types.unshift(<option key={-1} value={''} ></option>)
	}

	var display_indication_types = [];
	if (global_state.indication_types.length > 0) {
		display_indication_types = global_state.indication_types.map(item => {
			return (
				<option key={item.type_id} value={item.type_id} >{item.type_comment}</option>
			)
		})
		display_indication_types.unshift(<option key={-1} value={''} ></option>)
	}


	var display_hours = [];
	const max_hours = getHoursInDate(local_state.trade_date);
	for (let i = 0; i < max_hours; i++) {
		display_hours.push(
			<option key={i} value={i} >{i}</option>
		)
	}
	display_hours.unshift(<option key={-1} value={''} ></option>)


	return (

		<Container className={classes.root_form} component='main'>

			<Avatar className={classes.avatar}>
				{(order_id) ? <EditIcon /> : <AddIcon />}
			</Avatar>

			<Typography component='h2' variant='h4' align='center'>
				{(order_id) ? 'Редагувати заявку ВДР' : 'Додати заявку ВДР'}
			</Typography>

			<form onSubmit={onSubmitHandler} className={classes.form_control} >

				<Grid container spacing={3}>

					<Grid item xs={4}>

						<FormControl
							variant='outlined'
							required
							fullWidth
							className={classes.control_bottom_margin}
						>
							<InputLabel ref={sender_company_label} id='company-label'>Відправник</InputLabel>
							<Select
								native
								labelId='company-label'
								labelWidth={sender_company_label_width}
								onChange={handleCompanyChange}
								value={local_state.sender_company_id}
								disabled={local_state.status_id !== 1}
							>
								{display_companies}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							label='Отримувач'
							variant='outlined'
							className={classes.control_bottom_margin}
							fullWidth
							value={local_state.receiver_company_name}
							disabled
						/>

					</Grid>

					<Grid item xs={4} />

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба торгівлі'
							type='date'
							value={local_state.trade_date}
							error={!local_state.trade_date_valid}
							disabled={local_state.status_id !== 1}
							onChange={handleTradeDateChange}
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={domain_label} id='domain-label'>Торгова зона</InputLabel>
							<Select
								native
								labelId='domain-label'
								labelWidth={domain_label_width}
								onChange={handleDomainChange}
								value={local_state.domain_id}
								disabled={local_state.status_id !== 1}
							>
								{display_domains}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={trade_type_label} id='trade-type-label'>Напрямок торгівлі</InputLabel>
							<Select
								native
								labelId='trade-type-label'
								labelWidth={trade_type_label_width}
								onChange={handleTradeTypeChange}
								value={local_state.trade_type_id}
								disabled={local_state.status_id !== 1}
							>
								{display_trade_types}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						{/* <TextField
							label='Період з'
							variant='outlined'
							className={classes.control_bottom_margin}
							fullWidth
							error={!local_state.period_from_valid}
							value={local_state.period_from}
							onChange={handlePeriodFromChange}
							disabled={local_state.status_id !== 1}
						/> */}

						<FormControl variant='outlined' className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={period_label} id='period-from'>Період з</InputLabel>
							<Select
								native
								labelId='period-from'
								labelWidth={period_label_width}
								value={local_state.period_from}
								onChange={handlePeriodFromChange}
								disabled={local_state.status_id !== 1}
							>
								{display_hours}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							label='Період до'
							variant='outlined'
							className={classes.control_bottom_margin}
							fullWidth
							value={local_state.period_to}
							disabled
						/>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							fullWidth
							label='Дійсний до'
							type='datetime-local'
							value={local_state.validity_date}
							error={!local_state.validity_date_valid}
							onChange={handleValidityDateChange}
							disabled={local_state.status_id !== 1}
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={block_order_label} id='block-order-label'>Блочне замовлення</InputLabel>
							<Select
								native
								labelId='block-order-label'
								labelWidth={block_order_label_width}
								onChange={handleBlockOrderChange}
								value={local_state.block_order_id}
								disabled
							>
								{display_block_orders}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={block_type_label} id='block-type-label'>Тип блоку</InputLabel>
							<Select
								native
								labelId='block-type-label'
								labelWidth={block_type_label_width}
								value={local_state.block_type_id}
								onChange={handleBlockTypeChange}
								disabled
							>
								{display_block_types}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<FormControl variant='outlined' required className={classes.control_bottom_margin} fullWidth>
							<InputLabel ref={indication_type_label} id='indication-type-label'>Індікація</InputLabel>
							<Select
								native
								labelId='indication-type-label'
								labelWidth={indication_type_label_width}
								value={local_state.indication_id}
								onChange={handleIndicationTypeChange}
								disabled={local_state.status_id !== 1}
							>
								{display_indication_types}
							</Select>
						</FormControl>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Кількість'
							value={local_state.amount}
							onChange={handleAmountChange}
							disabled={local_state.status_id !== 1}
							error={!local_state.amount_valid}
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Ціна'
							value={local_state.price}
							onChange={handlePriceChange}
							disabled={local_state.status_id !== 1}
							error={!local_state.price_valid}
							InputLabelProps={{ shrink: true }}
						/>

					</Grid>

					<Grid item xs={4} />

					<Grid item xs={12}>

						<TextField
							variant='outlined'
							fullWidth
							label='Коментар'
							value={local_state.comment}
							onChange={handleCommentChange}
							disabled={local_state.status_id !== 1}
						/>

					</Grid>

				</Grid>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={classes.button_control}
						disabled={local_state.status_id !== 1}
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='primary'
						className={classes.button_control}
						onClick={handleImportExport}
					>
						Імпорт / Експорт
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={classes.button_control}
						onClick={handleCancel}
					>
						Скасувати
					</Button>

				</div>

			</form>

			<IdmHelpDialog open={ open_help_dialog } onClose={ handleHelpDialogClose } />

			<MessageErrorSnackbar
				open={message.show}
				message={message.message}
				info={message.info}
				onClose={onErrorMessageClose}
			/>

		</Container>
	)
}