import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccauntIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';

import intengin_logo from '../../public/img/intengin_logo.png';
import user_logo from '../../public/img/user_logo.png'
import { globalContext } from './Context';


const useStyles = makeStyles(theme => ({

	container: {
		padding: theme.spacing(0),
	},

	toolbar_logo_1: {
		margin: theme.spacing(0, 1),
		fontWeight: 'bold',
		color: '#A12830',
	},

	toolbar_center: {
		margin: 'auto',
		transform: 'translate(-45%, 0)',
	},

	toolbar_right: {
		position: 'absolute',
		right: '5px',
	},

	toolbar_logo_2: {
		maxWidth: 193,
		margin: theme.spacing(0, 1, 0, 2),
		verticalAlign: 'middle'
	},

	cabinet_panel: {
		padding: theme.spacing(4),
		textAlign: 'center',
		maxWidth: 300,
	},

	cabinet_logo: {
		marginTop: theme.spacing(2),
	},

	cabinet_content: {
		margin: theme.spacing(1),
	},

	button_holder: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(1),
	},

}));


function Layout(props) {

	const classes = useStyles();
	const history = useHistory();

	const [ menu_open, setMenuOpen ] = useState(false);
	const [ menu_anchor_elem, set_menu_anchor_elem ] = useState(null);

	const [ cabinet_open, setCabinetOpen ] = useState(false);
	const [ cabinet_anchor_elem, set_cabinet_anchor_elem ] = useState(null);

	const { user, is_user_authorised } = useContext(globalContext);


	const toggle_menu = (open) => event => {

		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		if (open) {
			set_menu_anchor_elem(event.currentTarget);
		}

		setMenuOpen(open);
	}


	const toggle_cabinet = (open) => event => {

		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		if (open) {
			set_cabinet_anchor_elem(event.currentTarget)
		}

		setCabinetOpen(open);
	}


	const follow_link = (link) => {
		history.push(link);
		setMenuOpen(false);
	}


	const go_to_message_archive = () => {
		history.push('/help');
	}


	return (
		<Container maxWidth={false} className={classes.container}>

			<AppBar position='static' color='default' >
				<Toolbar>

					<IconButton
						color='default'
						aria-controls='simple-menu'
						onClick={ toggle_menu(true) }
					>
						<MenuIcon />
					</IconButton>

					<Typography
						component='h1'
						variant='h5'
						className={ classes.toolbar_logo_1 }
					>
						PowerTrade
					</Typography>

					{
						(user.company_name !== '') &&
						<div className = { classes.toolbar_center }>

							<Typography component='h1' variant='h6'>
								Кабінет компанії { user.company_name }
							</Typography>

						</div>
					}

					<div className={classes.toolbar_right}>

						<IconButton
							color='primary'
							onClick={ toggle_cabinet(true) }
							aria-describedby={'cabinet-popover'}
						>
							<AccauntIcon />
						</IconButton>

						<IconButton
							color='primary'
							onClick={ () => go_to_message_archive() }
						>
							<Badge badgeContent={user.events_count} color='secondary'>
								<NotificationsIcon />
							</Badge>
						</IconButton>

						<img
							className={classes.toolbar_logo_2}
							src={ intengin_logo }
							alt='intengin_logo'
						/>

					</div>

				</Toolbar>
			</AppBar>

			<Menu
				id='simple-menu'
				anchorEl={menu_anchor_elem}
				open={ menu_open }
				onClose={ toggle_menu(false) }
			>
				<MenuItem onClick={() => follow_link('/')}>Головна</MenuItem>

				{ is_user_authorised(user.authorisation, 1) &&
				<MenuItem onClick={() => follow_link('/admin')}>Адміністрування</MenuItem> }

				{ is_user_authorised(user.authorisation, 6) &&
				<MenuItem onClick={() => follow_link('/contracts')}>Договори</MenuItem> }

				{ is_user_authorised(user.authorisation, 25) &&
				<MenuItem onClick={() => follow_link('/capacities')}>Пропускна спроможність</MenuItem> }

				{ is_user_authorised(user.authorisation, 27) &&
				<MenuItem onClick={() => follow_link('/external_series')}>Імпорт / експорт</MenuItem> }

				{ (is_user_authorised(user.authorisation, 20) || is_user_authorised(user.authorisation, 22)) &&
				<MenuItem onClick={() => follow_link('/planning')}>Планування</MenuItem> }

				{ is_user_authorised(user.authorisation, 8) &&
				<MenuItem onClick={() => follow_link('/time_series')}>Часові ряди</MenuItem> }

				{ is_user_authorised(user.authorisation, 11) &&
				<MenuItem onClick={() => follow_link('/schedules')}>Часові графіки</MenuItem> }

				{ is_user_authorised(user.authorisation, 14) &&
				<MenuItem onClick={() => follow_link('/dam')}>РДН</MenuItem> }

				{ is_user_authorised(user.authorisation, 24) &&
				<MenuItem onClick={() => follow_link('/dam_prices')}>Ціни РДН</MenuItem> }

				<MenuItem onClick={() => follow_link('/dam_results')}>Результати РДН</MenuItem>

				{ is_user_authorised(user.authorisation, 17) &&
				<MenuItem onClick={() => follow_link('/idm')}>ВДР</MenuItem> }

				{ user.is_logged_in &&
				<MenuItem onClick={() => follow_link('/idm_results')}>Результати ВДР</MenuItem> }

				{ user.is_logged_in &&
				<MenuItem onClick={() => follow_link('/operational_report')}>Оперативний звіт</MenuItem> }

				<MenuItem onClick={() => follow_link('/blocks')}>Стан блоків</MenuItem>

				{ user.is_logged_in &&
				<MenuItem onClick={() => follow_link('/help')}>Підтримка</MenuItem> }

			</Menu>

			<Popover
				id={'cabinet-popover'}
				open={cabinet_open}
				anchorEl={cabinet_anchor_elem}
				onClose={toggle_cabinet(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>

				<div
					className = { classes.cabinet_panel }
					onClick = { toggle_menu(false) }
					onKeyDown = { toggle_menu(false) }
				>
					<img src={ user_logo } alt='user_logo' className = { classes.cabinet_logo }/>

					<Typography variant='h6' className = { classes.cabinet_content }>
						{ user.first_name } { user.last_name }
					</Typography>

					<Typography variant='h6' className = { classes.cabinet_content }>
						{ user.company_name }
					</Typography>

					<Typography variant='h6' className = { classes.cabinet_content }>
						{ user.phone }
					</Typography>

					<Typography variant='h6' className = { classes.cabinet_content }>
						{ user.email }
					</Typography>

					<div className = { classes.button_holder }>

						<Button
							className={classes.button}
							size='small'
							variant='contained'
							color='primary'
							onClick={ () => history.push('/Auth') }
						>
							Кабінет користувача
						</Button>

					</div>
				</div>

			</Popover>

			<div>
				<main>
					{ props.children }
				</main>
			</div>

		</Container>
 	);
}

export default Layout;