import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import XLSX from 'xlsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ExitToAppSharp';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import ComponentPaymentsEdit from './ComponentPaymentsEdit';
import SummaryReport1 from './subcomponents/SummaryReport1';
import SummaryReport2 from './subcomponents/SummaryReport2';
import SummaryReport3 from './subcomponents/SummaryReport3';
import SummaryReport4 from './subcomponents/SummaryReport4';

import FilterPeriod from '../subcomponents/FilterPeriod';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';
import WaitDialog from '../subcomponents/WaitDialog';

import { globalContext } from '../layout/Context';
import { operReportContext } from './operReportContext';
import { getDateFormat, getLocalDateString, getLocalDateTimeString, getDaysInMonth, getHoursInDate, getMonthFormat } from '../utils/date_functions';
import { is_valid_date } from '../utils/validation';


const useStyles = makeStyles(theme => ({

	root: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},

	title: {
		display: 'none',
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},

	tab: {
		marginTop: theme.spacing(1),
	},

	right_button: {
		position: 'absolute',
		right: theme.spacing(1),
	},

	control_margin: {
		margin: theme.spacing(2, 0, 0, 2),
	},

	form_tab_1: {
		marginTop: theme.spacing(2),
		maxWidth: '40%',
		textAlign: 'center',
	},

	form_tab_2: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},

	form_small_area: {
		maxHeight: '200px',
	},

	table_reports: {
		overflow: 'auto',
		height: '200px',
		overflowY: 'scroll',
	},

	table_reports_cells: {
		borderBottom: '1px solid gray',
		padding: theme.spacing(1),
	},

	margin_title: {
		marginTop: theme.spacing(2),
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block',
	},

}));


function OperationalReportForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [global_state, setGlobalState] = useState(initial_state);

	const domain_label = React.useRef(null);
	const contract_label = React.useRef(null);
	const group_label = React.useRef(null);
	const [domain_label_width, setDomainLabelWidth] = useState(0);
	const [contract_label_width, setContractLabelWidth] = useState(0);
	const [group_label_width, setGroupLabelWidth] = useState(0);

	// state for tabs
	const [tab_no, setTabNo] = useState(0);

	const [wait, setWait] = useState(false);

	// import context
	const { user } = useContext(globalContext);


	function getTabsProps(index) {
		return {
			id: `operational-report-tab-${index}`,
			'aria-controls': `operational-report-tabpanel-${index}`,
		};
	}


	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				hidden={value !== index}
				id={`operational-report-tabpanel-${index}`}
				aria-labelledby={`operational-report-tab-${index}`}
				{...other}
			>
				{value === index && <div>{children}</div>}
			</div>
		);
	}


	function OnTabChage(e, tab) {
		setTabNo(tab);
	}

	// Create number formatters
	const curFormat = new Intl.NumberFormat('ua', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
	const priFormat = new Intl.NumberFormat('ua', {
		minimumFractionDigits: 6,
		maximumFractionDigits: 6
	});
	const valFormat = new Intl.NumberFormat('ua', {
		minimumFractionDigits: 1,
		maximumFractionDigits: 1
	});


	function createInitialState() {

		// set start date to begin of the month
		const day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);
		day_1.setDate(1);

		const day_2 = new Date(day_1);
		day_2.setMonth(day_1.getMonth() + 1, 0);

		const complex_state = {

			update_flag: false,

			companies: [],
			contracts: [],
			domains: [],
			out_domains: [],
			groups: [],

			period_start: getDateFormat(day_1),
			period_end: getDateFormat(day_2),

			consumer_company: '',
			consumer_id: 0,
			seller_company: '',
			seller_id: 0,
			contract_id: 0,
			domain_id: 0,
			group_id: 0,

			report_1: [],
			report_2: {},
			report_3: {},
			report_4: {},

			saved_reports: []
		}

		return complex_state;
	}


	function handleConsumerChange(event, option) {

		const company = global_state.companies.find(item => item.name === option);

		setGlobalState(prev_state => ({ ...prev_state,
			consumer_company: option,
			consumer_id: (company !== undefined) ? company.company_id : 0
		}));
	}


	function handleSellerChange(event, option) {

		const company = global_state.companies.find(item => item.name === option);

		setGlobalState(prev_state => ({ ...prev_state,
			seller_company: option,
			seller_id: (company !== undefined) ? company.company_id : 0
		}));
	}


	function handleContractChange(event) {

		const event_value = event.target.value;
		const parsed_id = parseInt(event_value);

		setGlobalState(prev_state => ({ ...prev_state,
			contract_id: isNaN(parsed_id) ? 0 : parsed_id,
		}));
	}


	function handleDomainChange(event) {

		const event_value = event.target.value;
		const parsed_id = parseInt(event_value);

		setGlobalState(prev_state => ({ ...prev_state,
			domain_id: isNaN(parsed_id) ? 0 : parsed_id,
		}));
	}


	function handleGroupChange(event) {

		const event_value = event.target.value;
		const parsed_id = parseInt(event_value);

		setGlobalState(prev_state => ({ ...prev_state,
			group_id: isNaN(parsed_id) ? 0 : parsed_id,
		}));
	}


	// report tab 1

	async function ContractorReport1Calculation(period_start, period_end, consumer_id, seller_id, contract_id, max_hours) {

		const period_start_date = new Date(period_start);
		const period_end_date = new Date(period_end);

		const result = [];

		// get plan rows for domain 1
		const resp_plan_rows_1 = await axios.get('/api/planning/plans_finance', { params: {
			domain:			1,
			consumer_company_id: consumer_id,
			seller_company_id: seller_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const plan_rows_1 = resp_plan_rows_1.data.plan_row_list;

		if (plan_rows_1.length === 0) return result;

		// get dam prices for domain 1
		const resp_prices_1 = await axios.get('/api/dam_prices', { params: {
			domain:			1,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const dam_prices_1 = resp_prices_1.data.dam_prices;

		if (dam_prices_1.length === 0) return result;

		// get plan rows for domain 2
		const resp_plan_rows_2 = await axios.get('/api/planning/plans_finance', { params: {
			domain:			2,
			consumer_company_id: consumer_id,
			seller_company_id: seller_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const plan_rows_2 = resp_plan_rows_2.data.plan_row_list;

		if (plan_rows_2.length === 0) return result;

		// get dam prices for domain 2
		const resp_prices_2 = await axios.get('/api/dam_prices', { params: {
			domain:			2,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const dam_prices_2 = resp_prices_2.data.dam_prices;

		if (dam_prices_2.length === 0) return result;

		// calculate for each row for domain 1
		for (let r = 0; r < plan_rows_1.length; r++) {

			const row_date = new Date(plan_rows_1[r].trade_date);

			// get dam prices for the current date
			const dam_price = dam_prices_1.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			if (dam_price === undefined) continue;

			// find existing result by its date
			const result_object = result.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			// calculate for all hours of the current row
			let res_volume = 0;
			let res_amount = 0;
			for (let h = 1; h <= max_hours; h++) {

				const index = (h < 10) ? `p0${h}` : `p${h}`;

				let dam_hour_price = 0;
				let volume = 0;
				let volume_price = 0;

				if (plan_rows_1[r][index] !== null && plan_rows_1[r][index] !== undefined) {

					dam_hour_price = dam_price[index];
					volume = parseFloat(plan_rows_1[r][index]);
					volume_price = valid_value(plan_rows_1[r].price) ? plan_rows_1[r].price :
									valid_value(plan_rows_1[r].discount) ? dam_hour_price + plan_rows_1[r].discount :
									valid_value(plan_rows_1[r].discount_percent) ? dam_hour_price * (1 + plan_rows_1[r].discount_percent / 100) : 0;
				}

				res_volume += volume;
				res_amount += volume * volume_price;
			}

			// if nothing exists, add
			if (result_object === undefined) {
				result.push({
					trade_date: row_date,
					consumer_id: consumer_id,
					seller_id: seller_id,
					volume: res_volume,
					amount: res_amount,
					payments: []
				})
			} else {
				result_object.volume += res_volume;
				result_object.amount += res_amount;
			}
		}

		// calculate for each row for domain 2
		for (let r = 0; r < plan_rows_2.length; r++) {

			const row_date = new Date(plan_rows_2[r].trade_date);

			// get dam prices for the current date
			const dam_price = dam_prices_2.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			if (dam_price === undefined) continue;

			// find existing result by its date
			const result_object = result.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			// calculate for all hours of the current row
			let res_volume = 0;
			let res_amount = 0;
			for (let h = 1; h <= max_hours; h++) {

				const index = (h < 10) ? `p0${h}` : `p${h}`;

				let dam_hour_price = 0;
				let volume = 0;
				let volume_price = 0;

				if (plan_rows_2[r][index] !== null && plan_rows_2[r][index] !== undefined) {

					dam_hour_price = dam_price[index];
					volume = parseFloat(plan_rows_2[r][index]);
					volume_price = valid_value(plan_rows_2[r].price) ? plan_rows_2[r].price :
									valid_value(plan_rows_2[r].discount) ? dam_hour_price + plan_rows_2[r].discount :
									valid_value(plan_rows_2[r].discount_percent) ? dam_hour_price * (1 + plan_rows_2[r].discount_percent / 100) : 0;
				}

				res_volume += volume;
				res_amount += volume * volume_price;
			}

			// if nothing exists, add
			if (result_object === undefined) {
				result.push({
					trade_date: row_date,
					domain_id: domain_id,
					consumer_id: consumer_id,
					seller_id: seller_id,
					volume: res_volume,
					amount: res_amount,
					payments: []
				})
			} else {
				result_object.volume += res_volume;
				result_object.amount += res_amount;
			}
		}

		// get payments for contract
		const resp_payments = await axios.get('/api/admin/contracts/payments', { params: {
			contract_id: contract_id,
		}});

		const all_payments = resp_payments.data.payments;
		const contract_payments = all_payments.filter(item => {
			const item_date = new Date(item.payment_date);
			return (item_date.getFullYear() >= period_start_date.getFullYear() && 
					item_date.getMonth() >= period_start_date.getMonth() &&
					item_date.getDate() >= period_start_date.getDate() &&
					item_date.getFullYear() <= period_end_date.getFullYear() &&
					item_date.getMonth() <= period_end_date.getMonth() &&
					item_date.getDate() <= period_end_date.getDate())
		});

		// integrate payments into results
		for (let p = 0; p < contract_payments.length; p++) {

			const the_date = new Date(contract_payments[p].payment_date);
			const result_object = result.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === the_date.getFullYear() && 
						item_date.getMonth() === the_date.getMonth() &&
						item_date.getDate() === the_date.getDate())
			});

			if (result_object !== undefined) {
				result_object.payments.push(contract_payments[p]);
			}
		}

		return result;
	}


	async function handleCalculateReport1() {

		setWait(true);

		// calculate hour count 
		const hours_count = hour_count(global_state.period_start, global_state.period_end);

		try {

			// define contractors and report owner
			const summary_rows = await ContractorReport1Calculation(global_state.period_start, global_state.period_end, global_state.consumer_id, global_state.seller_id, global_state.contract_id, hours_count);

			const display_rows = [];
			display_rows[0] = [ 'День', 'Сумма, грн з ПДВ', 'Сплати, грн з ПДВ', 'Сальдо, грн' ];

			// calculate summary
			let saldo = 0;
			for (let i = 0; i < summary_rows.length; i++) {

				let amount = summary_rows[i].amount * 1.2;
				let payment = 0;

				// get sum payment for calculation
				for (let p = 0; p < summary_rows[i].payments.length; p++) {
					payment += summary_rows[i].payments[p].payment;
				}

				let current_saldo = saldo - amount + payment;

				// get display payment without rest of the month
				const display_payment = summary_rows[i].payments.find(item => item.month_ballance === null);

				display_rows.push([
					getLocalDateString(summary_rows[i].trade_date),
					amount,
					(display_payment !== undefined) ? display_payment.payment : 0,
					current_saldo
				]);

				saldo = current_saldo;
			}

			setGlobalState(prev_state => ({ ...prev_state,
				report_1: display_rows,
			}));

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		setWait(false);
	}


	// report tab 2

	async function ContractorReport2Calculation(period_start, period_end, consumer_id, seller_id, domain_id, group_id, max_hours) {

		const result = [];

		const resp_plan_rows = await axios.get('/api/planning/plans_finance', { params: {
			domain:			domain_id,
			consumer_company_id: consumer_id,
			seller_company_id: seller_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const plan_rows = resp_plan_rows.data.plan_row_list;

		if (plan_rows.length === 0) return result;

		const resp_prices = await axios.get('/api/dam_prices', { params: {
			domain:			domain_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const dam_prices = resp_prices.data.dam_prices;

		if (dam_prices.length === 0) return result;

		// calculate for each row
		for (let r = 0; r < plan_rows.length; r++) {

			// skip rows if group_id does not match
			if (group_id > 0 && plan_rows[r].group_id !== group_id) continue;

			const row_date = new Date(plan_rows[r].trade_date);

			// find existing result by its date
			const result_object = result.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			// get dam prices for the current date
			const dam_price = dam_prices.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			if (dam_price === undefined) continue;

			// calculate for all hours of the current row
			const res_volumes = [];
			const res_amounts = [];
			const res_references = [];
			for (let h = 1; h <= max_hours; h++) {

				const index = (h < 10) ? `p0${h}` : `p${h}`;

				let dam_hour_price = 0;
				let volume = 0;
				let volume_price = 0;

				if (plan_rows[r][index] !== null && plan_rows[r][index] !== undefined) {

					dam_hour_price = dam_price[index];
					volume = parseFloat(plan_rows[r][index]);
					volume_price = valid_value(plan_rows[r].price) ? plan_rows[r].price :
									valid_value(plan_rows[r].discount) ? dam_hour_price + plan_rows[r].discount :
									valid_value(plan_rows[r].discount_percent) ? dam_hour_price * (1 + plan_rows[r].discount_percent / 100) : 0;
				}

				res_volumes[h] = volume;
				res_amounts[h] = volume * volume_price;
				res_references[h] = volume * dam_hour_price;
			}

			// if nothing exists, add
			if (result_object === undefined) {
				result.push({
					trade_date: row_date,
					domain_id: domain_id,
					consumer_id: consumer_id,
					seller_id: seller_id,
					volumes: res_volumes,
					amounts: res_amounts,
					references: res_references,
					payments: []
				})
			} else {
				for (let h = 1; h <= max_hours; h++) {
					result_object.volumes[h] += res_volumes[h];
					result_object.amounts[h] += res_amounts[h];
					result_object.references[h] += res_references[h];
				}
			}
		}

		// calculate sums
		for (let r = 0; r < result.length; r++) {

			let sum_amount = 0;
			let sum_references = 0;
			let sum_volume = 0;

			for (let h = 1; h <= max_hours; h++) {
				sum_amount += result[r].amounts[h];
				sum_references += result[r].references[h];
				sum_volume += result[r].volumes[h];
			}

			result[r].amounts.push(sum_amount);
			result[r].references.push(sum_references);
			result[r].volumes.push(sum_volume);
		}

		return result;
	}


	async function handleCalculateReport2() {

		setWait(true);

		// calculate hour count 
		const hours_count = hour_count(global_state.period_start, global_state.period_end);

		try {

			// define contractors and report owner
			const summary = await ContractorReport2Calculation(global_state.period_start, global_state.period_end, global_state.consumer_id, global_state.seller_id, global_state.domain_id, global_state.group_id, hours_count);

			const display_volume_rows = [];
			display_volume_rows[0] = [ 'День' ];

			for (let i = 1; i <= hours_count; i++) {
				display_volume_rows[0][i] = i;
			}

			display_volume_rows[0].push('Всього');

			const display_amount_rows = [];
			display_amount_rows[0] = display_volume_rows[0];

			const display_reference_rows = [];
			display_reference_rows[0] = display_volume_rows[0];

			const display_margin_rows = [];
			display_margin_rows[0] = display_volume_rows[0];

			const summary_volume_row = [ 'Всього' ];
			const summary_amount_row = [ 'Всього' ];
			const summary_reference_row = [ 'Всього' ];

			for (let i = 0; i < summary.length; i++) {

				const next_volume_row = [ getLocalDateString(summary[i].trade_date) ];
				const next_amount_row = [ getLocalDateString(summary[i].trade_date) ];
				const next_reference_row = [ getLocalDateString(summary[i].trade_date) ];

				for (let j = 1; j < summary[i].volumes.length; j++) {

					next_volume_row[j] = summary[i].volumes[j];
					next_amount_row[j] = summary[i].amounts[j];
					next_reference_row[j] = summary[i].references[j];

					if (summary_volume_row[j] === undefined) {
						summary_volume_row[j] = 0;
					}
					if (summary_amount_row[j] === undefined) {
						summary_amount_row[j] = 0;
					}
					if (summary_reference_row[j] === undefined) {
						summary_reference_row[j] = 0;
					}

					summary_volume_row[j] += summary[i].volumes[j];
					summary_amount_row[j] += summary[i].amounts[j];
					summary_reference_row[j] += summary[i].references[j];
				}

				display_volume_rows.push(next_volume_row);
				display_amount_rows.push(next_amount_row);
				display_reference_rows.push(next_reference_row);
			}

			display_volume_rows.push(summary_volume_row);
			display_amount_rows.push(summary_amount_row);
			display_reference_rows.push(summary_reference_row);

			const report = {
				column_count: hours_count,
				volumes: display_volume_rows,
				amounts: display_amount_rows,
				references: display_reference_rows,
			}

			setGlobalState(prev_state => ({ ...prev_state,
				report_2: report,
			}));

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		setWait(false);
	}


	// report tab 3

	async function ContractorReport3Calculation(period_start, period_end, consumer_id, seller_id, domain_id, contract_id, is_buying, max_hours) {

		const result = {
			groups: []
		};

		const period_start_date = new Date(period_start);
		const period_end_date = new Date(period_end);

		const resp_plan_rows = await axios.get('/api/planning/plans_finance', { params: {
			domain:			domain_id,
			consumer_company_id: consumer_id,
			seller_company_id: seller_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const plan_rows = resp_plan_rows.data.plan_row_list;

		if (plan_rows.length === 0) return result;

		const resp_prices = await axios.get('/api/dam_prices', { params: {
			domain:			domain_id,
			period_start:	getDateFormat(period_start),
			period_end:		getDateFormat(period_end)
		}});

		const dam_prices = resp_prices.data.dam_prices;

		if (dam_prices.length === 0) return result;

		// calculate for each row
		for (let r = 0; r < plan_rows.length; r++) {

			// find group by its id
			const the_group = result.groups.find(item => item.group_id === plan_rows[r].group_id);

			const row_date = new Date(plan_rows[r].trade_date);

			// get dam prices for the current date
			const dam_price = dam_prices.find(item => {
				const item_date = new Date(item.trade_date);
				return (item_date.getFullYear() === row_date.getFullYear() && 
						item_date.getMonth() === row_date.getMonth() &&
						item_date.getDate() === row_date.getDate())
			});

			if (dam_price === undefined) continue;

			// calculate for all hours
			let row_volume = 0;
			let row_amount = 0;
			let row_margin = 0;

			for (let h = 1; h <= max_hours; h++) {

				const index = (h < 10) ? `p0${h}` : `p${h}`;
				if (plan_rows[r][index] !== null && plan_rows[r][index] !== undefined) {

					const dam_hour_price = dam_price[index];
					const volume = parseFloat(plan_rows[r][index]);
					const volume_price = valid_value(plan_rows[r].price) ? plan_rows[r].price :
										 valid_value(plan_rows[r].discount) ? dam_hour_price + plan_rows[r].discount :
										 valid_value(plan_rows[r].discount_percent) ? dam_hour_price * (1 + plan_rows[r].discount_percent / 100) : 0;

					row_volume += volume;
					row_amount += (volume * volume_price);

					let this_margin = (is_buying === true) ? (volume * (dam_hour_price - volume_price)) : (volume * (volume_price - dam_hour_price));
					row_margin += this_margin;
				}
			}

			// if nothing exists, add
			if (the_group === undefined) {
				result.groups.push({
					group_id: plan_rows[r].group_id,
					volume: row_volume,
					amount: row_amount,
					margin: row_margin,
				})
			} else {
				the_group.volume += row_volume;
				the_group.amount += row_amount;
				the_group.margin += row_margin;
			}
		}

		// get group payments
		const resp_payments = await axios.get('/api/admin/contracts/payments', { params: {
			contract_id: contract_id,
		}});

		const all_payments = resp_payments.data.payments;
		const group_payments = all_payments.filter(item => {
			const item_date = new Date(item.payment_date);
			return (item_date.getFullYear() >= period_start_date.getFullYear() && 
					item_date.getMonth() >= period_start_date.getMonth() &&
					item_date.getDate() >= period_start_date.getDate() &&
					item_date.getFullYear() <= period_end_date.getFullYear() &&
					item_date.getMonth() <= period_end_date.getMonth() &&
					item_date.getDate() <= period_end_date.getDate())
		});

		// calculate payment
		let payment = 0;
		let pre_payment = 0;
		for (let r = 0; r < group_payments.length; r++) {
			if (group_payments[r].month_ballance === true) {
				pre_payment = group_payments[r].payment;
			} else {
				payment += group_payments[r].payment;
			}
		}

		let total_amount = 0;
		let total_margin = 0;
		let total_volume = 0;

		// calculate group summary
		for (let g = 0; g < result.groups.length; g++) {

			result.groups[g].price = result.groups[g].amount / result.groups[g].volume;
			result.groups[g].amount_vat = result.groups[g].amount * 1.2;

			total_volume += result.groups[g].volume;
			total_amount += result.groups[g].amount;
			total_margin += result.groups[g].margin;
		}

		result.total_days = dam_prices.length;
		result.domain_id = domain_id;
		result.consumer_id = consumer_id;
		result.seller_id = seller_id;
		result.pre_payment = pre_payment;
		result.volume = total_volume;
		result.amount = total_amount;
		result.payment = payment;
		result.ballance = pre_payment + payment - total_amount * 1.2;
		result.margin = total_margin;

		return result;
	}


	async function ExternalSeriesReport3Calculation(period_start, period_end, consumer_id, seller_id, domain_id, contract_id, is_import, max_hours) {

		const result = [];

		const period_start_date = new Date(period_start);
		const period_end_date = new Date(period_end);

		try {

			// get external series
			const resp_ess = await axios.get('/api/es/', { params: {
				domain: domain_id,
				consumer_id: consumer_id,
				seller_id: seller_id,
				period_start: getDateFormat(period_start_date),
				period_end: getDateFormat(period_end_date),
			}});

			const external_series = resp_ess.data.external_series_list;

			if (external_series.length === 0) return result;

			// get financial data
			const resp_group = await axios.get('/api/es/groups/' + contract_id);

			const deals = resp_group.data.group_list;

			if (deals.length === 0) return result;

			const finance_data = deals[0];

			// get exchange rates
			const resp_ers = await axios.get('/api/ers/', { params: {
				period_start: getDateFormat(period_start_date),
				period_end: getDateFormat(period_end_date),
				code: 978
			}});

			const exchange_rates = resp_ers.data.exchange_rates;

			if (exchange_rates.length === 0) return result;

			// pick up domains from external series
			for (let i = 0; i < external_series.length; i++) {

				const series = external_series[i];

				const existing_dir = result.find(item => item.out_domain_id === series.out_domain_id && item.in_domain_id === series.in_domain_id);
				if (existing_dir === undefined) {
					result.push({
						out_domain_id: series.out_domain_id,
						in_domain_id: series.in_domain_id,
						// volume: 0,
						// margin: 0,
						// price: 0
					});
				}
			}

			// for each pair of out and in domains get data and calculate
			for (let i = 0; i < result.length; i++) {

				// get external prices
				const resp_out_prices = await axios.get('/api/dam_prices', { params: {
					domain: result[i].out_domain_id,
					period_start: getDateFormat(period_start_date),
					period_end: getDateFormat(period_end_date),
				}});

				const dam_out_prices = resp_out_prices.data.dam_prices;

				if (dam_out_prices.length === 0) continue;

				// get internal prices
				const resp_in_prices = await axios.get('/api/dam_prices', { params: {
					domain: result[i].in_domain_id,
					period_start: getDateFormat(period_start_date),
					period_end: getDateFormat(period_end_date),
				}});

				const dam_in_prices = resp_in_prices.data.dam_prices;

				if (dam_in_prices.length === 0) continue;

				// get capacities
				const resp_capacities = await axios.get('/api/capacity', { params: {
					out_domain: result[i].out_domain_id,
					in_domain: result[i].in_domain_id,
					period_start: getDateFormat(period_start_date),
					period_end: getDateFormat(period_end_date),
				}});

				const capacities = resp_capacities.data.capacities_list;

				if (capacities.length === 0) continue;

				// make calculations

				let total_volume = 0;
				let total_price = 0;
				let total_margin = 0;
				// for each external series
				for (let j = 0; j < external_series.length; j++) {

					const the_date = new Date(external_series[j].trade_date);

					// Get the data for this date

					const out_prise_row = dam_out_prices.find(item => {

						const item_date = new Date(item.trade_date);
						return (item_date.getFullYear() === the_date.getFullYear() && 
								item_date.getMonth() === the_date.getMonth() &&
								item_date.getDate() === the_date.getDate())
					});

					const in_prise_row = dam_in_prices.find(item => {

						const item_date = new Date(item.trade_date);
						return (item_date.getFullYear() === the_date.getFullYear() && 
								item_date.getMonth() === the_date.getMonth() &&
								item_date.getDate() === the_date.getDate())
					});

					const ex_rate_row = exchange_rates.find(item => {

						const item_date = new Date(item.trade_date);
						return (item_date.getFullYear() === the_date.getFullYear() && 
								item_date.getMonth() === the_date.getMonth() &&
								item_date.getDate() === the_date.getDate())
					});

					const capacity_rows = capacities.filter(item => {

						const item_period_start = new Date(item.period_start);
						const item_period_end = new Date(item.period_end);

						return (
							(
								item.type === 0 &&
								item_period_start.getFullYear() === the_date.getFullYear() && 
								item_period_start.getMonth() === the_date.getMonth() &&
								item_period_start.getDate() === the_date.getDate()
							) || 
							(
								item.type > 0 &&
								item_period_start.getTime() <= the_date.getTime() &&
								item_period_end.getTime() >= the_date.getTime()
							)
						)
					});

					// construct single capacity price row
					const capacity_price = {
						trade_date: getDateFormat(the_date)
					};

					// for each index calculate weighted average of capacity price
					for (let h = 1; h <= max_hours; h++) {

						const target_index = (h < 10) ? `p0${h}` : `p${h}`;

						// for each row in capacity rows
						let volume = 0;
						for (let r = 0; r < capacity_rows.length; r++) {

							const the_row = capacity_rows[r];

							// calculate index
							const source_index = (the_row.type === 0) ? (h < 10) ? `p0${h}` : `p${h}` : 'p01';

							if (the_row[`${source_index}_pr`] !== undefined && the_row[`${source_index}_pr`] !== null) {

								if (capacity_price[target_index] === undefined) {
									capacity_price[target_index] = 0;
								}

								const price = the_row[`${source_index}_pr`];
								const cap = the_row[`${source_index}_rs`];

								capacity_price[target_index] += price * cap;
								volume += cap;
							}
						}

						capacity_price[target_index] /= volume;
					}

					// calculate volume and margin
					if (out_prise_row !== undefined && in_prise_row !== undefined) {

						for (let h = 1; h <= max_hours; h++) {

							const index = (h < 10) ? `p0${h}` : `p${h}`;

							if (out_prise_row[index] !== undefined && out_prise_row[index] !== null &&
								in_prise_row[index] !== undefined && in_prise_row[index] !== null && 
								external_series[j][index] !== undefined && external_series[j][index] !== null) {

								// calculate volume
								total_volume += external_series[j][index];
								let margin = 0;

								if (is_import) {
									// in case of import

									const buy_price = (out_prise_row[index] + finance_data.capacity_price + finance_data.external_tax) *
													  (1 + finance_data.internal_tax) * ex_rate_row.er;
									const sell_price = in_prise_row[index] - capacity_price[index];

									margin = (sell_price - buy_price) * finance_data.income_ratio + finance_data.additional_expenses;

								} else {
									// in case of export

									const buy_price = in_prise_row[index] + capacity_price[index] + finance_data.transition_tariff;
									const sell_price = (out_prise_row[index] - finance_data.capacity_price - finance_data.external_tax) * ex_rate_row.er;

									margin = (sell_price - buy_price) * finance_data.income_ratio + finance_data.additional_expenses;
								}

								total_margin += margin * external_series[j][index];
							}
						}
					}
				}

				// store calculation results
				result[i].volume = total_volume;
				result[i].margin = total_margin;
			}

		} catch(error) {

			console.log(error);
		}

		return result;
	}


	async function DamReportCalculation(period_start, total_days) {

		const result = {
			oes_sell_volume: 0,
			oes_sell_amount: 0,

			buos_sell_volume: 0,
			buos_sell_amount: 0,

			oes_buy_volume: 0,
			oes_buy_amount: 0,

			buos_buy_volume: 0,
			buos_buy_amount: 0,
		}

		try {

			// try catch to avoid failing of the report in case server negative response
			// calculate dam stats
			let current_date = new Date(period_start);
			for (let d = 0; d < total_days; d++) {

				// get dam results
				const resp_dam_bids = await axios.get('/api/dam/xmtrade/results', { params: {
					request_code: 961,
					trade_date: getDateFormat(current_date),
				}});

				const dam_bids = resp_dam_bids.data.results;

				const domain_filters = [ 'UA_IPS', 'UA_BEI' ];
				const dam_trade_type_filters = [ 1, /* buying */ 3 /* selling */ ];

				// for each domain
				for (let fd = 0; fd < domain_filters.length; fd++) {

					// for each trade type
					for (let ft = 0; ft < dam_trade_type_filters.length; ft++) {

						// get the dam result data
						const the_dam_result = dam_bids.find(item => item.domain === domain_filters[fd] && item.result_type_id === dam_trade_type_filters[ft]);

						let dam_amount = 0;
						let dam_price = 0;

						// for each row
						if (the_dam_result !== undefined) {

							for (let h = 0; h < the_dam_result.rows.length; h++) {

								if (the_dam_result.rows[h].amount !== undefined) {
									dam_amount += the_dam_result.rows[h].amount;
									dam_price += the_dam_result.rows[h].price;
								}
							}
						}

						switch (domain_filters[fd]) {
							// OES
							case 'UA_IPS':
								switch (dam_trade_type_filters[ft]) {
									// buy
									case 1:
										result.oes_buy_volume += dam_amount;
										result.oes_buy_amount += dam_price;
									break;
									// sell
									case 3:
										result.oes_sell_volume += dam_amount;
										result.oes_sell_amount += dam_price;
									break;
								}
							break;
							// BUOS
							case 'UA_BEI':
								switch (dam_trade_type_filters[ft]) {
									// buy
									case 1:
										result.buos_buy_volume += dam_amount;
										result.buos_buy_amount += dam_price;
									break;
									// sell
									case 3:
										result.buos_sell_volume += dam_amount;
										result.buos_sell_amount += dam_price;
									break;
								}
							break;
							default:
							break;
						}
					}
				}

				// calculate the date
				current_date.setHours(24, 0, 0, 0);
			}

		} catch {}

		return result;
	}


	async function IdmReportCalculation(period_start, total_days) {

		const result = {
			oes_sell_volume: 0,
			oes_sell_amount: 0,

			buos_sell_volume: 0,
			buos_sell_amount: 0,

			oes_buy_volume: 0,
			oes_buy_amount: 0,

			buos_buy_volume: 0,
			buos_buy_amount: 0,
		}

		try {

			// calculate idm stats
			let current_date = new Date(period_start);
			for (let d = 0; d < total_days; d++) {

				// get idm results
				const resp_idm_bids = await axios.get('/api/idm/xmtrade/results', { params: {
					request_code: 961,
					trade_date: getDateFormat(current_date),
				}});

				const idm_bids = resp_idm_bids.data.results;

				const domain_filters = [ 'UA_IPS', 'UA_BEI' ];
				const idm_trade_type_filters = [ 3, /* buying */ 5 /* selling */ ];

				// for each domain
				for (let fd = 0; fd < domain_filters.length; fd++) {

					// for each trade type
					for (let ft = 0; ft < idm_trade_type_filters.length; ft++) {

						// get the idm result data
						const the_idm_result = idm_bids.find(item => item.domain === domain_filters[fd] && item.result_type_id === idm_trade_type_filters[ft]);

						let idm_amount = 0;
						let idm_price = 0;

						// for each row
						if (the_idm_result !== undefined) {

							for (let h = 0; h < the_idm_result.rows.length; h++) {

								if (the_idm_result.rows[h].amount !== undefined) {
									idm_amount += the_idm_result.rows[h].amount;
									idm_price += the_idm_result.rows[h].price;
								}
							}
						}

						switch (domain_filters[fd]) {
							// OES
							case 'UA_IPS':
								switch (idm_trade_type_filters[ft]) {
									// buy
									case 3:
										result.oes_buy_volume += idm_amount;
										result.oes_buy_amount += idm_price;
									break;
									// sell
									case 5:
										result.oes_sell_volume += idm_amount;
										result.oes_sell_amount += idm_price;
									break;
								}
							break;
							// BUOS
							case 'UA_BEI':
								switch (idm_trade_type_filters[ft]) {
									// buy
									case 3:
										result.buos_buy_volume += idm_amount;
										result.buos_buy_amount += idm_price;
									break;
									// sell
									case 5:
										result.buos_sell_volume += idm_amount;
										result.buos_sell_amount += idm_price;
									break;
								}
							break;
							default:
							break;
						}
					}
				}

				// calculate the date
				current_date.setHours(24, 0, 0, 0);
			}

		} catch {}

		return result;
	}


	function ReformRowsReport3(rows) {

		const result = [];

		let sum_pre_payment = 0;
		let sum_volume = 0;
		let sum_amount = 0;
		let sum_payment = 0;
		let sum_margin = 0;
		let sum_ballance = 0;

		result.push([
			'Продавець',
			'Покупець',
			'Вхідний залишок, грн',
			'Обсяг, МВтг',
			'Ціна без ПДВ, грн',
			'Вартість з ПДВ, грн',
			'Оплата з ПДВ, грн',
			'Маржа, грн',
			'Вихідний залишок, грн'
		]);

		for (let r = 0; r < rows.length; r++) {

			sum_pre_payment += rows[r].pre_payment;
			sum_payment += rows[r].payment;
			sum_margin += rows[r].margin;
			sum_ballance += rows[r].ballance;

			for (let g = 0; g < rows[r].groups.length; g++) {

				sum_volume += rows[r].groups[g].volume;
				sum_amount += rows[r].groups[g].amount_vat;

				result.push([
					rows[r].seller,
					rows[r].consumer,
					(g === 0) ? curFormat.format(rows[r].pre_payment) : '',
					valFormat.format(rows[r].groups[g].volume),
					curFormat.format(rows[r].groups[g].amount / rows[r].groups[g].volume),
					curFormat.format(rows[r].groups[g].amount_vat),
					(g === 0) ? curFormat.format(rows[r].payment) : '',
					curFormat.format(rows[r].groups[g].margin),
					(g === 0) ? curFormat.format(rows[r].ballance) : '',
				]);
			}
		}

		result.push([
			'Всього по зоні',
			'',
			curFormat.format(sum_pre_payment),
			valFormat.format(sum_volume),
			'',
			curFormat.format(sum_amount),
			curFormat.format(sum_payment),
			curFormat.format(sum_margin),
			curFormat.format(sum_ballance),
		]);

		return result;
	}


	function ReformMarketSellRowsReport3(report) {

		const result = [];

		result.push([
			'Зона торгівлі',
			'Оператор ринку',
			'',
			'Обсяг, МВтг',
			'Ціна без ПДВ, грн',
			'Вартість з ПДВ, грн',
			'',
			'',
			''
		]);

		result.push([
			'ОЕС',
			'РДН',
			'',
			valFormat.format(report.dam_sell.oes_volume),
			curFormat.format(report.dam_sell.oes_amount / report.dam_sell.oes_volume),
			curFormat.format(report.dam_sell.oes_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'ОЕС',
			'ВДР',
			'',
			valFormat.format(report.idm_sell.oes_volume),
			curFormat.format(report.idm_sell.oes_amount / report.dam_sell.oes_volume),
			curFormat.format(report.idm_sell.oes_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'БУОС',
			'РДН',
			'',
			valFormat.format(report.dam_sell.buos_volume),
			curFormat.format(report.dam_sell.buos_amount / report.dam_sell.buos_volume),
			curFormat.format(report.dam_sell.buos_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'БУОС',
			'ВДР',
			'',
			valFormat.format(report.idm_sell.buos_volume),
			curFormat.format(report.idm_sell.buos_amount / report.dam_sell.buos_volume),
			curFormat.format(report.idm_sell.buos_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'Всього продаж на РДН та ВДР',
			'',
			'',
			valFormat.format(report.market_sell_summary.volume),
			'',
			curFormat.format(report.market_sell_summary.amount * 1.2),
			'',
			'',
			'',
		]);

		return result;
	}


	function ReformMarketBuyRowsReport3(report) {

		const result = [];

		result.push([
			'Зона торгівлі',
			'Оператор ринку',
			'',
			'Обсяг, МВтг',
			'Ціна без ПДВ, грн',
			'Вартість з ПДВ, грн',
			'',
			'',
			''
		]);

		result.push([
			'ОЕС',
			'РДН',
			'',
			valFormat.format(report.dam_buy.oes_volume),
			curFormat.format(report.dam_buy.oes_amount / report.dam_buy.oes_volume),
			curFormat.format(report.dam_buy.oes_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'ОЕС',
			'ВДР',
			'',
			valFormat.format(report.idm_buy.oes_volume),
			curFormat.format(report.idm_buy.oes_amount / report.idm_buy.oes_volume),
			curFormat.format(report.idm_buy.oes_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'БУОС',
			'РДН',
			'',
			valFormat.format(report.dam_buy.buos_volume),
			curFormat.format(report.dam_buy.buos_amount / report.dam_buy.buos_volume),
			curFormat.format(report.dam_buy.buos_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'БУОС',
			'ВДР',
			'',
			valFormat.format(report.idm_buy.buos_volume),
			curFormat.format(report.idm_buy.buos_amount / report.idm_buy.buos_volume),
			curFormat.format(report.idm_buy.buos_amount * 1.2),
			'',
			'',
			''
		]);

		result.push([
			'Всього купівля на РДН та ВДР',
			'',
			'',
			valFormat.format(report.market_buy_summary.volume),
			'',
			curFormat.format(report.market_buy_summary.amount * 1.2),
			'',
			'',
			'',
		]);

		return result;
	}


	function GetSummaryStruct() {

		return ({
			pre_payment: 0,
			volume: 0,
			amount: 0,
			payment: 0,
			margin: 0,
			ballance: 0
		});
	}


	async function handleCalculateReport3() {

		setWait(true);

		const days = getDaysInMonth(global_state.period_start);
		// calculate hour count 
		const hours_count = hour_count(global_state.period_start, global_state.period_end);

		try {

			// define contractors and report owner
			const contractors = global_state.companies.filter(item => item.company_id !== user.company_id);
			const owner = user.company_id;

			// sub total variables
			const total = {
				efective_days: 0,

				volume_sell: 0,
				amount_sell: 0,
				margin_sell: 0,
				ballance_sell: 0,

				volume_buy: 0,
				amount_buy: 0,
				margin_buy: 0,
				ballance_buy: 0,

				market_volume_sell: 0,
				market_amount_sell: 0,

				market_volume_buy: 0,
				market_amount_buy: 0
			}

			const sell_oes_rows = [];
			const sell_oes_summary = GetSummaryStruct();
			const sell_buos_rows = [];
			const sell_buos_summary = GetSummaryStruct();
			const export_data = [];
			for (let i = 0; i < contractors.length; i++) {

				let consumer_id = contractors[i].company_id;
				let seller_id = owner;

				// get contract
				const resp_contracts = await axios.get('/api/admin/contracts', { params: {
					consumer_id: consumer_id,
					seller_id: seller_id
				}});

				// for each contract
				const contracts = resp_contracts.data.contract_list;
				for (let j = 0; j < contracts.length; j++) {

					const summary1 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, false, hours_count);
					if (summary1.groups.length > 0) {
						sell_oes_rows.push({
							consumer: contractors[i].name,
							seller: user.company_name,
							...summary1
						});

						sell_oes_summary.pre_payment += summary1.pre_payment;
						sell_oes_summary.volume += summary1.volume;
						sell_oes_summary.amount += summary1.amount;
						sell_oes_summary.payment += summary1.payment;
						sell_oes_summary.margin += summary1.margin;
						sell_oes_summary.ballance += summary1.ballance;
					}

					const export1 = await ExternalSeriesReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, false, hours_count);
					if (export1.length > 0) {
						export_data.push(...export1);
					}

					const summary2 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, false, hours_count);
					if (summary2.groups.length > 0) {
						sell_buos_rows.push({
							consumer: contractors[i].name,
							seller: user.company_name,
							...summary2
						});

						sell_buos_summary.pre_payment += summary2.pre_payment;
						sell_buos_summary.volume += summary2.volume;
						sell_buos_summary.amount += summary2.amount;
						sell_buos_summary.payment += summary2.payment;
						sell_buos_summary.margin += summary2.margin;
						sell_buos_summary.ballance += summary2.ballance;
					}

					const export2 = await ExternalSeriesReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, false, hours_count);
					if (export2.length > 0) {
						export_data.push(...export2);
					}
				}
			}

			const buy_oes_rows = [];
			const buy_oes_summary = GetSummaryStruct();
			const buy_buos_rows = [];
			const buy_buos_summary = GetSummaryStruct();
			const import_data = [];
			for (let i = 0; i < contractors.length; i++) {

				let consumer_id = owner;
				let seller_id = contractors[i].company_id;

				// get contract
				const resp_contracts = await axios.get('/api/admin/contracts', { params: {
					consumer_id: consumer_id,
					seller_id: seller_id
				}});

				// for each contract
				const contracts = resp_contracts.data.contract_list;
				for (let j = 0; j < contracts.length; j++) {

					const summary1 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, true, hours_count);
					if (summary1.groups.length > 0) {
						buy_oes_rows.push({
							consumer: user.company_name,
							seller: contractors[i].name,
							...summary1
						});

						buy_oes_summary.pre_payment += summary1.pre_payment;
						buy_oes_summary.volume += summary1.volume;
						buy_oes_summary.amount += summary1.amount;
						buy_oes_summary.payment += summary1.payment;
						buy_oes_summary.margin += summary1.margin;
						buy_oes_summary.ballance += summary1.ballance;
					}

					const import1 = await ExternalSeriesReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, true, hours_count);
					if (import1.length > 0) {
						import_data.push(...import1);
					}

					const summary2 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, true, hours_count);
					if (summary2.groups.length > 0) {
						buy_buos_rows.push({
							consumer: user.company_name,
							seller: contractors[i].name,
							...summary2
						});

						buy_buos_summary.pre_payment += summary2.pre_payment;
						buy_buos_summary.volume += summary2.volume;
						buy_buos_summary.amount += summary2.amount;
						buy_buos_summary.payment += summary2.payment;
						buy_buos_summary.margin += summary2.margin;
						buy_buos_summary.ballance += summary2.ballance;
					}

					const import2 = await ExternalSeriesReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, true, hours_count);
					if (import2.length > 0) {
						import_data.push(...import2);
					}
				}
			}

			// calculate totals
			total.volume_sell += sell_oes_summary.volume;
			total.volume_sell += sell_buos_summary.volume;
			total.amount_sell += sell_oes_summary.amount;
			total.amount_sell += sell_buos_summary.amount;
			total.margin_sell += sell_oes_summary.margin;
			total.margin_sell += sell_buos_summary.margin;
			total.ballance_sell += sell_oes_summary.ballance;
			total.ballance_sell += sell_buos_summary.ballance;

			total.volume_buy += buy_oes_summary.volume;
			total.volume_buy += buy_buos_summary.volume;
			total.amount_buy += buy_oes_summary.amount;
			total.amount_buy += buy_buos_summary.amount;
			total.margin_buy += buy_oes_summary.margin;
			total.margin_buy += buy_buos_summary.margin;
			total.ballance_buy += buy_oes_summary.ballance;
			total.ballance_buy += buy_buos_summary.ballance;

			if (total.efective_days === 0 && sell_oes_rows.length > 0 && sell_oes_rows[0].total_days !== 0) {
				total.efective_days = sell_oes_rows[0].total_days;
			}
			if (total.efective_days === 0 && sell_buos_rows.length > 0 && sell_buos_rows[0].total_days !== 0) {
				total.efective_days = sell_buos_rows[0].total_days;
			}
			if (total.efective_days === 0 && buy_oes_rows.length > 0 && buy_oes_rows[0].total_days !== 0) {
				total.efective_days = buy_oes_rows[0].total_days;
			}
			if (total.efective_days === 0 && buy_buos_rows.length > 0 && buy_buos_rows[0].total_days !== 0) {
				total.efective_days = buy_buos_rows[0].total_days;
			}

			// reform export and import data
			for (let i = 0; i < export_data.length; i++) {

				const out_domain = global_state.out_domains.find(item => item.domain_id === export_data[i].out_domain_id);
				const in_domain = global_state.out_domains.find(item => item.domain_id === export_data[i].in_domain_id);

				export_data[i].out_domain = (out_domain !== undefined) ? out_domain.domain_display_name : '';
				export_data[i].in_domain = (in_domain !== undefined) ? in_domain.domain_display_name : '';

				total.volume_sell += export_data[i].volume;
				total.margin_sell += export_data[i].margin;
			}
			for (let i = 0; i < import_data.length; i++) {

				const out_domain = global_state.out_domains.find(item => item.domain_id === import_data[i].out_domain_id);
				const in_domain = global_state.out_domains.find(item => item.domain_id === import_data[i].in_domain_id);

				import_data[i].out_domain = (out_domain !== undefined) ? out_domain.domain_display_name : '';
				import_data[i].in_domain = (in_domain !== undefined) ? in_domain.domain_display_name : '';

				total.volume_buy += import_data[i].volume;
				total.margin_buy += import_data[i].margin;
			}

			const dam_results = await DamReportCalculation(global_state.period_start, total.efective_days);
			const idm_results = await IdmReportCalculation(global_state.period_start, total.efective_days);

			// calculate market totals
			total.market_volume_sell += dam_results.oes_sell_volume;
			total.market_volume_sell += dam_results.buos_sell_volume;
			total.market_volume_sell += idm_results.oes_sell_volume;
			total.market_volume_sell += idm_results.buos_sell_volume;
			total.volume_sell += total.market_volume_sell;

			total.market_amount_sell += dam_results.oes_sell_amount;
			total.market_amount_sell += dam_results.buos_sell_amount;
			total.market_amount_sell += idm_results.oes_sell_amount;
			total.market_amount_sell += idm_results.buos_sell_amount;
			total.amount_sell += total.market_amount_sell;

			total.market_volume_buy += dam_results.oes_buy_volume;
			total.market_volume_buy += dam_results.buos_buy_volume;
			total.market_volume_buy += idm_results.oes_buy_volume;
			total.market_volume_buy += idm_results.buos_buy_volume;
			total.volume_buy += total.market_volume_buy;

			total.market_amount_buy += dam_results.oes_buy_amount;
			total.market_amount_buy += dam_results.buos_buy_amount;
			total.market_amount_buy += idm_results.oes_buy_amount;
			total.market_amount_buy += idm_results.buos_buy_amount;
			total.amount_buy += total.market_amount_buy;

			const display_report = {
				oes_sell: sell_oes_rows,
				oes_sell_summary: sell_oes_summary,
				buos_sell: sell_buos_rows,
				buos_sell_summary: sell_buos_summary,
				dam_sell: {
					oes_volume: dam_results.oes_sell_volume,
					oes_amount: dam_results.oes_sell_amount,
					buos_volume: dam_results.buos_sell_volume,
					buos_amount: dam_results.buos_sell_amount,
				},
				idm_sell: {
					oes_volume: idm_results.oes_sell_volume,
					oes_amount: idm_results.oes_sell_amount,
					buos_volume: idm_results.buos_sell_volume,
					buos_amount: idm_results.buos_sell_amount,
				},
				market_sell_summary: {
					volume: total.market_volume_sell,
					amount: total.market_amount_sell,
				},
				export: export_data,
				total_sell: {
					volume: total.volume_sell,
					amount: total.amount_sell,
					margin: total.margin_sell,
					ballance: total.ballance_sell
				},

				oes_buy: buy_oes_rows,
				oes_buy_summary: buy_oes_summary,
				buos_buy: buy_buos_rows,
				buos_buy_summary: buy_buos_summary,
				dam_buy: {
					oes_volume: dam_results.oes_buy_volume,
					oes_amount: dam_results.oes_buy_amount,
					buos_volume: dam_results.buos_buy_volume,
					buos_amount: dam_results.buos_buy_amount,
				},
				idm_buy: {
					oes_volume: idm_results.oes_buy_volume,
					oes_amount: idm_results.oes_buy_amount,
					buos_volume: idm_results.buos_buy_volume,
					buos_amount: idm_results.buos_buy_amount,
				},
				market_buy_summary: {
					volume: total.market_volume_buy,
					amount: total.market_amount_buy,
				},
				import: import_data,
				total_buy: {
					volume: total.volume_buy,
					amount: total.amount_buy,
					margin: total.margin_buy,
					ballance: total.ballance_buy
				},

				total: {
					effective_days: total.effective_days,
					margin: total.margin_sell + total.margin_buy,
					plan_margin: (total.margin_sell + total.margin_buy) * days / total.efective_days
				}
			};

			setGlobalState(prev_state => ({ ...prev_state,
				report_3: display_report,
			}));

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		setWait(false);
	}


	async function handleSaveReport3() {

		try {

			const resp_save = await axios.post('/api/reports/add/', {
				period_start: getDateFormat(global_state.period_start),
				period_end: getDateFormat(global_state.period_end),
				report: global_state.report_3,
			});

			console.log(resp_save.data);

			if (resp_save.status === 200) {
				window.alert('Звіт збережено.');
			}

		} catch(error) {

			console.log(error);
		}
	}


	function handleExportReport3() {

		if (global_state.report_3.total !== undefined) {

			const oes_sell_rows = ReformRowsReport3(global_state.report_3.oes_sell);
			const buos_sell_rows = ReformRowsReport3(global_state.report_3.buos_sell);
			const market_sell_rows = ReformMarketSellRowsReport3(global_state.report_3);

			const oes_buy_rows = ReformRowsReport3(global_state.report_3.oes_buy);
			const buos_buy_rows = ReformRowsReport3(global_state.report_3.buos_buy);
			const market_buy_rows = ReformMarketBuyRowsReport3(global_state.report_3);

			const download_report = [
				['Продаж на РДД в ОЕС'],
				...oes_sell_rows,
				[],
				['Продаж на РДД в БУОС'],
				...buos_sell_rows,
				[],
				['Продаж на РДН та ВДР'],
				...market_sell_rows,
				[],
				['Купівля на РДД в ОЕС'],
				...oes_buy_rows,
				[],
				['Купівля на РДД в БУОС'],
				...buos_buy_rows,
				[],
				['Купівля на РДН та ВДР'],
				...market_buy_rows,
				[]
			];

			const file_name = `Summary_report_3_${getMonthFormat(global_state.period_start)}.xlsx`
	
			// create sheet from json
			const output_sheet = XLSX.utils.json_to_sheet(download_report, { skipHeader: true });

			// create new workbook
			var output_wb = XLSX.utils.book_new();

			// insert sheet into workbook
			XLSX.utils.book_append_sheet(output_wb, output_sheet, getMonthFormat(global_state.period_start));

			// save workbook to file
			XLSX.writeFile(output_wb, file_name);
		}
	}


	async function handleOpenReport3(report_id) {

		try {

			const resp_get = await axios.get('/api/reports/' + report_id);

			if (resp_get.status === 200) {

				setGlobalState(prev_state => ({ ...prev_state,
					report_3: resp_get.data.report
				}));
			}

		} catch(error) {

			console.log(error);
		}
	}


	async function handleDeleteReport3(report_id) {

		try {

			const resp_delete = await axios.post('/api/reports/' + report_id + '/delete');

			if (resp_delete.status === 200) {

				const reports = [ ...global_state.saved_reports ];
				const filtered = reports.filter(item => item.report_id !== report_id);

				setGlobalState(prev_state => ({ ...prev_state,
					saved_reports: filtered
				}));
			}

		} catch(error) {

			console.log(error);
		}
	}


	// report tab 4

	function ReformRowsReport4(rows) {

		const result = [];

		result.push([
			'Продавець',
			'Покупець',
			'Обсяг, МВтг',
			'Ціна, грн',
			'Сума без ПДВ, грн',
			'ПДВ, грн',
			'Сума з ПДВ, грн'
		]);

		for (let r = 0; r < rows.length; r++) {

			for (let g = 0; g < rows[r].groups.length; g++) {

				result.push([
					rows[r].seller,
					rows[r].consumer,
					valFormat.format(rows[r].groups[g].volume),
					priFormat.format(rows[r].groups[g].price),
					curFormat.format(rows[r].groups[g].amount),
					curFormat.format(rows[r].groups[g].amount * 0.2),
					curFormat.format(rows[r].groups[g].amount_vat),
				]);
			}
		}

		return result;
	}


	async function handleCalculateReport4() {

		setWait(true);

		// calculate hour count 
		const hours_count = hour_count(global_state.period_start, global_state.period_end);

		try {

			// define contractors and report owner
			const contractors = global_state.companies.filter(item => item.company_id !== user.company_id);
			const owner = user.company_id;

			const sell_oes_rows = [];
			const sell_buos_rows = [];
			for (let i = 0; i < contractors.length; i++) {

				let consumer_id = contractors[i].company_id;
				let seller_id = owner;

				// get contract
				const resp_contracts = await axios.get('/api/admin/contracts', { params: {
					consumer_id: consumer_id,
					seller_id: seller_id
				}});

				// for each contract
				const contracts = resp_contracts.data.contract_list;
				for (let j = 0; j < contracts.length; j++) {

					const summary1 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, false, hours_count);
					if (summary1.groups.length > 0) {
						sell_oes_rows.push({
							consumer: contractors[i].name,
							seller: user.company_name,
							...summary1
						});
					}

					const summary2 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, false, hours_count);
					if (summary2.groups.length > 0) {
						sell_buos_rows.push({
							consumer: contractors[i].name,
							seller: user.company_name,
							...summary2
						});
					}
				}
			}

			const buy_oes_rows = [];
			const buy_buos_rows = [];
			for (let i = 0; i < contractors.length; i++) {

				let consumer_id = owner;
				let seller_id = contractors[i].company_id;

				// get contract
				const resp_contracts = await axios.get('/api/admin/contracts', { params: {
					consumer_id: consumer_id,
					seller_id: seller_id
				}});

				// for each contract
				const contracts = resp_contracts.data.contract_list;
				for (let j = 0; j < contracts.length; j++) {

					const summary1 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 1, contracts[j].contract_id, true, hours_count);
					if (summary1.groups.length > 0) {
						buy_oes_rows.push({
							consumer: user.company_name,
							seller: contractors[i].name,
							...summary1
						});
					}

					const summary2 = await ContractorReport3Calculation(global_state.period_start, global_state.period_end, consumer_id, seller_id, 2, contracts[j].contract_id, true, hours_count);
					if (summary2.groups.length > 0) {
						buy_buos_rows.push({
							consumer: user.company_name,
							seller: contractors[i].name,
							...summary2
						});
					}
				}
			}

			const display_report = {
				oes_sell: sell_oes_rows,
				buos_sell: sell_buos_rows,
				oes_buy: buy_oes_rows,
				buos_buy: buy_buos_rows
			};

			setGlobalState(prev_state => ({ ...prev_state,
				report_4: display_report,
			}));

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		setWait(false);
	}


	function handleExportReport4() {

		if (global_state.report_4.oes_sell !== undefined) {

			const oes_sell_rows = ReformRowsReport4(global_state.report_4.oes_sell);
			const buos_sell_rows = ReformRowsReport4(global_state.report_4.buos_sell);
			const oes_buy_rows = ReformRowsReport4(global_state.report_4.oes_buy);
			const buos_buy_rows = ReformRowsReport4(global_state.report_4.buos_buy);

			const download_report = [
				['Продаж ОЕС'],
				...oes_sell_rows,
				[],
				['Продаж БУОС'],
				...buos_sell_rows,
				[],
				['Купівля ОЕС'],
				...oes_buy_rows,
				[],
				['Купівля БУОС'],
				...buos_buy_rows
			];

			const file_name = `Summary_report_4_${getMonthFormat(global_state.period_start)}.xlsx`

			// create sheet from json
			const output_sheet = XLSX.utils.json_to_sheet(download_report, { skipHeader: true });

			// create new workbook
			var output_wb = XLSX.utils.book_new();

			// insert sheet into workbook
			XLSX.utils.book_append_sheet(output_wb, output_sheet, getMonthFormat(global_state.period_start));

			// save workbook to file
			XLSX.writeFile(output_wb, file_name);
		}
	}


	// common functions

	function onFilterChange(date1, date2) {

		setGlobalState(prev_state => ({ ...prev_state,
			period_start: date1,
			period_end: date2
		}));
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
			edit: 0,
			info: '',
		}));
	}


	// helper functions

	const hour_count = (start, end) => {

		// calculate number of days
		let days = Math.round((new Date(end) - new Date(start)) / 3.6e6 / 24) + 1;

		// calculate max column count
		var result_hour_count = 24;
		for (let d = 0; d < days; d++) {

			const row_date = new Date(start);
			row_date.setHours(24 * d, 0, 0, 0);
			const current_hours = getHoursInDate(row_date);

			if (current_hours > result_hour_count) {
				result_hour_count = current_hours;
			}
		}

		return result_hour_count;
	}


	const valid_value = (a) => {
		return (a !== undefined) && (a !== null) && (a !== '');
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const res_market_defs = await axios.get('/api/defs/dam_results');
				const res_market_defs2 = await axios.get('/api/defs/capacities');
				const res_company_data = await axios.get('/api/admin/companies');

				setGlobalState(prev_state => ({ ...prev_state,
					companies: res_company_data.data.company_list,
					domains: res_market_defs.data.domain_list,
					out_domains: res_market_defs2.data.domain_list
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		fetchData();

	}, [])


	useEffect(() => {

		if (tab_no < 2) {
			setContractLabelWidth(contract_label.current.offsetWidth);
		}
		if (tab_no === 2) {
			setDomainLabelWidth(domain_label.current.offsetWidth);
			setGroupLabelWidth(group_label.current.offsetWidth);
		}

	}, [tab_no])


	useEffect(() => {

		async function fetchData() {

			try {

				const resp_reports = await axios.get('/api/reports', { params: {
					period_start:	getDateFormat(global_state.period_start),
					period_end:		getDateFormat(global_state.period_end)
				}});

				setGlobalState(prev_state => ({ ...prev_state,
					saved_reports: resp_reports.data.reports
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (tab_no === 3) {
			fetchData();
		}

	}, [tab_no, global_state.period_start, global_state.period_end])


	// effects

	useEffect(() => {

		async function fetchData(consumer_id, seller_id) {

			try {

				const resp_contracts = await axios.get('/api/admin/contracts', { params: {
					consumer_id: consumer_id,
					seller_id: seller_id
				}});

				const contracts = resp_contracts.data.contract_list;

				setGlobalState(prev_state => ({ ...prev_state,
					contracts: contracts,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (global_state.consumer_id !== 0 && global_state.seller_id !== 0) {
			fetchData(global_state.consumer_id, global_state.seller_id);
		}

	}, [global_state.consumer_id, global_state.seller_id])


	useEffect(() => {

		async function fetchData(consumer_id, seller_id, domain_id) {

			try {

				const resp_plan_rows = await axios.get('/api/planning/plans_finance', { params: {
					domain:			domain_id,
					consumer_company_id: consumer_id,
					seller_company_id: seller_id,
					period_start:	getDateFormat(global_state.period_start),
					period_end:		getDateFormat(global_state.period_end)
				}});

				const resp_groups = await axios.get('/api/planning/groups', { params: {
					domain: domain_id,
					period_start: getDateFormat(global_state.period_start),
					period_end: getDateFormat(global_state.period_end)
				}});

				const plan_rows = resp_plan_rows.data.plan_row_list;
				const group_list = resp_groups.data.group_list;

				// get group ids from plan rows
				const groups = [];
				const display_groups = [];
				plan_rows.forEach(row => {
					if (!groups.includes(row.group_id)) {

						groups.push(row.group_id);

						const group = group_list.find(item => item.group_id === row.group_id);
						if (group) display_groups.push(group);
					}
				});

				setGlobalState(prev_state => ({ ...prev_state,
					groups: display_groups,
				}));

			} catch(error) {
	
				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (global_state.consumer_id !== 0 && global_state.seller_id !== 0 && global_state.domain_id !== 0 && is_valid_date(global_state.period_start) && is_valid_date(global_state.period_end)) {
			fetchData(global_state.consumer_id, global_state.seller_id, global_state.domain_id);
		}

	}, [global_state.consumer_id, global_state.seller_id, global_state.domain_id, global_state.period_start, global_state.period_end])


	// filling select control for domains and companies
	var display_domains = [];
	if (global_state.domains.length > 0) {
		display_domains = global_state.domains.map(item => {
			return (
				<option key={item.domain_id} value={item.domain_id} >{item.domain_display_name}</option>
			)
		});
		display_domains.unshift(<option key={-1} value={''}></option>);
	}

	var display_companies = [];
	if (global_state.companies.length > 0) {
		display_companies = global_state.companies.map(company => company.name);
	}

	var display_contracts = [];
	if (global_state.contracts.length > 0) {
		display_contracts = global_state.contracts.map(item => (
			<option key={item.contract_id} value={item.contract_id}>{`${item.contract_number} від ${getLocalDateString(item.contract_date)}`}</option>
		));
		display_contracts.unshift(<option key={-1} value={''}></option>);
	}

	var display_groups = [];
	if (global_state.groups.length > 0) {
		display_groups = global_state.groups.map(item => {

			let opt_name = `Період від ${getLocalDateString(item.period_start)} до ${getLocalDateString(item.period_end)}; `;

			if (item.price) {
				opt_name += `Ціна ${item.price}; `;
			}
			if (item.discount) {
				opt_name += `Знижка ${item.discount}; `;
			}
			if (item.discount_percent) {
				opt_name += `Знижка ${item.discount_percent}%; `;
			}
			if (item.comment) {
				opt_name += `Коментар: \'${item.comment}\'.`;
			}

			return (<option key={item.group_id} value={item.group_id}>{ opt_name }</option>);
		});
		display_groups.unshift(<option key={-1} value={''}></option>);
	}


	return (
		<operReportContext.Provider value={{ global_state, setGlobalState }}>

			<div className={classes.root} >

				<AppBar position='static'>
					<Toolbar>

						<Typography className={classes.title} variant='h6' noWrap>
							Оперативний звіт
						</Typography>

						<FilterPeriod
							period_start={global_state.period_start}
							period_end={global_state.period_end}
							onFilterChange={onFilterChange}
							onFilterClick={() => {}}
						/>

						<IconButton
							className={classes.right_button}
							onClick={() => props.history.push('/')}
							color='inherit'
						>
							<BackIcon />
						</IconButton>

					</Toolbar>
				</AppBar>

				<Tabs
					value={tab_no}
					onChange={OnTabChage}
					className={classes.tab}
					indicatorColor='primary'
					textColor='primary'
				>
					<Tab label='Оплати РДД' {...getTabsProps(0)} />
					<Tab label='Розрахунок оплат' {...getTabsProps(1)} />
					<Tab label='Звітність РДД' {...getTabsProps(2)} />
					<Tab label='Зведений розрахунок' {...getTabsProps(3)} />
					<Tab label='Звіт купівлі - продажу' {...getTabsProps(4)} />
				</Tabs>

				<TabPanel value={tab_no} index={0}>

					<div className={classes.form_tab_1}>

						<Grid container spacing={2}>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Продавець' variant='outlined' />}
									value={global_state.seller_company}
									onChange={handleSellerChange}
								/>

							</Grid>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Покупець' variant='outlined' />}
									value={global_state.consumer_company}
									onChange={handleConsumerChange}
								/>

							</Grid>

							<Grid item xs={12}>

								<FormControl variant='outlined' required fullWidth>
									<InputLabel ref={contract_label} id='contract-label'>Договір</InputLabel>
									<Select
										native
										labelId='contract-label'
										labelWidth={contract_label_width}
										onChange={handleContractChange}
										value={global_state.contract_id}
									>
										{ display_contracts }
									</Select>
								</FormControl>

							</Grid>

						</Grid>

					</div>

					<ComponentPaymentsEdit
						contract_id={ global_state.contract_id }
						companies={ global_state.companies }
						period_start={global_state.period_start}
						period_end={global_state.period_end}
					/>

				</TabPanel>

				<TabPanel value={tab_no} index={1}>

					<div className={classes.form_tab_1}>

						<Grid container spacing={2}>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Продавець' variant='outlined' />}
									value={global_state.seller_company}
									onChange={handleSellerChange}
								/>

							</Grid>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Покупець' variant='outlined' />}
									value={global_state.consumer_company}
									onChange={handleConsumerChange}
								/>

							</Grid>

							<Grid item xs={12}>

								<FormControl variant='outlined' required fullWidth>
									<InputLabel ref={contract_label} id='contract-label'>Договір</InputLabel>
									<Select
										native
										labelId='contract-label'
										labelWidth={contract_label_width}
										onChange={handleContractChange}
										value={global_state.contract_id}
									>
										{ display_contracts }
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={12}>

								<Button
									variant='contained'
									color='primary'
									className={classes.control_margin}
									onClick={handleCalculateReport1}
									disabled={ global_state.consumer_id === 0 || global_state.seller_id === 0 || global_state.contract_id === 0 }
								>
									Підготувати звіт
								</Button>

							</Grid>

						</Grid>

					</div>

					<SummaryReport1
						report={global_state.report_1}
					/>

				</TabPanel>

				<TabPanel value={tab_no} index={2}>

					<div className={classes.form_tab_1}>

						<Grid container spacing={2}>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Продавець' variant='outlined' />}
									value={global_state.seller_company}
									onChange={handleSellerChange}
								/>

							</Grid>

							<Grid item xs={6}>

								<Autocomplete
									options={display_companies}
									getOptionLabel={(option) => option}
									renderInput={(params) => <TextField {...params} label='Покупець' variant='outlined' />}
									value={global_state.consumer_company}
									onChange={handleConsumerChange}
								/>

							</Grid>

							<Grid item xs={6}>

								<FormControl variant='outlined' required fullWidth>
									<InputLabel ref={domain_label} id='domain-label'>Зона торгівлі</InputLabel>
									<Select
										native
										labelId='domain-label'
										labelWidth={domain_label_width}
										onChange={handleDomainChange}
										value={global_state.domain_id}
									>
										{display_domains}
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={12}>

								<FormControl variant='outlined' fullWidth>
									<InputLabel ref={group_label} id='group-label'>Група фінансових показників</InputLabel>
									<Select
										native
										labelId='group-label'
										labelWidth={group_label_width}
										onChange={handleGroupChange}
										value={global_state.group_id}
									>
										{ display_groups }
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={12}>

								<Button
									variant='contained'
									color='primary'
									className={classes.control_margin}
									onClick={ handleCalculateReport2 }
									disabled={ global_state.consumer_id === 0 || global_state.seller_id === 0 || global_state.domain_id === 0 }
								>
									Підготувати звіт
								</Button>

							</Grid>

						</Grid>

					</div>

					<SummaryReport2
						report={global_state.report_2}
					/>

				</TabPanel>

				<TabPanel value={tab_no} index={3}>

					<div className={classes.form_tab_2}>

						<Grid container spacing={2}>

							<Grid item xs={8}>

								{
									(global_state.saved_reports.length > 0) &&
									<div className={classes.form_small_area}>
										<div className={ classes.table_reports }>
											<table>
												<thead>
													<tr>
														<th
															colSpan={5}
															className={classes.table_reports_cells}
														>
															Збережені звіти
														</th>
													</tr>
													<tr>
														<th className={classes.table_reports_cells}>
															Користувач
														</th>
														<th className={classes.table_reports_cells}>
															Дата звіту
														</th>
														<th className={classes.table_reports_cells}>
															Період звіту з
														</th>
														<th className={classes.table_reports_cells}>
															Період звіту по
														</th>
														<th className={classes.table_reports_cells}>
															Дії
														</th>
													</tr>
												</thead>
												<tbody>
													{
														global_state.saved_reports.map((item) => (
															<tr
																key={item.report_id}
															>
																<td className={classes.table_reports_cells}>
																	{`${item.first_name} ${item.last_name}`}
																</td>
																<td className={classes.table_reports_cells}>
																	{ getLocalDateTimeString(item.report_date) }
																</td>
																<td className={classes.table_reports_cells}>
																	{ getLocalDateString(item.period_start) }
																</td>
																<td className={classes.table_reports_cells}>
																	{ getLocalDateString(item.period_end) }
																</td>
																<td className={classes.table_reports_cells}>
																	<button
																		onClick={ () => handleOpenReport3(item.report_id) }
																	>
																		Відкрити
																	</button>
																	<button
																		onClick={ () => handleDeleteReport3(item.report_id) }
																	>
																		Видалити
																	</button>
																</td>
															</tr>
														))
													}
												</tbody>
											</table>
										</div>
									</div>
								}

							</Grid>

							<Grid item xs={4}>

								<Typography component='h3' variant='body1' noWrap>
									Підготування звіту займає декілька хвилин
								</Typography>

								<Button
									variant='contained'
									color='primary'
									className={classes.control_margin}
									onClick={handleCalculateReport3}
								>
									Підготувати новий звіт
								</Button>

							</Grid>

						</Grid>

					</div>

					<div className={classes.margin_title}>

						{
							(global_state.report_3.total) && 
							<Typography component='h3' variant='body1' noWrap>
								<b>{ `Планова маржінальність за період: ${ curFormat.format(global_state.report_3.total.plan_margin) }.` }</b>
							</Typography>
						}

						{
							(global_state.report_3.total) && 
							<Typography component='h3' variant='body1' noWrap>
								<b>{ `Фактична маржінальність за період: ${ curFormat.format(global_state.report_3.total.margin) }.` }</b>
							</Typography>
						}

					</div>

					<SummaryReport3
						report={global_state.report_3}
					/>

					<div className={classes.form_tab_2}>

						<Button
							variant='contained'
							color='primary'
							className={classes.control_margin}
							disabled={global_state.report_3.total === undefined}
							onClick={handleSaveReport3}
						>
							Зберегти звіт
						</Button>

						<Button
							variant='contained'
							color='primary'
							className={classes.control_margin}
							disabled={global_state.report_3.total === undefined}
							onClick={handleExportReport3}
						>
							Завантажити звіт
						</Button>

					</div>

				</TabPanel>

				<TabPanel value={tab_no} index={4}>

					<div className={classes.form_tab_2}>

						<Typography component='h3' variant='body1' noWrap>
							Підготування звіту купівлі - продажу займає декілька хвилин
						</Typography>

						<Button
							variant='contained'
							color='primary'
							className={classes.control_margin}
							onClick={handleCalculateReport4}
						>
							Підготувати звіт
						</Button>

					</div>

					<SummaryReport4
						report={global_state.report_4}
					/>

					<div className={classes.form_tab_2}>

						<Button
							variant='contained'
							color='primary'
							className={classes.control_margin}
							disabled={global_state.report_4.oes_buy === undefined}
							onClick={handleExportReport4}
						>
							Завантажити звіт
						</Button>

					</div>

				</TabPanel>

				<WaitDialog
					open={wait}
				/>

				<MessageErrorSnackbar
					open={message.show}
					message={message.message}
					info={''}
					onClose={onErrorMessageClose}
				/>

			</div>

		</operReportContext.Provider>
	);
}

export default withRouter(OperationalReportForm);