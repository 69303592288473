import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { capacityContext } from './capacityContext';
import { is_valid_float_input } from '../utils/validation';
import { getDateFormat } from '../utils/date_functions';


const useStyles = makeStyles(theme => ({

	root_form: {
		margin: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	form_control: {
		width: '100%',
		margin: theme.spacing(1),
		alignItems: 'center'
	},

	button_form_control: {
		textAlign: 'center',
	},

	button_control: {
		margin: theme.spacing(1),
		display: 'inline-block'
	},

	header: {
		marginBottom: theme.spacing(3),
	},

	control_bottom_margin: {
		marginBottom: theme.spacing(2),
	},

	table_control: {
		border: '1px solid gray',
		padding: theme.spacing(1),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'inline-block',
	},

	table_head: {
		fontWeight: 'bold',
	},

	table_row_1: {
		background: 'white'
	},

	table_row_2: {
		background: '#F0FFFF'
	},

	hour_point: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '85px'
	},

}));


export default function LimitEditForm(props) {
	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: '',
	}
	const [message, setMessage] = useState(initial_message);

	// states for form controls
	const initial_state = createInitialState();
	const [local_state, setLocalState] = useState(initial_state);

	// import context
	const { setGlobalState } = useContext(capacityContext);

	// get time series id from url
	const capacity_id = parseInt(props.match.params.capacity_id);
	const limit_id = parseInt(props.match.params.limit_id);
	const update_url = isNaN(limit_id) ? '/api/capacity/limit/add' : '/api/capacity/limit/' + limit_id;


	// capacity id is undefined - return
	if (isNaN(capacity_id)) {
		props.history.go(-1);
	}


	function createInitialState() {

		var day_1 = new Date();
		day_1.setHours(0, 0, 0, 0);

		var hour_points_array = [];
		for (let i = 1; i <= 24; i++) {

			hour_points_array.push({
				key: i,
				id: i.toString(),
				label: i.toString(),
				valid: true,
				limit: 0,
				rs: 0,
			});
		}

		const complex_state = {

			type_id: 0,
			out_domain_id: 0,
			in_domain_id: 0,
			period_start: '',
			period_end: '',
			limit_date: getDateFormat(day_1),
			date_valid: true,

			saved_es: [],
			hour_points: hour_points_array,

			changed: false,
		}

		return complex_state;
	}


	function handleLimitDateChange(event) {

		const event_value = event.target.value;

		const set_date = new Date(event_value);
		const start_date = new Date(local_state.period_start);
		const end_date = new Date(local_state.period_end);

		setLocalState(prev_state => ({ ...prev_state,
			limit_date: event_value,
			date_valid: start_date <= set_date && set_date <= end_date,
			changed: true,
		}));

		// update page according to the time difference
		updateHours(event_value);
	}


	function onTextChangeHandler(event) {

		// cache event value
		const event_value = event.target.value;
		// get element id
		const elem_id = event.target.id;
		const elem_int_id = parseInt(elem_id);

		// find item
		const new_points = [ ...local_state.hour_points ];
		const item_at_index = new_points.find(item => item.key === elem_int_id);

		item_at_index.valid = is_valid_float_input(event_value);

		if (elem_id === elem_int_id + '-limit') {
			item_at_index.limit = event_value;
		}

		setLocalState(prev_state => ({ ...prev_state,
			hour_points: new_points,
			changed: true,
		}));
	}


	function onValuePaste(event) {

		const event_target = event.target;
		// for elements with identified id
		if (event_target.id) {

			// e.clipboardData contains the data that is about to be pasted.
			if (event.clipboardData.types.indexOf('text/plain') > -1) {

				const string_to_paste = event.clipboardData.getData('text/plain');
				const array_to_paste = string_to_paste.replace(/\t+/g, ';').replace(/\s+/g, '').replace(/,/g, '.').split(';');

				// Since the paste operation get canceled, we need to manually
				// paste the data into the document.
				const new_points = [ ...local_state.hour_points ];

				// iterate through buffer array
				for (let i = 0; i < array_to_paste.length; i++) {

					if (array_to_paste[i]) {

						let element_id = parseInt(event_target.id) + i - 1;
						if (new_points[element_id.toString()]) {
							new_points[element_id.toString()].value = parseFloat(array_to_paste[i]);
						}
					}
				}

				setLocalState(prev_state => ({ ...prev_state,
					hour_points: new_points,
					changed: true,
				}));

				// This is necessary to prevent the default paste action.
				event.preventDefault();
			}
		}
	}


	async function saveHandler() {

		try {

			const limit = {
				type_id: local_state.type_id,
				capacity_id: capacity_id,
				limit_date: getDateFormat(local_state.limit_date),
				p01: (local_state.hour_points.length > 0) ? local_state.hour_points[0].limit : null,
				p02: (local_state.hour_points.length > 1) ? local_state.hour_points[1].limit : null,
				p03: (local_state.hour_points.length > 2) ? local_state.hour_points[2].limit : null,
				p04: (local_state.hour_points.length > 3) ? local_state.hour_points[3].limit : null,
				p05: (local_state.hour_points.length > 4) ? local_state.hour_points[4].limit : null,
				p06: (local_state.hour_points.length > 5) ? local_state.hour_points[5].limit : null,
				p07: (local_state.hour_points.length > 6) ? local_state.hour_points[6].limit : null,
				p08: (local_state.hour_points.length > 7) ? local_state.hour_points[7].limit : null,
				p09: (local_state.hour_points.length > 8) ? local_state.hour_points[8].limit : null,
				p10: (local_state.hour_points.length > 9) ? local_state.hour_points[9].limit : null,
				p11: (local_state.hour_points.length > 10) ? local_state.hour_points[10].limit : null,
				p12: (local_state.hour_points.length > 11) ? local_state.hour_points[11].limit : null,
				p13: (local_state.hour_points.length > 12) ? local_state.hour_points[12].limit : null,
				p14: (local_state.hour_points.length > 13) ? local_state.hour_points[13].limit : null,
				p15: (local_state.hour_points.length > 14) ? local_state.hour_points[14].limit : null,
				p16: (local_state.hour_points.length > 15) ? local_state.hour_points[15].limit : null,
				p17: (local_state.hour_points.length > 16) ? local_state.hour_points[16].limit : null,
				p18: (local_state.hour_points.length > 17) ? local_state.hour_points[17].limit : null,
				p19: (local_state.hour_points.length > 18) ? local_state.hour_points[18].limit : null,
				p20: (local_state.hour_points.length > 19) ? local_state.hour_points[19].limit : null,
				p21: (local_state.hour_points.length > 20) ? local_state.hour_points[20].limit : null,
				p22: (local_state.hour_points.length > 21) ? local_state.hour_points[21].limit : null,
				p23: (local_state.hour_points.length > 22) ? local_state.hour_points[22].limit : null,
				p24: (local_state.hour_points.length > 23) ? local_state.hour_points[23].limit : null,
				p25: (local_state.hour_points.length > 24) ? local_state.hour_points[24].limit : null,
			};

			const response_limit = await axios.post(update_url, limit);
			if (response_limit.status === 200) {

				// read avaliable capacity
				const limit_responce = await axios.get('/api/capacity/limits', {
					params: {
						out_domain: local_state.out_domain_id,
						in_domain: local_state.in_domain_id,
						limit_date: getDateFormat(local_state.limit_date)
					}
				});

				const rows = limit_responce.data.capacities_list;

				// calculate avaliable capacity for the date
				const capacity = []
				for (let i = 0; i < rows.length; i++) {

					for (let j = 1; j <= 25; j++) {

						const index = (j < 10) ? `p0${j}` : `p${j}`;

						if (capacity[j] === undefined) {
							capacity[j] = 0;
						}
						capacity[j] += rows[i][index];
					}
				}

				for (let i = 0; i < local_state.saved_es.length; i++) {

					let changed = false;

					for (let j = 1; j <= 25; j++) {

						const index = (j < 10) ? `p0${j}` : `p${j}`;

						if (local_state.saved_es[i][index] !== undefined && local_state.saved_es[i][index] > capacity[j]) {
							
							local_state.saved_es[i][index] = capacity[j];
							changed = true;
						}
					}

					if (changed) {

						window.alert('В системі збережено графіки імпорту / експорту, що будуть змінені згідно з введеним обмеженням.');

						const result_change = await axios.post('/api/es/' + local_state.saved_es[i].external_series_id, local_state.saved_es[i]);
					}
				}

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

				setMessage(message => ({ ...message,
					show: true,
					message: err_message,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function onSubmitHandler(e) {

		e.preventDefault();

		var data_valid = local_state.date_valid;
		local_state.hour_points.forEach(item => {
			if (!item.valid) {
				document.getElementById(item.id).focus();
				data_valid = false;
			}
		});

		if (data_valid) {

			if (local_state.changed) {

				// if state has changed - save
				if (await saveHandler()) {
					// return to parent page
					props.history.push('/capacities/' + capacity_id);
				}
			}

		} else {
			window.alert('Перевірте правильність вводу даних.');
		}
	}


	function onCancelHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		props.history.go(-1);
	}


	function OnImportExportHandler() {

		if (local_state.changed && !window.confirm('Незбережені зміни будуть втрачені. Ви впевнені, що бажаєте вийти?')) {
			return;
		}

		alert('Не реалізовано.');
	}


	function updateHours(start_date) {

		if (local_state.type_id > 0) {
			return;
		}

		// get start and end dates
		var calculated_start_date = new Date(start_date);
		var calculated_end_date = new Date(start_date);
		calculated_end_date.setMinutes(calculated_end_date.getMinutes() + 24 * 60);

		// get difference in hours between the dates
		var hours = (calculated_end_date - calculated_start_date) / 3.6e6;
		var result = null;

		// if hours is greater then array length, add elements
		if (hours > local_state.hour_points.length) {

			// create a copy of hour_point and add elements
			result = [ ...local_state.hour_points ];
			const new_length = (hours > 25) ? 25 : hours
			for (let i = local_state.hour_points.length + 1; i <= new_length; i++) {

				result.push({
					key: i,
					id: i.toString(),
					label: i.toString(),
					valid: true,
					limit: 0,
					rs: 0,
				});
			}

			setLocalState(prev_state => ({ ...prev_state,
				hour_points: result
			}));
		}

		// if hours is lower then array length, remove elements
		if (hours < local_state.hour_points.length) {

			// create a copy of hour_point and remove elements
			result = [ ...local_state.hour_points ];
			setLocalState(prev_state => ({ ...prev_state,
				hour_points: result.slice(0, (hours < 0) ? 0 : hours)
			}));
		}
	}


	function onErrorMessageClose() {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {

			try {

				const response = await axios.get(update_url);
				if (response.data !== null) {

					const { limit_date } = response.data;

					var result = [ ...local_state.hour_points ];
					for (let i = 1; i <= 25; i++) {

						const index = (i < 10) ? `p0${i}` : `p${i}`;
						if (response.data[index] !== null) {
							result[i - 1].limit = parseFloat(response.data[index]);
						}
					}

					setLocalState(prev_state => ({ ...prev_state,
						limit_date: getDateFormat(limit_date),
						hour_points: result,
						changed: false,
					}));
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (!isNaN(limit_id)) {
			fetchData();
		}

	}, [limit_id, update_url])


	useEffect(() => {

		async function fetchData() {

			try {

				const response = await axios.get('/api/capacity/' + capacity_id);
				if (response.data !== null) {

					const { type, out_domain_id, in_domain_id, period_start, period_end } = response.data;

					var result = [ ...local_state.hour_points ];
					for (let i = 1; i <= 25; i++) {

						const index = (i < 10) ? `p0${i}_rs` : `p${i}_rs`;

						if (type > 0) {
							if (result[i - 1] !== undefined) {
								result[i - 1].rs = parseFloat(response.data['p01_rs']);
							}
						} else {
							if (response.data[index] !== null) {
								result[i - 1].rs = parseFloat(response.data[index]);
							}
						}
					}

					const start_date = new Date(period_start);
					const end_date = new Date(period_end);
					const set_date = new Date(local_state.limit_date);

					setLocalState(prev_state => ({ ...prev_state,
						type_id: type,
						out_domain_id: parseInt(out_domain_id),
						in_domain_id: parseInt(in_domain_id),
						period_start: getDateFormat(period_start),
						period_end: getDateFormat(period_end),
						date_valid: start_date <= set_date && set_date <= end_date,
						hour_points: result,
					}));

					if (type === 0) {
						setLocalState(prev_state => ({ ...prev_state,
							limit_date: getDateFormat(period_start),
							date_valid: true,
						}));
					}
				}

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (!isNaN(capacity_id)) {
			fetchData();
		}

	}, [capacity_id])


	useEffect(() => {

		async function fetchData() {

			try {

				const response_es = await axios.get('/api/es', {
					params: {
						out_domain: local_state.out_domain_id,
						in_domain: local_state.in_domain_id,
						period_start: getDateFormat(local_state.limit_date),
						period_end: getDateFormat(local_state.limit_date)
					}
				});

				const saved_es = response_es.data.external_series_list;
				setLocalState(prev_state => ({ ...prev_state,
					saved_es: saved_es
				}));

			} catch (error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					let err_message = (error.response.data.message !== undefined) ? error.response.data.message : error.response.data;

					setMessage(message => ({ ...message,
						show: true,
						message: err_message,
					}));
				}

				console.log(error);
			}
		}

		if (local_state.out_domain_id !== 0 && local_state.in_domain_id !== 0) {
			fetchData();
		}

	}, [local_state.out_domain_id, local_state.in_domain_id, local_state.limit_date])


	return (
		<div className={ classes.root_form }>

			<Avatar className={ classes.avatar }>
				{ isNaN(limit_id) ? <AddIcon /> : <EditIcon /> }
			</Avatar>
			<Typography component='h2' variant='h4' align='center'>
				{ isNaN(limit_id) ? 'Додати обмеження до аукціону з розподілу пропускної спороможності' : 'Змінити обмеження до аукціону з розподілу пропускної спроможності' }
			</Typography>

			<form className = { classes.form_control } onSubmit={ onSubmitHandler } >

				<Typography component='h2' variant='h5' align='center' className= { classes.header }>
					Обмеження до аукціону з розподілу пропускної спроможності
				</Typography>

				<Grid container spacing={2}>

					<Grid item xs={3}>

						<TextField
							variant='outlined'
							required
							fullWidth
							label='Доба обмеження'
							type='date'
							value = { local_state.limit_date }
							onChange = { handleLimitDateChange }
							InputLabelProps={{ shrink: true }}
							disabled={ local_state.type_id === 0 }
							error={ !local_state.date_valid }
						/>

					</Grid>

					<Grid item xs={9} />

				</Grid>

				<div className={classes.button_form_control}>
					{
						(local_state.hour_points.length) > 0 &&
						<table className={classes.table_control}>
							<thead>
								<tr className={classes.table_head}>
									<td>
										Година
									</td>
									<td>
										РПС (МВт)
									</td>
									<td>
										Скоригована РПС (МВт)
									</td>
								</tr>
							</thead>
							<tbody>
								{
									local_state.hour_points.map((item, index) => (
										<tr key={index} className={ (index % 2 === 0) ? classes.table_row_1 : classes.table_row_2 }>
											<td className={classes.table_head}>
												{ item.label }
											</td>
											<td>
												{ item.rs }
											</td>
											<td>
												<TextField
													size='small'
													className= { classes.hour_point }
													key={ `${item.key}-limit` }
													id={ `${item.id}-limit` }
													error={ !item.valid }
													onChange={ onTextChangeHandler }
													onPaste={ onValuePaste }
													value={ item.limit }
												/>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					}
				</div>

				<div className={classes.button_form_control}>

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className = { classes.button_control }
						disabled={ !local_state.changed }
					>
						Зберегти
					</Button>

					<Button
						variant='contained'
						color='primary'
						className = { classes.button_control }
						onClick = { OnImportExportHandler }
						disabled = { true }
					>
						Імпорт / Експорт
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className = { classes.button_control }
						onClick= { onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</div>
	)
}