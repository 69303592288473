import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	grid: {
		marginTop: theme.spacing(4),
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		display: 'inline-block',
	},

}));


export default function SettingsConfigForm(props) {

	const classes = useStyles();

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	const initial_state = {
		oree_signature_password: '',
		oree_user: '',
		oree_password: '',
		es_send_address: ''
	};
	const [local_state, setLocalState] = useState(initial_state);


	function handleOreeSignaturePasswordChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			oree_signature_password: event_value,
		}));
	}


	function handleOreeUserChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			oree_user: event_value,
		}));
	}


	function handleOreePasswordChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			oree_password: event_value,
		}));
	}


	function handleEsSendAddressChange (event) {
		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			es_send_address: event_value,
		}));
	}


	async function oree_signature_password_change () {

		const payload = {
			setting_name: 'DSTU4145_KEY_PASS',
			setting_value: local_state.oree_signature_password,
			encode: true,
		};

		try {

			const response = await axios.post('/api/settings/config/set', payload);
			if (response.status === 200) {

				window.alert('Пароль було змінено.');

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function oree_user_change () {

		const payload = {
			setting_name: 'XMTRADE_USER',
			setting_value: local_state.oree_signature_password,
			encode: true,
		};

		try {

			const response = await axios.post('/api/settings/config/set', payload);
			if (response.status === 200) {
				window.alert('Користувача було змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function oree_password_change () {

		const payload = {
			setting_name: 'XMTRADE_PASS',
			setting_value: local_state.oree_signature_password,
			encode: true,
		};

		try {

			const response = await axios.post('/api/settings/config/set', payload);
			if (response.status === 200) {
				window.alert('Пароль було змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	async function es_send_address_change () {

		const payload = {
			setting_name: 'NEC_ES_SEND_ADDRESS',
			setting_value: local_state.es_send_address,
			encode: false,
		};

		try {

			const response = await axios.post('/api/settings/config/set', payload);
			if (response.status === 200) {
				window.alert('Адресу змінено.');
				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		}

		return false;
	}


	function onErrorMessageClose () {

		setMessage(message => ({...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData() {

			try {

				let payload = {
					setting_name: 'XMTRADE_USER',
					encode: true,
				};
		
				const resp_user = await axios.post('/api/settings/config/get', payload);
				const user_name = resp_user.data.setting_value;

				payload = {
					setting_name: 'NEC_ES_SEND_ADDRESS',
					encode: false,
				};

				const resp_addr = await axios.post('/api/settings/config/get', payload);
				const addr_list = resp_addr.data.setting_value;

				setLocalState(prev_state => ({ ...prev_state,
					oree_user: user_name,
					es_send_address: addr_list,
				}));

			} catch(error) {

				console.log(error);

				setMessage(message => ({ ...message,
					show: true,
					message: error.message,
				}));
			}
		}

		fetchData();

	}, [])


	return (

		<Container className={ classes.paper }>

			<Typography component='h1' variant='h4'>
				Системні налаштування
			</Typography>

			<Grid container spacing={3} className={classes.grid}>

				<Grid item xs={3}>

					<Typography variant='h6'>
						Пароль електронного кваліфікованого цифрового підпису ДСТУ 4145
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						type='password'
						fullWidth
						label='Пароль ЕЦП'
						onChange={ handleOreeSignaturePasswordChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ oree_signature_password_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Електронний кваліфікований цифровий підпис за форматом ДСТУ 4145 використовується для підпису повідомлень, що надсилаютсья в систему XMtrade.
						Підпис та сертифікат зберігаються на сервері PowerTrade, пароль для доступу змінюється за допомогою цього налаштування.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Користувач системи XMtrade
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						label='Користувач XMtrade'
						value={local_state.oree_user}
						onChange={ handleOreeUserChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ oree_user_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Ім'я користувача від імені якого Powertrade надсилає повідомлення до XMtrade.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Пароль користувача системи XMtrade
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						type='password'
						fullWidth
						label='Пароль користувача XMtrade'
						onChange={ handleOreePasswordChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ oree_password_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Пароль користувача від імені якого Powertrade надсилає повідомлення до XMtrade.
					</Typography>

				</Grid>


				<Grid item xs={3}>

					<Typography variant='h6'>
						Адреси відправлення графіків імпорту / експорту
					</Typography>

				</Grid>

				<Grid item xs={3}>

					<TextField
						variant='outlined'
						fullWidth
						multiline={true}
						label='EMAIL адреси'
						value={ local_state.es_send_address }
						onChange={ handleEsSendAddressChange }
					/>

				</Grid>

				<Grid item xs={2} className={classes.button_form_control}>

					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ es_send_address_change }
					>
						Зберегти
					</Button>

				</Grid>

				<Grid item xs={4}>

					<Typography variant='body1'>
						Адреси, на які відправляються графіки імпорту / експорту (декілька адрес вказуються через ';').
					</Typography>

				</Grid>

			</Grid>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}