import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import PersonEditIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';

import MessageErrorSnackbar from '../subcomponents/MessageErrorSnackbar';

import { adminContext } from './adminContext';
import { is_valid_company_name, is_valid_address, is_valid_registration_code, is_valid_eic_code, is_valid_email } from '../utils/validation';


const useStyles = makeStyles(theme => ({

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	select_form_control: {
		marginTop: theme.spacing(2),
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},

	button_form_control: {
		textAlign: 'center',
	},

	button: {
		margin: theme.spacing(2, 0.5),
		display: 'inline-block',
	},

	modal: {
		display: 'table',
		height: '100%',
	},

	wrapper: {
		display: 'table-cell',
		verticalAlign: 'middle',
	},

	delete_button: {
		marginLeft: theme.spacing(1),
		display: 'inline-block',
	},

	icon_button: {
		marginTop: theme.spacing(1),
	}

}));


export default function CompanyEditForm(props) {

	const classes = useStyles();

	const { setGlobalState } = useContext(adminContext);

	// define states for messages
	const initial_message = {
		show: false,
		message: ''
	};
	const [message, setMessage] = useState(initial_message);

	const initial_state = {
		company_name: '',
		company_address: '',
		company_reg_code: '',
		company_eic_code: '',

		name_valid: false,
		address_valid: false,
		reg_code_valid: false,
		eic_code_valid: false,

		initial_emails: [],
		emails: []
	};
	const [local_state, setLocalState] = useState(initial_state);

	// get company id from url
	const company_id = parseInt(props.match.params.company_id);
	const update_url = (company_id) ? '/api/admin/company/' + company_id : '/api/admin/company/add';
	const delete_url = (company_id) ? '/api/admin/company/' + company_id + '/delete' : undefined;


	function handleNameChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			company_name: event_value,
			name_valid: is_valid_company_name(event_value),
		}));
	}


	function handleAddressChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			company_address: event_value,
			address_valid: is_valid_address(event_value),
		}));
	}


	function handleCodeChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			company_reg_code: event_value,
			reg_code_valid: is_valid_registration_code(event_value),
		}));
	}


	function handleEicCodeChange (event) {

		const event_value = event.target.value;

		setLocalState(prev_state => ({ ...prev_state,
			company_eic_code: event_value,
			eic_code_valid: is_valid_eic_code(event_value),
		}));
	}


	function onAddConfirmationEmail () {

		const emails = [ ...local_state.emails ];
		emails.push('');

		setLocalState(prev_state => ({ ...prev_state,
			emails: emails,
		}));
	}


	function onConfirmationEmailChange (index, event) {

		const event_value = event.target.value;

		const emails = [ ...local_state.emails ];
		emails[index] = event_value;

		setLocalState(prev_state => ({ ...prev_state,
			emails: emails,
		}));
	}


	function onDeleteConfirmationEmail (index) {

		const emails = [ ...local_state.emails ];
		emails.splice(index, 1);

		setLocalState(prev_state => ({ ...prev_state,
			emails: emails,
		}));
	}


	async function SaveCompanyHandler () {

		const company_data = {
			company_id: company_id,
			name: local_state.company_name,
			address: local_state.company_address,
			registration_code: local_state.company_reg_code,
			eic_x_code: local_state.company_eic_code
		};

		try {

			const response = await axios.post(update_url, company_data);

			if (response.status === 200) {

				if (!isNaN(company_id)) {
					// update emails

					const payload = {
						emails: local_state.emails
					}
					await axios.post('/api/admin/company/' + company_id + '/confirmation', payload);
				}

				setGlobalState(prev_state => ({ ...prev_state,
					update_flag: !prev_state.update_flag,
				}));

				return true;
			}

		} catch(error) {

			if (error.response) {
				// The request was made and the server responded with a status code out of the range of 2xx
				setMessage(message => ({ ...message,
					show: true,
					message: error.response.data,
				}));
			}

			console.log(error);
		};

		return false;
	}


	function onCancelHandler () {
		props.history.go(-1);
	}


	function onDeleteHandler () {

		if (!delete_url) {
			return;
		}

		if (!window.confirm('Ви впевнені, що бажаєте видалити компанію?')) {
			return;
		}

		axios.post(delete_url)
			.then(response => {

				if (response.status === 200) {

					setGlobalState(prev_state => ({ ...prev_state,
						update_flag: !prev_state.update_flag,
					}));

					// return to parent page
					props.history.push('/admin');
				}

			})
			.catch(error => {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({
						...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			});
	}


	async function onSubmitHandler (e) {

		e.preventDefault();

		if (local_state.name_valid && local_state.address_valid && local_state.reg_code_valid && local_state.eic_code_valid) {

			for (let i = 0; i < local_state.emails.length; i++) {
				if (!is_valid_email(local_state.emails[i])) {
					alert('Перевірте вказану email адресу.');
					return;
				}
			}

			let user_response = true;
			if (company_id) {
				user_response = window.confirm('Ви впевнені, що бажаєте змінити компанію?');
			}

			if (user_response) {
				if (await SaveCompanyHandler()) {
					// return to parent page
					props.history.push('/admin');
				}
			}
		}
	}


	function onErrorMessageClose () {

		setMessage(message => ({ ...message,
			show: false,
			message: '',
		}));
	}


	useEffect(() => {

		async function fetchData(company_id, update_url) {

			try {

				const response_company = await axios.get(update_url);
				const response_emails = await axios.get('/api/admin/company/' + company_id + '/confirmation');
				const emails = response_emails.data.emails;

				if (response_company.data.company !== null) {

					setLocalState(prev_state => ({ ...prev_state,
						company_name: response_company.data.company.name,
						company_address: response_company.data.company.address,
						company_reg_code: response_company.data.company.registration_code,
						company_eic_code: response_company.data.company.eic_x_code,

						name_valid: true,
						address_valid: true,
						reg_code_valid: true,
						eic_code_valid: true,

						initial_emails: emails,
						emails: emails,
					}));
				}

			} catch(error) {

				if (error.response) {
					// The request was made and the server responded with a status code out of the range of 2xx
					setMessage(message => ({ ...message,
						show: true,
						message: error.response.data,
					}));
				}

				console.log(error);
			}
		}

		if (company_id) {
			fetchData(company_id, update_url);
		}

	},[company_id, update_url])


	return (
		<Container className={ classes.paper } component='main' maxWidth='sm'>

			<Avatar className={ classes.avatar }>
				{ isNaN(company_id) ? <PersonAddIcon /> : <PersonEditIcon /> }
			</Avatar>
			<Typography component='h1' variant='h4'>
				{ isNaN(company_id) ? 'Додати компанію' : 'Змінити компанію' }
			</Typography>

			<form onSubmit={ onSubmitHandler }>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					error={ !local_state.name_valid }
					label='Назва компанії'
					value={ local_state.company_name }
					onChange={ handleNameChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					error={ !local_state.address_valid }
					label='Адреса компанії'
					value={ local_state.company_address }
					onChange={ handleAddressChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					error={ !local_state.reg_code_valid }
					label='Код ЄДРПОУ'
					value={ local_state.company_reg_code }
					onChange={ handleCodeChange }
				/>

				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					error={ !local_state.eic_code_valid }
					label='EIC код типу X'
					value={ local_state.company_eic_code }
					onChange={ handleEicCodeChange }
				/>

				{ 
					local_state.emails.map((item, index) => {
						return(
						<Grid key={ `grid-${index}` } container>

							<Grid item xs={11}>
								<TextField
									key={ `text-${index}` }
									variant='outlined'
									margin='normal'
									required
									fullWidth
									error={ !is_valid_email(item) }
									label='Email погодження РДД'
									value={ item }
									onChange={ (event) => onConfirmationEmailChange(index, event) }
								/>
							</Grid>

							<Grid item xs={1}>

								<div className={classes.modal}>
									<div className={classes.wrapper}>
										<Fab
											className={ classes.delete_button }
											onClick={ () => onDeleteConfirmationEmail(index) }
											color='secondary'
											size='small'
										>
											<DeleteIcon
												className={classes.icon_button}
											/>
										</Fab>
									</div>
								</div>

							</Grid>

						</Grid>
					)})
				}

				<div className={ classes.button_form_control }>

					{ !isNaN(company_id) &&
					<Tooltip title='Додати email погодження РДД'>
						<span className = { classes.button }>
							<Fab
								onClick={ onAddConfirmationEmail }
								color='primary'
								size='small'
							>
								<AddIcon />
							</Fab>
						</span>
					</Tooltip> }

					<Button
						type='submit'
						variant='contained'
						color='primary'
						className={ classes.button }
					>
						Зберегти
					</Button>

					{ !isNaN(company_id) &&
					<Button
						variant='contained'
						color='primary'
						className={ classes.button }
						onClick={ onDeleteHandler }
					>
						Видалити
					</Button> }

					<Button
						variant='contained'
						color='secondary'
						className={ classes.button }
						onClick={ onCancelHandler }
					>
						Скасувати
					</Button>

				</div>

			</form>

			<MessageErrorSnackbar
				open={ message.show }
				message={ message.message }
				info={ '' }
				onClose={ onErrorMessageClose }
			/>

		</Container>
	)
}